import React, { useContext, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import DialogoConfirma from 'componentes/DialogoConfirma';
import FormField from "componentes/FormField";
import { Autocomplete, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";


function AjustesBancodeDadosAdmin(props) {

  const { empresaAtual, nomeEmpresaAtual } = props;

  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const [memoLog, setMemoLog] = useState('');
  const [valor_custo, setValor_custo] = useState(0.01);
  const [ativo,setAtivo]=useState({value:'N',label:'Não'});



  const alteraCustos = async () => {

    setDialogoOpen({ ...dialogoOpen, visible: false });

    // if (!((apagaProdutos) || (apagaProjetos) || (apagaVendas) || (apagaPessoas) || (apagaParametros))) {
    //   MensagemErro('Selecione a opção que deseja excluir');
    //   return;
    // }

    try {
      const id = dialogoOpen.id
      MostraTelaEspera('Aguarde o processamento ');
      const retorno = await api.post(`${rotafechada()}admin/empresas/alteracustos`, {
        empresa_id: empresaAtual,
        valor_custo: parseFloat(valor_custo)

      });
      FechaTelaEspera();
      MensagemAviso('Alterado com sucesso!');
      setMemoLog('');
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      const erro = error.response?.data?.erro || msg;
      setMemoLog(erro)
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const alteraAtivoProjetos = async () => {

    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      const id = dialogoOpen.id
      MostraTelaEspera('Aguarde o processamento ');
      const retorno = await api.post(`${rotafechada()}admin/empresas/alteraativoprojetos`, {
        empresa_id: empresaAtual,
        ativo: ativo.value||'N'

      });
      FechaTelaEspera();
      MensagemAviso('Alterado com sucesso!');
      setMemoLog('');
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      const erro = error.response?.data?.erro || msg;
      setMemoLog(erro)
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickAlterarCustos = async (id) => {
    setDialogoOpen({
      ...dialogoOpen,
      visible: true,
      id,
      title: 'Alterar Custos',
      message: `Certeza que Alterar os Custos de todos os materiais da empresa  ${empresaAtual}-${nomeEmpresaAtual}`,
      funcao: 'alteraCustos'
    });
  }

  const handleClickAlteraAtivoProjetos = async (id) => {
    setDialogoOpen({
      ...dialogoOpen,
      visible: true,
      id,
      title: 'Alterar Ativo',
      message: `Certeza que Alterar todos os projetos para ativo=${ativo.value==='S'?'S':'N'} da empresa  ${empresaAtual}-${nomeEmpresaAtual}`,
      funcao: 'alteraAtivo'
    });
  }


  const handleConfirmDialogo = () => {
    if (dialogoOpen.funcao === 'alteraCustos') {
      alteraCustos();
    }
    else{
      if (dialogoOpen.funcao === 'alteraAtivo') {
        alteraAtivoProjetos();
      }
    }
  }


  return (
    <>

      <DialogoConfirma
        open={dialogoOpen.visible}
        title={dialogoOpen.title}
        message={dialogoOpen.message}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
        handleConfirm={handleConfirmDialogo}
      />

      <MDBox>
        <MDBox mt={0} mb={4} textAlign="center">
          <MDBox mb={1}>
            <MDTypography variant="h4" fontWeight="bold">
              {`Exclui Banco de Dados`}
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox>
          <Grid container>

            <Grid item xs={12} sm={12} mt={4}>
              <MDBox >
                <FormField
                  type='number'
                  label='Valor de Custo Padrão'
                  rows={1}
                  multiline={false}
                  required={true}
                  disabled={false}
                  value={valor_custo}
                  onChange={(e) => setValor_custo(e.target.value)}
                />
                <MDButton sx={{ mt: 2 }} color='error' onClick={handleClickAlterarCustos}>Alterar Custos de Todos os Materiais</MDButton>
              </MDBox>
            </Grid>

            <Grid item xs={12} sm={12} mt={4}>
              <MDBox >
              <AutoCompleteStyled
                  options={[{value:'N',label:'Não'},{value:'S',label:'Sim'}]}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => <FormField label='Ativo' {...params} />}
                  onChange={(event, newValue) => {
                    setAtivo(newValue)
                  }}
                  defaultValue={{value:'N',label:'Não'}}
                />
                 <MDButton sx={{ mt: 2 }} color='error' onClick={handleClickAlteraAtivoProjetos}>Altera Ativo dos Projetos</MDButton>
              </MDBox>
            </Grid>

            <Grid item xs={12} sm={12} mt={12}>
              <FormField
                type='text'
                label='Log'
                name='memoLog'
                rows={10}
                multiline={true}
                required={true}
                disabled={false}
                value={memoLog}
              />
            </Grid>

          </Grid>
        </MDBox>

      </MDBox>
    </>
  );
}

export default AjustesBancodeDadosAdmin;
