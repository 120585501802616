import React, { useState, useEffect, useContext } from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import { Box, Icon, InputAdornment, Typography } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

//My Componentes
import FormField from "componentes/FormField";
//My componentes e functions
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import AuthContext from 'hooks/AuthContext';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import Visa from '../../../../assets/images/flags/visa.svg'
import Elo from '../../../../assets/images/flags/elo.svg'
import Mastercard from '../../../../assets/images/flags/mastercard.svg'
import Hipercard from '../../../../assets/images/flags/hipercard.svg'
import Diners from '../../../../assets/images/flags/diners.svg'
import Outro from '../../../../assets/images/flags/outro.svg'
import Americanexpress from '../../../../assets/images/flags/americanexpress.svg'
import CurrencyInput from 'componentes/MascaraMonetaria';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ptBR } from "date-fns/locale";



function DadosCartaoCredito(props) {

    const {
        label,
        banco,
        setBanco,
        diaFechamento,
        setDiaFechamento,
        diaVencimento,
        setDiaVencimento,
        bandeira,
        setBandeira,
        optionBandeira,
        optionBanco,
        tipo

    } = props;

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Typography variant='h5'>{label}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>{/*Nome banco*/}
                <AutoCompleteStyled
                    disableClearable //Retira o X (clear)
                    options={optionBanco}
                    getOptionLabel={(option) => option.label || ''}
                    renderInput={(params) => <FormField {...params} label="Banco" required={tipo.value == 'R' ? true : false} />}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    // renderOption={(props, option) => (
                    //     <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    //         <img
                    //             loading="lazy"
                    //             width="20"
                    //             //src={`${path}${option.img}`}
                    //             src={`https://app.contaazul.com/versions/current/assets/images/bank/50x50/${option.img}`}
                    //             alt="logo do banco"
                    //         />
                    //         {option.label}
                    //         {/*console.log("caminho: Logo dos banco", `${path}${option.img}`)*/}
                    //     </Box>
                    // )}
                    value={banco}
                    onChange={(event, newValue) => {
                        setBanco(newValue);
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>{/*Bandeira*/}
                <AutoCompleteStyled
                    disableClearable //Retira o X (clear)
                    options={optionBandeira}
                    getOptionLabel={(option) => option.label || ''}
                    renderInput={(params) => <FormField {...params} label="Bandeira" required={tipo.value == 'R' ? true : false} />}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            <img
                                loading="lazy"
                                width="20"
                                src={option.img}
                                alt="Bandeira dos cartões de crédito"
                            />
                            {option.label}
                        </Box>
                    )}
                    value={bandeira}
                    onChange={(event, newValue) => {
                        setBandeira(newValue);
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>{/*Dia do fechamento*/}
                <FormField
                    required={tipo.value == 'R' ? true : false}
                    label="Dia do fechamento"
                    type='number'
                    name='diaFechamento'
                    value={diaFechamento}
                    onChange={(e) => setDiaFechamento(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>{/*Dia do vencimento*/}
                <FormField
                    required={tipo.value == 'R' ? true : false}
                    label="Dia do vencimento"
                    type='number'
                    name='diaVencimento'
                    value={diaVencimento}
                    onChange={(e) => setDiaVencimento(e.target.value)}
                />
            </Grid>
        </Grid>
    )
}

function DadosBanco(props) {

    const {
        label,
        banco,
        setBanco,
        optionBanco,
        agencia,
        setAgencia,
        conta,
        setConta,
        tipo
    } = props;


    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Typography variant='h5'>{label}</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>{/*Nome banco*/}
                <AutoCompleteStyled
                    disableClearable //Retira o X (clear)
                    options={optionBanco}
                    getOptionLabel={(option) => option.label || ''}
                    renderInput={(params) => <FormField {...params} label="Banco" required={tipo.value == 'B' ? true : false} />}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    // renderOption={(props, option) => (
                    //     <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    //         <img
                    //             loading="lazy"
                    //             width="20"
                    //             //src={`${path}${option.img}`}
                    //             src={`https://app.contaazul.com/versions/current/assets/images/bank/50x50/${option.img}`}
                    //             alt="logo do banco"
                    //         />
                    //         {option.label}
                    //         {/*console.log("caminho: Logo dos banco", `${path}${option.img}`)*/}
                    //     </Box>
                    // )}
                    value={banco}
                    onChange={(event, newValue) => {
                        setBanco(newValue);
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>{/*Agência*/}
                <FormField
                    label="Agência"
                    name='agencia'
                    value={agencia}
                    onChange={(e) => setAgencia(e.target.value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>{/*Conta*/}
                <FormField
                    label="Conta"
                    name='conta'
                    value={conta}
                    onChange={(e) => setConta(e.target.value)}

                />
            </Grid>
        </Grid>
    )

}

function DadosLancamentoSaldo(props) {

    const {
        dataLancamento,
        setDataLancamento,
        saldoInicial,
        setSaldoInicial,
    } = props;


    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={12}> {/*Descritivo*/}
                <Typography variant="h6">Qual saldo você deve inserir?</Typography>
                <Typography variant="caption">
                    Para realizar a gestão financeira corretamente, é necessário informar o saldo final do dia anterior ao início do controle financeiro no sistema. É a partir deste valor que o sitema vai calcular as movimentações - entradas e saídas - e manter o total em conta com o mesmo valor do banco.
                </Typography>
                <Typography variant="caption">
                    Exemplo: se você quer cadastrar lançamentos a partir de hoje, informe o saldo final da sua conta de ontem.
                </Typography>
            </Grid>

            <Grid item xs={12} md={6}> {/*Inicio dos lançamentos na Conta*/}
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                    <DesktopDatePicker
                        required={true}
                        label='Data lançamento'
                        inputFormat="dd/MM/yyyy"
                        value={dataLancamento}
                        onChange={(e) => setDataLancamento(e)}
                        renderInput={(params) => <FormField {...params} />}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={6}> {/*Saldo final bancário*/}
                <CurrencyInput
                    label="Saldo final bancário:"
                    value={saldoInicial}
                    placeholder="0,00"
                    onChange={(e) => {
                        const tempValor = parseFloat(e.target.value.replace(/\./g, '').replace(',', '.'))
                        setSaldoInicial(tempValor)
                    }}
                    // helperText={valor > 0 ? false :
                    //     <>
                    //         <CancelIcon color={'error'} />
                    //         <Typography pl={0.5} fontWeight="small" variant="caption" color={'error'}>Valor deve ser superior a 0</Typography>
                    //     </>
                    // }
                    InputProps={{
                        startAdornment: <InputAdornment sx={{ width: '5%', fontSize: '20px' }} style={{ fontSize: '20px' }} position="start">R$</InputAdornment>,
                    }}
                />
            </Grid>
        </Grid>
    )

}

function Cadastro(props) {

    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();



    const { setCodigoAbrir, codigoAbrir, setAba } = props;

    const [id, setId] = useState('');
    const [nome, setNome] = useState('');
    const [saldoInicial, setSaldoInicial] = useState('');
    const [lancamentoInicialId, setLancamentoInicialId] = useState(null);
    const [dataLancamento, setDataLancamento] = useState(null);
    const [agencia, setAgencia] = useState('');
    const [conta, setConta] = useState('');
    const [diaFechamento, setDiaFechamento] = useState(0);
    const [diaVencimento, setDiaVencimento] = useState(0);

    const [bandeira, setBandeira] = useState({ label: '', value: '' });
    const optionBandeira = [
        {
            label: 'Visa',
            value: 'V',
            img: Visa
        },
        {
            label: 'Mastercard',
            value: 'M',
            img: Mastercard
        },
        {
            label: 'Diners',
            value: 'D',
            img: Diners
        },
        {
            label: 'American Express',
            value: 'A',
            img: Americanexpress
        },
        {
            label: 'Elo',
            value: 'E',
            img: Elo
        },
        {
            label: 'Hipercard',
            value: 'H',
            img: Hipercard
        },
        {
            label: 'Outro',
            value: 'O',
            img: Outro
        },
    ]

    const [banco, setBanco] = useState({ label: '', value: '' });
    const optionBanco = [
        { label: "Banco do Brasil", value: "1", img: 'banco-do-brasil-official.svg' },

        { label: "Bradesco", value: "237", img: 'bradesco.svg' },

        { label: "Santander", value: "33", img: 'santander.svg' },

        { label: "Caixa Econômica", value: "104", img: 'caixa.svg' },

        { label: "Banco Inter", value: "77", img: 'banco-inter.svg' },

        { label: "Nubank", value: "260", img: 'nubank.svg' },

        { label: "Banco BTG Pactual", value: "288", img: 'btgpactual.svg' },

        { label: "Banco BS2", value: "218", img: 'bs2.svg' },

        { label: "Itaú", value: "341", img: 'itau.svg' },

        { label: "Cora", value: "403", img: 'cora.svg' },

        { label: "Sicredi", value: "748", img: 'sicredi.svg' },

        { label: "Sicoob", value: "999", img: 'bancoob.svg' },

        { label: "Banco da Amazonia", value: "3", img: 'amazonia.svg' },

        { label: "Banco do Nordeste", value: "4", img: 'banco-nordeste.svg' },

        { label: "Banestes", value: "21", img: 'banestes.svg' },

        { label: "Banpara", value: "37", img: 'banpara.svg' },

        { label: "Banrisul", value: "41", img: 'banrisul.svg' },

        { label: "Banco Banese", value: "47", img: 'banese.svg' },

        { label: "Banco de Brasília", value: "70", img: 'banco-de-brasilia.svg' },

        { label: "Banco Topazio", value: "82", img: 'topazio.svg' },

        { label: "Uniprime Norte do Paraná", value: "84", img: 'uniprime.svg' },

        { label: "Ailos", value: "85", img: 'ailos.svg' },

        { label: "Credisan", value: "89", img: 'credisan.svg' },

        { label: "Polocred", value: "93", img: 'polocred.svg' },

        { label: "Cooperativa Central de Credito Noroeste Brasileiro", value: "97", img: 'credsis.svg' },

        { label: "Uniprime", value: "99", img: 'uniprime.svg' },

        { label: "AgiBank", value: "121", img: 'agibank.svg' },

        { label: "Banco Genial", value: "125", img: 'genial.svg' },

        { label: "Cresol", value: "133", img: 'cresol.svg' },

        { label: "Unicred", value: "136", img: 'unicred.svg' },

        { label: "Nossa Caixa", value: "151", img: 'nossa-caixa.svg' },

        { label: "BRL Trust DTVM", value: "173", img: 'brltust.svg' },

        { label: "Stone", value: "197", img: 'stone.svg' },

        { label: "Banco Original", value: "212", img: 'banco-original.svg' },

        { label: "CCR de São Miguel do Oeste", value: "273", img: 'default.svg' },

        { label: "Money Plus", value: "274", img: 'moneyplus.svg' },

        { label: "Pagseguro", value: "290", img: 'pagseguro.svg' },

        { label: "BCN", value: "291", img: 'bcn.svg' },

        { label: "PJBank", value: "301", img: 'pjbank.svg' },

        { label: "VORTX DTVM LTDA", value: "310", img: 'vortx.svg' },

        { label: "Banco BMG", value: "318", img: 'bmg.svg' },

        { label: "Mercado Pago", value: "323", img: 'mercadopago.svg' },

        { label: "Acesso Soluções de pagamento", value: "332", img: 'acesso.svg' },

        { label: "C6", value: "336", img: 'c6.svg' },

        { label: "Sudameris", value: "347", img: 'sudameris.svg' },

        { label: "Ótimo", value: "355", img: 'otimo.svg' },

        { label: "Banco Real", value: "356", img: 'banco-real.svg' },

        { label: "Gerencianet Pagamentos do Brasil", value: "364", img: 'gerencianet.svg' },

        { label: "JP Morgan", value: "376", img: 'jpmorgan.svg' },

        { label: "Picpay", value: "380", img: 'picpay.svg' },

        { label: "Juno", value: "383", img: 'juno.svg' },

        { label: "Global SCM", value: "384", img: 'globalscm.svg' },

        { label: "Mercantil do Brasil", value: "389", img: 'default.svg' },

        { label: "HSBC", value: "399", img: 'default.svg' },

        { label: "Unibanco", value: "409", img: 'unibanco.svg' },

        { label: "Banco Capital S.A", value: "412", img: 'bancocapital.svg' },

        { label: "Banco Safra", value: "422", img: 'default.svg' },

        { label: "Fitbank", value: "450", img: 'fitbank.svg' },

        { label: "ASAAS IP", value: "461", img: 'asaas.svg' },

        { label: "BankBoston", value: "479", img: 'bankboston.svg' },

        { label: "Banco Omni", value: "613", img: 'omni.svg' },

        { label: "Banco Pan", value: "623", img: 'pan.svg' },

        { label: "Rendimento", value: "633", img: 'rendimento.svg' },

        { label: "Neon", value: "655", img: 'default.svg' },

        { label: "Banco Daycoval", value: "707", img: 'daycoval.svg' },

        { label: "Banco Ribeirão Preto", value: "741", img: 'bancoribeirao.svg' },

        { label: "Citibank", value: "745", img: 'citi.svg' },

        { label: "Modal", value: "746", img: 'modal.svg' },

        { label: "BNP Paribas Brasil", value: "752", img: 'paribas.svg' },

        { label: "Bancoob", value: "756", img: 'bancoob.svg' },

        { label: "Next", value: "998", img: 'next.svg' },

        { label: "Outro Banco", value: "-20", img: 'outro-banco.svg' },

    ]

    const [ativo, setAtivo] = useState({
        label: 'Sim',
        value: 'S'
    });
    const optionAtivo = [
        {
            label: 'Não',
            value: 'N'
        },
        {
            label: 'Sim',
            value: 'S'
        },
    ]

    const [tipo, setTipo] = useState({
        label: 'Caixa',
        value: 'C'
    });
    const optionTipo = [
        {
            label: 'Caixa',
            value: 'C'
        },
        {
            label: 'Banco',
            value: 'B'
        },
        {
            label: 'Cartão de Credito',
            value: 'R'
        }
    ]

    const buscaDados = async () => {

        if (!parseInt(props.codigoAbrir, 10) > 0)
            return;


        MostraTelaEspera();
        try {
            const retorno = await api.get(`${rotafechada()}banco?id=${props.codigoAbrir}`);
            const { data, count } = retorno.data;

            // console.log("Dados Banco:", data);

            if (!count > 0) {
                MensagemErro('Nenhuma informação foi selecionada');
                return;
            }

            if (data[0].empresa_id !== sessaolocal.empresa_id) {
                MensagemErro('Registro de outra empresa!');
                return;
            }

            setId(data[0].id);
            setNome(data[0].nome);
            setAgencia(data[0].agencia);
            setConta(data[0].conta);
            if (data[0]?.Lancamento_Banco?.data)
                setDataLancamento(new Date(data[0]?.Lancamento_Banco?.data));
            if (data[0]?.Lancamento_Banco?.valor){}
                setSaldoInicial(parseFloat(data[0]?.Lancamento_Banco?.valor))
            if (data[0].lancamento_inicial_id)
                setLancamentoInicialId(data[0].lancamento_inicial_id)

            for (let i = 0; i < optionBandeira.length; i++) {

                if (optionBandeira[i].value == data[0].bandeira) {
                    setBandeira(optionBandeira[i]);
                }
            }

            for (let i = 0; i < optionBanco.length; i++) {

                if (optionBanco[i].value == data[0].banco) {
                    setBanco(optionBanco[i]);
                }
            }

            // optionBandeira.map((item, index) => {
            // })



            setDiaFechamento(data[0].dia_fechamento);
            setDiaVencimento(data[0].dia_vencimento);

            setTipo(data[0].tipo === 'C' ? optionTipo[0] : data[0].tipo === 'B' ? optionTipo[1] : optionTipo[2]);

            if (data[0].ativo === 'N') {
                setAtivo(optionAtivo[0])
            }
            else setAtivo(optionAtivo[1])


            FechaTelaEspera();

        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }

    }

    const handleClickGravar = async (e) => {
        e.preventDefault();

        try {
            MostraTelaEspera('Gravando o banco...');
            let retorno;


            const dados = {
                empresa_id: sessaolocal.empresa_id || null,
                nome,
                tipo: tipo.value,
                agencia: tipo.value == 'C' ? null : agencia,
                conta: tipo.value == 'C' ? null : conta,
                banco: tipo.value == 'C' ? null : banco.value,
                dia_fechamento: tipo.value == 'C' ? null : diaFechamento ? diaFechamento : 0,
                dia_vencimento: tipo.value == 'C' ? null : diaVencimento ? diaVencimento : 0,
                bandeira: tipo.value == 'C' ? null : bandeira.value,
                ativo: ativo.value,
                lancamento_inicial_id: lancamentoInicialId,
                saldo_inicial: saldoInicial,
                data_saldo_inicial: dataLancamento
            }
            // console.log(dados);

            if (codigoAbrir && parseInt(codigoAbrir, 10) > 0)
                retorno = await api.put(`${rotafechada()}banco/${codigoAbrir}`, dados);
            else {
                retorno = await api.post(`${rotafechada()}banco`, dados);
                props.setCodigoAbrir(retorno.data.data.id || 0)
            }

            MensagemAviso('Gravado com sucesso!');
            FechaTelaEspera();
            buscaDados()
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const limpaImputs = async () => {

        setCodigoAbrir('');
        setId('');
        setNome('');
        setBanco('');
        setTipo('');
        setAgencia('');
        setConta('');
        setBandeira('');
        setDiaFechamento(0);
        setDiaVencimento(0);


    }

    useEffect(() => {
        buscaDados();

    }, [codigoAbrir])


    return (
        <MDBox p={2}>
            <MDBox width="100%" display="flex" justifyContent="space-between">
                <MDButton
                    variant="gradient"
                    color="secondary"
                    type="button"
                    onClick={() => setAba(0)}
                >Voltar</MDButton>
                <MDButton
                    variant="gradient"
                    circular
                    color="info"
                    type="button"
                    onClick={limpaImputs}
                >
                    <Icon>add</Icon>
                </MDButton>
            </MDBox>

            <form onSubmit={handleClickGravar}>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} sm={3}> {/* ID */}
                        <FormField
                            type="text"
                            label="ID"
                            value={id || ''}
                            disabled={true}
                        //onChange={(e) => setNome(e.target.value)} 
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}> {/* ATIVO */}
                        <AutoCompleteStyled
                            disableClearable //Retira o X (clear)
                            defaultValue={optionAtivo ? optionAtivo[0] : { value: 'N' }}
                            options={optionAtivo}
                            getOptionLabel={(option) => option.label || ''}
                            renderInput={(params) => <FormField {...params} label="Ativo" />}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={ativo}
                            onChange={(event, newValue) => {
                                setAtivo(newValue);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}> {/* NOME */}
                        <FormField
                            required
                            type="text"
                            label="Nome"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}> {/* TIPO */}
                        <AutoCompleteStyled
                            disableClearable //Retira o X (clear)
                            defaultValue={optionTipo ? optionTipo[0] : { value: 'C' }}
                            options={optionTipo}
                            getOptionLabel={(option) => option.label || ''}
                            renderInput={(params) => <FormField {...params} label="Tipo" required />}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={tipo}
                            onChange={(event, newValue) => {
                                setTipo(newValue);
                            }}
                        />
                    </Grid>

                    {tipo?.value === 'B' &&
                        <>
                            <Grid item xs={12} md={12}>{/* Banco*/}
                                <DadosBanco
                                    label={'Banco'}
                                    banco={banco}
                                    setBanco={setBanco}
                                    optionBanco={optionBanco}
                                    agencia={agencia}
                                    setAgencia={setAgencia}
                                    conta={conta}
                                    setConta={setConta}
                                    tipo={tipo}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <DadosLancamentoSaldo
                                    dataLancamento={dataLancamento}
                                    setDataLancamento={setDataLancamento}
                                    saldoInicial={saldoInicial}
                                    setSaldoInicial={setSaldoInicial}
                                />
                            </Grid>
                        </>
                    }
                    {tipo?.value === 'R' &&
                        <>
                            <Grid item xs={12} md={12}>{/* Cartão de crédito */}
                                <DadosCartaoCredito
                                    label={'Cartão de crédito'}
                                    banco={banco}
                                    setBanco={setBanco}
                                    optionBanco={optionBanco}
                                    diaFechamento={diaFechamento}
                                    setDiaFechamento={setDiaFechamento}
                                    diaVencimento={diaVencimento}
                                    setDiaVencimento={setDiaVencimento}
                                    bandeira={bandeira}
                                    setBandeira={setBandeira}
                                    optionBandeira={optionBandeira}
                                    tipo={tipo}
                                />
                            </Grid>
                        </>
                    }
                    {tipo?.value === 'C' &&
                        <>
                            <Grid item xs={12} md={12}>
                                <DadosLancamentoSaldo
                                    dataLancamento={dataLancamento}
                                    setDataLancamento={setDataLancamento}
                                    saldoInicial={saldoInicial}
                                    setSaldoInicial={setSaldoInicial}
                                />
                            </Grid>
                        </>
                    }
                </Grid>

                <MDBox p={2}>{/*Botão gravar */}
                    <MDBox mt={3} width="100%" display="flex" justifyContent="flex-end">
                        <MDButton
                            type="submit"
                            variant="gradient"
                            color="info"
                        //onClick={handleClickGravar}
                        >
                            Gravar
                        </MDButton>
                    </MDBox>
                </MDBox>

            </form>


        </MDBox >
    )

}

export default Cadastro;