export const configTextField = [


  { xs: 12, sm: 3, autoComplete: 'id', id: 'id', name: 'id', label: 'ID', type: 'text', value: '', readOnly: true, register: {}, disabled: true },
  { xs: 12, sm: 12, required: true, autoComplete: 'nome', id: 'nome', name: 'nome', label: 'Nome', type: 'text', value: '', readOnly: false, register: {}, disabled: false },
  { xs: 12, sm: 6, autoComplete: 'descricao', id: 'descricao', name: 'descricao', label: 'Descrição', type: 'text', value: '', readOnly: false, register: {}, disabled: false },

  //campos de teste
  /*
  { xs: 12, sm: 12, autoComplete: 'grupoproduto', id: 'grupoproduto', name: 'grupoproduto', label: 'Grupos Produtos', type: 'textButton', value: '', readOnly: false, register: {}, disabled: false,
  rotachamadaclicklabel:'' },
  {xs: 12, sm: 12, autoComplete: 'data', id: 'data', name: 'data', label: 'Data', type: 'date', value: '', readOnly: false, register: {}, disabled: false },
  {xs: 12, sm: 12, autoComplete: 'hora', id: 'hora', name: 'hora', label: 'Hora', type: 'time', value: '', readOnly: false, register: {}, disabled: false },
  {xs: 12, sm: 12, autoComplete: 'datahora', id: 'datahora', name: 'datahora', label: 'Data/Hora', type: 'datetime', value: '', readOnly: false, register: {}, disabled: false },
{xs: 12, sm: 4, autoComplete: 'switch', id: 'switch', name: 'switch', label: 'Switch', type: 'switch', value: '', readOnly: false, register: {}, disabled: false },
{xs: 12, sm: 8, autoComplete: 'checkbox', id: 'checkbox', name: 'checkbox', label: 'checkbox', type: 'checkbox', value: '', readOnly: false, register: {}, disabled: false },
{xs: 12, sm: 12, autoComplete: 'radiogroup', id: 'radiogroup', name: 'radiogroup', label: 'radiogroup', type: 'radiogroup', value: '', readOnly: false, register: {}, disabled: false,
items:[
  { value: 'S', label: 'Sim' },
  { value: 'N', label: 'Não' },
] },
*/

]
