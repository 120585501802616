/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadgeDot from "components/MDBadgeDot";
import PieChart from "examples/Charts/PieChart";

import MessagesContext from 'hooks/MessagesContext';
import AuthContext from "hooks/AuthContext";


// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";
import React, { useContext, useEffect, useState } from 'react';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import { startOfWeek, endOfWeek, addMonths, addDays, addWeeks, addYears, endOfMonth, startOfMonth, startOfYear, endOfYear, subMonths, subDays, subWeeks, subYears } from 'date-fns'
import format from 'date-fns/format'
import { ptBR } from "date-fns/locale";
import { Button, Menu, MenuItem } from "@mui/material";

function GraficoPizza() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const [anchorElFiltro, setAnchorElFiltro] = useState(null);
  const [openDetalheFiltro, setOpenDetalheFiltro] = useState(false);
  const [dataInicial, setDataInicial] = useState(null);
  const [dataFinal, setDataFinal] = useState(null);

  const [filtro, setFiltro] = useState(
    {
      labelOriginal: new Date(),
      labelFormatada: format(new Date(), "dd 'de' MMMM yyyy", { locale: ptBR }),

      dataInicialOriginal: new Date(),
      dataInicialFormatada: (format(new Date(), "yyyy-MM-dd 00:00:00")),

      dataFinalOriginal: new Date(),
      dataFinalFormatada: (format(new Date(), "yyyy-MM-dd 23:59:59"))
    }
  );
  const [periodo, setPeriodo] = useState('Hoje');

  const [dadosGraficoPizza, setdadosGraficoPizza] = useState({ //Grafico de pizza 
    labels: ["Orçamentos", "Vendas"],
    datasets: {
      label: "Projects",
      backgroundColors: ["info", "warning"],
      data: [0, 0], //trocar pelo retorno do back
    },
  });

  const buscaQtdOrcamentosVendas = async () => {
    try {
      MostraTelaEspera('');
      let url = `${rotafechada()}dashboard/qtd_vendas_orcamentos?data_ini=${new Date(filtro.dataInicialFormatada)}&data_fim=${new Date(filtro.dataFinalFormatada)}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      setdadosGraficoPizza(prevState => ({
        ...prevState,
        datasets: {
          ...prevState.datasets,
          data: [parseInt(data.qtd_orcamentos), parseInt(data.qtd_vendas)]
        }
      }));
      // if (!data.length > 0) {
      //   MensagemErro(`Dashboard quantidade de venda e orcamento não localizado`);
      //   return;
      // }

      //setRecarregaComponenteOpcoes(!recarregaComponenteOpcoes);


      FechaTelaEspera();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleCloseFiltro = () => {
    setAnchorElFiltro(null);
    setOpenDetalheFiltro(false)
  };

  useEffect(async () => {
    await buscaQtdOrcamentosVendas();
  }, [filtro]);

  return (
    <>
      <Menu //Filtro
        anchorEl={anchorElFiltro}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={openDetalheFiltro}
        onClose={handleCloseFiltro}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        keepMounted
        disableAutoFocusItem
      >
        <MenuItem //Último Ano
          dense={true}
          onClick={() => {
            const lastYear = subYears(new Date(), 1);
            setPeriodo('Último ano')
            setFiltro(
              {
                labelOriginal: endOfYear(lastYear),
                labelFormatada: format(endOfYear(lastYear), "yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfYear(lastYear),
                dataInicialFormatada: (format(startOfYear(lastYear), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfYear(lastYear),
                dataFinalFormatada: (format(endOfYear(lastYear), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltro(false)
          }}>
          Último ano
        </MenuItem>

        <MenuItem //Último Mês
          dense={true}
          onClick={() => {
            const lastMonth = subMonths(new Date(), 1);
            setPeriodo('Último mês')
            setFiltro(
              {
                labelOriginal: endOfMonth(lastMonth),
                labelFormatada: format(endOfMonth(lastMonth), "MMMM 'de' yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfMonth(lastMonth),
                dataInicialFormatada: (format(startOfMonth(lastMonth), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfMonth(lastMonth),
                dataFinalFormatada: (format(endOfMonth(lastMonth), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltro(false)
          }}>
          Último mês
        </MenuItem>

        <MenuItem //Última Semana
          dense={true}
          onClick={() => {
            //const lastWeek = subWeeks(new Date(), 1);
            let lastWeek = new Date();
            lastWeek.setDate(lastWeek.getDate() - 7);
            setPeriodo('Última semana')
            setFiltro(
              {
                //labelOriginal: endOfWeek(lastWeek),
                //labelFormatada: format(endOfWeek(lastWeek), "'Semana de' dd 'de' MMMM 'de' yyyy", { locale: ptBR }),
                labelOriginal: `${format(startOfWeek(lastWeek), "dd/MM/yyyy")} até ${format(endOfWeek(lastWeek), "dd/MM/yyyy")}`,
                labelFormatada: `${format(startOfWeek(lastWeek), "dd/MM/yyyy")} até ${format(endOfWeek(lastWeek), "dd/MM/yyyy")}`,

                dataInicialOriginal: startOfWeek(lastWeek),
                dataInicialFormatada: (format(startOfWeek(lastWeek), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfWeek(lastWeek),
                dataFinalFormatada: (format(endOfWeek(lastWeek), "yyyy-MM-dd 23:59:59"))

              }
            )
            setOpenDetalheFiltro(false)
          }}>
          Última semana
        </MenuItem>

        <MenuItem //Hoje
          dense={true}
          onClick={() => {
            setPeriodo('Hoje')
            setFiltro(
              {
                labelOriginal: new Date(),
                labelFormatada: format(new Date(), "dd 'de' MMMM yyyy", { locale: ptBR }),

                dataInicialOriginal: new Date(),
                dataInicialFormatada: (format(new Date(), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: new Date(),
                dataFinalFormatada: (format(new Date(), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltro(false)
          }}>
          Hoje
        </MenuItem>

        <MenuItem //Semana
          dense={true}
          onClick={() => {
            setPeriodo('Essa semana')
            setFiltro(
              {
                labelOriginal: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,
                labelFormatada: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,

                dataInicialOriginal: startOfWeek(new Date()),
                dataInicialFormatada: (format(startOfWeek(new Date()), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfWeek(new Date()),
                dataFinalFormatada: (format(endOfWeek(new Date()), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltro(false)
          }}>
          Essa semana
        </MenuItem>

        <MenuItem //Mês
          dense={true}
          onClick={() => {
            setPeriodo('Esse mês')
            setFiltro(
              {
                labelOriginal: endOfMonth(new Date()),
                labelFormatada: format(endOfMonth(new Date()), "MMMM 'de' yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfMonth(new Date()),
                dataInicialFormatada: (format(startOfMonth(new Date()), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfMonth(new Date()),
                dataFinalFormatada: (format(endOfMonth(new Date()), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltro(false)
          }}>
          Esse mês
        </MenuItem>

        <MenuItem //Ano
          dense={true}
          onClick={() => {
            setPeriodo('Esse ano')
            setFiltro(
              {
                labelOriginal: endOfYear(new Date()),
                labelFormatada: format(endOfYear(new Date()), "yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfYear(new Date()),
                dataInicialFormatada: (format(startOfYear(new Date()), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfYear(new Date()),
                dataFinalFormatada: (format(endOfYear(new Date()), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltro(false)
          }}>
          Esse ano
        </MenuItem>

      </Menu>
      <Card sx={{ height: "100%" }}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <MDTypography variant="h6">Orçamentos/Vendas</MDTypography>
          <Tooltip title="Clique para alterar o Período" placement="top" arrow>
          <Button color="warning" size="small" onClick={(event) => {
            setAnchorElFiltro(event.currentTarget);
            setOpenDetalheFiltro(true);
          }} >
            {filtro.labelFormatada}
          </Button>
          </Tooltip>
        </MDBox>
        <MDBox mt={3}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <PieChart chart={dadosGraficoPizza} height="12.5rem" />
            </Grid>
            <Grid item xs={12}>
              <MDBox pr={1}>
                <MDBox mb={1}>
                  <MDBadgeDot color="info" size="md" badgeContent={`Orçamentos - ${dadosGraficoPizza.datasets.data[0]}`} />
                </MDBox>
                <MDBox mb={1}>
                  <MDBadgeDot color="warning" size="md" badgeContent={`Vendas - ${dadosGraficoPizza.datasets.data[1]}`} />
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        {/* <MDBox
          pt={4}
          pb={2}
          px={2}
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          mt="auto"
        >
          <MDBox width={{ xs: "100%", sm: "60%" }} lineHeight={1}>
            <MDTypography variant="button" color="text" fontWeight="light">
              More than <strong>1,200,000</strong> sales are made using referral marketing, and{" "}
              <strong>700,000</strong> are from social media.
            </MDTypography>
          </MDBox>
          <MDBox width={{ xs: "100%", sm: "40%" }} textAlign="right" mt={{ xs: 2, sm: "auto" }}>
            <MDButton color={darkMode ? "white" : "light"}>read more</MDButton>
          </MDBox>
        </MDBox> */}
      </Card>
    </>
  );
}

export default GraficoPizza;
