import React, { useState, useEffect, useContext } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MessagesContext from "hooks/MessagesContext";
import { Grid, Icon, InputAdornment, Tooltip, Divider, Typography } from "@mui/material";
import FormField from "componentes/FormField";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";
import AuthContext from 'hooks/AuthContext';
import { formataFloat, formatDate, formataValor } from "commons/utils";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Link } from "react-router-dom";
import HelpIcon from '@mui/icons-material/Help';
import MDBadge from "components/MDBadge";
import TabelaPadrao from "componentes/TabelaPadrao";
import DialogoConfirma from 'componentes/DialogoConfirma';
import { dateIsValid } from "commons/utils";
import CancelIcon from '@mui/icons-material/Cancel';
import { ptBR } from "date-fns/locale";
import CurrencyInput from "componentes/MascaraMonetaria";

export default function DialogoEditarParcela(props) {

    const { open, message, handleCancel, title, setCodigoAbrir, codigoAbrir, tipo } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera, MensagemAlerta } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });

    const [forma_pagamento_id, setForma_Pagamento_id] = useState({ value: -1, label: '<Escolha uma forma de pagamento>' });
    const [optionFormaPagamento, setOptionFormaPagamento] = useState([]);
    const [banco_id, setBanco_id] = useState({ value: -1, label: '<Escolha banco/caixa>' });
    const [optionBanco, setOptionBanco] = useState([]);

    //Conta
    const [conta_id, setConta_id] = useState(0);
    const [pessoa, setPessoa] = useState('');
    const [pessoa_id, setPessoa_id] = useState('');
    const [codigoReferencia, setCodigoReferencia] = useState('');
    const [dataCompetencia, setDataCompetencia] = useState('');
    const [categoria, setCategoria] = useState('');
    const [centro_custo, setCentro_custo] = useState('');
    const [descricaoConta, setDescricaoConta] = useState('');
    const [observacaoConta, setObservacaoConta] = useState('');
    const [valorConta, setValorConta] = useState(0);

    //Parcela
    const [data_Competencia, setData_Competencia] = useState('');
    const [vencimento, setVencimento] = useState('');
    const [parcela, setParcela] = useState(0);
    const [qtdParcela, setQtdParcela] = useState(0);
    const [descricaoParcela, setDescricaoParcela] = useState('');
    const [valorParcela, setValorParcela] = useState(0)
    const [observacaoParcela, setObservacaoParcela] = useState('');
    const [situacao, setSituacao] = useState('');

    //Lancamento_parcelas
    const [manual, setManual] = useState('');
    const [valorPago, setValorPago] = useState(0);
    const [juros, setJuros] = useState(0);
    const [multa, setMulta] = useState(0);
    const [desconto, setDesconto] = useState(0);
    const [tarifa, setTarifa] = useState(0);
    const [nsu, setNsu] = useState('');
    const [totalQuitado, setTotalQuitado] = useState(0);
    const [totalJuros, setTotalJuros] = useState(0);
    const [totalMulta, setTotalMulta] = useState(0);
    const [totalDesconto, setTotalDesconto] = useState(0);
    const [totalTarifa, setTotalTarifa] = useState(0);
    const [pago, setPago] = useState(false);

    const validaRotaPermissao = async (operacao = '', silencioso = false) => {
        if ((operacao !== 'inserir') &&
            (operacao !== 'editar') &&
            (operacao !== 'excluir') &&
            (operacao !== 'listar')) {
            MensagemErro('Escolha a operação que deseja validar a permissão!');
            return false;
        }

        //validando a permissao
        try {
            await api.get(`${rotafechada()}lancamento_parcelas/permissao/${operacao}`);
            return true;
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;

            if (silencioso === false) {
                MensagemErro(`Erro: ${msg}`);
            }
            return false;
        }
    }

    const buscaDados = async () => {
        try {
            MostraTelaEspera()
            //console.log(props)
            const retorno = await api.get(`${rotafechada()}parcelas_conta/parcelafull?id=${props.codigoAbrir}`);
            //const retorno = await api.get(`${rotafechada()}parcelas_conta/parcelafull?id=569`);
            const { dataConta, dataParcela, dataCountParcelas, dataLanParcelas, dataSituacao } = retorno.data;
            console.log("dataParcela", dataParcela)
            //Conta
            setConta_id(dataConta[0].id)
            setPessoa(dataConta[0].Pessoa.razao_social)
            setPessoa_id(dataConta[0].Pessoa.id)
            setCodigoReferencia(dataConta[0].codigo_referencia)
            let data_competencia_temp = new Date(dataConta[0].data_competencia)
            setDataCompetencia(formatDate(data_competencia_temp, 'DD/MM/YYYY'))
            setCategoria(dataConta[0].Categorium.nome)
            setCentro_custo(dataConta[0].Centro_Custo?.nome)
            setDescricaoConta(dataConta[0].nome)
            setObservacaoConta(dataConta[0].observacao)
            setValorConta(dataConta[0].valor)
            setManual(dataConta[0].manual)

            //Parcela
            setSituacao(dataSituacao)
            setData_Competencia(dataParcela[0].data_competencia)
            setVencimento(dataParcela[0].vencimento)
            setParcela(dataParcela[0].numero_parcela || 0)
            setDescricaoParcela(dataParcela[0].nome)
            setValorParcela(parseFloat(dataParcela[0].valor))
            setObservacaoParcela(dataParcela[0].observacao)

            setForma_Pagamento_id({ value: dataParcela[0].forma_pagamento_id ? dataParcela[0].forma_pagamento_id : -1, label: dataParcela[0].Forma_Pagamento?.nome ? dataParcela[0].Forma_Pagamento.nome : '<Escolha uma forma de pagamento>' })
            setBanco_id({ value: dataParcela[0].banco_id ? dataParcela[0].banco_id : -1, label: dataParcela[0].Banco?.nome ? dataParcela[0].Banco.nome : '<Escolha um banco>' })


            //Lancamento_parcelas
            setQtdParcela(dataCountParcelas || 0)
            setValorPago(parseFloat(dataLanParcelas.total_quitacao ? (dataParcela[0].valor - (dataLanParcelas.total_quitacao)) : dataParcela[0].valor))
            setTotalQuitado(dataLanParcelas.total_quitacao || 0)
            setTotalJuros(parseFloat(dataLanParcelas.total_juros) || 0)
            setTotalMulta(parseFloat(dataLanParcelas.total_multa) || 0)
            setTotalDesconto(parseFloat(dataLanParcelas.total_desconto) || 0)
            setTotalTarifa(parseFloat(dataLanParcelas.total_tarifa) || 0)

            setPago((parseFloat(dataLanParcelas.total_quitacao ? (dataParcela[0].valor - dataLanParcelas.total_quitacao) : dataParcela[0].valor)) == 0 ? true : false)

            FechaTelaEspera()
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const buscaFormaPagamento = async () => {
        try {
            const url = `${rotafechada()}forma_pagamento?order=nome&limit=-1`;
            //console.log("***URL forma de pagamento:", url);
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesFormaPagamentoTemp = [{}];

            data.map((item) => {
                opcoesFormaPagamentoTemp.push({ value: item.id, label: item.nome });
            });
            setOptionFormaPagamento(opcoesFormaPagamentoTemp);

            return opcoesFormaPagamentoTemp;
        } catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const buscaBanco = async () => {
        try {
            const url = `${rotafechada()}banco?order=nome&limit=-1&ativo=S`;
            //console.log("***URL forma de pagamento:", url);
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesBancoTemp = [{}];

            data.map((item) => {
                opcoesBancoTemp.push({ value: item.id, label: item.nome });
            });
            setOptionBanco(opcoesBancoTemp);

            return opcoesBancoTemp;
        } catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const handleClickGravar = async (e) => {

        let msg = ''
        if (valorParcela === '' || valorParcela <= 0)
            msg = msg + 'Valor, '

        if (descricaoParcela === '' || descricaoParcela === null)
            msg = msg + 'Descrição, '

        if (vencimento === '' || vencimento === null)
            msg = msg + 'Vencimento, '

        if (data_Competencia === '' || data_Competencia === null)
            msg = msg + 'Data competencia, '

        else
            if (msg.length > 0)
                MensagemAlerta(`Campo(s) obrigatório(s): ${msg}`)
            else {
                //alert('teste de edição')
                try {
                    MostraTelaEspera('Gravando o conta...');
                    let retorno;

                    const dados = {
                        empresa_id: sessaolocal.empresa_id || null,
                        conta_id,
                        forma_pagamento_id: forma_pagamento_id?.value > 0 ? forma_pagamento_id.value : null,
                        banco_id: banco_id?.value > 0 ? banco_id.value : null,
                        nome: descricaoParcela,
                        data_competencia: data_Competencia,
                        vencimento: vencimento,
                        previsao: vencimento,
                        valor: valorParcela,
                        saldo: valorParcela,
                        //agendado: agendado ? 'S' : 'N',
                        observacao: observacaoParcela,
                        //numero_parcela: 1,
                        //ativo: 'S'
                    }
                    //console.log(dados);

                    retorno = await api.put(`${rotafechada()}parcelas_conta/${codigoAbrir}`, dados);


                    MensagemAviso('Alterado com sucesso!');
                    FechaTelaEspera();
                    handleCancel()
                    limpaImputs()
                }
                catch (error) {
                    FechaTelaEspera();
                    const msg = error.response?.data?.msg || error;
                    MensagemErro(`Erro: ${msg}`);
                }
            }

    }

    const limpaImputs = () => {

        setCodigoAbrir('');
        setJuros(0);
        setMulta(0);
        setDesconto(0);

    }

    useEffect(() => {
        if (open === true) {
            buscaDados();
            buscaFormaPagamento();
            buscaBanco();
        }

    }, [open])

    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    handleCancel()
                    limpaImputs()
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen
            >
                <DialogTitle id="alert-dialog-title" sx={{ padding: '5px' }}>{/*HEADER*/}
                    <MDBox width="100%">
                        <MDBox display="flex" justifyContent="space-between">
                            <MDBox display="flex" justifyContent="space-start" flexDirection='column'>
                                <MDTypography fontWeight='bold' ml={5} mt={1} variant='h4'>{`${title} ${parcela}/${qtdParcela} ${tipo == 'R' ? '- Receita' : '- Despesa'}`}</MDTypography>
                                {/* <MDTypography fontWeight='bold' ml={5} mt={1} variant='caption'>{`Despesas`}</MDTypography> */}
                            </MDBox>

                            <Tooltip title="Fechar (Esc)">
                                <MDButton size="large" sx={{ padding: 0 }} onClick={() => {
                                    handleCancel()
                                    limpaImputs()
                                }}>
                                    <Icon>close</Icon>
                                </MDButton>

                            </Tooltip>
                        </MDBox>
                    </MDBox>
                </DialogTitle>


                <DialogContent dividers sx={{ backgroundColor: "#f1f4f9", borderColor: '#7bb5e4' }}>
                    <DialogContentText id="alert-dialog-description">

                        <MDBox ml={2} mr={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* CONTA */}
                            <Grid container spacing={2} mt={1} pl={2} pr={2}>

                                <Grid item xs={12} sm={12}> {/* TITULO*/}
                                    <MDTypography variant='subtitle2' fontWeight="bold">{tipo == 'D' ? 'Informações da conta' : 'Informações da conta'}</MDTypography>
                                    <Divider
                                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                                        style={{
                                            marginTop: 5,
                                            marginBottom: 10,
                                            border: "none",
                                            height: 2,
                                            margin: 0,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={2}>{/*PESSOA*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{tipo == 'D' ? 'Fornecedor' : 'Cliente'}</MDTypography>
                                        <MDTypography variant='caption' >
                                            <Link to={`/pessoas/pessoa/${pessoa_id}`} style={{ textDecoration: "none", color: 'info' }}>{pessoa}</Link>
                                        </MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* CODIGO_REFERENCIA */}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Código de referência'}</MDTypography>
                                        <MDTypography variant='caption' >{codigoReferencia}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* DATA_COMPETENCIA */}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Data de competência'}</MDTypography>
                                        <MDTypography variant='caption' >{dataCompetencia}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2} >{/*CATEGORIA*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Categoria'}</MDTypography>
                                        <MDTypography variant='caption' >{categoria}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/*CENTRO_CUSTO*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Centro de custo'}</MDTypography>
                                        <MDTypography variant='caption' >{centro_custo}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* VALOR_CONTA */}
                                    <MDBox width='100%' display='flex' alignText='flex-end' alignItems='flex-end' justifyContent={'flex-end'} flexDirection='row'>
                                        <MDTypography variant='caption' fontWeight="bold">{'Valor atual  '}</MDTypography>
                                        <Tooltip
                                            title="O valor total da conta que considera o somatório das parcelas"
                                            placement="top"
                                            disableInteractive
                                        >
                                            <HelpIcon color="info" />
                                        </Tooltip>
                                    </MDBox>
                                    <MDBox width='100%' display='flex' alignText='flex-end' alignItems='flex-end' flexDirection='column'>
                                        <MDTypography fontWeight="bold" >{formataValor(valorConta)}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/*DESCRIÇÃO_CONTA*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Descrição da conta'}</MDTypography>
                                        <MDTypography variant='caption' >{descricaoConta}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/*OBSERVAÇÃO_CONTA*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Observação da conta'}</MDTypography>
                                        <MDTypography variant='caption' >{observacaoConta}</MDTypography>
                                    </MDBox>
                                </Grid>

                            </Grid>

                        </MDBox>

                        <MDBox ml={2} mr={2} mt={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* PARCELA */}
                            <Grid container spacing={2} mt={1} pl={2} pr={2}>

                                <Grid item xs={12} sm={12}> {/* TITULO*/}
                                    <MDTypography variant='subtitle2' fontWeight="bold">{`Detalhes da parcela`}</MDTypography>
                                    {/* <MDTypography variant='caption' fontWeight="bold">{tipo == 'D' ? 'Despesa' : 'Receita'}</MDTypography> */}
                                    <Divider
                                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                                        style={{
                                            marginTop: 5,
                                            marginBottom: 10,
                                            border: "none",
                                            height: 2,
                                            margin: 0,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}> {/* DESCRIÇÃO */}
                                    <FormField
                                        required
                                        type="text"
                                        label="Descrição"
                                        value={descricaoParcela}
                                        onChange={(e) => setDescricaoParcela(e.target.value)}
                                        helperText={descricaoParcela?.length > 0 ? false :
                                            <>
                                                <CancelIcon color={'error'} />
                                                <Typography pl={0.5} fontWeight="small" variant="caption" color={'error'}>Campo obrigatório</Typography>
                                            </>
                                        }
                                    />
                                    {/* <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Descrição'}</MDTypography>
                                        <MDTypography variant='caption' >{descricaoParcela}</MDTypography>
                                    </MDBox> */}
                                </Grid>

                                <Grid item xs={12} sm={2}>{/*DATA_COMPETENCIA*/}
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                        <DesktopDatePicker
                                            //disabled={totalQuitado > 0 ? totalQuitado == valorParcela ? true : true : false}
                                            required={true}
                                            label='Data Competencia'
                                            inputFormat="dd/MM/yyyy"
                                            value={data_Competencia}
                                            onChange={(e) => {
                                                setData_Competencia(e);
                                            }}

                                            renderInput={(params) => <FormField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={12} sm={2}>{/*VENCIMENTO*/}
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                        <DesktopDatePicker
                                            disabled={totalQuitado > 0 ? totalQuitado == valorParcela ? true : true : false}
                                            required={true}
                                            label='Vencimento'
                                            inputFormat="dd/MM/yyyy"
                                            value={vencimento}
                                            onChange={(e) => {
                                                setVencimento(e);
                                            }}

                                            renderInput={(params) => <FormField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* VALOR_ORIGINAL_PARCELA */}
                                    <CurrencyInput
                                        disabled={manual == 'N' ? true : totalQuitado !== 0 ? true : false}
                                        required={true}
                                        label="Valor"
                                        value={valorParcela}
                                        onChange={(e) => {
                                            setValorParcela(parseFloat(e.target.value.replace(/\./g, '').replace(',', '.')));
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment sx={{ width: '5%' }} position="start">R$</InputAdornment>,
                                            disabled: manual == 'N' ? true : totalQuitado !== 0 ? true : false
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* SITUAÇÃO */}
                                    <MDBox width='100%' display="flex"
                                        alignText={'flex-end'}
                                        justifyContent="flex-end" flexDirection='row'>
                                        <MDBox
                                            display="flex"
                                            alignText={'center'}
                                            width="100px"
                                            justifyContent={'center'}
                                            //sx={{ background: `${totalQuitado > 0 ? totalQuitado == valorParcela ? '#bce2be' : '#aecef7' : '#ffd59f'}` }}
                                            sx={{ background: `${situacao == 'PAGO' ? '#bce2be' : situacao == 'PAGO PARCIAL' ? '#aecef7' : '#ffd59f'}` }}
                                            borderRadius="sm"
                                            p={0.5}
                                        >
                                            <MDTypography fontWeight="medium"
                                                variant="caption"
                                                textTransform="capitalize"
                                            //color={totalQuitado > 0 ? totalQuitado == valorParcela ? 'success' : 'info' : 'warning'}
                                            >
                                                {situacao}
                                                {/* {totalQuitado > 0 ? totalQuitado == valorParcela ? 'PAGO' : 'PAGO PARCIAL' : 'EM ABERTO'} */}
                                            </MDTypography>
                                        </MDBox>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={6}> {/* OBSERVAÇÃO_PARCELA */}
                                    <FormField
                                        type="text"
                                        label="Observação"
                                        value={observacaoParcela}
                                        onChange={(e) => setObservacaoParcela(e.target.value)}
                                    />
                                    {/* <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Observação'}</MDTypography>
                                        <MDTypography variant='caption' >{observacaoParcela}</MDTypography>
                                    </MDBox> */}
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* FORMA_PAGAMENTO */}
                                    <AutoCompleteStyled
                                        freeSolo
                                        forcePopupIcon={true}
                                        options={optionFormaPagamento}
                                        getOptionLabel={(option) => option.label || ""}
                                        renderInput={(params) => <FormField {...params} label="Forma de pagamento" />}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                        value={forma_pagamento_id}
                                        onChange={(event, newValue) => {
                                            setForma_Pagamento_id(newValue);
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* BANCO_CAIXA */}
                                    <AutoCompleteStyled
                                        options={optionBanco}
                                        getOptionLabel={(option) => option.label || ""}
                                        renderInput={(params) => <FormField {...params} label="Conta de Pagamento:" />}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                        value={banco_id}
                                        onChange={(event, newValue) => {
                                            setBanco_id(newValue);
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* VALOR_PARCELA */}
                                    <MDBox width='100%' display='flex' alignText='flex-end' alignItems='flex-end' justifyContent={'flex-end'} flexDirection='row'>
                                        <MDTypography variant='caption' fontWeight="bold">{'Valor atual  '}</MDTypography>
                                        <Tooltip
                                            title="O valor considera juros, multa, tarifas e descontos caso tenham
                                             sido informados no momento da baixa ou lançados avulso."
                                            placement="top"
                                            disableInteractive
                                        >
                                            <HelpIcon color="info" />
                                        </Tooltip>
                                    </MDBox>
                                    <MDBox width='100%' display='flex' alignText='flex-end' alignItems='flex-end' flexDirection='column'>
                                        <MDTypography fontWeight="bold" >{formataValor(valorParcela + (totalJuros * -1) + (totalMulta * -1) - totalTarifa - totalDesconto)}</MDTypography> {/*Valor da parcela + juros + multa - desconto*/}
                                    </MDBox>
                                </Grid>

                            </Grid>

                        </MDBox>


                    </DialogContentText>
                </DialogContent>

                <DialogActions sx={{ padding: '7px', margin: 0.5 }}> {/*FOOTER*/}
                    <MDBox width="100%">
                        <MDBox width="100%" display="flex" justifyContent="space-between">
                            <MDButton onClick={() => {
                                handleCancel()
                                limpaImputs()
                            }} variant="gradient" type="button" color="secondary">
                                voltar
                            </MDButton>
                            <MDButton
                                disabled={descricaoParcela?.length > 0 ? false : true}
                                onClick={handleClickGravar}
                                variant="gradient"
                                type="submit"
                                //type="button" 
                                color="info">
                                {'salvar'}
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </DialogActions>
            </Dialog>
        </>
    );
}