
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Icon, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Tabela from "./Tabela";
import Cadastro from "../Cadastro";
import Lancar from "../Lancar";
import DetalheParcela from "../DetalheParcela";
import EditarParcela from "../EditarParcela";
import DetalheConta from "../DetalheConta";
import EditarConta from "../EditarConta";
import MDButton from "components/MDButton";

import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import AuthContext from "hooks/AuthContext";
import DialogoConfirma from 'componentes/DialogoConfirma';
import DialogoConfirmaValidacao from 'componentes/DialogoConfirmaValidacao';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuConfiguracaoModulos from "componentes/MenuConfiguracaoModulos";
import { useNavigate, useParams } from "react-router-dom";
import { startOfWeek, endOfWeek, addMonths, addDays, addWeeks, addYears, endOfMonth, startOfMonth, startOfYear, endOfYear, subMonths, subDays, subWeeks, subYears } from 'date-fns'
import format from 'date-fns/format'
import { ptBR } from "date-fns/locale";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FormField from "componentes/FormField";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DialogoValidaModulo from "componentes/ValidaModulo";
import packageJson from '../../../../../package.json';
import { useTrataRetorno } from "commons/authUtils";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.any,
  index: PropTypes.number,
  value: PropTypes.number,
};

function ContasPagar() {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  let valorinicial = '';
  const trataRetorno = useTrataRetorno();
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();
  if ((id) && (parseInt(id, 10) >= 0)) {
    valorinicial = id;
  }

  const [aba, setAba] = React.useState(0);
  const [codigoCarregado, setCodigoCarregado] = useState(valorinicial);
  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const [dataset, setDataset] = useState([]);
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null, situacao: null });
  const [recarregaTabela, setRecarregaTabela] = useState(false);
  const [enabledBotaoGravar, setEnabledBotaoGravar] = useState(true);
  const [openFiltrosAvancados, setOpenFiltrosAvancados] = useState(false);
  const [CadastroOpen, setCadastroOpen] = useState({ visible: false, nome: '' });
  const [LancarOpen, setLancarOpen] = useState({ visible: false, nome: '' });
  const [detalheParcelaOpen, setDetalheParcelaOpen] = useState({ visible: false, nome: '' });
  const [editarParcelaOpen, setEditarParcelaOpen] = useState({ visible: false, nome: '' });
  const [detalheContaOpen, setDetalheContaOpen] = useState({ visible: false, nome: '' });
  const [editarContaOpen, setEditarContaOpen] = useState({ visible: false, nome: '' });

  const [openMenuConfiguracao, setOpenMenuConfiguracao] = useState(false);
  const [currentTargetBotaoMenu, setCurrentTargetBotaoMenu] = useState(null);
  const [openMensagemModuloAdicional, setOpenMensagemModuloAdicional] = useState(false);

  /*****************Menu Item*******************/
  const [openDetalhe, setOpenDetalhe] = useState(false);

  const [anchorElFiltro, setAnchorElFiltro] = useState(null);
  const [openDetalheFiltro, setOpenDetalheFiltro] = useState(false);
  const [openDetalheFiltroData, setOpenDetalheFiltroData] = useState(false);
  //const [labelFiltro, setLabelFiltro] = useState(format(endOfMonth(new Date()), "MMMM 'de' yyyy", { locale: ptBR }));
  const [dataInicial, setDataInicial] = useState(null);
  const [dataFinal, setDataFinal] = useState(null);
  //const [dataInicialFiltro, setDataInicialFiltro] = useState(format(startOfMonth(new Date()), "yyyy-MM-dd 00:00:00", { locale: ptBR }));
  //let dataInicialFiltro = (format(startOfMonth(new Date()), "yyyy-MM-dd 00:00:00", { locale: ptBR }));
  //const [dataFinalFiltro, setDataFinalFiltro] = useState(format(endOfMonth(new Date()), "yyyy-MM-dd 23:59:59", { locale: ptBR }));
  //let dataFinalFiltro = (format(endOfMonth(new Date()), "yyyy-MM-dd 23:59:59", { locale: ptBR }));
  const [filtro, setFiltro] = useState(
    {
      labelOriginal: endOfMonth(new Date()),
      labelFormatada: format(endOfMonth(new Date()), "MMMM 'de' yyyy", { locale: ptBR }),

      dataInicialOriginal: startOfMonth(new Date()),
      dataInicialFormatada: (format(startOfMonth(new Date()), "yyyy-MM-dd 00:00:00", { locale: ptBR })),

      dataFinalOriginal: endOfMonth(new Date()),
      dataFinalFormatada: (format(endOfMonth(new Date()), "yyyy-MM-dd 23:59:59", { locale: ptBR })),
    }
  );
  const [backNext, setBackNext] = useState(true);
  const [periodo, setPeriodo] = useState('mes');

  /*****************Menu Item*******************/
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClickMenu = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(true)
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpen(false)
  };

  const baseUrl = `${rotafechada()}parcelas_conta`;
  const baseCaption = 'Contas a pagar';

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${baseUrl}/permissao/${operacao}`);
      return true;
    }
    catch (error) {
      trataRetorno(error?.response?.data?.erro);
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }

  const handleClickNovo = async () => {
    if (await validaRotaPermissao('inserir') === false) {
      return;
    }
    setCodigoCarregado('');
    setAba(1)
  }

  const handleClickNovo2 = async () => {
    if (await validaRotaPermissao('inserir') === false) {
      return;
    }
    setCadastroOpen({ ...CadastroOpen, visible: true, nome: 'Nova Despesa' });
    setCodigoCarregado('');
    setAba(2)
  }

  const handleClickInformarPagamento = (id) => {
    setCodigoCarregado(id);
    abrirRegistro(id);
    setLancarOpen({ ...LancarOpen, visible: true, nome: 'Informar pagamento' });
    setAba(3)
  }

  const handleClickDetalheParcela = (id) => {
    setCodigoCarregado(id);
    setDetalheParcelaOpen({ ...detalheParcelaOpen, visible: true, nome: 'Detalhes da parcela ' });
    setAba(4)

  }

  const handleClickEditarParcela = (id) => {
    setCodigoCarregado(id);
    setEditarParcelaOpen({ ...editarParcelaOpen, visible: true, nome: 'Editar parcela ' });
    setAba(5)
  }

  const handleClickDetalheConta = async (id) => {
    setCodigoCarregado(id);
    setDetalheContaOpen({ ...detalheContaOpen, visible: true, nome: 'Detalhes da conta ' });
    setAba(6)
  }

  const handleClickEditarConta = (id) => {
    setCodigoCarregado(id);
    setEditarContaOpen({ ...editarContaOpen, visible: true, nome: 'Editar conta ' });
    setAba(7)
  }

  const handleClickAbrir = (id) => {
    setCodigoCarregado(id);
    abrirRegistro(id);
  }

  const abrirRegistro = async (id) => {
    if (!parseInt(id, 10) > 0)
      return;

    try {
      setCodigoCarregado(id);
      setLancarOpen({ ...LancarOpen, visible: true, nome: 'Informar pagamento' });
      setAba(3);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleExcluir = async () => {
    setDialogoOpen({ ...dialogoOpen, visible: false });//fecha dialogo de exclusão

    try {
      await api.delete(`${baseUrl}/full/${dialogoOpen.id || 0}`);
      MensagemAviso('Excluído com sucesso!');
      setCodigoCarregado('');
      setRecarregaTabela(!recarregaTabela);
      setAba(0)
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickExcluir = async (id, situacao) => {

    if (!(await validaRotaPermissao('excluir') === true)) {
      return;
    }

    setDialogoOpen({ ...dialogoOpen, visible: true, id, situacao });//abre dialogo de exclusão
  }

  const handleCloseFiltro = () => {
    setAnchorElFiltro(null);
    setOpenDetalheFiltro(false)
  };

  const handleCloseFiltroData = () => {
    setAnchorElFiltro(null);
    setOpenDetalheFiltroData(false)
  };

  const handleClickProximo = () => {

    let filtroTemp = filtro

    if (periodo === 'hoje') {
      setFiltro(
        {
          labelOriginal: (addDays(filtroTemp.dataInicialOriginal, 1)),
          labelFormatada: format(addDays(filtroTemp.dataInicialOriginal, 1), "dd 'de' MMMM yyyy", { locale: ptBR }),

          dataInicialOriginal: addDays(filtroTemp.dataInicialOriginal, 1),
          dataInicialFormatada: format(addDays(filtroTemp.dataInicialOriginal, 1), "yyyy-MM-dd 00:00:00", { locale: ptBR }),

          dataFinalOriginal: addDays(filtroTemp.dataFinalOriginal, 1),
          dataFinalFormatada: format(addDays(filtroTemp.dataFinalOriginal, 1), "yyyy-MM-dd 23:59:59", { locale: ptBR }),
        }
      )
    }
    if (periodo === 'semana') {
      setFiltro(
        {
          labelOriginal: `${format(addWeeks(filtroTemp.dataInicialOriginal, 1), "dd/MM/yyyy")} até ${format(addWeeks(filtroTemp.dataFinalOriginal, 1), "dd/MM/yyyy")}`,
          labelFormatada: `${format(addWeeks(filtroTemp.dataInicialOriginal, 1), "dd/MM/yyyy")} até ${format(addWeeks(filtroTemp.dataFinalOriginal, 1), "dd/MM/yyyy")}`,

          dataInicialOriginal: addWeeks(filtroTemp.dataInicialOriginal, 1),
          dataInicialFormatada: format(addWeeks(filtroTemp.dataInicialOriginal, 1), "yyyy-MM-dd 00:00:00", { locale: ptBR }),

          dataFinalOriginal: addWeeks(filtroTemp.dataFinalOriginal, 1),
          dataFinalFormatada: format(addWeeks(filtroTemp.dataFinalOriginal, 1), "yyyy-MM-dd 23:59:59", { locale: ptBR }),
        }
      )
    }
    if (periodo === 'mes') {
      setFiltro(
        {
          labelOriginal: endOfMonth(addMonths(filtroTemp.dataInicialOriginal, 1)),
          labelFormatada: format(endOfMonth(addMonths(filtroTemp.dataInicialOriginal, 1)), "MMMM 'de' yyyy", { locale: ptBR }),

          dataInicialOriginal: addMonths(filtroTemp.dataInicialOriginal, 1),
          dataInicialFormatada: format(addMonths(filtroTemp.dataInicialOriginal, 1), "yyyy-MM-dd 00:00:00", { locale: ptBR }),

          dataFinalOriginal: endOfMonth(addMonths(filtroTemp.dataFinalOriginal, 1)),
          dataFinalFormatada: format(endOfMonth(addMonths(filtroTemp.dataFinalOriginal, 1)), "yyyy-MM-dd 23:59:59", { locale: ptBR }),
        }
      )
    }
    if (periodo === 'ano') {
      setFiltro(
        {
          labelOriginal: endOfYear(addYears(filtroTemp.dataInicialOriginal, 1)),
          labelFormatada: format(endOfYear(addYears(filtroTemp.dataInicialOriginal, 1)), "yyyy", { locale: ptBR }),

          dataInicialOriginal: addYears(filtroTemp.dataInicialOriginal, 1),
          dataInicialFormatada: format(addYears(filtroTemp.dataInicialOriginal, 1), "yyyy-MM-dd 00:00:00", { locale: ptBR }),

          dataFinalOriginal: addYears(filtroTemp.dataFinalOriginal, 1),
          dataFinalFormatada: format(addYears(filtroTemp.dataFinalOriginal, 1), "yyyy-MM-dd 23:59:59", { locale: ptBR }),
        }
      )
    }

  }

  const handleClickAnterior = () => {

    let filtroTemp = filtro
    if (periodo === 'hoje') {
      setFiltro(
        {
          labelOriginal: (subDays(filtroTemp.dataInicialOriginal, 1)),
          labelFormatada: format(subDays(filtroTemp.dataInicialOriginal, 1), "dd 'de' MMMM yyyy", { locale: ptBR }),

          dataInicialOriginal: subDays(filtroTemp.dataInicialOriginal, 1),
          dataInicialFormatada: format(subDays(filtroTemp.dataInicialOriginal, 1), "yyyy-MM-dd 00:00:00", { locale: ptBR }),

          dataFinalOriginal: subDays(filtroTemp.dataFinalOriginal, 1),
          dataFinalFormatada: format(subDays(filtroTemp.dataFinalOriginal, 1), "yyyy-MM-dd 23:59:59", { locale: ptBR }),
        }
      )
    }
    if (periodo === 'semana') {
      setFiltro(
        {
          labelOriginal: `${format(subWeeks(filtroTemp.dataInicialOriginal, 1), "dd/MM/yyyy")} até ${format(subWeeks(filtroTemp.dataFinalOriginal, 1), "dd/MM/yyyy")}`,
          labelFormatada: `${format(subWeeks(filtroTemp.dataInicialOriginal, 1), "dd/MM/yyyy")} até ${format(subWeeks(filtroTemp.dataFinalOriginal, 1), "dd/MM/yyyy")}`,

          dataInicialOriginal: subWeeks(filtroTemp.dataInicialOriginal, 1),
          dataInicialFormatada: format(subWeeks(filtroTemp.dataInicialOriginal, 1), "yyyy-MM-dd 00:00:00", { locale: ptBR }),

          dataFinalOriginal: subWeeks(filtroTemp.dataFinalOriginal, 1),
          dataFinalFormatada: format(subWeeks(filtroTemp.dataFinalOriginal, 1), "yyyy-MM-dd 23:59:59", { locale: ptBR }),
        }
      )
    }
    if (periodo === 'mes') {
      setFiltro(
        {
          labelOriginal: endOfMonth(subMonths(filtroTemp.dataInicialOriginal, 1)),
          labelFormatada: format(endOfMonth(subMonths(filtroTemp.dataInicialOriginal, 1)), "MMMM 'de' yyyy", { locale: ptBR }),

          dataInicialOriginal: subMonths(filtroTemp.dataInicialOriginal, 1),
          dataInicialFormatada: format(subMonths(filtroTemp.dataInicialOriginal, 1), "yyyy-MM-dd 00:00:00", { locale: ptBR }),

          dataFinalOriginal: endOfMonth(subMonths(filtroTemp.dataFinalOriginal, 1)),
          dataFinalFormatada: format(endOfMonth(subMonths(filtroTemp.dataFinalOriginal, 1)), "yyyy-MM-dd 23:59:59", { locale: ptBR }),
        }
      )
    }
    if (periodo === 'ano') {
      setFiltro(
        {
          labelOriginal: endOfYear(subYears(filtroTemp.dataInicialOriginal, 1)),
          labelFormatada: format(endOfYear(subYears(filtroTemp.dataInicialOriginal, 1)), "yyyy", { locale: ptBR }),

          dataInicialOriginal: subYears(filtroTemp.dataInicialOriginal, 1),
          dataInicialFormatada: format(subYears(filtroTemp.dataInicialOriginal, 1), "yyyy-MM-dd 00:00:00", { locale: ptBR }),

          dataFinalOriginal: subYears(filtroTemp.dataFinalOriginal, 1),
          dataFinalFormatada: format(subYears(filtroTemp.dataFinalOriginal, 1), "yyyy-MM-dd 23:59:59", { locale: ptBR }),
        }
      )
    }

  }

  const validaModulo = async () => {

    MostraTelaEspera('Validando acesso ao módulo');
    try {
      let url = `${rotafechada()}assinatura_modulos/validar?nome=${'FINANCEIRO'}`;
      await api.get(url);
    }
    catch (error) {
      setOpenMensagemModuloAdicional(true)
    }
    FechaTelaEspera();
  }

  useEffect(async () => {
    setRecarregaTabela(!recarregaTabela)
  }, [filtro])

  useEffect(async () => {

    if (aba === 1) {
      if (parseInt(codigoCarregado, 10) > 0) {
        if (await validaRotaPermissao('editar') === false) {
          //alem da mensagem, desativo o botao de gravar
          setEnabledBotaoGravar(false);
        }
      }
      else {
        if (await validaRotaPermissao('inserir') === false) {
          //alem da mensagem, desativo o botao de gravar
          setEnabledBotaoGravar(false);
        }
      }

    }

  }, [aba])

  useEffect(() => {
    validaModulo()
  }, [])

  return (
    <>
      <DialogoValidaModulo
        open={openMensagemModuloAdicional}
        title={'Módulo adicional'}
        message={'Módulo Financeiro não contratado, para mais informações entre em contato com nossa equipe de suporte!'}
        handleCancel={() => {
          setOpenMensagemModuloAdicional(false);
          navigate('/desktop');
        }}
        handleConfirm={() => {
          setOpenMensagemModuloAdicional(false);
          navigate('/desktop');
          window.open(packageJson?.urlsuporte.replace('text', 'text=Tenho interesse em contratar o módulo Financeiro para o VidrosWeb📌 '))
        }
        }
      />

      <Menu //Filtro Período personalizado
        anchorEl={anchorElFiltro}
        open={openDetalheFiltroData}
        onClose={handleCloseFiltroData}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >

        <Card>
          <Box paddingBottom={1} paddingTop={1}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DesktopDatePicker
                required={true}
                //disabled={desabilitaData}
                label='Data Inicial'
                inputFormat="dd/MM/yyyy"
                value={dataInicial}
                onChange={(e) => setDataInicial(e)}
                renderInput={(params) => <FormField {...params} required />}
              />
            </LocalizationProvider>
          </Box>
          <Box>
            até
          </Box>
          <Box paddingBottom={1} paddingTop={1}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <DesktopDatePicker
                required={true}
                //disabled={desabilitaData}
                label='Data Final'
                inputFormat="dd/MM/yyyy"
                value={dataFinal}
                onChange={(e) => setDataFinal(e)}
                renderInput={(params) => <FormField {...params} required />}
              />
            </LocalizationProvider>
          </Box>
          <MDButton
            variant="gradient"
            color={'info'}
            onClick={() => {
              setBackNext(false);
              setFiltro(
                {
                  labelOriginal: `${format(dataInicial, "dd 'de' MMMM yyyy", { locale: ptBR })} até ${format(dataFinal, "dd 'de' MMMM yyyy", { locale: ptBR })}`,
                  labelFormatada: `${format(dataInicial, "dd 'de' MMMM yyyy", { locale: ptBR })} até ${format(dataFinal, "dd 'de' MMMM yyyy", { locale: ptBR })}`,

                  dataInicialOriginal: dataInicial,
                  dataInicialFormatada: (format(dataInicial, "yyyy-MM-dd 00:00:00")),

                  dataFinalOriginal: dataFinal,
                  dataFinalFormatada: (format(dataFinal, "yyyy-MM-dd 23:59:59"))
                }
              )
              setOpenDetalheFiltroData(false)

            }}
          >Aplicar filtro </MDButton>
        </Card>

      </Menu>

      <Menu //Filtro
        anchorEl={anchorElFiltro}
        open={openDetalheFiltro}
        onClose={handleCloseFiltro}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >

        <MenuItem //Hoje
          dense={true}
          onClick={() => {
            setBackNext(true);
            setPeriodo('hoje')
            setFiltro(
              {
                labelOriginal: new Date(),
                labelFormatada: format(new Date(), "dd 'de' MMMM yyyy", { locale: ptBR }),

                dataInicialOriginal: new Date(),
                dataInicialFormatada: (format(new Date(), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: new Date(),
                dataFinalFormatada: (format(new Date(), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltro(false)
          }}>
          Hoje
        </MenuItem>

        <MenuItem //Semana
          dense={true}
          onClick={() => {
            setBackNext(true);
            setPeriodo('semana')
            setFiltro(
              {
                labelOriginal: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,
                labelFormatada: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,

                dataInicialOriginal: startOfWeek(new Date()),
                dataInicialFormatada: (format(startOfWeek(new Date()), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfWeek(new Date()),
                dataFinalFormatada: (format(endOfWeek(new Date()), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltro(false)
          }}>
          Essa semana
        </MenuItem>

        <MenuItem //Mês
          dense={true}
          onClick={() => {
            setBackNext(true);
            setPeriodo('mes')
            setFiltro(
              {
                labelOriginal: endOfMonth(new Date()),
                labelFormatada: format(endOfMonth(new Date()), "MMMM 'de' yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfMonth(new Date()),
                dataInicialFormatada: (format(startOfMonth(new Date()), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfMonth(new Date()),
                dataFinalFormatada: (format(endOfMonth(new Date()), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltro(false)
          }}>
          Este mês
        </MenuItem>

        <MenuItem //Ano
          dense={true}
          onClick={() => {
            setBackNext(true);
            setPeriodo('ano')
            setFiltro(
              {
                labelOriginal: endOfYear(new Date()),
                labelFormatada: format(endOfYear(new Date()), "yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfYear(new Date()),
                dataInicialFormatada: (format(startOfYear(new Date()), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfYear(new Date()),
                dataFinalFormatada: (format(endOfYear(new Date()), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltro(false)
          }}>
          Este ano
        </MenuItem>

        <MenuItem
          dense={true}
          onClick={() => {
            setBackNext(false);
            setOpenDetalheFiltro(false)
            setOpenDetalheFiltroData(true)
          }}
        >
          Período personalizado
        </MenuItem>

      </Menu>

      <MenuConfiguracaoModulos
        open={openMenuConfiguracao}
        setOpen={setOpenMenuConfiguracao}
        nomeModulo='CONTAS'
        currentTarget={currentTargetBotaoMenu}
      />
      <DashboardLayout>
        <DashboardNavbar />

        {/* <DialogoConfirma
          open={dialogoOpen.visible}
          title={'Confirma Exclusão'}
          color={'error'}
          message={'Certeza que deseja excluir?, a exclusão desta parcela afetará o valor da conta e caso tenha lançamentos serão excluidos. Lembre-se esta ação não poderá ser desfeita.'}
          handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
          handleConfirm={handleExcluir}
        /> */}
        <DialogoConfirmaValidacao
          open={dialogoOpen.visible}
          title={'Confirma Exclusão'}
          color={'error'}
          message={'Certeza que deseja excluir? '}
          validacao={'Concordo com a exclusão desta parcela e atualização do saldo da conta.'}
          handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
          handleConfirm={handleExcluir}
        />

        <Cadastro
          open={CadastroOpen.visible}
          title={CadastroOpen.nome}
          message={'Mensagem'}
          tipo={'D'}
          setCodigoAbrir={setCodigoCarregado}
          codigoAbrir={codigoCarregado}
          handleCancel={() => {
            setCadastroOpen({ ...CadastroOpen, visible: false, nome: '' })
            setAba(0);
          }}
        />

        <TabPanel value={aba} index={3}>
          <Lancar
            open={LancarOpen.visible}
            title={LancarOpen.nome}
            message={'Mensagem'}
            tipo={'D'}
            setCodigoAbrir={setCodigoCarregado}
            codigoAbrir={codigoCarregado}
            setRecarregaTabela={setRecarregaTabela}
            recarregaTabela={recarregaTabela}
            handleCancel={() => {
              setLancarOpen({ ...LancarOpen, visible: false, nome: '' })
              setAba(0);
            }}
          />

        </TabPanel>

        <TabPanel value={aba} index={4}>
          {/* Ver detalhes da parcela */}
          <DetalheParcela
            open={detalheParcelaOpen.visible}
            title={detalheParcelaOpen.nome}
            message={'Mensagem'}
            tipo={'D'}
            setRecarregaTabela={setRecarregaTabela}
            recarregaTabela={recarregaTabela}
            setCodigoAbrir={setCodigoCarregado}
            codigoAbrir={codigoCarregado}
            handleCancel={() => {
              setDetalheParcelaOpen({ ...detalheParcelaOpen, visible: false, nome: '' })
              setAba(0);
            }}
          />

        </TabPanel>

        <TabPanel value={aba} index={5}>
          {/* Editar parcela */}
          <EditarParcela
            open={editarParcelaOpen.visible}
            title={editarParcelaOpen.nome}
            message={'Mensagem'}
            tipo={'D'}
            setCodigoAbrir={setCodigoCarregado}
            codigoAbrir={codigoCarregado}
            handleCancel={() => {
              setEditarParcelaOpen({ ...editarParcelaOpen, visible: false, nome: '' })
              setAba(0);
            }}
          />

        </TabPanel>

        <TabPanel value={aba} index={6}>
          {/* Ver detalhes da conta */}
          <DetalheConta
            open={detalheContaOpen.visible}
            title={detalheContaOpen.nome}
            message={'Mensagem'}
            tipo={'D'}
            setCodigoAbrir={setCodigoCarregado}
            codigoAbrir={codigoCarregado}
            handleCancel={() => {
              setDetalheContaOpen({ ...detalheContaOpen, visible: false, nome: '' })
              setAba(0);
            }}
            handleClickExcluir={handleClickExcluir}
            handleClickInformarPagamento={handleClickInformarPagamento}
            handleClickDetalheParcela={handleClickDetalheParcela}
            handleClickEditarParcela={handleClickEditarParcela}
            handleClickEditarConta={handleClickEditarConta}
            handleClickDetalheConta={handleClickDetalheConta}
          />

        </TabPanel>

        <TabPanel value={aba} index={7}>
          {/* Editar conta */}
          <EditarConta
            open={editarContaOpen.visible}
            title={editarContaOpen.nome}
            message={'Mensagem'}
            tipo={'D'}
            setCodigoAbrir={setCodigoCarregado}
            codigoAbrir={codigoCarregado}
            handleCancel={() => {
              setEditarContaOpen({ ...editarContaOpen, visible: false, nome: '' })
              setAba(0);
            }}
          />

        </TabPanel>

        <MDBox mt={5} mb={9}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={12}>
              <MDBox mt={0} mb={0} textAlign="center">
                <MDBox mb={1}>
                  <MDTypography variant="h3" fontWeight="bold">
                    {`${baseCaption}`}
                  </MDTypography>
                </MDBox>
                {/* Botao de Configuracoes (Parametros e Permissoes) */}
                <MDBox width="100%" display="flex" justifyContent="flex-end">
                  <Tooltip title="Configurações e Permissões" placement="top">
                    <IconButton
                      onClick={(event) => {
                        setCurrentTargetBotaoMenu(event.currentTarget);
                        setOpenMenuConfiguracao(true);
                      }
                      }
                    >
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>

              <Card>
                <TabPanel value={aba} index={0}>

                  <MDBox width="100%" display="flex" justifyContent="space-between" flexWrap={isSmallScreen ? "wrap" : "nowrap"}>
                    
                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='row'>{/*Filtro */}
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpenFiltrosAvancados(!openFiltrosAvancados)}
                      >
                        {openFiltrosAvancados ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        <Typography>Filtros</Typography>
                      </IconButton>

                    </MDBox>

                    <MDBox width='100%' display='flex' alignItems='center' flexDirection='column'> {/* Período */}
                      <MDTypography variant='subtitle2' textTransform="capitalize" fontWeight='bold'>{`Período`}</MDTypography>
                      <Box justifyContent='center' width='100%' display='flex' alignItems='center' flexDirection='row'>

                        {backNext === true &&
                          <Tooltip title="Anterior" placement="top" disableInteractive>
                            <MDButton
                              onClick={handleClickAnterior}
                              variant="contained"
                              color={'info'}
                              sx={{ borderBottomLeftRadius: '3px', borderTopLeftRadius: '3px', borderBottomRightRadius: '0px', borderTopRightRadius: '0px', borderColor: "#c9d3dd" }}
                            >
                              <ArrowBackIosIcon />
                            </MDButton>
                          </Tooltip>
                        }
                        <Box pl={0.2} pr={0.2}>
                          <Tooltip title="Clique aqui para selecionar outro período" placement="top" disableInteractive>
                            <MDButton
                              variant="contained"
                              color={'info'}
                              onClick={(e) => {
                                setAnchorElFiltro(e.currentTarget);
                                setOpenDetalheFiltro(true)
                              }}

                              //sx={{ borderRadius: '0px', borderColor: 'white' }}
                              sx={{ borderRadius: `${backNext == true ? '0px' : '3px'}`, borderColor: 'white' }}
                              endIcon={<KeyboardArrowDownIcon />}
                            //disableElevation
                            >
                              {filtro.labelFormatada}
                            </MDButton>
                          </Tooltip>
                        </Box>
                        {backNext === true &&
                          <Tooltip title="Próximo" placement="top" disableInteractive>
                            <MDButton
                              onClick={handleClickProximo}
                              variant="contained"
                              color={'info'}
                              sx={{ borderBottomRightRadius: '3px', borderTopRightRadius: '3px', borderBottomLeftRadius: '0px', borderTopLeftRadius: '0px' }}>
                              <ArrowForwardIosIcon />
                            </MDButton>
                          </Tooltip>
                        }
                      </Box>
                    </MDBox>

                    <MDBox width='100%' display='flex' alignItems='flex-end' flexDirection='column'>{/*Botão add */}
                      <MDTypography variant='subtitle2' > </MDTypography>
                      < MDButton
                        variant="contained"
                        onClick={handleClickNovo2}
                        color="error"
                        sx={{ borderRadius: '3px' }}
                      >Adicionar despesa
                      </MDButton>
                    </MDBox>
                  </MDBox>

                  <Tabela
                    openFiltrosAvancados={openFiltrosAvancados}
                    setOpenFiltrosAvancados={setOpenFiltrosAvancados}
                    abrirRegistro={handleClickAbrir}
                    handleClickExcluir={handleClickExcluir}
                    recarregaTabela={recarregaTabela}
                    validaRotaPermissao={validaRotaPermissao}
                    handleClickInformarPagamento={handleClickInformarPagamento}
                    handleClickDetalheParcela={handleClickDetalheParcela}
                    handleClickEditarParcela={handleClickEditarParcela}
                    handleClickDetalheConta={handleClickDetalheConta}
                    handleClickEditarConta={handleClickEditarConta}
                    dataInicialFiltro={filtro.dataInicialFormatada}
                    dataFinalFiltro={filtro.dataFinalFormatada}
                  />
                </TabPanel>

              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default ContasPagar;
