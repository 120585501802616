// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

import PageLayout from "examples/LayoutContainers/PageLayout";
import imgLogoLogin from "assets/images/personalizados/logologin.png";
import { Image } from "@mui/icons-material";
import { Box } from "@mui/system";
import MDAvatar from "components/MDAvatar";
import { Avatar } from "@mui/material";


function TermosDeUsoLayout({ coverHeight, image, children }) {
  return (
    <PageLayout>
      <MDBox px={1} width="700" mx="auto">
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} sm={12}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
    </PageLayout>
  );
}

// Setting default props for the LoginLayout
TermosDeUsoLayout.defaultProps = {
  coverHeight: "35vh",
};

// Typechecking props for the TermosDeUsoLayout
TermosDeUsoLayout.propTypes = {
  coverHeight: PropTypes.string,
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default TermosDeUsoLayout;
