import { Checkbox, Collapse, Divider, Grid, Icon, IconButton, Typography } from '@mui/material';
import api from 'commons/api';
import { rotafechada } from 'commons/urlconf';
import TabelaPadrao from 'componentes/TabelaPadrao';
import MDButton from 'components/MDButton';
import MessagesContext from 'hooks/MessagesContext';
import React, { useContext, useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import DialogoOpcoes from 'componentes/DialogoOpcoes';
import DialogoConfirma from 'componentes/DialogoConfirma';


const TabelaProdutos = (props) => {

    const { projetoAtual, recarregaTabela, setRecarregaTabela, abrirRegistro, excluirRegistro } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, FechaTelaEspera, MensagemAviso, MostraTelaEspera } = messageContext;

    const [countAll, setCountAll] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('tipoproduto');
    const [colunasTable, setColunasTable] = React.useState([]);
    const [linhas, setLinhas] = React.useState([]);
    const [openCollapseFiltro, setOpenCollapseFiltro] = useState(false);
    const [nomeProdutoFiltro, setNomeProdutoFiltro] = useState('');
    const [filtroTipoProdutoId, setFiltroTipoProdutoId] = useState({ value: 0, label: 'Todos' })
    const [opcoesTipoProduto, setOpcoesTipoProduto] = useState([{ value: 0, label: 'Todos' }])

    const [checkedItems, setCheckedItems] = useState({}); // estado inicial vazio
    const [opcoes, setOpcoes] = useState({
        open: false,
        items: [{}]
    });
    const [selectAll, setSelectAll] = useState(false);
    const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: [] });


    const carregaTabela = async () => {

        const handleChange = (event) => {
            setCheckedItems({ ...checkedItems, [event.target.name]: event.target.checked }); // Atualiza o estado do checkbox específico que disparou o evento
        }
        try {

            let complementoUrl = '';

            if (page > 0) {
                complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
            }

            if (orderBy) {
                complementoUrl = `${complementoUrl}&order=${orderBy}`;
                if (order) {
                    complementoUrl = `${complementoUrl}&direction=${order}`;
                }
            }

            if (filtroTipoProdutoId?.value !== 0) {
                if (filtroTipoProdutoId?.value > 0) {
                    complementoUrl = `${complementoUrl}&tipo_produto_id=${filtroTipoProdutoId?.value}`;
                }
            }

            if ((nomeProdutoFiltro) && (nomeProdutoFiltro !== '')) {
                complementoUrl = `${complementoUrl}&nomeprodutoreferencia=${nomeProdutoFiltro}`;
            }
            const retorno = await api.get(`${rotafechada()}projeto_produtos?limit=${rowsPerPage}&projeto_id=${projetoAtual}${complementoUrl}`);
            const { data, countAll: countAllretorno } = retorno.data;

            const handleSelectAllChange = (event) => {
                setSelectAll(event.target.checked);
                const newCheckedItems = {};
                data.forEach((item) => {
                    newCheckedItems[item.id] = event.target.checked;
                });
                setCheckedItems(newCheckedItems);
            };

            const dadostemp = [];
            const colunasTabletemp = [
                {
                    id: 'id',
                    numeric: false,
                    disablePadding: true,
                    label: 'id'
                },
                {
                    id: 'tipoproduto',
                    numeric: false,
                    disablePadding: true,
                    label: 'Tipo'
                },
                {
                    id: 'referencia',
                    numeric: false,
                    disablePadding: true,
                    label: 'Referência'
                },
                {
                    id: 'nomeproduto',
                    numeric: false,
                    disablePadding: true,
                    label: 'Produto'
                },
                {
                    id: 'formula_quantidade',
                    numeric: false,
                    disablePadding: true,
                    label: 'Qtd.'
                },
                {
                    id: 'observacao',
                    numeric: false,
                    disablePadding: true,
                    label: 'Observação'
                },
                {
                    id: 'acoes',
                    numeric: false,
                    disablePadding: true,
                    label: 'Ações',
                    disableOrder: true
                },
                {
                    id: 'lote',
                    numeric: false,
                    disablePadding: true,
                    label: <>
                        <MDBox width="100%" display="flex" alignItems='center' flexDirection='row'>
                            <Checkbox
                                checked={selectAll}
                                onChange={handleSelectAllChange}
                            />
                        </MDBox>
                    </>,
                    disableOrder: true
                },
            ]

            //mapeando os dados para a "rows"
            data.map((item, index) => {
                dadostemp.push({
                    id: item.id || '',
                    referencia: item.Produto?.referencia || '',
                    tipoproduto: item.Produto?.Tipos_Produto?.nome || '',
                    nomeproduto: `${item.Produto?.id}-${item.Produto?.nome}` || '',
                    formula_quantidade: item.formula_quantidade,
                    observacao: item.observacao,
                    acoes: <>
                        <MDButton size="medium" onClick={() => abrirRegistro(item.id)} iconOnly >
                            <Icon fontSize="medium" color="success">edit_icon</Icon>
                        </MDButton>
                        <MDButton size="medium" onClick={() => excluirRegistro(item.id)} iconOnly >
                            <Icon fontSize="medium" color="error">delete_forever</Icon>
                        </MDButton>
                    </>,
                    lote: <>
                        <MDBox width="100%" display="flex" alignItems='center' flexDirection='row'>
                            <Checkbox
                                checked={!!checkedItems[item.id] || selectAll}
                                onChange={handleChange} // A função handleChange é chamada quando o estado do checkbox muda
                                name={item.id} // O nome é usado para distinguir entre diferentes checkboxes
                            />
                        </MDBox>
                    </>
                })
            })

            setColunasTable([...colunasTabletemp]);
            setLinhas([...dadostemp]);
            setCountAll(countAllretorno);
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const buscaTiposProdutos = async () => {

        let url = '';
        let retorno;

        try {
            url = `${rotafechada()}tipos_produtos?limit=-1`;
            retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;

            let opcoesTipoProdutoTemp = [{ value: 0, label: 'Todos' }];
            data.map((item) => {
                opcoesTipoProdutoTemp.push({ value: item.id, label: item.nome })
            })
            setOpcoesTipoProduto(opcoesTipoProdutoTemp);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleClickOpcoes = () => {


        const items = [
            { value: 0, label: 'Excluir produtos selecionados' },
            // { value: 1, label: 'Opção 2' },
        ];
        setOpcoes({ ...opcoes, open: true, tipo: 'opcoes', items });
    }

    const onCloseDialogoOpcoes = async (value) => {

        setOpcoes({ ...opcoes, open: false, tipo: '' });

        if (opcoes.tipo === 'opcoes') {
            switch (parseInt(value, 10)) {
                case 0:
                    setDialogoOpen({ ...dialogoOpen, visible: true });
                    break;

                default:
                    break;
            }
        }
    }

    const excluirRegistroFull = async () => {

        setDialogoOpen({ ...dialogoOpen, visible: false });

        MostraTelaEspera("Carregando..");

        const selectedIds = Object.keys(checkedItems).filter(id => checkedItems[id]);

        const dados = {
            ids: selectedIds
        }

        try {
            await api.post(`${rotafechada()}projeto_produtos/full`, dados);
            MensagemAviso('Excluído com sucesso!');
            setRecarregaTabela(!recarregaTabela);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
        setCheckedItems({})
    }

    useEffect(() => {
        // console.log("checkedItems:", checkedItems)
        buscaTiposProdutos();
        carregaTabela();
    }, [recarregaTabela, page, rowsPerPage, order, orderBy, filtroTipoProdutoId, checkedItems, selectAll])


    return (
        <>
            <DialogoOpcoes
                value={''}
                open={opcoes.open || false}
                onClose={onCloseDialogoOpcoes}
                options={opcoes.items || []}
            />

            <DialogoConfirma
                open={dialogoOpen.visible}
                title={'Confirma Exclusão'}
                color={'error'}
                message={'Certeza que deseja excluir os produtos selecionados?'}
                handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
                handleConfirm={excluirRegistroFull}
            />

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={1}>{/*Botão filtros */}
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpenCollapseFiltro(!openCollapseFiltro)}>
                                {openCollapseFiltro ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                <Typography variant='h6' mb={2}>Filtros</Typography>
                            </IconButton>

                        </Grid>
                        <Grid item xs={12} sm={6}> {/*Botão opções */}
                            <MDButton
                                variant="gradient"
                                color="dark"
                                type="button"
                                onClick={() => handleClickOpcoes()}
                                sx={{ ml: 5 }}
                            >
                                opções
                            </MDButton>

                        </Grid>
                    </Grid>


                    <Collapse in={openCollapseFiltro} timeout="auto" unmountOnExit>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormField fullWidth
                                    variant="standard"
                                    label='Nome do Produto / Referência'
                                    value={nomeProdutoFiltro}
                                    onChange={(e) => setNomeProdutoFiltro(e.target.value)}
                                    inputProps={{
                                        onKeyPress: (e) => {
                                            if (e.key === 'Enter') {
                                                setPage(0);
                                                e.preventDefault();
                                                carregaTabela();
                                            }
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <AutoCompleteStyled
                                    options={opcoesTipoProduto}
                                    getOptionLabel={(option) => option.label || ''}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    renderInput={(params) => <FormField {...params} label='Tipo de Produto' />}
                                    disabled={false}

                                    value={filtroTipoProdutoId}
                                    onChange={(event, newValue) => {
                                        setFiltroTipoProdutoId(newValue);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider
                                    sx={{ bgcolor: (theme) => theme.palette.divider }}
                                    style={{
                                        marginTop: 20,
                                        border: "none",
                                        height: 2,
                                        margin: 0,
                                    }}
                                />

                            </Grid>
                        </Grid>
                    </Collapse>

                </Grid>
                <Grid item xs={12} sm={12}> {/*Tabela */}
                    <TabelaPadrao
                        colunas={colunasTable}
                        linhas={linhas}
                        countAll={countAll}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        order={order}
                        setOrder={setOrder}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                    />
                </Grid>
            </Grid>
        </>
    )
}


export default TabelaProdutos;