import React from 'react';
import { ConfigFormProvider } from './ConfigForm';
import { AuthProvider } from './AuthContext';
import { DataPrinterProvider } from './DataPrinter';

// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
}


/*PROVIDER GERAL DO APP, ADICIONAR AQUI OUTROS PROVIDERS*/
export function AppProvider({ children }) {
    return (
        <AuthProvider>
            
                <ConfigFormProvider>
                    <DataPrinterProvider>
                        {children}
                    </DataPrinterProvider>
                </ConfigFormProvider>
        </AuthProvider >


    )
}