import { Card, Collapse, IconButton, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MDBox from "components/MDBox";
import { useState } from "react";
import TabelaPrecos from "./Cadastro";

export default function TabelaPrecoAdmin(props) {

    const { openCollapseTabelaPreco, handleClickCollapseTabelaPreco } = props;
    const [recarregaTabela, setRecarregaTabela] = useState('');

    return (
        <Card id="cadastro-tabela-preco-empresa-logada" sx={{ overflow: "visible" }}>
            <MDBox p={2} bgColor='grey-100' mt={2}>
                <IconButton aria-label="expand row" size="small" onClick={() => handleClickCollapseTabelaPreco(!openCollapseTabelaPreco)}>
                    {openCollapseTabelaPreco ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    <Typography variant='h6' mb={2}>Tabelas de Preço da Empresa Logada</Typography>
                </IconButton>

                <Collapse in={openCollapseTabelaPreco} timeout="auto" unmountOnExit>
                    <TabelaPrecos/>
                </Collapse>
            </MDBox>
        </Card>
    )
}