import { Autocomplete, Collapse, Grid, Icon, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import api from 'commons/api';
import { rotafechada } from 'commons/urlconf';
import TabelaPadrao from 'componentes/TabelaPadrao';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import AuthContext from 'hooks/AuthContext';
import MessagesContext from 'hooks/MessagesContext';
import React, { useContext, useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MDInput from 'components/MDInput';
import FormField from 'componentes/FormField';
import { retornaSomenteNumeros } from 'commons/utils';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';


const TabelaEmpresasAdmin = (props) => {

    const { recarregaTabela, abrirRegistro, excluirRegistro, opcoesTiposContratos } = props;
    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    const [countAll, setCountAll] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [colunasTable, setColunasTable] = React.useState([]);
    const [linhas, setLinhas] = React.useState([]);

    const [openFiltrosAvancados, setOpenFiltrosAvancados] = useState(false);
    const opcaoAtivo = [
        {
            label: 'Todos',
            value: ''
        },
        {
            label: 'Ativos',
            value: 'S'
        },
        {
            label: 'Desativados',
            value: 'N'
        },
    ];
    const [filtroAtivo, setFiltroAtivo] = useState(opcaoAtivo[1]);
    const [filtroTipoContrato, setFiltroTipoContrato] = useState('');


    const opcaoCampos = [
        { id: 'fantasia', label: 'Nome Fantasia', buscaParcial: true },
        { id: 'razaosocial', label: 'Razão Social', buscaParcial: true },
        { id: 'cnpj', label: 'CNPJ' },
        { id: 'id', label: 'Id' },
        { id: 'cnpj', label: 'CNPJ' },
    ];
    const [campoPesquisa, setCampoPesquisa] = React.useState(opcaoCampos[0]);
    const [valorPesquisa, setValorPesquisa] = React.useState('');


    const carregaTabela = async () => {

        try {

            let complementoUrl = '';

            if (page > 0) {
                complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
            }

            if (orderBy) {
                complementoUrl = `${complementoUrl}&order=${orderBy}`;
                if (order) {
                    complementoUrl = `${complementoUrl}&direction=${order}`;
                }
            }

            if (filtroAtivo?.value) {
                complementoUrl = `${complementoUrl}&ativo=${filtroAtivo.value}`
            }

            if (filtroTipoContrato?.value) {
                complementoUrl = `${complementoUrl}&tipo_contrato_id=${filtroTipoContrato.value}`
            }

            if (campoPesquisa) {
                if ((campoPesquisa.id) && (valorPesquisa)) {
                    complementoUrl = `&${campoPesquisa.id}=${valorPesquisa}`;

                    if ((campoPesquisa.id === 'id') || (campoPesquisa.id === 'conta_id')) {
                        const temp = retornaSomenteNumeros(valorPesquisa);
                        if ((`${temp}`.length !== `${valorPesquisa}`.length) ||
                            (!(parseInt(`${valorPesquisa}`, 10) > 0))) {
                            MensagemErro('A pesquisa por ID aceita só números, escolha outro campo para busca');
                            return;
                        }
                    }

                    if ((valorPesquisa) && (valorPesquisa.length > 0) && (valorPesquisa[0] !== '%') && (valorPesquisa[valorPesquisa.length - 1] !== '%')
                        && (opcaoCampos) && (opcaoCampos.length > 0)) {

                        //se o texto a ser buscado nao terminar ou começar com %
                        //e tiver uma configuracao no opcaoCampos
                        //entao localizo que o campo usado nesse momento é um campo que esteja configurado
                        //o buscaParcial, se sim acrescento automaticamente o % no inicio e fim


                        let encontrouCampo = false;
                        for (let i = 0; i < opcaoCampos.length; i++) {

                            if ((opcaoCampos[i].buscaParcial === true) && (opcaoCampos[i].id === campoPesquisa.id)) {
                                encontrouCampo = true;
                            }
                        }

                        if (encontrouCampo === true) {
                            complementoUrl = `&${campoPesquisa.id}=%${valorPesquisa}%`;
                        }
                    }
                }
            }

            const retorno = await api.get(`${rotafechada()}empresas?limit=${rowsPerPage}&${complementoUrl}&all=true`);

            const { data, countAll: countAllretorno } = retorno.data;

            const dadostemp = [];

            const colunasTabletemp = [
                {
                    id: 'id',
                    numeric: false,
                    disablePadding: true,
                    label: 'Id'
                },
                {
                    id: 'fantasia',
                    numeric: false,
                    disablePadding: true,
                    label: 'Fantasia'
                },
                {
                    id: 'razaosocial',
                    numeric: false,
                    disablePadding: true,
                    label: 'Razão Social'
                },
                {
                    id: 'endereco_cidade',
                    numeric: false,
                    disablePadding: true,
                    label: 'Cidade'
                },
                {
                    id: 'ativo',
                    numeric: false,
                    disablePadding: true,
                    label: 'ativo'
                },
                {
                    id: 'acoes',
                    numeric: false,
                    disablePadding: true,
                    label: 'Ações',
                    disableOrder: true
                },
            ]


            //mapeando os dados para a "rows"
            data.map((item, index) => {
                dadostemp.push({
                    id: item.id,
                    fantasia: item.fantasia,
                    razaosocial: item.razaosocial,
                    ativo: item.ativo,
                    endereco_cidade: `${item.Cidade?.nome || ''}/${item.Cidade?.uf || ''}`,
                    acoes: <>
                        <MDButton size="medium" onClick={() => abrirRegistro(item.id)} iconOnly >
                            <Icon fontSize="medium" color="success">edit_icon</Icon>
                        </MDButton>
                        <MDButton size="medium" onClick={() => excluirRegistro(item.id)} iconOnly >
                            <Icon fontSize="medium" color="error">delete_forever</Icon>
                        </MDButton>
                    </>
                })
            })

            setColunasTable([...colunasTabletemp]);
            setLinhas([...dadostemp]);
            setCountAll(countAllretorno);
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    useEffect(() => {
        carregaTabela();
    }, [recarregaTabela, page, rowsPerPage, order, orderBy])

    const desenhaFiltro = () => {
        return (
            <Collapse in={openFiltrosAvancados} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>

                    < Grid container spacing={2} justifyContent="flex-start" >

                        <Grid item sm={4} >
                            <AutoCompleteStyled
                                autoHighlight
                                options={opcaoCampos}
                                getOptionLabel={(option) => option.label || ''}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                renderInput={(params) => <FormField {...params} label='Campo' />}
                                value={campoPesquisa}
                                onChange={(event, newValue) => {
                                    setCampoPesquisa(newValue);
                                }}
                                defaultValue={null}
                            />
                        </Grid>

                        <Grid item sm={8} >
                            <FormField type="text" label="Valor a ser Pesquisado" value={valorPesquisa} onChange={(e) => setValorPesquisa(e.target.value)}
                                inputProps={
                                    {
                                        onKeyPress: (e) => {

                                            if (e.key === 'Enter') {
                                                carregaTabela();
                                            }
                                        },
                                    }
                                }
                            />
                        </Grid>

                        <Grid item sm={4} >
                            <AutoCompleteStyled
                                autoHighlight
                                options={opcaoAtivo}
                                getOptionLabel={(option) => option.label || ''}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                renderInput={(params) => <FormField {...params} label='Ativo'/>}
                                value={filtroAtivo}
                                onChange={(event, newValue) => {
                                    setFiltroAtivo(newValue);
                                }}
                                defaultValue={{ id: -1, value: -1, label: '<Escolha um opção>' }}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <AutoCompleteStyled
                                autoHighlight
                                options={opcoesTiposContratos}
                                getOptionLabel={(option) => option.label || ''}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                renderInput={(params) => <FormField {...params} label='Tipo Contrato' />}
                                value={filtroTipoContrato}
                                onChange={(event, newValue) => {
                                    setFiltroTipoContrato(newValue);
                                }}
                                defaultValue={{ id: -1, value: -1, label: '<Escolha um opção>' }}
                            />
                        </Grid>


                        < Grid container justifyContent="flex-end" >
                            <Grid item xs={12} sm={2} mt={3}> {/*Pesquisar*/}
                                <MDButton onClick={carregaTabela} color='info'> Pesquisar</MDButton>
                            </Grid>
                        </Grid>
                    </Grid >
                </Box>
            </Collapse>
        )
    }

    return (
        <>

            <MDBox width="100%" display="flex" justifyContent="space-between">
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpenFiltrosAvancados(!openFiltrosAvancados)}
                >
                    {openFiltrosAvancados ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    <Typography>Filtros</Typography>
                </IconButton>

            </MDBox>

            <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                    {desenhaFiltro()}
                </MDTypography>
            </MDBox>

            <TabelaPadrao
                colunas={colunasTable}
                linhas={linhas}
                countAll={countAll}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
            />
        </>
    )
}


export default TabelaEmpresasAdmin;