import React, { useState, useEffect, useContext } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MessagesContext from "hooks/MessagesContext";
import { Grid, Icon, Tooltip, Divider, Menu, MenuItem, ListItemIcon, Box, Button } from "@mui/material";
import AuthContext from 'hooks/AuthContext';
import { formatDate, formataValor } from "commons/utils";
import { Link } from "react-router-dom";
import HelpIcon from '@mui/icons-material/Help';
import TabelaPadrao from "componentes/TabelaPadrao";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogoConfirmaValidacao from "componentes/DialogoConfirmaValidacao";


export default function DialogoDetalheConta(props) {

    const { open, message, handleCancel, title, setCodigoAbrir, codigoAbrir, tipo,
        handleClickInformarPagamento, handleClickDetalheParcela, handleClickEditarParcela,
        handleClickExcluir, handleClickInformarRecebimento, handleClickEditarConta, handleClickDetalheConta } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera, MensagemAlerta } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });

    /*****************Menu Item*******************/
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDetalhe, setOpenDetalhe] = useState(false);
    const [situacaoParcela, setSituacaoParcela] = useState('');
    const [idParcela, setIdParcela] = useState(0);

    const [anchorElFiltro, setAnchorElFiltro] = useState(null);
    const [openDetalheFiltro, setOpenDetalheFiltro] = useState(false);


    //TABELA
    const [colunasTable, setColunasTable] = React.useState([]);
    const [linhas, setLinhas] = React.useState([]);
    const [countAll, setCountAll] = React.useState(0);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    //Conta
    const [conta_id, setConta_id] = useState(0)
    const [pessoa, setPessoa] = useState('');
    const [pessoa_id, setPessoa_id] = useState('');
    const [codigoReferencia, setCodigoReferencia] = useState('');
    const [dataCompetencia, setDataCompetencia] = useState('');
    const [categoria, setCategoria] = useState('');
    const [centro_custo, setCentro_custo] = useState('');
    const [descricaoConta, setDescricaoConta] = useState('');
    const [observacaoConta, setObservacaoConta] = useState('');
    const [valorConta, setValorConta] = useState(0);
    const [rateio, setRateio] = useState('');
    const [manual, setManual] = useState('');
    const [dadosRateio, setDadosRateio] = useState([]);
    const [totalPago, setTotalPago] = useState(0)
    const [totalAberto, setTotalAberto] = useState(0)

    const handleClose = () => {
        setAnchorEl(null);
        setOpenDetalhe(false)
    };

    const validaRotaPermissao = async (operacao = '', silencioso = false) => {
        if ((operacao !== 'inserir') &&
            (operacao !== 'editar') &&
            (operacao !== 'excluir') &&
            (operacao !== 'listar')) {
            MensagemErro('Escolha a operação que deseja validar a permissão!');
            return false;
        }

        //validando a permissao
        try {
            await api.get(`${rotafechada()}lancamento_parcelas/permissao/${operacao}`);
            return true;
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;

            if (silencioso === false) {
                MensagemErro(`Erro: ${msg}`);
            }
            return false;
        }
    }

    const buscaDados = async () => {
        try {
            MostraTelaEspera()
            //console.log(props)
            let retorno
            if (props.conta_id > 0)
                retorno = await api.get(`${rotafechada()}parcelas_conta/parcelafull?conta_id=${props.conta_id}`);
            if (!props.conta_id)
                retorno = await api.get(`${rotafechada()}parcelas_conta/parcelafull?id=${props.codigoAbrir}`);
            //const retorno = await api.get(`${rotafechada()}parcelas_conta/parcelafull?id=569`);
            const { dataConta, dataParcela, dataCountParcelas, dataLanParcelas, dataSaldo, dataSituacao } = retorno.data;

            //console.log('DADOS CONTA:', dataConta)

            //Conta
            setConta_id(dataConta[0].id)
            setPessoa(dataConta[0].Pessoa.razao_social)
            setPessoa_id(dataConta[0].Pessoa.id)
            setCodigoReferencia(dataConta[0].codigo_referencia)
            let data_competencia_temp = new Date(dataConta[0].data_competencia)
            setDataCompetencia(formatDate(data_competencia_temp, 'DD/MM/YYYY'))
            setCategoria(dataConta[0].rateio == 'N' ? dataConta[0].Categorium.nome : `${dataConta[0].Rateio_Categoria.length} categorias informadas`)
            //setCategoria(dataConta[0].Categorium.nome )
            setCentro_custo(dataConta[0].Centro_Custo?.nome)
            setDescricaoConta(dataConta[0].nome)
            setObservacaoConta(dataConta[0].observacao)
            setValorConta(dataConta[0].valor)
            setRateio(dataConta[0].rateio)
            setManual(dataConta[0].manual)
            //console.log("Rateio_Categoria:", dataConta[0].Rateio_Categoria)
            if (dataConta[0].rateio == 'S')
                setDadosRateio(dataConta[0].Rateio_Categoria)


            /*DADOS TABELA*/
            let complementoUrl = '';

            if (page > 0) {
                complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
            }

            if (orderBy) {
                complementoUrl = `${complementoUrl}&order=${orderBy}`;
                if (order) {
                    complementoUrl = `${complementoUrl}&direction=${order}`;
                }
            }

            const retornoDetalheParcela = await api.get(`${rotafechada()}parcelas_conta/detalheconta?id=${dataConta[0].id}`);
            const { data, count: countAllretorno } = retornoDetalheParcela.data;
            const dadostemp = [];
            let totalPagoTemp = 0, totalAbertoTemp = 0

            //console.log("DATA DETALHE CONTA", data)

            const colunasTabletemp = [
                // {
                //     id: 'id',
                //     numeric: false,
                //     disablePadding: true,
                //     label: 'id',
                //     disableOrder: true
                // },
                {
                    id: 'data',
                    numeric: false,
                    disablePadding: true,
                    label: 'Data',
                    disableOrder: true
                },
                {
                    id: 'parcela',
                    numeric: false,
                    disablePadding: true,
                    label: 'Parcela',
                    disableOrder: true
                },
                // {
                //     id: 'forma_pagamento_id',
                //     numeric: false,
                //     disablePadding: true,
                //     label: 'Forma de pagamento',
                //     disableOrder: true
                // },
                // {
                //     id: 'banco_id',
                //     numeric: false,
                //     disablePadding: true,
                //     label: 'Conta',
                //     disableOrder: true
                // },
                {
                    id: 'descricao',
                    numeric: false,
                    disablePadding: true,
                    label: 'Descrição',
                    disableOrder: true
                },
                {
                    id: 'valor',
                    numeric: false,
                    disablePadding: true,
                    label: 'Valor(R$)',
                    disableOrder: true
                },
                {
                    id: 'pago',
                    numeric: false,
                    disablePadding: true,
                    label: tipo == 'R' ? 'Recebido(R$)' : 'Pago(R$)',
                    disableOrder: true
                },
                {
                    id: 'emAberto',
                    numeric: false,
                    disablePadding: true,
                    label: 'Em aberto(R$)',
                    disableOrder: true
                },
                {
                    id: 'situacao',
                    numeric: false,
                    disablePadding: true,
                    label: 'Situação',
                    disableOrder: true
                },
                {
                    id: 'acoes',
                    numeric: false,
                    disablePadding: true,
                    label: '',
                    disableOrder: true
                },
            ]
            //console.log('data:', data)
            //mapeando os dados para a "rows"
            data.map((item, index) => {
                totalPagoTemp = totalPagoTemp + parseFloat(item.pago)
                totalAbertoTemp = totalAbertoTemp + parseFloat(item.emaberto)
                dadostemp.push({
                    id: item.id,
                    data: formatDate(new Date(item.vencimento), 'DD/MM/YYYY'),
                    descricao: item.descricao,
                    //forma_pagamento_id: item.forma_pagamento,
                    //banco_id: item.conta,
                    valor: formataValor(item.valor).replace("R$", ""),
                    pago: formataValor(item.pago).replace("R$", ""),
                    parcela: `${item.parcela} / ${countAllretorno}`,
                    emAberto: formataValor(item.emaberto).replace("R$", ""),
                    situacao:
                        <MDBox display="flex" alignText={'center'} width="100px" justifyContent={'center'}
                            sx={{ background: `${item.situacao == 'PAGO' ? '#bce2be' : item.situacao == 'EM ABERTO' ? '#ffd59f' : '#aecef7'}` }}
                            borderRadius="sm"
                            p={0.5}
                        >
                            <MDTypography fontWeight="medium" variant="caption" textTransform="capitalize"
                            //color={item.situacao == 'PAGO' ? 'success' : item.situacao == 'EM ABERTO' ? 'warning' : 'info'}
                            >
                                {item.situacao}
                            </MDTypography>
                        </MDBox>,
                    acoes: <>
                        <MDButton
                            variant="outlined"
                            color="info"
                            size="small"
                            borderRadius="sm"
                            onClick={(e) => {
                                setSituacaoParcela(item.situacao)
                                setIdParcela(item.id)
                                setAnchorEl(e.currentTarget);
                                setOpenDetalhe(true)
                            }}
                            sx={{ borderRadius: '4px', height: '25px', minHeight: '12px' }}
                            disableElevation
                            endIcon={<KeyboardArrowDownIcon />}
                        >
                            ações
                        </MDButton>

                    </>
                })
            })

            setTotalPago(totalPagoTemp)
            setTotalAberto(totalAbertoTemp)
            setColunasTable([...colunasTabletemp]);
            setLinhas(dadostemp);
            setCountAll(countAllretorno);

            /*DADOS TABELA*/




            FechaTelaEspera()
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleExcluir = async () => {
        setDialogoOpen({ ...dialogoOpen, visible: false });

        try {
            await api.delete(`${rotafechada()}contas/full/${dialogoOpen.id || 0}`)
            MensagemAviso('Excluído com sucesso!');
            //setCodigoCarregado('');
            //setRecarregaTabela(!recarregaTabela);
            handleCancel()
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleClickExcluirConta = async (id) => {

        if (!(await validaRotaPermissao('excluir') === true)) {
            return;
        }

        setDialogoOpen({ ...dialogoOpen, visible: true, id });
    }

    const handleReplicarConta = async () => {

        try {
            let retorno = await api.put(`${rotafechada()}contas/replicarconta/${codigoAbrir}`)
            MensagemAviso('Conta replicada com sucesso com sucesso!');
            const { data } = retorno.data;
            console.log('retorno replicado', data)
            await handleCancel()
            await limpaImputs()
            await handleClickDetalheConta(data)
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const limpaImputs = async () => {

        setCodigoAbrir('');
    }

    const handleCloseFiltro = async () => {
        setAnchorElFiltro(null);
        setOpenDetalheFiltro(false)
    };

    useEffect(() => {
        if (open == true) {
            buscaDados();
        }

    }, [open])

    useEffect(() => {
        buscaDados();
    }, [page, rowsPerPage, order, orderBy])

    return (
        <>

            <DialogoConfirmaValidacao
                open={dialogoOpen.visible}
                title={'Confirma Exclusão'}
                color={'error'}
                message={'Certeza que deseja excluir? '}
                validacao={'Concordo com a exclusão desta conta e todas suas parcelas.'}
                handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
                handleConfirm={handleExcluir}
            />
            <Menu

                anchorEl={anchorEl}
                open={openDetalhe}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    dense={true}
                    disabled={situacaoParcela == 'PAGO' ? true : false}
                    onClick={() => {
                        tipo == 'R' ? handleClickInformarRecebimento(idParcela) : handleClickInformarPagamento(idParcela)
                        //handleClickInformarPagamento(idParcela)
                        setAnchorEl(null);
                        setOpenDetalhe(false)
                    }}>
                    {`Informar ${tipo == 'R' ? ' recebimento' : ' pagamento'}`}
                </MenuItem>
                <MenuItem
                    dense={true}
                    onClick={() => {
                        handleClickDetalheParcela(idParcela)
                        setAnchorEl(null);
                        setOpenDetalhe(false)
                    }}>
                    Ver detalhes da parcela
                </MenuItem>
                <MenuItem
                    dense={true}
                    onClick={() => {
                        handleClickEditarParcela(idParcela)
                        setAnchorEl(null);
                        setOpenDetalhe(false)
                    }}>
                    Editar parcela
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                    dense={true}
                    onClick={() => {
                        handleClickExcluir(idParcela, situacaoParcela)
                        setAnchorEl(null);
                        setOpenDetalhe(false)
                    }}>
                    <ListItemIcon>
                        <DeleteIcon />
                    </ListItemIcon>
                    Excluir
                </MenuItem>
            </Menu>

            <Menu //Filtro replicar
                anchorEl={anchorElFiltro}
                open={openDetalheFiltro}
                onClose={handleCloseFiltro}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    dense={true}
                    onClick={() => {
                        handleReplicarConta()
                    }}
                >
                    Replicar Conta
                </MenuItem>
            </Menu>


            <Dialog
                open={open}
                onClose={() => {
                    handleCancel()
                    limpaImputs()
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen
            >
                <DialogTitle id="alert-dialog-title" sx={{ padding: '5px' }}>{/*HEADER*/}
                    <MDBox width="100%">
                        <MDBox display="flex" justifyContent="space-between">
                            <MDBox display="flex" justifyContent="space-start" flexDirection='column'>
                                <MDTypography fontWeight='bold' ml={5} mt={1} variant='h4'>{`Detalhes da ${tipo == 'D' ? 'despesa' : 'receita'}  ${countAll > 0 ? `parcelada em ${countAll}x - ${descricaoConta}` : ''}`}</MDTypography>
                                {/* <MDTypography fontWeight='bold' ml={5} mt={1} variant='caption'>{`Despesas`}</MDTypography> */}
                            </MDBox>

                            <Tooltip title="Fechar (Esc)">
                                <MDButton size="large" sx={{ padding: 0 }} onClick={() => {
                                    handleCancel()
                                    limpaImputs()
                                }}>
                                    <Icon>close</Icon>
                                </MDButton>

                            </Tooltip>
                        </MDBox>
                    </MDBox>
                </DialogTitle>

                <DialogContent dividers sx={{ backgroundColor: "#f1f4f9", borderColor: '#7bb5e4' }}>
                    <DialogContentText id="alert-dialog-description">

                        <MDBox ml={2} mr={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* CONTA */}
                            <Grid container spacing={2} mt={1} pl={2} pr={2}>

                                <Grid item xs={12} sm={12}> {/* TITULO*/}
                                    <MDTypography variant='subtitle2' fontWeight="bold">{tipo == 'D' ? 'Informações da conta' : 'Informações da conta'}</MDTypography>
                                    <Divider
                                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                                        style={{
                                            marginTop: 5,
                                            marginBottom: 10,
                                            border: "none",
                                            height: 2,
                                            margin: 0,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={2}>{/*PESSOA*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{tipo == 'D' ? 'Fornecedor' : 'Cliente'}</MDTypography>
                                        <MDTypography variant='caption' >
                                            <Link to={`/pessoas/pessoa/${pessoa_id}`} style={{ textDecoration: "none", color: 'info' }}>{pessoa}</Link>
                                        </MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* CODIGO_REFERENCIA */}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Código de referência'}</MDTypography>
                                        <MDTypography variant='caption' >{codigoReferencia}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* DATA_COMPETENCIA */}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Data de competência'}</MDTypography>
                                        <MDTypography variant='caption' >{dataCompetencia}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2} >{/*CATEGORIA*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Categoria'}</MDTypography>
                                        <MDTypography variant='caption' >{categoria}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/*CENTRO_CUSTO*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Centro de custo'}</MDTypography>
                                        <MDTypography variant='caption' >{centro_custo}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* VALOR_CONTA */}
                                    <MDBox width='100%' display='flex' alignText='flex-end' alignItems='flex-end' justifyContent={'flex-end'} flexDirection='row'>
                                        <MDTypography variant='caption' fontWeight="bold">{'Valor atual  '}</MDTypography>
                                        <Tooltip
                                            title="O valor total da conta que considera o somatório das parcelas"
                                            placement="top"
                                            disableInteractive
                                        >
                                            <HelpIcon color="info" />
                                        </Tooltip>
                                    </MDBox>
                                    <MDBox width='100%' display='flex' alignText='flex-end' alignItems='flex-end' flexDirection='column'>
                                        <MDTypography fontWeight="bold" >{formataValor(valorConta)}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/*DESCRIÇÃO_CONTA*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Descrição da conta'}</MDTypography>
                                        <MDTypography variant='caption' >{descricaoConta}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={8}> {/*OBSERVAÇÃO_CONTA*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Observação da conta'}</MDTypography>
                                        <MDTypography variant='caption' >{observacaoConta}</MDTypography>
                                    </MDBox>
                                </Grid>

                            </Grid>

                        </MDBox>

                        <MDBox ml={2} mr={2} mt={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* CONDIÇÕES PAGAMENTO */}
                            <Grid container spacing={2} mt={1} pl={2} pr={2}>

                                <Grid item xs={12} sm={11}> {/* TITULO*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='subtitle2' pb={1} fontWeight="bold">Condições de {tipo == 'D' ? 'pagamento' : 'recebimento'}</MDTypography>
                                        {/* <MDTypography variant='caption'>{'Em cada lote de quitação pode conter Juros/Multa e/ou Desconto/Tarifa'}</MDTypography> */}
                                    </MDBox>
                                    <Divider
                                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                                        style={{
                                            marginTop: 5,
                                            marginBottom: 10,
                                            border: "none",
                                            height: 2,
                                            margin: 0,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12}>  {/* TABELA*/}
                                    {
                                        <TabelaPadrao
                                            colunas={colunasTable}
                                            linhas={linhas}
                                            countAll={countAll}
                                            page={page}
                                            setPage={setPage}
                                            rowsPerPage={60}
                                            setRowsPerPage={setRowsPerPage}
                                            order={order}
                                            setOrder={setOrder}
                                            orderBy={orderBy}
                                            setOrderBy={setOrderBy}
                                        />

                                        //:

                                        //<MDTypography variant='caption'>{'Não há informações de lançamentos para esta parcela.'}</MDTypography>

                                    }
                                </Grid>

                            </Grid>
                        </MDBox>

                        <MDBox ml={2} mr={2} mt={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/*TOTAIS PAG/ABERTO*/}
                            <Grid container spacing={2} mt={1} pl={2} pr={2}>
                                <Grid item xs={12} sm={8.5}> {/* espaço em branco */}
                                </Grid>

                                <Grid item xs={12} sm={1.5}> {/* Valor total pago */}
                                    <MDBox width='100%' display='flex' alignItems='flex-end' justifyContent={'flex-end'} flexDirection='row'>
                                        <MDTypography variant='caption' fontWeight="bold">Total pago(R$) </MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={1.5}> {/* Valor total em aberto*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-end' justifyContent={'flex-end'} flexDirection='row'>
                                        <MDTypography variant='caption' fontWeight="bold">Total em aberto(R$) </MDTypography>
                                    </MDBox>
                                </Grid>


                                <Grid item xs={12} sm={8.5}> {/* espaço em branco*/}
                                </Grid>

                                <Grid item xs={12} sm={1.5}> {/* Valor pago*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-end' justifyContent={'flex-end'} flexDirection='row'>
                                        <MDTypography variant='caption' color='info' fontWeight="bold">{formataValor(totalPago).replace("R$", "")}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={1.5}> {/* Valor em aberto */}
                                    <MDBox width='100%' display='flex' alignItems='flex-end' justifyContent={'flex-end'} flexDirection='row'>
                                        <MDTypography variant='caption' color='error' fontWeight="bold">{formataValor(totalAberto).replace("R$", "")}</MDTypography>
                                    </MDBox>
                                </Grid>
                            </Grid>
                        </MDBox>

                        {rateio == 'S' &&
                            //console.log("Dados Rateio",dadosRateio)
                            <MDBox ml={2} mr={2} mt={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/*RATEIO*/}
                                <Grid container spacing={2} mt={1} pl={2} pr={2}>
                                    <Grid item xs={12} sm={12}> {/* TITULO*/}
                                        <MDTypography variant='subtitle2' fontWeight="bold">Informações de categoria e centro de custo</MDTypography>
                                        <Divider
                                            sx={{ bgcolor: (theme) => theme.palette.divider }}
                                            style={{
                                                marginTop: 5,
                                                marginBottom: 10,
                                                border: "none",
                                                height: 2,
                                                margin: 0,
                                            }}
                                        />
                                    </Grid>
                                    {dadosRateio?.map((item, index) =>

                                        <>
                                            <Grid item xs={12} sm={4} >{/*CATEGORIA*/}
                                                <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                                    <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Categoria'}</MDTypography>
                                                    <MDTypography variant='caption' >{item.Categorium.nome}</MDTypography>
                                                </MDBox>
                                            </Grid>

                                            <Grid item xs={12} sm={2}> {/*CENTRO_CUSTO*/}
                                                <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                                    <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Centro de custo'}</MDTypography>
                                                    <MDTypography variant='caption' >{item.Centro_Custo?.nome}</MDTypography>
                                                </MDBox>
                                            </Grid>

                                            <Grid item xs={12} sm={2}> {/*Valor*/}
                                                <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                                    <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Valor(R$)'}</MDTypography>
                                                    <MDTypography variant='caption' >{formataValor(valorConta * (item.percentual / 100)).replace("R$", "")}</MDTypography>
                                                </MDBox>
                                            </Grid>

                                            <Grid item xs={12} sm={2}> {/*Percentual*/}
                                                <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                                    <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Percentual'}</MDTypography>
                                                    <MDTypography variant='caption' >{`${item.percentual} %`}</MDTypography>
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} sm={2}> {/*ESPAÇO*/}

                                            </Grid>

                                        </>



                                    )}
                                </Grid>
                            </MDBox>
                        }

                    </DialogContentText>
                </DialogContent>

                <DialogActions sx={{ padding: '7px', margin: 0.5 }}> {/*FOOTER*/}
                    {/* <MDBox width="100%"> */}
                    <MDBox width="100%" display="flex" justifyContent="space-between">
                        {/* <MDBox width="100%" display="flex" justifyContent="flex"> */}
                        <MDButton onClick={() => {
                            handleCancel()
                            limpaImputs()
                        }} variant="gradient" type="button" color="secondary">
                            voltar
                        </MDButton>
                        {/* </MDBox> */}

                        <MDBox display="flex" justifyContent="flex-end">
                            <Tooltip title="Excluir desabilitado pois essa conta foi originada da venda, para excluir 
                                é necessario estornar-la pelo módulo de Orçamento/Venda" disableHoverListener={manual == 'N' ? false : true}>
                                <span>
                                    <MDButton
                                        disabled={manual == 'N' ? true : false}
                                        onClick={() => handleClickExcluirConta(conta_id)}
                                        variant="outlined"
                                        //type="submit"
                                        type="button"
                                        color="error"
                                        sx={{ marginRight: 1 }}>
                                        {'Excluir'}
                                    </MDButton>

                                </span>
                            </Tooltip>
                            {/* <MDButton
                                onClick={() => handleClickEditarConta(conta_id)}
                                variant="gradient"
                                type="submit"
                                color="info">
                                {'Editar'}
                            </MDButton> */}
                            <Box justifyContent='center' width='100%' display='flex' alignItems='center' flexDirection='row'>
                                <Box pl={0.2} pr={0.2}>
                                    <MDButton
                                        onClick={() => handleClickEditarConta(conta_id)}
                                        variant="gradient"
                                        type="submit"
                                        color={'info'}
                                        sx={{
                                            borderTopRightRadius: '0px',
                                            borderBottomRightRadius: '0px',
                                            borderColor: 'red'
                                        }}
                                    >
                                        Editar
                                    </MDButton>
                                </Box>
                                <Tooltip title="Ações" placement="top" disableInteractive>
                                    <MDButton
                                        onClick={(e) => {
                                            setAnchorElFiltro(e.currentTarget);
                                            setOpenDetalheFiltro(true)
                                        }}
                                        variant="gradient"
                                        color='info'
                                        sx={{
                                            borderBottomLeftRadius: '0px',
                                            borderTopLeftRadius: '0px',
                                            minWidth: '0',
                                            width: '15px'
                                        }}>
                                        <KeyboardArrowUpIcon/>
                                    </MDButton>
                                </Tooltip>
                            </Box>
                        </MDBox>
                    </MDBox>
                </DialogActions>
            </Dialog>
        </>
    );
}