import React, { useContext, useEffect, useState, createRef } from "react";
import defaultImage from "assets/images/products/choose-product.png";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MessagesContext from "hooks/MessagesContext";
import { Box } from "@mui/system";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import DialogoConfirma from "componentes/DialogoConfirma";
import MostraImagem from "componentes/MostraImagem";
import { Tooltip } from "@mui/material";


const TAMANHO_MAX_KB_IMAGEM = 150;

export default function ImagemProduto(props) {


  const { produtoAtual, setValue, imagePreviewUrl, setImagePreviewUrl } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const [file, setFile] = useState(null);

  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const [openZoomImg, setOpenZoomImg] = useState(false);

  //ao criar uma referencia posso usar o componente (metodos...)
  //usando essa variavel
  let fileInput = createRef();

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    //maximo em KB
    if (file?.size > (TAMANHO_MAX_KB_IMAGEM * 1024)) {
      MensagemErro(`Escolha uma imagem com tamanho menor que ${TAMANHO_MAX_KB_IMAGEM}kb`);
      return;
    }

    reader.onloadend = async () => {
      const ok = await enviarImagemServidor(file);
      if (ok) {
        setFile(file);
        setImagePreviewUrl(reader.result);
      }
      else {
        console.log('Erro ao tentar enviar');
        setFile(null);
        setImagePreviewUrl(defaultImage);
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleClickAlterar = () => {
    fileInput.current.click();
  };

  async function enviarImagemServidor(file) {

    let formData = new FormData();
    formData.append("file", file);
    let url = `${rotafechada()}produtos/imagem/${parseInt(produtoAtual, 10) > 0 ? produtoAtual : 0}`;
    console.log(url);
    try {

      const retorno = await api.put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        //onUploadProgress,
      });

      if (retorno?.data?.data?.nome_imagem) {
        setValue('nome_imagem', retorno?.data?.data?.nome_imagem);
      }

      //se de certo gravar no arquivo ele retorna true para a funcao mostrar a foto no componente
      return true;
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro ao tentar enviar a imagem! ${msg}`);
    }
  };

  async function handleClickRemoverImagem() {
    setDialogoOpen({ ...dialogoOpen, visible: false });
    try {
      if (parseInt(produtoAtual, 10) > 0) {
        const url = `${rotafechada()}produtos/${produtoAtual}`;
        const retorno = await api.put(url, { nome_imagem: '' });
      }
      setImagePreviewUrl('');
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro ao tentar remover a imagem! ${msg}`);
    }
  };

  const buscaImagemProduto = async () => {

    if (!(parseInt(produtoAtual, 10) > 0)) {
      return;
    }

    try {
      const url = `${rotafechada()}produtos/imagem/${produtoAtual}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      const src = `data:image/png[jpg];base64,${data.imagem}`;
      setImagePreviewUrl(src);

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      //MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickExcluir = async () => {
    setDialogoOpen({ ...dialogoOpen, visible: true });
  }

  const handleClickAbrirImg = async () => {
    setOpenZoomImg(true);
  }

  useEffect(() => {
    buscaImagemProduto();
  }, [produtoAtual]);


  return (
    <>
      <MostraImagem
        open={openZoomImg}
        setOpen={setOpenZoomImg}
        img={imagePreviewUrl}
        alt='Imagem do produto'
      />

      <DialogoConfirma
        open={dialogoOpen.visible}
        title={'Confirma Exclusão'}
        color={'error'}
        message={'Certeza que deseja retirar a imagem?'}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
        handleConfirm={handleClickRemoverImagem}
      />

      <Box display={'flex'} justifyContent={'center'}>
        <input type="file"
          onChange={handleImageChange}
          ref={fileInput}
          style={{ display: 'none' }}
          accept="image/png, image/gif, image/jpeg, image/bmp"
        />
        <div>
          {
            imagePreviewUrl !== '' ?
              <img src={imagePreviewUrl} alt="Imagem do produto" onClick={() => handleClickAbrirImg()} style={{ maxHeight: 200, maxWidth: 200, cursor: "zoom-in" }} />
              :
              <img src={defaultImage} alt="Imagem do produto" />
          }
        </div>
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
        <Tooltip title="Carregar imagem do produto" placement="top">
          <AddIcon onClick={() => handleClickAlterar()} color={'info'} fontSize='medium' />
        </Tooltip>
        {imagePreviewUrl !== '' &&
          <DeleteIcon onClick={() => handleClickExcluir()} sx={{ ml: 2 }} color='error' fontSize='medium' />
        }
        <ZoomInIcon onClick={() => handleClickAbrirImg()} sx={{ ml: 2 }} color={'info'} fontSize='medium' />
      </Box>
    </>
  );
}