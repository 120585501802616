import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormField from 'componentes/FormField';
import DialogTitle from '@mui/material/DialogTitle';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import { useContext } from 'react';
import { useState } from 'react';
import { Box } from '@mui/system';
import MDButton from 'components/MDButton';


export default function ReplicacaoProjetoVenda(props) {

  const { open, setOpen, encerraReplicacao, id } = props;

  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera, MensagemAlerta } = messageContext;

  const [quantidadeReplicacao, setQuantidadeReplicacao] = useState(1);

  const handleCancel = () => {
    setOpen(false);
  }


  const replicarProjeto = async () => {

    if (quantidadeReplicacao) {
      try {
        MostraTelaEspera('Aguarde a replicação no servidor')
        await api.post(`${rotafechada()}venda_projetos/replicar`, {
          venda_projeto_id: id || 0,
          quantidadereplicacao: quantidadeReplicacao
        });
        FechaTelaEspera();
        MensagemAviso('Replicado com sucesso!');

        if (encerraReplicacao) {
          encerraReplicacao();
        }

      }
      catch (error) {
        FechaTelaEspera();
        const msg = error.response?.data?.msg || error;
        MensagemErro(`${msg}`);
      }

    }
    else {
      MensagemAlerta("Quandidade de cópias é um campo obrigatório !")
    }

  };


  return (
    <div>

      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Replicação (Cópia) de Projetos da Venda
        </DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <FormField
              autoFocus={true}
              autoComplete={false}
              type='number'
              label='Quantidade de Cópias'
              rows={1}
              multiline={false}
              required={true}
              value={quantidadeReplicacao}
              onChange={(e) => setQuantidadeReplicacao(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <MDButton variant="outlined" color="secondary" onClick={handleCancel}>Cancelar</MDButton>
          <MDButton variant="gradient" color="info" onClick={replicarProjeto}>
            Confirmar
          </MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
