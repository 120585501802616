import React, { Component, useRef, useState } from 'react';
import { render } from 'react-dom';
import { Stage, Layer, Rect, Text, Shape, Circle, Line, Image, Transformer } from 'react-konva';
import Konva from 'konva';
import { Box } from '@mui/system';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useImage from 'use-image';
import Exemplos from './exemplos';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DesenhaRelatorio from './genreldesenho';
import MDButton from 'components/MDButton';


export default function ConfiguracaoRelatorioPersonalizado(props) {

    const { relatorio_id, datasets,filtros,open,onClose } = props;



    return (
        
            <Dialog
                // fullWidth={true}
                // maxWidth={'xl'}
                fullScreen
                open={open}
                onClose={onClose}
            >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                </DialogTitle>
                
                <DialogContent>
                     <DesenhaRelatorio
                        relatorio_id={relatorio_id}
                        filtros={filtros}
                        datasets={datasets}
                    /> 
                    {/* <Exemplos/> */}
                </DialogContent>
            </Dialog>

    )

}