import React, { useContext, useEffect, useRef, useState } from 'react';
import RelatorioPadrao from '../../../relatorios/padrao';
import FiltroCustoLucroImpressao from './filtro';
import DataPrinterContext from 'hooks/DataPrinter';
import MessagesContext from 'hooks/MessagesContext';
import { copiaProfundaObjetos } from 'commons/utils';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import { formataValor } from 'commons/utils';


export default function RelatorioCustoeLucroPedido(props) {

    const { vendaAtual, open, setOpen } = props;
    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso } = messageContext;
    const [openRelatorioPadrao, setOpenRelatorioPadrao] = useState(false);
    const dataPrinterContext = useContext(DataPrinterContext);

    const handleImprimir = async (params = {
        tipos: [],
        mostraPreco: false
    }) => {

        if (!((params?.tipos?.length > 0)||(params.mostraServico))) {
            MensagemErro('Selecione pelo menos um tipo de material para imprimir o relatório');
            return;
        }

        try {
            const retorno = await api.get(`${rotafechada()}vendas/dadosrelatorios/custoelucro?venda_id=${vendaAtual}`);
            const { data } = retorno.data;

            if (!(data.length > 0)) {
                MensagemAviso('Nenhuma informação foi selecionada');
                return;
            }

            await geraRelatorio(params, data);
            setOpenRelatorioPadrao(true);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }
    }

    const geraRelatorio = async (params, data) => {


        dataPrinterContext.setEstiloColunas({ backgroundColor: '#F2F4F4', fontSize: '14px' })
        dataPrinterContext.setEstiloLinhas({ backgroundColor: 'white', fontSize: '12px' })

        dataPrinterContext.setTitulo(`Custo e Lucro dos Produtos do Pedido ${vendaAtual}`);

        const subtitulo = [];

        let nomestipos = ``;
        params?.tipos?.map((item) => {
            nomestipos = `${nomestipos}${item.nome} / `;
        });

        if (nomestipos !== '') {
            nomestipos = `Tipos ${nomestipos}`;
        }

        if (params.mostraServico){
            nomestipos = `${nomestipos} SERVIÇOS /  `;
        }

        if (nomestipos !== '') {
            subtitulo.push('Filtros: ' + nomestipos);
        }

        dataPrinterContext.setSubtitulo(subtitulo);

        const colunas = [];

        colunas.push({ id: 'referencia', label: 'Referência', sx: { width: '35px' } });
        colunas.push({ id: 'produto', label: 'Material' });
        colunas.push({ id: 'cor', label: 'Cor', sx: { width: '30px' } });
        colunas.push({ id: 'valor_custo', label: 'Custo', sx: { width: '70px' }, align: 'right' });
        if (params?.agrupaProduto) {
            colunas.push({ id: 'quantvezes', label: 'Peças', sx: { width: '20px' } });
        }
        colunas.push({ id: 'quantidade', label: 'Medida', sx: { width: '40px' } });
        colunas.push({ id: 'preco', label: 'Preço', sx: { width: '80px' }, align: 'right' });
        colunas.push({ id: 'valor_lucro', label: 'Lucro', sx: { width: '80px' }, align: 'right' });

        dataPrinterContext.setColunas(colunas);

        if (params?.agrupaProduto) {

            let dataTemp = [];

            data.map((item) => {

                let achou = false;

                for (let i = 0; i < dataTemp.length; i++) {

                    if  ((item.produto_grade_id)&&
                        (parseInt(dataTemp[i].produto_grade_id, 10) === parseInt(item.produto_grade_id, 10)) &&
                        (parseFloat(dataTemp[i].quantidade) === parseFloat(item.quantidade))) {
                        dataTemp[i].quantvezes = dataTemp[i].quantvezes + 1;
                        achou = true;
                    }
                }

                if (!achou) {
                    //add o item no vetortemp
                    dataTemp.push({
                        ...item,
                        quantvezes: 1
                    })
                }
            })

            data = copiaProfundaObjetos(dataTemp);
        }

        const linhasTemp = [];
        let totalLucro = 0;
        let totalCusto = 0;
        let totalProdutos = 0;
        data?.map((item) => {

            const tipo_produto_id = item?.Produto_Grade?.Produto?.tipo_produto_id;

            if (params.tipos.filter((item) => parseInt(item.id, 10) === parseInt(tipo_produto_id, 10)).length > 0) {

                const quantvezes = parseInt(item?.quantvezes, 10) || 1;
                const quantidade = parseFloat(item?.quantidade);
                const valor_custo = parseFloat(item?.valor_custo || item?.Produto_Grade.valor_custo) || 0;
                const preco = parseFloat(item?.valor_unitario);

                const valor_lucro = (preco - valor_custo) * quantvezes * quantidade;

                totalLucro = totalLucro + valor_lucro;
                totalProdutos = totalProdutos + (preco  * quantvezes * quantidade);
                totalCusto = totalCusto + valor_custo;

                linhasTemp.push({
                    tipo: item?.Produto_Grade?.Produto?.Tipos_Produto?.nome,
                    referencia: item?.Produto_Grade?.Produto?.referencia,
                    produto: item?.Produto_Grade?.Produto?.nome,
                    cor: item?.Produto_Grade?.Core?.nome,
                    quantvezes: `${quantvezes}x`,
                    quantidade: parseFloat(quantidade).toFixed(2) || '',
                    preco: formataValor(preco),
                    valor_custo: formataValor(valor_custo),
                    valor_lucro: formataValor(valor_lucro)
                })
            }

            if ((params.mostraServico)&&(item.Servico)){

                const quantvezes = parseInt(item?.quantvezes, 10) || 1;
                const quantidade = parseFloat(item?.quantidade);
                const valor_custo = parseFloat(item?.valor_custo || item?.Servico.valor_custo) || 0;
                const preco = parseFloat(item?.valor_unitario);

                const valor_lucro = (preco - valor_custo) * quantvezes * quantidade;

                totalLucro = totalLucro + valor_lucro;
                totalProdutos = totalProdutos + (preco  * quantvezes * quantidade);

                linhasTemp.push({
                    referencia: item?.Servico?.referencia,
                    produto: item?.Servico?.nome,
                    cor: '',
                    quantvezes: `${quantvezes}x`,
                    quantidade: parseFloat(quantidade).toFixed(2) || '',
                    preco: formataValor(preco),
                    valor_custo: formataValor(valor_custo),
                    valor_lucro: formataValor(valor_lucro)
                })

            }

        })


        const percentual_lucro = totalProdutos!==0?(totalLucro*100) / totalProdutos : '';
            //imprimindo o total
            linhasTemp.push({
                tipo: '',
                referencia: '',
                produto: 'TOTAL DO LUCRO',
                cor: '',
                quantvezes: ``,
                quantidade: '',
                valor_custo: formataValor(totalCusto),
                preco: `(${(percentual_lucro||0).toFixed(2)}%)`,
                valor_lucro: formataValor(totalLucro),
                sx: {
                    fontWeight: 'bold',
                    fontSize: '12px'
                }
            });


        dataPrinterContext.setLinhas(linhasTemp);
    }

    return (
        <>
            <RelatorioPadrao open={openRelatorioPadrao} setOpen={setOpenRelatorioPadrao} />
            <FiltroCustoLucroImpressao
                open={open}
                setOpen={setOpen}
                handleOk={handleImprimir}
            />
        </>
    )
}