
import React, { useContext, useEffect, useState } from 'react';
import MessagesContext from 'hooks/MessagesContext';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import { startOfWeek, endOfWeek, addMonths, addDays, addWeeks, addYears, endOfMonth, startOfMonth, startOfYear, endOfYear, subMonths, subDays, subWeeks, subYears } from 'date-fns'
import format from 'date-fns/format'
import { ptBR } from "date-fns/locale";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import SalesTable from "examples/Tables/SalesTable";
import DataTable from "examples/Tables/DataTable";

// Sales dashboard components
import GraficoPizza from "layouts/dashboards/sales/components/GraficoPizza";
import US from "assets/images/icons/flags/US.png";
import DE from "assets/images/icons/flags/DE.png";
import GB from "assets/images/icons/flags/GB.png";
import BR from "assets/images/icons/flags/BR.png";
import AU from "assets/images/icons/flags/AU.png";
import ARROW from "assets/images/down-arrow-dark.svg";
import STAR from "assets/images/small-logos/ribbon_star_icon.svg";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// Data
//import defaultLineChartData from "layouts/dashboards/sales/data/defaultLineChartData";
//import horizontalBarChartData from "layouts/dashboards/sales/data/horizontalBarChartData";
//import salesTableData from "layouts/dashboards/sales/data/salesTableData";
import dataTableData from "layouts/dashboards/sales/data/dataTableData";
import { formataValor } from 'commons/utils';
import { Button } from '@mui/material';

function DashboardVendas() {

  // constantes
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const [anchorElFiltro, setAnchorElFiltro] = useState(null);
  const [anchorElFiltroContasReceberPagar, setAnchorElFiltroContasReceberPagar] = useState(null);
  const [anchorElFiltroTop5Orcamentos, setAnchorElFiltroTop5Orcamentos] = useState(null);
  const [anchorElFiltroTop5Produtos, setAnchorElFiltroTop5Produtos] = useState(null);
  const [openDetalheFiltro, setOpenDetalheFiltro] = useState(false);
  const [openDetalheFiltroContasReceberPagar, setOpenDetalheFiltroContasReceberPagar] = useState(false);
  const [openDetalheFiltroTop5Orcamentos, setOpenDetalheFiltroTop5Orcamentos] = useState(false);
  const [openDetalheFiltroTop5Produtos, setOpenDetalheFiltroTop5Produtos] = useState(false);
  const [filtroContasReceberPagar, setFiltroContasReceberPagar] = useState(
    {
      labelOriginal: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,
      labelFormatada: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,

      dataInicialOriginal: startOfWeek(new Date()),
      dataInicialFormatada: (format(startOfWeek(new Date()), "yyyy-MM-dd 00:00:00")),

      dataFinalOriginal: endOfWeek(new Date()),
      dataFinalFormatada: (format(endOfWeek(new Date()), "yyyy-MM-dd 23:59:59"))
    }
  );
  const [filtro, setFiltro] = useState(
    {
      labelOriginal: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,
      labelFormatada: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,

      dataInicialOriginal: startOfWeek(new Date()),
      dataInicialFormatada: (format(startOfWeek(new Date()), "yyyy-MM-dd 00:00:00")),

      dataFinalOriginal: endOfWeek(new Date()),
      dataFinalFormatada: (format(endOfWeek(new Date()), "yyyy-MM-dd 23:59:59"))
    }
  );
  const [filtroTop5Orcamentos, setFiltroTop5Orcamentos] = useState(
    {
      labelOriginal: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,
      labelFormatada: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,

      dataInicialOriginal: startOfWeek(new Date()),
      dataInicialFormatada: (format(startOfWeek(new Date()), "yyyy-MM-dd 00:00:00")),

      dataFinalOriginal: endOfWeek(new Date()),
      dataFinalFormatada: (format(endOfWeek(new Date()), "yyyy-MM-dd 23:59:59"))
    }
  );
  const [filtroTop5Produtos, setFiltroTop5Produtos] = useState(
    {
      labelOriginal: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,
      labelFormatada: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,

      dataInicialOriginal: startOfWeek(new Date()),
      dataInicialFormatada: (format(startOfWeek(new Date()), "yyyy-MM-dd 00:00:00")),

      dataFinalOriginal: endOfWeek(new Date()),
      dataFinalFormatada: (format(endOfWeek(new Date()), "yyyy-MM-dd 23:59:59"))
    }
  );
  const [periodo, setPeriodo] = useState('Essa semana');
  const [periodoContasReceberPagar, setPeriodoContasReceberPagar] = useState('Essa semana');
  const [periodoTop5Orcamentos, setPeriodoTop5Orcamentos] = useState('Essa semana');
  const [periodoTop5Produtos, setPeriodoTop5Produtos] = useState('Essa semana');

  const [porcentagemVenda, setPorcentagemVenda] = useState(0);
  const [valorVenda, setValorVenda] = useState(0);
  const [sinalVenda, setSinalVenda] = useState('warning');

  const [porcentagemOrcamento, setPorcentagemOrcamento] = useState(0);
  const [valorOrcamento, setValorOrcamento] = useState(0);
  const [sinalOrcamento, setSinalOrcamento] = useState('warning');

  const [porcentagemPessoa, setPorcentagemPessoa] = useState(0);
  const [quantidadePessoa, setQuantidadePessoa] = useState(0);
  const [sinalPessoa, setSinalPessoa] = useState('warning');

  // DefaultStatisticsCard state for the dropdown value
  const [vendasDropdownValue, setVendasDropdownValue] = useState("Hoje");
  const [orcamentosDropdownValue, setOrcamentosDropdownValue] = useState("Hoje");
  const [pessoaDropdownValue, setPessoaDropdownValue] = useState("Hoje");

  // DefaultStatisticsCard state for the dropdown action
  const [vendasDropdown, setVendasDropdown] = useState(null);
  const [orcamentosDropdown, setOrcamentosDropdown] = useState(null);
  const [pessoaDropdown, setPessoaDropdown] = useState(null);

  // DefaultStatisticsCard handler for the dropdown action
  const openVendasDropdown = ({ currentTarget }) => {
    setVendasDropdown(currentTarget)
  };

  const closeVendasDropdown = ({ currentTarget }) => {
    setVendasDropdown(null);
    setVendasDropdownValue(currentTarget.innerText || vendasDropdownValue);
  };
  const openOrcamentosDropdown = ({ currentTarget }) => setOrcamentosDropdown(currentTarget);
  const closeOrcamentosDropdown = ({ currentTarget }) => {
    setOrcamentosDropdown(null);
    setOrcamentosDropdownValue(currentTarget.innerText || vendasDropdownValue);
  };
  const openPessoaDropdown = ({ currentTarget }) => setPessoaDropdown(currentTarget);
  const closePessoaDropdown = ({ currentTarget }) => {
    setPessoaDropdown(null);
    setPessoaDropdownValue(currentTarget.innerText || vendasDropdownValue);
  };

  const [dadosGraficoLinhas, setDadosGraficoLinhas] = useState({ //Grafico de linhas fluxo de caixa
    labels: ["exemplo 1", "exemplo 2"],
    datasets: [
      {
        label: "Contas Recebidas R$",
        color: "info",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        label: "Contas Pagas R$",
        color: "warning",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
  });

  const [dadosPagarReceber, setDadosPagarReceber] = useState({ //Grafico de linhas contas pagar/receber
    labels: ["exemplo 1", "exemplo 2"],
    datasets: [
      {
        label: "Contas a Receber R$",
        color: "info",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        label: "Contas a Pagar R$",
        color: "warning",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
  });

  const [horizontalBarChartData, setHorizontalBarChartData] = useState({ //Grafico de barras
    labels: ['Orçamento: 256', 'Orçamento: 6', 'Orçamento: 5', 'Orçamento: 2', 'Orçamento: 256'],
    datasets: [
      {
        label: "R$",
        color: ["info"],
        data: [70, 40, 20, 18, 10],
      }
    ],
  });

  const [salesTableData, setSalesTableData] = useState([ //top 5 Produtos
  ]);

  // Dropdown menu template for the DefaultStatisticsCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      <MenuItem onClick={close}>Último ano</MenuItem>
      <MenuItem onClick={close}>Último mês</MenuItem>
      <MenuItem onClick={close}>Última semana</MenuItem>
      <MenuItem onClick={close}>Hoje</MenuItem>
      <MenuItem onClick={close}>Essa semana</MenuItem>
      <MenuItem onClick={close}>Esse mês</MenuItem>
      <MenuItem onClick={close}>Esse ano</MenuItem>
    </Menu>
  );

  const buscaVendas = async () => {
    try {
      let data_ini = 0
      let data_fim = 0
      let data_ini_antes = 0
      let data_fim_antes = 0

      if (vendasDropdownValue == 'Último ano') {
        const agora = new Date();
        const inicioDoAnoAnterior = new Date(agora.getFullYear() - 1, 0, 1);
        const fimDoAnoAnterior = new Date(agora.getFullYear() - 1, 11, 31);
        const inicioDeDoisAnosAtras = new Date(agora.getFullYear() - 2, 0, 1);
        const fimDeDoisAnosAtras = new Date(agora.getFullYear() - 2, 11, 31);

        data_ini = format(inicioDoAnoAnterior, "yyyy-MM-dd 00:00:00");
        data_fim = format(fimDoAnoAnterior, "yyyy-MM-dd 23:59:59");
        data_ini_antes = format(inicioDeDoisAnosAtras, "yyyy-MM-dd 00:00:00");
        data_fim_antes = format(fimDeDoisAnosAtras, "yyyy-MM-dd 23:59:59");

      }

      if (vendasDropdownValue == 'Último mês') {
        const agora = new Date();
        const inicioDoMesAnterior = new Date(agora.getFullYear(), agora.getMonth() - 1, 1);
        const fimDoMesAnterior = new Date(agora.getFullYear(), agora.getMonth(), 0);
        const inicioDeDoisMesesAtras = new Date(agora.getFullYear(), agora.getMonth() - 2, 1);
        const fimDeDoisMesesAtras = new Date(agora.getFullYear(), agora.getMonth() - 1, 0);

        data_ini = format(inicioDoMesAnterior, "yyyy-MM-dd 00:00:00");
        data_fim = format(fimDoMesAnterior, "yyyy-MM-dd 23:59:59");
        data_ini_antes = format(inicioDeDoisMesesAtras, "yyyy-MM-dd 00:00:00");
        data_fim_antes = format(fimDeDoisMesesAtras, "yyyy-MM-dd 23:59:59");
      }

      if (vendasDropdownValue == 'Última semana') {
        const agora = new Date();
        const diaDaSemana = agora.getDay();
        const inicioDaSemanaAnterior = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() - diaDaSemana - 7);
        const fimDaSemanaAnterior = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() - diaDaSemana - 1);
        const inicioDeDuasSemanasAtras = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() - diaDaSemana - 14);
        const fimDeDuasSemanasAtras = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() - diaDaSemana - 8);

        data_ini = format(inicioDaSemanaAnterior, "yyyy-MM-dd 00:00:00");
        data_fim = format(fimDaSemanaAnterior, "yyyy-MM-dd 23:59:59");
        data_ini_antes = format(inicioDeDuasSemanasAtras, "yyyy-MM-dd 00:00:00");
        data_fim_antes = format(fimDeDuasSemanasAtras, "yyyy-MM-dd 23:59:59");
      }

      if (vendasDropdownValue == 'Hoje') {
        const ontem = new Date();
        ontem.setDate(ontem.getDate() - 1);

        data_ini = format(new Date(), "yyyy-MM-dd 00:00:00")
        data_fim = format(new Date(), "yyyy-MM-dd 23:59:59")
        data_ini_antes = format(ontem, "yyyy-MM-dd 00:00:00")
        data_fim_antes = format(ontem, "yyyy-MM-dd 23:59:59")
      }

      if (vendasDropdownValue == 'Essa semana') {
        const agora = new Date();
        const diaDaSemana = agora.getDay();
        const inicioDaSemana = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() - diaDaSemana);
        const fimDaSemana = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() + (6 - diaDaSemana));
        const inicioDaSemanaPassada = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() - diaDaSemana - 7);
        const fimDaSemanaPassada = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() - diaDaSemana - 1);

        data_ini = format(inicioDaSemana, "yyyy-MM-dd 00:00:00");
        data_fim = format(fimDaSemana, "yyyy-MM-dd 23:59:59");
        data_ini_antes = format(inicioDaSemanaPassada, "yyyy-MM-dd 00:00:00");
        data_fim_antes = format(fimDaSemanaPassada, "yyyy-MM-dd 23:59:59");
      }

      if (vendasDropdownValue == 'Esse mês') {
        const agora = new Date();
        const inicioDoMes = new Date(agora.getFullYear(), agora.getMonth(), 1);
        const fimDoMes = new Date(agora.getFullYear(), agora.getMonth() + 1, 0);
        const inicioDoMesPassado = new Date(agora.getFullYear(), agora.getMonth() - 1, 1);
        const fimDoMesPassado = new Date(agora.getFullYear(), agora.getMonth(), 0);

        data_ini = format(inicioDoMes, "yyyy-MM-dd 00:00:00");
        data_fim = format(fimDoMes, "yyyy-MM-dd 23:59:59");
        data_ini_antes = format(inicioDoMesPassado, "yyyy-MM-dd 00:00:00");
        data_fim_antes = format(fimDoMesPassado, "yyyy-MM-dd 23:59:59");
      }

      if (vendasDropdownValue == 'Esse ano') {
        const agora = new Date();
        const inicioDoAno = new Date(agora.getFullYear(), 0, 1);
        const fimDoAno = new Date(agora.getFullYear(), 11, 31);
        const inicioDoAnoPassado = new Date(agora.getFullYear() - 1, 0, 1);
        const fimDoAnoPassado = new Date(agora.getFullYear() - 1, 11, 31);

        data_ini = format(inicioDoAno, "yyyy-MM-dd 00:00:00");
        data_fim = format(fimDoAno, "yyyy-MM-dd 23:59:59");
        data_ini_antes = format(inicioDoAnoPassado, "yyyy-MM-dd 00:00:00");
        data_fim_antes = format(fimDoAnoPassado, "yyyy-MM-dd 23:59:59");
      }

      MostraTelaEspera('');
      let url = `${rotafechada()}dashboard/valor_vendas?data_ini=${new Date(data_ini)}&data_fim=${new Date(data_fim)}&data_ini_antes=${new Date(data_ini_antes)}&data_fim_antes=${new Date(data_fim_antes)}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      setValorVenda(formataValor(data?.total_periodo))
      //if (data.porcentagem) {
      setPorcentagemVenda(data?.sinal == '+' ? data?.porcentagem?.toFixed(2) + '%' : '-' + data?.porcentagem?.toFixed(2) + '%')
      setSinalVenda(data.sinal == '+' ? 'info' : 'warning')
      //}

      FechaTelaEspera();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaOrcamentos = async () => {
    try {

      let data_ini = 0
      let data_fim = 0
      let data_ini_antes = 0
      let data_fim_antes = 0

      if (orcamentosDropdownValue == 'Último ano') {
        const agora = new Date();
        const inicioDoAnoAnterior = new Date(agora.getFullYear() - 1, 0, 1);
        const fimDoAnoAnterior = new Date(agora.getFullYear() - 1, 11, 31);
        const inicioDeDoisAnosAtras = new Date(agora.getFullYear() - 2, 0, 1);
        const fimDeDoisAnosAtras = new Date(agora.getFullYear() - 2, 11, 31);

        data_ini = format(inicioDoAnoAnterior, "yyyy-MM-dd 00:00:00");
        data_fim = format(fimDoAnoAnterior, "yyyy-MM-dd 23:59:59");
        data_ini_antes = format(inicioDeDoisAnosAtras, "yyyy-MM-dd 00:00:00");
        data_fim_antes = format(fimDeDoisAnosAtras, "yyyy-MM-dd 23:59:59");

      }

      if (orcamentosDropdownValue == 'Último mês') {
        const agora = new Date();
        const inicioDoMesAnterior = new Date(agora.getFullYear(), agora.getMonth() - 1, 1);
        const fimDoMesAnterior = new Date(agora.getFullYear(), agora.getMonth(), 0);
        const inicioDeDoisMesesAtras = new Date(agora.getFullYear(), agora.getMonth() - 2, 1);
        const fimDeDoisMesesAtras = new Date(agora.getFullYear(), agora.getMonth() - 1, 0);

        data_ini = format(inicioDoMesAnterior, "yyyy-MM-dd 00:00:00");
        data_fim = format(fimDoMesAnterior, "yyyy-MM-dd 23:59:59");
        data_ini_antes = format(inicioDeDoisMesesAtras, "yyyy-MM-dd 00:00:00");
        data_fim_antes = format(fimDeDoisMesesAtras, "yyyy-MM-dd 23:59:59");
      }

      if (orcamentosDropdownValue == 'Última semana') {
        const agora = new Date();
        const diaDaSemana = agora.getDay();
        const inicioDaSemanaAnterior = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() - diaDaSemana - 7);
        const fimDaSemanaAnterior = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() - diaDaSemana - 1);
        const inicioDeDuasSemanasAtras = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() - diaDaSemana - 14);
        const fimDeDuasSemanasAtras = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() - diaDaSemana - 8);

        data_ini = format(inicioDaSemanaAnterior, "yyyy-MM-dd 00:00:00");
        data_fim = format(fimDaSemanaAnterior, "yyyy-MM-dd 23:59:59");
        data_ini_antes = format(inicioDeDuasSemanasAtras, "yyyy-MM-dd 00:00:00");
        data_fim_antes = format(fimDeDuasSemanasAtras, "yyyy-MM-dd 23:59:59");
      }

      if (orcamentosDropdownValue == 'Hoje') {
        const ontem = new Date();
        ontem.setDate(ontem.getDate() - 1);

        data_ini = format(new Date(), "yyyy-MM-dd 00:00:00")
        data_fim = format(new Date(), "yyyy-MM-dd 23:59:59")
        data_ini_antes = format(ontem, "yyyy-MM-dd 00:00:00")
        data_fim_antes = format(ontem, "yyyy-MM-dd 23:59:59")
      }

      if (orcamentosDropdownValue == 'Essa semana') {
        const agora = new Date();
        const diaDaSemana = agora.getDay();
        const inicioDaSemana = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() - diaDaSemana);
        const fimDaSemana = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() + (6 - diaDaSemana));
        const inicioDaSemanaPassada = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() - diaDaSemana - 7);
        const fimDaSemanaPassada = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() - diaDaSemana - 1);

        data_ini = format(inicioDaSemana, "yyyy-MM-dd 00:00:00");
        data_fim = format(fimDaSemana, "yyyy-MM-dd 23:59:59");
        data_ini_antes = format(inicioDaSemanaPassada, "yyyy-MM-dd 00:00:00");
        data_fim_antes = format(fimDaSemanaPassada, "yyyy-MM-dd 23:59:59");
      }

      if (orcamentosDropdownValue == 'Esse mês') {
        const agora = new Date();
        const inicioDoMes = new Date(agora.getFullYear(), agora.getMonth(), 1);
        const fimDoMes = new Date(agora.getFullYear(), agora.getMonth() + 1, 0);
        const inicioDoMesPassado = new Date(agora.getFullYear(), agora.getMonth() - 1, 1);
        const fimDoMesPassado = new Date(agora.getFullYear(), agora.getMonth(), 0);

        data_ini = format(inicioDoMes, "yyyy-MM-dd 00:00:00");
        data_fim = format(fimDoMes, "yyyy-MM-dd 23:59:59");
        data_ini_antes = format(inicioDoMesPassado, "yyyy-MM-dd 00:00:00");
        data_fim_antes = format(fimDoMesPassado, "yyyy-MM-dd 23:59:59");
      }

      if (orcamentosDropdownValue == 'Esse ano') {
        const agora = new Date();
        const inicioDoAno = new Date(agora.getFullYear(), 0, 1);
        const fimDoAno = new Date(agora.getFullYear(), 11, 31);
        const inicioDoAnoPassado = new Date(agora.getFullYear() - 1, 0, 1);
        const fimDoAnoPassado = new Date(agora.getFullYear() - 1, 11, 31);

        data_ini = format(inicioDoAno, "yyyy-MM-dd 00:00:00");
        data_fim = format(fimDoAno, "yyyy-MM-dd 23:59:59");
        data_ini_antes = format(inicioDoAnoPassado, "yyyy-MM-dd 00:00:00");
        data_fim_antes = format(fimDoAnoPassado, "yyyy-MM-dd 23:59:59");
      }


      MostraTelaEspera('');
      let url = `${rotafechada()}dashboard/valor_orcamentos?data_ini=${new Date(data_ini)}&data_fim=${new Date(data_fim)}&data_ini_antes=${new Date(data_ini_antes)}&data_fim_antes=${new Date(data_fim_antes)}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      setValorOrcamento(formataValor(data?.total_periodo))
      //if (data.porcentagem) {
      setPorcentagemOrcamento(data?.sinal == '+' ? data?.porcentagem?.toFixed(2) + '%' : '-' + data?.porcentagem?.toFixed(2) + '%')
      setSinalOrcamento(data.sinal == '+' ? 'info' : 'warning')
      //}

      FechaTelaEspera();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaPessoas = async () => {
    try {

      let data_ini = 0
      let data_fim = 0
      let data_ini_antes = 0
      let data_fim_antes = 0

      if (pessoaDropdownValue == 'Último ano') {
        const agora = new Date();
        const inicioDoAnoAnterior = new Date(agora.getFullYear() - 1, 0, 1);
        const fimDoAnoAnterior = new Date(agora.getFullYear() - 1, 11, 31);
        const inicioDeDoisAnosAtras = new Date(agora.getFullYear() - 2, 0, 1);
        const fimDeDoisAnosAtras = new Date(agora.getFullYear() - 2, 11, 31);

        data_ini = format(inicioDoAnoAnterior, "yyyy-MM-dd 00:00:00");
        data_fim = format(fimDoAnoAnterior, "yyyy-MM-dd 23:59:59");
        data_ini_antes = format(inicioDeDoisAnosAtras, "yyyy-MM-dd 00:00:00");
        data_fim_antes = format(fimDeDoisAnosAtras, "yyyy-MM-dd 23:59:59");

      }

      if (pessoaDropdownValue == 'Último mês') {
        const agora = new Date();
        const inicioDoMesAnterior = new Date(agora.getFullYear(), agora.getMonth() - 1, 1);
        const fimDoMesAnterior = new Date(agora.getFullYear(), agora.getMonth(), 0);
        const inicioDeDoisMesesAtras = new Date(agora.getFullYear(), agora.getMonth() - 2, 1);
        const fimDeDoisMesesAtras = new Date(agora.getFullYear(), agora.getMonth() - 1, 0);

        data_ini = format(inicioDoMesAnterior, "yyyy-MM-dd 00:00:00");
        data_fim = format(fimDoMesAnterior, "yyyy-MM-dd 23:59:59");
        data_ini_antes = format(inicioDeDoisMesesAtras, "yyyy-MM-dd 00:00:00");
        data_fim_antes = format(fimDeDoisMesesAtras, "yyyy-MM-dd 23:59:59");
      }

      if (pessoaDropdownValue == 'Última semana') {
        const agora = new Date();
        const diaDaSemana = agora.getDay();
        const inicioDaSemanaAnterior = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() - diaDaSemana - 7);
        const fimDaSemanaAnterior = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() - diaDaSemana - 1);
        const inicioDeDuasSemanasAtras = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() - diaDaSemana - 14);
        const fimDeDuasSemanasAtras = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() - diaDaSemana - 8);

        data_ini = format(inicioDaSemanaAnterior, "yyyy-MM-dd 00:00:00");
        data_fim = format(fimDaSemanaAnterior, "yyyy-MM-dd 23:59:59");
        data_ini_antes = format(inicioDeDuasSemanasAtras, "yyyy-MM-dd 00:00:00");
        data_fim_antes = format(fimDeDuasSemanasAtras, "yyyy-MM-dd 23:59:59");
      }

      if (pessoaDropdownValue == 'Hoje') {
        const ontem = new Date();
        ontem.setDate(ontem.getDate() - 1);

        data_ini = format(new Date(), "yyyy-MM-dd 00:00:00")
        data_fim = format(new Date(), "yyyy-MM-dd 23:59:59")
        data_ini_antes = format(ontem, "yyyy-MM-dd 00:00:00")
        data_fim_antes = format(ontem, "yyyy-MM-dd 23:59:59")
      }

      if (pessoaDropdownValue == 'Essa semana') {
        const agora = new Date();
        const diaDaSemana = agora.getDay();
        const inicioDaSemana = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() - diaDaSemana);
        const fimDaSemana = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() + (6 - diaDaSemana));
        const inicioDaSemanaPassada = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() - diaDaSemana - 7);
        const fimDaSemanaPassada = new Date(agora.getFullYear(), agora.getMonth(), agora.getDate() - diaDaSemana - 1);

        data_ini = format(inicioDaSemana, "yyyy-MM-dd 00:00:00");
        data_fim = format(fimDaSemana, "yyyy-MM-dd 23:59:59");
        data_ini_antes = format(inicioDaSemanaPassada, "yyyy-MM-dd 00:00:00");
        data_fim_antes = format(fimDaSemanaPassada, "yyyy-MM-dd 23:59:59");
      }

      if (pessoaDropdownValue == 'Esse mês') {
        const agora = new Date();
        const inicioDoMes = new Date(agora.getFullYear(), agora.getMonth(), 1);
        const fimDoMes = new Date(agora.getFullYear(), agora.getMonth() + 1, 0);
        const inicioDoMesPassado = new Date(agora.getFullYear(), agora.getMonth() - 1, 1);
        const fimDoMesPassado = new Date(agora.getFullYear(), agora.getMonth(), 0);

        data_ini = format(inicioDoMes, "yyyy-MM-dd 00:00:00");
        data_fim = format(fimDoMes, "yyyy-MM-dd 23:59:59");
        data_ini_antes = format(inicioDoMesPassado, "yyyy-MM-dd 00:00:00");
        data_fim_antes = format(fimDoMesPassado, "yyyy-MM-dd 23:59:59");
      }

      if (pessoaDropdownValue == 'Esse ano') {
        const agora = new Date();
        const inicioDoAno = new Date(agora.getFullYear(), 0, 1);
        const fimDoAno = new Date(agora.getFullYear(), 11, 31);
        const inicioDoAnoPassado = new Date(agora.getFullYear() - 1, 0, 1);
        const fimDoAnoPassado = new Date(agora.getFullYear() - 1, 11, 31);

        data_ini = format(inicioDoAno, "yyyy-MM-dd 00:00:00");
        data_fim = format(fimDoAno, "yyyy-MM-dd 23:59:59");
        data_ini_antes = format(inicioDoAnoPassado, "yyyy-MM-dd 00:00:00");
        data_fim_antes = format(fimDoAnoPassado, "yyyy-MM-dd 23:59:59");
      }

      MostraTelaEspera('');
      let url = `${rotafechada()}dashboard/qtd_pessoas?data_ini=${new Date(data_ini)}&data_fim=${new Date(data_fim)}&data_ini_antes=${new Date(data_ini_antes)}&data_fim_antes=${new Date(data_fim_antes)}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      setQuantidadePessoa(data?.total_periodo)
      if (data.porcentagem) {
        setPorcentagemPessoa(data?.sinal == '+' ? data?.porcentagem?.toFixed(2) + '%' : '-' + data?.porcentagem?.toFixed(2) + '%')
        setSinalPessoa(data.sinal == '+' ? 'info' : 'warning')
      }

      FechaTelaEspera();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaFluxoCaixa = async () => {
    let periodo_temp = ''
    let vetor_periodo = [{}]

    if (periodo == 'Esse ano') {
      periodo_temp = 'ano'
      vetor_periodo = [
        { periodo: 'Janeiro', data: format(new Date(new Date().getFullYear(), 0, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Fevereiro', data: format(new Date(new Date().getFullYear(), 1, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Março', data: format(new Date(new Date().getFullYear(), 2, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Abril', data: format(new Date(new Date().getFullYear(), 3, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Maio', data: format(new Date(new Date().getFullYear(), 4, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Junho', data: format(new Date(new Date().getFullYear(), 5, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Julho', data: format(new Date(new Date().getFullYear(), 6, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Agosto', data: format(new Date(new Date().getFullYear(), 7, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Setembro', data: format(new Date(new Date().getFullYear(), 8, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Outubro', data: format(new Date(new Date().getFullYear(), 9, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Novembro', data: format(new Date(new Date().getFullYear(), 10, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Dezembro', data: format(new Date(new Date().getFullYear(), 11, 31), "yyyy-MM-dd 23:59:59"), pagas: '', recebidas: '' }
      ];
    }

    if (periodo == 'Último ano') {
      periodo_temp = 'ano'
      let lastYear = subYears(new Date(), 1);
      vetor_periodo = [
        { periodo: 'Janeiro', data: format(new Date(lastYear.getFullYear(), 0, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Fevereiro', data: format(new Date(lastYear.getFullYear(), 1, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Março', data: format(new Date(lastYear.getFullYear(), 2, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Abril', data: format(new Date(lastYear.getFullYear(), 3, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Maio', data: format(new Date(lastYear.getFullYear(), 4, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Junho', data: format(new Date(lastYear.getFullYear(), 5, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Julho', data: format(new Date(lastYear.getFullYear(), 6, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Agosto', data: format(new Date(lastYear.getFullYear(), 7, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Setembro', data: format(new Date(lastYear.getFullYear(), 8, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Outubro', data: format(new Date(lastYear.getFullYear(), 9, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Novembro', data: format(new Date(lastYear.getFullYear(), 10, 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Dezembro', data: format(new Date(lastYear.getFullYear(), 11, 31), "yyyy-MM-dd 23:59:59"), pagas: '', recebidas: '' }
      ];
    }


    if (periodo == 'Esse mês') {
      periodo_temp = 'mes'
      let mesAtual = new Date().getMonth();
      let anoAtual = new Date().getFullYear();
      let diasNoMes = new Date(anoAtual, mesAtual + 1, 0).getDate();

      vetor_periodo = [];

      for (let i = 0; i < diasNoMes; i++) {
        let dia;
        if (i != diasNoMes - 1) {
          dia = format(new Date(anoAtual, mesAtual, i + 1), "yyyy-MM-dd 00:00:00");
        } else {
          dia = format(new Date(anoAtual, mesAtual, i + 1), "yyyy-MM-dd 23:59:59");
        }
        vetor_periodo.push({
          periodo: `Dia ${i + 1}`,
          data: dia,
          pagas: '',
          recebidas: ''
        });
      }
    }

    if (periodo == 'Último mês') {
      periodo_temp = 'mes'
      let dataAtual = new Date();
      let mesAnterior = dataAtual.getMonth() - 1;
      let anoAtual = dataAtual.getFullYear();
      // Se o mês anterior for dezembro do ano passado.
      if (mesAnterior < 0) {
        mesAnterior = 11;
        anoAtual -= 1;
      }
      let diasNoMes = new Date(anoAtual, mesAnterior + 1, 0).getDate();

      vetor_periodo = [];

      for (let i = 0; i < diasNoMes; i++) {
        let dia;
        if (i != diasNoMes - 1) {
          dia = format(new Date(anoAtual, mesAnterior, i + 1), "yyyy-MM-dd 00:00:00");
        } else {
          dia = format(new Date(anoAtual, mesAnterior, i + 1), "yyyy-MM-dd 23:59:59");
        }
        vetor_periodo.push({
          periodo: `Dia ${i + 1}`,
          data: dia,
          pagas: '',
          recebidas: ''
        });
      }
    }

    if (periodo == 'Essa semana') {
      periodo_temp = 'semana'
      vetor_periodo = [
        { periodo: 'Domingo', data: format(startOfWeek(new Date()), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Segunda', data: format(addDays(startOfWeek(new Date()), 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Terça', data: format(addDays(startOfWeek(new Date()), 2), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Quarta', data: format(addDays(startOfWeek(new Date()), 3), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Quinta', data: format(addDays(startOfWeek(new Date()), 4), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Sexta', data: format(addDays(startOfWeek(new Date()), 5), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Sábado', data: format(addDays(startOfWeek(new Date()), 6), "yyyy-MM-dd 23:59:59"), pagas: '', recebidas: '' }
      ];
    }

    if (periodo == 'Última semana') {
      periodo_temp = 'semana'
      let lastWeek = subWeeks(new Date(), 1);
      vetor_periodo = [
        { periodo: 'Domingo', data: format(startOfWeek(lastWeek), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Segunda', data: format(addDays(startOfWeek(lastWeek), 1), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Terça', data: format(addDays(startOfWeek(lastWeek), 2), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Quarta', data: format(addDays(startOfWeek(lastWeek), 3), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Quinta', data: format(addDays(startOfWeek(lastWeek), 4), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Sexta', data: format(addDays(startOfWeek(lastWeek), 5), "yyyy-MM-dd 00:00:00"), pagas: '', recebidas: '' },
        { periodo: 'Sábado', data: format(addDays(startOfWeek(lastWeek), 6), "yyyy-MM-dd 23:59:59"), pagas: '', recebidas: '' }
      ];
    }

    try {
      MostraTelaEspera('');
      let url = `${rotafechada()}dashboard/fluxo_caixa?periodo=${periodo_temp}`;
      const retorno = await api.post(encodeURI(url), vetor_periodo);
      const { data } = retorno.data;

      if (periodo_temp == 'semana' || periodo_temp == 'mes') {
        for (let conta of data.result_contas_pagas) {
          conta.total = parseFloat(conta.total);
          conta.data = format(new Date(conta.data), "yyyy-MM-dd");
        }

        for (let conta of data.result_contas_recebidas) {
          conta.total = parseFloat(conta.total);
          conta.data = format(new Date(conta.data), "yyyy-MM-dd");
        }

        vetor_periodo = vetor_periodo.map(obj => {
          if (obj.data) {
            let data = obj.data.split(' ')[0]; // separa a data e a hora pelo espaço e pega apenas a data
            return { ...obj, data }; // retorna um novo objeto com a data atualizada
          } else {
            // console.log('obj.data está indefinido para', obj);
            return obj; // retorna o objeto original se obj.data estiver indefinido
          }
        });
      }

      if (periodo_temp == 'ano') {
        vetor_periodo.forEach(obj => { // pega apenas o ano e o mês
          obj.data = obj.data.slice(0, 7); // modifica a data diretamente no objeto original
        });
      }


      let result_contas_pagas = data.result_contas_pagas
      let result_contas_recebidas = data.result_contas_recebidas

      vetor_periodo.forEach((item) => {
        result_contas_pagas.forEach((conta_paga) => {
          if (item.data === conta_paga.data) {
            item.pagas = conta_paga.total;
          }
        });

        result_contas_recebidas.forEach((conta_recebida) => {
          if (item.data === conta_recebida.data) {
            item.recebidas = conta_recebida.total;
          }
        });
      });


      // console.log('data****', data);
      // console.log('vetor_periodo****', vetor_periodo);

      let novasLabels = vetor_periodo.map(item => item.periodo);
      let novosDadosRecebidas = vetor_periodo.map(item => item.recebidas || 0);
      let novosDadosPagas = vetor_periodo.map(item => item.pagas || 0);

      setDadosGraficoLinhas({
        labels: novasLabels,
        datasets: [
          {
            label: "Contas Recebidas R$",
            color: "info",
            data: novosDadosRecebidas,
          },
          {
            label: "Contas Pagas R$",
            color: "warning",
            data: novosDadosPagas,
          },
        ],
      });

      // setdadosGraficoPizza(prevState => ({
      //   ...prevState,
      //   datasets: {
      //     ...prevState.datasets,
      //     data: [parseInt(data.qtd_orcamentos), parseInt(data.qtd_vendas)]
      //   }
      // }));




      FechaTelaEspera();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaContasReceberPagar = async () => {
    let periodo_temp = ''
    let vetor_periodo = [{}]

    if (periodoContasReceberPagar == 'Esse ano') {
      periodo_temp = 'ano'
      vetor_periodo = [
        { periodo: 'Janeiro', data: format(new Date(new Date().getFullYear(), 0, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Fevereiro', data: format(new Date(new Date().getFullYear(), 1, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Março', data: format(new Date(new Date().getFullYear(), 2, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Abril', data: format(new Date(new Date().getFullYear(), 3, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Maio', data: format(new Date(new Date().getFullYear(), 4, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Junho', data: format(new Date(new Date().getFullYear(), 5, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Julho', data: format(new Date(new Date().getFullYear(), 6, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Agosto', data: format(new Date(new Date().getFullYear(), 7, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Setembro', data: format(new Date(new Date().getFullYear(), 8, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Outubro', data: format(new Date(new Date().getFullYear(), 9, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Novembro', data: format(new Date(new Date().getFullYear(), 10, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Dezembro', data: format(new Date(new Date().getFullYear(), 11, 31), "yyyy-MM-dd 23:59:59"), pagar: '', receber: '' }
      ];
    }

    if (periodoContasReceberPagar == 'Último ano') {
      periodo_temp = 'ano'
      let lastYear = subYears(new Date(), 1);
      vetor_periodo = [
        { periodo: 'Janeiro', data: format(new Date(lastYear.getFullYear(), 0, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Fevereiro', data: format(new Date(lastYear.getFullYear(), 1, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Março', data: format(new Date(lastYear.getFullYear(), 2, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Abril', data: format(new Date(lastYear.getFullYear(), 3, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Maio', data: format(new Date(lastYear.getFullYear(), 4, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Junho', data: format(new Date(lastYear.getFullYear(), 5, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Julho', data: format(new Date(lastYear.getFullYear(), 6, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Agosto', data: format(new Date(lastYear.getFullYear(), 7, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Setembro', data: format(new Date(lastYear.getFullYear(), 8, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Outubro', data: format(new Date(lastYear.getFullYear(), 9, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Novembro', data: format(new Date(lastYear.getFullYear(), 10, 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Dezembro', data: format(new Date(lastYear.getFullYear(), 11, 31), "yyyy-MM-dd 23:59:59"), pagar: '', receber: '' }
      ];
    }

    if (periodoContasReceberPagar == 'Esse mês') {
      periodo_temp = 'mes'
      let mesAtual = new Date().getMonth();
      let anoAtual = new Date().getFullYear();
      let diasNoMes = new Date(anoAtual, mesAtual + 1, 0).getDate();

      vetor_periodo = [];

      for (let i = 0; i < diasNoMes; i++) {
        let dia;
        if (i != diasNoMes - 1) {
          dia = format(new Date(anoAtual, mesAtual, i + 1), "yyyy-MM-dd 00:00:00");
        } else {
          dia = format(new Date(anoAtual, mesAtual, i + 1), "yyyy-MM-dd 23:59:59");
        }
        vetor_periodo.push({
          periodo: `Dia ${i + 1}`,
          data: dia,
          pagar: '',
          receber: ''
        });
      }
    }

    if (periodoContasReceberPagar == 'Último mês') {
      periodo_temp = 'mes'
      let dataAtual = new Date();
      let mesAnterior = dataAtual.getMonth() - 1;
      let anoAtual = dataAtual.getFullYear();
      // Se o mês anterior for dezembro do ano passado.
      if (mesAnterior < 0) {
        mesAnterior = 11;
        anoAtual -= 1;
      }
      let diasNoMes = new Date(anoAtual, mesAnterior + 1, 0).getDate();

      vetor_periodo = [];

      for (let i = 0; i < diasNoMes; i++) {
        let dia;
        if (i != diasNoMes - 1) {
          dia = format(new Date(anoAtual, mesAnterior, i + 1), "yyyy-MM-dd 00:00:00");
        } else {
          dia = format(new Date(anoAtual, mesAnterior, i + 1), "yyyy-MM-dd 23:59:59");
        }
        vetor_periodo.push({
          periodo: `Dia ${i + 1}`,
          data: dia,
          pagar: '',
          receber: ''
        });
      }
    }

    if (periodoContasReceberPagar == 'Essa semana') {
      periodo_temp = 'semana'
      vetor_periodo = [
        { periodo: 'Domingo', data: format(startOfWeek(new Date()), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Segunda', data: format(addDays(startOfWeek(new Date()), 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Terça', data: format(addDays(startOfWeek(new Date()), 2), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Quarta', data: format(addDays(startOfWeek(new Date()), 3), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Quinta', data: format(addDays(startOfWeek(new Date()), 4), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Sexta', data: format(addDays(startOfWeek(new Date()), 5), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Sábado', data: format(addDays(startOfWeek(new Date()), 6), "yyyy-MM-dd 23:59:59"), pagar: '', receber: '' }
      ];
    }

    if (periodoContasReceberPagar == 'Última semana') {
      periodo_temp = 'semana'
      let lastWeek = subWeeks(new Date(), 1);
      vetor_periodo = [
        { periodo: 'Domingo', data: format(startOfWeek(lastWeek), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Segunda', data: format(addDays(startOfWeek(lastWeek), 1), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Terça', data: format(addDays(startOfWeek(lastWeek), 2), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Quarta', data: format(addDays(startOfWeek(lastWeek), 3), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Quinta', data: format(addDays(startOfWeek(lastWeek), 4), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Sexta', data: format(addDays(startOfWeek(lastWeek), 5), "yyyy-MM-dd 00:00:00"), pagar: '', receber: '' },
        { periodo: 'Sábado', data: format(addDays(startOfWeek(lastWeek), 6), "yyyy-MM-dd 23:59:59"), pagar: '', receber: '' }
      ];
    }

    try {
      MostraTelaEspera('');
      let url = `${rotafechada()}dashboard/conta_receber_pagar?periodo=${periodo_temp}`;
      const retorno = await api.post(encodeURI(url), vetor_periodo);
      const { data } = retorno.data;

      if (periodo_temp == 'semana' || periodo_temp == 'mes') {
        for (let conta of data.result_contas_pagar) {
          conta.total = parseFloat(conta.total);
          conta.data = format(new Date(conta.data), "yyyy-MM-dd");
        }

        for (let conta of data.result_contas_receber) {
          conta.total = parseFloat(conta.total);
          conta.data = format(new Date(conta.data), "yyyy-MM-dd");
        }

        vetor_periodo = vetor_periodo.map(obj => {
          if (obj.data) {
            let data = obj.data.split(' ')[0]; // separa a data e a hora pelo espaço e pega apenas a data
            return { ...obj, data }; // retorna um novo objeto com a data atualizada
          } else {
            // console.log('obj.data está indefinido para', obj);
            return obj; // retorna o objeto original se obj.data estiver indefinido
          }
        });
      }

      if (periodo_temp == 'ano') {
        vetor_periodo.forEach(obj => { // pega apenas o ano e o mês
          obj.data = obj.data.slice(0, 7); // modifica a data diretamente no objeto original
        });
      }


      let result_contas_pagar = data.result_contas_pagar
      let result_contas_receber = data.result_contas_receber

      vetor_periodo.forEach((item) => {
        result_contas_pagar.forEach((conta_pagar) => {
          if (item.data === conta_pagar.data) {
            item.pagar = conta_pagar.total;
          }
        });

        result_contas_receber.forEach((conta_receber) => {
          if (item.data === conta_receber.data) {
            item.receber = conta_receber.total;
          }
        });
      });


      // console.log('data****', data);
      // console.log('vetor_periodo****', vetor_periodo);

      let novasLabels = vetor_periodo.map(item => item.periodo);
      let novosDadosReceber = vetor_periodo.map(item => item.receber || 0);
      let novosDadosPagar = vetor_periodo.map(item => item.pagar || 0);

      setDadosPagarReceber({
        labels: novasLabels,
        datasets: [
          {
            label: "Contas a Receber R$",
            color: "info",
            data: novosDadosReceber,
          },
          {
            label: "Contas a Pagar R$",
            color: "warning",
            data: novosDadosPagar,
          },
        ],
      });

      FechaTelaEspera();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaTopCincoOrcamentos = async () => {
    try {
      MostraTelaEspera('');
      let url = `${rotafechada()}dashboard/top_cinco_orcamentos?data_ini=${new Date(filtroTop5Orcamentos.dataInicialFormatada)}&data_fim=${new Date(filtroTop5Orcamentos.dataFinalFormatada)}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      let labels = data.map(obj => obj.Venda_id);
      let dataValues = data.map(obj => obj.valor_final);

      // Atualizar o estado
      setHorizontalBarChartData({
        labels: labels,
        datasets: [
          {
            label: "R$",
            color: ["info"],
            data: dataValues,
          }
        ],
      });

      FechaTelaEspera();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaTopCincoProdutos = async () => {
    try {
      MostraTelaEspera('');
      let url = `${rotafechada()}dashboard/top_cinco_produtos?data_ini=${new Date(filtroTop5Produtos.dataInicialFormatada)}&data_fim=${new Date(filtroTop5Produtos.dataFinalFormatada)}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      // console.log('data:', data)
      let formattedData = [];
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        formattedData.push({
          produto: [STAR, item.produto],
          "custo R$": formataValor(item.custo_total).replace('R$', ''),
          "venda R$": formataValor(item.valor_total).replace('R$', ''),
          lucro: formataValor(item.lucro_total).replace('R$', '') + '%'
        });
      }

      // Verifica se formattedData tem menos de 5 elementos
      while (formattedData.length < 5) {
        // Adiciona um objeto vazio ao array
        formattedData.push({ produto: [STAR, ""], 'custo R$': '0', 'venda R$': '0', lucro: '0' });
      }
      // Atualizar o estado
      setSalesTableData(formattedData);


      FechaTelaEspera();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleCloseFiltro = () => {
    setOpenDetalheFiltro(false)
    setOpenDetalheFiltroContasReceberPagar(false)
    setOpenDetalheFiltroTop5Orcamentos(false)
    setOpenDetalheFiltroTop5Produtos(false)
    setAnchorElFiltro(null);
    setAnchorElFiltroContasReceberPagar(null);
    setAnchorElFiltroTop5Orcamentos(null);
    setAnchorElFiltroTop5Produtos(null);
  };

  useEffect(async () => {
    await buscaFluxoCaixa();
  }, [filtro]);

  useEffect(async () => {
    await buscaContasReceberPagar()
  }, [filtroContasReceberPagar]);

  useEffect(async () => {
    await buscaTopCincoOrcamentos();
  }, [filtroTop5Orcamentos]);

  useEffect(async () => {
    await buscaTopCincoProdutos();
  }, [filtroTop5Produtos]);

  useEffect(async () => {
    await buscaVendas();
  }, [vendasDropdownValue]);

  useEffect(async () => {
    await buscaOrcamentos();
  }, [orcamentosDropdownValue]);

  useEffect(async () => {
    await buscaPessoas();
  }, [pessoaDropdownValue]);

  return (
    <>
      <Menu //Filtro linhas fluxo de caixa
        anchorEl={anchorElFiltro}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={openDetalheFiltro}
        onClose={handleCloseFiltro}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        keepMounted
        disableAutoFocusItem
      >
        <MenuItem //Último Ano
          dense={true}
          onClick={() => {
            const lastYear = subYears(new Date(), 1);
            setPeriodo('Último ano')
            setFiltro(
              {
                labelOriginal: endOfYear(lastYear),
                labelFormatada: format(endOfYear(lastYear), "yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfYear(lastYear),
                dataInicialFormatada: (format(startOfYear(lastYear), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfYear(lastYear),
                dataFinalFormatada: (format(endOfYear(lastYear), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltro(false)
          }}>
          Último ano
        </MenuItem>

        <MenuItem //Último Mês
          dense={true}
          onClick={() => {
            const lastMonth = subMonths(new Date(), 1);
            setPeriodo('Último mês')
            setFiltro(
              {
                labelOriginal: endOfMonth(lastMonth),
                labelFormatada: format(endOfMonth(lastMonth), "MMMM 'de' yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfMonth(lastMonth),
                dataInicialFormatada: (format(startOfMonth(lastMonth), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfMonth(lastMonth),
                dataFinalFormatada: (format(endOfMonth(lastMonth), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltro(false)
          }}>
          Último mês
        </MenuItem>

        <MenuItem //Última Semana
          dense={true}
          onClick={() => {
            //const lastWeek = subWeeks(new Date(), 1);
            let lastWeek = new Date();
            lastWeek.setDate(lastWeek.getDate() - 7);
            setPeriodo('Última semana')
            setFiltro(
              {
                //labelOriginal: endOfWeek(lastWeek),
                //labelFormatada: format(endOfWeek(lastWeek), "'Semana de' dd 'de' MMMM 'de' yyyy", { locale: ptBR }),
                labelOriginal: `${format(startOfWeek(lastWeek), "dd/MM/yyyy")} até ${format(endOfWeek(lastWeek), "dd/MM/yyyy")}`,
                labelFormatada: `${format(startOfWeek(lastWeek), "dd/MM/yyyy")} até ${format(endOfWeek(lastWeek), "dd/MM/yyyy")}`,

                dataInicialOriginal: startOfWeek(lastWeek),
                dataInicialFormatada: (format(startOfWeek(lastWeek), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfWeek(lastWeek),
                dataFinalFormatada: (format(endOfWeek(lastWeek), "yyyy-MM-dd 23:59:59"))

              }
            )
            setOpenDetalheFiltro(false)
          }}>
          Última semana
        </MenuItem>

        <MenuItem //Semana
          dense={true}
          onClick={() => {
            setPeriodo('Essa semana')
            setFiltro(
              {
                labelOriginal: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,
                labelFormatada: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,

                dataInicialOriginal: startOfWeek(new Date()),
                dataInicialFormatada: (format(startOfWeek(new Date()), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfWeek(new Date()),
                dataFinalFormatada: (format(endOfWeek(new Date()), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltro(false)
          }}>
          Essa semana
        </MenuItem>

        <MenuItem //Mês
          dense={true}
          onClick={() => {
            setPeriodo('Esse mês')
            setFiltro(
              {
                labelOriginal: endOfMonth(new Date()),
                labelFormatada: format(endOfMonth(new Date()), "MMMM 'de' yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfMonth(new Date()),
                dataInicialFormatada: (format(startOfMonth(new Date()), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfMonth(new Date()),
                dataFinalFormatada: (format(endOfMonth(new Date()), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltro(false)
          }}>
          Esse mês
        </MenuItem>

        <MenuItem //Ano
          dense={true}
          onClick={() => {
            setPeriodo('Esse ano')
            setFiltro(
              {
                labelOriginal: endOfYear(new Date()),
                labelFormatada: format(endOfYear(new Date()), "yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfYear(new Date()),
                dataInicialFormatada: (format(startOfYear(new Date()), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfYear(new Date()),
                dataFinalFormatada: (format(endOfYear(new Date()), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltro(false)
          }}>
          Esse ano
        </MenuItem>

      </Menu>

      <Menu //Filtro linhas ContasReceberPagar
        anchorEl={anchorElFiltroContasReceberPagar}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={openDetalheFiltroContasReceberPagar}
        onClose={handleCloseFiltro}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        keepMounted
        disableAutoFocusItem
      >
        <MenuItem //Último Ano
          dense={true}
          onClick={() => {
            const lastYear = subYears(new Date(), 1);
            setPeriodoContasReceberPagar('Último ano')
            setFiltroContasReceberPagar(
              {
                labelOriginal: endOfYear(lastYear),
                labelFormatada: format(endOfYear(lastYear), "yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfYear(lastYear),
                dataInicialFormatada: (format(startOfYear(lastYear), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfYear(lastYear),
                dataFinalFormatada: (format(endOfYear(lastYear), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltroContasReceberPagar(false)
          }}>
          Último ano
        </MenuItem>

        <MenuItem //Último Mês
          dense={true}
          onClick={() => {
            const lastMonth = subMonths(new Date(), 1);
            setPeriodoContasReceberPagar('Último mês')
            setFiltroContasReceberPagar(
              {
                labelOriginal: endOfMonth(lastMonth),
                labelFormatada: format(endOfMonth(lastMonth), "MMMM 'de' yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfMonth(lastMonth),
                dataInicialFormatada: (format(startOfMonth(lastMonth), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfMonth(lastMonth),
                dataFinalFormatada: (format(endOfMonth(lastMonth), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltroContasReceberPagar(false)
          }}>
          Último mês
        </MenuItem>

        <MenuItem //Última Semana
          dense={true}
          onClick={() => {
            //const lastWeek = subWeeks(new Date(), 1);
            let lastWeek = new Date();
            lastWeek.setDate(lastWeek.getDate() - 7);
            setPeriodoContasReceberPagar('Última semana')
            setFiltroContasReceberPagar(
              {
                //labelOriginal: endOfWeek(lastWeek),
                //labelFormatada: format(endOfWeek(lastWeek), "'Semana de' dd 'de' MMMM 'de' yyyy", { locale: ptBR }),
                labelOriginal: `${format(startOfWeek(lastWeek), "dd/MM/yyyy")} até ${format(endOfWeek(lastWeek), "dd/MM/yyyy")}`,
                labelFormatada: `${format(startOfWeek(lastWeek), "dd/MM/yyyy")} até ${format(endOfWeek(lastWeek), "dd/MM/yyyy")}`,

                dataInicialOriginal: startOfWeek(lastWeek),
                dataInicialFormatada: (format(startOfWeek(lastWeek), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfWeek(lastWeek),
                dataFinalFormatada: (format(endOfWeek(lastWeek), "yyyy-MM-dd 23:59:59"))

              }
            )
            setOpenDetalheFiltroContasReceberPagar(false)
          }}>
          Última semana
        </MenuItem>

        <MenuItem //Semana
          dense={true}
          onClick={() => {
            setPeriodoContasReceberPagar('Essa semana')
            setFiltroContasReceberPagar(
              {
                labelOriginal: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,
                labelFormatada: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,

                dataInicialOriginal: startOfWeek(new Date()),
                dataInicialFormatada: (format(startOfWeek(new Date()), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfWeek(new Date()),
                dataFinalFormatada: (format(endOfWeek(new Date()), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltroContasReceberPagar(false)
          }}>
          Essa semana
        </MenuItem>

        <MenuItem //Mês
          dense={true}
          onClick={() => {
            setPeriodoContasReceberPagar('Esse mês')
            setFiltroContasReceberPagar(
              {
                labelOriginal: endOfMonth(new Date()),
                labelFormatada: format(endOfMonth(new Date()), "MMMM 'de' yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfMonth(new Date()),
                dataInicialFormatada: (format(startOfMonth(new Date()), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfMonth(new Date()),
                dataFinalFormatada: (format(endOfMonth(new Date()), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltroContasReceberPagar(false)
          }}>
          Esse mês
        </MenuItem>

        <MenuItem //Ano
          dense={true}
          onClick={() => {
            setPeriodoContasReceberPagar('Esse ano')
            setFiltroContasReceberPagar(
              {
                labelOriginal: endOfYear(new Date()),
                labelFormatada: format(endOfYear(new Date()), "yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfYear(new Date()),
                dataInicialFormatada: (format(startOfYear(new Date()), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfYear(new Date()),
                dataFinalFormatada: (format(endOfYear(new Date()), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltroContasReceberPagar(false)
          }}>
          Esse ano
        </MenuItem>

      </Menu>

      <Menu //Filtro barras Top 5 Orçamentos
        anchorEl={anchorElFiltroTop5Orcamentos}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={openDetalheFiltroTop5Orcamentos}
        onClose={handleCloseFiltro}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        keepMounted
        disableAutoFocusItem
      >
        <MenuItem //Último Ano
          dense={true}
          onClick={() => {
            const lastYear = subYears(new Date(), 1);
            setPeriodoTop5Orcamentos('Último ano')
            setFiltroTop5Orcamentos(
              {
                labelOriginal: endOfYear(lastYear),
                labelFormatada: format(endOfYear(lastYear), "yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfYear(lastYear),
                dataInicialFormatada: (format(startOfYear(lastYear), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfYear(lastYear),
                dataFinalFormatada: (format(endOfYear(lastYear), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltroTop5Orcamentos(false)
          }}>
          Último ano
        </MenuItem>

        <MenuItem //Último Mês
          dense={true}
          onClick={() => {
            const lastMonth = subMonths(new Date(), 1);
            setPeriodoTop5Orcamentos('Último mês')
            setFiltroTop5Orcamentos(
              {
                labelOriginal: endOfMonth(lastMonth),
                labelFormatada: format(endOfMonth(lastMonth), "MMMM 'de' yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfMonth(lastMonth),
                dataInicialFormatada: (format(startOfMonth(lastMonth), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfMonth(lastMonth),
                dataFinalFormatada: (format(endOfMonth(lastMonth), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltroTop5Orcamentos(false)
          }}>
          Último mês
        </MenuItem>

        <MenuItem //Última Semana
          dense={true}
          onClick={() => {
            //const lastWeek = subWeeks(new Date(), 1);
            let lastWeek = new Date();
            lastWeek.setDate(lastWeek.getDate() - 7);
            setPeriodoTop5Orcamentos('Última semana')
            setFiltroTop5Orcamentos(
              {
                //labelOriginal: endOfWeek(lastWeek),
                //labelFormatada: format(endOfWeek(lastWeek), "'Semana de' dd 'de' MMMM 'de' yyyy", { locale: ptBR }),
                labelOriginal: `${format(startOfWeek(lastWeek), "dd/MM/yyyy")} até ${format(endOfWeek(lastWeek), "dd/MM/yyyy")}`,
                labelFormatada: `${format(startOfWeek(lastWeek), "dd/MM/yyyy")} até ${format(endOfWeek(lastWeek), "dd/MM/yyyy")}`,

                dataInicialOriginal: startOfWeek(lastWeek),
                dataInicialFormatada: (format(startOfWeek(lastWeek), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfWeek(lastWeek),
                dataFinalFormatada: (format(endOfWeek(lastWeek), "yyyy-MM-dd 23:59:59"))

              }
            )
            setOpenDetalheFiltroTop5Orcamentos(false)
          }}>
          Última semana
        </MenuItem>

        <MenuItem //Semana
          dense={true}
          onClick={() => {
            setPeriodoTop5Orcamentos('Essa semana')
            setFiltroTop5Orcamentos(
              {
                labelOriginal: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,
                labelFormatada: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,

                dataInicialOriginal: startOfWeek(new Date()),
                dataInicialFormatada: (format(startOfWeek(new Date()), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfWeek(new Date()),
                dataFinalFormatada: (format(endOfWeek(new Date()), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltroTop5Orcamentos(false)
          }}>
          Essa semana
        </MenuItem>

        <MenuItem //Mês
          dense={true}
          onClick={() => {
            setPeriodoTop5Orcamentos('Esse mês')
            setFiltroTop5Orcamentos(
              {
                labelOriginal: endOfMonth(new Date()),
                labelFormatada: format(endOfMonth(new Date()), "MMMM 'de' yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfMonth(new Date()),
                dataInicialFormatada: (format(startOfMonth(new Date()), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfMonth(new Date()),
                dataFinalFormatada: (format(endOfMonth(new Date()), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltroTop5Orcamentos(false)
          }}>
          Esse mês
        </MenuItem>

        <MenuItem //Ano
          dense={true}
          onClick={() => {
            setPeriodoTop5Orcamentos('Esse ano')
            setFiltroTop5Orcamentos(
              {
                labelOriginal: endOfYear(new Date()),
                labelFormatada: format(endOfYear(new Date()), "yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfYear(new Date()),
                dataInicialFormatada: (format(startOfYear(new Date()), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfYear(new Date()),
                dataFinalFormatada: (format(endOfYear(new Date()), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltroTop5Orcamentos(false)
          }}>
          Esse ano
        </MenuItem>

      </Menu>

      <Menu //Filtro barras Top 5 Produtos
        anchorEl={anchorElFiltroTop5Produtos}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={openDetalheFiltroTop5Produtos}
        onClose={handleCloseFiltro}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        keepMounted
        disableAutoFocusItem
      >
        <MenuItem //Último Ano
          dense={true}
          onClick={() => {
            const lastYear = subYears(new Date(), 1);
            setPeriodoTop5Produtos('Último ano')
            setFiltroTop5Produtos(
              {
                labelOriginal: endOfYear(lastYear),
                labelFormatada: format(endOfYear(lastYear), "yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfYear(lastYear),
                dataInicialFormatada: (format(startOfYear(lastYear), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfYear(lastYear),
                dataFinalFormatada: (format(endOfYear(lastYear), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltroTop5Produtos(false)
          }}>
          Último ano
        </MenuItem>

        <MenuItem //Último Mês
          dense={true}
          onClick={() => {
            const lastMonth = subMonths(new Date(), 1);
            setPeriodoTop5Produtos('Último mês')
            setFiltroTop5Produtos(
              {
                labelOriginal: endOfMonth(lastMonth),
                labelFormatada: format(endOfMonth(lastMonth), "MMMM 'de' yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfMonth(lastMonth),
                dataInicialFormatada: (format(startOfMonth(lastMonth), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfMonth(lastMonth),
                dataFinalFormatada: (format(endOfMonth(lastMonth), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltroTop5Produtos(false)
          }}>
          Último mês
        </MenuItem>

        <MenuItem //Última Semana
          dense={true}
          onClick={() => {
            //const lastWeek = subWeeks(new Date(), 1);
            let lastWeek = new Date();
            lastWeek.setDate(lastWeek.getDate() - 7);
            setPeriodoTop5Produtos('Última semana')
            setFiltroTop5Produtos(
              {
                //labelOriginal: endOfWeek(lastWeek),
                //labelFormatada: format(endOfWeek(lastWeek), "'Semana de' dd 'de' MMMM 'de' yyyy", { locale: ptBR }),
                labelOriginal: `${format(startOfWeek(lastWeek), "dd/MM/yyyy")} até ${format(endOfWeek(lastWeek), "dd/MM/yyyy")}`,
                labelFormatada: `${format(startOfWeek(lastWeek), "dd/MM/yyyy")} até ${format(endOfWeek(lastWeek), "dd/MM/yyyy")}`,

                dataInicialOriginal: startOfWeek(lastWeek),
                dataInicialFormatada: (format(startOfWeek(lastWeek), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfWeek(lastWeek),
                dataFinalFormatada: (format(endOfWeek(lastWeek), "yyyy-MM-dd 23:59:59"))

              }
            )
            setOpenDetalheFiltroTop5Produtos(false)
          }}>
          Última semana
        </MenuItem>

        <MenuItem //Semana
          dense={true}
          onClick={() => {
            setPeriodoTop5Produtos('Essa semana')
            setFiltroTop5Produtos(
              {
                labelOriginal: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,
                labelFormatada: `${format(startOfWeek(new Date()), "dd/MM/yyyy")} até ${format(endOfWeek(new Date()), "dd/MM/yyyy")}`,

                dataInicialOriginal: startOfWeek(new Date()),
                dataInicialFormatada: (format(startOfWeek(new Date()), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfWeek(new Date()),
                dataFinalFormatada: (format(endOfWeek(new Date()), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltroTop5Produtos(false)
          }}>
          Essa semana
        </MenuItem>

        <MenuItem //Mês
          dense={true}
          onClick={() => {
            setPeriodoTop5Produtos('Esse mês')
            setFiltroTop5Produtos(
              {
                labelOriginal: endOfMonth(new Date()),
                labelFormatada: format(endOfMonth(new Date()), "MMMM 'de' yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfMonth(new Date()),
                dataInicialFormatada: (format(startOfMonth(new Date()), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfMonth(new Date()),
                dataFinalFormatada: (format(endOfMonth(new Date()), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltroTop5Produtos(false)
          }}>
          Esse mês
        </MenuItem>

        <MenuItem //Ano
          dense={true}
          onClick={() => {
            setPeriodoTop5Produtos('Esse ano')
            setFiltroTop5Produtos(
              {
                labelOriginal: endOfYear(new Date()),
                labelFormatada: format(endOfYear(new Date()), "yyyy", { locale: ptBR }),

                dataInicialOriginal: startOfYear(new Date()),
                dataInicialFormatada: (format(startOfYear(new Date()), "yyyy-MM-dd 00:00:00")),

                dataFinalOriginal: endOfYear(new Date()),
                dataFinalFormatada: (format(endOfYear(new Date()), "yyyy-MM-dd 23:59:59"))
              }
            )
            setOpenDetalheFiltroTop5Produtos(false)
          }}>
          Esse ano
        </MenuItem>

      </Menu>

      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <MDBox mb={3}>
            <Grid container spacing={2}> {/*1ª Linha */}
              <Grid item xs={12} sm={4}> {/*Orçamentos*/}
                <Tooltip title="Clique para alterar o Período" placement="top" arrow>
                  <DefaultStatisticsCard
                    title="Orçamentos"
                    count={valorOrcamento}//"200"
                    percentage={{
                      color: sinalOrcamento,//"error",
                      value: porcentagemOrcamento,//"-12%",
                      label: orcamentosDropdownValue == 'Hoje' ? 'que ontem' :
                        orcamentosDropdownValue == 'Último ano' ? 'ano anterior' :
                          orcamentosDropdownValue == 'Esse ano' ? 'ano anterior' :
                            orcamentosDropdownValue == 'Último mês' ? 'mês anterior' :
                              orcamentosDropdownValue == 'Esse mês' ? 'ano anterior' :
                                orcamentosDropdownValue == 'Última semana' ? 'semana anterior' :
                                  orcamentosDropdownValue == 'Essa semana' ? 'semana anterior' : 'erro',
                    }}
                    dropdown={{
                      action: openOrcamentosDropdown,
                      menu: renderMenu(orcamentosDropdown, closeOrcamentosDropdown),
                      value: orcamentosDropdownValue,
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={4}> {/*Vendas*/}
                <DefaultStatisticsCard
                  title="Vendas"
                  count={valorVenda} //"R$100.000,00"
                  percentage={{
                    color: sinalVenda, //"success",
                    value: porcentagemVenda,//"+55%",
                    label: vendasDropdownValue == 'Hoje' ? 'que ontem' :
                      vendasDropdownValue == 'Último ano' ? 'ano anterior' :
                        vendasDropdownValue == 'Esse ano' ? 'ano anterior' :
                          vendasDropdownValue == 'Último mês' ? 'mês anterior' :
                            vendasDropdownValue == 'Esse mês' ? 'ano anterior' :
                              vendasDropdownValue == 'Essa semana' ? 'semana anterior' :
                                vendasDropdownValue == 'Última semana' ? 'semana anterior' : 'erro',
                  }}
                  dropdown={{
                    action: openVendasDropdown,
                    menu: renderMenu(vendasDropdown, closeVendasDropdown),
                    value: vendasDropdownValue,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>{/*Pessoas*/}
                <DefaultStatisticsCard
                  title="Pessoas cadastradas"
                  count={quantidadePessoa}
                  percentage={{
                    color: sinalPessoa,
                    value: porcentagemPessoa,
                    label: pessoaDropdownValue == 'Hoje' ? 'que ontem' :
                      pessoaDropdownValue == 'Último ano' ? 'ano anterior' :
                        pessoaDropdownValue == 'Esse ano' ? 'ano anterior' :
                          pessoaDropdownValue == 'Último mês' ? 'mês anterior' :
                            pessoaDropdownValue == 'Esse mês' ? 'ano anterior' :
                              pessoaDropdownValue == 'Essa semana' ? 'semana anterior' :
                                pessoaDropdownValue == 'Última semana' ? 'semana anterior' : 'erro',
                  }}
                  dropdown={{
                    action: openPessoaDropdown,
                    menu: renderMenu(pessoaDropdown, closePessoaDropdown),
                    value: pessoaDropdownValue,
                  }}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mb={3}> {/*2ª Linha */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4}> {/*Pizza Orçamentos/Venda*/}
                <GraficoPizza />
              </Grid>
              <Grid item xs={12} sm={6} lg={8}> {/*Linhas Fluxo de caixa*/}
                <DefaultLineChart
                  title="Fluxo de caixa"
                  description={
                    <MDBox display="flex" justifyContent="space-between">
                      <MDBox display="flex" ml={-1}>
                        <MDBadgeDot color="info" size="sm" badgeContent="Contas Recebidas" />
                        <MDBadgeDot color="warning" size="sm" badgeContent="Contas Pagas" />
                      </MDBox>
                      <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                        <Tooltip title="Clique para alterar o Período" placement="top" arrow>
                          <Button color="warning" size="small" onClick={(event) => {
                            setAnchorElFiltro(event.currentTarget);
                            setOpenDetalheFiltro(true);
                          }} >
                            {filtro.labelFormatada}
                          </Button>
                        </Tooltip>
                      </MDBox>
                    </MDBox>
                  }
                  chart={dadosGraficoLinhas}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mb={3}> {/*3ª Linha */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={8}> {/*Linhas Conta a Pagar/Receber*/}
                <DefaultLineChart
                  title="Contas a Receber/Pagar"
                  description={
                    <MDBox display="flex" justifyContent="space-between">
                      <MDBox display="flex" ml={-1}>
                        <MDBadgeDot color="info" size="sm" badgeContent="Contas a Receber" />
                        <MDBadgeDot color="warning" size="sm" badgeContent="Contas a Pagar" />
                      </MDBox>
                      <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                        <Tooltip title="Clique para alterar o Período" placement="top" arrow>
                          <Button color="warning" size="small" onClick={(event) => {
                            setAnchorElFiltroContasReceberPagar(event.currentTarget);
                            setOpenDetalheFiltroContasReceberPagar(true);
                          }} >
                            {filtroContasReceberPagar.labelFormatada}
                          </Button>
                        </Tooltip>
                      </MDBox>
                    </MDBox>
                  }
                  chart={dadosPagarReceber}
                />
              </Grid>
              <Grid item xs={12} lg={4}> {/*Top 5 Orçamentos */}
                <VerticalBarChart
                  title="Top 5 Orçamentos"
                  description="por período..."
                  chart={horizontalBarChartData}
                >
                  <Tooltip title="Clique para alterar o Período" placement="top" arrow>
                    <Button color='secondary'
                      onClick={(event) => {
                        setAnchorElFiltroTop5Orcamentos(event.currentTarget);
                        setOpenDetalheFiltroTop5Orcamentos(true);
                      }}
                    >
                      {filtroTop5Orcamentos.labelFormatada}
                      {/* <CalendarMonthIcon /> */}
                    </Button>
                  </Tooltip>
                </VerticalBarChart>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mb={3}> {/*4ª Linha */}
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}> {/*Top 5 Produtos Vendidos */}
                <SalesTable title="Top 5 Produtos mais vendidos"
                  rows={salesTableData}
                  shadow={true}>
                  <Tooltip title="Clique para alterar o Período" placement="top" arrow>
                    <Button color='secondary'
                      onClick={(event) => {
                        setAnchorElFiltroTop5Produtos(event.currentTarget);
                        setOpenDetalheFiltroTop5Produtos(true);
                      }}
                    >
                      {filtroTop5Produtos.labelFormatada}
                      {/* <CalendarMonthIcon /> */}
                    </Button>
                  </Tooltip>
                </SalesTable>
              </Grid>

            </Grid>
          </MDBox>
          {/* <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <MDBox pt={3} px={3}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Produtos mais vendidos
                  </MDTypography>
                </MDBox>
                <MDBox py={1}>
                  <DataTable
                    table={dataTableData}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    isSorted={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid> */}
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default DashboardVendas;
