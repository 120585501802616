import React from 'react';

const ArvorePropriedades = ({ data, confRelatorio }) => {

  if (!(data))
    return null;

  let dadosgrupo = '';

  if ((data.tipo === 'banda-grupo-cabecalho') || (data.tipo === 'banda-grupo-rodape')) {

    const configuracaoGrupo = confRelatorio.grupos?.filter((item) => item.idGrupo === data.idGrupo);

    if (configuracaoGrupo.length > 0) {
      dadosgrupo =
        <div >
          <strong>Dados Grupo</strong>: {`${configuracaoGrupo[0].dataset||'"não-configurado"'}.${configuracaoGrupo[0].field||''}`}
        </div >
    }

  }


  const renderTree = (node) => {
    if (!node)
      return null;

    return Object.entries(node).map(([key, value]) => {
      if (typeof value === 'object' && value !== null) {
        return (
          <div key={key}>
            <strong>{key}</strong>
            <div style={{ marginLeft: '20px' }}>{renderTree(value)}</div>
          </div>
        );
      } else {


        return (
          <div key={key}>
            <strong>{key}</strong>: {value?.toString()}
          </div>
        );
      }
    });
  };

  return (
    <>
      {dadosgrupo}
      <div>{renderTree(data)}</div>
    </>
  );
};

export default ArvorePropriedades;