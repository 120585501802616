import * as React from 'react';
import Box from '@mui/material/Box';
import MDBox from 'components/MDBox';
import { Button, Grid, Icon, Tooltip } from '@mui/material';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';



function TabelaCategoria(props) {

  const { linhas, abrirRegistro, handleClickExcluir, tipo, handleClickNovaDespesa, handleClickNovaReceita, handleClickCriaFilho } = props;


  return (
    <Box sx={{ width: '100%' }}>
      <Box display="flex" justifyContent="space-between">
        <MDTypography
          display="block"
          variant="h3"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {tipo == 'R' ? 'Categorias de Receita' : 'Categorias de Despesa'}
        </MDTypography>

        {tipo == 'R' ?
          < MDButton variant="contained" onClick={handleClickNovaReceita} color="info" sx={{ borderRadius: '3px' }}>
            Nova categoria de Receita
          </MDButton>
          :
          < MDButton variant="contained" onClick={handleClickNovaDespesa} color="info" sx={{ borderRadius: '3px' }}>
            Nova categoria de Despesa
          </MDButton>
        }

      </Box>
      <br />
      <hr color='#c9d3dd' size="1" />
      {
        linhas.map((item) => (
          <>
            <MDBox width="100%" bgColor='white' display='flex' justifyContent='space-between' m={1}>
              <MDBox width="75%" >
                <Grid display='flex' textAlign="left" pl={2}>
                  {/* {<MDTypography >{item.id} -</MDTypography>} */}
                  {item.categoria_id ? <MDTypography fontSize="15px" ml={2.5}> {item.nome}</MDTypography> : <MDTypography fontSize="18px" mt={0.5}>{item.nome}</MDTypography>}
                </Grid>

                <Grid display='flex' textAlign="left">
                  {item.categoria_id ? <MDTypography fontSize="12px" ml={4.5}>DRE: <strong>{item.plano_conta_id}</strong></MDTypography> : <MDTypography fontSize="12px"> </MDTypography>}
                  {!item.mae && !item.categoria_id ? <MDTypography fontSize="12px" ml={2.3}>DRE: <strong>{item.plano_conta_id}</strong></MDTypography> : <MDTypography fontSize="12px"> </MDTypography>}
                </Grid>

              </MDBox>


              <MDBox width="25%" display='flex' m={0.2} mr={2} justifyContent='flex-end' >
                <Grid pl={1} >
                  {item.categoria_id ? '' :
                    <Tooltip title="Criar sub-categoria">
                      <MDButton
                        size="medium"
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleClickCriaFilho(item.id, item.tipo)}
                        iconOnly
                        sx={{ color: "#395a7a", backgroundColor: "#f1f4f9", borderColor: "#c9d3dd", borderRadius: '3px' }}
                      >
                        <Icon sx={{ fontWeight: "bold" }}>
                          add_icon
                        </Icon>
                      </MDButton>
                    </Tooltip>
                  }

                </Grid>
                <Grid pl={1}>
                  <Tooltip title="Editar categoria">
                    <MDButton
                      size="medium"
                      mr={2}
                      pr={2}
                      variant="outlined"
                      color="secondary"
                      onClick={() => abrirRegistro(item.id, item.tipo)}
                      iconOnly
                      sx={{ color: "#395a7a", backgroundColor: "#f1f4f9", borderColor: "#c9d3dd", borderRadius: '3px' }}
                    >
                      <Icon fontSize="medium" color="#395a7a">
                        edit_icon
                      </Icon>
                    </MDButton>
                  </Tooltip>
                </Grid>

                <Grid pl={1}>
                  <Tooltip title="Excluir categoria">
                    <MDButton
                      size="medium"
                      variant="outlined"
                      color="secondary"       
                      onClick={() => handleClickExcluir(item.id, item.mae, item.nome, item.tipo)}
                      iconOnly
                      sx={{ color: "#395a7a", backgroundColor: "#f1f4f9", borderColor: "#c9d3dd", borderRadius: '3px' }}
                    >
                      <Icon fontSize="medium" sx={{ color: "#395a7a" }}>
                        delete_forever
                      </Icon>
                    </MDButton>
                  </Tooltip>
                </Grid>
              </MDBox>


            </MDBox>
            <hr color='#c9d3dd' size="1" />
          </>
        ))
      }
      <br />
    </Box >
  );
}

export default React.memo(TabelaCategoria);