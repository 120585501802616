import React, { useContext, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import DialogoConfirma from 'componentes/DialogoConfirma';
import FormField from "componentes/FormField";
import { Autocomplete, Checkbox, Divider, FormControlLabel, FormGroup, Typography } from "@mui/material";
import PesquisaEmpresa from "../../componentes/pesquisaempresa";
import MDButton from "components/MDButton";
import UploadBancodeDados from "./uploadbancodedados";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";


function ImportaVidrosFirebirdAdmin(props) {

  const { empresaAtual, nomeEmpresaAtual } = props;

  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const [empresaModeloId, setEmpresaModeloId] = useState('');
  const [nomeEmpresaModeloId, setNomeEmpresaModeloId] = useState('');
  const [confPesquisaDinamica, setConfPesquisaDinamica] = useState({});
  const [memoLog, setMemoLog] = useState('');

  const [pastaimportacao, setPastaimportacao] = useState('');
  const [arquivo, setArquivo] = useState('');
  const [prefixoidentificador, setPrefixoidentificador] = useState('');
  const [codigoprojetofb, setCodigoprojetofb] = useState('');
  const [codigoprojetofbinicial, setCodigoprojetofbinicial] = useState('');
  const [codigoprojetofbfinal, setCodigoprojetofbfinal] = useState('');

  const [opcoesOrdemdasMedidas, setopcoesOrdemdasMedidas] = useState([
    { value: 'AXL', label: 'AXL' },
    { value: 'LXA', label: 'LXA' },
  ]);
  const [ordemdasmedidas, setOrdemdasmedidas] = useState(opcoesOrdemdasMedidas[0]);

  const [arredonda50mmcomponentes, setArredonda50mmcomponentes] = useState(false);
  const [importavidros, setImportavidros] = useState(false);
  const [importaprojetos, setImportaprojetos] = useState(false);
  const [importaclientes, setImportaclientes] = useState(false);
  const [importafornecedores, setImportafornecedores] = useState(false);

  const importaDados = async () => {

    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      const id = dialogoOpen.id
      MostraTelaEspera('Aguarde a importação');
      const dados = {
        empresa_id: empresaAtual,
        pastaimportacao,
        arquivo,
        prefixoidentificador,
        codigoprojetofb,
        codigoprojetofbinicial,
        codigoprojetofbfinal,
        arredonda50mmcomponentes,
        ordemdasmedidas: ordemdasmedidas.value || 'AXL',
        importavidros,
        importaprojetos,
        importaclientes,
        importafornecedores
      };
      const retorno = await api.post(`${rotafechada()}importacao/vidros`, dados);
      FechaTelaEspera();
      MensagemAviso('Importado com sucesso!');
      setMemoLog(JSON.stringify(retorno.data.dados));
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      const erro = error.response?.data?.erro || msg;
      setMemoLog(erro)
      MensagemErro(`Erro: ${msg}`);
    }
  }
  useEffect(async () => {

  }, [])

  const handleClickIniciarImportacao = async (id) => {
    setDialogoOpen({
      ...dialogoOpen,
      visible: true,
      id,
      title: 'Confirma Importação',
      message: `Certeza que importar os dados para a empresa  ${nomeEmpresaAtual}`,
      funcao: 'importaDados'
    });
  }

  const handleConfirmDialogo = () => {
    if (dialogoOpen.funcao === 'importaDados') {
      importaDados();
    }
  }


  const handleFuncaoSelecaoPesquisaDinamica = (codigo, datasetPesquisa) => {
    /* Essa funcao é chamada pelo componente de pesquisa dinamica quando um registro é escolhido
    ele retorna os campos que foram configurados*/
    setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false });
    setNomeEmpresaModeloId(datasetPesquisa.fantasia);
    setEmpresaModeloId(codigo);
  }

  const handleBlurEmpresaModelo = async () => {

    if (!(parseInt(empresaModeloId, 10) > 0)) {
      setNomeEmpresaModeloId('');
      return;
    }

    try {
      let url = `${rotafechada()}empresas?all=true&id=${empresaModeloId}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      if ((data) && (data?.length > 0)) {
        setNomeEmpresaModeloId(data[0].fantasia || '');
      } else {
        setNomeEmpresaModeloId('');
        setEmpresaModeloId('');
      }
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  return (
    <>

      <DialogoConfirma
        open={dialogoOpen.visible}
        title={dialogoOpen.title}
        message={dialogoOpen.message}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
        handleConfirm={handleConfirmDialogo}
      />

      <PesquisaEmpresa
        open={confPesquisaDinamica.open || false}
        handleClose={
          () => setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false })
        }
        handleClick={handleFuncaoSelecaoPesquisaDinamica}
      />

      <MDBox>
        <MDBox mt={0} mb={4} textAlign="center">
          <MDBox mb={1}>
            <MDTypography variant="h4" fontWeight="bold">
              {`Importa Banco de Dados do Vidros Windows (Firebird)`}
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox>
          <Grid container spacing={2}>

            <Grid item xs={12} sm={12} mr={2}>{/*arquivo*/}
              <UploadBancodeDados
              setPastaimportacao={setPastaimportacao}
              />
            </Grid>

            <Grid item xs={12} sm={5} mr={2}>{/*pastaimportacao*/}
              <FormField
                type='text'
                label='Pasta Importação'
                name='pastaimportacao'
                rows={1}
                multiline={false}
                required={true}
                disabled={false}
                value={pastaimportacao}
                onChange={(e) => setPastaimportacao(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={5}>{/*arquivo*/}
              <FormField
                type='text'
                label='Arquivo do Banco de Dados (.fdb)'
                name='arquivo'
                rows={1}
                multiline={false}
                required={true}
                disabled={false}
                value={arquivo}
                onChange={(e) => setArquivo(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={5} mr={2}>{/*prefixoidentificador*/}
              <FormField
                type='text'
                label='Prefixo Identificador'
                name='prefixoidentificador'
                rows={1}
                multiline={false}

                disabled={false}
                value={prefixoidentificador}
                onChange={(e) => setPrefixoidentificador(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={5}>{/*codigoprojetofb*/}
              <FormField
                type='text'
                label='Código do Projeto único'
                name='codigoprojetofb'
                rows={1}
                multiline={false}

                disabled={false}
                value={codigoprojetofb}
                onChange={(e) => setCodigoprojetofb(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={5} mr={2}>{/*codigoprojetofbinicial*/}
              <FormField
                type='text'
                label='Código do Projeto Inicial'
                name='codigoprojetofbinicial'
                rows={1}
                multiline={false}

                disabled={false}
                value={codigoprojetofbinicial}
                onChange={(e) => setCodigoprojetofbinicial(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={5}>{/*codigoprojetofbfinal*/}
              <FormField
                type='text'
                label='Código do Projeto Final'
                name='codigoprojetofbfinal'
                rows={1}
                multiline={false}
                disabled={false}
                value={codigoprojetofbfinal}
                onChange={(e) => setCodigoprojetofbfinal(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={5} >{/*ordemdasmedidas*/}
              <AutoCompleteStyled
                options={opcoesOrdemdasMedidas}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <FormField {...params} label="Ordem das Medidas" InputLabelProps={{ shrink: true }} />}
                value={ordemdasmedidas}
                onChange={(event, newValue) => {
                  setOrdemdasmedidas(newValue)
                }}
              />
            </Grid>


            <Grid item xs={12} sm={12} mt={2}>
              <MDBox >
                <FormGroup>
                  <FormControlLabel ml={2} control={<Checkbox checked={arredonda50mmcomponentes} onChange={(e) => setArredonda50mmcomponentes(e.target.checked)} />} label="Arredonda 50mm nos Componentes" />
                  <FormControlLabel ml={2} control={<Checkbox checked={importavidros} onChange={(e) => setImportavidros(e.target.checked)} />} label="Importar Vidros" />
                  <FormControlLabel ml={2} control={<Checkbox checked={importaprojetos} onChange={(e) => setImportaprojetos(e.target.checked)} />} label="Importar Projetos" />
                  <FormControlLabel ml={2} control={<Checkbox checked={importaclientes} onChange={(e) => setImportaclientes(e.target.checked)} />} label="Importar Clientes" />
                  <FormControlLabel ml={2} control={<Checkbox checked={importafornecedores} onChange={(e) => setImportafornecedores(e.target.checked)} />} label="Importar Fornecedores" />
                </FormGroup>
                <MDButton color='warning' onClick={handleClickIniciarImportacao} ml={2} >Importar Dados</MDButton>
              </MDBox>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Divider fullWidth />
            </Grid>

            <Grid item xs={12} sm={12} mt={12}>
              <FormField
                type='text'
                label='Log'
                name='memoLog'
                rows={10}
                multiline={true}

                disabled={false}
                value={memoLog}
              />
            </Grid>

          </Grid>
        </MDBox>

      </MDBox>
    </>
  );
}

export default ImportaVidrosFirebirdAdmin;
