
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Icon, Typography } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import AuthContext from "hooks/AuthContext";
import DialogoConfirma from 'componentes/DialogoConfirma';
import ModulosSistema from "./modulos_sistema";
import ParametrosModulo from "./parametros_modulo";
import { useParams } from "react-router-dom";
import { useTrataRetorno } from "commons/authUtils";

function Parametros() {

  const params = useParams();
  const { id } = params;
  const trataRetorno = useTrataRetorno();
  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const baseUrl = `${rotafechada()}parametro_empresas`;
  const baseCaption = 'Parâmetros';
  const [nomeModuloCarregar, setNomeModuloCarregar] = useState(id);
  const [moduloCarregado, setModuloCarregado] = useState();
  const [nomeModuloCarregado, setNomeModuloCarregado] = useState();
  const [permitido, setPermitido] = useState(false);

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${baseUrl}/permissao/${operacao}`);
      return true;
    }
    catch (error) {
      trataRetorno(error?.response?.data?.erro);
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }

  const buscaModuloPeloNome = async () => {

    if (!nomeModuloCarregar) {
      return;
    }

    try {
      MostraTelaEspera(`Buscando ${baseCaption}`);
      const url = `${rotafechada()}modulos_sistema?nome=${nomeModuloCarregar}`;
      const retorno = await api.get(encodeURI(url));
      FechaTelaEspera();
      const { data } = retorno.data;

      if (data.length > 0) {
        setModuloCarregado(data[0].id);
        setNomeModuloCarregado(data[0].nome);

      }
      setNomeModuloCarregar('');
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`${msg}`);
    }
  }

  const validaPermissao = async () => {
    const permitidotemp = await validaRotaPermissao('editar');
    setPermitido(permitidotemp);
  }

  useEffect(() => {
    buscaModuloPeloNome();
    validaPermissao();
  }, [nomeModuloCarregar]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />

        <MDBox mt={5} mb={9}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={12}>
              <MDBox mt={6} mb={8} textAlign="center">
                <MDBox mb={1}>
                  <MDTypography variant="h3" fontWeight="bold">
                    {`${baseCaption}`}
                  </MDTypography>
                </MDBox>
                <MDTypography variant="h5" fontWeight="regular" color="secondary">
                  { }
                </MDTypography>
              </MDBox>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Card>
                <Grid container justifyContent="center" m={2}>
                  <Grid item xs={12} sm={3}>
                    <ModulosSistema
                      moduloCarregado={moduloCarregado}
                      setModuloCarregado={setModuloCarregado}
                      setNomeModuloCarregado={setNomeModuloCarregado}
                    />
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    {
                      permitido ?
                        <ParametrosModulo
                          moduloCarregado={moduloCarregado}
                          setModuloCarregado={setModuloCarregado}
                          nomeModuloCarregado={nomeModuloCarregado}
                        />
                        :
                        <MDBox>
                          <Typography>
                            Sem permissão para alterar parâmetros
                          </Typography>
                        </MDBox>
                    }

                  </Grid>
                </Grid>
              </Card>
            </Grid>

          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default Parametros;
