import React, { useState, useEffect, useContext } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MessagesContext from "hooks/MessagesContext";
import { Grid, Icon, Tooltip, Divider } from "@mui/material";
import AuthContext from 'hooks/AuthContext';
import { formatDate, formataValor } from "commons/utils";
import { Link } from "react-router-dom";
import HelpIcon from '@mui/icons-material/Help';
import TabelaPadrao from "componentes/TabelaPadrao";
import DialogoConfirma from 'componentes/DialogoConfirma';
import DialogoConfirmaValidacao from "componentes/DialogoConfirmaValidacao";


export default function DialogoDetalheParcela(props) {

    const { open, message, handleCancel, title, setCodigoAbrir, codigoAbrir, tipo, recarregaTabela, setRecarregaTabela } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera, MensagemAlerta } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
    const [dialogoEstornoOpen, setDialogoEstornoOpen] = useState({ visible: false, id: null });


    //TABELA
    const [colunasTable, setColunasTable] = React.useState([]);
    const [linhas, setLinhas] = React.useState([]);
    const [countAll, setCountAll] = React.useState(0);

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    //Conta
    const [pessoa, setPessoa] = useState('');
    const [pessoa_id, setPessoa_id] = useState('');
    const [codigoReferencia, setCodigoReferencia] = useState('');
    const [dataCompetencia, setDataCompetencia] = useState('');
    const [categoria, setCategoria] = useState('');
    const [centro_custo, setCentro_custo] = useState('');
    const [descricaoConta, setDescricaoConta] = useState('');
    const [rateio, setRateio] = useState('');
    const [rateioCategoria, setRateioCategoria] = useState('');
    const [rateioCentro_custo, setRateioCentro_custo] = useState('');
    const [rateioPercentual, setRateioPercentual] = useState('');
    const [dadosRateio, setDadosRateio] = useState([]);

    //Parcela
    const [idParcela, setIdParcela] = useState(0);
    const [vencimento, setVencimento] = useState('');
    const [parcela, setParcela] = useState(0);
    const [qtdParcela, setQtdParcela] = useState(0);
    const [descricaoParcela, setDescricaoParcela] = useState('');
    const [valorParcela, setValorParcela] = useState(0)
    const [observacaoParcela, setObservacaoParcela] = useState('');
    const [situacao, setSituacao] = useState('');
    const [bancoPrevisao, setBancoPrevisao] = useState('');

    //Lancamento_parcelas
    const [valorPago, setValorPago] = useState(0);
    const [totalJuros, setTotalJuros] = useState(0);
    const [totalMulta, setTotalMulta] = useState(0);
    const [totalDesconto, setTotalDesconto] = useState(0);
    const [totalTarifa, setTotalTarifa] = useState(0);
    const [pago, setPago] = useState(false);
    const [saldo, setSaldo] = useState(0);

    const validaRotaPermissao = async (operacao = '', silencioso = false) => {
        if ((operacao !== 'inserir') &&
            (operacao !== 'editar') &&
            (operacao !== 'excluir') &&
            (operacao !== 'listar')) {
            MensagemErro('Escolha a operação que deseja validar a permissão!');
            return false;
        }

        //validando a permissao
        try {
            await api.get(`${rotafechada()}lancamento_parcelas/permissao/${operacao}`);
            return true;
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;

            if (silencioso === false) {
                MensagemErro(`Erro: ${msg}`);
            }
            return false;
        }
    }

    const buscaDados = async () => {
        try {
            MostraTelaEspera()
            //console.log(props)
            const retorno = await api.get(`${rotafechada()}parcelas_conta/parcelafull?id=${props.codigoAbrir}`);
            //const retorno = await api.get(`${rotafechada()}parcelas_conta/parcelafull?id=569`);
            const { dataConta, dataParcela, dataCountParcelas, dataLanParcelas, dataSaldo, dataSituacao } = retorno.data;

            //console.log('DADOS CONTA:', dataConta)

            //Conta
            setPessoa(dataConta[0].Pessoa.razao_social)
            setPessoa_id(dataConta[0].Pessoa.id)
            setCodigoReferencia(dataConta[0].codigo_referencia)
            let data_competencia_temp = new Date(dataConta[0].data_competencia)
            setDataCompetencia(formatDate(data_competencia_temp))
            setCategoria(dataConta[0].rateio == 'N' ? dataConta[0].Categorium.nome : `${dataConta[0].Rateio_Categoria.length} categorias informadas`)
            //setCategoria(dataConta[0].Categorium.nome )
            setCentro_custo(dataConta[0].Centro_Custo?.nome)
            setDescricaoConta(dataConta[0].nome)
            setRateio(dataConta[0].rateio)
            if (dataConta[0].rateio == 'S')
                setDadosRateio(dataConta[0].Rateio_Categoria)


            //Parcela
            setSituacao(dataSituacao)
            setIdParcela(dataParcela[0].id || 0)
            let vencimento_temp = new Date(dataParcela[0].vencimento)
            setVencimento(formatDate(vencimento_temp, 'DD/MM/YYYY'))
            setParcela(dataParcela[0].numero_parcela || 0)
            setDescricaoParcela(dataParcela[0].nome)
            setValorParcela(parseFloat(dataParcela[0].valor))
            setObservacaoParcela(dataParcela[0].observacao)
            setBancoPrevisao(dataParcela[0]?.Banco?.nome || '')

            //setFormaPagamento()
            //setForma_Pagamento_id({ value: dataParcela[0].Forma_Pagamento_id ? dataParcela[0].Forma_Pagamento_id : '', label: dataParcela[0].Forma_Pagamento?.nome ? dataParcela[0].Forma_Pagamento.nome : '' })
            //setBanco_id({ value: dataParcela[0].banco_id ? dataParcela[0].banco_id : -1, label: dataParcela[0].Banco?.nome ? dataParcela[0].Banco.nome : '<Escolha um banco>' })


            //Lancamento_parcelas
            setQtdParcela(dataCountParcelas || 0)
            setValorPago(parseFloat(dataLanParcelas.total_quitacao ? (dataParcela[0].valor - (dataLanParcelas.total_quitacao)) : dataParcela[0].valor))
            setTotalJuros(dataLanParcelas.total_juros || 0)
            setTotalMulta(dataLanParcelas.total_multa || 0)
            setTotalDesconto(dataLanParcelas.total_desconto || 0)
            setTotalTarifa(dataLanParcelas.total_tarifa || 0)
            setSaldo(dataSaldo || 0)

            setPago(dataLanParcelas.total_quitacao > 0 ? dataLanParcelas.total_quitacao == parseFloat(dataParcela[0].valor) ? false : false : true)

            /*DADOS TABELA*/
            let complementoUrl = '';

            if (page > 0) {
                complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
            }

            if (orderBy) {
                complementoUrl = `${complementoUrl}&order=${orderBy}`;
                if (order) {
                    complementoUrl = `${complementoUrl}&direction=${order}`;
                }
            }

            const retornoDetalheParcela = await api.get(`${rotafechada()}lancamento_parcelas/detalheparcela?parcela_conta_id=${props.codigoAbrir}${complementoUrl}&limit=${rowsPerPage}`);
            const { data, countAll: countAllretorno } = retornoDetalheParcela.data;
            const dadostemp = [];

            const colunasTabletemp = [
                // {
                //     id: 'id',
                //     numeric: false,
                //     disablePadding: true,
                //     label: 'id',
                //     disableOrder: true
                // },
                {
                    id: 'data',
                    numeric: false,
                    disablePadding: true,
                    label: 'Data',
                    disableOrder: true
                },
                {
                    id: 'forma_pagamento_id',
                    numeric: false,
                    disablePadding: true,
                    label: 'Forma de pagamento',
                    disableOrder: true
                },
                {
                    id: 'banco_id',
                    numeric: false,
                    disablePadding: true,
                    label: 'Conta',
                    disableOrder: true
                },
                {
                    id: 'valor',
                    numeric: false,
                    disablePadding: true,
                    label: 'Valor(R$)',
                    disableOrder: true
                },
                {
                    id: 'tipo',
                    numeric: false,
                    disablePadding: true,
                    label: 'Tipo',
                    disableOrder: true
                },
                {
                    id: 'lote',
                    numeric: false,
                    disablePadding: true,
                    label: 'Lote',
                    disableOrder: true
                },
                {
                    id: 'observacao',
                    numeric: false,
                    disablePadding: true,
                    label: 'Observação',
                    disableOrder: true
                },
                {
                    id: 'acoes',
                    numeric: false,
                    disablePadding: true,
                    label: 'Ações',
                    disableOrder: true
                },
            ]
            //console.log('data:', data)
            //mapeando os dados para a "rows"
            data.map((item, index) => {
                dadostemp.push({
                    id: item.id,
                    data: item.tipo == 'Q' ? <MDTypography variant="body2" fontWeight="bold" >{formatDate(new Date(item.data), 'DD/MM/YYYY')}</MDTypography> : item.tipo != 'Q' && item.lote == null ? <MDTypography variant="body2" fontWeight="bold" >{formatDate(new Date(item.data), 'DD/MM/YYYY')}</MDTypography> : '',
                    forma_pagamento_id: item.tipo == 'Q' ? <MDTypography variant="body2" fontWeight="bold" >{item.Relacionamento_Lancamentos[0]?.Lancamento_Banco?.Forma_Pagamento?.nom}</MDTypography> : '',
                    banco_id: item.tipo == 'Q' ? <MDTypography variant="body2" fontWeight="bold" >{item.Relacionamento_Lancamentos[0]?.Lancamento_Banco?.Banco?.nome}</MDTypography> : '',
                    valor: item.tipo == 'Q' ? <MDTypography variant="body2" fontWeight="bold" >{formataValor(item.valor).replace("R$", "")}</MDTypography> : item.tipo != 'Q' && item.lote == null ? <MDTypography variant="body2" fontWeight="bold" >{formataValor(Math.abs(item.valor)).replace("R$", "")}</MDTypography> : <MDTypography variant="caption" >{formataValor(Math.abs(item.valor)).replace("R$", "")}</MDTypography>,
                    tipo: item.tipo == 'Q' ?
                        <MDTypography variant="body2" fontWeight="bold" >Quitação</MDTypography>
                        : item.tipo == 'M' ?
                            item.lote == null ?
                                <MDTypography variant="body2" fontWeight="bold" >Multa</MDTypography>
                                : <MDTypography variant="caption" >Multa</MDTypography>
                            : item.tipo == 'J' ?
                                item.lote == null ?
                                    <MDTypography variant="body2" fontWeight="bold" >Juros</MDTypography>
                                    : <MDTypography variant="caption" >Juros</MDTypography>
                                : item.tipo == 'D' ?
                                    item.lote == null ?
                                        <MDTypography variant="body2" fontWeight="bold" >Desconto</MDTypography>
                                        : <MDTypography variant="caption" >Desconto</MDTypography>
                                    : <MDTypography variant="caption" >Tarifa</MDTypography>,
                    lote: item.tipo == 'Q' ? <MDTypography variant="body2" fontWeight="bold" >{item.lote}</MDTypography> : <MDTypography variant="caption">{item.lote}</MDTypography>,
                    observacao: item.tipo == 'Q' ? <MDTypography variant="body2" fontWeight="bold" >{item.Relacionamento_Lancamentos[0]?.Lancamento_Banco?.observacao}</MDTypography> : item.Relacionamento_Lancamentos[0]?.Lancamento_Banco?.observacao,
                    acoes: item.tipo == 'Q' ?
                        <>
                            <Tooltip
                                title={`Excluir lote ${item.lote} de quitação.`}
                                placement="top"
                                disableInteractive
                            >
                                <MDButton onClick={() => handleClickExcluir(item.id)}>
                                    <Icon fontSize="small" color="error" >
                                        delete_forever
                                    </Icon>
                                </MDButton>
                            </Tooltip>
                            <Tooltip
                                title={`Emitir recibo da quitação`}
                                placement="top"
                                disableInteractive
                            >
                                <MDButton onClick={() => handleClickRecibo(item.id)}>
                                    <Icon fontSize="small" color="info" >
                                        receipt
                                    </Icon>
                                </MDButton>
                            </Tooltip>
                        </>
                        :
                        item.tipo != 'Q' && item.lote == null ?
                            <>
                                <Tooltip
                                    title={`Excluir lançamento de ${item.tipo == 'J' ? 'juros' : item.tipo == 'M' ? 'multa' : item.tipo == 'D' ? 'desconto' : item.tipo == 'T' ? 'tarifa' : 'quitação'}.`}
                                    placement="top"
                                    disableInteractive
                                >
                                    <MDButton onClick={() => handleClickExcluir(item.id)}>
                                        <Icon fontSize="small" color="error">
                                            delete_forever
                                        </Icon>
                                    </MDButton>
                                </Tooltip>
                            </>

                            : ''
                })
            })

            setColunasTable([...colunasTabletemp]);
            setLinhas(dadostemp);
            setCountAll(countAllretorno);

            /*DADOS TABELA*/




            FechaTelaEspera()
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleClickEstonar = async (id) => {

        if (!(await validaRotaPermissao('excluir') === true)) {
            return;
        }

        setDialogoEstornoOpen({ ...dialogoEstornoOpen, visible: true, id });
    }

    const handleEstonar = async (id) => {
        setDialogoEstornoOpen({ ...dialogoEstornoOpen, visible: false });

        try {
            await api.delete(`${rotafechada()}lancamento_parcelas/lancamenosfull/${dialogoEstornoOpen.id || 0}`);
            MensagemAviso('Excluído com sucesso!');
            buscaDados();
            setRecarregaTabela(!recarregaTabela);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleClickRecibo = async(id) => {
        try {
            const retorno = await api.get(`${rotafechada()}imprimir/financeiro/recibo?empresa_id=${sessaolocal.empresa_id}&lancamento_parcela_id=${id}`, {
              responseType: 'arraybuffer'
            });
      
            const file = new Blob([retorno.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }
          catch (error) {
            console.log(error);
            MensagemErro(`Erro: Não foi possível gerar o recibo`);
          }
    }

    const handleClickExcluir = async (id) => {

        if (!(await validaRotaPermissao('excluir') === true)) {
            return;
        }

        setDialogoOpen({ ...dialogoOpen, visible: true, id });
    }

    const handleExcluir = async (id) => {
        setDialogoOpen({ ...dialogoOpen, visible: false });

        try {
            await api.delete(`${rotafechada()}lancamento_parcelas/full/${dialogoOpen.id || 0}`);
            MensagemAviso('Excluído com sucesso!');
            buscaDados();
            setRecarregaTabela(!recarregaTabela);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const limpaImputs = () => {

        setCodigoAbrir('');
        setTotalJuros(0);
        setTotalMulta(0);
        setTotalDesconto(0);
        setTotalTarifa(0);


    }

    useEffect(() => {
        if (open == true) {
            buscaDados();
        }

    }, [open])

    useEffect(() => {
        buscaDados();
    }, [page, rowsPerPage, order, orderBy])

    return (
        <>
            <DialogoConfirma
                open={dialogoOpen.visible}
                title={'Confirma Exclusão'}
                color={'error'}
                message={'Certeza que deseja excluir?'}
                handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
                handleConfirm={handleExcluir}
            />

            <DialogoConfirmaValidacao
                open={dialogoEstornoOpen.visible}
                title={'Confirma Exclusão'}
                color={'error'}
                message={'Certeza que deseja excluir todos os lançamentos desta parcela? '}
                validacao={'Concordo com a exclusão de todos os lançamentos desta parcela'}
                handleCancel={() => setDialogoEstornoOpen({ ...dialogoEstornoOpen, visible: false })}
                handleConfirm={handleEstonar}
            />

            <Dialog
                open={open}
                onClose={() => {
                    handleCancel()
                    limpaImputs()
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen
            >
                <DialogTitle id="alert-dialog-title" sx={{ padding: '5px' }}>{/*HEADER*/}
                    <MDBox width="100%">
                        <MDBox display="flex" justifyContent="space-between">
                            <MDBox display="flex" justifyContent="space-start" flexDirection='column'>
                                <MDTypography fontWeight='bold' ml={5} mt={1} variant='h4'>{`${title} ${parcela}/${qtdParcela} ${tipo == 'R' ? '- Receita' :  '- Despesa'}`}</MDTypography>
                                {/* <MDTypography fontWeight='bold' ml={5} mt={1} variant='caption'>{`Despesas`}</MDTypography> */}
                            </MDBox>

                            <Tooltip title="Fechar (Esc)">
                                <MDButton size="large" sx={{ padding: 0 }} onClick={() => {
                                    handleCancel()
                                    limpaImputs()
                                }}>
                                    <Icon>close</Icon>
                                </MDButton>

                            </Tooltip>
                        </MDBox>
                    </MDBox>
                </DialogTitle>

                <DialogContent dividers sx={{ backgroundColor: "#f1f4f9", borderColor: '#7bb5e4' }}>
                    <DialogContentText id="alert-dialog-description">

                        <MDBox ml={2} mr={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* CONTA */}
                            <Grid container spacing={2} mt={1} pl={2} pr={2}>

                                <Grid item xs={12} sm={12}> {/* TITULO*/}
                                    <MDTypography variant='subtitle2' fontWeight="bold">{tipo == 'D' ? 'Informações da conta' : 'Informações da conta'}</MDTypography>
                                    <Divider
                                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                                        style={{
                                            marginTop: 5,
                                            marginBottom: 10,
                                            border: "none",
                                            height: 2,
                                            margin: 0,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={2}>{/*PESSOA*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{tipo == 'D' ? 'Fornecedor' : 'Cliente'}</MDTypography>
                                        <MDTypography variant='caption' >
                                            <Link to={`/pessoas/pessoa/${pessoa_id}`} style={{ textDecoration: "none", color: 'info' }}>{pessoa}</Link>
                                        </MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* CODIGO_REFERENCIA */}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Código de referência'}</MDTypography>
                                        <MDTypography variant='caption' >{codigoReferencia}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* DATA_COMPETENCIA */}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Data de competência'}</MDTypography>
                                        <MDTypography variant='caption' >{dataCompetencia}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2} >{/*CATEGORIA*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Categoria'}</MDTypography>
                                        <MDTypography variant='caption' >{categoria}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/*CENTRO_CUSTO*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Centro de custo'}</MDTypography>
                                        <MDTypography variant='caption' >{centro_custo}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/*DESCRIÇÃO_CONTA*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Descrição da conta'}</MDTypography>
                                        <MDTypography variant='caption' >{descricaoConta}</MDTypography>
                                    </MDBox>
                                </Grid>

                            </Grid>

                        </MDBox>

                        <MDBox ml={2} mr={2} mt={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* PARCELA */}
                            <Grid container spacing={2} mt={1} pl={2} pr={2}>

                                <Grid item xs={12} sm={12}> {/* TITULO*/}
                                    <MDTypography variant='subtitle2' fontWeight="bold">{`Detalhes da parcela`}</MDTypography>
                                    {/* <MDTypography variant='caption' fontWeight="bold">{tipo == 'D' ? 'Despesa' : 'Receita'}</MDTypography> */}
                                    <Divider
                                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                                        style={{
                                            marginTop: 5,
                                            marginBottom: 10,
                                            border: "none",
                                            height: 2,
                                            margin: 0,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={1}>{/*VENCIMENTO*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Vencimento'}</MDTypography>
                                        <MDTypography variant='caption' >{vencimento}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={1}> {/* PARCELA */}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Parcela'}</MDTypography>
                                        <MDTypography variant='caption' >{`${parcela}/${qtdParcela}`}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* PARCELA */}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{tipo == 'D' ? 'Conta de pagamento' : 'Conta de recebimento'}</MDTypography>
                                        <MDTypography variant='caption' >{`${bancoPrevisao}`}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* DESCRIÇÃO */}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Descrição'}</MDTypography>
                                        <MDTypography variant='caption' >{descricaoParcela}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* OBSERVAÇÃO_PARCELA */}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Observação'}</MDTypography>
                                        <MDTypography variant='caption' >{observacaoParcela}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* VALOR_ORIGINAL_PARCELA */}
                                    <MDBox width='100%' display='flex' alignText='flex-end' alignItems='flex-end' justifyContent={'flex-end'} flexDirection='row'>
                                        <MDTypography variant='caption' fontWeight="bold">{'Valor inicial  '}</MDTypography>
                                        <Tooltip
                                            title="O valor da parcela no momento da sua criação não sofre alterações por lançamentos"
                                            placement="top"
                                            disableInteractive
                                        >
                                            <HelpIcon color="info" />
                                        </Tooltip>
                                    </MDBox>
                                    <MDBox width='100%' display='flex' alignText='flex-end' alignItems='flex-end' flexDirection='column'>
                                        <MDTypography fontWeight="bold" >{formataValor(valorParcela)}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={2}> {/* VALOR_PARCELA */}
                                    <MDBox width='100%' display='flex' alignText='flex-end' alignItems='flex-end' justifyContent={'flex-end'} flexDirection='row'>
                                        <MDTypography variant='caption' fontWeight="bold">{'Valor atual  '}</MDTypography>
                                        <Tooltip
                                            title="O valor considera juros, multa, tarifas e descontos caso tenham
                                             sido informados no momento da baixa ou lançados avulso."
                                            placement="top"
                                            disableInteractive
                                        >
                                            <HelpIcon color="info" />
                                        </Tooltip>
                                    </MDBox>
                                    <MDBox width='100%' display='flex' alignText='flex-end' alignItems='flex-end' flexDirection='column'>
                                        <MDTypography fontWeight="bold" >{formataValor(valorParcela + (totalJuros * -1) + (totalMulta * -1) - totalTarifa - totalDesconto)}</MDTypography> {/*Valor da parcela + juros + multa - desconto*/}
                                    </MDBox>
                                </Grid>

                            </Grid>

                        </MDBox>

                        <MDBox ml={2} mr={2} mt={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* INFORMAÇÕES LANCAMENTOS */}
                            <Grid container spacing={2} mt={1} pl={2} pr={2}>

                                <Grid item xs={12} sm={11}> {/* TITULO*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                        <MDTypography variant='subtitle2' pb={1} fontWeight="bold">Informações detalhadas dos lançamentos</MDTypography>
                                        <MDTypography variant='caption'>{'Em cada lote de quitação pode conter Juros/Multa e/ou Desconto/Tarifa'}</MDTypography>
                                    </MDBox>
                                    <Divider
                                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                                        style={{
                                            marginTop: 5,
                                            marginBottom: 10,
                                            border: "none",
                                            height: 2,
                                            margin: 0,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={1}> {/* SITUAÇÃO */}
                                    <MDBox width='100%'
                                        justifyContent="flex-end" flexDirection='row'>
                                        <MDBox
                                            display="flex"
                                            alignText={'center'}
                                            width="100px"
                                            justifyContent={'center'}
                                            sx={{ background: `${situacao == 'PAGO' ? '#bce2be' : situacao == 'PAGO PARCIAL' ? '#aecef7' : '#ffd59f'}` }}
                                            borderRadius="sm"
                                            p={0.5}
                                        >
                                            <MDTypography fontWeight="medium"
                                                variant="caption"
                                                textTransform="capitalize"
                                            >
                                                {situacao}
                                            </MDTypography>
                                        </MDBox>
                                    </MDBox>
                                </Grid>
                                <Grid item xs={12} sm={12}>  {/* TABELA*/}
                                    {
                                        <TabelaPadrao
                                            colunas={colunasTable}
                                            linhas={linhas}
                                            countAll={countAll}
                                            page={page}
                                            setPage={setPage}
                                            rowsPerPage={rowsPerPage}
                                            setRowsPerPage={setRowsPerPage}
                                            order={order}
                                            setOrder={setOrder}
                                            orderBy={orderBy}
                                            setOrderBy={setOrderBy}
                                        />

                                        //:

                                        //<MDTypography variant='caption'>{'Não há informações de lançamentos para esta parcela.'}</MDTypography>

                                    }
                                </Grid>

                                <Grid item xs={12} sm={9}> {/* espaço em branco */}
                                </Grid>

                                <Grid item xs={12} sm={1.5}> {/* Valor em aberto*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-end' justifyContent={'flex-end'} flexDirection='row'>
                                        <MDTypography variant='caption' fontWeight="bold">Valor em aberto(R$) </MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={1}> {/* Valor pago */}
                                    <MDBox width='100%' display='flex' alignItems='flex-end' justifyContent={'flex-end'} flexDirection='row'>
                                        <MDTypography variant='caption' fontWeight="bold">Valor pago(R$) </MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={0.5}> {/* espaço em branco */}
                                </Grid>

                                <Grid item xs={12} sm={9.5}> {/* espaço em branco*/}
                                </Grid>

                                <Grid item xs={12} sm={1}> {/* Valor em aberto */}
                                    <MDBox width='100%' display='flex' alignItems='flex-end' justifyContent={'flex-end'} flexDirection='row'>
                                        <MDTypography variant='caption' color='error' fontWeight="bold">{formataValor(valorPago).replace("R$", "")}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={1}> {/* Valor pago*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-end' justifyContent={'flex-end'} flexDirection='row'>
                                        <MDTypography variant='caption' color='info' fontWeight="bold">{formataValor((valorParcela + (totalJuros * -1) + (totalMulta * -1) - totalTarifa - totalDesconto) - valorPago).replace("R$", "")}</MDTypography>
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={0.5}> {/* espaço em branco */}
                                </Grid>

                                <Grid item xs={12} sm={9.5}> {/* espaço em branco */}
                                </Grid>

                                <Grid item xs={12} sm={1}> {/* Campos*/}
                                    <MDBox width='100%' display='flex' alignItems='flex-end' justifyContent={'flex-end'} flexDirection='column'>
                                        <MDTypography variant='caption' pb={1} fontWeight="regular" >Parcelas(R$):</MDTypography>
                                        <MDTypography variant='caption' pb={1} fontWeight="regular" >Juros(R$):</MDTypography>
                                        <MDTypography variant='caption' pb={1} fontWeight="regular" >Multas(R$):</MDTypography>
                                        <MDTypography variant='caption' pb={1} fontWeight="regular" >Descontos(R$):</MDTypography>
                                        {tipo == 'R' &&
                                            <MDTypography variant='caption' pb={1} fontWeight="regular" >Tarifas(R$):</MDTypography>
                                        }
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={1}> {/* Valores pagos */}
                                    <MDBox width='100%' display='flex' alignItems='flex-end' justifyContent={'flex-end'} flexDirection='column'>
                                        <MDTypography variant='caption' pb={1} fontWeight="bold">{formataValor(valorParcela).replace("R$", "")}</MDTypography>
                                        <MDTypography variant='caption' pb={1} fontWeight="bold">{formataValor(totalJuros * (-1)).replace("R$", "")}</MDTypography>
                                        <MDTypography variant='caption' pb={1} fontWeight="bold">{formataValor(totalMulta * (-1)).replace("R$", "")}</MDTypography>
                                        <MDTypography variant='caption' pb={1} fontWeight="bold">{formataValor(totalDesconto).replace("R$", "")}</MDTypography>
                                        {tipo == 'R' &&
                                            <MDTypography variant='caption' pb={1} fontWeight="bold">{formataValor(totalTarifa).replace("R$", "")}</MDTypography>
                                        }
                                    </MDBox>
                                </Grid>

                                <Grid item xs={12} sm={0.5}> {/* espaço em branco */}
                                </Grid>
                            </Grid>

                        </MDBox>
                        {rateio == 'S' &&
                            //console.log("Dados Rateio",dadosRateio)
                            <MDBox ml={2} mr={2} mt={2} pb={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/*RATEIO*/}
                                <Grid container spacing={2} mt={1} pl={2} pr={2}>
                                    <Grid item xs={12} sm={12}> {/* TITULO*/}
                                        <MDTypography variant='subtitle2' fontWeight="bold">Informações de categoria e centro de custo</MDTypography>
                                        <Divider
                                            sx={{ bgcolor: (theme) => theme.palette.divider }}
                                            style={{
                                                marginTop: 5,
                                                marginBottom: 10,
                                                border: "none",
                                                height: 2,
                                                margin: 0,
                                            }}
                                        />
                                    </Grid>
                                    {dadosRateio?.map((item, index) =>

                                        <>
                                            <Grid item xs={12} sm={4} >{/*CATEGORIA*/}
                                                <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                                    <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Categoria'}</MDTypography>
                                                    <MDTypography variant='caption' >{item.Categorium.nome}</MDTypography>
                                                </MDBox>
                                            </Grid>

                                            <Grid item xs={12} sm={2}> {/*CENTRO_CUSTO*/}
                                                <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                                    <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Centro de custo'}</MDTypography>
                                                    <MDTypography variant='caption' >{item.Centro_Custo?.nome}</MDTypography>
                                                </MDBox>
                                            </Grid>

                                            <Grid item xs={12} sm={2}> {/*Percentual*/}
                                                <MDBox width='100%' display='flex' alignItems='flex-start' flexDirection='column'>
                                                    <MDTypography variant='caption' fontWeight="bold" pb={1}>{'Percentual'}</MDTypography>
                                                    <MDTypography variant='caption' >{`${item.percentual} %`}</MDTypography>
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} sm={4}> {/*ESPAÇO*/}
                                                
                                            </Grid>

                                        </>



                                    )}
                                </Grid>
                            </MDBox>
                        }

                    </DialogContentText>
                </DialogContent>

                <DialogActions sx={{ padding: '7px', margin: 0.5 }}> {/*FOOTER*/}
                    <MDBox width="100%">
                        <MDBox width="100%" display="flex" justifyContent="space-between">
                            <MDButton onClick={() => {
                                handleCancel()
                                limpaImputs()
                            }} variant="gradient" type="button" color="secondary">
                                voltar
                            </MDButton>
                            <MDButton
                                disabled={linhas.length > 0 ? false : true}
                                onClick={() => handleClickEstonar(idParcela)}
                                variant="gradient"
                                type="submit"
                                //type="button" 
                                color="info">
                                {'Excluir todos os lançamentos'}
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </DialogActions>
            </Dialog>
        </>
    );
}