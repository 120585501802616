import { Autocomplete, Grid, Typography } from '@mui/material';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';


const Cabecalho = (props) => {
  const { control, opcoesModulo_Sistema_id, opcoesCategoria_Central_Relatorio_id, opcoesAtivo, opcoesPermissao_id, getValues, setValues } = props;
  return (
    <MDBox p={2}>
      <MDBox mb={3}>
        <Typography variant='h6' >Dados de Relatorios_Personalizados: {`${getValues('id')}`}</Typography>
      </MDBox>

      <Grid container spacing={1}>

        <Grid item xs={12} sm={12}>{/*dados de Relatorios_Personalizados*/}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>{/*id*/}
              <Controller
                name={'id'}
                control={props.control}
                defaultValue=''
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    key={'id'}
                    id={'id'}
                    autoComplete={false}
                    type='text'
                    label='ID'
                    rows={1}
                    multiline={false}
                    required={false}
                    disabled={true}
                    inputProps={{
                      disabled: true
                    }}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>

            <Grid item xs={12} sm={3}>{/*Ativo*/}
              <Controller
                name='ativo'
                control={control}
                rules={{required: true}}
                defaultValue={{ value: 'S' }}
                disableClearable //Retira o X (clear)
                render={({ field: { onChange, ...props } }) => (
                  <AutoCompleteStyled
                    options={opcoesAtivo}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => <FormField {...params} label='Ativo' />}
                    onChange={(_, data) => {
                      return onChange(data);
                    }}
                    disabled={false}
                    {...props}
                  />
                )}

              />
            </Grid>

            <Grid item xs={12} sm={3}>{/*Filtro_Externo*/}
              <Controller
                name='filtro_externo'
                control={control}
                rules={{required: true}}
                defaultValue={{ value: 'N' }}
                disableClearable //Retira o X (clear)
                render={({ field: { onChange, ...props } }) => (
                  <AutoCompleteStyled
                    options={opcoesAtivo}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => <FormField {...params} label='Filtro Externo' />}
                    onChange={(_, data) => {
                      return onChange(data);
                    }}
                    disabled={false}
                    {...props}
                  />
                )}

              />
            </Grid>

            <Grid item xs={12} sm={4}>{/*Modulo_Sistema_id*/}
              <Controller
                name='modulo_sistema_id'
                control={control}
                defaultValue={{ value: 'F' }}
                render={({ field: { onChange, ...props } }) => (
                  <AutoCompleteStyled
                    options={opcoesModulo_Sistema_id}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => <FormField {...params} label='Módulos do Sistema' />}
                    onChange={(_, data) => {
                      return onChange(data);
                    }}
                    disabled={false}
                    {...props}
                  />
                )}

              />
            </Grid>

            <Grid item xs={12} sm={4}>{/*categoria_central_relatorio_id*/}
              <Controller
                name='categoria_central_relatorio_id'
                control={control}
                rules={{required: true}}
                disableClearable //Retira o X (clear)
                defaultValue={{ value: 'F' }}
                render={({ field: { onChange, ...props } }) => (
                  <AutoCompleteStyled
                    options={opcoesCategoria_Central_Relatorio_id}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => <FormField required {...params} label='Categoria' />}
                    onChange={(_, data) => {
                      return onChange(data);
                    }}
                    disabled={false}
                    {...props}
                  />
                )}

              />
            </Grid>

            <Grid item xs={12} sm={4}>{/* Permissao_id*/}
              <Controller
                name='permissao_id'
                control={control}
                defaultValue={{ value: 'N' }}
                render={({ field: { onChange, ...props } }) => (
                  <AutoCompleteStyled
                    options={opcoesPermissao_id}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => <FormField {...params} v label='Permissão' />}
                    onChange={(_, data) => {
                      return onChange(data);
                    }}
                    disabled={false}
                    {...props}
                  />
                )}

              />
            </Grid>

            <Grid item xs={12} sm={12}>{/*Nome*/}
              <Controller
                name={'nome'}
                defaultValue=''
                control={props.control}
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    key={'nome'}
                    id={'nome'}
                    autoComplete={false}
                    type='text'
                    label={'Nome'}
                    rows={1}
                    multiline={false}
                    required={true}
                    disabled={false}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>
            
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  )
}

export default Cabecalho;