function formatDate(data = new Date(), formato = 'DD/MM/YYYY hh:mm') {

    if ((!dateIsValid(data)) || (typeof data !== 'object')) {
        throw `Data Inválida  ${data}`;
    }

    try {
        const dia = (data.getDate() < 10 ? '0' + data.getDate() : data.getDate());
        const mes = (((data.getMonth() + 1) < 10 ? '0' + (data.getMonth() + 1) : (data.getMonth() + 1)));
        const ano = data.getFullYear() + '';
        const hora = (data.getHours() < 10 ? '0' + data.getHours() : data.getHours());
        const minuto = (data.getMinutes() < 10 ? '0' + data.getMinutes() : data.getMinutes());


        //const temp=formato.toUpperCase();

        let final = formato;
        final = final.replace('DD', dia);
        final = final.replace('MM', mes);
        final = final.replace('YYYY', ano);
        final = final.replace('YY', ano.length > 2 ? ano[2] + ano[3] : ano);
        final = final.replace('hh', hora);
        final = final.replace('mm', minuto);



        return final;
    }
    catch (e) {
        throw `Erro. Data Inválida  ${data}`;
    }
}

function dateIsValid(pdata) {
    /*
    Parametro-> data = object do tipo date
    Quando tenta converter para string se a data nao for valida 
    ele retorna uma string "Invalid Date"
    */
    if (typeof pdata !== 'object') {
        return false;
    }

    const data = new Date(pdata);
    return data.toString() !== 'Invalid Date' ? true : false;
}

function strToDate(data = '') {
    /*
    data=string
    retornara um objeto 
    
    */
    let temp = data;
    temp = temp.replace('/', '-');
    temp = temp.replace('/', '-');
    temp = temp.replace('/', '-');

    temp = temp.split('-');
    if (temp.length === 3) {

        let ano = parseInt(temp[2], 10);
        if (ano < 100) {
            ano = ano + 2000;
        }
        if (isNaN(ano)) {
            throw 'Data Inválida';
        }
        const mes = parseInt(temp[1], 10);
        if (isNaN(mes)) {
            throw 'Data Inválida';
        }
        const dia = parseInt(temp[0], 10);
        if (isNaN(dia)) {
            throw 'Data Inválida';
        }

        //ano, mes(0-11), dia
        let tmpdata = new Date(ano, mes - 1, dia);

        if (dateIsValid(tmpdata))
            return tmpdata;
    }

    //retorna uma data invalida 
    return new Date('0000-00-0000');
}

function strToTime(hora = '') {
    /*
    hora: no formato "hh:mm"
    retornara um objeto  Date
    
    */
    let temp = hora;

    temp = temp.split(':');
    if (temp.length === 2) {
        const hora = parseInt(temp[0], 10);
        const minuto = parseInt(temp[1], 10);

        let tmpdata = new Date(2000, 1, 1, hora, minuto);

        if (dateIsValid(tmpdata))
            return tmpdata;
    }

    //retorna uma data invalida 
    return new Date('0000-00-0000 00:00');
}

function strToDateTime(datahora = '') {
    /*
    datahora=string  no formato ptbr dd/mm/yyyy hh:mm
    retornara um objeto 
    
    */

    let vetDataCompleta = datahora;
    vetDataCompleta = vetDataCompleta.split('/');

    if (vetDataCompleta.length === 3)//aqui tem ["dia","mes","ano hh:mm"]
    {
        const vetAnoHora = vetDataCompleta[2].split(' ');//aqui tem="ano hh:mm"
        if (vetAnoHora.length === 2)//quebrou em um vetor com ['ano','hh:mm']
        {
            const vetHoraMinuto = vetAnoHora[1].split(':');

            if (vetHoraMinuto.length === 2)//quebrou um vetor com ['hh','mm']
            {
                let ano = parseInt(vetAnoHora[0], 10);
                if (ano < 100) {
                    ano = ano + 2000;
                }
                const mes = parseInt(vetDataCompleta[1], 10) - 1;
                const dia = parseInt(vetDataCompleta[0], 10);
                const hora = parseInt(vetHoraMinuto[0], 10);
                const minuto = parseInt(vetHoraMinuto[1], 10);

                let tmpdata = new Date(ano, mes, dia, hora, minuto, 0, 0);



                if (dateIsValid(tmpdata))
                    return tmpdata;
            }
        }
    }

    //retorna uma data invalida 
    return new Date('0000-00-0000');
}

function incDay(data = null, quant = 1) {

    if (!dateIsValid(data)) {
        throw `Data Inválida: ${data}`;
    }
    const previsao = new Date(data.getTime() + (quant * 86400000));

    return previsao;
}

function incMonth(data = null, quant = 1) {

    if (!dateIsValid(data)) {
        throw `Data Inválida: ${data}`;
    }

    const newDate = new Date(data.setMonth(data.getMonth() + quant));

    return newDate;
}

function decMonth(data = null, quant = 1) {

    if (!dateIsValid(data)) {
        throw `Data Inválida: ${data}`;
    }

    const newDate = new Date(data.setMonth(data.getMonth() - quant));

    return newDate;
}

function incYear(data = null, quant = 1) {

    if (!dateIsValid(data)) {
        throw `Data Inválida: ${data}`;
    }

    const newDate = new Date(data.setYear(data.getFullYear() + quant));

    return newDate;
}

function DayOfWeek(data = null) {
    if (!dateIsValid(data)) {
        throw `Data Inválida: ${data}`;
    }

    //getDay retorna 0 domingo e 6 para sabado
    //vou devolver 1 domingo e 7 para sabado
    return data.getDay() + 1;
}

function Extenso(vlr = '') {

    const temp = `${vlr}`.replace(',', '.');
    let Num = parseFloat(temp);



    if (vlr == 0) {
        return "zero";
    } else {

        var inteiro = parseInt(vlr);; // parte inteira do valor
        if (inteiro < 1000000000000000) {

            var resto = Num.toFixed(2) - inteiro.toFixed(2);       // parte fracionária do valor
            resto = resto.toFixed(2)
            var vlrS = inteiro.toString();

            var cont = vlrS.length;
            var extenso = "";
            var auxnumero;
            var auxnumero2;
            var auxnumero3;

            var unidade = ["", "um", "dois", "três", "quatro", "cinco",
                "seis", "sete", "oito", "nove", "dez", "onze",
                "doze", "treze", "quatorze", "quinze", "dezesseis",
                "dezessete", "dezoito", "dezenove"];

            var centena = ["", "cento", "duzentos", "trezentos",
                "quatrocentos", "quinhentos", "seiscentos",
                "setecentos", "oitocentos", "novecentos"];

            var dezena = ["", "", "vinte", "trinta", "quarenta", "cinquenta",
                "sessenta", "setenta", "oitenta", "noventa"];

            var qualificaS = ["reais", "mil", "milhão", "bilhão", "trilhão"];
            var qualificaP = ["reais", "mil", "milhões", "bilhões", "trilhões"];

            for (var i = cont; i > 0; i--) {
                var verifica1 = "";
                var verifica2 = 0;
                var verifica3 = 0;
                auxnumero2 = "";
                auxnumero3 = "";
                auxnumero = 0;
                auxnumero2 = vlrS.substr(cont - i, 1);
                auxnumero = parseInt(auxnumero2);


                if ((i == 14) || (i == 11) || (i == 8) || (i == 5) || (i == 2)) {
                    auxnumero2 = vlrS.substr(cont - i, 2);
                    auxnumero = parseInt(auxnumero2);
                }

                if ((i == 15) || (i == 12) || (i == 9) || (i == 6) || (i == 3)) {
                    extenso = extenso + centena[auxnumero];
                    auxnumero2 = vlrS.substr(cont - i + 1, 1)
                    auxnumero3 = vlrS.substr(cont - i + 2, 1)

                    if ((auxnumero2 != "0") || (auxnumero3 != "0"))
                        extenso += " e ";

                } else

                    if (auxnumero > 19) {
                        auxnumero2 = vlrS.substr(cont - i, 1);
                        auxnumero = parseInt(auxnumero2);
                        extenso = extenso + dezena[auxnumero];
                        auxnumero3 = vlrS.substr(cont - i + 1, 1)

                        if ((auxnumero3 != "0") && (auxnumero2 != "1"))
                            extenso += " e ";
                    }
                    else if ((auxnumero <= 19) && (auxnumero > 9) && ((i == 14) || (i == 11) || (i == 8) || (i == 5) || (i == 2))) {
                        extenso = extenso + unidade[auxnumero];
                    } else if ((auxnumero < 10) && ((i == 13) || (i == 10) || (i == 7) || (i == 4) || (i == 1))) {
                        auxnumero3 = vlrS.substr(cont - i - 1, 1);
                        if ((auxnumero3 != "1") && (auxnumero3 != ""))
                            extenso = extenso + unidade[auxnumero];
                    }

                if (i % 3 == 1) {
                    verifica3 = cont - i;
                    if (verifica3 == 0)
                        verifica1 = vlrS.substr(cont - i, 1);

                    if (verifica3 == 1)
                        verifica1 = vlrS.substr(cont - i - 1, 2);

                    if (verifica3 > 1)
                        verifica1 = vlrS.substr(cont - i - 2, 3);

                    verifica2 = parseInt(verifica1);

                    if (i == 13) {
                        if (verifica2 == 1) {
                            extenso = extenso + " " + qualificaS[4] + " ";
                        } else if (verifica2 != 0) { extenso = extenso + " " + qualificaP[4] + " "; }
                    }
                    if (i == 10) {
                        if (verifica2 == 1) {
                            extenso = extenso + " " + qualificaS[3] + " ";
                        } else if (verifica2 != 0) { extenso = extenso + " " + qualificaP[3] + " "; }
                    }
                    if (i == 7) {
                        if (verifica2 == 1) {
                            extenso = extenso + " " + qualificaS[2] + " ";
                        } else if (verifica2 != 0) { extenso = extenso + " " + qualificaP[2] + " "; }
                    }
                    if (i == 4) {
                        if (verifica2 == 1) {
                            extenso = extenso + " " + qualificaS[1] + " ";
                        } else if (verifica2 != 0) { extenso = extenso + " " + qualificaP[1] + " "; }
                    }
                    if (i == 1) {
                        if (verifica2 == 1) {
                            extenso = extenso + " " + qualificaS[0] + " ";
                        } else { extenso = extenso + " " + qualificaP[0] + " "; }
                    }
                }
            }
            resto = resto * 100;
            var aexCent = 0;
            if (resto <= 19 && resto > 0)
                extenso += " e " + unidade[resto] + " Centavos";
            if (resto > 19) {
                aexCent = parseInt(resto / 10);

                extenso += " e " + dezena[aexCent]
                resto = resto - (aexCent * 10);

                if (resto != 0)
                    extenso += " e " + unidade[resto] + " Centavos";
                else extenso += " Centavos";
            }

            return extenso;
        }
        else {
            return "Numero maior que 999 trilhões";
        }
    }
}

const copiaProfundaObjetos = (inObject) => {
    let outObject, value, key

    if (typeof inObject !== "object" || inObject === null) {
        // retorna o valor de entrada se inObject não é objeto
        return inObject
    }

    // Cria um objeto ou array para armazenar os valores
    outObject = Array.isArray(inObject) ? [] : {}

    for (key in inObject) {
        value = inObject[key]

        // Cópia recursiva (profunda) para objetos aninhados
        outObject[key] = copiaProfundaObjetos(value)
    }

    return outObject
}

const strToInt = (paramValor = '') => {

    //o parseInt retorna um inteiro se a string
    //iniciar com numero e terminar com letra

    let erro = false;
    const temp = `${paramValor}`;

    for (let i = 0; i <= (temp.length - 1); i++) {
        const item = temp[i];
        if ((item !== '1') && (item !== '2') && (item !== '3') && (item !== '4')
            && (item !== '5') && (item !== '6') && (item !== '7') && (item !== '8')
            && (item !== '9') && (item !== '0')) {
            erro = true;
            break;
        }
    }

    if (erro) {
        return NaN;
    }

    return parseInt(paramValor, 10);
}

const formataFloat = (paramValor = '', paramQtdeDigitos = 2, paramMostraZerosFinal = false) => {

    let valorStr = `${parseFloat(paramValor).toFixed(paramQtdeDigitos)}`;

    if ((!paramMostraZerosFinal) &&
        (valorStr.indexOf('.') > 0)) {
        for (let i = 1; i <= paramQtdeDigitos; i++) {
            valorStr = valorStr.replace(/0$/m, "");//substitui o ultimo zero apenas
        }
        valorStr = valorStr.replace(/\.$/m, "");//se tiver um ponto sozinho no final retira
    }


    return valorStr;
}

function formataValor(valor = 0) {
    if (!parseFloat(valor)) {
        valor = 0;
    }
    return Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(valor);
}

function retornaSomenteNumeros(param = '') {
    let resultado = '';

    for (let i = 0; i < `${param}`.length; i++) {
        if ((param[i] === '0') || (param[i] === '1') || (param[i] === '2') || (param[i] === '3') || (param[i] === '4') || (param[i] === '5') ||
            (param[i] === '6') || (param[i] === '7') || (param[i] === '8') || (param[i] === '9')) {
            resultado = `${resultado}${param[i]}`
        }
    }
    return resultado;
}

function validarCPF(cpf) {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf == '') return false;
    // Elimina CPFs invalidos conhecidos	
    if (cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999")
        return false;
    // Valida 1o digito	
    let add = 0;
    for (let i = 0; i < 9; i++)
        add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
        rev = 0;
    if (rev != parseInt(cpf.charAt(9)))
        return false;
    // Valida 2o digito	
    add = 0;
    for (let i = 0; i < 10; i++)
        add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11)
        rev = 0;
    if (rev != parseInt(cpf.charAt(10)))
        return false;
    return true;
}

function validarCNPJ(cnpj) {

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;

    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
        return false;

    return true;
}

function adicionaMascaraCPFCNPJ(paramNumero = '') {

    if ((`${paramNumero}`.length !== 11) &&
        (`${paramNumero}`.length !== 14)) {
        return paramNumero;
    }

    if (`${paramNumero}`.length === 11) {
        return `${paramNumero[0]}${paramNumero[1]}${paramNumero[2]}.${paramNumero[3]}${paramNumero[4]}${paramNumero[5]}.${paramNumero[6]}${paramNumero[7]}${paramNumero[8]}-${paramNumero[9]}${paramNumero[10]}`;
    }

    return `${paramNumero[0]}${paramNumero[1]}.${paramNumero[2]}${paramNumero[3]}${paramNumero[4]}.${paramNumero[5]}${paramNumero[6]}${paramNumero[7]}/${paramNumero[8]}${paramNumero[9]}${paramNumero[10]}${paramNumero[11]}-${paramNumero[12]}${paramNumero[13]}`;
}

function converteDataSemUTC(datastr = '') {

    let data = `${datastr}`.substring(0, 10).split('-');
    data = data[0] + '-' + data[1] + '-' + data[2];
    return data;
}

function incMinutes(data = null, quant = 1) {

    if (!dateIsValid(data)) {
        throw new Error(`Data Inválida: ${data}`);
    }
    const previsao = new Date(data.getTime() + (quant * 60000));

    return previsao;
}

function minutesBetween(enddate, begindate) {
    let diff = (enddate.getTime() - begindate.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));

}

function retiraEspacos(param = '') {
    return `${param}`.replace(/ /g, ""); //retira os espaços
}

function removeZeros(num) {
    // Tenta converter o valor para número
    const numberValue = parseFloat(num);

    // Verifica se a conversão foi bem-sucedida
    if (!isNaN(numberValue)) {
        return parseFloat(numberValue.toFixed(12));
    } else {
        return null; 
    }
}

function capitalizeInitials(text) {
    return text
        .toLowerCase()              // Transforma todo o texto em minúsculas
        .split(' ')                 // Divide o texto em palavras
        .map(word => {
            if (word.length > 0) {    // Verifica se a palavra não está vazia
                return word[0].toUpperCase() + word.slice(1); // Converte a primeira letra para maiúscula
            } else {
                return word;             // Mantém palavras vazias (pode ser o caso de múltiplos espaços)
            }
        })
        .join(' ');                 // Junta as palavras de volta em um texto
}

function reduzirTexto(texto, inicio, final) {
    if (texto.length <= inicio + final) {
        return texto
    }
    return texto.slice(0, inicio) + ' ... ' + texto.slice(-final)
}

function truncarTexto(texto, inicio) {
    if (texto.length <= inicio) {
        return texto
    }
    return texto.slice(0, inicio) + ' ... '
}

function handleKeyPressNumericos(event) {
    const charCode = event.which || event.keyCode
    //console.log(charCode)
    if (charCode === 46) {
        event.preventDefault();
        event.keyCode=44
      } else if (
      !(charCode >= 48 && charCode <= 57) &&
      charCode !== 44 
    ) {
      event.preventDefault()
    }
  }

module.exports = {
    formatDate,
    dateIsValid,
    strToDate,
    strToDateTime,
    incDay,
    incMonth,
    decMonth,
    incYear,
    Extenso,
    copiaProfundaObjetos,
    strToInt,
    formataValor,
    formataFloat,
    retornaSomenteNumeros,
    validarCPF,
    validarCNPJ,
    adicionaMascaraCPFCNPJ,
    converteDataSemUTC,
    incMinutes,
    minutesBetween,
    retiraEspacos,
    removeZeros,
    capitalizeInitials,
    reduzirTexto,
    handleKeyPressNumericos,
    truncarTexto
}