import React, { useContext, useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MessagesContext from 'hooks/MessagesContext';
import AuthContext from "hooks/AuthContext";
import { useForm } from 'react-hook-form';
// import MDButton from 'components/MDButton';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import Cabecalho from './cabecalho';
import ComponentesProjeto from './componentesprojeto';
import ProdutosProjeto from './produtosprojeto';
import PossibilidadesProjeto from './possibilidadesprojeto';
import ProjetoMedidas from '././medidasprojeto';
import ServicosProjeto from './servicosprojeto';
import CoresTiposProjeto from './corestiposprojeto';
import { useNavigate } from 'react-router-dom';

function Cadastro(props) {

  const navigate = useNavigate();

  
  const { projetoAtual, setProjetoAtual, setAba } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const { handleSubmit, control, setValue, getValues, setFocus } = useForm();

  const [opcoesAtivo, setOpcoesAtivo] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'S', label: 'Sim' },
    { value: 'N', label: 'Não' },
  ]);

  const [opcoesGrupo, setOpcoesGrupo] = useState([]);
  const [recarregaTabela, setRecarregaTabela] = useState(false);
  const opcaoDefaultGrupoProduto = [{ id: -1, value: -1, label: '<Selecione um Grupo>' }];

  //Usado na table do componente "possibilidadesprojeto" e no autocomplete da  "produtosproduto"
  const opcaoDefault = [{ id: -1, value: -1, label: '<Selecione...>' }];
  const [possibilidadesProjetos, setPossibilidadeProjetos] = useState(opcaoDefault);
  //******************************** */

  const limpaInputs = () => {
    setValue('id', '');
    setValue('referencia', '');
    setValue('id_externo', '');
    setValue('nome', '');
    setValue(`ativo`, { value: 'N', label: 'Não' });
    setValue(`grupo_projeto_id`, opcaoDefaultGrupoProduto[0]);
    setValue('nome_imagem', '');
    setValue('observacao', '');
  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;

    if (!data.nome) {
      camposObrigatorios = `${camposObrigatorios}/Nome`
    }

    if (!data.ativo) {
      camposObrigatorios = `${camposObrigatorios}/Ativo`
    }

    // if (!(parseInt(data.grupo_projeto_id, 10) > 0)) {
    //   camposObrigatorios = `${camposObrigatorios}/Grupo`
    // }

    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const gravarRegistro = async (data) => {

    //tratando os campos autocomplete
    data.ativo = data.ativo?.value ? data.ativo.value : '';
    data.grupo_projeto_id = parseInt(data.grupo_projeto_id?.value, 10) > 0 ? data.grupo_projeto_id.value : '';
    if (data.ativo !== 'N') {
      data.ativo = 'S'
    }

    try {
      validaCampos(data);
    }
    catch (error) {
      MensagemErro(`${error.message}`);
      return;
    }

    try {
      const dados = { empresa_id: sessaolocal.empresa_id || null };

      //pegando do OBJ do RHF apenas os campos que sao do projeto
      dados.referencia = data.referencia;
      dados.id_externo = data.id_externo;
      dados.nome = data.nome;
      dados.ativo = data.ativo;
      dados.grupo_projeto_id = data.grupo_projeto_id;
      dados.grupo_produto_id = data.grupo_produto_id;
      dados.observacao = data.observacao;

      let retorno;
      let codigoProjeto = '';

      if (parseInt(projetoAtual, 10) > 0) {
        retorno = await api.put(`${rotafechada()}projetos/${projetoAtual}`, dados);
        codigoProjeto = projetoAtual;
        setRecarregaTabela(!recarregaTabela);
      }
      else {
        if (getValues('nome_imagem') !== '') {
          dados.nome_imagem = getValues('nome_imagem');
        }
        retorno = await api.post(`${rotafechada()}projetos`, dados);
        codigoProjeto = retorno.data.data.id;
        navigate(`/projetos/projeto/${codigoProjeto}`, { replace: true });

      }

      MensagemAviso(`Gravado com sucesso! Projeto: ${codigoProjeto}`);

      //se for um novo produto entao "recarrego" a pagina
      if (codigoProjeto !== projetoAtual) {
        setProjetoAtual(codigoProjeto);
      }
    }
    catch (error) {
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaGrupos = async () => {

    try {
      let url = `${rotafechada()}grupos_projetos?limit=-1`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      let opcoesGrupoProdutoTemp = [];
      opcoesGrupoProdutoTemp.push({ ...opcaoDefaultGrupoProduto[0] });
      data.map((item) => {
        opcoesGrupoProdutoTemp.push({ id: item.id, value: item.id, label: item.nome })
      })
      setOpcoesGrupo(opcoesGrupoProdutoTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaDatasets = async () => {
    await buscaGrupos();
  }

  const novoProjeto = async () => {
    limpaInputs();
    setProjetoAtual('');
  }

  const atualizaPossibilidadesProjetos = async () => {
    try {

      const url = `${rotafechada()}projeto_possibilidades?limit=-1&projeto_id=${projetoAtual}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      //* Sera usado no select no componente "produtosprojeto" */
      const possibilidadesProjetosTemp = opcaoDefault;
      //****************************************** */
      data.forEach((item) => {
        possibilidadesProjetosTemp.push({
          value: item.possibilidade_projeto_id,
          label: item.Possibilidades_Projeto?.nome
        })
      })

      setPossibilidadeProjetos(possibilidadesProjetosTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const abrirProjeto = async () => {
    try {
      MostraTelaEspera('Aguarde...');
      let url = `${rotafechada()}projetos?id=${projetoAtual}`;
      const retorno = await api.get(encodeURI(url));
      FechaTelaEspera();
      const { data } = retorno.data;
      // console.log(data);

      if (!data.length > 0) {
        MensagemErro(`Projeto ${projetoAtual} não localizado`);
        return;
      }
      limpaInputs();

      setValue('id', data[0].id);
      setValue('referencia', data[0].referencia);
      setValue('id_externo', data[0].id_externo);
      setValue('nome', data[0].nome);
      setValue('observacao', data[0].observacao);
      setValue(`ativo`, { value: data[0].ativo === 'S' ? 'S' : 'N', label: data[0].ativo === 'S' ? 'Sim' : 'Não' });

      setValue(`grupo_projeto_id`,
        parseInt(data[0].grupo_projeto_id, 10) > 0 ?
          { value: data[0].grupo_projeto_id, label: data[0].Grupos_Projeto.nome }
          :
          opcaoDefaultGrupoProduto[0]);
      //setValue('grupo_produto_id',data[0].id);

      //o state possibilidadesProjetos é usado no produtosprojeto
      //no autocomplete
      await atualizaPossibilidadesProjetos();
      //*********************** */

    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  useEffect(async () => {
    await buscaDatasets();
    if (parseInt(projetoAtual, 10) > 0) {
      await abrirProjeto();
    }
    else {
      await novoProjeto();
    }
  }, [projetoAtual])



  return (
    <MDBox p={2}>


      <Cabecalho
        setAba={setAba}
        control={control}
        opcoesGrupo={opcoesGrupo}
        opcoesAtivo={opcoesAtivo}
        getValues={getValues}
        setValue={setValue}
        projetoAtual={projetoAtual}
        setProjetoAtual={setProjetoAtual}
        handleSubmit={handleSubmit}
        novoProjeto={novoProjeto}
        gravarRegistro={gravarRegistro}
      />


      {
        parseInt(projetoAtual, 10) > 0 ?
          <CoresTiposProjeto
            setFocus={setFocus}
            control={control}
            getValues={getValues}
            setValue={setValue}
            projetoAtual={projetoAtual}
            handleSubmit={handleSubmit}
          />
          : false
      }

      {
        parseInt(projetoAtual, 10) > 0 ?
          <PossibilidadesProjeto
            control={control}
            getValues={getValues}
            setValue={setValue}
            projetoAtual={projetoAtual}
            handleSubmit={handleSubmit}
            possibilidadesProjetos={possibilidadesProjetos}
            setPossibilidadeProjetos={setPossibilidadeProjetos}
          />
          : false
      }

      {
        parseInt(projetoAtual, 10) > 0 ?
          <ProjetoMedidas
            control={control}
            getValues={getValues}
            setValue={setValue}
            projetoAtual={projetoAtual}
            handleSubmit={handleSubmit}
          />
          : false
      }

      {
        parseInt(projetoAtual, 10) > 0 ?
          <ComponentesProjeto
            control={control}
            getValues={getValues}
            setValue={setValue}
            projetoAtual={projetoAtual}
            handleSubmit={handleSubmit}
          />
          : false
      }

      {
        parseInt(projetoAtual, 10) > 0 ?
          <ProdutosProjeto
            setFocus={setFocus}
            control={control}
            getValues={getValues}
            setValue={setValue}
            projetoAtual={projetoAtual}
            handleSubmit={handleSubmit}
            possibilidadesProjetos={possibilidadesProjetos}
            setPossibilidadeProjetos={setPossibilidadeProjetos}
          />
          : false
      }

      {
        parseInt(projetoAtual, 10) > 0 ?
          <ServicosProjeto
            setFocus={setFocus}
            control={control}
            getValues={getValues}
            setValue={setValue}
            projetoAtual={projetoAtual}
            handleSubmit={handleSubmit}
            possibilidadesProjetos={possibilidadesProjetos}
            setPossibilidadeProjetos={setPossibilidadeProjetos}
          />
          : false
      }

    </MDBox>
  )

}

export default Cadastro;
