import React, { useContext, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, Grid, IconButton, Typography, Autocomplete, Tooltip } from '@mui/material';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';

import MDTypography from 'components/MDTypography';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import MDButton from 'components/MDButton';
import AuthContext from 'hooks/AuthContext';
import TabelaOpcoes from './tabela';
import DialogoConfirma from 'componentes/DialogoConfirma';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import InfoIcon from '@mui/icons-material/Info';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/theme-ambiance';

const Relatorios_Personalizados_Sql = (props) => {


  const { handleSubmit, control, setValue, getValues, Relatorios_PersonalizadosAtual, recarregaComponenteOpcoes } = props;

  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openCollapseUltimoComando, setOpenCollapseUltimoComando] = useState(false);
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const opcaoDefault = [{ id: -1, value: -1, label: '<Selecione...>' }];
  const [recarregaTabela, setRecarregaTabela] = useState(false);
  const [codigoAtual, setCodigoAtual] = useState('');

  const objModeloRelatorios_Personalizados_Sql = {
    id: { type: 'text' },
    relatorio_personalizado_id: { type: 'text' },
    repeticao: { type: 'checkbox' },
    nome: { type: 'text' },
    sql: { type: 'text' },
    ultimo_comando: { type: 'text' },
    ativo: { type: 'text' },
  }

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${rotafechada()}relatorios_personalizados_sql/permissao/${operacao}`);
      return true;
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;

    if (!data.nome) {
      camposObrigatorios = `${camposObrigatorios}/Nome`
    }

    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const gravarRegistro = async (data) => {

    const dados = {};
    //no data esta todos os dados do RHF registrados, o que me interesse é 
    //apenas o objeto "relatorios"
    //assim resgato no data apenas os campos que se referem a relatorios
    //e monto o objeto que sera enviado para o back
    Object.keys(objModeloRelatorios_Personalizados_Sql).forEach((nome) => {
      dados[nome] = '';
      if (nome in data['relatorios_sql']) {
        if (typeof data['relatorios_sql'][nome] === 'object') {
          dados[nome] = data['relatorios_sql'][nome]?.value ? data['relatorios_sql'][nome]?.value : '';
        }
        else
          if (typeof data['relatorios_sql'][nome] === 'boolean') {
            dados[nome] = data['relatorios_sql'][nome] === true ? 'S' : 'N'
          }
          else dados[nome] = data['relatorios_sql'][nome];
      }
    })

    dados.empresa_id = sessaolocal.empresa_id || null;
    dados.relatorio_personalizado_id = Relatorios_PersonalizadosAtual
    dados.ativo = 'S'
    delete dados.id;
    delete dados.ultimo_comando;


    try {
      validaCampos(dados);
    }
    catch (error) {
      MensagemErro(`${error.message}`);
      return;
    }
    try {
      let retorno;
      let codigoTemp = data.relatorios_sql.id;

      if ((data.relatorios_sql.id) && (parseInt(data.relatorios_sql.id, 10) > 0)) {
        retorno = await api.put(`${rotafechada()}relatorios_personalizados_sql/${data.relatorios_sql.id}`, dados);
      }
      else {
        retorno = await api.post(`${rotafechada()}relatorios_personalizados_sql`, dados);
        codigoTemp = retorno.data.data.id;
      }

      MensagemAviso(`Gravado com sucesso!`);
      setCodigoAtual('');
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const novoRegistro = () => {
    //limpo os campos
    Object.keys(objModeloRelatorios_Personalizados_Sql).forEach((nome) => {
      if (objModeloRelatorios_Personalizados_Sql[nome].type === 'autocomplete') {
        setValue(`relatorios_sql.${nome}`, opcaoDefault[0])
      }
      else
        if (objModeloRelatorios_Personalizados_Sql[nome].type === 'checkbox') {
          setValue(`relatorios_sql.${nome}`, false)
        }
        else
          if (objModeloRelatorios_Personalizados_Sql[nome].type === 'text') {
            setValue(`relatorios_sql.${nome}`, '')
          }
    })
  }

  const abrirRegistro = async (codigo) => {
    try {
      let url = `${rotafechada()}relatorios_personalizados_sql?id=${codigo}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      // console.log('dados do sql do relatorio',data);

      if (!data.length > 0) {
        MensagemErro(`Componente ID ${codigo} não localizado`);
        return;
      }
      setValue('relatorios_sql.id', data[0].id);
      setValue('relatorios_sql.repeticao', data[0].repeticao === 'S' ? true : false);
      setValue(`relatorios_sql.nome`, data[0].nome,);
      setValue(`relatorios_sql.sql`, data[0].sql,);
      setValue(`relatorios_sql.ultimo_comando`, data[0].ultimo_comando,);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const excluirRegistro = async () => {

    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      await api.delete(`${rotafechada()}relatorios_personalizados_sql/${dialogoOpen.id || 0}`);
      MensagemAviso('Excluído com sucesso!');
      novoRegistro();
      setRecarregaTabela(!recarregaTabela);

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickExcluir = async (id) => {
    if (!(await validaRotaPermissao('excluir') === true)) {
      return;
    }
    setDialogoOpen({ ...dialogoOpen, visible: true, id });
  }

  useEffect(() => {
    if (parseInt(codigoAtual, 10) > 0) {
      abrirRegistro(codigoAtual);
    }
    else novoRegistro();
  }, [Relatorios_PersonalizadosAtual, codigoAtual, recarregaTabela, recarregaComponenteOpcoes])

  return (
    <MDBox p={2} bgColor='grey-100' mt={2} borderRadius="lg" coloredShadow='secondary'>
      <DialogoConfirma
        open={dialogoOpen.visible}
        title={'Confirma Exclusão'}
        color={'error'}
        message={'Certeza que deseja excluir?'}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
        handleConfirm={excluirRegistro}
      />
      <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => setOpenCollapse(!openCollapse)}
      >
        {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        <Typography variant='h6' mb={2}>Sql`s  </Typography>
      </IconButton>
      <Tooltip title="Você pode cadastrar mais de um sql para esse relatório." placement="top">
        <InfoIcon color="info" sx={{ m: 0.5 }}></InfoIcon>
      </Tooltip>

      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <form onSubmit={handleSubmit(gravarRegistro)}>
          <Grid container spacing={2}>

            <Grid item xs={12} sm={2}>{/*id*/}
              <Controller
                name={`relatorios_sql.id`}
                control={props.control}
                defaultValue='Id'
                render={({ field: { ref, ...props } }) =>
                  <FormField
                    //autoComplete={false}
                    type='text'
                    label='Id'
                    rows={1}
                    multiline={false}
                    required={false}
                    disabled={true}
                    inputProps={{
                      disabled: true
                    }}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>

            <Grid item xs={12} sm={4}>{/*nome*/}
              <Controller
                name={`relatorios_sql.nome`}
                control={props.control}
                defaultValue='Nome'
                rules={{
                  validate: value => !/^\d|[\s\u00C0-\u00FF]/.test(value) || 'A entrada não deve começar com um número, conter espaços ou acentuação'
                }}
                render={({ field: { ref, ...props }, fieldState: { error } }) =>
                  <FormField

                    //autoComplete={false}
                    type='text'
                    label='Nome'
                    rows={1}
                    multiline={false}
                    required={true}
                    disabled={false}
                    inputRef={ref}
                    // helperText='A entrada não deve conter espaços, acentuação ou números'
                    helperText={error ? 'A entrada não deve começar com um número, conter espaços ou acentuação' : ''}
                    error={!!error}
                    {...props}

                  />}
              />
            </Grid>

            <Grid item xs={12} sm={2}>{/*Repeticao*/}
              <MDBox width="100%" display="flex" alignItems='center' flexDirection='column'>
                <MDTypography fontWeight="bold" variant="caption">
                  {'Repetir Sql'}
                </MDTypography>
                <Controller
                  name={`relatorios_sql.repeticao`}
                  control={control}
                  render={({ field: { value, ...otherprops } }) => (
                    <Checkbox
                      {...otherprops}
                      checked={value ? (value === true ? true : false) : false}
                      onChange={(e) => setValue(`relatorios_sql.repeticao`, e.target.checked)}
                    />
                  )}
                />
              </MDBox>
            </Grid>

            {/* <Grid item xs={12} sm={12}>{/*sql
              <Controller
                name={`relatorios_sql.sql`}
                control={props.control}
                defaultValue='Sql'
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    //autoComplete={false}
                    type='text'
                    label='Sql'
                    rows={10}
                    multiline={true}
                    required={false}
                    disabled={false}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid> */}

            <Grid item xs={12} sm={12}>{/*sql aceeditor*/}
              <MDTypography pl={2} fontWeight="bold" variant="h6">
                {'SQL '}
              </MDTypography>
              <Typography variant='caption'>
                {`(Usar `}<strong>{` :EMPRESA `}</strong>{`NOS SQLS QUE DESEJA INFORMAR A EMPRESA_ID) Exemplo select * from "Pessoas" where empresa_id = :EMPRESA`}
              </Typography>
              <Controller
                name={`relatorios_sql.sql`}
                control={props.control}
                defaultValue='Sql'
                render={({ field: { onChange, value } }) =>
                  <AceEditor
                    focus
                    mode="sql"
                    theme="ambiance"
                    fontSize={14}
                    placeholder="Escreve seu SQL aqui..."
                    width="100%" // Ajuste a largura aqui
                    height="400px" // Ajuste a altura aqui
                    onChange={onChange}
                    editorProps={{ $blockScrolling: false }}
                    setOptions={{
                      showLineNumbers: true,
                      tabSize: 2
                    }}
                    value={value}
                  />
                }
              />
            </Grid>

            {/* <Grid item xs={12} sm={12}>{/*ultimo_comando
              {/* <MDBox p={2} bgColor='grey-100' borderRadius="lg" coloredShadow='secondary'>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpenCollapseUltimoComando(!openCollapseUltimoComando)}
              >
                {openCollapseUltimoComando ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                <MDTypography fontWeight="bold" variant="h6">
                  {'Ultimo comando SQL executado'}
                </MDTypography>
              </IconButton>
              <Collapse in={openCollapseUltimoComando} timeout="auto" unmountOnExit>
                <Grid container spacing={2}>

                  <Grid item xs={12} sm={12}>{/*ultimo_comando

                    {/* <Typography variant="body2">
                      {getValues(`relatorios_sql.ultimo_comando`)}
                    </Typography>
                    <Controller
                      name={`relatorios_sql.ultimo_comando`}
                      control={props.control}
                      defaultValue='ultimo_comando'
                      render={({ field: { ref, ...props } }) =>
                        <FormField

                          //autoComplete={false}
                          type='text'
                          label='Ultimo comando SQL executado'
                          rows={5}
                          multiline={true}
                          required={false}
                          disabled={false}
                          inputRef={ref}
                          {...props}
                        />}
                    />
                  </Grid>

                </Grid>
              </Collapse>
              {/* </MDBox> 
            </Grid> */}

            <Grid item xs={12} sm={12}>{/*ultimo_comando aceeditor*/}
              {/* <MDBox p={2} bgColor='grey-100' borderRadius="lg" coloredShadow='secondary'> */}
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpenCollapseUltimoComando(!openCollapseUltimoComando)}
              >
                {openCollapseUltimoComando ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                <MDTypography fontWeight="bold" variant="h6">
                  {'Ultimo comando SQL executado'}
                </MDTypography>
              </IconButton>
              <Collapse in={openCollapseUltimoComando} timeout="auto" unmountOnExit>
                <Grid container spacing={2}>

                  <Grid item xs={12} sm={12}>{/*ultimo_comando*/}

                    {/* <Typography variant="body2">
                      {getValues(`relatorios_sql.ultimo_comando`)}
                    </Typography> */}
                    <Controller
                      name={`relatorios_sql.ultimo_comando`}
                      control={props.control}
                      defaultValue='ultimo_comando'
                      render={({ field: { onChange, value } }) =>
                        <AceEditor
                          focus
                          mode="sql"
                          theme="ambiance"
                          fontSize={14}
                          placeholder="Ultimo comando SQL executado"
                          width="100%" // Ajuste a largura aqui
                          height="400px" // Ajuste a altura aqui
                          onChange={onChange}
                          editorProps={{ $blockScrolling: false }}
                          setOptions={{
                            showLineNumbers: true,
                            tabSize: 2
                          }}
                          value={value}
                        />
                      }
                    />
                  </Grid>

                </Grid>
              </Collapse>
              {/* </MDBox> */}
            </Grid>

          </Grid>

          <MDBox width="100%" display="flex" justifyContent="flex-end" mt={2}>
            <MDButton variant="gradient" color="info"
              type="submit"
            // onClick={() => handleSubmit(gravarRegistro)()}
            >Gravar SQL</MDButton>
            <MDButton variant="gradient" color="info" onClick={novoRegistro} sx={{ ml: 2 }}>Novo</MDButton>
          </MDBox>
          <MDBox width="100%" display="flex" mt={2}>
            <TabelaOpcoes
              Relatorios_PersonalizadosAtual={Relatorios_PersonalizadosAtual}
              abrirRegistro={abrirRegistro}
              excluirRegistro={handleClickExcluir}
              recarregaTabela={recarregaTabela}
              recarregaComponenteOpcoes={recarregaComponenteOpcoes}
              getValues={getValues}
            />
          </MDBox>
        </form>
      </Collapse>
    </MDBox >
  )
}

export default Relatorios_Personalizados_Sql;