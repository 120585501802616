import { Autocomplete, Box, Button, Checkbox, Divider, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import api from 'commons/api';
import { rotafechada } from 'commons/urlconf';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import MessagesContext from 'hooks/MessagesContext';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Controller, useWatch } from 'react-hook-form';
import MDInput from 'components/MDInput';
import CloseIcon from '@mui/icons-material/Close';
import PesquisaProduto from './pesquisaproduto';
import DialogoConfirma from 'componentes/DialogoConfirma';
import MostraImagem from 'componentes/MostraImagem';
import ZoomInIcon from '@mui/icons-material/ZoomIn';


const ComponentesProjetos = (props) => {


    const { control, setValue, getValues, vendaAtual, handleSubmit, etapaAtual,
        setEtapaAtual, objFull, proximaEtapa, urlImagem, setUrlImagem, desenhaCabecalhoPadrao } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
    const opcaoDefault = [{ id: -1, value: -1, label: '<Selecione...>' }];
    const [confPesquisaDinamica, setConfPesquisaDinamica] = useState({});
    const [openZoomImg, setOpenZoomImg] = useState(false);


    function gravarTeste(data) {
        console.log('objfull-original:', objFull);
        console.log('obj-RHF:', data);
    }

    const validaProximaEtapa = () => {

        //verificando se todos os componentes tiveram o produto selecionado

        let erro = false;
        objFull.componentes?.map((itemcomponente, index) => {

            if (getValues(`projetos.componentes[${index}].incluido`) === true) {
                if (!(parseInt(getValues(`projetos.componentes[${index}].produto_grade_id_destino`), 10) > 0)) {
                    MensagemErro('Todos os componentes devem ter um produto selecionado');
                    erro = true;
                    return;
                }
            }
        })

        if (erro === true) {
            return;
        }

        proximaEtapa();
    }

    const etapaAnterior = () => {
        setEtapaAtual(etapaAtual - 1);
    }

    useEffect(() => {
    }, [])

    const handleClickAlteraProdutoComponente = (index, tipo_produto) => {

        //procurando esse tipo_produto nas Cores Padroes do Projeto
        //se existir entao ja mando um filtro de cor_id para
        //ser enviado para as tags de filtro da tela de pesquisa

        let cor_id = '';
        let nomecor = '';

        objFull.cores?.map((itemcor, index) => {

            if (itemcor.tipo_produto_id === tipo_produto) {
                cor_id = getValues(`projetos.cores[${index}].cor_id`)?.value || '';
                nomecor = getValues(`projetos.cores[${index}].cor_id`)?.label || '';
            }
        });

        setConfPesquisaDinamica({ ...confPesquisaDinamica, indice: index, tipo_produto, cor_id, nomecor, open: true });
    }

    const handleFuncaoSelecaoPesquisaDinamica = (codigo, datasetPesquisa) => {
        /* Essa funcao é chamada pelo componente de pesquisa dinamica quando um registro é escolhido
        ele retorna os campos que foram configurados*/
        setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false });

        if (confPesquisaDinamica.indice >= 0) {
            setValue(`projetos.componentes[${confPesquisaDinamica.indice}].produto_grade_id_destino`, datasetPesquisa.id)
            setValue(`projetos.componentes[${confPesquisaDinamica.indice}].nome_produto_grade_id_destino`, `${datasetPesquisa.id}-${datasetPesquisa.nomeproduto}-${datasetPesquisa.nomecor}`)
        }
        else {

            //se nao tem indice entao foi um click no titulo do tipo
            objFull.componentes?.map((itemcomponente, index) => {
                if (itemcomponente.tipo_produto_id_destino === confPesquisaDinamica.tipo_produto) {
                    setValue(`projetos.componentes[${index}].produto_grade_id_destino`, datasetPesquisa.id)
                    setValue(`projetos.componentes[${index}].nome_produto_grade_id_destino`, `${datasetPesquisa.id}-${datasetPesquisa.nomeproduto}-${datasetPesquisa.nomecor}`)
                }
            });

        }

    }

    const tiposProdutoDestino = () => {
        console.log('renderizou a funcao')

        //separando os tipo do produto destino de forma distinta em uma tabela
        //exemplo
        /*componentes
            Vidro
                Porta
                Bandeira
            Perfis
                Contra MArco
                XXX
        */

        let tipoprodutotemp = -1;
        const componentes = [];

        objFull.componentes?.map((item, index) => {

            //trocou o tipo entao desenha o cabeçalho
            if (item.tipo_produto_id_destino !== tipoprodutotemp) {
                tipoprodutotemp = item.tipo_produto_id_destino;
                componentes.push(
                    <MDBox component="thead" bgColor='grey-200' >
                        <TableRow>
                            <TableCell>
                                <Typography component="div" variant='h6'>{item?.Tipos_Produto?.nome}</Typography>
                            </TableCell>
                            <TableCell>
                                <MDBox display="flex" alignItems='center'>
                                    <MDInput
                                        variant="outlined"
                                        fullWidth={true}
                                        autoComplete={false}
                                        type='text'
                                        label='Produto Padrão'
                                        value='Altere todos os produtos dessa categoria'
                                        rows={1}
                                        multiline={false}
                                        required={false}
                                        disabled={true}
                                        inputProps={{
                                            disabled: true,
                                        }}
                                    />

                                    <SearchIcon sx={{ cursor: 'pointer', ml: 2 }}
                                        onClick={() => {
                                            handleClickAlteraProdutoComponente(-1, item?.tipo_produto_id_destino);
                                        }}
                                    />

                                </MDBox>
                            </TableCell>
                            <TableCell>Incluído</TableCell>
                        </TableRow>
                    </MDBox>
                )
            }

            componentes.push(
                <TableBody>
                    <TableRow >
                        <TableCell>
                            
                            {
                                `${item?.Componentes_Projeto?.nome} ${item?.observacao?'(':''}${item?.observacao?item?.observacao:''}${item?.observacao?')':''}`
                            }

                        </TableCell>
                        <TableCell>
                            <MDBox display="flex" alignItems='center'>

                                <Controller
                                    name={`projetos.componentes[${index}].nome_produto_grade_id_destino`}
                                    control={props.control}
                                    defaultValue=''
                                    render={({ field: { ref, ...props } }) =>
                                        <MDInput
                                            variant="outlined"
                                            fullWidth={true}
                                            autoComplete={false}
                                            type='text'
                                            label=''
                                            rows={1}
                                            multiline={false}
                                            required={false}
                                            disabled={true}
                                            inputProps={{
                                                disabled: true,

                                            }}
                                            inputRef={ref}
                                            {...props}
                                            sx={{
                                                "& input.Mui-disabled": {
                                                    color: "green"
                                                }
                                            }}

                                        />}
                                />
                                <SearchIcon sx={{ cursor: 'pointer', ml: 2 }}
                                    onClick={() => {
                                        handleClickAlteraProdutoComponente(index, item?.tipo_produto_id_destino);
                                    }}
                                />
                            </MDBox>
                        </TableCell>
                        <TableCell>
                            <Controller
                                name={`projetos.componentes[${index}].incluido`}
                                control={control}
                                render={({ field: { value, ...otherprops } }) => (
                                    <Checkbox
                                        {...otherprops}
                                        disabled={item?.opcional === 'S' ? false : true}
                                        checked={value ? (value === true ? true : false) : false}
                                        onChange={(e) => setValue(`projetos.componentes[${index}].incluido`, e.target.checked)}
                                    />
                                )}
                            />

                        </TableCell>


                    </TableRow>
                </TableBody>
            )
        });

        return componentes;

    }


    const setCorId = () => {

    }

    const handleClickAbrirImg = async () => {
        setOpenZoomImg(true);
    }

    return (
        <>

            <MostraImagem
                open={openZoomImg}
                setOpen={setOpenZoomImg}
                img={`data:image/png[jpg];base64,${urlImagem}`}
                alt='Imagem do projeto'
            />

            <PesquisaProduto
                open={confPesquisaDinamica.open || false}
                handleClose={
                    () => setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false })
                }
                handleClick={handleFuncaoSelecaoPesquisaDinamica}
                tipoProduto={confPesquisaDinamica.tipo_produto}
                corId={confPesquisaDinamica.cor_id}
                setCorId={() => setConfPesquisaDinamica({ ...confPesquisaDinamica, cor_id: '', nomecor: '' })}
                nomeCor={confPesquisaDinamica.nomecor}
            />

            {
                //desenhaCabecalhoPadrao('Componentes - ')
            }

            <MDBox width="100%" display="flex" mb={4}>
                <MDTypography variant='button'>Escolha os produtos para cada componente</MDTypography>
            </MDBox>
            <MDBox width="100%" display="flex" mt={1}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={9}>
                        <TableContainer>
                            <Table size='small'>
                                {
                                    tiposProdutoDestino()?.map((item) => item)
                                }
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <MDBox m={2} p={2} shadow="lg" borderRadius="lg" width="100%" display='flex' justifyContent='center'>
                            {
                                urlImagem !== '' ?
                                    <img src={`data:image/png[jpg];base64,${urlImagem}`} alt="Imagem do Projeto" onClick={() => handleClickAbrirImg()} style={{ maxHeight: 200, maxWidth: 200 ,cursor: "zoom-in" }} />
                                    :
                                    false
                            }
                        </MDBox>
                        <Box display={'flex'} justifyContent={'center'}>
                            <ZoomInIcon onClick={() => handleClickAbrirImg()} sx={{ ml: 2 }} color={'info'} fontSize='medium' />
                        </Box>
                    </Grid>
                </Grid>
            </MDBox>

            <MDBox width="100%" display="flex" justifyContent="space-between" mt={2}>
                <MDButton variant="gradient" color="info" onClick={etapaAnterior} sx={{ ml: 2 }}>Retornar</MDButton>
                {
                    (props.parametrosVenda.mostra_botao_obj || false) &&
                    <MDButton variant="gradient" color='warning' onClick={() => handleSubmit(gravarTeste)()} sx={{ ml: 2 }}>Mostra OBJ</MDButton>
                }
                <MDButton variant="gradient" color="info" onClick={validaProximaEtapa} sx={{ ml: 2 }}>Avançar</MDButton>
            </MDBox>
        </>
    )
}

export default ComponentesProjetos;
