import React, { useContext, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import DialogoConfirma from 'componentes/DialogoConfirma';
import FormField from "componentes/FormField";
import { Autocomplete, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { Box } from "@mui/system";


function ExportaProjetosAdmin(props) {

  const { empresaAtual, nomeEmpresaAtual } = props;

  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const [memoLog, setMemoLog] = useState('');

  const [projetoInicial, setProjetoInicial] = useState('');
  const [projetoFinal, setProjetoFinal] = useState('');
  const [empresaDestino, setEmpresaDestino] = useState('');

  const copiaDesenhos = async () => {

    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      MostraTelaEspera('Aguarde o processamento ');
      const retorno = await api.post(`${rotafechada()}admin/empresas/copiaprojetos`, {
        empresa_id: empresaAtual,
        projetoInicial,
        projetoFinal,
        empresaDestino
      });
      FechaTelaEspera();
      MensagemAviso(retorno.data.msg);
      setMemoLog('');
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      const erro = error.response?.data?.erro || msg;
      setMemoLog(erro)
      MensagemErro(`Erro: ${msg}`);
    }
  }


  const handleClickProcessar = async () => {
    setDialogoOpen({
      ...dialogoOpen,
      visible: true,
      title: 'Confirmação',
      message: `Essa operação é irreversível! Já conferiu os códigos dos projetos e da empresa destino???`,
      funcao: ()=>copiaDesenhos()
    });
  }

  return (
    <>

      <DialogoConfirma
        open={dialogoOpen.visible}
        title={dialogoOpen.title}
        message={dialogoOpen.message}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
        handleConfirm={dialogoOpen.funcao||null}
      />

      <MDBox>
        <MDBox mt={0} mb={4} textAlign="center">
          <MDBox mb={1}>
            <MDTypography variant="h4" fontWeight="bold">
              {`Cópia de Projetos`}
            </MDTypography>
            <MDTypography variant="h6" fontWeight="bold">
              {`Empresa: ${nomeEmpresaAtual}`}
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox>
          <Grid container>

            <Grid item xs={12} sm={5} >

              <FormField
                type='number'
                label='Projeto Inicial'
                rows={1}
                multiline={false}
                required={true}
                disabled={false}
                value={projetoInicial}
                onChange={(e) => setProjetoInicial(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={1} ></Grid>
            <Grid item xs={12} sm={5} >
              <FormField
                type='number'
                label='Projeto Final'
                rows={1}
                multiline={false}
                required={true}
                disabled={false}
                value={projetoFinal}
                onChange={(e) => setProjetoFinal(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={12} mt={4} >
              <FormField
                type='number'
                label='Empresa Destino (deixe em brancos para alterar todas as empresas)'
                rows={1}
                multiline={false}
                required={false}
                disabled={false}
                value={empresaDestino}
                onChange={(e) => setEmpresaDestino(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={12} mt={4} >
              <Box display='flex' justifyContent='flex-end'>
                  <MDButton color='error' onClick={handleClickProcessar}>Processar</MDButton>

              </Box>
            </Grid>




            <Grid item xs={12} sm={12} mt={12}>
              <FormField
                type='text'
                label='Log'
                name='memoLog'
                rows={10}
                multiline={true}
                required={true}
                disabled={false}
                value={memoLog}
              />
            </Grid>

          </Grid>
        </MDBox>

      </MDBox>
    </>
  );
}

export default ExportaProjetosAdmin;
