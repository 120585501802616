import React, { useContext, useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Autocomplete, Checkbox, Grid, IconButton, Typography } from '@mui/material';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import MDButton from 'components/MDButton';
import AuthContext from 'hooks/AuthContext';
import TabelaCoresTipos from './tabela';
import DialogoConfirma from 'componentes/DialogoConfirma';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';


const CoresTiposProjeto = (props) => {

  const { handleSubmit, control, setValue, getValues, projetoAtual} = props;

  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const opcaoDefault = [{ value: -1, label: '<Selecione...>' }];
  const [recarregaTabela, setRecarregaTabela] = useState(false);
  const [codigoAtual, setCodigoAtual] = useState('');
  const [opcoesTiposProdutos,setOpcoesTiposProdutos]=useState(opcaoDefault);
  const [opcoesCores,setOpcoesCores]=useState(opcaoDefault);

  const ObjModeloProjetoCoresTipos = {
    id: { type: 'text' },
    tipo_produto_id: { type: 'autocomplete' },
    cor_id: { type: 'autocomplete' },
  }

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${rotafechada()}projeto_cores_tipos/permissao/${operacao}`);
      return true;
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;

    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const gravarRegistro = async (data) => {
    //no data esta todos os dados do RHF registrados, o que me interesse é 
    //apenas o objeto "corestipos"
    //assim resgato no data apenas os campos que se referem a corestipos
    //e monto o objeto que sera enviado para o back
    const dados = {};

    Object.keys(ObjModeloProjetoCoresTipos).forEach((nome) => {
      dados[nome] = '';
      if (nome in data['corestipos']) {
        if (typeof data['corestipos'][nome] === 'object') {
          dados[nome] = data['corestipos'][nome]?.value ? data['corestipos'][nome]?.value : '';
        }
        else
          if (typeof data['corestipos'][nome] === 'boolean') {
            dados[nome] = data['corestipos'][nome] === true ? 'S' : 'N'
          }
          else dados[nome] = data['corestipos'][nome];
      }
    })

    dados.empresa_id = sessaolocal.empresa_id || null;
    dados.projeto_id = projetoAtual;
    delete dados.id;

    try {
      validaCampos(dados);
    }
    catch (error) {
      MensagemErro(`${error.message}`);
      return;
    }

    try {
      let retorno;
      let codigoTemp = data.corestipos.id;

      if ((data.corestipos.id) && (parseInt(data.corestipos.id, 10) > 0)) {
        retorno = await api.put(`${rotafechada()}projeto_cores_tipos/${data.corestipos.id}`, dados);
      }
      else {
        retorno = await api.post(`${rotafechada()}projeto_cores_tipos`, dados);
        codigoTemp = retorno.data.data.id;
      }

      MensagemAviso(`Gravado com sucesso! ID: ${codigoTemp}`);
      setCodigoAtual('');
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }
  
  const novoComponente = () => {
    //limpo os campos
    Object.keys(ObjModeloProjetoCoresTipos).forEach((nome) => {
      if (ObjModeloProjetoCoresTipos[nome].type === 'autocomplete') {
        setValue(`corestipos.${nome}`, opcaoDefault[0])
      }
      else
        if (ObjModeloProjetoCoresTipos[nome].type === 'checkbox') {
          setValue(`corestipos.${nome}`, false)
        }
        else
          if (ObjModeloProjetoCoresTipos[nome].type === 'text') {
            setValue(`corestipos.${nome}`, '')
          }
    })
  }

  const abrirRegistro = async (codigo) => {
    try {
      let url = `${rotafechada()}projeto_cores_tipos?id=${codigo}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      if (!data.length > 0) {
        MensagemErro(`ID ${codigo} não localizado`);
        return;
      }
      setValue('corestipos.id', data[0].id);
      setValue(`corestipos.tipo_produto_id`, { value: data[0].tipo_produto_id, label: data[0].Tipos_Produto.nome});
      setValue(`corestipos.cor_id`, { value: data[0].cor_id, label: data[0].Core.nome});
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const excluirRegistro = async () => {

    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      await api.delete(`${rotafechada()}projeto_cores_tipos/${dialogoOpen.id || 0}`);
      MensagemAviso('Excluído com sucesso!');
      novoComponente();
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickExcluir = async (id) => {
    if (!(await validaRotaPermissao('excluir') === true)) {
      return;
    }
    setDialogoOpen({ ...dialogoOpen, visible: true, id });
  }

  const buscaOpcoesTipos = async () => {

    try {
      let url = `${rotafechada()}tipos_produtos?limit=-1`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      let opcoesTiposTemp = [];
      opcoesTiposTemp.push({ ...opcaoDefault[0] });
      data.map((item) => {
        opcoesTiposTemp.push({ id: item.id, value: item.id, label: item.nome })
      })
      setOpcoesTiposProdutos(opcoesTiposTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaOpcoesCores = async () => {

    try {
      let url = `${rotafechada()}cores?limit=-1`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      let opcoesCoresTemp = [];
      opcoesCoresTemp.push({ ...opcaoDefault[0] });
      data.map((item) => {
        opcoesCoresTemp.push({ id: item.id, value: item.id, label: item.nome })
      })
      setOpcoesCores(opcoesCoresTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaOpcoes=async()=>{
    await buscaOpcoesTipos();
    await buscaOpcoesCores();
  }

  useEffect(() => {
    
    if (parseInt(codigoAtual, 10) > 0) {
      abrirRegistro(codigoAtual);
    }
    else novoComponente();
  }, [projetoAtual, codigoAtual, recarregaTabela])

  useEffect(()=>{
    buscaOpcoes();
  },[]);


  return (
    <MDBox p={2} bgColor='#EAFAF1' mt={2}>

      <DialogoConfirma
        open={dialogoOpen.visible}
        title={'Confirma Exclusão'}
        color={'error'}
        message={'Certeza que deseja excluir?'}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
        handleConfirm={excluirRegistro}
      />

      <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => setOpenCollapse(!openCollapse)}
      >
        {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        <Typography variant='h6' mb={2}>Cor Padrão </Typography>
      </IconButton>

      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>{/*id*/}
            <Controller
              name={`corestipos.id`}
              control={props.control}
              defaultValue=''
              render={({ field: { ref, ...props } }) =>
                <FormField

                  autoComplete={false}
                  type='text'
                  label='Id'
                  rows={1}
                  multiline={false}
                  required={false}
                  disabled={true}
                  inputProps={{
                    disabled: true
                  }}
                  inputRef={ref}
                  {...props}
                />}
            />
          </Grid>
          
          <Grid item xs={12} sm={4}>{/*tipo_produto_id*/}
            <Controller
              name='corestipos.tipo_produto_id'
              control={control}
              defaultValue={{ value: -1 }}
              render={({ field: { onChange, ...props } }) => (
                <AutoCompleteStyled
                  options={opcoesTiposProdutos}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => <FormField {...params} label='Tipo de Produto' />}
                  onChange={(_, data) => {
                    return onChange(data);
                  }}
                  disabled={false}
                  {...props}

                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>{/*cor_id*/}
            <Controller
              name='corestipos.cor_id'
              control={control}
              defaultValue={{ value: -1 }}
              render={({ field: { onChange, ...props } }) => (
                <AutoCompleteStyled
                  options={opcoesCores}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => <MDInput {...params} label='Cor do Produto' />}
                  onChange={(_, data) => {
                    return onChange(data);
                  }}
                  disabled={false}
                  {...props}

                />
              )}
            />
          </Grid>
          
        </Grid>

        <MDBox width="100%" display="flex" justifyContent="flex-end" mt={2}>
          <MDButton variant="gradient" color="info" onClick={() => handleSubmit(gravarRegistro)()}>Gravar</MDButton>
          <MDButton variant="gradient" color="info" onClick={novoComponente} sx={{ ml: 2 }}>Novo</MDButton>
        </MDBox>

        <MDBox width="100%" display="flex" mt={2}>
          <TabelaCoresTipos
            projetoAtual={projetoAtual}
            abrirRegistro={abrirRegistro}
            excluirRegistro={handleClickExcluir}
            recarregaTabela={recarregaTabela}
          />
        </MDBox>

      </Collapse>
    </MDBox>
  )
}

export default CoresTiposProjeto;