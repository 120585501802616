import React, { useContext, useEffect, useState } from "react";

// @mui material components
import { Autocomplete, Collapse, Grid, Typography } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//My componentes e functions
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MDInput from "components/MDInput";
import FormField from "componentes/FormField";
import TabelaProjetos from "./TabelaProjetos";
import { Box } from "@mui/system";
import { retornaSomenteNumeros } from "commons/utils";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";


function Tabela(props) {

  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const { handleClickExcluir, abrirRegistro, recarregaTabela, openFiltrosAvancados,
    campoPesquisa,
    setCampoPesquisa,
    valorPesquisa,
    setValorPesquisa,
    camposFiltro,
    setCamposFiltro,
    opcaoDefaultGrupoProduto,
    opcoesFiltroGrupo,
    setOpcoesFiltroGrupo,
    filtroGrupo,
    setFiltroGrupo,
    page, setPage, rowsPerPage, setRowsPerPage, order,
    setOrder, orderBy, setOrderBy } = props;

  const [countAll, setCountAll] = React.useState(0);

  const [colunasTable, setColunasTable] = React.useState([]);
  const [linhas, setLinhas] = React.useState([]);




  const baseUrl = `${rotafechada()}projetos`;
  const baseCaption = 'Projetos';

  const buscaDados = async () => {
    if (props.validaRotaPermissao) {
      if (await props.validaRotaPermissao('listar') === false) {
        return;
      }
    }

    try {

      let complementoUrl = '';

      if ((campoPesquisa.id) && (valorPesquisa)) {
        complementoUrl = `&${campoPesquisa.id}=${valorPesquisa}`;

        if (campoPesquisa.id === 'id') {
          const temp = retornaSomenteNumeros(valorPesquisa);
          if ((`${temp}`.length !== `${valorPesquisa}`.length) ||
            (!(parseInt(`${valorPesquisa}`, 10) > 0))) {
            MensagemErro('A pesquisa por ID aceita só números, escolha outro campo para busca');
            return;
          }
        }

        if ((valorPesquisa) && (valorPesquisa.length > 0) && (valorPesquisa[0] !== '%') && (valorPesquisa[valorPesquisa.length - 1] !== '%')
          && (camposFiltro) && (camposFiltro.length > 0)) {

          //se o texto a ser buscado nao terminar ou começar com %
          //e tiver uma configuracao no camposFiltro
          //entao localizo que o campo usado nesse momento é um campo que esteja configurado
          //o buscaParcial, se sim acrescento automaticamente o % no inicio e fim


          let encontrouCampo = false;
          for (let i = 0; i < camposFiltro.length; i++) {

            if ((camposFiltro[i].buscaParcial === true) && (camposFiltro[i].id === campoPesquisa.id)) {
              encontrouCampo = true;
            }
          }

          if (encontrouCampo === true) {
            complementoUrl = `&${campoPesquisa.id}=%${valorPesquisa}%`;
          }
        }
      }

      if (page > 0) {
        complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
      }

      if (orderBy) {
        complementoUrl = `${complementoUrl}&order=${orderBy}`;
        if (order) {
          complementoUrl = `${complementoUrl}&direction=${order}`;
        }
      }

      if (parseInt(filtroGrupo?.id, 10) > 0) {
        complementoUrl = `${complementoUrl}&grupo_projeto_id=${filtroGrupo.id}`;
      }

      MostraTelaEspera(`Buscando ${baseCaption}`);

      const url = `${baseUrl}?limit=${rowsPerPage}${complementoUrl}&retornaimagem=S`;
      const retorno = await api.get(encodeURI(url));
      const { data, countAll: countAllretorno } = retorno.data;
      const dadostemp = [];

      const colunasTabletemp = [
        {
          id: 'imagem',
          numeric: false,
          disablePadding: true,
          label: 'Imagem'
        },
        {
          id: 'id',
          numeric: false,
          disablePadding: true,
          label: 'Id'
        },
        {
          id: 'nome',
          numeric: false,
          disablePadding: true,
          label: 'Nome'
        },
        {
          id: 'referencia',
          numeric: false,
          disablePadding: true,
          label: 'Ref.'
        },
        {
          id: 'nomegrupo',
          numeric: false,
          disablePadding: true,
          label: 'Grupo'
        },
        {
          id: 'ativo',
          numeric: false,
          disablePadding: true,
          label: 'Ativo'
        },
        {
          id: 'id_externo',
          numeric: false,
          disablePadding: true,
          label: 'ID.EXT.'
        },
        {
          id: 'botoes',
          numeric: false,
          disablePadding: true,
          label: 'Ações',
          disableOrder: true
        },
      ]

      data.map((item, index) => {
        item.nomegrupo = item.Grupos_Projeto?.nome || '';
        return item;
      })

      setColunasTable([...colunasTabletemp]);
      setLinhas(data);
      setCountAll(countAllretorno);
      FechaTelaEspera();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  useEffect(() => {
    buscaDados();
  }, [recarregaTabela, page, rowsPerPage, order, orderBy])

  const handleClickPesquisar = () => {
    setPage(0);
    buscaDados();
  }

  const desenhaFiltro = () => {
    return (
      <Collapse in={openFiltrosAvancados} timeout="auto" unmountOnExit>
        <Box sx={{ margin: 1 }}>
          < Grid container >
            <Grid item xs={12} sm={4} >{/* Grupo */}
              <AutoCompleteStyled
                options={opcoesFiltroGrupo}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <FormField {...params} label="Grupo" />}
                value={filtroGrupo}
                onChange={(event, newValue) => {
                  setFiltroGrupo(newValue);
                }}
                defaultValue={{ ...opcaoDefaultGrupoProduto }}
                renderOption={(props, option) => {
                  //para evitar o erro de duplicate keys...
                  return (
                    <li {...props} key={option.id}>
                      {option.label}
                    </li>
                  );
                }}
              />
            </Grid>
          </Grid>

          < Grid container spacing={2} mt={1} justifyContent="center" >
            <Grid item xs={12} sm={3} >{/* Combo com os campos que podem ser pesquisados */}
              <AutoCompleteStyled
                options={camposFiltro}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <FormField {...params} label="Campo" />}
                value={campoPesquisa}
                onChange={(event, newValue) => {
                  setCampoPesquisa(newValue);
                }}
                defaultValue={null}
              />
            </Grid>
            <Grid item xs={12} sm={7} >{/*Valor a ser Pesquisado*/}
              <FormField type="text" label="Valor a ser Pesquisado" value={valorPesquisa} onChange={(e) => setValorPesquisa(e.target.value)}

                inputProps={
                  {
                    onKeyPress: (e) => {

                      if (e.key === 'Enter') {
                        handleClickPesquisar();
                      }
                    },
                  }
                }
              />
            </Grid>
            <Grid item xs={12} sm={2} >{/*Botão do Filtro*/}
              <MDButton onClick={handleClickPesquisar} color='info'> Pesquisar</MDButton>
            </Grid>
          </Grid >
        </Box>
      </Collapse>
    )
  }


  const buscaOpcoesGrupoProduto = async () => {
    try {
      const url = `${rotafechada()}grupos_projetos?limit=-1`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      const opcoesTemp = [];
      opcoesTemp.push({ ...opcaoDefaultGrupoProduto });
      data.map((item) => {
        opcoesTemp.push({ id: item.id, value: item.value, label: item.nome, tipo_produto_id: item.tipo_produto_id })
      })
      setOpcoesFiltroGrupo(opcoesTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }


  useEffect(() => {
    buscaOpcoesGrupoProduto();
  }, [])


  return (
    <>

      <MDBox p={3} lineHeight={1}>
        <MDTypography variant="h5" fontWeight="medium">
          {desenhaFiltro()}
        </MDTypography>
      </MDBox>

      <TabelaProjetos
        colunas={colunasTable}
        linhas={linhas}
        countAll={countAll}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        handleClickExcluir={handleClickExcluir}
        abrirRegistro={abrirRegistro}
      />
    </>


  );
}

export default Tabela;
