import React, { useContext, useState } from 'react';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Icon, IconButton, Typography } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import GenericForm from 'componentes/GenericForm';
import { Box } from '@mui/system';
import MenuOpcoesTabelaPreco from './MenuOpcoesTabelaPreco';
import { rotaaberta, rotafechada } from '../../../../../commons/urlconf';
import api from '../../../../../commons/api';
import MessageContext from "../../../../../hooks/MessagesContext";
import FiltroInserePrecosTabelaPreco from './filtroinsereprecos';

function Cadastro(props) {

    const baseUrl = `${rotafechada()}tabelas_precos`;

    const { dataset, handleGravar, enabledBotaoGravar, setAba, codigoCarregado } = props;

    const messageContext = useContext(MessageContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const [openMenuConfiguracao, setOpenMenuConfiguracao] = useState(false);
    const [currentTargetBotaoMenu, setCurrentTargetBotaoMenu] = useState(null);
    const [openFiltroInserePrecos, setOpenFiltroInserePrecos] = useState(false);

    const handleClickInserePreco = async (tabelaEscolhida,percentual_margem) => {

        setOpenFiltroInserePrecos(false);

        if (!(parseInt(codigoCarregado, 10) > 0)) {
            MensagemErro('Selecione primeiro a tabela de preço!')
            return;
        }

        if (parseInt(codigoCarregado, 10) === parseInt(tabelaEscolhida, 10)) {
            MensagemErro('A Tabela de Preço Origem precisa ser diferente da Destino!')
            return;
        }

        try {
            let retorno;
            MostraTelaEspera('Aguarde o Processamento...');
            retorno = await api.post(`${baseUrl}/insereprecos`, {
                tabela_preco_id_origem: tabelaEscolhida,
                tabela_preco_id_destino: codigoCarregado,
                percentual_margem,
            });
            FechaTelaEspera();
            MensagemAviso('Processado com Sucesso!');
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    return (
        <>

            <FiltroInserePrecosTabelaPreco
                open={openFiltroInserePrecos}
                setOpen={setOpenFiltroInserePrecos}
                handleOk={handleClickInserePreco}
            />

            <MenuOpcoesTabelaPreco
                open={openMenuConfiguracao}
                setOpen={setOpenMenuConfiguracao}
                currentTarget={currentTargetBotaoMenu}
                handleClickInserePreco={() => setOpenFiltroInserePrecos(true)}
            />

            <MDBox p={2}>
                <MDBox width="100%" display="flex" justifyContent="space-between">
                    <MDButton
                        variant="gradient"
                        color="secondary"
                        type="button"
                        onClick={() => setAba(0)}
                    >Voltar</MDButton>
                    <Box>
                        <IconButton
                            onClick={(event) => {
                                setCurrentTargetBotaoMenu(event.currentTarget);
                                setOpenMenuConfiguracao(true);
                            }
                            }
                        >
                            <SettingsIcon />
                        </IconButton>
                        <MDButton
                            variant="gradient"
                            circular
                            color="info"
                            type="button"
                            onClick={props.handleClickNovo}
                        >
                            <Icon>add</Icon>
                        </MDButton>
                    </Box>
                </MDBox>
                <MDBox>
                    <MDBox mb={3}>
                        <Typography variant='h6' ></Typography>
                    </MDBox>
                    <GenericForm
                        permiteAlteracao={true}
                        funcaoGravar={enabledBotaoGravar ? handleGravar : null} //precisa retornar um boolean
                        dataset={dataset}

                    />
                </MDBox>

            </MDBox>
        </>
    )

}

export default Cadastro;