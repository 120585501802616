import React, { useContext, useEffect, useState } from 'react';
import { Grid, Card } from '@mui/material';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';

import MDTypography from 'components/MDTypography';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import MDButton from 'components/MDButton';
import AuthContext from 'hooks/AuthContext';
import DialogoConfirma from 'componentes/DialogoConfirma';
import ImagemPerfilEmpresa from "./imagemperfilempresa";
import id from 'date-fns/esm/locale/id/index.js';

const camposEmpresaUsuarios = {
    id: '',
    nome: '',
    email: '',
    conf_email: '',
    nome_imagem_logo: ''

}

function PerfilLogado(props) {

    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();
    const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
    const [datasetEmpresaUsuarios, setDatasetEmpresaUsuarios] = useState({ ...camposEmpresaUsuarios });
    const [datasetEmpresaUsuariosOriginal, setDatasetEmpresaUsuariosOriginal] = useState({ ...camposEmpresaUsuarios });
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const baseUrl = `${rotafechada()}empresa_usuarios/perfil`;


    const buscaDados = async () => {

        try {
            //const retorno = await api.get(`${baseUrl}?id=${sessaolocal.empresa_usuario_id}`);
            const retorno = await api.get(`${baseUrl}`);
            const { data } = retorno.data;
            const dados = { ...camposEmpresaUsuarios };


            // Object.keys(camposEmpresaUsuarios).forEach((nome) => {
            //     dados[nome] = data[nome];
            // });

            dados.nome = data.nome
            dados.email = data.email
            dados.conf_email = data.email

            setDatasetEmpresaUsuarios(dados);
            setDatasetEmpresaUsuariosOriginal(dados);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const validaGravarPerfilEmpresa = async () => {
        try {

            if (datasetEmpresaUsuarios.email === datasetEmpresaUsuarios.conf_email) {
                const dados = {
                    nome: datasetEmpresaUsuarios.nome,
                    email: datasetEmpresaUsuarios.email,
                    empresa_id: sessaolocal.empresa_id
                };

                const dadosOriginais = {
                    nome: datasetEmpresaUsuariosOriginal.nome,
                    email: datasetEmpresaUsuariosOriginal.email,
                    empresa_id: sessaolocal.empresa_id
                }

                if (dados.nome != dadosOriginais.nome) {
                    if (dados.email != dadosOriginais.email) {
                        handleClickEmail();
                    }
                    else {
                        gravarPerfilEmpresa(dados);
                    }

                }
                if (dados.email != dadosOriginais.email) {
                    handleClickEmail();

                }
                if ((dados.email == dadosOriginais.email) && (dados.nome == dadosOriginais.nome)) {
                    gravarPerfilEmpresa(dados);
                }
            }
            else {
                MensagemErro('Emails não conferem');
                //alert("Emails não conferem")
            }

        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const gravarPerfilEmpresa = async (dados) => {
        try {


            if (dados.alterou_email){
                MostraTelaEspera('Gravando'); //Abre tela de espera    
    
                await api.post(`${baseUrl}`, dados);
    
                MensagemAviso('Alterado com Sucesso!');
                FechaTelaEspera(); //Fecho tela de espera
    
                authContext.logout(); //deslogar
            }
            else{
                await api.post(`${baseUrl}`, dados);
                MensagemAviso('Alterado com Sucesso!');
                buscaDados();
            }
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    useEffect(() => {
        buscaDados();
    }, [])

    const alteraCampo = (e) => {
        const dados = { ...datasetEmpresaUsuarios };
        dados[e.target.name] = e.target.value;
        setDatasetEmpresaUsuarios(dados);
    }

    const handleEmail = async () => {
        setDialogoOpen({ ...dialogoOpen, visible: false });

        try {

            const dados = {
                nome: datasetEmpresaUsuarios.nome,
                email: datasetEmpresaUsuarios.email,
                empresa_id: sessaolocal.empresa_id,
                alterou_email: true
            };

            gravarPerfilEmpresa(dados);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleClickEmail = async (id) => {

        setDialogoOpen({ ...dialogoOpen, visible: true, id });
    }

    return (
        <Card id="perfil-logado" sx={{ overflow: "visible" }}>

            <DialogoConfirma
                open={dialogoOpen.visible}
                title={'Confirma alteração de Email'}
                message={`Ao alterar seu email você será deslogado do sistema e um email de ativação será enviado para o novo email. ${datasetEmpresaUsuarios.email}`}
                handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
                handleConfirm={handleEmail}
            />


            <MDBox p={3}>
                <MDTypography variant="h5">Perfil</MDTypography>
            </MDBox>

            <MDBox component="form" pb={3} px={3}>

                <Grid container spacing={3}>

                    <Grid item xs={12} sm={8}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>{/* Nome*/}
                                <FormField
                                    label="Nome"
                                    placeholder=""
                                    name='nome'
                                    value={datasetEmpresaUsuarios.nome}
                                    onChange={alteraCampo} />
                            </Grid>
                            <Grid item xs={12} sm={12}>{/* Email */}
                                <FormField
                                    label="Email"
                                    placeholder=""
                                    name='email'
                                    value={datasetEmpresaUsuarios.email}
                                    onChange={alteraCampo} />
                            </Grid>
                            <Grid item xs={12} sm={12}>{/*Confirma Email */}
                                <FormField
                                    label="Confirma email"
                                    placeholder=""
                                    name='conf_email'
                                    value={datasetEmpresaUsuarios.conf_email}
                                    onChange={alteraCampo} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4}>{/* Img */}
                        <ImagemPerfilEmpresa
                            imagePreviewUrl={imagePreviewUrl}
                            setImagePreviewUrl={setImagePreviewUrl}
                        />
                    </Grid>

                    <Grid item xs={12} md={10}>{/* Espaço para identar */}
                    </Grid>

                    <Grid item xs={12} md={2} >{/* Botão Gravar */}
                        <MDButton color='info' onClick={validaGravarPerfilEmpresa}>Gravar</MDButton>
                    </Grid>

                </Grid>
            </MDBox>

        </Card>

    );
}

export default PerfilLogado;