import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import MDBox from 'components/MDBox';

/*
colunas = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Id'
  },
  {
    id: 'nome',
    numeric: false,
    disablePadding: true,
    label: 'Nome'
  },
  {
    id: 'ativo',
    numeric: false,
    disablePadding: true,
    label: 'Ativo',
    disableOrder: false
  },
  {
    id: 'acoes',
    numeric: false,
    disablePadding: true,
    label: 'Ações',
    disableOrder: true
  },
]

linhas = [
  {
    id:10,
    nome:'XXX',
    ativo: 'S',
    acoes:<button/>
  }
]
*/



function EnhancedTableHead(props) {
  const { headCells } = props;

  return (

    <MDBox component="thead" bgColor='grey-200' >
      {/* <TableHead> */}

      <TableRow>
        {headCells?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            <Typography
              variant="h6"
              component="div"
            >
              {headCell.label}
            </Typography>

          </TableCell>
        ))}
      </TableRow>
    </MDBox>


  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,

  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function TabelaEtapa3(props) {

  const { linhas: rows, colunas: headCells } = props;


  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size='small'
          >
            <EnhancedTableHead
              headCells={headCells}
            />
            <TableBody>
              {rows?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={`tr${index}`}
                  >
                    {
                      headCells.map((item, index) => {

                        return (
                          <TableCell
                            align={item.numeric ? 'right' : 'left'}
                            padding={item.disablePadding ? 'none' : 'normal'}
                            key={`tc${index}`}
                          >
                            {row[item.id]}
                          </TableCell>
                        )
                      })
                    }
                  </TableRow>
                );
              })}

            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default React.memo(TabelaEtapa3);