import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Grid from "@mui/material/Grid";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
// import { TransitionProps } from '@mui/material/transitions';
import MDTypography from "components/MDTypography";
import MDBox from 'components/MDBox';
import { Autocomplete, Typography } from '@mui/material';
import MDInput from 'components/MDInput';
import FormField from "componentes/FormField";
import MDButton from 'components/MDButton';
import TabelaPesquisaDinamica from './tabela';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import { rotafechada } from 'commons/urlconf';
import { Box } from '@mui/system';
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { formataValor } from 'commons/utils';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


//exemplos:
// const dadosColunas = [
//   {
//     id: 'nome',
//     numeric: false,
//     disablePadding: true,
//     label: 'Nome',
//   }...
// ];
// const dadosLinhas=[
//   {nome:'assss',valor:10,data:'01/01/2000'},
//   {nome:'bsss',valor:20,data:'01/02/2002'},
// ];

export default function PesquisaProdutoGradeVenda(props) {

  const { open, tabela_preco_id, nomeCor } = props;

  const messageContext = React.useContext(MessagesContext);
  const { MensagemErro } = messageContext;

  const camposFiltro = [{ id: 'nomeproduto', label: 'Nome', buscaParcial: true }, { id: 'id', label: 'id Produto Cor' }, { id: 'produto_id', label: 'id Produto' }, { id: 'referenciaproduto', label: 'Referência', buscaParcial: true }];


  const campoInicial = camposFiltro ? camposFiltro[0] : '';
  const [campoPesquisa, setCampoPesquisa] = React.useState(campoInicial);
  const [valorPesquisa, setValorPesquisa] = React.useState('');
  const [countAll, setCountAll] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');

  const [colunasTable, setColunasTable] = React.useState([]);
  const [linhas, setLinhas] = React.useState([]);
  const [recarregaDados, setRecarregaDados] = React.useState(false);

  // const [tagsFiltro, setTagsFiltro] = React.useState([]);

  const [opcoesFiltroTipo, setOpcoesFiltroTipo] = React.useState([]);
  const [filtroTipo, setFiltroTipo] = React.useState();

  const [opcoesFiltroCor, setOpcoesFiltroCor] = React.useState([]);
  const [filtroCor, setFiltroCor] = React.useState();



  const handleClose = () => {
    if (props.handleClose) {
      props.handleClose();
    }
  };

  const handleClickPesquisa = () => {
    if (!campoPesquisa.id) {
      MensagemErro('Selecione o campo que deseja pesquisar');
      return;
    }
    setPage(0);
    setRecarregaDados(!recarregaDados);
  }

  const buscaDados = async () => {

    try {

      const urlBase = `${rotafechada()}produto_grade_preco/produtovenda?ativo=S`;
      const operadorUrl = urlBase.indexOf('?') >= 0 ? '&' : '?';
      let complementoUrl = '';

      if ((campoPesquisa.id) && (valorPesquisa)) {
        complementoUrl = `&${campoPesquisa.id}=${valorPesquisa}`;

        if ((valorPesquisa) && (valorPesquisa.length > 0) && (valorPesquisa[0] !== '%') && (valorPesquisa[valorPesquisa.length - 1] !== '%')
          && (camposFiltro) && (camposFiltro.length > 0)) {

          //se o texto a ser buscado nao terminar ou começar com %
          //e tiver uma configuracao no camposFiltro
          //entao localizo que o campo usado nesse momento é um campo que esteja configurado
          //o buscaParcial, se sim acrescento automaticamente o % no inicio e fim


          let encontrouCampo = false;
          for (let i = 0; i < camposFiltro.length; i++) {

            if ((camposFiltro[i].buscaParcial === true) && (camposFiltro[i].id === campoPesquisa.id)) {
              encontrouCampo = true;
            }
          }

          if (encontrouCampo === true) {
            complementoUrl = `&${campoPesquisa.id}=%${valorPesquisa}%`;
          }
        }
      }

      if (page > 0) {
        complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
      }

      if (orderBy) {
        complementoUrl = `${complementoUrl}&order=${orderBy}`;
        if (order) {
          complementoUrl = `${complementoUrl}&direction=${order}`;
        }
      }

      if ((tabela_preco_id) && (parseInt(tabela_preco_id, 10) > 0)) {
        complementoUrl = `${complementoUrl}&tabela_preco_id=${tabela_preco_id}`;
      }

      if (parseInt(filtroTipo?.id, 10) > 0) {
        complementoUrl = `${complementoUrl}&tipo_produto_id=${filtroTipo.id}`;
      }

      if (parseInt(filtroCor?.id, 10) > 0) {
        complementoUrl = `${complementoUrl}&cor_id=${filtroCor.id}`;
      }


      const url = `${urlBase}${operadorUrl}limit=${rowsPerPage}${complementoUrl}&retornaimagem=S`;
      // console.log(url);
      const retorno = await api.get(encodeURI(url));
      const { data, count } = retorno.data;
      //console.log('url:', url);
      //console.log('data:');
      //console.log(data);

      if (!count > 0) {
        MensagemErro('Nenhuma informação foi selecionada');
        setLinhas([]);
        setCountAll(0)
        return;
      }
      const colunasadd = [
        {
          id: 'imagem',
          numeric: false,
          disablePadding: true,
          label: 'Imagem',
          disableOrder: true,
        },
        {
          id: 'id',
          numeric: false,
          disablePadding: true,
          label: 'ID (PG)',
        },
        {
          id: 'nomeproduto',
          numeric: false,
          disablePadding: true,
          label: 'Nome',
        },
        {
          id: 'referenciaproduto',
          numeric: false,
          disablePadding: true,
          label: 'Referência',
        },
        {
          id: 'nomecor',
          numeric: false,
          disablePadding: true,
          label: 'Cor',
        },
        {
          id: 'valor_venda',
          numeric: false,
          disablePadding: true,
          label: 'Preço',
        },
        {
          id: 'nometipo',
          numeric: false,
          disablePadding: true,
          label: 'Tipo',
        },
      ];
      setColunasTable(colunasadd);

      const linhasadd = [];
      data.forEach((item) => {
        linhasadd.push({
          imagem: item.imagem ? <img src={`data:image/png[jpg];base64,${item.imagem}`} alt="Imagem do Produto" style={{ maxWidth: 100, maxHeight: 100 }} /> : '',
          id: item.id,
          produto_id: item.Produto.id,
          nomeproduto: item.Produto.nome,
          referenciaproduto: item.Produto.referencia,
          nomecor: item.Core.nome,
          valor_venda: formataValor(item.Produto_Precos[0]?.valor_venda || ''),
          nometipo: item.Produto.Tipos_Produto?.nome || ''
        })
      });

      setLinhas(linhasadd);
      setCountAll(retorno.data.countAll || 0)
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }

  }

  const buscaOpcoesFiltros = async () => {
    let url = '';
    let retorno;
    let opcoesTemp = [];

    try {
      url = `${rotafechada()}tipos_produtos?limit=-1`;
      retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      opcoesTemp = [];

      data.map((item) => {
        opcoesTemp.push({ id: item.id, value: item.id, label: item.nome })
      })

      setOpcoesFiltroTipo(opcoesTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }

    try {
      url = `${rotafechada()}cores?limit=-1`;
      retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      opcoesTemp = [];

      data.map((item) => {
        opcoesTemp.push({ id: item.id, value: item.id, label: item.nome })
      })

      setOpcoesFiltroCor(opcoesTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickSelecionado = (...ref) => {
    if (props.handleClick) {
      props.handleClick(...ref);
      handleClose();
    }
  }

  React.useEffect(() => {

    if (open) {
      //só busca no Back se a tela estiver visivel
      buscaDados();
      buscaOpcoesFiltros();
    }

  }, [recarregaDados, rowsPerPage, page, order, orderBy, open])


  const desenhaFiltro = () => {
    //só desenha a linha do filtro de veio campos de filtro na props
    if ((camposFiltro) && (camposFiltro.length > 0)) {
      return (
        //Grid do Filtro
        < Grid container spacing={2} justifyContent="center" >

          <Grid item xs={12} sm={2} >{/*Filtro de Tipos*/}

            <AutoCompleteStyled

              options={opcoesFiltroTipo}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => <FormField {...params} label={'Tipo'}/>}
              value={filtroTipo}
              onChange={(event, newValue) => {
                setFiltroTipo(newValue);
              }}
              defaultValue={{ id: -1, value: -1, label: '<Escolha um Tipo>' }}
            />
          </Grid>

          <Grid item xs={12} sm={2} >{/*Filtro de Cor*/}
            <AutoCompleteStyled
              options={opcoesFiltroCor}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => <FormField {...params} label={'Cor'} />}
              value={filtroCor}
              onChange={(event, newValue) => {
                setFiltroCor(newValue);
              }}
              defaultValue={{ id: -1, value: -1, label: '<Escolha uma Cor>' }}
            />
          </Grid>

          <Grid item xs={12} sm={7}>{/*Espaço em branco*/}
          </Grid>

          <Grid item xs={12} sm={2}  >{/* Combo com os campos que podem ser pesquisados */}
            <AutoCompleteStyled

              options={camposFiltro}
              //getOptionLabel={(option) => option.label}
              renderInput={(params) => <FormField {...params} label='Campo'/>}
              value={campoPesquisa}
              onChange={(event, newValue) => {
                setCampoPesquisa(newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={8}  >{/*Valor a ser Pesquisado*/}
            <FormField type="text" label="Valor a ser Pesquisado" value={valorPesquisa} onChange={(e) => setValorPesquisa(e.target.value)}

              inputProps={
                {
                  onKeyPress: (e) => {

                    if (e.key === 'Enter') {
                      handleClickPesquisa();
                    }
                  },
                }
              }
            />
          </Grid>
          <Grid item xs={12} sm={1} >{/*Botão do Filtro*/}
            <MDButton onClick={handleClickPesquisa} color='info'> Pesquisar</MDButton>
          </Grid>

          <Grid item xs={12} sm={12}>

            <Box ml={8}>
              <Typography variant='button'>Filtro Aplicado: </Typography>

              {
                tabela_preco_id !== '' ?
                  <Typography variant='button' >
                    <span>Tabela de Preço:{tabela_preco_id}</span>
                  </Typography> : false
              }
            </Box>


          </Grid>



        </Grid >
      )
    }
    else return false;
  }

  return (
    <>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <MDTypography variant="h3" fontWeight="bold">
              {'Produtos'}
            </MDTypography>

          </Toolbar>
        </AppBar>


        <MDBox>

          {
            desenhaFiltro()
          }

          {/* Grid da Tabela */}
          <Grid container>
            <Grid item xs={12}>
              <TabelaPesquisaDinamica
                colunas={colunasTable}
                linhas={linhas}
                campoRetorno={'id'}
                handleClick={handleClickSelecionado}
                countAll={countAll}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
              //campoAuxiliar={}
              />
            </Grid>
          </Grid>
        </MDBox>

      </Dialog>

    </>
  );
}
