import React, { useContext, useEffect, useState, createRef } from "react";
import { green, pink } from '@mui/material/colors';
import defaultImage from "assets/images/products/choose-product.png";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MessagesContext from "hooks/MessagesContext";
import { Avatar, Button } from "@mui/material";
import { Box } from "@mui/system";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogoConfirma from "componentes/DialogoConfirma";
import AuthContext from "hooks/AuthContext";


const TAMANHO_MAX_KB_IMAGEM = 150;

export default function ImagemLogoEmpresa(props) {


  const { imagePreviewUrl, setImagePreviewUrl } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const [file, setFile] = useState(null);
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  let fileInput = createRef();

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    //maximo em KB
    if (file?.size > (TAMANHO_MAX_KB_IMAGEM * 1024)) {
      MensagemErro(`Escolha uma imagem com tamanho menor que ${TAMANHO_MAX_KB_IMAGEM}kb`);
      return;
    }

    reader.onloadend = async () => {
      const ok = await enviarImagemServidor(file);
      if (ok) {
        setFile(file);
        setImagePreviewUrl(reader.result);
        e.target.value = null;
      }
      else {
        console.log('Erro ao tentar enviar');
        setFile(null);
        setImagePreviewUrl(defaultImage);
      }
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleClickAlterar = () => {
    fileInput.current.click();
  };

  async function enviarImagemServidor(file) {

    let formData = new FormData();
    formData.append("file", file);
    let url = `${rotafechada()}empresas/imagem/${sessaolocal.empresa_id}`;
    console.log(url);
    try {

      const retorno = await api.put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        //onUploadProgress,
      });

      //se de certo gravar no arquivo ele retorna true para a funcao mostrar a foto no componente
      return true;
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro ao tentar enviar a imagem! ${msg}`);
    }
  };

  async function handleClickRemoverImagem() {
    setDialogoOpen({ ...dialogoOpen, visible: false });
    try {
      const url = `${rotafechada()}empresas/${sessaolocal.empresa_id}`;
      const retorno = await api.put(url, { nome_imagem_logo: '' });
      setImagePreviewUrl('');
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro ao tentar remover a imagem! ${msg}`);
    }
  };

  const buscaImagemLogoEmpresa = async () => {

    try {
      const url = `${rotafechada()}empresas/imagem/${sessaolocal.empresa_id}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      const src = `data:image/png[jpg];base64,${data.imagem}`;
      setImagePreviewUrl(src);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      console.log(msg);
    }
  }

  const handleClickExcluir = async () => {
    setDialogoOpen({ ...dialogoOpen, visible: true });
  }

  useEffect(() => {
    buscaImagemLogoEmpresa();
  }, []);


  return (
    <>
      <DialogoConfirma
        open={dialogoOpen.visible}
        title={'Confirma Exclusão'}
        color={'error'}
        message={'Certeza que deseja retirar a imagem?'}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
        handleConfirm={handleClickRemoverImagem}
      />

      <Box display={'flex'} justifyContent={'center'}>
        <input type="file"
          onChange={handleImageChange}
          ref={fileInput}
          style={{ display: 'none' }}
          accept="image/png, image/gif, image/jpeg, image/bmp"
        />
        <div >
          {
            imagePreviewUrl !== '' ?
              <img src={imagePreviewUrl} alt="Logo da empresa" style={{ maxHeight: 200, maxWidth: 200 }} />
              :
              <img src={defaultImage} alt="Logo da empresa" style={{maxHeight: 200, maxWidth: 200}} />
          }
        </div>
      </Box>
      <Box display={'flex'} justifyContent={'center'}>
        <AddIcon onClick={() => handleClickAlterar()} color={'info'} fontSize='medium' />
        {imagePreviewUrl !== '' &&
          <DeleteIcon onClick={() => handleClickExcluir()} sx={{ ml: 2 }} color='error' fontSize='medium' />
        }
      </Box>
    </>
  );
}