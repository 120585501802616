import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { AppBar, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import CloseIcon from '@mui/icons-material/Close';



function CabecalhoLocal(props) {

  const { tabela } = props;

  return (
    <MDBox component="thead" bgColor='grey-200' >
      <TableRow>
        {
          Object.keys(tabela[0]).map((campo, index) => 
            <TableCell key={`thc-${index}`}>{campo}</TableCell>
          )
        }
      </TableRow>
    </MDBox>
  )


}

export default function MostraTabelaFormulas(props) {
  const { onClose, open, tabela } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} fullScreen>
      {/* <DialogTitle>{'Fórmulas'}</DialogTitle> */}
      <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <MDTypography variant="h3" fontWeight="bold">
              Auditoria de Fórmulas
            </MDTypography>

          </Toolbar>
        </AppBar>
      <MDBox m={2}>
        <TableContainer>
          <Table size='small'>
            <CabecalhoLocal tabela={tabela} />
            <TableBody>
              {
                tabela.map((linha, index) =>
                  <TableRow hover index={`tr-${index}`}>
                    {
                      Object.keys(linha).map((campo) => {
                        return <TableCell 
                        sx={{
                          color: linha['calculou']?'black':'red'
                        }}
                        
                        >{campo==='calculou'?linha[campo].toString(linha[campo]):linha[campo]}</TableCell>
                      })
                    }
                  </TableRow>
                )
              }
            </TableBody>


          </Table>
        </TableContainer>
      </MDBox>

    </Dialog>
  );
}