import React, { useState, useEffect, useContext } from 'react';

// @mui material components
import Grid from "@mui/material/Grid";
import { Icon } from "@mui/material";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
//My Componentes
import FormField from "componentes/FormField";
//My componentes e functions
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import AuthContext from 'hooks/AuthContext';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';


function Cadastro(props) {

    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera, MensagemAlerta } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    const { setCodigoAbrir, codigoAbrir, setAba } = props;

    const [id, setId] = useState('');
    const [titulo, setTitulo] = useState(''); // Titulo
    const [texto, setTexto] = useState(''); // Texto
    const [ordem, setOrdem] = useState(0); // Ordem
    const [tipo, setTipo] = useState({}); //Tipo
    const optionTipo = [
        {
            label: 'Cláusulas',
            value: 'CLA'
        },
        {
            label: 'Observação',
            value: 'OBS'
        },
        {
            label: 'Pagamento',
            value: 'PAG'
        },
    ]
    const [pagamento, setPagamento] = useState(
        {
            label: 'Sim',
            value: 'S'
        },
    ); //Pagamento
    const optionPagamento = [
        {
            label: 'Sim',
            value: 'S'
        },
        {
            label: 'Não',
            value: 'N'
        },
    ]

    const buscaDados = async () => {

        if (!parseInt(props.codigoAbrir, 10) > 0)
            return;

        try {
            const retorno = await api.get(`${rotafechada()}relatorio_clausulas?id=${props.codigoAbrir}`);
            const { data, count } = retorno.data;
            console.log('dados do relatorio clausulas:', data)

            if (!count > 0) {
                MensagemErro('Nenhuma informação foi selecionada');
                return;
            }

            if (data[0].empresa_id !== sessaolocal.empresa_id) {
                MensagemErro('Registro de outra empresa!');
                return;
            }

            setId(data[0]?.id);
            setTitulo(data[0]?.titulo)
            setTexto(data[0]?.texto)
            setOrdem(data[0]?.ordem)

            setTipo(optionTipo.find(op => op.value === data[0]?.tipo));
            //setPagamento(optionPagamento.find(op => op.value === data[0]?.titulo));   
            setPagamento(data[0]?.tipo == 'PAG' ? optionPagamento[0] : optionPagamento[1]);

        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }

    }

    const handleClickGravar = async (e) => {
        e.preventDefault();

        try {
            MostraTelaEspera('Gravando o relatório cláudulas contrato...');
            let retorno;
            console.log('ORDEM ***********************', ordem)
            if (tipo?.value == 'CLA') {
                if (ordem == null || ordem < 0) {
                    MensagemAlerta(`Campo obrigatório: Ordem`)
                    FechaTelaEspera();
                    return;
                }
            }


            const dados = {
                empresa_id: sessaolocal.empresa_id || null,
                titulo: tipo?.value == 'PAG' ? pagamento.value : titulo,
                texto,
                ordem: ordem || null,
                tipo: tipo?.value
            }
            console.log(dados);

            if (codigoAbrir && parseInt(codigoAbrir, 10) > 0)
                retorno = await api.put(`${rotafechada()}relatorio_clausulas/${codigoAbrir}`, dados);
            else {
                retorno = await api.post(`${rotafechada()}relatorio_clausulas`, dados);
                props.setCodigoAbrir(retorno.data.data.id || 0)
            }

            MensagemAviso('Gravado com sucesso!');
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const limpaImputs = async () => {

        setCodigoAbrir('');
        setId('');
        setTitulo('')
        setTexto('')
        setOrdem(0)
        setTipo('')
        setPagamento('')

    }

    useEffect(() => {
        buscaDados();

    }, [codigoAbrir])


    return (
        <MDBox p={2}>
            <MDBox width="100%" display="flex" justifyContent="space-between">
                <MDButton
                    variant="gradient"
                    color="secondary"
                    type="button"
                    onClick={() => setAba(0)}
                >Voltar</MDButton>
                <MDButton
                    variant="gradient"
                    circular
                    color="info"
                    type="button"
                    onClick={limpaImputs}
                >
                    <Icon>add</Icon>
                </MDButton>
            </MDBox>

            <form onSubmit={handleClickGravar}>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} sm={3}> {/* ID */}
                        <FormField
                            type="text"
                            label="ID"
                            value={id || ''}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}> {/* TIPO*/}
                        <AutoCompleteStyled
                            //defaultValue={optionTipo ? optionTipo[0] : { value: 'N' }}
                            options={optionTipo}
                            getOptionLabel={(option) => option.label || ''}
                            renderInput={(params) => <FormField {...params} label="Tipo" required />}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={tipo}
                            onChange={(event, newValue) => {
                                setTipo(newValue);
                            }}
                        />
                    </Grid>
                    {(tipo?.value == 'CLA' || tipo?.value == 'OBS') &&

                        <Grid item xs={12} sm={3}> {/* ORDEM */}
                            <FormField
                                type="number"
                                label="Ordem"
                                value={ordem || ''}
                                required
                                onChange={(e) => setOrdem(e.target.value)}
                            />
                        </Grid>
                    }
                    {tipo?.value == 'CLA' &&

                        <Grid item xs={12} sm={12}> {/* TITULO */}
                            <FormField
                                type="text"
                                label="Titulo"
                                value={titulo || ''}
                                onChange={(e) => setTitulo(e.target.value)}
                            />
                        </Grid>
                    }
                    {(tipo?.value == 'CLA' || tipo?.value == 'OBS') &&

                        <Grid item xs={12} sm={12}> {/* TEXTO */}
                            <FormField
                                type="text"
                                label="Texto"
                                value={texto || ''}
                                onChange={(e) => setTexto(e.target.value)}
                                multiline={true}
                                rows={12}
                            />
                        </Grid>
                    }

                    {tipo?.value == 'PAG' &&

                        <Grid item xs={12} sm={3}> {/* TEXTO */}
                            <AutoCompleteStyled
                                defaultValue={optionTipo ? optionTipo[0] : { value: 'S' }}
                                options={optionPagamento}
                                getOptionLabel={(option) => option.label || ''}
                                renderInput={(params) => <FormField {...params} label="Mostra forma de pagamento" required />}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                value={pagamento}
                                onChange={(event, newValue) => {
                                    setPagamento(newValue);
                                }}
                            />
                        </Grid>
                    }

                </Grid>

                <MDBox p={2}>{/*Botão gravar */}
                    <MDBox mt={3} width="100%" display="flex" justifyContent="flex-end">
                        <MDButton
                            variant="gradient"
                            color="info"
                            //onClick={handleClickGravar}
                            type="submit"
                        >
                            Gravar
                        </MDButton>
                    </MDBox>
                </MDBox>

            </form>

        </MDBox >
    )

}

export default Cadastro;