import { Autocomplete, Box, Button, Dialog, DialogTitle, FormControl, Icon, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import React, { Component, useState } from 'react';
import { Stage, Layer, Rect, Text, Shape, Circle, Line, Image, Transformer, Arrow } from 'react-konva';
import { CompactPicker } from 'react-color';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDBox from 'components/MDBox';


export const PropriedadesTexto = (props) => {

    const { itensTela, setItensTela, selectedId, open, setOpen, opcoesTexto } = props;
    const [corAtual, setCorAtual] = useState('#FFF');

    const handleChangeColor = (color, event) => {
        const temp = itensTela.slice();
        temp[selectedId].texto.fill = color.hex;
        setCorAtual(color.hex);
        setItensTela(temp);
    }

    const handleChangeX = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].x = parseInt(e.target.value, 10) || 1;
        setItensTela(temp);
    }

    const handleChangeY = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].y = parseInt(e.target.value, 10) || 1;
        setItensTela(temp);
    }

    const handleChangeTipoTexto = (newValue) => {
        console.log(newValue);
        const temp = itensTela.slice();
        temp[selectedId].texto.tipo = newValue;
        if (newValue !== 'textosimples') {
            temp[selectedId].texto.text = newValue?.label;
        }
        setItensTela(temp);
    }

    const handleChangeTextoSimples = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].texto.text = e.target.value;
        setItensTela(temp);
    }

    const handleChangeFontSize = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].texto.fontSize = parseInt(e.target.value,10)||24;
        setItensTela(temp);
    }

    const handleChangeRotation = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].texto.rotation = parseInt(e.target.value,10)||0;
        setItensTela(temp);
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>{'Texto'}</DialogTitle>
            <Box m={2}>
                <Typography variant='h6'>Cor</Typography>
                <CompactPicker onChange={handleChangeColor} />
                <Box mt={2}>
                    <MDInput
                        label='Posição eixo X'
                        type='number'
                        value={itensTela[selectedId].x}
                        onChange={handleChangeX}
                        sx={{ mr: 2 }}
                    />
                    <MDInput
                        label='Posição eixo Y'
                        type='number'
                        value={itensTela[selectedId].y}
                        onChange={handleChangeY}
                    />
                </Box>

                <Box mt={2}>
                    <MDInput
                        label='Tamanho da Fonte'
                        type='number'
                        value={itensTela[selectedId].texto?.fontSize||24}
                        onChange={handleChangeFontSize}
                        sx={{ mr: 2 }}
                    />
                    <MDInput
                        label='Rotação'
                        type='number'
                        value={itensTela[selectedId].texto?.rotation||0}
                        onChange={handleChangeRotation}
                    />
                </Box>

                <MDBox
                    mt={2}
                    fullWidth
                >

                    <Autocomplete
                        options={opcoesTexto}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        renderInput={(params) => <MDInput {...params} variant="standard" />}
                        value={itensTela[selectedId].texto?.tipo || 'textosimples'}
                        onChange={(event, newValue) => {
                            handleChangeTipoTexto(newValue);
                        }}
                        defaultValue={null}
                    />
                </MDBox>
                {
                    itensTela[selectedId]?.texto?.tipo?.value === 'textosimples' &&
                    <MDBox
                        mt={2}
                        fullWidth>

                        <MDInput
                            fullWidth
                            label='Texto'
                            rows={3}
                            multiline
                            value={itensTela[selectedId].texto.text}
                            onChange={handleChangeTextoSimples}
                        />

                    </MDBox>
                }

            </Box>
        </Dialog>
    )

}


export const Texto = ({ shapeProps, isSelected, onSelect, onChange }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>
            <Text
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...shapeProps}
                {...shapeProps?.texto}
                draggable
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: parseInt(e.target.x(),10),
                        y: parseInt(e.target.y(),10),
                    });
                }}
                onTransformEnd={(e) => {
                    // transformer is changing scale of the node
                    // and NOT its width or height
                    // but in the store we have only width and height
                    // to match the data better we will reset scale on transform end
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();
                    const escala = scaleX > scaleY ? scaleX : scaleY;

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);
                    
                    onChange({
                        ...shapeProps,
                        x: parseInt(node.x(),10),
                        y: parseInt(node.y(),10),
                        

                        texto: {
                            ...shapeProps?.texto,
                            fontSize: parseInt(node.attrs.fontSize * escala, 10),
                            rotation: node.rotation()
                        }

                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
}
