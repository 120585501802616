import React, { useContext, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, Grid, Card, IconButton, Typography, Autocomplete } from '@mui/material';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import MDTypography from 'components/MDTypography';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import MDButton from 'components/MDButton';
import AuthContext from 'hooks/AuthContext';
import TabelaOpcoes from './tabela';
import DialogoConfirma from 'componentes/DialogoConfirma';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import { useTrataRetorno } from "commons/authUtils";

const camposEmpresaContatos = {
    id: '',
    nome: '',
    principal: false,
    telefone: '',
    tipo_telefone: '',
    email: '',
    ativo: 'S'
}

export default function EmpresaContatos(props) {

    const trataRetorno = useTrataRetorno();
    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro } = messageContext;
    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();
    const baseUrl = `${rotafechada()}empresa_contatos`;

    const [datasetEmpresaContatos, setDatasetEmpresaContatos] = useState({ ...camposEmpresaContatos });
    const [permitido, setPermitido] = useState(false);
    const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
    const [recarregaTabela, setRecarregaTabela] = useState(false);
    const [opcoesTipoTelefone, setOpcoesTipoTelefone] = useState([
        { value: -1, label: '<Selecione uma opção>' },
        { value: 'C', label: 'Celular' },
        { value: 'F', label: 'Fixo' },
        { value: 'W', label: 'WhatsApp' },
    ])

    const validaRotaPermissao = async (operacao = '', silencioso = false) => {
        if ((operacao !== 'inserir') &&
            (operacao !== 'editar') &&
            (operacao !== 'excluir') &&
            (operacao !== 'listar')) {
            MensagemErro('Escolha a operação que deseja validar a permissão!');
            return false;
        }

        //validando a permissao
        try {
            await api.get(`${baseUrl}/permissao/${operacao}`);
            return true;
        }
        catch (error) {
            trataRetorno(error?.response?.data?.erro);
            const msg = error.response?.data?.msg || error;

            if (silencioso === false) {
                MensagemErro(`Erro: ${msg}`);
            }
            return false;
        }
    }

    const gravarEmpresaContatos = async () => {
        try {

            let retorno

            const dados = {
                empresa_id: sessaolocal.empresa_id,
                id: datasetEmpresaContatos.id,
                nome: datasetEmpresaContatos.nome,
                principal: datasetEmpresaContatos.principal === true ? 'S' : 'N',
                telefone: datasetEmpresaContatos.telefone,
                tipo_telefone: datasetEmpresaContatos.tipo_telefone?.value ? datasetEmpresaContatos.tipo_telefone.value : 'F',
                email: datasetEmpresaContatos.email,
                ativo: 'S'

            };

            if ((dados.id) && (parseInt(dados.id, 10) > 0)) {
                retorno = await api.put(`${rotafechada()}empresa_contatos/${dados.id}`, dados);
            }
            else {
                delete dados.id
                retorno = await api.post(`${rotafechada()}empresa_contatos`, dados);
            }

            MensagemAviso('Gravado com Sucesso!');
            setRecarregaTabela(!recarregaTabela);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const abrirRegistro = async (codigo) => {
        try {
            let url = `${rotafechada()}empresa_contatos?id=${codigo}`;
            const retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;

            if (!data.length > 0) {
                MensagemErro(`Empresa Contato ID ${codigo} não localizado`);
                return;
            }

            const tipo_telefone = opcoesTipoTelefone.filter((item) => item.value == data[0].tipo_telefone);

            const dados = {
                id: data[0].id,
                nome: data[0].nome,
                principal: data[0].principal === 'S' ? true : false,
                telefone: data[0].telefone,
                tipo_telefone: tipo_telefone.length > 0 ? tipo_telefone[0] : opcoesTipoTelefone[0],
                email: data[0].email,

            };

            setDatasetEmpresaContatos(dados);
            setRecarregaTabela(!recarregaTabela);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const excluirRegistro = async () => {

        setDialogoOpen({ ...dialogoOpen, visible: false });

        try {
            await api.delete(`${rotafechada()}empresa_contatos/${dialogoOpen.id || 0}`);
            MensagemAviso('Excluído com sucesso!');
            limpaInputs();
            setRecarregaTabela(!recarregaTabela);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }

    }

    const handleClickExcluir = async (id) => {
        if (!(await validaRotaPermissao('excluir') === true)) {
            return;
        }
        setDialogoOpen({ ...dialogoOpen, visible: true, id });
    }

    const limpaInputs = async () => {
        const dados = {
            id: '',
            nome: '',
            principal: false,
            telefone: '',
            tipo_telefone:opcoesTipoTelefone[0],
            email: '',
        };
        setDatasetEmpresaContatos(dados);
    }

    const validaAcesso = async () => {
        const retorno = await validaRotaPermissao('listar', true);
        setPermitido(retorno || false);
    }

    useEffect(() => {
        validaAcesso();
    }, [])

    const alteraCampo = (e) => {
        const dados = { ...datasetEmpresaContatos };
        dados[e.target.name] = e.target.value;
        setDatasetEmpresaContatos(dados);
    }

    return (
        <Card id="empresa-contatos" sx={{ overflow: "visible" }}>
            <MDBox p={3}>
                <MDTypography variant="h5">Empresa Contatos</MDTypography>
            </MDBox>
            <DialogoConfirma
                open={dialogoOpen.visible}
                title={'Confirma Exclusão'}
                color={'error'}
                message={'Certeza que deseja excluir?'}
                handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
                handleConfirm={excluirRegistro}
            />
            <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={1}>{/*Id*/}
                                <FormField
                                    label="Id"
                                    disabled={true}
                                    name='id'
                                    value={datasetEmpresaContatos.id}
                                    onChange={alteraCampo}
                                />
                            </Grid>
                            <Grid item xs={12} sm={8}>{/*Nome contato*/}
                                <FormField
                                    label="Nome do contato"
                                    disabled={!permitido}
                                    name='nome'
                                    value={datasetEmpresaContatos.nome}
                                    onChange={alteraCampo}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>{/*Principal*/}
                                <MDBox width="100%" display="flex" alignItems='center' flexDirection='column'>
                                    <MDTypography variant="caption" fontWeight="bold">
                                        {'Contato Principal'}
                                    </MDTypography>
                                    <Checkbox
                                        checked={datasetEmpresaContatos.principal}
                                        onChange={(e, newValue) => {
                                            setDatasetEmpresaContatos({ ...datasetEmpresaContatos, principal: e.target.checked })
                                        }}
                                    />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} sm={4}>{/*Telefone*/}
                                <FormField
                                    label="Telefone"
                                    disabled={false}
                                    name='telefone'
                                    value={datasetEmpresaContatos.telefone}
                                    onChange={alteraCampo}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>{/*Tipo Telefone*/}
                                <AutoCompleteStyled
                                    options={opcoesTipoTelefone}
                                    getOptionLabel={(option) => option.label || ''}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    renderInput={(params) => <FormField {...params} label='Tipo do contato' />}
                                    value={datasetEmpresaContatos.tipo_telefone}
                                    onChange={(event, newValue) => {
                                        setDatasetEmpresaContatos({ ...datasetEmpresaContatos, tipo_telefone: newValue })
                                    }}
                                    disabled={!permitido}
                                    {...props}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>{/*Email*/}
                                <FormField
                                    label="Email"
                                    disabled={false}
                                    //placeholder=""
                                    name='email'
                                    value={datasetEmpresaContatos.email}
                                    onChange={alteraCampo}
                                />
                            </Grid>
                            <MDBox width="100%" display="flex" justifyContent="flex-end" mt={2}>
                                <MDButton variant="gradient" color="info" disabled={!permitido} onClick={gravarEmpresaContatos} >Gravar</MDButton>
                                <MDButton variant="gradient" color="info" disabled={!permitido} onClick={limpaInputs} sx={{ ml: 2 }}>Novo</MDButton>
                            </MDBox>
                        </Grid>
                    </Grid>
                </Grid>
            </MDBox>

            <MDBox width="100%" display="flex" mt={2}>
                <TabelaOpcoes
                    abrirRegistro={abrirRegistro}
                    excluirRegistro={handleClickExcluir}
                    recarregaTabela={recarregaTabela}
                />
            </MDBox>
        </Card >

    )
}
