import { Autocomplete, Box, Button, Dialog, DialogTitle, FormControl, Icon, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import React, { Component, useState } from 'react';
import { Stage, Layer, Rect, Text, Shape, Circle, Line, Image, Transformer, Arrow } from 'react-konva';
import { CompactPicker } from 'react-color';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDBox from 'components/MDBox';
import { calculaPosicaoTexto } from './funcoescomuns';


const LARGURAPONTASETA = 15;

export const PropriedadesCotaHorizontal = (props) => {

    const { itensTela, setItensTela, selectedId, open, setOpen, opcoesTexto } = props;

    const [corAtual, setCorAtual] = useState('#FFF');


    const handleChangeColor = (color, event) => {
        const temp = itensTela.slice();
        temp[selectedId].fill = color.hex;
        temp[selectedId].stroke = color.hex;
        temp[selectedId].texto.fill = color.hex;

        setCorAtual(color.hex);
        setItensTela(temp);
    }

    const handleChangeEspessuraBorda = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].strokeWidth = parseInt(e.target.value, 10) || 1;
        setItensTela(temp);
    }


    const handleChangeWidthHight = (e) => {

        const temp = itensTela.slice();

        if (temp[selectedId].tipo === 'cotahorizontal') {
            temp[selectedId].points[2] = parseInt(e.target.value, 10) || 1;
            const larguraseta = parseInt(temp[selectedId].points[2], 10);

            const {x:xTexto,y:yTexto}=calculaPosicaoTexto({angulo:parseInt(temp[selectedId].rotation,10)||0,largura:larguraseta,x:temp[selectedId].x,y:temp[selectedId].y});
            temp[selectedId].texto.x = xTexto;
            temp[selectedId].texto.y = yTexto;

        }
        else {
            temp[selectedId].points[3] = parseInt(e.target.value, 10) || 1;
            const alturaseta = parseInt(temp[selectedId].points[3], 10)
            temp[selectedId].texto.x = temp[selectedId].x - 25;
            temp[selectedId].texto.y = parseInt(temp[selectedId].y + alturaseta / 2, 10);
        }




        setItensTela(temp);
    }

    const handleChangeX = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].x = parseInt(e.target.value, 10) || 1;

        if (temp[selectedId].tipo === 'cotahorizontal') {
            
            const larguraseta=temp[selectedId].points[2];
            const {x:xTexto,y:yTexto}=calculaPosicaoTexto({angulo:parseInt(temp[selectedId].rotation,10)||0,largura:larguraseta,x:temp[selectedId].x,y:temp[selectedId].y});
            temp[selectedId].texto.x = xTexto;
            temp[selectedId].texto.y = yTexto;

        } else {
            const alturaseta = parseInt(temp[selectedId].points[3], 10)
            temp[selectedId].texto.x = temp[selectedId].x - 25;
            temp[selectedId].texto.y = parseInt(temp[selectedId].y + alturaseta / 2, 10);
        }
        setItensTela(temp);
    }

    const handleChangeY = (e) => {

        const temp = itensTela.slice();
        temp[selectedId].y = parseInt(e.target.value, 10) || 1;

        if (temp[selectedId].tipo === 'cotahorizontal') {
            const larguraseta=temp[selectedId].points[2];
            const {x:xTexto,y:yTexto}=calculaPosicaoTexto({angulo:parseInt(temp[selectedId].rotation,10)||0,largura:larguraseta,x:temp[selectedId].x,y:temp[selectedId].y});
            temp[selectedId].texto.x = xTexto;
            temp[selectedId].texto.y = yTexto;
        } else {
            const alturaseta = parseInt(temp[selectedId].points[3], 10)
            temp[selectedId].texto.x = temp[selectedId].x - 25;
            temp[selectedId].texto.y = parseInt(temp[selectedId].y + alturaseta / 2, 10);
        }

        setItensTela(temp);
    }

    const handleChangeTipoTexto = (newValue) => {
        console.log(newValue);
        const temp = itensTela.slice();
        temp[selectedId].texto.tipo = newValue;
        if (newValue !== 'textosimples') {
            temp[selectedId].texto.text = newValue?.caption;
        }
        setItensTela(temp);
    }

    const handleChangeTextoSimples = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].texto.text = e.target.value;
        setItensTela(temp);
    }

    const handleChangeFontSize = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].texto.fontSize = parseInt(e.target.value, 10) || 24;
        setItensTela(temp);
    }

    const handleChangeRotation = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].rotation = parseInt(e.target.value, 10) || 0;
        temp[selectedId].texto.rotation = parseInt(e.target.value, 10) || 0;
        setItensTela(temp);
    }


    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            {/* <DialogTitle>{'Cota'}</DialogTitle> */}
            <Box m={2}>
                <Typography variant='h6'>Cor de Fundo</Typography>
                <CompactPicker onChange={handleChangeColor} />
                <Box>
                    <Typography variant='h6' mt={2}>Sem Fundo</Typography>
                    <Tooltip title='Sem Fundo'>
                        <MDButton sx={{ ml: 2 }} onClick={
                            (e) => {
                                e.preventDefault();
                                const temp = itensTela.slice();
                                temp[selectedId].fill = null;
                                setItensTela(temp);
                            }
                        }>
                            <Icon fontSize='large'>border_outer</Icon>
                        </MDButton>
                    </Tooltip>


                    <MDInput
                        type='number'
                        label='Espessura da Borda'
                        value={itensTela[selectedId].strokeWidth || 1}
                        onChange={handleChangeEspessuraBorda}
                    />

                    <MDInput
                        label={itensTela[selectedId].tipo === 'cotahorizontal' ? 'Largura da Cota' : 'Altura da Cota'}
                        type='number'
                        value={itensTela[selectedId].tipo === 'cotahorizontal' ? itensTela[selectedId].points[2] : itensTela[selectedId].points[3]}
                        onChange={handleChangeWidthHight}
                        sx={{ mr: 2 }}
                    />

                </Box>

                <Box mt={2}>
                    <MDInput
                        label='Posição eixo X'
                        type='number'
                        value={itensTela[selectedId].x}
                        onChange={handleChangeX}
                        sx={{ mr: 2 }}
                    />
                    <MDInput
                        label='Posição eixo Y'
                        type='number'
                        value={itensTela[selectedId].y}
                        onChange={handleChangeY}
                    />
                </Box>


                <Box mt={2}>
                    <MDInput
                        label='Rotação'
                        type='number'
                        value={itensTela[selectedId].rotation || 0}
                        onChange={handleChangeRotation}
                    />
                    <MDInput
                        label='Tamanho da Fonte'
                        type='number'
                        value={itensTela[selectedId].texto?.fontSize || 24}
                        onChange={handleChangeFontSize}
                        sx={{ mr: 2 }}
                    />

                </Box>

                <MDBox
                    mt={2}
                    fullWidth
                >

                    <Autocomplete
                        options={opcoesTexto}
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        renderInput={(params) => <MDInput {...params} variant="standard" label='Opção de Texto' />}
                        value={itensTela[selectedId].texto.tipo}
                        onChange={(event, newValue) => {
                            handleChangeTipoTexto(newValue);
                        }}
                        defaultValue={null}
                    />
                </MDBox>
                {
                    itensTela[selectedId]?.texto?.tipo?.value === 'textosimples' &&
                    <MDBox
                        mt={2}
                        fullWidth>

                        <MDInput
                            fullWidth
                            label='Texto'
                            value={itensTela[selectedId].texto.text}
                            onChange={handleChangeTextoSimples}
                        />

                    </MDBox>
                }

            </Box>
        </Dialog>
    )

}


export const CotaHorizontal = ({ shapeProps, isSelected, onSelect, onChange }) => {

    const cotaRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            trRef.current.nodes([cotaRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>

            {shapeProps.texto ?
                <Text
                    {...shapeProps.texto}
                />
                : false
            }
            <Arrow
                onClick={onSelect}
                onTap={onSelect}
                ref={cotaRef}
                {...shapeProps}
                draggable
                onDragEnd={(e) => {

                    const x = parseInt(e.target.x(), 10);
                    const y = parseInt(e.target.y(), 10);
                    const larguraseta = parseInt(e.target.attrs.points[2], 10)

                    const {x:xTexto,y:yTexto}=calculaPosicaoTexto({angulo:parseInt(shapeProps.rotation,10)||0,largura:larguraseta,x,y})

                    shapeProps.texto.x = xTexto;
                    shapeProps.texto.y = yTexto;

                    onChange({
                        ...shapeProps,
                        x,
                        y,
                    });
                }}


                onTransformEnd={(e) => {

                    const node = cotaRef.current;

                    //posicional inicial da seta apos o redimensionamento
                    const x = parseInt(e.target.x(), 10);
                    const y = parseInt(e.target.y(), 10);
                    const larguraseta = parseInt(e.target.attrs.points[2], 10);
                    const angulo = parseInt(node.rotation(), 10);
                    const scaleX = node.scaleX();
                    //redimensionando a seta com uma nova largura
                    const novalarguraseta = parseInt(larguraseta * scaleX, 10);
                    const points = [0, 0, novalarguraseta, 0]

                    const {x:xTexto,y:yTexto}=calculaPosicaoTexto({angulo,largura:novalarguraseta,x,y})

                    //zerando a escala
                    node.scaleX(1);
                    node.scaleY(1);

                    onChange({
                        ...shapeProps,
                        x,
                        y,
                        points,
                        rotation: parseInt(node.rotation(), 10),
                        texto: {
                            ...shapeProps.texto,
                            rotation: node.rotation(),
                            x: xTexto,
                            y: yTexto,
                        }
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};
