import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
// old_import FormField from "layouts/pages/account/components/FormField";

// Data
import MDButton from "components/MDButton";
import { useContext, useEffect, useState } from "react";
import { rotafechada } from "commons/urlconf";
import api from "commons/api";
import MessagesContext from "hooks/MessagesContext";
import AuthContext from "hooks/AuthContext";
import ImagemLogoEmpresa from "./imagemlogoempresa";
import FormField from "componentes/FormField";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";
import { useTrataRetorno } from "commons/authUtils";

const camposEmpresa = {
  id: '',
  fisica_juridica: '',
  razaosocial: '',
  fantasia: '',
  cnpj: '',
  inscricao_estadual: '',
  inscricao_municipal: '',
  endereco_rua: '',
  endereco_numero: '',
  endereco_bairro: '',
  endereco_complemento: '',
  endereco_cep: '',
  endereco_cidade: '',
  endereco_estado: '',
  endereco_cidade_id: '',
  ativo: '',
  nome_imagem_logo: ''
}

function CadastroEmpresa() {

  const trataRetorno = useTrataRetorno();
  const fisica_juridica = ["Jurídica", "Física"];
  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const [datasetEmpresas, setDatasetEmpresas] = useState({ ...camposEmpresa });
  const [permitido, setPermitido] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const baseUrl = `${rotafechada()}empresas`;

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${baseUrl}/permissao/${operacao}`);
      return true;
    }
    catch (error) {
      trataRetorno(error?.response?.data?.erro);
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }

  const buscaDados = async () => {

    try {
      const retorno = await api.get(`${baseUrl}?id=${sessaolocal.empresa_id}`);
      const { data } = retorno.data;
      const dados = { ...camposEmpresa };

      Object.keys(camposEmpresa).forEach((nome) => {
        dados[nome] = data[0][nome];
      });

      setDatasetEmpresas(dados);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const gravarEmpresa = async () => {
    try {

      const dados = {
        fisica_juridica: datasetEmpresas.fisica_juridica,
        razaosocial: datasetEmpresas.razaosocial,
        fantasia: datasetEmpresas.fantasia,
        //cnpj: datasetEmpresas.cnpj,
        inscricao_estadual: datasetEmpresas.inscricao_estadual,
        inscricao_municipal: datasetEmpresas.inscricao_municipal,
        endereco_rua: datasetEmpresas.endereco_rua,
        endereco_numero: datasetEmpresas.endereco_numero,
        endereco_bairro: datasetEmpresas.endereco_bairro,
        //endereco_complemento:'',
        endereco_cep: datasetEmpresas.endereco_cep,
        endereco_cidade: datasetEmpresas.endereco_cidade,
        endereco_estado: datasetEmpresas.endereco_estado,
        //endereco_cidade_id: '',
        //ativo: '',
        //nome_imagem_logo: '' 
      };

      await api.put(`${baseUrl}/${sessaolocal.empresa_id}`, dados);
      MensagemAviso('Alterado com Sucesso!');
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const validaAcesso = async () => {
    const retorno = await validaRotaPermissao('listar', true);
    setPermitido(retorno || false);
  }

  useEffect(() => {
    validaAcesso();
    buscaDados();
  }, [])

  const alteraCampo = (e) => {
    const dados = { ...datasetEmpresas };
    dados[e.target.name] = e.target.value;
    setDatasetEmpresas(dados);
  }

  const alteraFisicaJuridica = (newValue) => {
    const dados = { ...datasetEmpresas };
    dados.fisica_juridica = newValue === 'Jurídica' ? 'J' : 'F';
    setDatasetEmpresas(dados);
  }

  return (
    <Card id="cadastro-empresa" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Dados Empresa</MDTypography>
      </MDBox>

      <MDBox component="form" pb={3} px={3}>

        <Grid container spacing={2}>

          <Grid item xs={12} sm={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>{/* Razão Social */}
                <FormField
                  label="Razão Social"
                  disabled={!permitido}
                  placeholder=""
                  name='razaosocial'
                  value={datasetEmpresas.razaosocial}
                  onChange={alteraCampo} />
              </Grid>
              <Grid item xs={12} sm={12}>{/* Nome Fantasia */}
                <FormField label="Nome Fantasia" disabled={!permitido} placeholder="" name='fantasia' value={datasetEmpresas.fantasia} onChange={alteraCampo} />
              </Grid>
              <Grid item xs={12} sm={6}>{/* Pessoa */}
                <AutoCompleteStyled
                  disabled={!permitido}
                  inputProps={{
                    name: 'fisica_juridica'
                  }}

                  defaultValue="Jurídica"
                  value={datasetEmpresas.fisica_juridica === 'J' ? 'Jurídica' : 'Física'}
                  options={fisica_juridica}
                  renderInput={(params) => (
                    <FormField {...params} label="Pessoa" InputLabelProps={{ shrink: true }} />
                  )}
                  onChange={(event, newValue) => {
                    alteraFisicaJuridica(newValue)
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>{/* Cnpj */}
                <FormField label="Cnpj" disabled={true} placeholder="" name='cnpj' value={datasetEmpresas.cnpj} onChange={alteraCampo} />
              </Grid>
              <Grid item xs={12} sm={6}>{/* Insc.Estadual */}
                <FormField label="Insc.Estadual" disabled={!permitido} placeholder="" name='inscricao_estadual' value={datasetEmpresas.inscricao_estadual} onChange={alteraCampo} />
              </Grid>
              <Grid item xs={12} sm={6}>{/* Insc.Municipal */}
                <FormField label="Insc.Municipal" disabled={!permitido} placeholder="" name='inscricao_municipal' value={datasetEmpresas.inscricao_municipal} onChange={alteraCampo} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4}>{/* Img */}
            <ImagemLogoEmpresa
              imagePreviewUrl={imagePreviewUrl}
              setImagePreviewUrl={setImagePreviewUrl}
            />
          </Grid>
          <Grid item xs={12} sm={6}>{/* Rua */}
            <FormField
              label="Rua"
              placeholder=""
              name='endereco_rua' disabled={!permitido} value={datasetEmpresas.endereco_rua} onChange={alteraCampo}
            />
          </Grid>
          <Grid item xs={12} sm={2}>{/* Nº */}
            <FormField
              label="Nº"
              placeholder=""
              inputProps={{ type: "number" }}
              name='endereco_numero' disabled={!permitido} value={datasetEmpresas.endereco_numero} onChange={alteraCampo}
            />
          </Grid>
          <Grid item xs={12} sm={4}>{/* Bairro */}
            <FormField
              label="Bairro"
              placeholder=""
              name='endereco_bairro' disabled={!permitido} value={datasetEmpresas.endereco_bairro} onChange={alteraCampo}
            />
          </Grid>
          <Grid item xs={12} sm={6}>{/* Cidade */}
            <FormField label="Cidade" placeholder=""
              name='endereco_cidade' disabled={!permitido} value={datasetEmpresas.endereco_cidade} onChange={alteraCampo}
            />
          </Grid>
          <Grid item xs={12} sm={4}>{/* Estado */}
            <FormField
              label="Estado"
              placeholder=""
              name='endereco_estado' disabled={!permitido} value={datasetEmpresas.endereco_estado} onChange={alteraCampo}
            />
          </Grid>
          <Grid item xs={12} sm={2}>{/* CEP */}
            <FormField
              label="CEP"
              placeholder=""
              name='endereco_cep' disabled={!permitido} value={datasetEmpresas.endereco_cep} onChange={alteraCampo}
            />
          </Grid>

          <Grid item xs={12} md={10}>
          </Grid>

          <Grid item xs={12} md={2} >
            <MDButton color='info' disabled={!permitido} onClick={gravarEmpresa}>Gravar</MDButton>
          </Grid>

        </Grid>
      </MDBox>
    </Card>
  );
}

export default CadastroEmpresa;
