import React, { useContext, useEffect, useState } from "react";

// @mui material components
import { Collapse, Grid, Icon, Tooltip } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//My componentes e functions
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import FormField from "componentes/FormField";
import { Box } from "@mui/system";
import { retornaSomenteNumeros } from "commons/utils";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";
import TabelaPadrao from "componentes/TabelaPadrao";
import { formataValor } from "commons/utils";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { formatDate } from "commons/utils";


function Tabela(props) {

  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const { handleClickExcluir, abrirRegistro, recarregaTabela, openFiltrosAvancados, handleClickMovimentacao } = props;

  const [countAll, setCountAll] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('ordem');
  const [colunasTable, setColunasTable] = React.useState([]);
  const [linhas, setLinhas] = React.useState([]);


  // *****States relacionado do filtro de pesquisa ****
  const [campoPesquisa, setCampoPesquisa] = React.useState({ id: 'titulo', label: 'Titulo', buscaParcial: true });
  const [valorPesquisa, setValorPesquisa] = React.useState('');
  const [camposFiltro, setCamposFiltro] = useState([
    { id: 'titulo', label: 'Titulo', buscaParcial: true },
    { id: 'id', label: 'Id' },
  ]);
  const [opcoesFiltroTipo, setOpcoesFiltroTipo] = useState([
    { value: '', label: 'Todos' },
    { value: 'PAG', label: 'Pagamento' },
    { value: 'CLA', label: 'Cláusulas' },
    { value: 'OBS', label: 'Observação' },
  ]);
  const [filtroTipo, setFiltroTipo] = useState();
  // ****************************

  const baseUrl = `${rotafechada()}relatorio_clausulas`;
  const baseCaption = 'Configurador do Relatório de Contrato';


  const buscaDados = async () => {
    if (props.validaRotaPermissao) {
      if (await props.validaRotaPermissao('listar') === false) {
        return;
      }
    }

    try {

      let complementoUrl = '';

      if ((campoPesquisa.id) && (valorPesquisa)) {
        complementoUrl = `&${campoPesquisa.id}=${valorPesquisa}`;

        if (campoPesquisa.id === 'id') {
          const temp = retornaSomenteNumeros(valorPesquisa);
          if ((`${temp}`.length !== `${valorPesquisa}`.length) ||
            (!(parseInt(`${valorPesquisa}`, 10) > 0))) {
            MensagemErro('A pesquisa por ID aceita só números, escolha outro campo para busca');
            return;
          }
        }

        if ((valorPesquisa) && (valorPesquisa.length > 0) && (valorPesquisa[0] !== '%') && (valorPesquisa[valorPesquisa.length - 1] !== '%')
          && (camposFiltro) && (camposFiltro.length > 0)) {

          //se o texto a ser buscado nao terminar ou começar com %
          //e tiver uma configuracao no camposFiltro
          //entao localizo que o campo tipo nesse momento é um campo que esteja configurado
          //o buscaParcial, se sim acrescento automaticamente o % no inicio e fim


          let encontrouCampo = false;
          for (let i = 0; i < camposFiltro.length; i++) {

            if ((camposFiltro[i].buscaParcial === true) && (camposFiltro[i].id === campoPesquisa.id)) {
              encontrouCampo = true;
            }
          }

          if (encontrouCampo === true) {
            complementoUrl = `&${campoPesquisa.id}=%${valorPesquisa}%`;
          }
        }
      }

      if (page > 0) {
        complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
      }

      if (orderBy) {
        complementoUrl = `${complementoUrl}&order=${orderBy}`;
        if (order) {
          complementoUrl = `${complementoUrl}&direction=${order}`;
        }
      }

      if (filtroTipo) {
        complementoUrl = `${complementoUrl}&tipo=${filtroTipo.value}`;
      }

      //complementoUrl = encodeURI(complementoUrl)

      MostraTelaEspera(`Buscando ${baseCaption}`);

      const url = `${baseUrl}?limit=${rowsPerPage}${complementoUrl}`;
      const retorno = await api.get(encodeURI(url));
      const { data, countAll: countAllretorno } = retorno.data;
      const dadostemp = [];

      //console.log("Retorno Banco",data)

      const colunasTabletemp = [
        // {
        //   id: 'id',
        //   numeric: false,
        //   disablePadding: true,
        //   label: 'Id'
        // },
        {
          id: 'tipo',
          numeric: false,
          disablePadding: true,
          label: 'Tipo',
          disableOrder: true
        },
        {
          id: 'ordem',
          numeric: false,
          disablePadding: true,
          label: 'Ordem'
        },
        {
          id: 'titulo',
          numeric: false,
          disablePadding: true,
          label: 'Titulo'
        },
        {
          id: 'texto',
          numeric: false,
          disablePadding: true,
          label: 'Texto'
        },
        {
          id: 'acoes',
          numeric: false,
          disablePadding: true,
          label: 'Ações',
          disableOrder: true
        },
      ]
      //console.log('data bancos e caixas',data)
      //mapeando os dados para a "rows"
      data.map((item, index) => {
        dadostemp.push({
          id: item.id,
          titulo: item.titulo,
          texto: item.texto,
          ordem: item.ordem,
          tipo: item.tipo,
          acoes: <>
            <MDButton size="medium" onClick={() => abrirRegistro(item.id)} iconOnly >
              <Icon fontSize="medium" color="success">edit_icon</Icon>
            </MDButton>
            <MDButton size="medium" onClick={() => handleClickExcluir(item.id)} iconOnly >
              <Icon fontSize="medium" color="error">delete_forever</Icon>
            </MDButton>
          </>
        })
      })

      setColunasTable([...colunasTabletemp]);
      setLinhas(dadostemp);
      setCountAll(countAllretorno);
      FechaTelaEspera();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }


  useEffect(() => {
    buscaDados();
  }, [recarregaTabela, page, rowsPerPage, order, orderBy])

  const handleClickPesquisar = () => {
    setPage(0);
    buscaDados();
  }

  const desenhaFiltro = () => {
    return (
      <Collapse in={openFiltrosAvancados} timeout="auto" unmountOnExit>
        <Box sx={{ margin: 1 }}>
          < Grid container spacing={2} justifyContent="center" >
            <Grid item xs={12} sm={3} >{/*Filtro Tipo*/}
              <AutoCompleteStyled
                options={opcoesFiltroTipo}
                disableClearable //Retira o X (clear)
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <FormField {...params} label='Tipo' />}
                value={filtroTipo}
                onChange={(event, newValue) => {
                  setFiltroTipo(newValue);
                }}
                defaultValue={{ value: '', label: 'Todos' }}
              />
            </Grid>
            <Grid item xs={12} sm={9} >{/*Espaço em branco*/}
            </Grid>
            <Grid item xs={12} sm={3}   >{/*Filtro Campo*/}
              <AutoCompleteStyled
                disableClearable //Retira o X (clear)
                options={camposFiltro}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={(params) => <FormField {...params} label='Campo' />}
                value={campoPesquisa}
                onChange={(event, newValue) => {
                  setCampoPesquisa(newValue);
                }}
                defaultValue={null}
              />
            </Grid>
            <Grid item xs={12} sm={7}   >{/*Valor a ser Pesquisado*/}
              <FormField type="text" label="Valor a ser Pesquisado" value={valorPesquisa} onChange={(e) => setValorPesquisa(e.target.value)}

                inputProps={
                  {
                    onKeyPress: (e) => {

                      if (e.key === 'Enter') {
                        handleClickPesquisar();
                      }
                    },
                  }
                }
              />
            </Grid>
            <Grid item xs={12} sm={2} >{/*Botão do Filtro*/}
              <MDButton onClick={handleClickPesquisar} color='info'> Pesquisar</MDButton>
            </Grid>
          </Grid >
        </Box>
      </Collapse>
    )
  }

  return (
    <>

      <MDBox p={3} lineHeight={1}>
        <MDTypography variant="h5" fontWeight="medium">
          {desenhaFiltro()}
        </MDTypography>
      </MDBox>

      <TabelaPadrao
        colunas={colunasTable}
        linhas={linhas}
        countAll={countAll}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
    </>


  );
}

export default Tabela;