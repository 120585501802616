import React, { useContext, useEffect, useState } from "react";

// @mui material components
import { Autocomplete, Grid, Icon } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//My componentes e functions
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import TabelaPadrao from 'componentes/TabelaPadrao';
import MDInput from "components/MDInput";
import FormField from "componentes/FormField";
import { retornaSomenteNumeros } from "commons/utils";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";


function Tabela(props) {

  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const { handleClickExcluir, abrirRegistro, recarregaTabela } = props;

  const [countAll, setCountAll] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [colunasTable, setColunasTable] = React.useState([]);
  const [linhas, setLinhas] = React.useState([]);
  const [campoPesquisa, setCampoPesquisa] = React.useState({ id: 'nome', label: 'Nome', buscaParcial: true });
  const [valorPesquisa, setValorPesquisa] = React.useState('');
  const [camposFiltro, setCamposFiltro] = useState([
    { id: 'nome', label: 'Nome', buscaParcial: true },
    { id: 'ativo', label: 'Ativo' },
    { id: 'referencia', label: 'Referência', buscaParcial: true },
    { id: 'id', label: 'Id' },
  ]);

  const baseUrl = `${rotafechada()}cores`;
  const baseCaption = 'Cores';

  const buscaDados = async () => {

    if (props.validaRotaPermissao) {
      if (await props.validaRotaPermissao('listar') === false) {
        return;
      }
    }

    try {

      let complementoUrl = '';

      if ((campoPesquisa.id) && (valorPesquisa)) {
        complementoUrl = `&${campoPesquisa.id}=${valorPesquisa}`;

        if (campoPesquisa.id === 'id') {
          const temp = retornaSomenteNumeros(valorPesquisa);
          if ((`${temp}`.length !== `${valorPesquisa}`.length) ||
            (!(parseInt(`${valorPesquisa}`, 10) > 0))) {
            MensagemErro('A pesquisa por ID aceita só números, escolha outro campo para busca');
            return;
          }
        }

        if ((valorPesquisa) && (valorPesquisa.length > 0) && (valorPesquisa[0] !== '%') && (valorPesquisa[valorPesquisa.length - 1] !== '%')
          && (camposFiltro) && (camposFiltro.length > 0)) {

          //se o texto a ser buscado nao terminar ou começar com %
          //e tiver uma configuracao no camposFiltro
          //entao localizo que o campo usado nesse momento é um campo que esteja configurado
          //o buscaParcial, se sim acrescento automaticamente o % no inicio e fim


          let encontrouCampo = false;
          for (let i = 0; i < camposFiltro.length; i++) {

            if ((camposFiltro[i].buscaParcial === true) && (camposFiltro[i].id === campoPesquisa.id)) {
              encontrouCampo = true;
            }
          }

          if (encontrouCampo === true) {
            complementoUrl = `&${campoPesquisa.id}=%${valorPesquisa}%`;
          }
        }
      }

      if (page > 0) {
        complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
      }

      if (orderBy) {
        complementoUrl = `${complementoUrl}&order=${orderBy}`;
        if (order) {
          complementoUrl = `${complementoUrl}&direction=${order}`;
        }
      }

      MostraTelaEspera(`Buscando ${baseCaption}`);

      const url = `${baseUrl}?limit=${rowsPerPage}${complementoUrl}`;
      const retorno = await api.get(encodeURI(url));

      const { data, countAll: countAllretorno } = retorno.data;

      const dadostemp = [];

      const colunasTabletemp = [
        {
          id: 'id',
          numeric: false,
          disablePadding: true,
          label: 'Id'
        },
        {
          id: 'nome',
          numeric: false,
          disablePadding: true,
          label: 'Nome'
        },
        {
          id: 'referencia',
          numeric: false,
          disablePadding: true,
          label: 'Ref.'
        },
        {
          id: 'ativo',
          numeric: false,
          disablePadding: true,
          label: 'Ativo'
        },
        {
          id: 'id_externo',
          numeric: false,
          disablePadding: true,
          label: 'ID EXT.'
        },
        {
          id: 'acoes',
          numeric: false,
          disablePadding: true,
          label: 'Ações',
          disableOrder: true
        },
      ]

      //mapeando os dados para a "rows"
      data.map((item, index) => {
        dadostemp.push({
          id: item.id,
          nome: item.nome,
          referencia: item.referencia,
          ativo: item.ativo,
          id_externo: item.id_externo,
          acoes: <><MDButton size="medium" onClick={() => abrirRegistro(item.id)} iconOnly >
            <Icon fontSize="medium" color="success">edit_icon</Icon>
          </MDButton>
            <MDButton size="medium" onClick={() => handleClickExcluir(item.id)} iconOnly >
              <Icon fontSize="medium" color="error">delete_forever</Icon>
            </MDButton>
          </>
        })
      })

      setColunasTable([...colunasTabletemp]);
      setLinhas([...dadostemp]);
      setCountAll(countAllretorno);
      FechaTelaEspera();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  useEffect(() => {
    buscaDados();
  }, [recarregaTabela, page, rowsPerPage, order, orderBy])


  const handleClickPesquisar = () => {
    setPage(0);
    buscaDados();
  }

  const desenhaFiltro = () => {
    //só desenha a linha do filtro de veio campos de filtro na props
    if ((camposFiltro) && (camposFiltro.length > 0)) {
      return (
        //Grid do Filtro
        < Grid container spacing={2} justifyContent="center" >
          <Grid item xs={12} sm={3}  >             {/* Combo com os campos que podem ser pesquisados */}
            <AutoCompleteStyled
              options={camposFiltro}
              //getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => <MDInput {...params} label="Campo" />}
              value={campoPesquisa}
              onChange={(event, newValue) => {
                setCampoPesquisa(newValue);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={7} > {/*Valor a ser Pesquisado*/}
            <FormField type="text" label="Valor a ser Pesquisado" value={valorPesquisa} onChange={(e) => setValorPesquisa(e.target.value)}

              inputProps={
                {
                  onKeyPress: (e) => {

                    if (e.key === 'Enter') {
                      handleClickPesquisar();
                    }
                  },
                }
              }
            />
          </Grid>
          <Grid item xs={12} sm={2} >{/*Botão do Filtro*/}
            <MDButton onClick={handleClickPesquisar} color='info'> Pesquisar</MDButton>
          </Grid>
        </Grid >
      )
    }
    else return false;
  }


  return (
    <>

      <MDBox p={3} lineHeight={1}>
        <MDTypography variant="h5" fontWeight="medium">
          {desenhaFiltro()}
        </MDTypography>

      </MDBox>

      <TabelaPadrao
        colunas={colunasTable}
        linhas={linhas}
        countAll={countAll}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
    </>


  );
}

export default Tabela;
