import React, { useContext, useEffect, useState, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, Box, Button, Checkbox, Dialog, DialogContent, DialogTitle, DialogActions, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import MDTypography from 'components/MDTypography';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import MDButton from 'components/MDButton';
import AuthContext from 'hooks/AuthContext';
import TabelaComponentes from './tabela';
import DialogoConfirma from 'componentes/DialogoConfirma';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import CalculateIcon from '@mui/icons-material/Calculate';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import BackspaceIcon from '@mui/icons-material/Backspace';
import DialogoOpcoes from 'componentes/DialogoOpcoes';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-ambiance';


const ComponentesProjeto = (props) => {

  const { handleSubmit, control, setValue, getValues, projetoAtual } = props;

  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const opcaoDefault = [{ id: -1, value: -1, label: '<Selecione...>' }];
  const [opcoesComponente, setOpcoesComponente] = useState(opcaoDefault);
  const [opcoesTipoProduto, setOpcoesTipoProduto] = useState(opcaoDefault);
  const [recarregaTabela, setRecarregaTabela] = useState(false);
  const [codigoAtual, setCodigoAtual] = useState('');
  const [openAssistenteFormula, setAssistenteFormula] = useState({ visible: false, campo: '', label: '', });
  const [valorFormula, setValorFormula] = useState('');
  const inputRef = useRef(null);
  const [medidasFormula, setMedidasFormula] = useState({ value: -1, label: '<Escolha uma Medida>' })
  const [componentesFormula, setComponentesFormula] = useState({ value: -1, label: '<Escolha um Componente>' })
  const [optionMedidasFormula, setOptionMedidasFormula] = useState([
    { value: -1, label: "<Escolha uma Medida>" },
  ]);

  const [optionComponentesFormula, setOptionComponentesFormula] = useState([
    { value: -1, label: "<Escolha um Componente>" },
  ]);

  const [opcoes, setOpcoes] = useState({
    open: false,
    items: [{}]
  });

  const [opcoesMedidas, setOpcoesMedidas] = useState({
    open: false,
    items: [{}]
  });

  const [opcoesAxL, setOpcoesAxL] = useState({
    open: false,
    items: [{}]
  });

  const objModeloProjetoComponentes = {
    id: { type: 'text' },
    componente_id: { type: 'autocomplete' },
    tipo_produto_id_destino: { type: 'autocomplete' },
    formula_altura: { type: 'text' },
    formula_largura: { type: 'text' },
    formula_medida_final: { type: 'text' },
    formula_altura_cobranca: { type: 'text' },
    formula_largura_cobranca: { type: 'text' },
    formula_medida_final_cobranca: { type: 'text' },
    formula_quantidadeinsercao: { type: 'text' },
    folga_altura: { type: 'text' },
    folga_largura: { type: 'text' },
    opcional: { type: 'checkbox' },
    individual: { type: 'checkbox' },
    observacao: { type: 'text' },
  }

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${rotafechada()}projeto_componentes/permissao/${operacao}`);
      return true;
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;

    if (data.formula_quantidadeinsercao === '') {
      data.formula_quantidadeinsercao = '1'
    }
    if (data.folga_altura === '') {
      data.folga_altura = '0'
    }
    if (data.folga_largura === '') {
      data.folga_largura = '0'
    }

    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const gravarComponente = async (data) => {

    const dados = {};
    //no data esta todos os dados do RHF registrados, o que me interesse é 
    //apenas o objeto "componentes"
    //assim resgato no data apenas os campos que se referem a componentes
    //e monto o objeto que sera enviado para o back
    Object.keys(objModeloProjetoComponentes).forEach((nome) => {
      dados[nome] = '';
      if (nome in data['componentes']) {
        if (typeof data['componentes'][nome] === 'object') {
          dados[nome] = data['componentes'][nome]?.value ? data['componentes'][nome]?.value : '';
        }
        else
          if (typeof data['componentes'][nome] === 'boolean') {
            dados[nome] = data['componentes'][nome] === true ? 'S' : 'N'
          }
          else dados[nome] = data['componentes'][nome];
      }
    })

    dados.empresa_id = sessaolocal.empresa_id || null;
    dados.projeto_id = projetoAtual;
    delete dados.id;

    try {
      validaCampos(dados);
    }
    catch (error) {
      MensagemErro(`${error.message}`);
      return;
    }

    try {
      let retorno;
      let codigoTemp = data.componentes.id;

      if ((data.componentes.id) && (parseInt(data.componentes.id, 10) > 0)) {
        retorno = await api.put(`${rotafechada()}projeto_componentes/${data.componentes.id}`, dados);
      }
      else {
        retorno = await api.post(`${rotafechada()}projeto_componentes`, dados);
        codigoTemp = retorno.data.data.id;
      }

      MensagemAviso(`Gravado com sucesso! Componente: ${codigoTemp}`);
      setCodigoAtual('');
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaOpcoesComponentes = async () => {

    try {
      let url = `${rotafechada()}componentes_projetos?limit=-1`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      let opcoesComponenteTemp = [];
      opcoesComponenteTemp.push({ ...opcaoDefault[0] });
      data.map((item) => {
        opcoesComponenteTemp.push({ id: item.id, value: item.id, label: `${item.referencia}-${item.nome}` })
      })
      setOpcoesComponente(opcoesComponenteTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaOpcoesTipoProduto = async () => {

    try {
      let url = `${rotafechada()}tipos_produtos?limit=-1`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      let opcoesTipoProdutoTemp = [];
      opcoesTipoProdutoTemp.push({ ...opcaoDefault[0] });
      data.map((item) => {
        opcoesTipoProdutoTemp.push({ id: item.id, value: item.id, label: item.nome })
      })
      setOpcoesTipoProduto(opcoesTipoProdutoTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaOpcoes = async () => {
    await buscaOpcoesComponentes();
    await buscaOpcoesTipoProduto();
  };

  const novoComponente = () => {
    //limpo os campos
    Object.keys(objModeloProjetoComponentes).forEach((nome) => {
      if (objModeloProjetoComponentes[nome].type === 'autocomplete') {
        setValue(`componentes.${nome}`, opcaoDefault[0])
      }
      else
        if (objModeloProjetoComponentes[nome].type === 'checkbox') {
          setValue(`componentes.${nome}`, false)
        }
        else
          if (objModeloProjetoComponentes[nome].type === 'text') {
            setValue(`componentes.${nome}`, '')
          }
    })
  }

  const handleClickCopiaFormulas = () => {
    setValue('componentes.formula_altura_cobranca', getValues('componentes.formula_altura'));
    setValue('componentes.formula_largura_cobranca', getValues('componentes.formula_largura'));
    setValue('componentes.formula_medida_final_cobranca', getValues('componentes.formula_medida_final'));
  }

  const abrirRegistro = async (codigo) => {
    try {
      let url = `${rotafechada()}projeto_componentes?id=${codigo}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      if (!data.length > 0) {
        MensagemErro(`Componente ID ${codigo} não localizado`);
        return;
      }
      setValue('componentes.id', data[0].id);
      setValue('componentes.formula_altura', data[0].formula_altura || '');
      setValue('componentes.formula_largura', data[0].formula_largura || '');
      setValue('componentes.formula_medida_final', data[0].formula_medida_final || '');
      setValue('componentes.formula_altura_cobranca', data[0].formula_altura_cobranca || '');
      setValue('componentes.formula_largura_cobranca', data[0].formula_largura_cobranca || '');
      setValue('componentes.formula_medida_final_cobranca', data[0].formula_medida_final_cobranca || '');
      setValue('componentes.formula_quantidadeinsercao', data[0].formula_quantidadeinsercao);
      setValue('componentes.folga_altura', data[0].folga_altura);
      setValue('componentes.folga_largura', data[0].folga_largura);
      setValue(`componentes.componente_id`, { value: data[0].componente_id, label: `${data[0].Componentes_Projeto.referencia}-${data[0].Componentes_Projeto.nome}` });
      setValue(`componentes.tipo_produto_id_destino`, { value: data[0].tipo_produto_id_destino, label: data[0].Tipos_Produto.nome });
      setValue('componentes.opcional', data[0].opcional === 'S' ? true : false);
      setValue('componentes.individual', data[0].individual === 'S' ? true : false);
      setValue('componentes.observacao', data[0].observacao || '');
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const excluirRegistro = async () => {

    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      await api.delete(`${rotafechada()}projeto_componentes/${dialogoOpen.id || 0}`);
      MensagemAviso('Excluído com sucesso!');
      novoComponente();
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickExcluir = async (id) => {
    if (!(await validaRotaPermissao('excluir') === true)) {
      return;
    }
    setDialogoOpen({ ...dialogoOpen, visible: true, id });
  }

  const inserirCaractere = (caractere) => {
    const posicaoCursor = inputRef.current.selectionStart;
    const novoValor = valorFormula.slice(0, posicaoCursor) + caractere + valorFormula.slice(posicaoCursor);
    setValorFormula(novoValor);
  };

  const envolverComParenteses = (complemento_ini, complemento_fim) => {
    // const inicioSelecao = inputRef.current.selectionStart;
    // const fimSelecao = inputRef.current.selectionEnd;
    // const novoValor = valorFormula.slice(0, inicioSelecao) + complemento_ini + '(' + valorFormula.slice(inicioSelecao, fimSelecao) + ')' + valorFormula.slice(fimSelecao) + complemento_fim;
    const novoValor = complemento_ini + '(' + valorFormula + ')' + complemento_fim;

    setValorFormula(novoValor);
  };

  const buscaMedidasFormula = async () => {
    try {

      MostraTelaEspera(`Buscando`);

      const retorno = await api.get(`${rotafechada()}projeto_medidas?limit=-1&projeto_id=${projetoAtual}`);
      const { data } = retorno.data;

      let opcoesMedidasFormulaTemp = [{ value: -1, label: '<Escolha uma Medida>' }];

      data.map((item) => {
        opcoesMedidasFormulaTemp.push({ value: item.Medidas_Projeto.referencia, label: item.Medidas_Projeto.nome });
      });
      setOptionMedidasFormula(opcoesMedidasFormulaTemp);
      FechaTelaEspera();

      return opcoesMedidasFormulaTemp;


    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaComponentesFormula = async () => {
    try {

      MostraTelaEspera(`Buscando`);

      const retorno = await api.get(`${rotafechada()}projeto_componentes?limit=-1&projeto_id=${projetoAtual}`);
      const { data } = retorno.data;
      console.log("data componentes", data);
      let opcoesComponentesFormulaTemp = [{ value: -1, label: '<Escolha um Componente>' }];

      data.map((item) => {
        opcoesComponentesFormulaTemp.push({ value: item.Componentes_Projeto.referencia, label: item.Componentes_Projeto.nome });
      });
      setOptionComponentesFormula(opcoesComponentesFormulaTemp);
      FechaTelaEspera();

      return opcoesComponentesFormulaTemp;


    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickOpcoesFormula = (value) => {

    const items = [
      { value: 0, label: 'Apenas insere' },
      { value: 1, label: 'Arredonda' },
      { value: 2, label: 'convertida MM² para M²' },
      { value: 3, label: 'Converte MM para M Linear (Perimetro)' },
    ];
    setOpcoes({ ...opcoes, open: true, tipo: 'opcoes', items, campo: value });
  }

  const onCloseDialogoOpcoes = async (value) => {

    //fechando o dialogo
    setOpcoes({ ...opcoes, open: false, tipo: '' });
    switch (parseInt(value, 10)) {
      case 0:
        handleClickOpcoesAxL(opcoes.campo, 'insere')
        //inserirCaractere('@L[' + opcoes.campo + ']');
        break;
      case 1:
        handleClickOpcoesAxL(opcoes.campo, 'arredonda')
        //inserirCaractere('(' + opcoes.campo + ')/1000000');
        break;
      case 2:
        inserirCaractere(`((@L[${opcoes.campo}]*2)+(@A[${opcoes.campo}]*2))/1000000`);
        //inserirCaractere('(' + opcoes.campo + ')1000');
        break;
      case 3:
        inserirCaractere(`((@L[${opcoes.campo}]*2)+(@A[${opcoes.campo}]*2))/1000`);
        break;

      default:
        break;
    }

  }

  const handleClickOpcoesAxL = (value, tipo) => {

    const items = [
      { value: 0, label: 'Altura' },
      { value: 1, label: 'Largura' },
    ];
    setOpcoesAxL({ ...opcoesAxL, open: true, tipo: 'opcoes', items, campo: value, tipo: tipo });
  }

  const onCloseDialogoOpcoesAxL = async (value, tipo) => {

    //fechando o dialogo
    setOpcoesAxL({ ...opcoesAxL, open: false, tipo: '' });
    switch (parseInt(value, 10)) {
      case 0:
        if (opcoesAxL.tipo === 'insere')
          inserirCaractere('@A[' + opcoesAxL.campo + ']');
        if (opcoesAxL.tipo === 'arredonda')
          inserirCaractere('@ARREDONDA50MM(@A[' + opcoesAxL.campo + '])');
        break;
      case 1:
        if (opcoesAxL.tipo === 'insere')
          inserirCaractere('@L[' + opcoesAxL.campo + ']');
        if (opcoesAxL.tipo === 'arredonda')
          inserirCaractere('@ARREDONDA50MM(@L[' + opcoesAxL.campo + '])');
        break;
      default:
        break;
    }

  }

  const handleClickOpcoesFormulaMedidas = (value) => {

    const items = [
      { value: 3, label: 'Apenas insere' },
      { value: 0, label: 'Arredonda' },
      { value: 1, label: 'Converte MM para M' },
      //{ value: 2, label: 'Converte MM para M Linear' },
    ];
    setOpcoesMedidas({ ...opcoesMedidas, open: true, tipo: 'opcoes', items, campo: value });
  }

  const onCloseDialogoOpcoesMedidas = async (value) => {

    //fechando o dialogo
    setOpcoesMedidas({ ...opcoesMedidas, open: false, tipo: '' });
    switch (parseInt(value, 10)) {
      case 0:
        inserirCaractere('ARREDONDA50MM(' + opcoesMedidas.campo + ')');
        break;
      case 1:
        inserirCaractere('(' + opcoesMedidas.campo + ')/1000');
        break;
      case 3:
        inserirCaractere(opcoesMedidas.campo);
        break;

      default:
        break;
    }

  }

  const validateBrackets = (value) => {
    if (typeof value !== 'string') {
      return true;
    }
    const openBrackets = (value.match(/\(/g) || []).length;
    const closeBrackets = (value.match(/\)/g) || []).length;
    return openBrackets === closeBrackets || 'Por favor, feche todos os parênteses abertos.';
  };

  useEffect(() => {
    buscaOpcoes();
    if (parseInt(codigoAtual, 10) > 0) {
      abrirRegistro(codigoAtual);
    }
    else novoComponente();
  }, [projetoAtual, codigoAtual, recarregaTabela])

  useEffect(() => {
    if (openAssistenteFormula.visible == true) {
      buscaMedidasFormula()
      buscaComponentesFormula()
    }
  }, [openAssistenteFormula])

  return (
    <>

      <DialogoOpcoes
        value={''}
        open={opcoes.open || false}
        onClose={onCloseDialogoOpcoes}
        options={opcoes.items || []}
      />

      <DialogoOpcoes
        value={''}
        open={opcoesMedidas.open || false}
        onClose={onCloseDialogoOpcoesMedidas}
        options={opcoesMedidas.items || []}
      />

      <DialogoOpcoes
        value={''}
        open={opcoesAxL.open || false}
        onClose={onCloseDialogoOpcoesAxL}
        options={opcoesAxL.items || []}
      />

      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={openAssistenteFormula.visible}
        onClose={() => setAssistenteFormula({ ...openAssistenteFormula, visible: false, campo: '', label: '' })}
      //disableEscapeKeyDown
      >
        <DialogTitle>
          <Box display='flex' justifyContent="space-between">
            <Typography variant='h6'>Assistente de Fórmula</Typography>
            <MDButton onClick={() => setAssistenteFormula({ ...openAssistenteFormula, visible: false, campo: '', label: '' })}>
              <CloseIcon fontSize="large" color='info' />
            </MDButton>

          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={8}> {/*BOX lado esquerdo */}
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <Typography variant='h7'>Aplicar em toda a Fórmula</Typography>
                </Grid>
                <Grid item xs={12} sm={7}> {/*ARREDONDA */}
                  <MDButton
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() => envolverComParenteses('@ARREDONDA50MM', '')}
                    //iconOnly
                    sx={{ color: "#395a7a", backgroundColor: "#f1f4f9", borderColor: "#c9d3dd", borderRadius: '3px', marginRight: '10px' }}
                  >
                    Arredonda
                  </MDButton>
                </Grid>
                <Grid item xs={12} sm={3}> {/*ESPAÇO EM BRANCO */}
                </Grid>
                <Grid item xs={12} sm={7}> {/*CONVERTE M2 DIVIDE POR 1000*/}
                  <MDButton
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() => envolverComParenteses('', '/1000000')}
                    //iconOnly
                    sx={{ color: "#395a7a", backgroundColor: "#f1f4f9", borderColor: "#c9d3dd", borderRadius: '3px', marginRight: '10px' }}
                  >
                    Converte MM² para M2
                  </MDButton>
                </Grid>
                <Grid item xs={12} sm={3}> {/*ESPAÇO EM BRANCO */}
                </Grid>
                <Grid item xs={12} sm={7}> {/*MM para M linear*/}
                  <MDButton
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() => envolverComParenteses('', '/1000')}
                    //iconOnly
                    sx={{ color: "#395a7a", backgroundColor: "#f1f4f9", borderColor: "#c9d3dd", borderRadius: '3px', marginRight: '10px' }}
                  >
                    Converte para MM para M linear
                  </MDButton>
                </Grid>
                <Grid item xs={12} sm={12}> {/*ESPAÇO EM BRANCO */}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant='h7'>Operadores</Typography>
                </Grid>
                <Grid item xs={12} sm={0.7}> {/*SOMA*/}
                  <MDButton
                    size="medium"
                    variant="outlined"
                    color="secondary"
                    onClick={() => inserirCaractere('+')}
                    iconOnly
                    sx={{ color: "#395a7a", backgroundColor: "#f1f4f9", borderColor: "#c9d3dd", borderRadius: '3px', marginRight: '10px' }}
                  >
                    <IconButton sx={{ fontWeight: "bold" }} >{'+'}</IconButton>
                  </MDButton>
                </Grid>

                <Grid item xs={12} sm={0.7}> {/*SUBTRAÇÃO*/}
                  <MDButton
                    size="medium"
                    variant="outlined"
                    color="secondary"
                    onClick={() => inserirCaractere('-')}
                    iconOnly
                    sx={{ color: "#395a7a", backgroundColor: "#f1f4f9", borderColor: "#c9d3dd", borderRadius: '3px', marginRight: '10px' }}
                  >
                    <IconButton sx={{ fontWeight: "bold" }} >{'-'}</IconButton>
                  </MDButton>
                </Grid>

                <Grid item xs={12} sm={0.7}> {/*MULTIPLICAÇÃO*/}
                  <MDButton
                    size="medium"
                    variant="outlined"
                    color="secondary"
                    onClick={() => inserirCaractere('*')}
                    iconOnly
                    sx={{ color: "#395a7a", backgroundColor: "#f1f4f9", borderColor: "#c9d3dd", borderRadius: '3px', marginRight: '10px' }}
                  >
                    <IconButton sx={{ fontWeight: "bold" }} >{'*'}</IconButton>
                  </MDButton>
                </Grid>

                <Grid item xs={12} sm={0.7}> {/*DIVISÃO*/}
                  <MDButton
                    size="medium"
                    variant="outlined"
                    color="secondary"
                    onClick={() => inserirCaractere('/')}
                    iconOnly
                    sx={{ color: "#395a7a", backgroundColor: "#f1f4f9", borderColor: "#c9d3dd", borderRadius: '3px', marginRight: '10px' }}
                  >
                    <IconButton sx={{ fontWeight: "bold" }} >{'/'}</IconButton>
                  </MDButton>
                </Grid>

                <Grid item xs={12} sm={0.7}> {/*ABRE PARÊNTESES*/}
                  <MDButton
                    size="medium"
                    variant="outlined"
                    color="secondary"
                    onClick={() => inserirCaractere('(')}
                    iconOnly
                    sx={{ color: "#395a7a", backgroundColor: "#f1f4f9", borderColor: "#c9d3dd", borderRadius: '3px', marginRight: '10px' }}
                  >
                    <IconButton sx={{ fontWeight: "bold" }} >{'('}</IconButton>
                  </MDButton>
                </Grid>

                <Grid item xs={12} sm={0.7}> {/*FECHA PARÊNTESES*/}
                  <MDButton
                    size="medium"
                    variant="outlined"
                    color="secondary"
                    onClick={() => inserirCaractere(')')}
                    iconOnly
                    sx={{ color: "#395a7a", backgroundColor: "#f1f4f9", borderColor: "#c9d3dd", borderRadius: '3px', marginRight: '10px' }}
                  >
                    <IconButton sx={{ fontWeight: "bold" }} >{')'}</IconButton>
                  </MDButton>
                </Grid>

                <Grid item xs={12} sm={0.7}> {/*BACKSPACE */}
                  <MDButton
                    size="medium"
                    variant="outlined"
                    color="secondary"
                    onClick={() => setValorFormula((valorFormula) => valorFormula.slice(0, -1))}
                    iconOnly
                    sx={{ color: "#395a7a", backgroundColor: "#f1f4f9", borderColor: "#c9d3dd", borderRadius: '3px', marginRight: '10px' }}
                  >
                    <BackspaceIcon />
                  </MDButton>
                </Grid>

                <Grid item xs={12} sm={6}> {/*ESPAÇO EM BRANCO */}
                </Grid>

                <Grid item xs={12} sm={12} mt={1}> {/*FORMULA */}
                  <FormField
                    required={true}
                    disabled
                    type="text"
                    label={openAssistenteFormula.label}
                    value={valorFormula}
                    onChange={(e) => setValorFormula(e.target.value)}
                    InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                    inputRef={inputRef}
                  />
                </Grid>
                <Grid item xs={12} sm={12} mt={1}> {/*FORMULA */}
                  <AceEditor
                    focus
                    mode="javascript"
                    theme="ambiance"
                    fontSize={18}
                    placeholder="Informe sua formula"
                    width="1150px" // Ajuste a largura aqui
                    height="200px" // Ajuste a altura aqu
                    onChange={setValorFormula}
                    editorProps={{ $blockScrolling: false }}
                    setOptions={{
                      showLineNumbers: true,
                      tabSize: 2
                    }}
                    value={valorFormula}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}> {/*BOX lado direito */}
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} mt={2}>{/*Medidas Formula*/}
                  <AutoCompleteStyled
                    disableClearable //Retira o X (clear)
                    options={optionMedidasFormula}
                    getOptionLabel={(option) => option.label || ""}
                    renderInput={(params) => <FormField {...params} label="Medidas:" required />}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    value={medidasFormula}
                    onChange={(event, newValue) => {
                      //setMedidasFormula(newValue);
                      handleClickOpcoesFormulaMedidas(newValue.value)
                      //inserirCaractere(newValue.value);
                      //setMedidasFormula({ value: -1, label: '<Escolha uma Medida>' });

                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} mt={2}>{/*Componente Formula*/}
                  <AutoCompleteStyled
                    disableClearable //Retira o X (clear)
                    options={optionComponentesFormula}
                    getOptionLabel={(option) => option.label || ""}
                    renderInput={(params) => <FormField {...params} label="Componente:" required />}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    value={componentesFormula}
                    onChange={(event, newValue) => {
                      //setComponentesFormula(newValue);
                      handleClickOpcoesFormula(newValue.value)
                      //inserirCaractere(newValue.value);
                      //setComponentesFormula({ value: -1, label: '<Escolha um Componente>' });

                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
            <MDButton onClick={() => setAssistenteFormula({ ...openAssistenteFormula, visible: false, campo: '', label: '' })} variant="outlined" color="dark">
              Cancelar
            </MDButton>
            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
              <MDButton onClick={() => {
                setValue(openAssistenteFormula.campo, valorFormula);
                setAssistenteFormula({ ...openAssistenteFormula, visible: false, campo: '', label: '' })
                //openAssistenteFormula.campo
              }} variant="gradient" color="info" sx={{ height: "100%" }}>
                Aplicar
              </MDButton>
            </MDBox>
          </MDBox>
        </DialogActions>
      </Dialog>

      <MDBox p={2} bgColor='#EAFAF1' mt={2}>


        <DialogoConfirma
          open={dialogoOpen.visible}
          title={'Confirma Exclusão'}
          color={'error'}
          message={'Certeza que deseja excluir?'}
          handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
          handleConfirm={excluirRegistro}
        />

        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpenCollapse(!openCollapse)}
        >
          {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          <Typography variant='h6' mb={2}>Componentes</Typography>
        </IconButton>

        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
          <form onSubmit={handleSubmit(gravarComponente)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={2}>{/*id*/}
                <Controller
                  name={`componentes.id`}
                  control={props.control}
                  defaultValue=''
                  render={({ field: { ref, ...props } }) =>
                    <FormField

                      autoComplete={false}
                      type='text'
                      label='Id'
                      rows={1}
                      multiline={false}
                      required={false}
                      disabled={true}
                      inputProps={{
                        disabled: true
                      }}
                      inputRef={ref}
                      {...props}
                    />}
                />
              </Grid>
              <Grid item xs={12} sm={3}>{/*componente_id*/}
                <Controller
                  name='componentes.componente_id'
                  control={control}
                  defaultValue={{ value: -1 }}
                  render={({ field: { onChange, ...props } }) => (
                    <AutoCompleteStyled
                      options={opcoesComponente}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      renderInput={(params) => <FormField required {...params} label='Componente' />}
                      onChange={(_, data) => {
                        return onChange(data);
                      }}
                      disabled={false}
                      {...props}

                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>{/*tipo_produto_id_destino*/}
                <Controller
                  name='componentes.tipo_produto_id_destino'
                  control={control}
                  defaultValue={{ value: -1 }}
                  render={({ field: { onChange, ...props } }) => (
                    <AutoCompleteStyled
                      options={opcoesTipoProduto}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      renderInput={(params) => <FormField required {...params} label='Tipo Produto (Destino)' />}
                      onChange={(_, data) => {
                        return onChange(data);
                      }}
                      disabled={false}
                      {...props}

                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={2}>{/*opcional*/}
                <MDBox width="100%" display="flex" alignItems='center' flexDirection='column'>
                  <MDTypography fontWeight="bold" variant="caption">
                    {'Opcional'}
                  </MDTypography>

                  <Controller
                    name={`componentes.opcional`}
                    control={control}
                    render={({ field: { value, ...otherprops } }) => (
                      <Checkbox
                        {...otherprops}
                        checked={value ? (value === true ? true : false) : false}
                        onChange={(e) => setValue(`componentes.opcional`, e.target.checked)}
                      />
                    )}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={2}>{/*Lançar Individual*/}
                <MDBox width="100%" display="flex" alignItems='center' flexDirection='column'>
                  <MDTypography fontWeight="bold" variant="caption">
                    {'Lançar Individual'}
                  </MDTypography>

                  <Controller
                    name={`componentes.individual`}
                    control={control}
                    render={({ field: { value, ...otherprops } }) => (
                      <Checkbox
                        {...otherprops}
                        checked={value ? (value === true ? true : false) : false}
                        onChange={(e) => setValue(`componentes.individual`, e.target.checked)}
                      />
                    )}
                  />
                </MDBox>
              </Grid>

              <Grid item xs={12} sm={7.5}>{/*formula_altura*/}
                <Controller
                  name={`componentes.formula_altura`}
                  control={props.control}
                  defaultValue=''
                  rules={{ validate: validateBrackets }}
                  render={({ field, fieldState: { error } }) => (
                    <FormField
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : null}
                      autoComplete={false}
                      type='text'
                      label='Altura: Quantidade ou Fórmula'
                      rows={1}
                      multiline={false}
                      required={true}
                      disabled={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={0.5}> {/*Assistente de formula */}
                <Tooltip title="Click para abrir o Assistente de Fórmula" placement="top">
                  <IconButton
                    onClick={(event) => {
                      //setCurrentTargetBotaoMenu(event.currentTarget);
                      setValorFormula(getValues('componentes.formula_altura'))
                      setAssistenteFormula({ ...openAssistenteFormula, visible: true, campo: 'componentes.formula_altura', label: 'Altura: Quantidade ou Fórmula' })
                    }}
                  >
                    <HelpCenterIcon />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item xs={12} sm={3.5}>{/*folga_altura*/}
                <Controller
                  name={`componentes.folga_altura`}
                  control={props.control}
                  //rules={{ validate: validateBrackets }}
                  render={({ field, fieldState: { error } }) => (
                    <FormField
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : null}
                      autoComplete={false}
                      type='text'
                      label='Folga da Altura'
                      rows={1}
                      multiline={false}
                      required={false}
                      disabled={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={0.5}> {/*Assistente de formula */}
                <Tooltip title="Click para abrir o Assistente de Fórmula" placement="top">
                  <IconButton
                    onClick={(event) => {
                      //setCurrentTargetBotaoMenu(event.currentTarget);
                      setValorFormula(getValues('componentes.folga_altura'))
                      setAssistenteFormula({ ...openAssistenteFormula, visible: true, campo: 'componentes.folga_altura', label: 'Folga da Altura' })
                    }}
                  >
                    <HelpCenterIcon />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item xs={12} sm={7.5}>{/*formula_largura*/}
                <Controller
                  name={`componentes.formula_largura`}
                  control={props.control}
                  defaultValue=''
                  rules={{ validate: validateBrackets }}
                  render={({ field, fieldState: { error } }) => (
                    <FormField
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : null}
                      autoComplete={false}
                      type='text'
                      label='Largura: Quantidade ou Fórmula'
                      rows={1}
                      multiline={false}
                      required={true}
                      disabled={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={0.5}> {/*Assistente de formula */}
                <Tooltip title="Click para abrir o Assistente de Fórmula" placement="top">
                  <IconButton
                    onClick={(event) => {
                      //setCurrentTargetBotaoMenu(event.currentTarget);
                      setValorFormula(getValues('componentes.formula_largura'))
                      setAssistenteFormula({ ...openAssistenteFormula, visible: true, campo: 'componentes.formula_largura', label: 'Largura: Quantidade ou Fórmula' })
                    }}
                  >
                    <HelpCenterIcon />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item xs={12} sm={3.5}>{/*folga_largura*/}
                <Controller
                  name={`componentes.folga_largura`}
                  control={props.control}
                  defaultValue=''
                  //rules={{ validate: validateBrackets }}
                  render={({ field, fieldState: { error } }) => (
                    <FormField
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : null}
                      autoComplete={false}
                      type='text'
                      label='Folga da Largura'
                      rows={1}
                      multiline={false}
                      required={false}
                      disabled={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={0.5}> {/*Assistente de formula */}
                <Tooltip title="Click para abrir o Assistente de Fórmula" placement="top">
                  <IconButton
                    onClick={(event) => {
                      //setCurrentTargetBotaoMenu(event.currentTarget);
                      setValorFormula(getValues('componentes.folga_largura'))
                      setAssistenteFormula({ ...openAssistenteFormula, visible: true, campo: 'componentes.folga_largura', label: 'Folga da Largura' })
                    }}
                  >
                    <HelpCenterIcon />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item xs={12} sm={7.5}>{/*formula_medida_final*/}
                <Controller
                  name={`componentes.formula_medida_final`}
                  control={props.control}
                  defaultValue=''
                  rules={{ validate: validateBrackets }}
                  render={({ field, fieldState: { error } }) => (
                    <FormField
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : null}
                      autoComplete={false}
                      type='text'
                      label='Medida Final: Quantidade ou Fórmula'
                      rows={1}
                      multiline={false}
                      required={true}
                      disabled={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={0.5}> {/*Assistente de formula */}
                <Tooltip title="Click para abrir o Assistente de Fórmula" placement="top">
                  <IconButton
                    onClick={(event) => {
                      //setCurrentTargetBotaoMenu(event.currentTarget);
                      setValorFormula(getValues('componentes.formula_medida_final'))
                      setAssistenteFormula({ ...openAssistenteFormula, visible: true, campo: 'componentes.formula_medida_final', label: 'Medida Final: Quantidade ou Fórmula' })
                    }}
                  >
                    <HelpCenterIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={4}>{/*botao de copiar dados*/}
                <Button onClick={handleClickCopiaFormulas} >Copia Fórmulas para Cobrança</Button>
              </Grid>


              <Grid item xs={12} sm={5.5}>{/*formula_altura_cobranca*/}
                <Controller
                  name={`componentes.formula_altura_cobranca`}
                  control={props.control}
                  defaultValue=''
                  rules={{ validate: validateBrackets }}
                  render={({ field, fieldState: { error } }) => (
                    <FormField
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : null}
                      autoComplete={false}
                      type='text'
                      label='Altura de Cobrança: Quantidade ou Fórmula'
                      rows={1}
                      multiline={false}
                      required={true}
                      disabled={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={0.5}> {/*Assistente de formula */}
                <Tooltip title="Click para abrir o Assistente de Fórmula" placement="top">
                  <IconButton
                    onClick={(event) => {
                      //setCurrentTargetBotaoMenu(event.currentTarget);
                      setValorFormula(getValues('componentes.formula_altura_cobranca'))
                      setAssistenteFormula({ ...openAssistenteFormula, visible: true, campo: 'componentes.formula_altura_cobranca', label: 'Altura de Cobrança: Quantidade ou Fórmula' })
                    }}
                  >
                    <HelpCenterIcon />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item xs={12} sm={5.5}>{/*formula_largura_cobranca*/}
                <Controller
                  name={`componentes.formula_largura_cobranca`}
                  control={props.control}
                  defaultValue=''
                  rules={{ validate: validateBrackets }}
                  render={({ field, fieldState: { error } }) => (
                    <FormField
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : null}
                      autoComplete={false}
                      type='text'
                      label='Largura de Cobrança: Quantidade ou Fórmula'
                      rows={1}
                      multiline={false}
                      required={true}
                      disabled={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={0.5}> {/*Assistente de formula */}
                <Tooltip title="Click para abrir o Assistente de Fórmula" placement="top">
                  <IconButton
                    onClick={(event) => {
                      //setCurrentTargetBotaoMenu(event.currentTarget);
                      setValorFormula(getValues('componentes.formula_largura_cobranca'))
                      setAssistenteFormula({ ...openAssistenteFormula, visible: true, campo: 'componentes.formula_largura_cobranca', label: 'Largura de Cobrança: Quantidade ou Fórmula' })
                    }}
                  >
                    <HelpCenterIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={11.5}>{/*formula_medida_final_cobranca*/}
                <Controller
                  name={`componentes.formula_medida_final_cobranca`}
                  control={props.control}
                  defaultValue=''
                  rules={{ validate: validateBrackets }}
                  render={({ field, fieldState: { error } }) => (
                    <FormField
                      {...field}
                      error={!!error}
                      helperText={error ? error.message : null}
                      autoComplete={false}
                      type='text'
                      label='Medida Final de Cobrança: Quantidade ou Fórmula'
                      rows={1}
                      multiline={false}
                      required={true}
                      disabled={false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={0.5}> {/*Assistente de formula */}
                <Tooltip title="Click para abrir o Assistente de Fórmula" placement="top">
                  <IconButton
                    onClick={(event) => {
                      //setCurrentTargetBotaoMenu(event.currentTarget);
                      setValorFormula(getValues('componentes.formula_medida_final_cobranca'))
                      setAssistenteFormula({ ...openAssistenteFormula, visible: true, campo: 'componentes.formula_medida_final_cobranca', label: 'Medida Final de Cobrança: Quantidade ou Fórmula' })
                    }}
                  >
                    <HelpCenterIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6}>{/*formula_quantidadeinsercao*/}
                <Controller
                  name={`componentes.formula_quantidadeinsercao`}
                  control={props.control}
                  defaultValue=''
                  render={({ field: { ref, ...props } }) =>
                    <FormField
                      autoComplete={false}
                      type='text'
                      label='Quantidade de Inserções (0 não insere o componente)'
                      rows={1}
                      multiline={false}
                      disabled={false}
                      inputRef={ref}
                      required={true}
                      {...props}
                    />}
                />
              </Grid>

              <Grid item xs={12} sm={6}>{/*observacao*/}
                <Controller
                  name={`componentes.observacao`}
                  control={props.control}
                  defaultValue=''
                  render={({ field: { ref, ...props } }) =>
                    <FormField
                      autoComplete={false}
                      type='text'
                      label='Observação'
                      rows={1}
                      multiline={false}
                      disabled={false}
                      inputRef={ref}
                      required={false}
                      {...props}
                    />}
                />
              </Grid>

            </Grid>

            <MDBox width="100%" display="flex" justifyContent="flex-end" mt={2}>
              <MDButton variant="gradient" color="info"
                type="submit"
              //onClick={() => handleSubmit(gravarComponente)()}
              >Gravar</MDButton>
              <MDButton variant="gradient" color="info" onClick={novoComponente} sx={{ ml: 2 }}>Novo</MDButton>
            </MDBox>

            <MDBox width="100%" display="flex" mt={2}>
              <TabelaComponentes
                projetoAtual={projetoAtual}
                abrirRegistro={abrirRegistro}
                excluirRegistro={handleClickExcluir}
                recarregaTabela={recarregaTabela}
              />
            </MDBox>
          </form>

        </Collapse>
      </MDBox>
    </>
  )
}

export default ComponentesProjeto;