import React, { useContext, useEffect, useRef, useState } from 'react'
import RelatorioPadrao from '../../../relatorios/padrao'
import FiltroListaMateriaisImpressao from './filtro'
import DataPrinterContext from 'hooks/DataPrinter'
import MessagesContext from 'hooks/MessagesContext'
import { copiaProfundaObjetos } from 'commons/utils'
import { rotafechada } from 'commons/urlconf'
import api from 'commons/api'
import { formataValor } from 'commons/utils'


export default function RelatorioListaDeMateriaisAluminioPedido(props) {

    const { vendaAtual, open, setOpen } = props
    const messageContext = useContext(MessagesContext)
    const { MensagemErro, MensagemAviso } = messageContext
    const [openRelatorioPadrao, setOpenRelatorioPadrao] = useState(false)
    const dataPrinterContext = useContext(DataPrinterContext)

    const geraRelatorio = async (params, data) => {


        dataPrinterContext.setEstiloColunas({ backgroundColor: '#F2F4F4', fontSize: '14px' })
        dataPrinterContext.setEstiloLinhas({ backgroundColor: 'white', fontSize: '12px' })

        dataPrinterContext.setTitulo(`Lista de Alumínios do Pedido ${vendaAtual}`)

        const subtitulo = []

        let nomestipos = ``
        params?.tipos?.map((item) => {
            nomestipos = `${nomestipos}${item.nome} / `
        })

        if (nomestipos !== '') {
            nomestipos = `Tipos ${nomestipos}`
        }

        if (nomestipos !== '') {
            subtitulo.push('Filtros: ' + nomestipos)
        }

        dataPrinterContext.setSubtitulo(subtitulo)


        const colunas = []

        if (params.mostraImagem) {
            colunas.push({ id: 'imagem', label: '', sx: { width: '50px' } })
        }

        if (params.mostraTipo) {
            colunas.push({ id: 'tipo', label: 'Tipo', sx: { width: '40px' } })
        }

        if (params.mostraReferencia) {
            colunas.push({ id: 'referencia', label: 'Referência', sx: { width: '50px' } })
        }

        if (params?.mostraNomeProduto) {
            colunas.push({ id: 'produto', label: 'Material' })
        }
        if (params?.mostraCor) {
            colunas.push({ id: 'cor', label: 'Cor', sx: { width: '250px' } })
        }

        colunas.push({ id: 'barras', label: 'Barras', sx: { width: '40px' } })
        
        colunas.push({ id: 'peso', label: 'Peso', sx: { width: '100px' } })
        
        if (params.mostraPreco) {
            colunas.push({ id: 'preco', label: 'Custo', sx: { width: '100px' } })
        }
        dataPrinterContext.setColunas(colunas)

        const linhasTemp = []
        
        let somaBarras = 0
        let somaPeso = 0
        let somaCusto = 0

        for(let i=0; i<data.length; i++) {
            const tipo_produto_id = data[i].tipo_produto_id

            if (params.tipos.filter((item) => parseInt(item.id, 10) === parseInt(tipo_produto_id, 10)).length > 0) {
                let imagem = ''

                if (data[i]?.imagem) {
                    imagem = <img src={`data:image/png[jpg];base64,${data[i].imagem}`} alt="Imagem do Produto" style={{ maxHeight: 50, maxWidth: 50 }} />
                }

                let componentes = data[i].componentes.map(item => `${item.pecas} x ${parseInt(item.tamanho*1000)}mm   ||    `) //alt255 
                
                componentes[componentes.length-1] = componentes[componentes.length-1].replace('   ||   ','')                

                linhasTemp.push({
                    imagem,
                    tipo: data[i]?.tipo_produto_nome,
                    referencia: data[i]?.referencia,
                    produto: <>
                        <p>
                            {data[i]?.nome} <br></br>
                            <br></br>
                            {componentes}
                        </p>
                    </>,  
                    cor: data[i]?.cor,
                    peso: (parseFloat(data[i]?.peso)*(parseInt(data[i]?.total/1000))).toFixed(2) || '',
                    barras: (parseInt(data[i]?.total/1000) / parseInt(data[i]?.tamanho_barra)),
                    preco: (parseFloat(data[i]?.valor_custo)*(parseInt(data[i]?.total/1000))).toFixed(2) || '',
                })
                somaPeso+=parseFloat((parseFloat(data[i]?.peso)*(parseInt(data[i]?.total/1000))).toFixed(2))
                somaBarras+=(parseInt(data[i]?.total/1000) / parseInt(data[i]?.tamanho_barra))
                somaCusto+=parseFloat((parseFloat(data[i]?.valor_custo)*(parseInt(data[i]?.total/1000))).toFixed(2))
            }
        }

        //totalizando
        linhasTemp.push({
            imagem: '',
            tipo: '',
            referencia: '',
            produto: 'TOTALIZAÇÃO',  
            cor: '',
            peso: `${somaPeso.toFixed(2)} KG`,
            barras: somaBarras,
            preco: formataValor(somaCusto),
            sx: {
                fontWeight: 'bold',
                fontSize: '12px'
            }
        })        


        dataPrinterContext.setLinhas(linhasTemp)
    }

    const handleImprimir = async (params = {
        tipos: [],
        mostraImagem: false,
        mostraPreco: false
    }) => {

        if (!(params?.tipos?.length > 0)) {
            MensagemErro('Selecione pelo menos um tipo de material para imprimir o relatório')
            return
        }

        try {
            const retorno = await api.get(`${rotafechada()}vendas/dadosrelatorios/listamateriaisaluminio?venda_id=${vendaAtual}&${params.mostraImagem ? 'retornaimagem=S' : 'retornaimagem=N'}`)
            const { data } = retorno.data

            if (!(data.length > 0)) {
                MensagemAviso('Nenhuma informação foi selecionada')
                return
            }

            setOpenRelatorioPadrao(true)
            await geraRelatorio(params, data)
        }
        catch (error) {
            const msg = error.response?.data?.msg || error
            MensagemErro(`${msg}`)
        }
    }

    return (
        <>
            <RelatorioPadrao open={openRelatorioPadrao} setOpen={setOpenRelatorioPadrao} />
            <FiltroListaMateriaisImpressao
                open={open}
                setOpen={setOpen}
                handleOk={handleImprimir}
            />
        </>
    )
}