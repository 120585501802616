import { Button, Checkbox, Dialog, DialogContent, DialogContentText, DialogTitle, Icon, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import button from "assets/theme/components/button";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import { converteDataSemUTC } from "commons/utils";
import { formatDate } from "commons/utils";
import DialogoConfirma from "componentes/DialogoConfirma";
import MDButton from "components/MDButton";
import AuthContext from "hooks/AuthContext";
import MessagesContext from "hooks/MessagesContext";
import { useState } from "react";
import { useContext, useEffect } from "react";
import DadosPedidoTempera from "./dadospedidotempera";
import OpcoesInsercaoProjeto from "./opcoesinsercaoprojeto";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import TabelaEtapa1 from "./tabela";
import { Add } from "@mui/icons-material";
import { copiaProfundaObjetos } from "commons/utils";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';

/*
Nesta etapa

Mostra uma tela com os "Projetos sem Pedido de Têmpera" na parte de cima e os "Projetos com Pedido de Tempera na parte de baixo"

Ao selecionar um projeto em cima e mandar adicionar verificar se já existe algum pedido de têmpera para essa venda
    Se Sim
        mostrar uma tela de escolha para ("Criar um novo projeto de tempera") ou "Escolher qual projeto de tempera desejo adicionar esse projeto"
            Se escolher um novo projeto (abrir a tela de novo projeto), caso contrario avança para a próxima etapa
    Se Não
        Abrir a tela de novo Projeto de têmpera (Solicitar fornecedor, data e obs)

*/


export default function Etapa1_PreparaPedidoTempera(props) {

    const { handleClose, vendaAtual, etapaAtual, setEtapaAtual, abortaProcesso, parametrosVenda } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();


    const [openOpcoesInsercaoProjeto, setOpenOpcoesInsercaoProjeto] = useState({ open: false, venda_projeto_id: null });

    const [openConfirmacao, setOpenConfirmacao] = useState({ open: false, pedido_tempera_venda_projeto_id: null });


    const [datasetSemPedidoTempera, setDatasetSemPedidoTempera] = useState({});
    const [datasetComPedidoTempera, setDatasetComPedidoTempera] = useState({});

    const [datasetPedidosTemperaDisponiveis, setDatasetPedidosTemperaDisponiveis] = useState([]);

    const [dadosPedidoTempera, setDadosPedidoTempera] = useState({
        open: false,
        id: '',
        fornecedor_id: '',
        data: '',
        observacao: '',
        venda_projeto_id: ''
    })

    const handleClickAdicionarSelecionados = () => {

        const objtemp = copiaProfundaObjetos(datasetSemPedidoTempera.linhas);
        const codigos = []

        try {
            for (let i = 0; i < objtemp.length; i++) {
                if (objtemp[i].selecionado) {
                    codigos.push(objtemp[i].id)
                }

            }
            handleClickAdicionarProjeto(codigos);
        }
        catch (error) {
        }
    }

    const handleClickAdicionarProjeto = async (venda_projeto_id = []) => {

        try {
            const retorno = await api.get(encodeURI(`${rotafechada()}pedido_tempera_venda_projetos?venda_id=${vendaAtual}`));
            const { countAll, data } = retorno.data;

            if (countAll === 0) {
                //nao tem nenhum pedido de tempera
                setDadosPedidoTempera({
                    open: true,
                    venda_projeto_id,
                    id: '',
                    fornecedor_id: '',
                    observacao: '',
                    data: new Date()
                });
                return;
            }

            const pedidosTemperaTemp = [];
            //se chegou até aqui tem pelo menos um pedido de têmpera para a venda atual então vou adicionar em um state
            //os pedidos de têmpera que podem ser usados para adicionar esse projeto 
            data.map((item) => {

                if (pedidosTemperaTemp.filter((itemfiltro) => parseInt(itemfiltro.pedido_tempera_id, 10) === parseInt(item.pedido_tempera_id, 10)).length === 0) {
                    pedidosTemperaTemp.push({
                        pedido_tempera_id: item.pedido_tempera_id,
                        data: item?.Pedidos_Tempera?.data,
                        concluido: item?.Pedidos_Tempera?.concluido,
                        fornecedor_id: item?.Pedidos_Tempera?.fornecedor_id,
                        razao_social: item?.Pedidos_Tempera?.Fornecedor?.razao_social,
                        fantasia: item?.Pedidos_Tempera?.Fornecedor?.fantasia,
                    })
                }
            });

            setDatasetPedidosTemperaDisponiveis(pedidosTemperaTemp);
            setOpenOpcoesInsercaoProjeto({
                open: true,
                venda_projeto_id
            })
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            const erro = error.response?.data?.erro || '';
            console.log(erro);
            MensagemErro(`Erro ao tentar resgatar os dados dos projetos da Venda!${msg}`)
        }

    }

    const handleClickRemoverProjeto = async (pedido_tempera_venda_projeto_id) => {

        if (!(parseInt(pedido_tempera_venda_projeto_id, 10) > 0)) {
            MensagemErro('Código inválido!');
            return;
        }
        setOpenConfirmacao({
            open: true,
            pedido_tempera_venda_projeto_id,
            message: 'Certeza que deseja excluir o Projeto do Pedido de Têmpera?',
            handleCancel: () => setOpenConfirmacao({ open: false }),
            handleConfirm: () => removerProjeto(pedido_tempera_venda_projeto_id)
        });
    }

    const handleClickRemoverProjetoSelecionados = async () => {

        setOpenConfirmacao({
            open: true,
            message: 'Certeza que deseja excluir os Projetos do Pedido de Têmpera?',
            handleCancel: () => setOpenConfirmacao({ open: false }),
            handleConfirm: () => removerProjetoSelecionados()
        });
    }

    const removerProjetoSelecionados = async () => {

        const objtemp = copiaProfundaObjetos(datasetComPedidoTempera.linhas);

        try {
            MostraTelaEspera('Aguarde...');
            for (let i = 0; i < objtemp.length; i++) {
                if (objtemp[i].selecionado) {
                    await removerProjeto(objtemp[i].pedido_tempera_venda_projeto_id, false);
                }

            }
            buscaDadosVendaPedidoTempera();
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
        }
    }

    const removerProjeto = async (pedido_tempera_venda_projeto_id, carregaDadosAposRemover = true) => {

        setOpenConfirmacao({ open: false });

        if (!(parseInt(pedido_tempera_venda_projeto_id, 10) > 0)) {
            MensagemErro('Código inválido!');
            return;
        }

        try {
            await api.delete(encodeURI(`${rotafechada()}pedido_tempera_venda_projetos/${pedido_tempera_venda_projeto_id}`));
            if (carregaDadosAposRemover) {
                buscaDadosVendaPedidoTempera();
            }
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            const erro = error.response?.data?.erro || '';
            console.log(erro);
            MensagemErro(`Erro ao tentar excluir o projeto do Pedido de têmpera!`)
        }
    }

    const buscaDadosVendaPedidoTempera = async () => {
        try {
            const retorno = await api.get(encodeURI(`${rotafechada()}pedido_tempera_venda_projetos/listarvendaprojetos?venda_id=${vendaAtual}`));
            const { data } = retorno.data;

            if (!data.length > 0) {
                MensagemErro(`A venda não possui projetos!`);
                abortaProcesso();
                return;
            }

            const semPedidoTemperaTemp = {
                colunas: [

                    {
                        id: 'acoes',
                        disableOrder: true,
                        numeric: false,
                        disablePadding: true,
                        label: 'Ações'
                    },
                    {
                        id: 'nomeprojeto',
                        numeric: false,
                        disableOrder: true,
                        disablePadding: true,
                        label: 'Nome Projeto'
                    },
                    {
                        id: 'ambiente_instalacao',
                        disableOrder: true,
                        numeric: false,
                        disablePadding: true,
                        label: 'Ambiente'
                    },
                ],
                linhas: []
            };
            const comPedidoTemperaTemp = {
                colunas: [
                    {
                        id: 'acoes',
                        disableOrder: true,
                        numeric: false,
                        disablePadding: true,
                        label: 'Ações'
                    },
                    {
                        id: 'pedidotempera',
                        numeric: false,
                        disablePadding: true,
                        disableOrder: true,
                        label: 'Pedido Têmpera'
                    },
                    {
                        id: 'nomeprojeto',
                        numeric: false,
                        disableOrder: true,
                        disablePadding: true,
                        label: 'Nome Projeto'
                    },
                    {
                        id: 'ambiente_instalacao',
                        disableOrder: true,
                        numeric: false,
                        disablePadding: true,
                        label: 'Ambiente'
                    },
                ],
                linhas: []
            };

            data.map((item, index) => {

                if (item.Pedido_Tempera_Venda_Projetos?.length > 0) {
                    let datastr = '';
                    try {
                        const dataSemUtc = converteDataSemUTC(item.Pedido_Tempera_Venda_Projetos[0]?.Pedidos_Tempera.data);
                        datastr = `${formatDate(new Date(dataSemUtc), 'DD/MM/YY')}`;
                    }
                    catch (error) {
                        datastr = item.Pedido_Tempera_Venda_Projetos[0]?.Pedidos_Tempera.data;
                    }

                    comPedidoTemperaTemp.linhas.push({
                        selecao: <></>,
                        //ATENÇÃO O CAMPO FANTASIA ESTA COMO "FANTAS" DEVIDO AO BUG NO SEQUELIZE, REPORTEI NA ISSUE  https://github.com/sequelize/sequelize/issues/15460
                        pedidotempera: <>
                            {`${item.Pedido_Tempera_Venda_Projetos[0]?.pedido_tempera_id}-${item.Pedido_Tempera_Venda_Projetos[0]?.Pedidos_Tempera.Fornecedor.fantas}-Data: ${datastr} `}
                            <Tooltip title='Editar o Pedido de Têmpera'>
                                <MDButton size="medium" onClick={() => handleClickAlteraDadosPedidoTempera(item.Pedido_Tempera_Venda_Projetos[0]?.pedido_tempera_id)} iconOnly >
                                    <Icon fontSize="medium" color='info'>edit_icon</Icon>
                                </MDButton>
                            </Tooltip>
                        </>,
                        nomeprojeto: `${item.id} - Proj: ${item.projeto_id}-${item.Projeto?.nome}`,
                        ambiente_instalacao: item.ambiente_instalacao,
                        acoes: <>
                            <Tooltip title='Retira o Projeto do Pedido de Têmpera'>
                                <MDButton
                                    onClick={(e) => handleClickRemoverProjeto(item.Pedido_Tempera_Venda_Projetos[0]?.id || 0)}
                                >
                                    <RemoveCircleIcon fontSize="large" color='info' />
                                </MDButton>
                            </Tooltip>
                        </>,
                        selecionado: false,
                        pedido_tempera_venda_projeto_id: item.Pedido_Tempera_Venda_Projetos[0]?.id
                    })
                }
                else {
                    semPedidoTemperaTemp.linhas.push({
                        selecao: <></>,
                        nomeprojeto: `${item.id} - Proj: ${item.projeto_id}-${item.Projeto?.nome}`,
                        ambiente_instalacao: item.ambiente_instalacao,
                        acoes: <>
                            <Tooltip title='Adiciona o Projeto em um Pedido de Têmpera'>
                                <MDButton
                                    onClick={(e) => handleClickAdicionarProjeto([item.id])}
                                >
                                    <AddCircleIcon fontSize="large" color='info' />
                                </MDButton>
                            </Tooltip>
                        </>,
                        selecionado: false,
                        id: item.id
                    })
                }

            })

            setDatasetComPedidoTempera(comPedidoTemperaTemp);
            setDatasetSemPedidoTempera(semPedidoTemperaTemp);

        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            const erro = error.response?.data?.erro || '';
            console.log(erro);
            MensagemErro(`Erro ao tentar resgatar os dados dos projetos da Venda!${msg}`)
        }
    }

    const handleClickSelecaoComPedidoTempera = async (index, estadocheckbox) => {

        debugger;
        const objtemp = copiaProfundaObjetos(datasetComPedidoTempera.linhas);
        objtemp[index].selecionado = estadocheckbox;
        setDatasetComPedidoTempera({ colunas: datasetComPedidoTempera.colunas, linhas: objtemp });
    }

    const handleClickSelecaoSemPedidoTempera = async (index, estadocheckbox) => {

        const objtemp = copiaProfundaObjetos(datasetSemPedidoTempera.linhas);
        objtemp[index].selecionado = estadocheckbox;
        setDatasetSemPedidoTempera({ colunas: datasetSemPedidoTempera.colunas, linhas: objtemp });
    }

    const handleClickAllSelecaoComPedidoTempera = async (estadocheckbox) => {

        const objtemp = copiaProfundaObjetos(datasetComPedidoTempera.linhas);
        objtemp.map((item) => item.selecionado = estadocheckbox)
        setDatasetComPedidoTempera({ colunas: datasetComPedidoTempera.colunas, linhas: objtemp });
    }

    const handleClickAllSelecaoSemPedidoTempera = async (estadocheckbox) => {

        const objtemp = copiaProfundaObjetos(datasetSemPedidoTempera.linhas);
        objtemp.map((item) => item.selecionado = estadocheckbox)
        setDatasetSemPedidoTempera({ colunas: datasetSemPedidoTempera.colunas, linhas: objtemp });
    }



    const handleInserirPedidoTempera = async (pedido_tempera_id, venda_projeto_id = []) => {

        setOpenOpcoesInsercaoProjeto({ open: false });
        MensagemAviso(`Inserir o venda/projeto ${venda_projeto_id} no Pedido de Têmpera ${pedido_tempera_id}`);

        //escolheu inserir em um novo
        if (parseInt(pedido_tempera_id, 10) === 0) {
            setDadosPedidoTempera({
                open: true,
                id: '',
                venda_projeto_id,
                fornecedor_id: '',
                data: new Date(),
                observacao: ''
            });
            return;
        }

        try {
            let dados = {};
            let retorno;

            //inserindo em um pedido de tempera ja existente
            for (let i = 0; i < venda_projeto_id.length; i++) {
                dados = {
                    empresa_id: sessaolocal.empresa_id || null,
                    pedido_tempera_id,
                    venda_projeto_id: venda_projeto_id[i]
                }
                retorno = await api.post(encodeURI(`${rotafechada()}pedido_tempera_venda_projetos`), dados);
            }

            buscaDadosVendaPedidoTempera();

        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            const erro = error.response?.data?.erro || '';
            console.log(erro);
            MensagemErro(`${msg}`)
        }


    }

    const handleClickAlteraDadosPedidoTempera = async (pedido_tempera_id) => {

        //MensagemAviso('Altera pedido de tempera ' + pedido_tempera_id);

        try {
            const retorno = await api.get(encodeURI(`${rotafechada()}pedidos_tempera?id=${pedido_tempera_id}`));
            const { data } = retorno.data;

            if (!data.length > 0) {
                MensagemErro(`Pedido de Têmpera não localizado para ser alterado!`);
                return;
            }

            const datatemp = converteDataSemUTC(data[0].data);

            setDadosPedidoTempera({
                open: true,
                id: pedido_tempera_id,
                fornecedor_id: data[0].fornecedor_id,
                data: new Date(datatemp),
                observacao: data[0].observacao,
                venda_projeto_id: [],
            })


        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            const erro = error.response?.data?.erro || '';
            console.log(erro);
            MensagemErro(`Erro ao tentar resgatar os dados do pedido de têmpera!${msg}`)
        }
    }


    useEffect(() => {
        buscaDadosVendaPedidoTempera();
    }, [])



    const handleClickProximaEtapa = async () => {

        if (!(datasetComPedidoTempera.linhas.length > 0)) {
            MensagemErro('Só é possível imprimir se existir pelo menos um projeto com pedido de têmpera! Selecione os projetos primeiro');
            return;
        }
        setEtapaAtual(etapaAtual + 1);
    }

    return (
        <>
            <DialogoConfirma
                open={openConfirmacao?.open || false}
                message={openConfirmacao?.message || ''}
                title={openConfirmacao?.title || 'Confirmação'}
                handleCancel={openConfirmacao.handleCancel}
                handleConfirm={openConfirmacao.handleConfirm}
            />

            <OpcoesInsercaoProjeto
                open={openOpcoesInsercaoProjeto?.open || false}
                handleClose={() => {
                    setOpenOpcoesInsercaoProjeto({ ...openOpcoesInsercaoProjeto, open: false });
                    buscaDadosVendaPedidoTempera();
                }}
                venda_projeto_id={openOpcoesInsercaoProjeto?.venda_projeto_id || null}
                datasetPedidosTemperasDisponiveis={datasetPedidosTemperaDisponiveis}
                handlePedidoTemperaEscolhido={(pedido_tempera_id) => handleInserirPedidoTempera(pedido_tempera_id, openOpcoesInsercaoProjeto.venda_projeto_id)}
            />

            <DadosPedidoTempera
                open={dadosPedidoTempera?.open || false}
                handleClose={() => {
                    setDadosPedidoTempera({ ...dadosPedidoTempera, open: false });
                    buscaDadosVendaPedidoTempera();
                }}
                dadosPedidoTempera={dadosPedidoTempera}
                setDadosPedidoTempera={setDadosPedidoTempera}
                parametrosVenda={parametrosVenda}
            />



            {datasetSemPedidoTempera?.linhas?.length > 0 &&
                <>
                    <Typography variant="h6">Estes Projetos ainda não foram adicionados em um Pedido de Têmpera</Typography>
                    {
                        (datasetSemPedidoTempera?.linhas?.filter((item) => item.selecionado).length > 0) &&
                        <Tooltip title='Adiciona todos os selecionados'>
                            <Button size='large' onClick={handleClickAdicionarSelecionados}>
                                <PlaylistAddIcon color='info' fontSize="large" />
                            </Button>
                        </Tooltip>
                    }
                    <TabelaEtapa1
                        colunas={datasetSemPedidoTempera?.colunas}
                        linhas={datasetSemPedidoTempera?.linhas}
                        onChangeCheckBox={handleClickSelecaoSemPedidoTempera}
                        onChangeAllCheckBox={handleClickAllSelecaoSemPedidoTempera}

                    />
                </>
            }

            {
                datasetComPedidoTempera?.linhas?.length > 0 &&
                <>
                    <Typography variant="h6" mb={2}>Estes Projetos já estão adicionados em um Pedido de Têmpera</Typography>
                    {
                        (datasetComPedidoTempera?.linhas?.filter((item) => item.selecionado).length > 0) &&
                        <Tooltip title='Remove todos os selecionados'>
                            <Button size='large' onClick={handleClickRemoverProjetoSelecionados}>
                                <PlaylistRemoveIcon color='info' fontSize="large" />
                            </Button>
                        </Tooltip>

                    }
                    <TabelaEtapa1
                        colunas={datasetComPedidoTempera?.colunas}
                        linhas={datasetComPedidoTempera?.linhas}
                        onChangeCheckBox={handleClickSelecaoComPedidoTempera}
                        onChangeAllCheckBox={handleClickAllSelecaoComPedidoTempera}
                    />

                </>
            }



            <Box display='flex' justifyContent={'flex-end'}>
                <MDButton color='info' onClick={handleClickProximaEtapa}>Avançar</MDButton>
            </Box>

        </>
    )
}