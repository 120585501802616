
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Box, Card, IconButton, InputAdornment, ListItemIcon, Menu, MenuItem, Paper, Popover, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";
import { formatDate, incMonth } from "commons/utils";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ptBR } from "date-fns/locale";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FormField from "componentes/FormField";
import MessagesContext from "hooks/MessagesContext";
import AuthContext from 'hooks/AuthContext';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { addMonths, endOfMonth, parseJSON, startOfMonth, subMonths } from 'date-fns'
import format from 'date-fns/format'
import CancelIcon from '@mui/icons-material/Cancel';
import { capitalizeInitials } from "commons/utils";
import DescriptionIcon from '@mui/icons-material/Description';
import CurrencyInput from "componentes/MascaraMonetaria";
import DialogoFiltrosRelatorio from '../../relatoriosPersonalizados/Filtro';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.any,
    index: PropTypes.number,
    value: PropTypes.number,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function Relatorios() {

    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera, MensagemAlerta } = messageContext;

    const [aba, setAba] = React.useState(0);
    const [openFiltroExtrato, setOpenFiltroExtrato] = React.useState({ visible: false, relatorio_id: null });
    const [openFiltroReciboAvulso, setOpenFiltroReciboAvulso] = React.useState({ visible: false, relatorio_id: null });
    const [openFiltroFatura, setOpenFiltroFatura] = React.useState({ visible: false, relatorio_id: null });
    const [openFiltroConta, setOpenFiltroConta] = React.useState({ visible: false, relatorio_id: null });
    const [dataInicial, setDataInicial] = useState(incMonth(new Date(), -1));
    const [dataFinal, setDataFinal] = useState(new Date());
    const [banco, setBanco] = useState({ value: -1, label: '<Escolha uma opção>' });
    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();
    const [openCollapse, setOpenCollapse] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
    const [todosAbertos, setTodosAbertos] = useState(true);
    const [relatorioPersonalizadoId, setRelatorioPersonalizadoId] = useState(null);

    const [pessoa, setPessoa] = useState({ value: -1, label: '<Escolha uma Pessoa>' });
    const [categoria, setCategoria] = useState({ value: -1, label: '<Escolha uma Categoria>' });
    const [categoriaTipo, setCategoriaTipo] = useState('');

    const [dataRecibo, setDataRecibo] = useState(new Date());
    const [valorRecibo, setValorRecibo] = useState('');
    const [recebiDeRecibo, setRecebiDeRecibo] = useState('');
    const [referenteRecibo, setReferenteRecibo] = useState('');
    const [recebedorRecibo, setRecebedorRecibo] = useState(sessaolocal.fantasia);
    const [openFiltrosAvancados, setOpenFiltrosAvancados] = useState(false);
    const [valorPesquisa, setValorPesquisa] = React.useState('');

    const [filtrosPersonalizados, setFiltrosPersonalizados] = useState({
        open: false,
        itens: [{}],
        dataset: [{}],
        relatorio_id: 0
    });

    const [filtro, setFiltro] = useState(
        {
            labelOriginal: endOfMonth(new Date()),
            labelFormatada: format(endOfMonth(new Date()), "MMMM 'de' yyyy", { locale: ptBR }),

            dataInicialOriginal: startOfMonth(new Date()),
            dataInicialFormatada: (format(startOfMonth(new Date()), "yyyy-MM-dd 00:00:00", { locale: ptBR })),

            dataFinalOriginal: endOfMonth(new Date()),
            dataFinalFormatada: (format(endOfMonth(new Date()), "yyyy-MM-dd 23:59:59", { locale: ptBR })),

            descricaoFatura: `Pagamento fatura ${format(endOfMonth(new Date()), "MMMM 'de' yyyy", { locale: ptBR })}`
        }
    );
    const options = [
        // { value: 1, label: 'Contas a pagar', modulo: 'financeiro' },
        // { value: 2, label: 'Contas a receber', modulo: 'financeiro' },
        // { value: 3, label: 'Contas pagas', modulo: 'financeiro' },
        // { value: 4, label: 'Contas recebidas', modulo: 'financeiro' },
        { value: 5, label: 'Extrato de movimentação', modulo: 'financeiro' },
        { value: 6, label: 'Fatura do cartão de crédito', modulo: 'financeiro' },
        { value: 7, label: 'Recibo avulso', modulo: 'financeiro' },
        { value: 8, label: 'Pedido compacto', modulo: 'orcamento' },
        { value: 9, label: 'Pedido completo', modulo: 'orcamento' },
        { value: 10, label: 'Relatorio personalizado', modulo: 'personalizados' },

    ];

    const [optionsPersonalizados, setOptionPersonalizados] = useState([
        //{ value: 9, label: 'Relatorio personalizado', modulo: 'personalizados' },
    ]);

    const [optionsCategoriaCentralRelatorios, setOptionsCategoriaCentralRelatorios] = useState([
        //{ value: 9, label: 'Relatorio personalizado', modulo: 'personalizados' },
    ]);

    const [optionBancoExtrato, setOptionBancoExtrato] = useState([
        { value: -1, label: "<Escolha um Banco ou Caixa>" },
    ]);

    const [optionBancoFatura, setOptionBancoFatura] = useState([
        { value: -1, label: "<Escolha um Cartão de crédito>" },
    ]);

    const [optionPessoaConta, setOptionPessoaConta] = useState([
        { value: -1, label: "<Escolha uma Pessoa>" },
    ]);

    const [optionCategoriaConta, setOptionCategoriaConta] = useState([
        { value: -1, label: "<Escolha uma Categoria>" },
    ]);

    const handleChangeAba = (event, novaAba) => {
        setAba(novaAba);
    };

    const handleclickFiltroRelatorio = async (e) => {
        if ((e === 1) || (e === 2) || (e === 3) || (e === 4)) {
            setOpenFiltroConta({ visible: true, relatorio_id: e })
            if ((e === 1) || (e === 3)) setCategoriaTipo('D')
            if ((e === 2) || (e === 4)) setCategoriaTipo('R')
        }

        if (e === 5) {
            setOpenFiltroExtrato({ visible: true, relatorio_id: e })
        }

        if (e === 6) {
            setOpenFiltroFatura({ visible: true, relatorio_id: e })
        }

        if (e === 7) {
            setOpenFiltroReciboAvulso({ visible: true, relatorio_id: e })
        }
    };

    const handleClickFiltroRelatorioPersonalizado = async (e) => {
        setFiltrosPersonalizados({ ...filtrosPersonalizados, configuracao: true, open: true, impressao: true, relatorio_id: e });
    }

    const buscaBanco = async () => {
        try {
            const url = `${rotafechada()}banco?order=nome&limit=-1&ativo=S`;
            //console.log("***URL forma de pagamento:", url);
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesBancoExtratoTemp = [{ value: -1, label: '<Escolha uma opção>' }];
            let opcoesBancoFaturaTemp = [{ value: -1, label: '<Escolha uma opção>' }];

            data.map((item) => {
                if (item.tipo === 'B' || item.tipo === 'C') {
                    opcoesBancoExtratoTemp.push({ value: item.id, label: item.nome });
                }
                else
                    opcoesBancoFaturaTemp.push({ value: item.id, label: item.nome });
            });
            setOptionBancoExtrato(opcoesBancoExtratoTemp);
            setOptionBancoFatura(opcoesBancoFaturaTemp);

            return opcoesBancoExtratoTemp, opcoesBancoFaturaTemp;
        } catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const buscaPessoas = async () => {
        try {
            const url = `${rotafechada()}pessoas?order=fantasia&limit=-1&ativo=S`
            let retorno = await api.get(encodeURI(url))
            const { data } = retorno.data
            let opcoesPessoasContaTemp = [{ value: -1, label: '<Escolha uma Pessoa>' }];

            data.map((item) => {
                opcoesPessoasContaTemp.push({ value: item.id, label: `${item.id} - ${capitalizeInitials(item.razao_social)}` })
                //({ value: item.id, label: item.fisica_juridica == 'F' ? item.id + ' - ' + item.razao_social : item.id + ' - ' + item.razao_social + ' / ' + item.fantasia })
            });
            setOptionPessoaConta(opcoesPessoasContaTemp)

            return opcoesPessoasContaTemp
        } catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const buscaCategorias = async (tipo) => {
        try {
            const complementoUrl = `?tipo=${tipo}`
            const url = `${rotafechada()}categoria/listarordenado${complementoUrl}`

            let retorno = await api.get(encodeURI(url))
            const { data } = retorno.data
            let opcoesCategoriaContaTemp = [{ value: -1, label: '<Escolha uma Categoria>' }];

            data.map((item) => {
                opcoesCategoriaContaTemp.push({ value: item.id, label: item.nome, mae: item?.filho ? true : false, })
            });
            setOptionCategoriaConta(opcoesCategoriaContaTemp)

            return opcoesCategoriaContaTemp
        } catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`)
        }
    }

    const buscaRelatoriosPersonalizados = async () => {
        try {
            let complementoUrl = '';

            if (valorPesquisa) {
                complementoUrl = `&nome=%${valorPesquisa}%`;
            }

            const url = `${rotafechada()}relatorios_personalizados?filtro_externo=N&ativo=S&empresa_id=1&empresa_id=${sessaolocal.empresa_id}${complementoUrl}`
            let retorno = await api.get(encodeURI(url))
            const { data } = retorno.data
            let relatoriosPersonalizadosTemp = [];

            data.map((item) => {
                console.log('item.Personalizacao_Relatorios_Personalizados', item.Personalizacao_Relatorios_Personalizados)
                if (item.Personalizacao_Relatorios_Personalizados.length == 1) {
                    relatoriosPersonalizadosTemp.push({ value: item.id, label: `${capitalizeInitials(item.nome)}`, modulo: 'personalizados', categoria: item.Personalizacao_Relatorios_Personalizados[0].Categoria_Central_Rela, categoria_id: item.Personalizacao_Relatorios_Personalizados[0].categoria_central_rela })

                }
                if (item.Personalizacao_Relatorios_Personalizados.length > 1) {
                    item.Personalizacao_Relatorios_Personalizados.map((item_categoria) => {
                        if (item_categoria.empresa_id == sessaolocal.empresa_id)
                            relatoriosPersonalizadosTemp.push({ value: item.id, label: `${capitalizeInitials(item.nome)}`, modulo: 'personalizados', categoria: item_categoria.Categoria_Central_Rela, categoria_id: item_categoria.categoria_central_rela })
                    })
                }
            });
            setOptionPersonalizados(relatoriosPersonalizadosTemp)

            //return relatoriosPersonalizadosTemp
        } catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
        try {
            let url = `${rotafechada()}categoria_central_relatorios?limit=-1&listaglobal=true&order=nome`;
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesCategoriaCentralRelatoriosTemp = []

            data.map((item) => {
                opcoesCategoriaCentralRelatoriosTemp.push({ id: item.id, value: item.id, nome: item.nome })
            })
            setOptionsCategoriaCentralRelatorios(opcoesCategoriaCentralRelatoriosTemp);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleClickProximo = () => {

        let filtroTemp = filtro

        setFiltro(
            {
                labelOriginal: endOfMonth(addMonths(filtroTemp.dataInicialOriginal, 1)),
                labelFormatada: format(endOfMonth(addMonths(filtroTemp.dataInicialOriginal, 1)), "MMMM 'de' yyyy", { locale: ptBR }),

                dataInicialOriginal: addMonths(filtroTemp.dataInicialOriginal, 1),
                dataInicialFormatada: format(addMonths(filtroTemp.dataInicialOriginal, 1), "yyyy-MM-dd 00:00:00", { locale: ptBR }),

                dataFinalOriginal: endOfMonth(addMonths(filtroTemp.dataFinalOriginal, 1)),
                dataFinalFormatada: format(endOfMonth(addMonths(filtroTemp.dataFinalOriginal, 1)), "yyyy-MM-dd 23:59:59", { locale: ptBR }),

                //descricaoFatura: `Pagamento fatura ${format(endOfMonth(addMonths(filtroTemp.dataInicialOriginal, 1)), "MMMM 'de' yyyy", { locale: ptBR })}`
            }
        )
    }

    const handleClickAnterior = () => {

        let filtroTemp = filtro

        setFiltro(
            {
                labelOriginal: endOfMonth(subMonths(filtroTemp.dataInicialOriginal, 1)),
                labelFormatada: format(endOfMonth(subMonths(filtroTemp.dataInicialOriginal, 1)), "MMMM 'de' yyyy", { locale: ptBR }),

                dataInicialOriginal: subMonths(filtroTemp.dataInicialOriginal, 1),
                dataInicialFormatada: format(subMonths(filtroTemp.dataInicialOriginal, 1), "yyyy-MM-dd 00:00:00", { locale: ptBR }),

                dataFinalOriginal: endOfMonth(subMonths(filtroTemp.dataFinalOriginal, 1)),
                dataFinalFormatada: format(endOfMonth(subMonths(filtroTemp.dataFinalOriginal, 1)), "yyyy-MM-dd 23:59:59", { locale: ptBR }),

                //descricaoFatura: `Pagamento fatura ${format(endOfMonth(subMonths(filtroTemp.dataInicialOriginal, 1)), "MMMM 'de' yyyy", { locale: ptBR })}`

            }
        )

    }

    const handleClickRelatorio = async () => {
        let data = new Date()
        const offSet = data.getTimezoneOffset()
        const relatoriosConta = [1, 2, 3, 4]

        // if (relatoriosConta.includes(openFiltroConta.relatorio_id)) { //relatorios de contas
        //     let data1 = formatDate(dataInicial, "YYYY-MM-DD 00:00:00")
        //     let data2 = formatDate(dataFinal, "YYYY-MM-DD 23:59:59")
        //     let titulo = 'CONTAS'
        //     let tipo = ''
        //     if ((openFiltroConta.relatorio_id == 1) || (openFiltroConta.relatorio_id == 3)) {
        //         tipo = 'D'
        //         if (openFiltroConta.relatorio_id == 1) { titulo += ' A PAGAR' }
        //         if (openFiltroConta.relatorio_id == 3) { titulo += ' PAGAS' }
        //     } else {
        //         tipo = 'R'
        //         if (openFiltroConta.relatorio_id == 2) { titulo += ' A RECEBER' }
        //         if (openFiltroConta.relatorio_id == 4) { titulo += ' RECEBIDAS' }

        //     }

        //     let url = `${rotafechada()}imprimir/financeiro/contas?titulo=${titulo}&tipo=${tipo}&datainicio=${new Date(data1)}&datafim=${new Date(data2)}&empresa_id=${sessaolocal.empresa_id}&timeoffset=${offSet}`

        //     if ((openFiltroConta.relatorio_id == 1) || (openFiltroConta.relatorio_id == 2)) { //pagar ou receber
        //         url += '&comsaldo=S'
        //     }
        //     if ((openFiltroConta.relatorio_id == 3) || (openFiltroConta.relatorio_id == 4)) { //pagas ou recebidas
        //         url += '&comsaldo=N'
        //     }

        //     if ((pessoa.value) && (pessoa.value != '') && (pessoa.value != -1)) {
        //         url += `&pessoa_id=${pessoa.value}`
        //     }

        //     if ((categoria.value) && (categoria.value != '') && (categoria.value != -1)) {
        //         url += `&categoria_id=${categoria.value}`
        //     }
        //     //console.log('URL', url)

        //     setOpenFiltroConta({ visible: false, relatorio_id: null })
        //     let retorno
        //     try {
        //         retorno = await api.get(url, {
        //             responseType: 'arraybuffer'
        //         });

        //     } catch (error) {
        //         const response = error.response;
        //         // Verifica se a propriedade response está definida
        //         if (response) {
        //             // Acessa a propriedade data do objeto de resposta
        //             const data = response.data;
        //             // Converte o ArrayBuffer para uma string e depois em JSON
        //             const msg = JSON.parse(new TextDecoder().decode(data));
        //             // Exibe a mensagem no console
        //             //console.log('teste',msg.msg);
        //             setPessoa(optionPessoaConta[0])
        //             setCategoria(optionCategoriaConta[0])
        //             setDataInicial(incMonth(new Date(), -1));
        //             setDataFinal(new Date());

        //             MensagemErro(`Erro: ${msg.msg}`);
        //         } else {
        //             // Exibe o erro no console
        //             console.log(error);
        //             MensagemErro(`Erro: Não foi possível gerar relatório`);
        //         }
        //         return
        //     }

        //     //console.log(retorno)

        //     //let tmp = new TextDecoder().decode(retorno.data)

        //     //console.log('Retorno', parseJSON(tmp))

        //     const file = new Blob([retorno.data], { type: 'application/pdf' });
        //     const fileURL = URL.createObjectURL(file);
        //     window.open(fileURL);
        //     setPessoa(optionPessoaConta[0])
        //     setCategoria(optionCategoriaConta[0])
        //     setDataInicial(incMonth(new Date(), -1));
        //     setDataFinal(new Date());
        // }

        if (openFiltroExtrato.relatorio_id == 5) { //relatório de movimentações
            let data1 = formatDate(dataInicial, "YYYY-MM-DD 00:00:00")
            let data2 = formatDate(dataFinal, "YYYY-MM-DD 23:59:59")

            setOpenFiltroExtrato({ visible: false, relatorio_id: null })
            const retorno = await api.get(`${rotafechada()}imprimir/financeiro/extrato?banco_id=${banco.value}&data1=${new Date(data1)}&data2=${new Date(data2)}&empresa_id=${sessaolocal.empresa_id}&timeoffset=${offSet}`, {
                responseType: 'arraybuffer'
            });

            const file = new Blob([retorno.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            setBanco({ value: -1, label: '<Escolha uma opção>' })
            setDataInicial(incMonth(new Date(), -1));
            setDataFinal(new Date());
        }

        if (openFiltroFatura.relatorio_id == 6) { //relatorio de fatura do cartão
            let data1 = filtro.dataInicialFormatada
            let data2 = filtro.dataFinalFormatada
            setOpenFiltroFatura({ visible: false, relatorio_id: null })
            const retorno = await api.get(`${rotafechada()}imprimir/financeiro/fatura?banco_id=${banco?.value}&data1=${new Date(data1)}&data2=${new Date(data2)}&empresa_id=${sessaolocal.empresa_id}&timeoffset=${offSet}`, {
                responseType: 'arraybuffer'
            });

            const file = new Blob([retorno.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            setBanco({ value: -1, label: '<Escolha uma opção>' })
        }

        if (openFiltroReciboAvulso.relatorio_id == 7) { //relatório de recibo avulso
            const dadosRecibo = {
                dataRecibo,
                recebiDeRecibo,
                valorRecibo,
                referenteRecibo,
                recebedorRecibo
            }
            // let data1 = formatDate(dataInicial, "YYYY-MM-DD 00:00:00")
            // let data2 = formatDate(dataFinal, "YYYY-MM-DD 23:59:59")

            setOpenFiltroReciboAvulso({ visible: false, relatorio_id: null })
            const retorno = await api.get(`${rotafechada()}imprimir/financeiro/recibo_avulso?empresa_id=${sessaolocal.empresa_id}
            &data=${dataRecibo}
            &recebi_de=${recebiDeRecibo}
            &valor=${valorRecibo}
            &referente=${referenteRecibo}
            &recebedor=${recebedorRecibo}
            `, {
                responseType: 'arraybuffer'
            });

            const file = new Blob([retorno.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);

            setDataRecibo(new Date());
            setRecebiDeRecibo('')
            setValorRecibo('')
            setReferenteRecibo('')
            setRecebedorRecibo(sessaolocal.fantasia)
        }
    };

    const onCloseDialogoOpcoes = (value) => {

        setFiltrosPersonalizados({ ...filtrosPersonalizados, open: false, })

    }

    const handleRightClick = (value) => (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setRelatorioPersonalizadoId(value)
    };

    const handleSubMenuOpen = (event) => {
        event.preventDefault();
        setSubMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSubMenuAnchorEl(null);
    };

    const moverPara = async (categoria_central_relatorio_id, relatorio_personalizado_id) => {
        try {
            MostraTelaEspera('Movendo relatorio..');
            await api.put(`${rotafechada()}relatorios_personalizados/mover/${relatorio_personalizado_id}`, { categoria_central_relatorio_id });
            MensagemAviso(`Relatorio: ${relatorio_personalizado_id} movido com sucesso! `);
            setAnchorEl(null);
            setSubMenuAnchorEl(null);
            buscaRelatoriosPersonalizados()
            FechaTelaEspera();

        } catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const alternarTodos = () => {
        let newState = {};
        optionsCategoriaCentralRelatorios.forEach((item) => {
            newState[item.id] = !todosAbertos;
        });
        setOpenCollapse(newState);
        setTodosAbertos(!todosAbertos);
    };

    const desenhaFiltro = () => {
        return (
            <Collapse in={openFiltrosAvancados} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                    < Grid container spacing={2} justifyContent="center" >
                        <Grid item xs={12} sm={10}> {/*Valor a ser Pesquisado*/}
                            <FormField type="text" label="Valor a ser Pesquisado" value={valorPesquisa} onChange={(e) => setValorPesquisa(e.target.value)}

                                inputProps={
                                    {
                                        onKeyPress: (e) => {

                                            if (e.key === 'Enter') {
                                                buscaRelatoriosPersonalizados();
                                            }
                                        },
                                    }
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} > {/*Botão do Filtro*/}
                            <MDButton onClick={buscaRelatoriosPersonalizados} color='info'> Pesquisar</MDButton>
                        </Grid>
                    </Grid >
                </Box>
            </Collapse>
        )
    }

    useEffect(async () => {
        if (openFiltroExtrato.visible == true || openFiltroFatura.visible == true) {
            buscaBanco();
        }
    }, [openFiltroExtrato, openFiltroFatura])

    useEffect(async () => {
        if (openFiltroConta.visible == true) {
            buscaPessoas()
            buscaCategorias(categoriaTipo)
        }
    }, [openFiltroConta])

    useEffect(async () => {
        buscaRelatoriosPersonalizados()
    }, [])

    useEffect(() => {
        const initialCollapseState = {};
        optionsCategoriaCentralRelatorios.forEach(item => {
            initialCollapseState[item.id] = true;
        });
        setOpenCollapse(initialCollapseState);
    }, [optionsCategoriaCentralRelatorios]);

    return (
        <>

            <DialogoFiltrosRelatorio
                open={filtrosPersonalizados.open || false}
                onClose={onCloseDialogoOpcoes}
                options={filtrosPersonalizados.itens || []}
                relatorio_id={filtrosPersonalizados.relatorio_id}
                configuracao={filtrosPersonalizados.configuracao}
                impressao={filtrosPersonalizados.impressao}
            />

            <Dialog //filtro extrato banco/caixa
                maxWidth="xl"
                open={openFiltroExtrato.visible}
                onClose={() => {
                    setOpenFiltroExtrato({ visible: false, relatorio_id: null })
                    setBanco({ value: -1, label: '<Escolha uma opção>' })
                    setDataInicial(incMonth(new Date(), -1));
                    setDataFinal(new Date());
                }}
            >
                <DialogTitle>{'Filtros do Relatório'}</DialogTitle>

                <DialogContent dividers>

                    <Box display='flex' mt={2} mb={2}> {/*Periodo*/}
                        <Box mr={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                <DesktopDatePicker
                                    label='Data Inicial'
                                    inputFormat="dd/MM/yyyy"
                                    value={dataInicial}
                                    onChange={(e) => setDataInicial(e)}
                                    renderInput={(params) => <TextField {...params} required
                                        helperText={dataInicial > 0 ? false :
                                            <>
                                                <CancelIcon color={'error'} />
                                                <Typography pl={0.5} fontWeight="small" variant="caption" color={'error'}>Campo obrigatório</Typography>
                                            </>
                                        }
                                    />}
                                />
                            </LocalizationProvider>
                        </Box>

                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                            <DesktopDatePicker
                                label='Data Final'
                                inputFormat="dd/MM/yyyy"
                                value={dataFinal}
                                onChange={(e) => setDataFinal(e)}
                                renderInput={(params) => <TextField {...params} required
                                    helperText={dataFinal > 0 ? false :
                                        <>
                                            <CancelIcon color={'error'} />
                                            <Typography pl={0.5} fontWeight="small" variant="caption" color={'error'}>Campo obrigatório</Typography>
                                        </>
                                    }
                                />}
                            />
                        </LocalizationProvider>
                    </Box>
                    <AutoCompleteStyled
                        disableClearable //Retira o X (clear)
                        options={optionBancoExtrato}
                        getOptionLabel={(option) => option.label || ""}
                        renderInput={(params) => <FormField {...params} label="Banco ou Caixa:" required />}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        value={banco}
                        onChange={(event, newValue) => {
                            setBanco(newValue);
                        }}
                    />

                </DialogContent>

                <DialogActions>
                    <MDButton
                        onClick={() => {
                            setOpenFiltroExtrato({ visible: false, relatorio_id: null })
                            setBanco({ value: -1, label: '<Escolha uma opção>' })
                            setDataInicial(incMonth(new Date(), -1));
                            setDataFinal(new Date());
                        }}
                        variant="outlined"
                        color="secondary">
                        Cancelar
                    </MDButton>
                    <MDButton
                        disabled={banco.value == -1 || banco?.length == 0 || !(dataInicial > 0) || !(dataFinal > 0) ? true : false}
                        onClick={handleClickRelatorio}
                        variant="gradient"
                        color={'info'}
                        sx={{ height: "100%" }}>
                        Confirmar
                    </MDButton>
                </DialogActions>
            </Dialog >

            <Dialog //filtro fatura cartão credito
                maxWidth="xl"
                open={openFiltroFatura.visible}
                onClose={() => {
                    setOpenFiltroFatura({ visible: false, relatorio_id: null })
                    setBanco({ value: -1, label: '<Escolha uma opção>' })
                }}
            >
                <DialogTitle>{'Filtros do Relatório'}</DialogTitle>

                <DialogContent dividers>

                    <Box display='flex' mt={2} mb={2}> {/*Periodo*/}
                        <Box justifyContent='center' width='100%' display='flex' alignItems='center' flexDirection='row'>

                            <Tooltip title="Anterior" placement="top" disableInteractive>
                                <MDButton
                                    onClick={handleClickAnterior}
                                    variant="contained"
                                    color={'info'}
                                    sx={{ borderBottomLeftRadius: '3px', borderTopLeftRadius: '3px', borderBottomRightRadius: '0px', borderTopRightRadius: '0px', borderColor: "#c9d3dd" }}
                                >
                                    <ArrowBackIosIcon />
                                </MDButton>
                            </Tooltip>
                            <Box pl={0.2} pr={0.2}>
                                <MDButton
                                    variant="contained"
                                    color={'info'}
                                    sx={{ borderRadius: '0px', borderColor: 'white', cursor: "not-allowed" }}
                                >
                                    {filtro.labelFormatada}
                                </MDButton>
                            </Box>
                            <Tooltip title="Próximo" placement="top" disableInteractive>
                                <MDButton
                                    onClick={handleClickProximo}
                                    variant="contained"
                                    color={'info'}
                                    sx={{ borderBottomRightRadius: '3px', borderTopRightRadius: '3px', borderBottomLeftRadius: '0px', borderTopLeftRadius: '0px' }}>
                                    <ArrowForwardIosIcon />
                                </MDButton>
                            </Tooltip>
                        </Box>
                    </Box>
                    <AutoCompleteStyled
                        disableClearable //Retira o X (clear)
                        options={optionBancoFatura}
                        getOptionLabel={(option) => option.label || ""}
                        renderInput={(params) => <FormField {...params} label="Cartão de crédito:" required />}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        value={banco}
                        onChange={(event, newValue) => {
                            setBanco(newValue);
                        }}
                    />

                </DialogContent>

                <DialogActions>
                    <MDButton
                        onClick={() => {
                            setOpenFiltroFatura({ visible: false, relatorio_id: null })
                            setBanco({ value: -1, label: '<Escolha uma opção>' })
                        }}
                        variant="outlined"
                        color="secondary">
                        Cancelar
                    </MDButton>
                    <MDButton
                        disabled={banco?.length == 0 || banco?.value == -1 || banco == {} ? true : false}
                        onClick={handleClickRelatorio}
                        variant="gradient"
                        color={'info'}
                        sx={{ height: "100%" }}>
                        Confirmar
                    </MDButton>
                </DialogActions>
            </Dialog >

            <Dialog //filtro Contas pagar e receber, pago e recebido
                maxWidth="xl"
                open={openFiltroConta.visible}
                onClose={() => {
                    setOpenFiltroConta({ visible: false, relatorio_id: null })
                    setDataInicial(incMonth(new Date(), -1));
                    setDataFinal(new Date());
                }}
            >

                <DialogTitle>{`Filtros do Relatório - Contas ${openFiltroConta.relatorio_id == 1 ? ' a Pagar' : openFiltroConta.relatorio_id == 2 ? ' a Receber' : openFiltroConta.relatorio_id == 3 ? ' Pagas' : openFiltroConta.relatorio_id == 4 ? ' Recebidos' : ''}`}</DialogTitle>

                <DialogContent dividers>

                    <Box display='flex' mt={2} mb={2}> {/*Periodo*/}
                        <Box mr={2}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                <DesktopDatePicker
                                    label='Data Inicial'
                                    inputFormat="dd/MM/yyyy"
                                    value={dataInicial}
                                    onChange={(e) => setDataInicial(e)}
                                    renderInput={(params) => <TextField {...params} required
                                        helperText={dataInicial > 0 ? false :
                                            <>
                                                <CancelIcon color={'error'} />
                                                <Typography pl={0.5} fontWeight="small" variant="caption" color={'error'}>Campo obrigatório</Typography>
                                            </>
                                        }
                                    />}
                                />
                            </LocalizationProvider>
                        </Box>

                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                            <DesktopDatePicker
                                label='Data Final'
                                inputFormat="dd/MM/yyyy"
                                value={dataFinal}
                                onChange={(e) => setDataFinal(e)}
                                renderInput={(params) => <TextField {...params} required
                                    helperText={dataFinal > 0 ? false :
                                        <>
                                            <CancelIcon color={'error'} />
                                            <Typography pl={0.5} fontWeight="small" variant="caption" color={'error'}>Campo obrigatório</Typography>
                                        </>
                                    }
                                />}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box marginBottom={2}>
                        <AutoCompleteStyled
                            disableClearable //Retira o X (clear)
                            options={optionPessoaConta}
                            //defaultValue={{value: optionPessoaConta[0].value, value: optionPessoaConta[0].label}}
                            getOptionLabel={(option) => option.label || ""}
                            renderInput={(params) => <FormField {...params} label={categoriaTipo == 'R' ? 'Cliente' : 'Fornecedor'} required />}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={pessoa}
                            onChange={(event, newValue) => {
                                //console.log('**** pessoa',newValue)
                                setPessoa(newValue);
                            }}
                        />
                    </Box>
                    <Box>
                        <AutoCompleteStyled
                            disableClearable //Retira o X (clear)
                            options={optionCategoriaConta}
                            getOptionLabel={(option) => option.label || ""}
                            getOptionDisabled={(option) => option.mae === true}
                            renderInput={(params) => <FormField {...params} label="Categoria:" required />}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {option.mae === true ?
                                        <Typography variant="subtitle" style={{ fontWeight: '900', color: 'black' }}>{option.label}</Typography>
                                        :
                                        <Typography variant="subtitle" style={{ font: 'inherit' }}>{`   ${option.label}`}</Typography>
                                    }
                                </Box>
                            )}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={categoria}
                            onChange={(event, newValue) => {
                                setCategoria(newValue);
                            }}
                        />
                    </Box>

                </DialogContent>

                <DialogActions>
                    <MDButton
                        onClick={() => {
                            setOpenFiltroConta({ visible: false, relatorio_id: null })
                            setPessoa(optionPessoaConta[0])
                            setCategoria(optionCategoriaConta[0])
                            setDataInicial(incMonth(new Date(), -1));
                            setDataFinal(new Date());
                        }}
                        variant="outlined"
                        color="secondary">
                        Cancelar
                    </MDButton>
                    <MDButton
                        //disabled={banco?.length == 0 || !(dataInicial > 0) || !(dataFinal > 0) ? true : false}
                        onClick={handleClickRelatorio}
                        variant="gradient"
                        color={'info'}
                        sx={{ height: "100%" }}>
                        Confirmar
                    </MDButton>
                </DialogActions>
            </Dialog >

            <Dialog //filtro recibo avulso
                maxWidth="xl"
                open={openFiltroReciboAvulso.visible}
                onClose={() => {
                    setOpenFiltroReciboAvulso({ visible: false, relatorio_id: null })
                    setDataRecibo(new Date());
                    setRecebiDeRecibo('')
                    setValorRecibo('')
                    setReferenteRecibo('')
                    setRecebedorRecibo('')
                }}
            >
                <DialogTitle>{'Filtros do Recibo Avulso'}</DialogTitle>

                <DialogContent dividers>
                    <MDBox bgColor={'#fff'}> {/* CONTA */}
                        <Grid container spacing={1.5}>
                            <Grid item xs={12} sm={2}> {/* DATA */}
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                    <DesktopDatePicker
                                        requiresd={true}
                                        label='Data'
                                        inputFormat="dd/MM/yyyy"
                                        value={dataRecibo}
                                        onChange={(e) => setDataRecibo(e)}
                                        renderInput={(params) => <FormField {...params} required />}
                                    />
                                </LocalizationProvider>

                            </Grid>
                            <Grid item xs={12} sm={5}> {/* RECEBI DE */}
                                <FormField
                                    required={true}
                                    type="text"
                                    label="Recebi de"
                                    value={recebiDeRecibo}
                                    onChange={(e) => setRecebiDeRecibo(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}> {/* VALOR*/}
                                <CurrencyInput
                                    autoFocus
                                    placeholder="0,00"
                                    required={true}
                                    label="Valor"
                                    value={valorRecibo}
                                    //onChange={(e) => setValor(e.target.value)}
                                    onChange={(e) => {
                                        const tempValor = parseFloat(e.target.value.replace(/\./g, '').replace(',', '.'))
                                        setValorRecibo(tempValor)
                                    }}
                                    InputProps={{
                                        //inputMode: 'numeric',
                                        //pattern: '[0-9]*',
                                        startAdornment: <InputAdornment sx={{ width: '5%' }} position="start">R$</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}> {/* REFERENTE */}
                                <FormField
                                    required={true}
                                    type="text"
                                    label="Referente"
                                    value={referenteRecibo}
                                    onChange={(e) => setReferenteRecibo(e.target.value)}
                                    rows={3}
                                    multiline={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}> {/* RECEBEDOR */}
                                <FormField
                                    required={true}
                                    type="text"
                                    label="Recebedor (Assinatura)"
                                    value={recebedorRecibo}
                                    onChange={(e) => setRecebedorRecibo(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </MDBox>

                </DialogContent>

                <DialogActions>
                    <MDButton
                        onClick={() => {
                            setOpenFiltroReciboAvulso({ visible: false, relatorio_id: null })
                            setDataRecibo(new Date());
                            setRecebiDeRecibo('')
                            setValorRecibo('')
                            setReferenteRecibo('')
                            setRecebedorRecibo(sessaolocal.fantasia)
                        }}
                        variant="outlined"
                        color="secondary">
                        Cancelar
                    </MDButton>
                    <MDButton
                        //disabled={banco.value == -1 || banco?.length == 0 || !(dataInicial > 0) || !(dataFinal > 0) ? true : false}
                        onClick={handleClickRelatorio}
                        variant="gradient"
                        color={'info'}
                        sx={{ height: "100%" }}>
                        Confirmar
                    </MDButton>
                </DialogActions>
            </Dialog >

            <Menu //Menu para mover os relatorios de categoria
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem endIcon={<KeyboardArrowDownIcon />} onMouseOver={handleSubMenuOpen}>
                    Mover para
                    <ListItemIcon>
                        <ChevronRightIcon fontSize="small" />
                    </ListItemIcon>
                </MenuItem>
            </Menu>

            <Menu //SubMenu para mover os relatorios de categoria
                anchorEl={subMenuAnchorEl}
                keepMounted
                open={Boolean(subMenuAnchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >

                {optionsCategoriaCentralRelatorios.map((item) => {
                    const categoria_id = optionsPersonalizados.find(option => option.value === relatorioPersonalizadoId)?.categoria_id;
                    if (item.id !== categoria_id) {
                        return (
                            <MenuItem onClick={() => { moverPara(item.id, relatorioPersonalizadoId) }}>{item.nome}</MenuItem>
                        )
                    }
                })}

            </Menu>

            <DashboardLayout>
                <DashboardNavbar />
                <MDBox mt={5} mb={9}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} lg={12}>
                            <MDBox mt={0} mb={0} textAlign="center">
                                <MDBox mb={1}>
                                    <MDTypography variant="h3" fontWeight="bold">
                                        {`Relatórios`}
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                            <Card>
                                <MDBox mt={1} mb={0} mx={2}>
                                    <Tabs value={aba} onChange={handleChangeAba} aria-label={`Aba de Relatórios`}>
                                        <Tab label="Financeiro" {...a11yProps(0)} />
                                        <Tab label="Personalizados" {...a11yProps(1)} />
                                    </Tabs>

                                </MDBox>

                                <TabPanel value={aba} index={0}>{/* Financeiro */}
                                    {options.map((option, index) => (
                                        option.modulo == 'financeiro' ?
                                            <Typography m={1} onClick={() => { handleclickFiltroRelatorio(option.value) }} sx={{ cursor: "pointer" }}><DescriptionIcon />{` ${option?.label}`}</Typography>
                                            : ''
                                    ))}
                                </TabPanel>
                                <TabPanel value={aba} index={1}> {/* Personalizados guilherme*/}
                                    {/*Colocar filtros aqui */}
                                    <MDBox width="100%" display="flex" justifyContent="flex-start">
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => setOpenFiltrosAvancados(!openFiltrosAvancados)}
                                        >
                                            {openFiltrosAvancados ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            <Typography>Filtros</Typography>
                                        </IconButton>

                                    </MDBox>
                                    <MDBox p={2} >
                                        <MDTypography variant="h5" fontWeight="medium">
                                            {desenhaFiltro()}
                                        </MDTypography>
                                    </MDBox>
                                    {/*Abre/fecha categorias */}
                                    <MDButton variant='outlined' color="secondary" size="small" onClick={alternarTodos} sx={{ borderRadius: '4px', height: '25px', minHeight: '12px' }}>
                                        {todosAbertos ? 'Fechar todos' : 'Abrir todos'}
                                        {todosAbertos ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
                                    </MDButton>

                                    {optionsCategoriaCentralRelatorios.map((item) => (
                                        <div key={item.id}> {/* Certifique-se de que 'item' tenha uma propriedade única 'id' */}
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => {
                                                    setOpenCollapse(prevState => ({
                                                        ...prevState,
                                                        [item.id]: !prevState[item.id]
                                                    }));
                                                }}
                                            >
                                                {openCollapse[item.id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                <Typography>{item.nome}</Typography>
                                            </IconButton>
                                            <Collapse in={openCollapse[item.id] || false} timeout="auto" unmountOnExit>
                                                {optionsPersonalizados.map((option) => (
                                                    option.modulo == 'personalizados' && option.categoria == item.nome ?
                                                        <Typography pl={3} m={0.5}
                                                            fontWeight="small"
                                                            variant="h6"
                                                            onContextMenu={handleRightClick(option.value)}
                                                            onClick={() => { handleClickFiltroRelatorioPersonalizado(option.value) }}
                                                            sx={{ cursor: "pointer" }}>
                                                            <DescriptionIcon />{` ${option?.label}`}</Typography>
                                                        : ''
                                                ))}
                                            </Collapse>
                                        </div>
                                    ))}
                                </TabPanel>

                                {/* <TabPanel value={aba} index={1}> // Orçamento/Venda
                                    {options.map((option, index) => (
                                        option.modulo == 'orcamento' ?
                                            <Typography m={1} onClick={() => { alert(`Relatório: ${option.label}`) }} sx={{ cursor: "pointer" }}>{`• ${option?.label}`}</Typography>
                                            : ''
                                    ))}
                                </TabPanel> */}
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
                <Footer />
            </DashboardLayout>
        </>
    );
}

export default Relatorios;
