import { createContext, useState} from 'react'

const AuthContext = createContext()

export default AuthContext;

export const AuthProvider = ({ children }) => {

    const dadoslocalstorage = JSON.parse(localStorage.getItem('dadossessao'));

    const [sessaolocal, setSessaoLocal] = useState({
        autenticado: dadoslocalstorage?.autenticado ? dadoslocalstorage.autenticado : false,
        token: dadoslocalstorage?.token ? dadoslocalstorage.token : '',
        refreshToken: dadoslocalstorage?.refreshToken ? dadoslocalstorage.refreshToken : '',
        empresa_usuario_id: dadoslocalstorage?.empresa_usuario_id ? dadoslocalstorage.empresa_usuario_id : '',
        empresa_id: dadoslocalstorage?.empresa_id ? dadoslocalstorage.empresa_id : '',
        nome: dadoslocalstorage?.nome ? dadoslocalstorage.nome : '',
        fantasia: dadoslocalstorage?.fantasia ? dadoslocalstorage.fantasia : '',
        imagemPerfil: dadoslocalstorage?.imagemPerfil ? dadoslocalstorage.imagemPerfil : '',
        imagemEmpresa: dadoslocalstorage?.imagemEmpresa ? dadoslocalstorage.imagemEmpresa : '',
    })

    const getSessao = () => sessaolocal;

    const setSessao = (token = '', refreshToken = '', empresa_usuario_id = '',empresa_id='',configuracoes={}, nome='', fantasia='', imagemPerfil='', imagemEmpresa='' ) => {
        if ((token) && (empresa_usuario_id)) {

            const data={autenticado: true, token, refreshToken, empresa_usuario_id,empresa_id,configuracoes, nome, fantasia, imagemPerfil, imagemEmpresa}
            localStorage.removeItem('dadossessao');
            localStorage.setItem('dadossessao', JSON.stringify(data));
            setSessaoLocal({ ...sessaolocal, ...data})

        }
        else setSessaoLocal({ ...sessaolocal, autenticado: false, token: '', refreshToken: '', empresa_usuario_id: '',empresa_id:'',configuracoes:{}, nome:'', fantasia:'', imagemPerfil:'', imagemEmpresa:'' })
    }

    const logout = () => {
        localStorage.removeItem('dadossessao');
        setSessaoLocal({...sessaolocal,autenticado:false,token:'',refreshToken:'',empresa_usuario_id:'',empresa_id:'',configuracoes:{}, nome:'', fantasia:'', imagemPerfil:'', imagemEmpresa:''})
    }

    let contextAuthData = {
        getSessao,
        setSessao,
        logout

    }

    return (
        <AuthContext.Provider value={contextAuthData} >
            {children}
        </AuthContext.Provider>
    )
}
