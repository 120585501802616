import { Autocomplete, TextField, Box, Checkbox, Dialog, Tooltip, Typography } from '@mui/material';
import defaultImage from "assets/images/products/choose-product.png";
import MDInput from 'components/MDInput';
import MessagesContext from 'hooks/MessagesContext';
import React, { useContext, useEffect, useState } from 'react';
import { Image as KonvaImage, Transformer } from 'react-konva';
import useImage from 'use-image';
import MDBox from 'components/MDBox';
import { copiaProfundaObjetos } from 'commons/utils';

export const PropriedadesGrupo = (props) => {

    const { itensTela, setItensTela, selectedId, open, setOpen, fieldsDbLabel, confRelatorio, setConfRelatorio } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro } = messageContext;
    const [datasetSelecionado, setDatasetSelecionado] = useState(null);
    const [campoSelecionado, setCampoSelecionado] = useState(null);
    
    //filtro somente os campos do  dataset de repeticao
    const fieldsDatasetRepeticao=fieldsDbLabel.filter((item)=>item.repeticao==='S');
    const datasetsUnicos = [...new Set(fieldsDatasetRepeticao.map((opcao) => opcao.dataset))];
    
    const opcoesFiltradas = datasetSelecionado
        ? fieldsDbLabel.filter((opcao) => opcao.dataset === datasetSelecionado)
        : [];

    const handleChangeField = (newValue) => {
        // console.log(newValue);
        //const temp = itensTela.slice();
        const confRelatorioTemp=copiaProfundaObjetos(confRelatorio);
        // temp[selectedId].dataset = newValue?.dataset;
        // temp[selectedId].field = newValue?.value;

        debugger;

        //procura o grupo na confRelatorio 
        if (confRelatorioTemp.grupos){
            for (let i=0;i<confRelatorioTemp.grupos.length;i++){
                if (confRelatorioTemp.grupos[i].idGrupo===itensTela[selectedId].idGrupo){
                    confRelatorioTemp.grupos[i].dataset = newValue?.dataset;
                    confRelatorioTemp.grupos[i].field = newValue?.value;
                }
            }
        }

        setCampoSelecionado(newValue);
        //setItensTela(temp);
        setConfRelatorio(confRelatorioTemp);
    }

    useEffect(() => {

        //ao carregar preparo o states com o que ja estava configurado na DbLabel

        if (open !== true)
            return;

        const temp = itensTela.slice();
        let datasettemp;
        let fieldtemp;

        //procuro na confRelatorio o grupo correspondente
        const confRelatorioTemp=copiaProfundaObjetos(confRelatorio);
        if (confRelatorioTemp.grupos){
            for (let i=0;i<confRelatorioTemp.grupos.length;i++){
                if (confRelatorioTemp.grupos[i].idGrupo===temp[selectedId].idGrupo){
                    datasettemp=confRelatorioTemp.grupos[i].dataset;
                    fieldtemp = confRelatorioTemp.grupos[i].field;
                }
            }
        }

        if ((datasettemp)&&(fieldtemp)) {
            if (datasetsUnicos?.find((item) => item === datasettemp)) {
                setDatasetSelecionado(datasettemp);
                setCampoSelecionado({
                    label: fieldtemp,
                    dataset: datasettemp,
                    value: fieldtemp
                })
            }
            else setDatasetSelecionado(null);
        }
        else {
            setDatasetSelecionado(null);
            setCampoSelecionado(null);
        }

        // if (temp[selectedId].dataset) {
        //     if (datasetsUnicos?.find((item) => item === temp[selectedId].dataset)) {
        //         setDatasetSelecionado(temp[selectedId].dataset);
        //         setCampoSelecionado({
        //             label: temp[selectedId].field,
        //             dataset: temp[selectedId].dataset,
        //             value: temp[selectedId].field
        //         })
        //     }
        //     else setDatasetSelecionado(null);
        // }
        // else {
        //     setDatasetSelecionado(null);
        //     setCampoSelecionado(null);
        // }

    }, [open])

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            maxWidth={'md'}
            fullWidth
        >
            <Box m={2} >

                <MDBox
                    mt={2}
                    fullWidth
                >
                    <Autocomplete
                        options={datasetsUnicos}
                        renderInput={(params) => <TextField {...params} label="Escolha o Dataset" />}
                        value={datasetSelecionado}
                        onChange={(event, newValue) => setDatasetSelecionado(newValue || null)}
                    />

                </MDBox>
                <MDBox
                    mt={2}
                    fullWidth
                >
                    <Autocomplete
                        options={opcoesFiltradas}
                        getOptionLabel={(opcao) => opcao.label}
                        renderInput={(params) => <TextField {...params} label="Escolha o Campo" />}
                        value={campoSelecionado} // Para redefinir a seleção ao escolher um novo dataset
                        onChange={(event, newValue) => {
                            handleChangeField(newValue);

                        }}
                    />

                </MDBox>
              

            </Box>

        </Dialog>
    )
}
