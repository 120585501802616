import { Autocomplete, Grid, Typography } from '@mui/material';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';


const Cabecalho = (props) => {
  const { control, opcoesAtivo, opcoesGrupo, getValues, setValue, tabelapreco } = props;


  const handleBlurMargem = () => {

    let custo = getValues(`valor_custo`);
    custo = `${custo}`.replace(',', '.');
    // console.log("Custo",custo);
    // console.log("Tabela de preço",tabelapreco);

    tabelapreco?.map((itempreco, key) => {
      let margem = getValues(`servico_precos[${key}].percentual_margem`);
      margem = `${margem}`.replace(',', '.');
    // console.log("Margem",margem);


      if (!parseFloat(custo) > 0) {
        custo = 0;
      }

      if (!parseFloat(margem) > 0) {
        margem = 0;
      }

      const valorsugerido = parseFloat(custo) + ((parseFloat(custo) * parseFloat(margem)) / 100);
      // console.log("ValorSugerido",valorsugerido);

      setValue(`servico_precos[${key}].sugerido`, `${valorsugerido}`);
    })

  }

  return (
    <MDBox p={2}>
      <MDBox mb={3}>
        <Typography variant='h6' >Dados de Serviços: {`${getValues('id') || ''}-${getValues('nome') || ''}`}</Typography>
      </MDBox>

      <Grid container spacing={1}>

        <Grid item xs={12} sm={12}>{/*dados de Serviços*/}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>{/*id*/}
              <Controller
                name={'id'}
                control={props.control}
                defaultValue=''
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    key={'id'}
                    id={'id'}
                    autoComplete={false}
                    type='text'
                    label='ID'
                    rows={1}
                    multiline={false}
                    required={false}
                    disabled={true}
                    inputProps={{
                      disabled: true
                    }}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>{/*Referencia*/}
              <Controller
                name={'referencia'}
                defaultValue=''
                control={props.control}
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    key={'referencia'}
                    id={'referencia'}
                    autoComplete={false}
                    type='text'
                    label='Referência'
                    rows={1}
                    multiline={false}
                    required={false}
                    disabled={false}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>
            <Grid item xs={12} sm={3}>{/*Ativo*/}
              <Controller
                name='ativo'
                control={control}
                defaultValue={{ value: 'S' }}
                render={({ field: { onChange, ...props } }) => (
                  <AutoCompleteStyled
                    options={opcoesAtivo}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => <FormField {...params} label='Ativo' />}
                    onChange={(_, data) => {
                      return onChange(data);
                    }}
                    disabled={false}
                    {...props}
                  />
                )}

              />
            </Grid>
            <Grid item xs={12} sm={9}>{/*Nome*/}
              <Controller
                name={'nome'}
                defaultValue=''
                control={props.control}
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    key={'nome'}
                    id={'nome'}
                    autoComplete={false}
                    type='text'
                    label='Nome'
                    rows={1}
                    multiline={false}
                    required={true}
                    disabled={false}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>
            <Grid item xs={12} sm={3}>{/*id externo*/}
              <Controller
                name={'id_externo'}
                defaultValue=''
                control={props.control}
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    key={'id_externo'}
                    id={'id_externo'}
                    autoComplete={false}
                    type='text'
                    label='ID Externo'
                    rows={1}
                    multiline={false}
                    required={false}
                    disabled={false}
                    inputRef={ref}
                    {...props}
                  />}
              />
            </Grid>
            <Grid item xs={12} sm={3}>{/*Valor de custo*/}
              <Controller
                name={'valor_custo'}
                defaultValue=''
                control={props.control}
                render={({ field: { ref, ...props } }) =>
                  <FormField

                    key={'valor_custo'}
                    id={'valor_custo'}
                    autoComplete={false}
                    type='text'
                    label='Valor de custo'
                    rows={1}
                    multiline={false}
                    required={true}
                    disabled={false}
                    //inputRef={ref}
                    inputProps={{
                      onKeyUp: (e) => {
                      },
                      onBlur: (e) => {
                        e.preventDefault();
                        handleBlurMargem();
                      },
                    }}
                    {...props}
                  />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>{/*Grupo*/}
              <Controller
                name='grupo_servico_id'
                control={control}
                defaultValue={{ value: -1 }}
                render={({ field: { onChange, ...props } }) => (
                  <AutoCompleteStyled
                    id='grupo_servico_id'
                    options={opcoesGrupo}
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    renderInput={(params) => <FormField required {...params}  label='Grupo' />}
                    onChange={(_, data) => {
                      return onChange(data);
                    }}
                    disabled={false}
                    required={true}
                    {...props}

                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  )
}

export default Cabecalho;