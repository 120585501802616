import MDBox from "components/MDBox";
import ContratoPadraoLayout from "./contratopadraolayout";
import bgImage from "assets/images/personalizados/headerlogin.png";
import { Grid, Typography } from "@mui/material";
import MDButton from "components/MDButton";
import { rotaaberta } from "commons/urlconf";
import api from "commons/api";
import { useContext } from "react";
import MessagesContext from "hooks/MessagesContext";

function GenEducacaoContratoPadrao() {

  const messageContext = useContext(MessagesContext);
  const { MensagemErro } = messageContext;

  const handleClickDownloadContratoPadrao = async () => {
    try {
      const url = `${rotaaberta()}gen/downloadcontratopadrao`;
      const resultado =await api({
        method:'GET',
        url,
        responseType: 'arraybuffer', // important
    });
    const nomearquivo='contratopadrao.pdf';
    const {headers} = resultado;
    var blob = new Blob([resultado.data],{type:headers['content-type']});
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = nomearquivo||'file';
    link.click();
    link.remove();



    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  return (
    <ContratoPadraoLayout image={bgImage}>
      <MDBox
        mx={2}
        mt={-3}
        p={3}
        mb={1}
      >
        <Grid container spacing={3}>

          <Grid item xs={10} sm={10}>

            <Typography variant='h3' mt={2} mb={2} >
              CONTRATO PADRÃO METODO GEN
            </Typography>
            <MDButton color='info' onClick={handleClickDownloadContratoPadrao}>Clique para fazer download ou visualzar</MDButton>

          </Grid>
        </Grid>
      </MDBox>
    </ContratoPadraoLayout >
  );
}

export default GenEducacaoContratoPadrao;
