import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MDButton from "components/MDButton";
import AuthContext from "hooks/AuthContext";
import MessagesContext from "hooks/MessagesContext";
import ProjetoTempera from "pages/desenhos/projetotempera";
import { useContext, useEffect, useRef, useState } from "react";
import TabelaEtapa3 from './tabela';
import ZoomInIcon from '@mui/icons-material/ZoomIn';


export default function Etapa3_ConfiguraDesenhos(props) {

    const { pedido_tempera_id, handleClose, vendaAtual, etapaAtual, setEtapaAtual, abortaProcesso, etapaAnterior } = props;
    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();
    //https://beta.reactjs.org/learn/manipulating-the-dom-with-refs#how-to-manage-a-list-of-refs-using-a-ref-callback
    const itemsRef = useRef(null);

    const [openProjetoSelecionado, setOpenProjetoSelecionado] = useState({ open: false });
    const [dadosProjetos, setDadosProjetos] = useState([]);
    const colunasProjetos = [
        {
            id: 'icones',
            disableOrder: true,
            numeric: false,
            disablePadding: true,
            label: ''
        },
        {
            id: 'pedido_tempera_id',
            numeric: false,
            disablePadding: true,
            disableOrder: true,
            label: 'Pedido Têmpera'
        },
        {
            id: 'nomeprojeto',
            numeric: false,
            disableOrder: true,
            disablePadding: true,
            label: 'Nome Projeto'
        },
        {
            id: 'ambiente_instalacao',
            disableOrder: true,
            numeric: false,
            disablePadding: true,
            label: 'Ambiente'
        },
    ]


    const buscaProjetosPedidoTempera = async () => {
        if (!(parseInt(pedido_tempera_id, 10) > 0)) {
            return;
        }

        try {
            MostraTelaEspera('Aguarde a busca dos dados...');
            const retorno = await api.get(encodeURI(`${rotafechada()}pedido_tempera_venda_projetos?pedido_tempera_id=${pedido_tempera_id}`));
            const { data } = retorno.data;

            if (!data.length > 0) {
                MensagemErro(`O pedido de têmpera não possui projetos!`);
                abortaProcesso();
                return;
            }

            const temp = [];
            data.map((item) => {

                temp.push({
                    icones: 
                    <MDButton onClick={() => handleClickSelecionaProjeto(item)}>
                        <ZoomInIcon fontSize='large' color='info' />
                    </MDButton>,
                    pedido_tempera_id: item.pedido_tempera_id,
                    nomeprojeto: `${item?.Venda_Projeto?.id}- Proj: ${item?.Venda_Projeto?.projeto_id}-${item?.Venda_Projeto?.Projeto?.nome}`,
                    ambiente_instalacao: item?.Venda_Projeto.ambiente_instalacao,

                    pedido_tempera_venda_projeto_id: item.id,
                    venda_projeto_id: item.venda_projeto_id,
                    projeto_id: item.Venda_Projeto?.projeto_id,
                    //desenho_tempera: item.desenho_tempera,
                })

            })

            setDadosProjetos(temp);
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            const erro = error.response?.data?.erro || '';
            console.log(erro);
            MensagemErro(`Erro ao tentar resgatar os dados dos projetos da Venda!${msg}`)
        }
    }

    const handleClickSelecionaProjeto = async (item = {}) => {

        setOpenProjetoSelecionado({
            open: true,
            pedido_tempera_venda_projeto_id: item?.id || -1,
            projeto_id: item?.Venda_Projeto?.projeto_id || -1,
            nomeprojeto: `ID: ${item?.venda_projeto_id} Projeto: ${item?.Venda_Projeto?.projeto_id}-${item?.Venda_Projeto?.Projeto?.nome || ''} | Ambiente: ${item?.Venda_Projeto.ambiente_instalacao || ''}`
        }
        )
    }

    useEffect(() => {
        buscaProjetosPedidoTempera();
    }, [pedido_tempera_id])

    const handleClickRetornar = async () => {
        setEtapaAtual(etapaAnterior);
    }

    const handleClickProximaEtapa = async () => {
        setEtapaAtual(etapaAtual + 1);
    }

    return (
        <>
            <Box>
                <Typography variant='h6' mb={2}>
                    Nesta etapa você pode visualizar e alterar os projetos antes da Impressão
                </Typography>
                <TabelaEtapa3
                    colunas={colunasProjetos}
                    linhas={dadosProjetos}
                />

            </Box>

            <Box mt={2} mb={2}>
                <Box display='flex' justifyContent={'space-between'}>
                    <MDButton color='info' onClick={handleClickRetornar}>Retornar</MDButton>
                    <MDButton color='info' onClick={handleClickProximaEtapa}>Avançar</MDButton>
                </Box>
            </Box>

            <Divider />
            <Box>
                {
                    openProjetoSelecionado?.open &&
                    <ProjetoTempera
                        projeto_id={openProjetoSelecionado?.projeto_id || -1}
                        pedido_tempera_venda_projeto_id={openProjetoSelecionado?.pedido_tempera_venda_projeto_id || -1}
                        nomeprojeto={openProjetoSelecionado?.nomeprojeto || ''}
                        itemsRef={itemsRef}
                    />
                }

            </Box>



        </>
    )


}