import React, { useContext, useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { Autocomplete, Checkbox, Grid, IconButton, Typography } from '@mui/material';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import SearchIcon from '@mui/icons-material/Search';

import MDTypography from 'components/MDTypography';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import MDButton from 'components/MDButton';
import AuthContext from 'hooks/AuthContext';
import TabelaPossibilidades from './tabela';
import DialogoConfirma from 'componentes/DialogoConfirma';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';


const PossibilidadesProjeto = (props) => {

  const { handleSubmit, control, setValue, getValues, projetoAtual, setFocus,
    setPossibilidadeProjetos
  } = props;

  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
 
  const [recarregaTabela, setRecarregaTabela] = useState(false);
  const [codigoAtual, setCodigoAtual] = useState('');

  const opcaoDefault = [{ id: -1, value: -1, label: '<Selecione...>' }];
  const [opcoesPossibilidades,setOpcoesPossibilidades]=useState(opcaoDefault);
  const ObjModeloProjetoPossibilidades = {
    id: { type: 'text' },
    possibilidade_projeto_id: { type: 'autocomplete' },
    obrigatorio: { type: 'checkbox' },
  }

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${rotafechada()}projeto_possibilidades/permissao/${operacao}`);
      return true;
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;

    // if (!data.nome) {
    //   camposObrigatorios = `${camposObrigatorios}/Nome`
    // }

    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const gravarRegistro = async (data) => {

    const dados = {};
    //no data esta todos os dados do RHF registrados, o que me interesse é 
    //apenas o objeto "produtos"
    //assim resgato no data apenas os campos que se referem a produtos
    //e monto o objeto que sera enviado para o back
    Object.keys(ObjModeloProjetoPossibilidades).forEach((nome) => {
      dados[nome] = '';
      if (nome in data['possibilidades']) {
        if (typeof data['possibilidades'][nome] === 'object') {
          dados[nome] = data['possibilidades'][nome]?.value ? data['possibilidades'][nome]?.value : '';
        }
        else
          if (typeof data['possibilidades'][nome] === 'boolean') {
            dados[nome] = data['possibilidades'][nome] === true ? 'S' : 'N'
          }
          else dados[nome] = data['possibilidades'][nome];
      }
    })

    dados.empresa_id = sessaolocal.empresa_id || null;
    dados.projeto_id = projetoAtual;
    delete dados.id;

    try {
      validaCampos(dados);
    }
    catch (error) {
      MensagemErro(`${error.message}`);
      return;
    }

    try {
      let retorno;
      let codigoTemp = data.possibilidades.id;

      if ((data.possibilidades.id) && (parseInt(data.possibilidades.id, 10) > 0)) {
        retorno = await api.put(`${rotafechada()}projeto_possibilidades/${data.possibilidades.id}`, dados);
      }
      else {
        retorno = await api.post(`${rotafechada()}projeto_possibilidades`, dados);
        codigoTemp = retorno.data.data.id;
      }

      MensagemAviso(`Gravado com sucesso! Componente: ${codigoTemp}`);
      setCodigoAtual('');
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaOpcoesPossibilidadeProjetos = async () => {

    try {
      let url = `${rotafechada()}possibilidades_projetos?limit=-1&ativo=S`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      let opcoesPossibilidadesTemp = [];
      opcoesPossibilidadesTemp.push({ ...opcaoDefault[0] });
      data.map((item) => {
        opcoesPossibilidadesTemp.push({ id: item.id, value: item.id, label: item.nome })
      })
      setOpcoesPossibilidades(opcoesPossibilidadesTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaOpcoes = async () => {
    await buscaOpcoesPossibilidadeProjetos();
  };

  const novoComponente = () => {
    //limpo os campos
    Object.keys(ObjModeloProjetoPossibilidades).forEach((nome) => {
      if (ObjModeloProjetoPossibilidades[nome].type === 'autocomplete') {
        setValue(`possibilidades.${nome}`, opcaoDefault[0])
      }
      else
        if (ObjModeloProjetoPossibilidades[nome].type === 'checkbox') {
          setValue(`possibilidades.${nome}`, false)
        }
        else
          if (ObjModeloProjetoPossibilidades[nome].type === 'text') {
            setValue(`possibilidades.${nome}`, '')
          }
    })
  }

  const abrirRegistro = async (codigo) => {
    try {
      let url = `${rotafechada()}projeto_possibilidades?id=${codigo}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      if (!data.length > 0) {
        MensagemErro(`Componente ID ${codigo} não localizado`);
        return;
      }
      setValue('possibilidades.id', data[0].id);
      setValue('possibilidades.obrigatorio', data[0].obrigatorio === 'S' ? true : false);
      setValue(`possibilidades.possibilidade_projeto_id`, { value: data[0].possibilidade_projeto_id, label: data[0].Possibilidades_Projeto?.nome });
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  };

  const excluirRegistro = async () => {

    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      await api.delete(`${rotafechada()}projeto_possibilidades/${dialogoOpen.id || 0}`);
      MensagemAviso('Excluído com sucesso!');
      novoComponente();
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  };

  const handleClickExcluir = async (id) => {
    
    if (!(await validaRotaPermissao('excluir') === true)) {
      return;
    }
    setDialogoOpen({ ...dialogoOpen, visible: true, id });
  }

  useEffect(() => {
    buscaOpcoes();
    if (parseInt(codigoAtual, 10) > 0) {
      abrirRegistro(codigoAtual);
    }
    else novoComponente();
  }, [projetoAtual, codigoAtual, recarregaTabela])


  return (
    <MDBox p={2} bgColor='#EAFAF1' mt={2}>

      <DialogoConfirma
        open={dialogoOpen.visible}
        title={'Confirma Exclusão'}
        color={'error'}
        message={'Certeza que deseja excluir?'}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
        handleConfirm={excluirRegistro}
      />

      <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => setOpenCollapse(!openCollapse)}
      >
        {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        <Typography variant='h6' mb={2}>Possibilidades</Typography>
      </IconButton>

      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>{/*id*/}
            <Controller
              name={`possibilidades.id`}
              control={props.control}
              defaultValue=''
              render={({ field: { ref, ...props } }) =>
                <FormField

                  autoComplete={false}
                  type='text'
                  label='Id'
                  rows={1}
                  multiline={false}
                  required={false}
                  disabled={true}
                  inputProps={{
                    disabled: true
                  }}
                  inputRef={ref}
                  {...props}
                />}
            />
          </Grid>
          <Grid item xs={12} sm={7}>{/*possibilidade_projeto_id*/}
            <Controller
              name='possibilidades.possibilidade_projeto_id'
              control={control}
              defaultValue={{ value: -1 }}
              render={({ field: { onChange, ...props } }) => (
                <AutoCompleteStyled
                  options={opcoesPossibilidades}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => <FormField {...params} label='Possibilidades do Projeto'/>}
                  onChange={(_, data) => {
                    return onChange(data);
                  }}
                  disabled={false}
                  {...props}

                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3}>{/*obrigatorio*/}
            <MDBox width="100%" display="flex" alignItems='center' flexDirection='column'>
              <MDTypography fontWeight="bold" variant="caption">
                {'Obrigatório'}
              </MDTypography>

              <Controller
                name={`possibilidades.obrigatorio`}
                control={control}
                render={({ field: { value, ...otherprops } }) => (
                  <Checkbox
                    {...otherprops}
                    checked={value ? (value === true ? true : false) : false}
                    onChange={(e) => setValue(`possibilidades.obrigatorio`, e.target.checked)}
                  />
                )}
              />
            </MDBox>
          </Grid>
        </Grid>

        <MDBox width="100%" display="flex" justifyContent="flex-end" mt={2}>
          <MDButton variant="gradient" color="info" onClick={() => handleSubmit(gravarRegistro)()}>Gravar</MDButton>
          <MDButton variant="gradient" color="info" onClick={novoComponente} sx={{ ml: 2 }}>Novo</MDButton>
        </MDBox>

        <MDBox width="100%" display="flex" mt={2}>
          <TabelaPossibilidades
            projetoAtual={projetoAtual}
            abrirRegistro={abrirRegistro}
            excluirRegistro={handleClickExcluir}
            recarregaTabela={recarregaTabela}
            setPossibilidadeProjetos={setPossibilidadeProjetos}
          />
        </MDBox>

      </Collapse>
    </MDBox>
  )
}

export default PossibilidadesProjeto;