import React, { useContext, useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import { Icon, Typography } from "@mui/material";
import MessagesContext from 'hooks/MessagesContext';
import AuthContext from "hooks/AuthContext";
import { useForm, useWatch } from 'react-hook-form';
import MDButton from 'components/MDButton';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import Cabecalho from './cabecalho';
import PossibilidadeProjetosOpcoes from './possibilidadeprojetosopcoes';

function Cadastro(props) {

  const { possibilidadeAtual, setPossibilidadeAtual, setAba } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const { handleSubmit, control, setValue, getValues, setFocus } = useForm({});

  const [opcoesAtivo, setOpcoesAtivo] = useState([
    //{ value: -1, label: '<Selecione uma opção>' },
    { value: 'S', label: 'Sim' },
    { value: 'N', label: 'Não' },
  ]);

  const [opcoesTipo, setOpcoesTipo] = useState([
    //{ value: -1, label: '<Selecione uma opção>' },
    { value: 'SIMPLES', label: 'Simples' },
    { value: 'OPCOES', label: 'Opções' },
  ]);

  const [recarregaComponenteOpcoes, setRecarregaComponenteOpcoes] = useState(false);

  const onSubmit = (data) => {
    handleClickGravar(data);
  }

  const limpaInputs = () => {
    setValue('id', '');
    setValue('referencia', '');
    setValue('nome', '');
    setValue(`ativo`, { value: 'S', label: 'Sim' });
    setValue(`tipo`, { value: 'SIMPLES', label: 'Simples' });
  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;

    if (!data.nome) {
      camposObrigatorios = `${camposObrigatorios}/Nome`
    }

    if (!data.ativo) {
      camposObrigatorios = `${camposObrigatorios}/Ativo`
    }

    if (!data.tipo) {
      camposObrigatorios = `${camposObrigatorios}/Tipo`
    }

    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const handleClickGravar = async (data) => {

    //tratando os campos autocomplete
    data.ativo = data.ativo?.value ? data.ativo.value : '';
    data.tipo = data.tipo?.value ? data.tipo.value : '';

    try {
      validaCampos(data);
    }
    catch (error) {
      MensagemErro(`${error.message}`);
      return;
    }

    try {
      const dados = { empresa_id: sessaolocal.empresa_id || null };

      //pegando do OBJ do RHF apenas os campos que sao do projeto
      dados.referencia = data.referencia;
      dados.nome = data.nome;
      dados.ativo = data.ativo;
      dados.tipo = data.tipo;

      let retorno;
      let codigoPossibilidade = '';

      if (parseInt(possibilidadeAtual, 10) > 0) {
        retorno = await api.put(`${rotafechada()}possibilidades_projetos/${possibilidadeAtual}`, dados);
        codigoPossibilidade = possibilidadeAtual;
        setRecarregaComponenteOpcoes(!recarregaComponenteOpcoes);
      }
      else {
        retorno = await api.post(`${rotafechada()}possibilidades_projetos`, dados);
        codigoPossibilidade = retorno.data.data.id;
      }

      MensagemAviso(`Gravado com sucesso! Possibilidade: ${codigoPossibilidade}`);

      //se for um novo produto entao "recarrego" a pagina
      if (codigoPossibilidade !== possibilidadeAtual) {
        setPossibilidadeAtual(codigoPossibilidade);
      }
    }
    catch (error) {
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const novapossibilidade = async () => {
    limpaInputs();
    setPossibilidadeAtual('');
  }

  const abrirPossibilidadeProjeto = async () => {
    // console.log('abrir possibilidade:', possibilidadeAtual);
    try {
      let url = `${rotafechada()}possibilidades_projetos?id=${possibilidadeAtual}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      if (!data.length > 0) {
        MensagemErro(`Possibilidade Projeto ${possibilidadeAtual} não localizado`);
        return;
      }
      //console.log("DATA",data)
      limpaInputs();

      setValue('id', data[0].id);
      setValue('referencia', data[0].referencia);
      setValue('nome', data[0].nome);
      setValue(`ativo`, { value: data[0].ativo === 'S' ? 'S' : 'N', label: data[0].ativo === 'S' ? 'Sim' : 'Não' });
      setValue(`tipo`, { value: data[0].tipo === 'SIMPLES' ? 'SIMPLES' : 'OPCOES', label: data[0].tipo === 'SIMPLES' ? 'Simples' : 'Opções' });
      //setValue('grupo_produto_id',data[0].id);
      setRecarregaComponenteOpcoes(!recarregaComponenteOpcoes);

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }


  useEffect(async () => {
    if (parseInt(possibilidadeAtual, 10) > 0) {
      await abrirPossibilidadeProjeto();
    }
    else {
      await novapossibilidade();
    }
  }, [possibilidadeAtual])


  // const tipoWatch = useWatch({
  //   control,
  //   name: "tipo",
  // });

  // useEffect(()=>{
  //   console.log('Alterou tipo:',tipoWatch);
  // },[tipoWatch])




  return (
    <MDBox p={2}>

      <MDBox width="100%" display="flex" justifyContent="space-between">
        <MDButton
          variant="gradient"
          color="secondary"
          type="button"
          onClick={() => setAba(0)}
        >Voltar</MDButton>
        <MDButton
          variant="gradient"
          circular
          color="info"
          type="button"
          onClick={novapossibilidade}
        >
          <Icon>add</Icon>
        </MDButton>
      </MDBox>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Cabecalho
          control={control}
          opcoesAtivo={opcoesAtivo}
          opcoesTipo={opcoesTipo}
          getValues={getValues}
        />
        <MDBox width="100%" display="flex" justifyContent="flex-end">
          <MDButton
            variant="gradient"
            color="info"
            type="submit"
          >
            Gravar
          </MDButton>
        </MDBox>

        {
          parseInt(possibilidadeAtual, 10) > 0 ?
            <PossibilidadeProjetosOpcoes
              control={control}
              getValues={getValues}
              setValue={setValue}
              possibilidadeAtual={possibilidadeAtual}
              handleSubmit={handleSubmit}
              recarregaComponenteOpcoes={recarregaComponenteOpcoes}
            />
            : false
        }

      </form>


    </MDBox>
  )

}

export default Cadastro;
