import MessagesContext from 'hooks/MessagesContext';
import React, { useContext, useEffect, useState } from 'react';
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MDTypography from 'components/MDTypography';
import { Box } from '@mui/system';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import { Grid } from '@mui/material';

import ParametroTipo from './parametrotipo';
import ParametroImagemCabecalhoProposta from './personalizacoes/imagemcabecalhoproposta';

export default function ParametrosModulo(props) {


    const { moduloCarregado, setModuloCarregado, nomeModuloCarregado } = props;
    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
    const [dataset, setDataset] = useState([]);
    const [datasetCategorias, setDatasetCategorias] = useState([]);

    const baseUrl = `${rotafechada()}parametros_categoria_modulo_parametro`;
    const baseCaption = 'Módulos dos Parâmetros';

    const buscaCategorias = async () => {

        if (!parseInt(moduloCarregado, 10) > 0) {
            return;
        }

        try {
            MostraTelaEspera(`Buscando ${baseCaption}`);
            const url = `${baseUrl}?limit=-1&order=categoria_modulo_parametro_id&modulo_sistema_id=${moduloCarregado}`;
            const retorno = await api.get(encodeURI(url));
            FechaTelaEspera();
            const { data, countAll } = retorno.data;
            // console.log(data);

            const categoria = {};

            const categoriastemp = [];

            //separando as categorias
            data.map((item) => {

                if (categoria?.id !== item.categoria_modulo_parametro_id) {
                    categoriastemp.push({
                        id: item.categoria_modulo_parametro_id,
                        caption: item.Categorias_Modulo_Parametro.caption,
                        nome: item.Categorias_Modulo_Parametro.nome
                    })
                    categoria.id = item.categoria_modulo_parametro_id;
                }
            })

            setDatasetCategorias(categoriastemp)
            setDataset(data)
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }
    }


    useEffect(async () => {

        // console.log('categorias')
        buscaCategorias();

    }, [moduloCarregado])

    const handleClickModulo = (item) => {
        setModuloCarregado(item.id);
    }

    return (
        <MDBox
            sx={{
                mr: 6
            }}
        >
            {

                datasetCategorias?.map((itemcategoria, index) => {
                    return (
                        <div>
                            <MDBox
                                key={index}
                                bgColor={'grey-200'}
                                fullWidth
                            >
                                <MDTypography variant="button" m={2} fontWeight='bold'>
                                    {
                                        itemcategoria.caption
                                    }
                                </MDTypography>
                            </MDBox>
                            <Grid container m={2}>
                                {
                                    dataset?.filter((itemfiltro) => itemfiltro.categoria_modulo_parametro_id === itemcategoria.id).map((item, index) => {
                                        return (
                                            <Grid item xs={12} sm={12} >
                                                <MDTypography variant="caption">
                                                    {item.Parametro.descricao}
                                                </MDTypography>
                                                <ParametroTipo
                                                    parametro={item.Parametro}
                                                />
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>

                            {
                                /* Personalização de determinadas categorias*/
                                ((nomeModuloCarregado === 'VENDAS') && (itemcategoria?.nome === 'IMPRESSAO')) &&
                                <Grid item xs={12} sm={12} >
                                    <ParametroImagemCabecalhoProposta

                                    />
                                </Grid>
                            }

                        </div>

                    )
                })


                // dataset?.map((item, index) => {
                //     return (
                //         <Box key={index}>
                //             <MDTypography variant="button">
                //                 {
                //                     item.Parametro.nome
                //                 }
                //             </MDTypography>
                //         </Box>
                //     )
                // }
                // )
            }
        </MDBox>
    )
}