import React, { Component, useContext, useState } from 'react';
import { Stage, Layer, Rect, Text, Shape, Circle, Line, Image, Transformer, Arrow } from 'react-konva';
import { Circulo } from './objetos/circulo';
import { LinhaHorizontal } from './objetos/linhahorizontal';
import LinhaVertical from './objetos/linhavertical';
import { Retangulo } from './objetos/retangulo';
import { Texto } from './objetos/texto';
import { Banda } from './objetos/banda';
import MessagesContext from 'hooks/MessagesContext';
import { DBTexto } from './objetos/dbtexto';
import { VariavelLabel } from './objetos/variavel';
import { DBCalc } from './objetos/dbcalc';
import { copiaProfundaObjetos } from 'commons/utils';
import { Imagem } from './objetos/imagem';
import { DbImagem } from './objetos/dbimagem';
import defaultImage from "assets/images/products/choose-product.png";
import { Memo } from './objetos/memo';
import { DBMemo } from './objetos/dbmemo';

export default function CamadaDesenho(props) {

    const { itensTela, selectShape, setItensTela, LARGURA, ALTURA, selectedId, stageRef,
        encontraBandaporPosicao, reposicionaBandas, opcoesFormatacao, shiftPressionado, setShiftPressionado, multiplosSelecionados, setMultiplosSelecionados } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro } = messageContext;


    const selecionaObjeto = (i) => {

        if (shiftPressionado) {
            const objCopia = copiaProfundaObjetos(multiplosSelecionados);

            if (parseInt(selectedId, 10) >= 0) {
                if (!(itensTela[selectedId].tipo.indexOf('banda-') >= 0))
                    objCopia.push(parseInt(selectedId, 10))
            }
            objCopia.push(i);
            setMultiplosSelecionados(objCopia);
            selectShape(null);
        }
        else {
            selectShape(i);
            setMultiplosSelecionados([]);
        }
    }


    return (
        <Stage
            width={LARGURA}
            height={ALTURA}
            ref={stageRef}
        >
            <Layer>
                {
                    itensTela?.map((item, i) => {

                        if ((item) && (item.tipo)) {
                            switch (item.tipo) {

                                case 'banda-cabecalho':
                                    return (<Banda
                                        key={`ID-${i}`}
                                        shapeProps={item}
                                        isSelected={i === selectedId}
                                        onSelect={() => {
                                            selectShape(i);
                                            setMultiplosSelecionados([]);
                                        }}
                                        onChange={(newAttrs) => {
                                            const itenstemp = itensTela.slice();
                                            itenstemp[i] = newAttrs;
                                            debugger;
                                            const vetorFinal = reposicionaBandas(itenstemp);
                                            setItensTela(vetorFinal);
                                        }}

                                    />)

                                case 'banda-titulo':
                                    return (<Banda
                                        key={`ID-${i}`}
                                        shapeProps={item}
                                        isSelected={i === selectedId}
                                        onSelect={() => {
                                            selectShape(i);
                                            setMultiplosSelecionados([]);
                                        }}
                                        onChange={(newAttrs) => {
                                            const itenstemp = itensTela.slice();
                                            itenstemp[i] = newAttrs;
                                            const vetorFinal = reposicionaBandas(itenstemp);
                                            setItensTela(vetorFinal);
                                        }}
                                    />)

                                case 'banda-detalhe':
                                    return (<Banda
                                        key={`ID-${i}`}
                                        shapeProps={item}
                                        isSelected={i === selectedId}
                                        onSelect={() => {
                                            selectShape(i);
                                            setMultiplosSelecionados([]);
                                        }}
                                        onChange={(newAttrs) => {
                                            const itenstemp = itensTela.slice();
                                            itenstemp[i] = newAttrs;
                                            const vetorFinal = reposicionaBandas(itenstemp);
                                            setItensTela(vetorFinal);
                                        }}
                                    />)

                                case 'banda-sumario':
                                    return (<Banda
                                        key={`ID-${i}`}
                                        shapeProps={item}
                                        isSelected={i === selectedId}
                                        onSelect={() => {
                                            selectShape(i);
                                            setMultiplosSelecionados([]);
                                        }}
                                        onChange={(newAttrs) => {
                                            const itenstemp = itensTela.slice();
                                            itenstemp[i] = newAttrs;
                                            const vetorFinal = reposicionaBandas(itenstemp);
                                            setItensTela(vetorFinal);
                                        }}
                                    />)

                                case 'banda-rodape':
                                    return (<Banda
                                        key={`ID-${i}`}
                                        shapeProps={item}
                                        isSelected={i === selectedId}
                                        onSelect={() => {
                                            selectShape(i);
                                            setMultiplosSelecionados([]);
                                        }}
                                        onChange={(newAttrs) => {
                                            const itenstemp = itensTela.slice();
                                            itenstemp[i] = newAttrs;
                                            const vetorFinal = reposicionaBandas(itenstemp);
                                            setItensTela(vetorFinal);
                                        }}
                                    />)

                                case 'banda-grupo-cabecalho':
                                    return (<Banda
                                        key={`ID-${i}`}
                                        shapeProps={item}
                                        isSelected={i === selectedId}
                                        onSelect={() => {
                                            selectShape(i);
                                            setMultiplosSelecionados([]);
                                        }}
                                        onChange={(newAttrs) => {
                                            const itenstemp = itensTela.slice();
                                            itenstemp[i] = newAttrs;
                                            const vetorFinal = reposicionaBandas(itenstemp);
                                            setItensTela(vetorFinal);
                                        }}
                                    />)

                                case 'banda-grupo-rodape':
                                    return (<Banda
                                        key={`ID-${i}`}
                                        shapeProps={item}
                                        isSelected={i === selectedId}
                                        onSelect={() => {
                                            selectShape(i);
                                            setMultiplosSelecionados([]);
                                        }}
                                        onChange={(newAttrs) => {
                                            const itenstemp = itensTela.slice();
                                            itenstemp[i] = newAttrs;
                                            const vetorFinal = reposicionaBandas(itenstemp);
                                            setItensTela(vetorFinal);
                                        }}
                                    />)

                                case 'text':
                                    return (<Texto
                                        key={`ID-${i}`}
                                        shapeProps={item}
                                        //isSelected={() => retornaSeEstaSelecionado(i)}
                                        isSelected={(i === selectedId) || (multiplosSelecionados?.includes(i))}
                                        onSelect={() => {
                                            selecionaObjeto(i);
                                        }}
                                        onChange={(newAttrs) => {
                                            const itenstemp = itensTela.slice();

                                            let bandaPai = encontraBandaporPosicao({ x: newAttrs.x, y: newAttrs.y });

                                            if (!bandaPai) {
                                                MensagemErro('Nenhuma banda foi encontrada');
                                            }
                                            else {
                                                itenstemp[i] = newAttrs;
                                                itenstemp[i].banda = bandaPai.id;
                                                itenstemp[i].y_banda = newAttrs.y - bandaPai.y;
                                            }

                                            setItensTela(itenstemp);
                                        }}

                                    />)

                                case 'memo':
                                    return (<Memo
                                        key={`ID-${i}`}
                                        shapeProps={item}
                                        //isSelected={() => retornaSeEstaSelecionado(i)}
                                        isSelected={(i === selectedId) || (multiplosSelecionados?.includes(i))}
                                        onSelect={() => {
                                            selecionaObjeto(i);
                                        }}
                                        onChange={(newAttrs) => {
                                            const itenstemp = itensTela.slice();

                                            let bandaPai = encontraBandaporPosicao({ x: newAttrs.x, y: newAttrs.y });

                                            if (!bandaPai) {
                                                MensagemErro('Nenhuma banda foi encontrada');
                                            }
                                            else {
                                                itenstemp[i] = newAttrs;
                                                itenstemp[i].banda = bandaPai.id;
                                                itenstemp[i].y_banda = newAttrs.y - bandaPai.y;
                                            }

                                            setItensTela(itenstemp);
                                        }}

                                    />)

                                case 'dbtext':
                                    return (<DBTexto
                                        key={`ID-${i}`}
                                        shapeProps={item}
                                        isSelected={(i === selectedId) || (multiplosSelecionados?.includes(i))}
                                        opcoesFormatacao={opcoesFormatacao}
                                        onSelect={() => {
                                            selecionaObjeto(i);
                                        }}
                                        onChange={(newAttrs) => {
                                            const itenstemp = itensTela.slice();

                                            let bandaPai = encontraBandaporPosicao({ x: newAttrs.x, y: newAttrs.y });

                                            if (!bandaPai) {
                                                MensagemErro('Nenhuma banda foi encontrada');
                                            }
                                            else {
                                                itenstemp[i] = newAttrs;
                                                itenstemp[i].banda = bandaPai.id;
                                                itenstemp[i].y_banda = newAttrs.y - bandaPai.y;
                                            }

                                            setItensTela(itenstemp);
                                        }}

                                    />)
                                case 'dbmemo':
                                    return (<DBMemo
                                        key={`ID-${i}`}
                                        shapeProps={item}
                                        isSelected={(i === selectedId) || (multiplosSelecionados?.includes(i))}
                                        opcoesFormatacao={opcoesFormatacao}
                                        onSelect={() => {
                                            selecionaObjeto(i);
                                        }}
                                        onChange={(newAttrs) => {
                                            const itenstemp = itensTela.slice();

                                            let bandaPai = encontraBandaporPosicao({ x: newAttrs.x, y: newAttrs.y });

                                            if (!bandaPai) {
                                                MensagemErro('Nenhuma banda foi encontrada');
                                            }
                                            else {
                                                itenstemp[i] = newAttrs;
                                                itenstemp[i].banda = bandaPai.id;
                                                itenstemp[i].y_banda = newAttrs.y - bandaPai.y;
                                            }

                                            setItensTela(itenstemp);
                                        }}

                                    />)

                                case 'dbcalc':
                                    return (<DBCalc
                                        key={`ID-${i}`}
                                        shapeProps={item}
                                        isSelected={(i === selectedId) || (multiplosSelecionados?.includes(i))}
                                        opcoesFormatacao={opcoesFormatacao}
                                        onSelect={() => {
                                            selecionaObjeto(i);
                                        }}
                                        onChange={(newAttrs) => {
                                            const itenstemp = itensTela.slice();

                                            let bandaPai = encontraBandaporPosicao({ x: newAttrs.x, y: newAttrs.y });

                                            if (!bandaPai) {
                                                MensagemErro('Nenhuma banda foi encontrada');
                                            }
                                            else {
                                                itenstemp[i] = newAttrs;
                                                itenstemp[i].banda = bandaPai.id;
                                                itenstemp[i].y_banda = newAttrs.y - bandaPai.y;
                                            }

                                            setItensTela(itenstemp);
                                        }}

                                    />)

                                case 'variavellabel':
                                    return (<VariavelLabel
                                        key={`ID-${i}`}
                                        shapeProps={item}
                                        isSelected={i === selectedId}
                                        onSelect={() => {
                                            selecionaObjeto(i);
                                        }}
                                        onChange={(newAttrs) => {
                                            const itenstemp = itensTela.slice();

                                            let bandaPai = encontraBandaporPosicao({ x: newAttrs.x, y: newAttrs.y });

                                            if (!bandaPai) {
                                                MensagemErro('Nenhuma banda foi encontrada');
                                            }
                                            else {
                                                itenstemp[i] = newAttrs;
                                                itenstemp[i].banda = bandaPai.id;
                                                itenstemp[i].y_banda = newAttrs.y - bandaPai.y;
                                            }

                                            setItensTela(itenstemp);
                                        }}

                                    />)

                                case 'rect':
                                    return (<Retangulo
                                        key={`ID-${i}`}
                                        shapeProps={item}
                                        isSelected={(i === selectedId) || (multiplosSelecionados?.includes(i))}
                                        onSelect={() => {
                                            selecionaObjeto(i);
                                        }}
                                        onChange={(newAttrs) => {
                                            const itenstemp = itensTela.slice();

                                            let bandaPai = encontraBandaporPosicao({ x: newAttrs.x, y: newAttrs.y });

                                            if (!bandaPai) {
                                                MensagemErro('Nenhuma banda foi encontrada');
                                            }
                                            else {
                                                itenstemp[i] = newAttrs;
                                                itenstemp[i].banda = bandaPai.id;
                                                itenstemp[i].y_banda = newAttrs.y - bandaPai.y;
                                            }

                                            setItensTela(itenstemp);
                                        }}

                                    />)

                                case 'linhahorizontal':
                                    return (<LinhaHorizontal
                                        key={`ID-${i}`}
                                        shapeProps={item}
                                        isSelected={(i === selectedId) || (multiplosSelecionados?.includes(i))}
                                        onSelect={() => {
                                            selecionaObjeto(i);
                                        }}
                                        onChange={(newAttrs) => {
                                            const itenstemp = itensTela.slice();

                                            let bandaPai = encontraBandaporPosicao({ x: newAttrs.x, y: newAttrs.y });

                                            if (!bandaPai) {
                                                MensagemErro('Nenhuma banda foi encontrada');
                                            }
                                            else {
                                                itenstemp[i] = newAttrs;
                                                itenstemp[i].banda = bandaPai.id;
                                                itenstemp[i].y_banda = newAttrs.y - bandaPai.y;
                                            }

                                            setItensTela(itenstemp);
                                        }}

                                    />)

                                case 'linhavertical':
                                    return (<LinhaVertical
                                        key={`ID-${i}`}
                                        shapeProps={item}
                                        isSelected={i === selectedId}
                                        onSelect={() => {
                                            selecionaObjeto(i);
                                        }}
                                        onChange={(newAttrs) => {
                                            const itenstemp = itensTela.slice();

                                            let bandaPai = encontraBandaporPosicao({ x: newAttrs.x, y: newAttrs.y });

                                            if (!bandaPai) {
                                                MensagemErro('Nenhuma banda foi encontrada');
                                            }
                                            else {
                                                itenstemp[i] = newAttrs;
                                                itenstemp[i].banda = bandaPai.id;
                                                itenstemp[i].y_banda = newAttrs.y - bandaPai.y;
                                            }

                                            setItensTela(itenstemp);
                                        }}

                                    />)

                                case 'circle':
                                    return (<Circulo
                                        key={`ID-${i}`}
                                        shapeProps={item}
                                        isSelected={i === selectedId}
                                        onSelect={() => {
                                            selecionaObjeto(i);
                                        }}
                                        onChange={(newAttrs) => {
                                            const itenstemp = itensTela.slice();

                                            let bandaPai = encontraBandaporPosicao({ x: newAttrs.x, y: newAttrs.y });

                                            if (!bandaPai) {
                                                MensagemErro('Nenhuma banda foi encontrada');
                                            }
                                            else {
                                                itenstemp[i] = newAttrs;
                                                itenstemp[i].banda = bandaPai.id;
                                                itenstemp[i].y_banda = newAttrs.y - bandaPai.y;
                                            }

                                            setItensTela(itenstemp);
                                        }}
                                    />)

                                case 'imagem':
                                    return (<Imagem
                                        key={`ID-${i}`}
                                        shapeProps={item}
                                        isSelected={i === selectedId}
                                        onSelect={() => {
                                            selecionaObjeto(i);
                                        }}
                                        onChange={(newAttrs) => {
                                            const itenstemp = itensTela.slice();

                                            let bandaPai = encontraBandaporPosicao({ x: newAttrs.x, y: newAttrs.y });

                                            if (!bandaPai) {
                                                MensagemErro('Nenhuma banda foi encontrada');
                                            }
                                            else {
                                                itenstemp[i] = newAttrs;
                                                itenstemp[i].banda = bandaPai.id;
                                                itenstemp[i].y_banda = newAttrs.y - bandaPai.y;
                                            }

                                            setItensTela(itenstemp);
                                        }}
                                    />)

                                case 'dbimagem':
                                    return (<DbImagem
                                        fieldsDbLabel
                                        key={`ID-${i}`}
                                        shapeProps={item}
                                        isSelected={i === selectedId}
                                        onSelect={() => {
                                            selecionaObjeto(i);
                                        }}
                                        onChange={(newAttrs) => {
                                            const itenstemp = itensTela.slice();

                                            let bandaPai = encontraBandaporPosicao({ x: newAttrs.x, y: newAttrs.y });

                                            if (!bandaPai) {
                                                MensagemErro('Nenhuma banda foi encontrada');
                                            }
                                            else {
                                                itenstemp[i] = newAttrs;
                                                itenstemp[i].banda = bandaPai.id;
                                                itenstemp[i].y_banda = newAttrs.y - bandaPai.y;
                                            }

                                            setItensTela(itenstemp);
                                        }}
                                    />)

                                default:
                                    break;
                            }
                        }
                    })
                }
            </Layer>
        </Stage>
    )
}