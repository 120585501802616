import React, { useContext, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete, Checkbox, Grid, IconButton, Typography } from '@mui/material';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';

import MDTypography from 'components/MDTypography';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import MDButton from 'components/MDButton';
import AuthContext from 'hooks/AuthContext';
import TabelaMedidas from './tabela';
import DialogoConfirma from 'componentes/DialogoConfirma';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';


const ProjetoMedidas = (props) => {

  const { handleSubmit, control, setValue, getValues, projetoAtual } = props;

  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const opcaoDefault = [{ id: -1, value: -1, label: '<Selecione...>' }];
  const [opcoesMedidas, setOpcoesMedidas] = useState(opcaoDefault);
  const [recarregaTabela, setRecarregaTabela] = useState(false);
  const [codigoAtual, setCodigoAtual] = useState('');

  const objModeloProjetoMedidas = {
    id: { type: 'text' },
    medida_projeto_id: { type: 'autocomplete' },
    mostra_impressao: { type: 'checkbox' },
    oculto: { type: 'checkbox' },
    observacao: { type: 'text' },
    valor_padrao: { type: 'text' },
  }

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${rotafechada()}projeto_medidas/permissao/${operacao}`);
      return true;
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;

    // if (!data.nome) {
    //   camposObrigatorios = `${camposObrigatorios}/Nome`
    // }

    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const gravarRegistro = async (data) => {

    const dados = {};
    
    Object.keys(objModeloProjetoMedidas).forEach((nome) => {
      dados[nome] = '';
      if (nome in data['medidas']) {
        if (typeof data['medidas'][nome] === 'object') {
          dados[nome] = data['medidas'][nome]?.value ? data['medidas'][nome]?.value : '';
        }
        else
          if (typeof data['medidas'][nome] === 'boolean') {
            dados[nome] = data['medidas'][nome] === true ? 'S' : 'N'
          }
          else dados[nome] = data['medidas'][nome];
      }
    })

    dados.empresa_id = sessaolocal.empresa_id || null;
    dados.projeto_id = projetoAtual;
    delete dados.id;

    try {
      validaCampos(dados);
    }
    catch (error) {
      MensagemErro(`${error.message}`);
      return;
    }

    try {
      let retorno;
      let codigoTemp = data.medidas.id;
      

      if ((data.medidas.id) && (parseInt(data.medidas.id, 10) > 0)) {
        retorno = await api.put(`${rotafechada()}projeto_medidas/${data.medidas.id}`, dados);
      }
      else {
        retorno = await api.post(`${rotafechada()}projeto_medidas`, dados);
        codigoTemp = retorno.data.data.id;
      }

      MensagemAviso(`Gravado com sucesso! Componente: ${codigoTemp}`);
      setCodigoAtual('');
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaopcoesMedidas = async () => {

    try {
      let url = `${rotafechada()}medidas_projetos?limit=-1`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      let opcoesMedidasTemp = [];
      opcoesMedidasTemp.push({ ...opcaoDefault[0] });
      data.map((item) => {
        opcoesMedidasTemp.push({ id: item.id, value: item.id, label: `${item.referencia}-${item.nome}`})
      })
      setOpcoesMedidas(opcoesMedidasTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }


  const buscaOpcoes = async () => {
    await buscaopcoesMedidas();
  };

  const novoRegistro = () => {
    //limpo os campos
    Object.keys(objModeloProjetoMedidas).forEach((nome) => {
      if (objModeloProjetoMedidas[nome].type === 'autocomplete') {
        setValue(`medidas.${nome}`, opcaoDefault[0])
      }
      else
        if (objModeloProjetoMedidas[nome].type === 'checkbox') {
          setValue(`medidas.${nome}`, false)
        }
        else
          if (objModeloProjetoMedidas[nome].type === 'text') {
            setValue(`medidas.${nome}`, '')
          }
    })
  }

  const abrirRegistro = async (codigo) => {
    try {
      let url = `${rotafechada()}projeto_medidas?id=${codigo}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      if (!data.length > 0) {
        MensagemErro(`Componente ID ${codigo} não localizado`);
        return;
      }
      setValue('medidas.id', data[0].id);
      setValue(`medidas.medida_projeto_id`, { value: data[0].medida_projeto_id, label: data[0].Medidas_Projeto.nome});
      setValue('medidas.mostra_impressao', data[0].mostra_impressao==='S'?true:false);
      setValue('medidas.observacao', data[0].observacao);
      setValue('medidas.valor_padrao', data[0].valor_padrao);
      setValue('medidas.oculto', data[0].oculto==='S'?true:false);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const excluirRegistro = async () => {

    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      await api.delete(`${rotafechada()}projeto_medidas/${dialogoOpen.id || 0}`);
      MensagemAviso('Excluído com sucesso!');
      novoRegistro();
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickExcluir = async (id) => {
    if (!(await validaRotaPermissao('excluir') === true)) {
      return;
    }
    setDialogoOpen({ ...dialogoOpen, visible: true, id });
  }

  useEffect(() => {
    buscaOpcoes();
    if (parseInt(codigoAtual, 10) > 0) {
      abrirRegistro(codigoAtual);
    }
    else novoRegistro();
  }, [projetoAtual, codigoAtual, recarregaTabela])




  return (
    <MDBox p={2} bgColor='#EAFAF1' mt={2}>

      <DialogoConfirma
        open={dialogoOpen.visible}
        title={'Confirma Exclusão'}
        color={'error'}
        message={'Certeza que deseja excluir?'}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
        handleConfirm={excluirRegistro}
      />

      <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => setOpenCollapse(!openCollapse)}
      >
        {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        <Typography variant='h6' mb={2}>Medidas</Typography>
      </IconButton>

      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>{/*id*/}
            <Controller
              name={`medidas.id`}
              control={props.control}
              defaultValue=''
              render={({ field: { ref, ...props } }) =>
                <FormField

                  autoComplete={false}
                  type='text'
                  label='Id'
                  rows={1}
                  multiline={false}
                  required={false}
                  disabled={true}
                  inputProps={{
                    disabled: true
                  }}
                  inputRef={ref}
                  {...props}
                />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>{/*medida_projeto_id*/}
            <Controller
              name='medidas.medida_projeto_id'
              control={control}
              defaultValue={{ value: -1 }}
              render={({ field: { onChange, ...props } }) => (
                <AutoCompleteStyled
                  options={opcoesMedidas}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => <FormField {...params} label='Sigla Medida' />}
                  onChange={(_, data) => {
                    return onChange(data);
                  }}
                  disabled={false}
                  {...props}

                />
              )}
            />
          </Grid>
          
          <Grid item xs={12} sm={2}>{/*mostra_impressao*/}
            <MDBox width="100%" display="flex" alignItems='center' flexDirection='column'>
              <MDTypography fontWeight="bold" variant="caption">
                {'Mostra Impressão'}
              </MDTypography>

              <Controller
                name={`medidas.mostra_impressao`}
                control={control}
                render={({ field: { value, ...otherprops } }) => (
                  <Checkbox
                    {...otherprops}
                    checked={value ? (value === true ? true : false) : false}
                    onChange={(e) => setValue(`medidas.mostra_impressao`, e.target.checked)}
                  />
                )}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} sm={2}>{/*oculto*/}
            <MDBox width="100%" display="flex" alignItems='center' flexDirection='column'>
              <MDTypography fontWeight="bold" variant="caption">
                {'Oculta'}
              </MDTypography>

              <Controller
                name={`medidas.oculto`}
                control={control}
                render={({ field: { value, ...otherprops } }) => (
                  <Checkbox
                    {...otherprops}
                    checked={value ? (value === true ? true : false) : false}
                    onChange={(e) => setValue(`medidas.oculto`, e.target.checked)}
                  />
                )}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} sm={12}>{/*valor_padrao*/}
            <Controller
              name={`medidas.valor_padrao`}
              control={props.control}
              defaultValue=''
              render={({ field: { ref, ...props } }) =>
                <FormField

                  autoComplete={false}
                  type='text'
                  label='Valor Padrão (valor ou fórmula)'
                  rows={1}
                  multiline={false}
                  required={false}
                  disabled={false}
                  inputRef={ref}
                  {...props}

                />}
            />
          </Grid>

          <Grid item xs={12} sm={12}>{/*observacao*/}
            <Controller
              name={`medidas.observacao`}
              control={props.control}
              defaultValue=''
              render={({ field: { ref, ...props } }) =>
                <FormField

                  autoComplete={false}
                  type='text'
                  label='Observação'
                  rows={1}
                  multiline={false}
                  required={false}
                  disabled={false}
                  inputRef={ref}
                  {...props}

                />}
            />
          </Grid>

        </Grid>

        <MDBox width="100%" display="flex" justifyContent="flex-end" mt={2}>
          <MDButton variant="gradient" color="info" onClick={() => handleSubmit(gravarRegistro)()}>Gravar</MDButton>
          <MDButton variant="gradient" color="info" onClick={novoRegistro} sx={{ ml: 2 }}>Novo</MDButton>
        </MDBox>

        <MDBox width="100%" display="flex" mt={2}>
          <TabelaMedidas
            projetoAtual={projetoAtual}
            abrirRegistro={abrirRegistro}
            excluirRegistro={handleClickExcluir}
            recarregaTabela={recarregaTabela}
          />
        </MDBox>

      </Collapse>
    </MDBox>
  )
}

export default ProjetoMedidas;