import React, { useContext, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Grid from "@mui/material/Grid";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import MDTypography from "components/MDTypography";
import MDBox from 'components/MDBox';
import { Autocomplete, Box, Icon, TextField } from '@mui/material';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import MessagesContext from 'hooks/MessagesContext';
import TabelaOpcoes from './tabela';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Controller, useForm, useWatch } from 'react-hook-form';
import AuthContext from "hooks/AuthContext";
import FormField from 'componentes/FormField';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import { ptBR } from "date-fns/locale";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function EstatisticaGeralModal(props) {

    //const { open, opcoesTiposPessoas, campoTipoPessoaIdFiltroPesquisa, setCampoTipoPessoaIdFiltroPesquisa, setAba} = props;
    const { setAba } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();
    const { handleSubmit, control, setValue, getValues, setFocus } = useForm({});


    let dataHoje = new Date();
    const data7dias = new Date(dataHoje.setDate(dataHoje.getDate() - 7));
    dataHoje = new Date();
    const data15dias = new Date(dataHoje.setDate(dataHoje.getDate() - 15));
    dataHoje = new Date();
    const data30dias = new Date(dataHoje.setDate(dataHoje.getDate() - 30));

    const periodo = [
        { value: data7dias, label: 'Últimos 7 dias' },
        { value: data15dias, label: 'Últimos 15 dias' },
        { value: data30dias, label: 'Últimos 30 dias' },
        { value: null, label: 'Personalizado' },
    ];

    const opcaoAtivo = [
        {
            label: 'Todos',
            value: ''
        },
        {
            label: 'Ativos',
            value: 'S'
        },
        {
            label: 'Desativados',
            value: 'N'
        },
    ];

    const [filtroAtivo, setFiltroAtivo] = useState(opcaoAtivo[1]);


    const [dataInicial, setDataInicial] = useState(data7dias);
    const [dataFinal, setDataFinal] = useState(new Date());

    const [openDialogo, setOpenDialogo] = useState({ open: false });


    const [desabilitaData, setDesabilitaData] = useState(false);

    const [recarregaTabela, setRecarregaTabela] = useState(false);


    useEffect(async () => {
        //await abrirEstatisticaCs();
        setOpenDialogo({ ...openDialogo, visible: true })
    }, [])

    const handleClickPesquisar = () => {
        setRecarregaTabela(!recarregaTabela);
    }

    //UseWatch fica escutando o campo perído para quando o periodo mudar ele carregar o state dos datePicker e pesquisa
    const aguardaPeriodo = useWatch({
        control,
        name: "Periodo",
    });

    useEffect(async () => {
        if (aguardaPeriodo && aguardaPeriodo.value != null) {

            setDataInicial(aguardaPeriodo.value);
            setDataFinal(new Date());

            setDesabilitaData(true);

            handleClickPesquisar();
        }
        if (aguardaPeriodo && aguardaPeriodo.value == null) {
            setDataInicial(null);
            setDataFinal(null);
            setDesabilitaData(false);
        }
    }, [aguardaPeriodo])

    const handleClose = () => {
        setOpenDialogo({ ...openDialogo, visible: false });
        setAba(0);
    };


    return (
        <>

            <Dialog
                fullScreen
                open={openDialogo.visible}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            //onClick={() => setOpenDialogo({ ...openDialogo, visible: false })}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <MDTypography variant="h3" fontWeight="bold">
                            {'Estatísticas CS'}
                        </MDTypography>

                    </Toolbar>
                </AppBar>

                <MDBox width='100%'>

                    {/* <Box sx={{ margin: 0 }}> */}
                    <Grid container spacing={2} justifyContent="center" >
                        <Grid item xs={12} sm={1}>{/* recuo */}
                            
                        </Grid>

                        <Grid item xs={12} sm={2}>{/*Período*/}
                            <Controller
                                name='Periodo'
                                control={control}
                                //defaultValue={{value: }}
                                render={({ field: { onChange, ...props } }) => (
                                    <AutoCompleteStyled
                                        options={periodo}
                                        getOptionLabel={(option) => option.label || ''}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                        renderInput={(params) => <FormField {...params} label='Período' />}
                                        onChange={(_, data) => {
                                            return onChange(data);
                                        }}
                                        disabled={false}
                                        {...props}
                                    />
                                )}

                            />
                        </Grid>

                        <Grid item xs={12} sm={2}> {/*Periodo ini*/}
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                <DesktopDatePicker
                                    required={true}
                                    disabled={desabilitaData}
                                    label='Data Inicial'
                                    inputFormat="dd/MM/yyyy"
                                    value={dataInicial}
                                    onChange={(e) => setDataInicial(e)}
                                    renderInput={(params) => <FormField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={2}>{/*Periodo fin*/}
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                <DesktopDatePicker
                                    required={true}
                                    disabled={desabilitaData}
                                    label='Data Final'
                                    inputFormat="dd/MM/yyyy"
                                    value={dataFinal}
                                    onChange={(e) => setDataFinal(e)}
                                    renderInput={(params) => <FormField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={2}>{/*Ativo*/}
                            <AutoCompleteStyled
                                autoHighlight
                                options={opcaoAtivo}
                                getOptionLabel={(option) => option.label || ''}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                renderInput={(params) => <FormField {...params} label='Ativo' />}
                                value={filtroAtivo}
                                onChange={(event, newValue) => {
                                    setFiltroAtivo(newValue);
                                }}
                                defaultValue={{ id: -1, value: -1, label: '<Escolha um opção>' }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={1}>{/* espaço */}
                            
                        </Grid>

                        <Grid item xs={12} sm={1} mb={2}>{/*Pesquisar*/}
                            <MDButton onClick={handleClickPesquisar} color='info' > Pesquisar</MDButton>
                        </Grid>
                    </Grid>

                    {/* <Grid container justifyContent="flex-end" >
                        <Grid item xs={12} sm={1}>
                            <MDButton onClick={handleClickPesquisar} color='info' > Pesquisar</MDButton>
                        </Grid>

                    </Grid> */}
                    {/* </Box> */}

                    <Grid item xs={12} sm={12}>{/*Tabela*/}
                        <TabelaOpcoes
                            dataInicial={dataInicial}
                            dataFinal={dataFinal}
                            recarregaTabela={recarregaTabela}
                            filtroAtivo={filtroAtivo}
                        />
                    </Grid>
                </MDBox>

            </Dialog>

        </>
    );
}
