import React, { useState, useEffect, useContext } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AutoCompleteStyled } from './AutoCompleteStyled';
import FormField from './FormField';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MessagesContext from "hooks/MessagesContext";
import AuthContext from 'hooks/AuthContext';


export default function DialogoConfirma(props) {

  const { open, message, handleCancel, handleConfirm, title, color, id, setDialogoOpenReplicar, dialogoOpenReplicar } = props;

  const messageContext = useContext(MessagesContext);
  const { MensagemErro } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const [desabilitaBotao, setDesabilitaBotao] = useState(true)
  const optionEmpresas = [
    { value: sessaolocal.empresa_id, label: `Empresa: ${sessaolocal.empresa_id} logada` },
    { value: 1, label: 'Empresa: 1 Modelo' },
  ]


  useEffect(async () => {
    setDesabilitaBotao(true);
  }, [id]);

  return (
    <div>

      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
          <br></br>
          <MDTypography variant="caption">
            Escolha para qual empresa deseja replicar:
          </MDTypography>
          <AutoCompleteStyled
            autocomplete="off"
            pt={2}
            mt={2}
            required
            disableClearable //Retira o X (clear)
            options={optionEmpresas}
            defaultValue={{ value: sessaolocal.empresa_id, label: `Empresa: ${sessaolocal.empresa_id} logada` }}
            getOptionLabel={(option) => option.label || ""}
            renderInput={(params) => <FormField {...params} label={''} />}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            value={dialogoOpenReplicar.empresa_escolhida}
            onChange={(event, newValue) => {
              setDialogoOpenReplicar({...dialogoOpenReplicar, empresa_escolhida: newValue });
              if (newValue) {
                setDesabilitaBotao(false);
              }
              if (!newValue) {
                setDesabilitaBotao(true);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
            <MDButton onClick={handleCancel} variant="outlined" color="dark">
              Cancelar
            </MDButton>
            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
              <MDButton disabled={desabilitaBotao} onClick={handleConfirm} variant="gradient" color={color ? color : 'info'} sx={{ height: "100%" }}>
                Confirmar
              </MDButton>
            </MDBox>
          </MDBox>
        </DialogActions>
      </Dialog>
    </div>
  );
}
