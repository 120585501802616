import React, { useContext, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, Grid, IconButton, Typography, Autocomplete, TextField } from '@mui/material';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { useForm, useWatch } from 'react-hook-form';
import MDTypography from 'components/MDTypography';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import MDButton from 'components/MDButton';
import AuthContext from 'hooks/AuthContext';
import TabelaOpcoes from './tabela';
import DialogoConfirma from 'componentes/DialogoConfirma';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { formataFloat, formatDate, formataValor } from "commons/utils";
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import { ptBR } from "date-fns/locale";

const AssinaturaModulos = (props) => {

  const { handleSubmit, control, setValue, getValues, assinaturaAtual, recarregaComponenteOpcoes
    , parametrosPessoa, dadosAssinaturas, setDadosAssinaturas, opcoesModulosSistema, opcoesAtivo } = props;

  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const [openCollapse, setOpenCollapse] = useState(false);
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const opcaoDefault = [{ value: -1, label: '<Selecione um opção>' }];
  const [recarregaTabela, setRecarregaTabela] = useState(false);
  const [codigoAtual, setCodigoAtual] = useState('');

  const objModeloAssinaturaModulos = {
    id: { type: 'text' },
    modulo_sistema_id: { type: 'autocomplete' },
    valor: { type: 'text' },
    valor_total: { type: 'text' },
    data_contratacao: { type: 'date' },
    desconto: { type: 'text' },
    validade_desconto: { type: 'date' },
    data_cancelamento: { type: 'date' },
    data_limite_uso: { type: 'date' },
    observacao: { type: 'text' },
    ativo: { type: 'autocomplete' },
  }

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${rotafechada()}assinatura_modulos/permissao/${operacao}`);
      return true;
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;
    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const gravarRegistro = async (data) => {

    const dadosAssinaturaModulo = {
      id: data.modulos?.id || '',
      modulo_sistema_id: data.modulos.modulo_sistema_id.value,
      assinatura_id: assinaturaAtual,
      valor: data.modulos.valor,
      data_contratacao: data.modulos.data_contratacao,
      desconto: data.modulos.desconto,
      validade_desconto: data.modulos.validade_desconto,
      data_cancelamento: data.modulos.data_cancelamento,
      data_limite_uso: data.modulos.data_limite_uso,
      observacao: data.modulos.observacao,
      ativo: data.modulos.ativo.value
    }

    try {

      if (parseInt(dadosAssinaturaModulo.id, 10) > 0) {
        let url = `${rotafechada()}assinatura_modulos/${dadosAssinaturaModulo.id}`
        await api.put(url, dadosAssinaturaModulo);
        MensagemAviso(`Alterado com sucesso!`);
      }
      else {
        delete dadosAssinaturaModulo.id
        let url2 = `${rotafechada()}assinatura_modulos`
        await api.post(url2, dadosAssinaturaModulo);
        MensagemAviso(`Gravado com sucesso!`);
      }

      setRecarregaTabela(!recarregaTabela);
      novoRegistro();
    }
    catch (error) {
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }

  }

  const novoRegistro = () => {
    //limpo os campos
    // Object.keys(objModeloAssinaturaModulos).forEach((nome) => {
    //   if (objModeloAssinaturaModulos[nome].type === 'autocomplete') {
    //     setValue(`modulos.${nome}`, opcaoDefault[0])
    //   }
    //   else
    //     if (objModeloAssinaturaModulos[nome].type === 'checkbox') {
    //       setValue(`modulos.${nome}`, false)
    //     }
    //     else
    //       if (objModeloAssinaturaModulos[nome].type === 'text') {
    //         setValue(`modulos.${nome}`, '')
    //       }
    //       else
    //         if (objModeloAssinaturaModulos[nome].type === 'date') {
    //           setValue(`modulos.${nome}`, null)
    //         }
    // })

    setValue('modulos.id', '');
    setValue(`modulos.valor`, 0);
    setValue(`modulos.valor_total`, 0);
    setValue(`modulos.data_contratacao`, new Date());
    setValue(`modulos.desconto`, 0);
    setValue(`modulos.validade_desconto`, null);
    setValue(`modulos.data_cancelamento`, null);
    setValue(`modulos.data_limite_uso`, null);
    setValue(`modulos.observacao`, '');
    setValue(`modulos.modulo_sistema_id`, { value: -1, label: '<Selecione um opção>' });
    setValue(`modulos.ativo`, { value: 'S', label: 'Sim' });
  }

  const abrirRegistro = async (codigo) => {
    try {
      let url = `${rotafechada()}assinatura_modulos?id=${codigo}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;


      if (!data.length > 0) {
        MensagemErro(`Assinatura_Modulos ID ${codigo} não localizado`);
        return;
      }

      setValue('modulos.id', data[0].id);
      setValue(`modulos.valor`, formataFloat(data[0].valor,));
      setValue(`modulos.valor_total`, formataValor(data[0].valor - (data[0].desconto ? data[0].desconto : 0),));
      setValue(`modulos.data_contratacao`, data[0].data_contratacao,);
      setValue(`modulos.desconto`, formataFloat(data[0].desconto ? data[0].desconto : 0,));
      setValue(`modulos.validade_desconto`, data[0].validade_desconto,);
      setValue(`modulos.data_cancelamento`, data[0].data_cancelamento,);
      setValue(`modulos.data_limite_uso`, data[0].data_limite_uso,);
      setValue(`modulos.observacao`, data[0].observacao,);
      let tempNomeModulo = data[0].Modulos_Sistema.caption
      setValue(`modulos.modulo_sistema_id`, { value: data[0].modulo_sistema_id, label: tempNomeModulo || 'Não encontrado' });
      setValue(`modulos.ativo`, { value: data[0].ativo === 'S' ? 'S' : 'N', label: data[0].ativo === 'S' ? 'Sim' : 'Não' });

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const excluirRegistro = async () => {

    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      await api.delete(`${rotafechada()}assinatura_modulos/${dialogoOpen.id || 0}`);
      MensagemAviso('Excluído com sucesso!');
      novoRegistro();
      setRecarregaTabela(!recarregaTabela);

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickExcluir = async (id) => {
    if (!(await validaRotaPermissao('excluir') === true)) {
      return;
    }
    setDialogoOpen({ ...dialogoOpen, visible: true, id });
  }


  return (
    <MDBox p={2} bgColor='#EAFAF1' mt={2}>

      <DialogoConfirma
        open={dialogoOpen.visible}
        title={'Confirma Exclusão'}
        color={'error'}
        message={'Certeza que deseja excluir?'}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
        handleConfirm={excluirRegistro}
      />

      <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => setOpenCollapse(!openCollapse)}
      >
        {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        <Typography variant='h6' mb={1}>Módulos Adicionais</Typography>
      </IconButton>

      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>{/*id*/}
            <Controller
              name={`modulos.id`}
              control={props.control}
              defaultValue='Id'
              render={({ field: { ref, ...props } }) =>
                <FormField
                  //autoComplete={false}
                  type='text'
                  label='Id'
                  rows={1}
                  multiline={false}
                  required={false}
                  disabled={true}
                  inputProps={{
                    disabled: true
                  }}
                  inputRef={ref}
                  {...props}
                />}
            />
          </Grid>
          <Grid item xs={12} sm={5}>{/*modulo_sistema_id*/}
            <Controller
              name='modulos.modulo_sistema_id'
              control={control}
              defaultValue={{ value: -1 }}
              render={({ field: { onChange, ...props } }) => (
                <AutoCompleteStyled
                  //id='modulo_sistema_id'
                  options={opcoesModulosSistema}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => <FormField {...params} label='Módulos' />}
                  onChange={(_, data) => {
                    return onChange(data);
                  }}
                  {...props}

                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={5}>{/*ativo*/}
            <Controller
              name='modulos.ativo'
              control={control}
              defaultValue={{ value: 'S', label: 'Sim'}}
              render={({ field: { onChange, ...props } }) => (
                <AutoCompleteStyled
                  //id='ativo'
                  options={opcoesAtivo}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  renderInput={(params) => <FormField {...params}  label='Ativo' />}
                  onChange={(_, data) => {
                    return onChange(data);
                  }}
                  {...props}

                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>{/*valor*/}
            <Controller
              name={`modulos.valor`}
              control={props.control}
              defaultValue={0}
              render={({ field: { ref, ...props } }) =>
                <FormField

                  //autoComplete={false}
                  type='text'
                  label='Valor'
                  rows={1}
                  multiline={false}
                  required={false}
                  disabled={false}
                  inputRef={ref}
                  {...props}
                />}
            />
          </Grid>

          <Grid item xs={12} sm={4}>{/*data_contratacao*/}
            <Controller
              name={'modulos.data_contratacao'}
              defaultValue={new Date()}
              control={props.control}
              render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                  <DesktopDatePicker
                    label='Data de contratação'
                    inputFormat="dd/MM/yyyy"
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => <FormField {...params} />}
                    disabled={false}
                    required={true}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>{/*data_cancelamento*/}
            <Controller
              name={'modulos.data_cancelamento'}
              defaultValue={null}
              control={props.control}
              render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                  <DesktopDatePicker
                    label='Data cancelamento'
                    inputFormat="dd/MM/yyyy"
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => <FormField {...params} />}
                    disabled={false}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>{/*desconto*/}
            <Controller
              name={`modulos.desconto`}
              control={props.control}
              defaultValue={0}
              render={({ field: { ref, ...props } }) =>
                <FormField

                  //autoComplete={false}
                  type='text'
                  label='Desconto'
                  rows={1}
                  multiline={false}
                  required={false}
                  disabled={false}
                  inputRef={ref}
                  {...props}
                />}
            />
          </Grid>

          <Grid item xs={12} sm={4}>{/*validade_desconto*/}
            <Controller
              name={'modulos.validade_desconto'}
              defaultValue={null}
              control={props.control}
              render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                  <DesktopDatePicker
                    label='Validade do desconto'
                    inputFormat="dd/MM/yyyy"
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => <FormField {...params} />}
                    disabled={false}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>{/*data_limite_uso*/}
            <Controller
              name={'modulos.data_limite_uso'}
              defaultValue={null}
              control={props.control}
              render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                  <DesktopDatePicker
                    label='Data limite de uso do sistema'
                    inputFormat="dd/MM/yyyy"
                    value={value}
                    onChange={onChange}
                    renderInput={(params) => <FormField {...params} />}
                    disabled={false}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>{/*Valor total campo virtual*/}
            <Controller
              name={`modulos.valor_total`}
              control={props.control}
              defaultValue=''
              render={({ field: { ref, ...props } }) =>
                <FormField

                  //autoComplete={false}
                  type='text'
                  label='Valor total'
                  rows={1}
                  multiline={false}
                  required={false}
                  disabled={true}
                  inputProps={{
                    disabled: true
                  }}
                  inputRef={ref}
                  {...props}
                />}
            />
          </Grid>

          <Grid item xs={12} sm={12}>{/*observacao*/}
            <Controller
              name={`modulos.observacao`}
              control={props.control}
              defaultValue=''
              render={({ field: { ref, ...props } }) =>
                <FormField
                  type='text'
                  label='Observação'
                  rows={3}
                  multiline={true}
                  required={false}
                  disabled={false}
                  inputRef={ref}
                  {...props}
                />}
            />
          </Grid>

          <MDBox width="100%" display="flex" justifyContent="flex-end" mt={1}>{/* Botões*/}
            <MDButton variant="gradient" color="info" onClick={() => handleSubmit(gravarRegistro)()}>Gravar</MDButton>
            <MDButton variant="gradient" color="info" onClick={novoRegistro} sx={{ ml: 2 }}>Novo</MDButton>
          </MDBox>


          <Grid item xs={12}>{/*Tabela*/}
            {/* <MDBox width="100%" display="flex" mt={1}> */}
            <TabelaOpcoes
              assinaturaAtual={assinaturaAtual}
              abrirRegistro={abrirRegistro}
              excluirRegistro={handleClickExcluir}
              recarregaTabela={recarregaTabela}
              recarregaComponenteOpcoes={recarregaComponenteOpcoes}
              getValues={getValues}
            />
            {/* </MDBox> */}
          </Grid>

        </Grid >

      </Collapse>
    </MDBox>
  )
}

export default AssinaturaModulos;