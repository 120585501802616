import { Avatar, Divider, Grid, Button, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Switch, Typography } from "@mui/material";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MDBox from "components/MDBox";
import AuthContext from "hooks/AuthContext";
import MessagesContext from "hooks/MessagesContext";
import { useContext, useEffect } from "react";
import { useState } from "react";
import ListaPermissao from '../componentes/listapermissao';

export default function ConfiguraPermissao(props) {

    const { permissao, permissao_categoria_modulo_permissao_id, perfis, usuarios, permitidoVerReservado } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    const [permissaoPerfisEmpresas, setPermissaoPerfisEmpresas] = useState([]);
    const [usuarioPermissoesAutorizados, setUsuarioPermissoesAutorizados] = useState([]);
    const [usuarioPermissoesBloqueados, setUsuarioPermissoesBloqueados] = useState([]);
    const [permissaoEmpresas, setPermissaoEmpresas] = useState(null);


    const reprocessaPermissoesVinculadas = async () => {

        if (!(parseInt(permissao_categoria_modulo_permissao_id, 10) > 0)) {
            return;
        }

        try {
            const url = `${rotafechada()}permissoes_vinculadas_modulo_permissao/reprocessar?permissao_categoria_modulo_permissao_id=${permissao_categoria_modulo_permissao_id}`;
            const retorno = await api.post(url);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Reprocessa: ${msg}`);
        }
    }

    const onClickDeletePerfil = async (permissao_perfil_empresa_id) => {

        if (!parseInt(permissao_perfil_empresa_id, 10) > 0) {
            return;
        }

        try {
            const url = `${rotafechada()}permissao_perfis_empresas/${permissao_perfil_empresa_id}`;
            const retorno = await api.put(url, {
                ativo: 'N'
            });
            await reprocessaPermissoesVinculadas();
            buscaDadosPermissao();
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }

    }

    const onClickDeleteUsuario = async (usuario_permissao_id) => {

        if (!parseInt(permissaoEmpresas?.id, 10) > 0) {
            return;
        }

        try {
            const url = `${rotafechada()}usuario_permissoes/${usuario_permissao_id}`;
            const retorno = await api.delete(url);
            await reprocessaPermissoesVinculadas();
            buscaDadosPermissao();
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }

    }

    const handleChangeSwitchAtiva = async (e) => {

        if (!parseInt(permissaoEmpresas?.id, 10) > 0) {
            return;
        }

        try {
            const url = `${rotafechada()}permissao_empresas/${permissaoEmpresas.id}`;
            const retorno = await api.put(url, {
                ativo: e.target.checked === true ? 'S' : 'N'
            });
            await reprocessaPermissoesVinculadas();
            buscaDadosPermissao();
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }

    }

    const buscaDadosPermissao = async () => {

        if (!parseInt(permissao.id, 10) > 0) {
            return;
        }

        try {

            const url = `${rotafechada()}permissao_empresas/full?permissao_id=${permissao.id}`;
            const retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;

            const listatemp = [];
            data[0].Permissao_Perfis_Empresas.map((item) => {

                if (item.ativo === 'S') {
                    let mostra = true;

                    if ((item.Perfis_Acesso.reservado === 'S') && (!permitidoVerReservado)) {
                        //se for um perfil reservado e o usuario nao for o root entao nao mostra
                        mostra = false;
                    }

                    if (mostra) {
                        listatemp.push({
                            id: item.id,
                            perfil_acesso_id: item.perfil_acesso_id,
                            caption: item.Perfis_Acesso.nome
                        })
                    }
                }
            })

            const usuariosAutorizadoTemp = [];
            const usuariosBloqueadosTemp = [];
            data[0]?.Permisso?.Usuario_Permissoes.map((item) => {

                let mostra = true;

                if ((item.Empresa_Usuario.reservado === 'S') && (!permitidoVerReservado)) {
                    //se for um perfil reservado e o usuario nao for o root entao nao mostra
                    mostra = false;
                }

                if (mostra) {
                    if (item.permitido == 'S') {
                        usuariosAutorizadoTemp.push({
                            id: item.id,
                            empresa_usuario_id: item.empresa_usuario_id,
                            caption: item.Empresa_Usuario.nome
                        })
                    }
                    else {
                        usuariosBloqueadosTemp.push({
                            id: item.id,
                            empresa_usuario_id: item.empresa_usuario_id,
                            caption: item.Empresa_Usuario.nome
                        })
                    }
                }

            })

            setPermissaoEmpresas({
                id: data[0].id,
                ativo: data[0].ativo,
                permissao_id: data[0].permissao_id
            })

            setPermissaoPerfisEmpresas(listatemp);
            setUsuarioPermissoesAutorizados(usuariosAutorizadoTemp);
            setUsuarioPermissoesBloqueados(usuariosBloqueadosTemp);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }
    }

    const onClickAddPerfil = async (perfil_acesso_id) => {

        //busco esse perfil na "permissao_perfis_empresas"
        //se ele existe apenas jogo o ativo='S
        //se ele nao existe eu crio um ativado

        if (!parseInt(permissaoEmpresas?.id, 10) > 0) {
            return;
        }

        if (!parseInt(perfil_acesso_id, 10) > 0) {
            return;
        }

        try {

            let url;
            let retorno;

            url = `${rotafechada()}permissao_perfis_empresas?permissao_empresa_id=${permissaoEmpresas.id}&perfil_acesso_id=${perfil_acesso_id}`;
            retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;

            if (data.length > 0) {
                //ja existe
                const permissao_perfil_empresa_id = data[0].id;
                url = `${rotafechada()}permissao_perfis_empresas/${permissao_perfil_empresa_id}`;
                await api.put(url, { ativo: 'S' });
            }
            else {
                //nao existe entao precisa inserir
                url = `${rotafechada()}permissao_perfis_empresas`;
                await api.post(url, {
                    perfil_acesso_id,
                    permissao_empresa_id: permissaoEmpresas.id,
                    ativo: 'S'
                });

            }

            await reprocessaPermissoesVinculadas();
            buscaDadosPermissao();
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }
    }

    const alteraUsuarioPermissao = async (empresa_usuario_id, permitido = 'N') => {
        if (!parseInt(permissaoEmpresas?.id, 10) > 0) {
            return;
        }

        if (!parseInt(empresa_usuario_id, 10) > 0) {
            return;
        }

        try {

            let url;
            let retorno;

            url = `${rotafechada()}usuario_permissoes?permissao_id=${permissao.id}&empresa_usuario_id=${empresa_usuario_id}`;
            retorno = await api.get(encodeURI(url));

            if (retorno.data.data.length > 0) {
                //ja existe
                const usuario_permissao_id = retorno.data.data[0].id;
                url = `${rotafechada()}usuario_permissoes/${usuario_permissao_id}`;
                await api.put(url, { permitido });
            }
            else {
                //nao existe entao precisa inserir
                url = `${rotafechada()}usuario_permissoes`;
                await api.post(url, {
                    permissao_id: permissao.id,
                    empresa_usuario_id,
                    permitido
                });
            }

            await reprocessaPermissoesVinculadas();
            buscaDadosPermissao();
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }
    }

    const onClickAddUsuarioAutorizado = async (empresa_usuario_id) => {

        alteraUsuarioPermissao(empresa_usuario_id, 'S');

    }

    const onClickAddUsuarioBloqueado = async (empresa_usuario_id) => {
        alteraUsuarioPermissao(empresa_usuario_id, 'N');
    }


    useEffect(() => {

        buscaDadosPermissao();

    }, [perfis])

    return (
        <>
            {
                permissaoEmpresas &&
                <Grid container>
                    <Grid item xs={12} lg={12}>
                        <Typography
                            variant="body2"
                            fontWeight={'bold'}
                            bgcolor={'#F2F4F4'}
                            p={1}
                        >
                            {permissao.descricao}
                        </Typography>
                        <Switch
                            label={permissaoEmpresas.ativo === 'S' ? 'Ativa' : 'Desativada'}
                            disabled={false}
                            checked={permissaoEmpresas.ativo === 'S' ? true : false}
                            onChange={(e) => handleChangeSwitchAtiva(e)}
                            color="default"
                        />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        {
                            permissaoEmpresas.ativo === 'S' &&
                            <MDBox display='flex' justifyContent="space-around" mt={2} >
                                <ListaPermissao
                                    opcoesInserir={perfis}
                                    titulo='Perfis Autorizados'
                                    lista={permissaoPerfisEmpresas}
                                    onClickDelete={onClickDeletePerfil}
                                    onClickAdd={onClickAddPerfil}
                                />

                                <ListaPermissao
                                    opcoesInserir={usuarios}
                                    titulo='Usuários Permitidos'
                                    lista={usuarioPermissoesAutorizados}
                                    onClickDelete={onClickDeleteUsuario}
                                    onClickAdd={onClickAddUsuarioAutorizado}
                                />

                                <ListaPermissao
                                    opcoesInserir={usuarios}
                                    titulo='Usuários Bloqueados'
                                    lista={usuarioPermissoesBloqueados}
                                    onClickDelete={onClickDeleteUsuario}
                                    onClickAdd={onClickAddUsuarioBloqueado}
                                />
                            </MDBox>
                        }
                    </Grid>
                </Grid>
            }
        </>
    )
}

/*
{
    "data": [
        {
            "id": 1944,
            "ativo": "S",
            "createdAt": "2022-06-17T20:59:19.290Z",
            "updatedAt": "2022-06-17T20:59:19.290Z",
            "empresa_id": 1,
            "permissao_id": 273,
            "Permissao_Perfis_Empresas": [
                {
                    "id": 7359,
                    "ativo": "S",
                    "createdAt": "2022-06-17T20:59:19.817Z",
                    "updatedAt": "2022-06-17T20:59:19.817Z",
                    "perfil_acesso_id": 1,
                    "permissao_empresa_id": 1944,
                    "Perfis_Acesso": {
                        "nome": "ROOT"
                    }
                },
                {
                    "id": 7358,
                    "ativo": "S",
                    "createdAt": "2022-06-17T20:59:19.815Z",
                    "updatedAt": "2022-06-17T20:59:19.815Z",
                    "perfil_acesso_id": 2,
                    "permissao_empresa_id": 1944,
                    "Perfis_Acesso": {
                        "nome": "GERENCIA_SUPORTE"
                    }
                },
                {
                    "id": 7357,
                    "ativo": "S",
                    "createdAt": "2022-06-17T20:59:19.809Z",
                    "updatedAt": "2022-06-17T20:59:19.809Z",
                    "perfil_acesso_id": 3,
                    "permissao_empresa_id": 1944,
                    "Perfis_Acesso": {
                        "nome": "SUPORTE"
                    }
                },
                {
                    "id": 7356,
                    "ativo": "S",
                    "createdAt": "2022-06-17T20:59:19.805Z",
                    "updatedAt": "2022-06-17T20:59:19.805Z",
                    "perfil_acesso_id": 4,
                    "permissao_empresa_id": 1944,
                    "Perfis_Acesso": {
                        "nome": "ADMIN"
                    }
                }
            ],
            "Permisso": {
                "id": 273,
                "nome": "EDITAR VENDA_PROJETO_CORES_TIPOS",
                "descricao": "Indica se é permitido chamada a rota de edição de VENDA_PROJETO_CORES_TIPOS",
                "ativo": "S",
                "createdAt": "2022-06-07T21:43:35.334Z",
                "updatedAt": "2022-06-07T21:43:35.334Z",
                "Usuario_Permissoes": [
                    {
                        "id": 276,
                        "permitido": "S",
                        "createdAt": "2022-06-07T21:43:35.726Z",
                        "updatedAt": "2022-06-07T21:43:35.726Z",
                        "empresa_usuario_id": 8,
                        "permissao_id": 273,
                        "Empresa_Usuario": {
                            "id": 8,
                            "ativo": "S",
                            "configuracoes": null,
                            "createdAt": "2022-02-07T15:42:26.214Z",
                            "updatedAt": "2022-02-07T15:42:26.214Z",
                            "empresa_id": 1,
                            "nome": "Celio"
                        }
                    }
                ]
            }
        }
    ]
}*/