/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext,  useState } from "react";

// react-router-dom components
import {  useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import AlterarPrimeiraSenhaLayout from "./Layout";
import bgImage from "assets/images/personalizados/headerlogin.png";

import { rotaaberta } from '../../commons/urlconf';
import api from '../../commons/api';
import MessageContext from "../../hooks/MessagesContext";

function PrimeiroAcesso() {

  const messageContext = useContext(MessageContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

  const [rememberMe, setRememberMe] = useState(true);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [senha, setSenha] = useState('');
  const [confirmaSenha, setConfirmaSenha] = useState('');
  const { token } = useParams();
  let navigate = useNavigate();




  const handleClickEntrar = async (e) => {
    e.preventDefault();


    if ((senha.trim() === '')|| (confirmaSenha.trim() === '')) {
      MensagemErro('Prencha todos os campos!')
      return;
    }
    
    if (senha!==confirmaSenha) {
      MensagemErro('As senhas precisam ser iguais!')
      return;
    }

    try {

      MostraTelaEspera('Solicitação Alteração da Senha');
      //let retorno = await api.post(`${rotaaberta()}alterarsenha`,
      let retorno = await api.post(`${rotaaberta()}primeiroacesso`,
        {
          token:token,
          senha: senha,
          confirmasenha:confirmaSenha
        });
      FechaTelaEspera();

      MensagemAviso('Senha criada com Sucesso!!')

      navigate('/login');



    }
    catch (error) {
      FechaTelaEspera();
      const msgerro = error.response?.data?.msg || error;
      MensagemErro(`Erro na tentativa de criar a senha: ${msgerro}`);
      return;
    }
  }


  return (
    <AlterarPrimeiraSenhaLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Primeiro acesso
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Crie sua senha
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Senha"
                variant="standard"
                fullWidth
                placeholder="************"
                autoComplete="current-password"
                InputLabelProps={{ shrink: true }}
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Confirmação da Senha"
                variant="standard"
                fullWidth
                placeholder="************"
                autoComplete="current-password"
                InputLabelProps={{ shrink: true }}
                value={confirmaSenha}
                onChange={(e) => setConfirmaSenha(e.target.value)}
              />
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleClickEntrar}>
                CONFIRMAR
              </MDButton>
            </MDBox>
            
          </MDBox>
        </MDBox>
      </Card>
    </AlterarPrimeiraSenhaLayout>
  );
}

export default PrimeiroAcesso;
