import React, { useContext, useState } from 'react';
import RelatorioPadrao from '../../../relatorios/padrao';
import FiltroVendasPorPeriodo from './filtro';
import DataPrinterContext from 'hooks/DataPrinter';
import MessagesContext from 'hooks/MessagesContext';
// import { copiaProfundaObjetos } from 'commons/utils';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import { dateIsValid } from 'commons/utils';
import { formatDate } from 'commons/utils';
import { formataValor } from 'commons/utils';
import { format } from 'date-fns';


export default function RelatorioVendasPorPeriodo(props) {

    const { open, setOpen, parametrosVenda } = props;
    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso } = messageContext;
    const [openRelatorioPadrao, setOpenRelatorioPadrao] = useState(false);
    const dataPrinterContext = useContext(DataPrinterContext);

    const geraRelatorio = async (params, data) => {


        dataPrinterContext.setEstiloColunas({ backgroundColor: '#F2F4F4', fontSize: '14px' })
        dataPrinterContext.setEstiloLinhas({ backgroundColor: 'white', fontSize: '12px' })
        if (params.tipoVenda==='venda')
            dataPrinterContext.setTitulo(`Vendas por Período`);
        else dataPrinterContext.setTitulo(`Propostas por Período`);
        
        const subtitulo = [];

        let textofiltros = `Período: ${formatDate(params.dataInicial, 'DD/MM/YY')}-${formatDate(params.dataFinal, 'DD/MM/YY')} | `;

        if (parseInt(params.vendedor, 10) > 0) {
            textofiltros = `${textofiltros}Vendedor: ${params.vendedor} | `;
        }
        if (parseInt(params.cliente, 10) > 0) {
            textofiltros = `${textofiltros}Cliente: ${params.cliente} | `;
        }
        subtitulo.push(textofiltros);
        dataPrinterContext.setSubtitulo(subtitulo);

        const colunas = [];

        colunas.push({ id: 'vendedor', label: 'Vendedor', sx: { width: '150px' } });
        colunas.push({ id: 'id', label: 'ID', sx: { width: '50px' } });
        colunas.push({ id: 'data', label: 'Data' });
        colunas.push({ id: 'cliente', label: 'Cliente' });
        colunas.push({ id: 'conta_id', label: 'Conta', sx: { width: '40px' } });
        colunas.push({ id: 'valor_final', label: 'Valor', sx: { width: '60px'}, align:'right' });

        dataPrinterContext.setColunas(colunas);

        const linhasTemp = [];

        debugger;

        let vendedor_id = parseInt(data[0].vendedor_id, 10);
        let somaValorFinal = 0;
        let somaValorVendedor = 0;
        data?.map((item) => {

            if (parseInt(item.vendedor_id, 10) !== vendedor_id) {
                //totalizando o anterior
                linhasTemp.push({
                    vendedor: 'TOTAL',
                    id: '',
                    data: '',
                    cliente: '',
                    valor_final: formataValor(somaValorVendedor),
                    conta_id: '',
                    sx: {
                        fontWeight: 'bold'
                    }
                })
                somaValorVendedor = 0;
            }

            vendedor_id = parseInt(item.vendedor_id, 10);
            somaValorFinal = somaValorFinal + parseFloat(item.valor_final) || 0;
            somaValorVendedor = somaValorVendedor + parseFloat(item.valor_final) || 0;
            let vendedor = `${item?.Vendedor?.fantasia || item?.Vendedor?.razao_social}`;
            vendedor=vendedor.length>14?vendedor.substring(0, 14) + '...':vendedor;
            const cliente = `${item?.Cliente?.fantasia || item?.Cliente?.razao_social}`;
            let dataTemp = parseInt(item?.conta_id, 10) > 0 ? item?.data_venda : item?.data_orcamento;
            dataTemp = new Date(dataTemp);
            dataTemp = formatDate(dataTemp, 'DD/MM/YY');

            linhasTemp.push({
                vendedor,
                id: item?.id,
                data: dataTemp,
                cliente,
                valor_final: formataValor(item?.valor_final),
                conta_id: item?.conta_id
            })
        })

        //totalizando o ultimo vendedor
        linhasTemp.push({
            vendedor: 'TOTAL',
            id: '',
            data: '',
            cliente: '',
            valor_final: formataValor(somaValorVendedor),
            conta_id: '',
            sx: {
                fontWeight: 'bold'
            }
        })
             //totalizando GERAL
             linhasTemp.push({
                vendedor: 'TOTAL FINAL',
                id: '',
                data: '',
                cliente: '',
                valor_final: formataValor(somaValorFinal),
                conta_id: '',
                sx: {
                    fontWeight: 'bold',
                    fontSize:'14px',
                    backgroundColor:'#F2F4F4'
                }
            })

        dataPrinterContext.setLinhas(linhasTemp);
    }

    const handleImprimir = async (params = {
        tipos: [],
        mostraImagem: false,
        mostraPreco: false
    }) => {

        if (!
            ((params.dataInicial) && (params.dataFinal) &&
                dateIsValid(params.dataInicial) && dateIsValid(params.dataFinal))) {
            MensagemErro('Escolha o período para gerar o relatório');
            return;
        }

        const datainicial = format(params.dataInicial, 'yyyy-MM-dd :00:00:00');
        const datafinal = format(params.dataFinal, 'yyyy-MM-dd 23:59:59');

        try {
            let filtros = '';

            if ((params.vendedor) && (parseInt(params.vendedor, 10) > 0)) {
                filtros = `${filtros}&vendedor_id=${params.vendedor}`;
            }

            if ((params.cliente) && (parseInt(params.cliente, 10) > 0)) {
                filtros = `${filtros}&cliente_id=${params.cliente}`;
            }

            if (params.tipoVenda) {
                filtros = `${filtros}&tipo=${params.tipoVenda}`;
            }

            const retorno = await api.get(`${rotafechada()}vendas/dadosrelatorios/vendasporperiodo?datainicial=${new Date(datainicial)}&datafinal=${new Date(datafinal)}&${filtros}`);
            const { data } = retorno.data;

            if (!(data.length > 0)) {
                MensagemAviso('Nenhuma informação foi selecionada');
                return;
            }

            await geraRelatorio(params, data);

            setOpenRelatorioPadrao(true);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }
    }

    return (
        <>
            <RelatorioPadrao open={openRelatorioPadrao} setOpen={setOpenRelatorioPadrao} />
            <FiltroVendasPorPeriodo
                open={open}
                setOpen={setOpen}
                handleOk={handleImprimir}
                parametrosVenda={parametrosVenda}
            />
        </>
    )
}