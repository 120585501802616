import React, { useContext, useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import MDButton from 'components/MDButton';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import SearchIcon from '@mui/icons-material/Search';
import { ptBR } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FormField from 'componentes/FormField';
import { Grid, IconButton, } from '@mui/material';
import MessagesContext from 'hooks/MessagesContext';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import ConfiguracaoRelatorioPersonalizado from 'pages/genrel/configuracaorelatoriopersonalizado';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import MDBox from 'components/MDBox';
import { formatDate } from 'commons/utils';
import { capitalizeInitials } from 'commons/utils';
import CloseIcon from '@mui/icons-material/Close';
import TabelaPesquisaDinamica from './tabela';
import { retornaSomenteNumeros } from 'commons/utils';
import DialogoConfirma from 'componentes/DialogoConfirma';
import DialogoSimples from 'componentes/DialogoSimples';
import { format } from 'date-fns';

export default function DialogoFiltrosRelatorio(props) {
    const { impressao, relatorio_id, onClose, value: valueProp, open, title, options, color, configuracao, ...other } = props;

    //const [value, setValue] = React.useState(valueProp);
    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MensagemAlerta, MostraTelaEspera, FechaTelaEspera } = messageContext;
    const radioGroupRef = React.useRef(null);
    const [values, setValues] = useState({});
    const [filtroAtual, setFiltroAtual] = useState();
    const [dataset, setDataset] = useState([]);
    const [configuraRelatorio, setConfiguraRelatorio] = useState({
        open: false,
        datasets: {},
        relatorio_id
    })
    const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
    const [dialogoSimples, setDialogoSimples] = useState({ open: false, linhas: [] });

    //Tabela
    const [countAll, setCountAll] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [colunasTable, setColunasTable] = React.useState([]);
    const [linhas, setLinhas] = React.useState([]);
    const [recarregaDados, setRecarregaDados] = React.useState(false);

    const [camposFiltro, setCamposFiltro] = React.useState([
        //{ id: 'id', label: 'idsss', buscaParcial: false },
        // { id: 'nomeproduto', label: 'Nome', buscaParcial: true },
        // { id: 'produto_id', label: 'id Produto' },
        // { id: 'referenciaproduto', label: 'Referência', buscaParcial: true }
    ]);

    // const campoInicial = camposFiltro ? camposFiltro[0] : '';
    const campoInicial = 'Escolha um filtro';
    const [campoPesquisa, setCampoPesquisa] = React.useState(campoInicial);
    const [valorPesquisa, setValorPesquisa] = React.useState('');

    const [openConfirmaFechamentoConfiguracao, setOpenConfirmaFechamentoConfiguracao] = useState(false);


    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        setValues({})
        setDialogoOpen({ ...dialogoOpen, visible: false });
        onClose();
    };

    const handleCancel2 = () => {
        setDialogoOpen({ ...dialogoOpen, visible: false });
        setValorPesquisa('')
        setCampoPesquisa(campoInicial)
        setLinhas([]);
        setColunasTable([])
        setCamposFiltro([])
    };

    const abreTelaPesquisa = async (id) => {
        setFiltroAtual(id)
        setDialogoOpen({ ...dialogoOpen, visible: true })
    }

    const buscaDados = async () => {
        try {
            let complementoUrl = ''

            if ((campoPesquisa.id) && (valorPesquisa)) {
                complementoUrl = `&${campoPesquisa.id}=${valorPesquisa}`;

                if (campoPesquisa.id === 'id') {
                    const temp = retornaSomenteNumeros(valorPesquisa);
                    if ((`${temp}`.length !== `${valorPesquisa}`.length) ||
                        (!(parseInt(`${valorPesquisa}`, 10) > 0))) {
                        MensagemErro('A pesquisa por ID aceita só números, escolha outro campo para busca');
                        return;
                    }
                }
            }

            if ((valorPesquisa) && (valorPesquisa.length > 0) && (valorPesquisa[0] !== '%') && (valorPesquisa[valorPesquisa.length - 1] !== '%')
                && (camposFiltro) && (camposFiltro.length > 0)) {

                //se o texto a ser buscado nao terminar ou começar com %
                //e tiver uma configuracao no camposFiltro
                //entao localizo que o campo usado nesse momento é um campo que esteja configurado
                //o buscaParcial, se sim acrescento automaticamente o % no inicio e fim


                let encontrouCampo = false;
                for (let i = 0; i < camposFiltro.length; i++) {
                    if ((camposFiltro[i].buscaParcial === true) && (camposFiltro[i].id === campoPesquisa.id)) {
                        encontrouCampo = true;
                    }
                }

                if (encontrouCampo === true) {
                    complementoUrl = `&${campoPesquisa.id}=%${valorPesquisa}%`;
                }
            }


            if (page > 0) {
                complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
            }

            // if (orderBy) {
            //     complementoUrl = `${complementoUrl}&order=${orderBy}`;
            //     if (order) {
            //         complementoUrl = `${complementoUrl}&direction=${order}`;
            //     }
            // }

            MostraTelaEspera(`Buscando dados`);

            //const url2 = `${baseUrl}?limit=${rowsPerPage}${complementoUrl}`;

            let url = `${rotafechada()}/relatorios_personalizados_filtros/chave-estrangeira/${filtroAtual}?limit=${rowsPerPage}${complementoUrl}`;
            const retorno = await api.get(encodeURI(url));
            const data = retorno.data;

            const filtrosSqlChaveEstrangeira = retorno.data.filtros;


            // Extrair os nomes dos atributos
            //var atributos = Object.keys(data?.data[0]);
            let atributos;
            if (data && data.data && data.data[0]) {
                atributos = Object.keys(data.data[0]);
            } else {
                atributos = [];
            }

            const { countAll: countAllretorno } = retorno.data;
            let dadostemp = [];

            let colunasTabletemp = []

            /*Montando cabeçalho com período dinâmico */
            let camposFiltroTemp = []
            for (let i = 0; i < atributos.length; i++) {
                //camposFiltroTemp.push({ id: atributos[i], label: capitalizeInitials(atributos[i].replace('_', ' ')) })
                let temp = atributos[i].replace('_', ' '); // Substituir '_' por ' ' nos nomes dos atributos

                colunasTabletemp.push({
                    id: atributos[i],
                    numeric: false,
                    disablePadding: false,
                    label: capitalizeInitials(temp),
                    disableOrder: true,
                },)
            }

            for (let i = 0; i < filtrosSqlChaveEstrangeira.length; i++) {
                camposFiltroTemp.push({
                    id: filtrosSqlChaveEstrangeira[i].campoFiltro,
                    label: filtrosSqlChaveEstrangeira[i].campoFiltro,
                    buscaParcial: filtrosSqlChaveEstrangeira[i].buscaParcial ? filtrosSqlChaveEstrangeira[i].buscaParcial : false
                })
            }
            setCamposFiltro(camposFiltroTemp)
            /*mapeando os dados para a "rows" e montando o período dinamico*/
            // dadostemp = dadostemp.map((item, index) => {

            //     return item
            // })

            data.data.map((item, index) => {
                dadostemp.push({
                    ...item
                })
            })


            if (colunasTabletemp.length > 0){
                setColunasTable([...colunasTabletemp]);
            }
            setLinhas(dadostemp);
            setCountAll(countAllretorno);
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    function validateFields() {
        let isValid = true;
        let msg = ''
        dataset?.map((item) => {
            if (item.obrigatorio == 'S' && (values[item.id] === undefined || values[item.id] === '')) {
                msg = msg + `${item.label}, `
                isValid = false;
            }
        });
        if (msg.length > 0)
            MensagemAlerta(`Campo(s) obrigatório(s): ${msg}`, 5)

        return isValid;
    }

    //function handleConfirm() {
    const handleConfirm = async () => {
        if (validateFields()) {
            // Se todos os campos obrigatórios estiverem preenchidos, prossiga com a confirmação
            let dados1 = values
            let dados2 = dataset
            //unindo os datasets de filtros com 
            for (let i = 0; i < dados2.length; i++) {
                let id = dados2[i].id;
                if (dados1.hasOwnProperty(id)) {
                    dados2[i].valor = dados1[id];
                }
            }

            //Percorrendo e formatando a data
            dados2.forEach(item => {
                if (item.tipo === 'DAT' && item.valor) {
                    item.label_filtro = format(item.valor, "dd/MM/yyyy")
                    item.valor = item.periodo_data_tipo == 'I' ? //If (item.periodo_data_tipo == 'I' ?)
                        new Date(format(item.valor, "yyyy-MM-dd 00:00:00")) :
                        item.periodo_data_tipo == 'F' ? // If (item.periodo_data_tipo == 'F')
                            new Date(format(item.valor, "yyyy-MM-dd 23:59:59")) :
                            item.periodo_data_tipo == 'E' ? // If (item.periodo_data_tipo == 'E')
                                `${new Date(format(item.valor, "yyyy-MM-dd 00:00:00"))} and ${new Date(format(item.valor, "yyyy-MM-dd 23:59:59"))}` :
                                item.valor
                }
            });

            onClose();
            setValues({})
            try {
                if (impressao) {
                    let data = new Date()
                    const offSet = data.getTimezoneOffset()
                    const resultado = await api.post(`${rotafechada()}relatorios_personalizados/imprimir/${relatorio_id}`, {
                        data: {
                            //conf:confRelatorio,
                            //itensRelatorio: itensTela,
                            relatorio_id,
                            filtros: dados2,
                            timeoffset: offSet
                        },
                        responseType: 'arraybuffer'
                    });

                    const file = new Blob([resultado.data], { type: 'application/pdf' });

                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                }
                if (!impressao) {
                    let url = `${rotafechada()}relatorios_personalizados/gerardados/${relatorio_id}`;
                    const retorno = await api.post(encodeURI(url), dados2);
                    const data = retorno.data;

                    if (configuracao) {
                        setConfiguraRelatorio({
                            ...configuraRelatorio,
                            relatorio_id,
                            datasets: data.datasets,
                            filtros: dados2,
                            open: true
                        })
                    }
                }
            }
            catch (error) {
                const msg = error.response?.data?.msg || error;
                MensagemErro(`Erro: ${msg}`);
                if (!msg == 'Relatório sem Informações') setDialogoSimples({ ...dialogoSimples, open: true, linhas: [error.response?.data?.erro], title: 'Erro na montagem do SQL' })
                //alert(error.response?.data?.erro); //aqui informa o erro do SQL
            }

        } else {
            // Se algum campo obrigatório não estiver preenchido, mostre uma mensagem de erro
        }

    }

    const buscaFiltros = async () => {
        try {
            let url = `${rotafechada()}relatorios_personalizados_filtros/configurafiltros/${relatorio_id}`;
            const retorno = await api.get(encodeURI(url));
            const data = retorno.data;
            setDataset(data.data)

            // let temp = {}
            // for (let i = 0; i < data.data.length; i++) {
            //     temp = { ...temp, [data.data[i].id]: '' }
            // }
            // setValues(temp)
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }

    }

    const handleClickPesquisa = () => {
        if (!campoPesquisa.id) {
            MensagemErro('Selecione o campo que deseja pesquisar');
            return;
        }
        setPage(0);
        setRecarregaDados(!recarregaDados);
    }

    const handleCloseDialogoSimples = () => {
        setDialogoSimples({ ...dialogoSimples, open: false });
    }

    const desenhaFiltro = () => { //Desenha os filtros de pesquisa do filtro de chave estrangeira
        //só desenha a linha do filtro de veio campos de filtro na props
        if ((camposFiltro) && (camposFiltro.length > 0)) {
            return (
                //Grid do Filtro
                < Grid container spacing={2} justifyContent="center" >

                    <Grid item xs={12} sm={2}  >{/* Combo com os campos que podem ser pesquisados */}
                        <AutoCompleteStyled

                            options={camposFiltro}
                            //getOptionLabel={(option) => option.label}
                            renderInput={(params) => <FormField {...params} label='Campo' />}
                            value={campoPesquisa}
                            onChange={(event, newValue) => {
                                setCampoPesquisa(newValue);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={8}  >{/*Valor a ser Pesquisado*/}
                        <FormField type="text" label="Valor a ser Pesquisado" value={valorPesquisa} onChange={(e) => setValorPesquisa(e.target.value)}

                            inputProps={
                                {
                                    onKeyPress: (e) => {

                                        if (e.key === 'Enter') {
                                            handleClickPesquisa();
                                        }
                                    },
                                }
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={1} >{/*Botão do Filtro*/}
                        <MDButton onClick={handleClickPesquisa} color='info'> Pesquisar</MDButton>
                    </Grid>

                    <Grid item xs={12} sm={12}>

                    </Grid>

                </Grid >
            )
        }
        else return false;
    }

    const handleClickSelecionado = (...ref) => {

        let objeto = dataset.find(item => item.id === filtroAtual);

        if (objeto.multipla_escolha == 'S') {
            setValues(prevValues => ({
                ...prevValues,
                [filtroAtual]: prevValues[filtroAtual]
                    ? `${prevValues[filtroAtual]}${ref[0]};`
                    : `${ref[0]};`
            }));
        } else {
            setValues({ ...values, [filtroAtual]: `${ref[0]}` })
        }

        //setValues({ ...values, [filtroAtual]: `${ref[0]};` }) //aqui é onde coloca o valor que vem do F9

        // setValues(prevValues => ({
        //     ...prevValues,
        //     [filtroAtual]: prevValues[filtroAtual]
        //         ? `${prevValues[filtroAtual]}${ref[0]};`
        //         : `${ref[0]};`
        // }));

        // for (let key in values) {
        //     if (key == filtroAtual) {
        //       values[key] = ref[0];
        //     }
        //   }

        // if (props.handleClick) {
        //   props.handleClick(...ref);
        //   //handleClose();
        // }
        handleCancel2();
    }

    useEffect(() => {
        if (relatorio_id)
            buscaFiltros()
    }, [open])

    useEffect(() => {
        desenhaFiltro()
    }, [linhas])

    useEffect(() => {
        if (dialogoOpen.visible)
            buscaDados();
    }, [filtroAtual, page, rowsPerPage, order, orderBy, recarregaDados, dialogoOpen])

    return (
        <>

            <DialogoConfirma
                open={openConfirmaFechamentoConfiguracao || false}
                message={'Certeza que deseja fechar? Você perderá todas as alterações não salvas!'}
                title={'Confirmação'}
                handleCancel={() => setOpenConfirmaFechamentoConfiguracao(false)}
                handleConfirm={() => {
                    setOpenConfirmaFechamentoConfiguracao(false);
                    setConfiguraRelatorio({ ...configuraRelatorio, open: false });
                }}
            />
            <DialogoSimples
                open={dialogoSimples.open || false}
                linhas={dialogoSimples.linhas || []}
                onClose={handleCloseDialogoSimples}
                title={dialogoSimples.title || ''}
            />
            <ConfiguracaoRelatorioPersonalizado
                open={configuraRelatorio.open}
                onClose={() => setOpenConfirmaFechamentoConfiguracao(true)}
                datasets={configuraRelatorio.datasets}
                relatorio_id={configuraRelatorio.relatorio_id}
                filtros={configuraRelatorio.filtros}
            />
            <Dialog
                //sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                fullScreen
                //maxWidth="xl"
                //TransitionProps={{ onEntering: handleEntering }}
                open={dialogoOpen.visible}
                onClose={handleCancel2}
                {...other}>
                <DialogTitle>
                    <MDBox width="100%" display="flex" justifyContent="space-between">
                        {title || 'Filtros'}
                        <IconButton
                            // edge="end"
                            color="inherit"
                            onClick={handleCancel2}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>

                    </MDBox>
                </DialogTitle>
                <DialogContent dividers>
                    {/* conteudo aqui */}
                    <MDBox>
                        {
                            desenhaFiltro()
                        }
                        <Grid container>
                            <Grid item xs={12}>
                                <TabelaPesquisaDinamica
                                    colunas={colunasTable}
                                    linhas={linhas}
                                    campoRetorno={'id'}
                                    handleClick={handleClickSelecionado}
                                    countAll={countAll}
                                    page={page}
                                    setPage={setPage}
                                    rowsPerPage={rowsPerPage}
                                    setRowsPerPage={setRowsPerPage}
                                    order={order}
                                    setOrder={setOrder}
                                    orderBy={orderBy}
                                    setOrderBy={setOrderBy}
                                //campoAuxiliar={}
                                />
                            </Grid>
                        </Grid>
                    </MDBox>

                </DialogContent >
            </Dialog >

            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                maxWidth="xs"
                TransitionProps={{ onEntering: handleEntering }}
                open={open}
                onClose={handleCancel}
                {...other}>
                <DialogTitle>{title || 'Filtros'}</DialogTitle>
                <DialogContent dividers>
                    {dataset?.map((item) => {
                        if (item.tipo == 'DAT') {
                            return (
                                <>
                                    <Grid item xs={12} sm={12} pb={2}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR} key={item.id}>
                                            <DesktopDatePicker
                                                required={item.obrigatorio == 'S' ? true : false}
                                                disabled={false}
                                                label={item.label}
                                                inputFormat="dd/MM/yyyy"
                                                value={values[item.id] || null}
                                                onChange={(e) => setValues({ ...values, [item.id]: e })}
                                                renderInput={(params) => <FormField {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: 'dd/mm/aaaa' // Adicione seu placeholder aqui
                                                    }}
                                                    required={item.obrigatorio == 'S' ? true : false}
                                                    InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                                />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </>
                            );
                        } else if (item.tipo == 'INT') {
                            return (
                                <>
                                    <Grid container spacing={1} pb={2}>
                                        <Grid item xs={12} sm={item.sql_chave_estrangeira.length > 0 ? 10 : 12}>{/*inteiro*/}
                                            <FormField
                                                defaultValue={item.valor_padrao}
                                                // type={item.multipla_escolha == 'S' ? 'text' : 'number'}
                                                type={'text'}
                                                label={item.label}
                                                rows={1}
                                                multiline={false}
                                                required={item.obrigatorio == 'S' ? true : false}
                                                disabled={false}
                                                value={values[item.id]}
                                                onChange={(e) => setValues({ ...values, [item.id]: e.target.value })}
                                                key={item.id}
                                                inputProps={{
                                                    onKeyUp: (e) => {
                                                        e.preventDefault();
                                                        if (e.key === 'F9' && item.sql_chave_estrangeira.length > 0) {
                                                            abreTelaPesquisa(item.id)
                                                        }

                                                    },
                                                }}
                                                InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                            />

                                        </Grid>
                                        {
                                            item.sql_chave_estrangeira.length > 0 &&
                                            <>
                                                <Grid item xs={12} sm={2}>{/*botao lupa do inteiro*/}
                                                    <MDBox width="100%" height='100%' display="flex" justifyContent='center' alignItems='center'>
                                                        <SearchIcon sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                abreTelaPesquisa(item.id)
                                                            }} />
                                                    </MDBox>
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                </>
                            );
                        } else if (item.tipo == 'STR') {
                            return (
                                <>
                                    <Grid item xs={12} sm={12} pb={2} >
                                        <FormField
                                            defaultValue={item.valor_padrao}
                                            type='text'
                                            label={item.label}
                                            rows={1}
                                            multiline={false}
                                            required={item.obrigatorio == 'S' ? true : false}
                                            disabled={false}
                                            value={values[item.id]}
                                            onChange={(e) => setValues({ ...values, [item.id]: e.target.value })}
                                            key={item.id}
                                            InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                        />
                                    </Grid>
                                </>
                            );
                        } else if (item.tipo == 'DEC') {
                            return (
                                <>
                                    <Grid item xs={12} sm={12} pb={2} >
                                        <FormField
                                            defaultValue={item.valor_padrao}
                                            type='number'
                                            label={item.label}
                                            rows={1}
                                            multiline={false}
                                            required={item.obrigatorio == 'S' ? true : false}
                                            disabled={false}
                                            value={values[item.id]}
                                            onChange={(e) => setValues({ ...values, [item.id]: parseFloat(e.target.value) })}
                                            key={item.id}
                                            InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                        />
                                    </Grid>
                                </>
                            );
                        } else if (item.tipo == 'OPC') {
                            return (
                                <>
                                    <Grid item xs={12} sm={12} pb={2} >
                                        <AutoCompleteStyled
                                            autoHighlight
                                            // options={item.Relatorios_Personalizados_Filtros_Opcaos}
                                            options={item.Relatorios_Personalizados_Filtros_Opcaos.map(item => ({ label: item.opcao, value: item.comando }))}
                                            getOptionLabel={(option) => option.label || ''}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            renderInput={(params) => <FormField {...params} label={item.label} />}
                                            // value=
                                            value={values[item.id]}
                                            onChange={(e, newValue) => setValues({ ...values, [item.id]: newValue.value })}
                                            // onChange={(event, newValue) => {
                                            //     setValues(newValue);
                                            // }}
                                            defaultValue={null}
                                        />
                                    </Grid>
                                </>
                            );
                        } else {
                            return null;
                        }
                    })}
                </DialogContent >
                <DialogActions>
                    <MDButton onClick={handleCancel} variant="outlined" color="secondary">
                        Cancelar
                    </MDButton>
                    <MDButton onClick={handleConfirm} variant="gradient" color={color ? color : 'info'} sx={{ height: "100%" }} >
                        Confirmar
                    </MDButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

