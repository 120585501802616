import MDBox from "components/MDBox";
import PoliticaDePrivacidadeLayout from "./politicadeprivacidadelayout";
import bgImage from "assets/images/personalizados/headerlogin.png";
import { Grid, Typography } from "@mui/material";

function PoliticaDePrivacidade() {

  return (
    <PoliticaDePrivacidadeLayout image={bgImage}>
      <MDBox
        mx={2}
        mt={-3}
        p={3}
        mb={1}
      >
        <Grid container spacing={3}>

          <Grid item xs={10} sm={10}>


            <Typography variant='h3' mt={2} mb={2} >
            POLÍTICA DE PRIVACIDADE DE SOFTWARE
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>Bem-vindo(a)!</p>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>Obrigado por utilizar um dos nossos softwares. A empresa Invictos Tecnologia LTDA., pessoa Jurídica de Direito  Privado, inscrita no CNPJ nº 11.758.339/0001-78 produz e comercializa os softwares: Invictos Vidros, Invictos Essencial, Invictos Comercial, Invictos Financeiro e Invictos WEB, todos eles são regidos pela política abaixo, sendo que algum deles por questões de tecnologia específica, como por exemplo ser armazenado em “nuvem” poderão ter itens específicos nessa política. A partir de agora nesse documento todos eles serão identificados como SOFTWARE.</p>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>Quando você utiliza o SOFTWARE, você nos confia seus dados e informações. Nos comprometemos a manter essa confiança. </p>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>Nesse sentido, a presente Política de Privacidade (“Política”) explica de maneira clara e acessível como as suas informações e dados serão coletados, usados, compartilhados e armazenados por meio dos nossos sistemas.</p>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>A aceitação da nossa Política será feita quando você acessar ou usar o site, aplicativo ou serviços da Invictos Tecnologia. Isso indicará que você está ciente e em total acordo com a forma como utilizaremos as suas informações e seus dados.</p>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>A presente Política está dividida da seguinte forma para facilitar a sua compreensão:</p>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>
                <l>
                  <li>(1) Quais informações o SOFTWARE coleta</li>
                  <li>(2) Como o SOFTWARE  usa as informações coletadas</li>
                  <li>(3) Como, quando e com quem a Invictos Tecnologia compartilha suas informações</li>
                  <li>(4) Quais direitos você tem sobre as informações que coletamos</li>
                  <li>(5) Como a Invictos Tecnologia protege suas informações</li>
                  <li>(6) Atualizações dessa política de privacidade</li>
                  <li>(7) Lei aplicável</li>
                </l>
              </p>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>Este documento deve ser lido em conjunto com o nosso Termos de Uso e/ou contrato de Prestação de serviços, para que tenha uma visão geral da nossa plataforma. Caso haja dúvidas ou precise tratar de qualquer assunto relacionado a esta Política, entre em contato conosco através do e-mail contato@invictos.com.br. </p>
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              1. INFORMAÇÕES QUE COLETAMOS
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>Nós coletamos os seguintes tipos de informações:</p>
            </Typography>
            
            <Typography variant='h6' mt={2} mb={2}>
              1.1. Informações que você nos fornece.
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>Isso inclui:</p>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>
                <l>
                  <li>Dados de cadastro. Quando você se cadastra no  SOFTWARE, você nos fornece as seguintes informações: Contrato Social, CNH ou RG, Comprovante de Residência do administrador da empresa, endereço de e-mail, Estado Civil do representante da empresa, seu número de telefone, dados de contato do escritório contábil responsável em casos de necessidade de configuração de módulos fiscais.</li>
                  <li>Informações de autenticação. Para lhe proporcionar um ambiente seguro, podemos pedir que você nos forneça informações de identificação coletadas no cadastro e/ou outras informações de autenticação, como por exemplo, SMS ou e-mail. Garantindo assim a autenticação do uso da sua conta.</li>
                </l>
              </p>              
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              1.2 Informações geradas quando você usa nossos serviços.
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>Nós coletamos as seguintes informações geradas:</p>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>
                <l>
                  <li>Registros de acesso. o SOFTWARE coleta automaticamente registros de acesso a aplicação, que incluem o endereço IP, com data e hora, utilizado para acessar a plataforma. Esses dados são de coleta obrigatória, de acordo com a Lei 12.965/2014, mas somente serão fornecidos para terceiros com a sua autorização expressa ou por meio de demanda judicial.</li>
                  <li>Dados de pagamento. Quando você realiza pagamentos no SOFTWARE, poderão ser armazenados dados do pagamento, como a data e hora, o valor e outros detalhes da transação, que poderão ser utilizados inclusive para fins de prevenção à fraude. Desta forma, poderemos lhe proporcionar um ambiente seguro e adequado para você realizar as suas transações.</li>
                  <li>Comunicações com o SOFTWARE. Quando você se comunica com o SOFTWARE, coletamos informações sobre sua comunicação, incluindo metadados como data, IP e hora das comunicações e todo o seu conteúdo, assim como qualquer informação que você escolha fornecer.</li>
                  <li>Cookies e tecnologias semelhantes. Nós utilizamos cookies, que são arquivos de texto gerados e armazenados no seu navegador ou aparelho por sites, aplicativos e anúncios online. Os cookies poderão ser utilizados para as seguintes finalidades: autenticar usuários, lembrar preferências e configurações do usuário.</li>
                  <li>Outras informações geradas. Ao utilizar certos recursos, do SOFTWARE pode coletar informações geradas por você como, registros de exclusão e Registros de acesso ao sistema. </li>
                </l>
              </p>              
            </Typography>
            
            <Typography variant='h6' mt={2} mb={2}>
              2. COMO USAMOS SUAS INFORMAÇÕES
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>Não custa lembrar, prezamos muito pela sua privacidade. Por isso, todos os dados e informações sobre você são tratadas como confidenciais, e somente as usaremos para os fins aqui descritos e autorizados por você, principalmente para que você possa utilizar o SOFTWARE de forma plena, visando sempre melhorar a sua experiência como usuário. </p>
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              2.1. Usos autorizados.
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>Desta forma, poderemos utilizar seus dados para:</p>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>
                <l>
                  <li>Permitir que você acesse e utilize todas as funcionalidades do SOFTWARE;</li>
                  <li>Enviar a você mensagens a respeito de suporte ou serviço, como alertas, notificações e atualizações;</li>
                  <li>Nos comunicar com você sobre produtos, serviços, promoções, notícias, atualizações, eventos e outros assuntos que você possa ter interesse;</li>
                  <li>Detecção e prevenção de fraudes, spam e incidentes de segurança;</li>
                  <li>Verificar ou autenticar as informações fornecidas por você, inclusive comparando a dados coletados de outras fontes;</li>
                  <li>Registro interno e criação de contrato de prestação de serviço.;</li>
                  <li>Para qualquer fim que você autorizar no momento da coleta de dados;</li>
                  <li>Cumprir obrigações legais.</li>
                </l>
              </p>              
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>Eventualmente, poderemos utilizar dados para finalidades não previstas nesta política de privacidade, mas estas estarão dentro das suas legítimas expectativas. O eventual uso dos seus dados para finalidades que não cumpram com essa prerrogativa será feito mediante sua autorização prévia.</p>
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              2.2. Exclusão dos dados
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>Todos os dados coletados serão excluídos de nossos servidores quando você assim requisitar, por procedimento gratuito e facilitado, ou quando estes não forem mais necessários ou relevantes para lhe oferecermos os nossos serviços, salvo se houver qualquer outra razão para a sua manutenção, como eventual obrigação legal de retenção de dados ou necessidade de preservação destes para resguardo de direitos do SOFTWARE.</p>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>Nos casos em que você solicite a exclusão dos seus dados, manteremos os seus dados por 03 (três) meses contados a partir da sua solicitação de exclusão.</p>
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              3. COMPARTILHAMENTO DAS INFORMAÇÕES
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>Todos os dados, informações e conteúdos sobre você podem ser considerados ativos no caso de negociações em que o SOFTWARE fizer parte. Portanto, nos reservamos no direito de, por exemplo, incluir seus dados dentre os ativos da empresa caso esta venha a ser vendida, adquirida ou fundida com outra. Por meio desta Política você concorda e está ciente desta possibilidade.</p>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>O SOFTWARE se reserva no direito de fornecer seus dados e informações sobre você, incluindo interações suas, caso seja requisitado judicialmente para tanto, ato necessário para que a empresa esteja em conformidade com as leis nacionais, ou caso você autorize expressamente.</p>
            </Typography>

            <Typography variant='h6' mt={2} mb={2}>
              4. DIREITOS DOS TITULARES
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 0, textAlign: 'justify' }}><strong>4.1.</strong> Direito de acesso. Este direito permite que você possa requisitar e receber uma cópia dos dados pessoais que possuímos sobre você.</p>
              <p style={{ marginBottom: 0, textAlign: 'justify' }}><strong>4.2.</strong> Direito de retificação. Este direito permite que você, a qualquer momento, possa solicitar a correção e/ou retificação dos seus dados pessoais, caso identifique que alguns deles estão incorretos. Contudo, para ser efetivada essa correção, teremos que checar a validade dos dados que você nos fornece. Você pode retificar diretamente alguns dos seus dados pessoais através do e-mail contato@invictos.com.br.</p>
              <p style={{ marginBottom: 0, textAlign: 'justify' }}><strong>4.3.</strong> Direito de exclusão. Este direito permite que você possa nos solicitar a exclusão dos dados pessoais que possuímos sobre você. Todos os dados coletados serão excluídos de nossos servidores quando você assim requisitar ou quando estes não forem mais necessários ou relevantes para lhe oferecermos nossos serviços, salvo se houver qualquer outra razão para a sua manutenção, como eventual obrigação legal de retenção de dados ou necessidade de preservação destes para resguardo de direitos do SOFTWARE. Para alterar suas informações pessoais ou excluí-las do nosso banco de dados, basta enviar um e-mail para contato@invictos.com.br.</p>
              <p style={{ marginBottom: 0, textAlign: 'justify' }}><strong>4.4</strong> Direito de oposição ao processamento. Você também tem o direito de contestar onde e em que contexto estamos tratando seus dados pessoais para diferentes finalidades. Em determinadas situações, podemos demonstrar que temos motivos legítimos para tratar seus dados, os quais se sobrepõem aos seus direitos, caso, por exemplo, sejam essenciais para o fornecimento de nossas aplicações.</p>
              <p style={{ marginBottom: 0, textAlign: 'justify' }}><strong>4.5</strong> Direito de solicitar anonimização, bloqueio ou eliminação. Este direito permite que você nos peça para suspender o processamento de seus dados pessoais nos seguintes cenários: (a) se você quiser que nós estabeleçamos a precisão dos dados; (b) quando você precisar que sejam mantidos os dados mesmo se não precisarmos mais deles, conforme necessário, para estabelecer, exercer ou defender reivindicações legais; ou (c) se você se opôs ao uso de seus dados, mas nesta hipótese precisamos verificar se temos motivos legítimos para usá-los.</p>
              <p style={{ marginBottom: 0, textAlign: 'justify' }}><strong>4.6</strong> Direito à portabilidade. Forneceremos a você, ou a terceiros que você escolheu, seus dados pessoais em formato estruturado e interoperável.</p>
              <p style={{ marginBottom: 0, textAlign: 'justify' }}><strong>4.7</strong> Direito de retirar o seu consentimento. Você tem o direito de retirar o seu consentimento em relação aos termos desta Política de Privacidade. No entanto, isso não afetará a legalidade de qualquer processamento realizado anteriormente. Se você retirar o seu consentimento, talvez não possamos fornecer determinados serviços.</p>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}><strong>4.8</strong> Direito a revisão de decisões automatizadas. Você também tem o direito de solicitar a revisão de decisões tomadas unicamente com base em tratamento automatizado de seus dados pessoais que afetem seus interesses, incluídas as decisões destinadas a definição de perfis pessoais, profissionais, de consumo e de crédito e/ou os aspectos de sua personalidade.</p>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>Talvez seja necessário solicitar informações específicas suas para nos ajudar a confirmar sua identidade e garantir seu direito de acessar seus dados pessoais (ou de exercer seus outros direitos). Esta é uma medida de segurança para garantir que os dados pessoais não sejam divulgados a qualquer pessoa que não tenha direito de recebê-los. Podemos também contatá-lo para obter mais informações em relação à sua solicitação, a fim de acelerar nossa resposta. Tentamos responder a todas as solicitações legítimas dentro de 5 dias úteis. Ocasionalmente, pode levar mais de 5 dias se sua solicitação for particularmente complexa ou se você tiver feito várias solicitações. Neste caso, iremos comunicá-lo e mantê-lo atualizado sobre o andamento da sua solicitação.</p>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>Caso Você tenha alguma dúvida sobre essas questões e sobre como você pode exercer esses direitos, fique à vontade para entrar em contato conosco no e-mail contato@invictos.com.br.</p>
            </Typography>
            
            <Typography variant='h6' mt={2} mb={2}>
              5. SEGURANÇA DAS INFORMAÇÕES
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>Todos os seus dados são confidenciais e somente as pessoas com as devidas autorizações terão acesso a eles. Qualquer uso destes estará de acordo com a presente Política. A Invictos Tecnologia empreenderá todos os esforços razoáveis de mercado para garantir a segurança dos nossos sistemas e dos seus dados. Nossos servidores estão localizados em diferentes locais para garantir estabilidade e segurança, e somente podem ser acessados por meio de canais de comunicação previamente autorizados.</p>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>Todas as suas informações serão, sempre que possível, criptografadas, caso não inviabilizem o seu uso pela plataforma. A qualquer momento você poderá requisitar cópia dos seus dados armazenados em nossos sistemas. Manteremos os dados e informações somente até quando estas forem necessárias ou relevantes para as finalidades descritas nesta Política, ou em caso de períodos pré-determinados por lei, ou até quando estas forem necessárias para a manutenção de interesses legítimos da Invictos Tecnologia. </p>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>A Invictos Tecnologia considera a sua privacidade algo extremamente importante e fará tudo que estiver ao alcance para protegê-la. Todavia, não temos como garantir que todos os dados e informações sobre você em nossa plataforma estarão livres de acessos não autorizados, principalmente caso haja compartilhamento indevido das credenciais necessárias para acessar o nosso aplicativo. Portanto, você é o único responsável por manter sua senha de acesso em local seguro e é vedado o compartilhamento desta com terceiros. Você se compromete a notificar a Invictos Tecnologia imediatamente, através de meio seguro, a respeito de qualquer uso não autorizado de sua conta, bem como o acesso não autorizado por terceiros a esta.</p>
            </Typography>
            
            <Typography variant='h6' mt={2} mb={2}>
              6. ATUALIZAÇÕES DA POLÍTICA DE PRIVACIDADE
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>A Invictos Tecnologia se reserva no direito de alterar essa Política quantas vezes forem necessárias, visando fornecer a você mais segurança, conveniência, e melhorar cada vez mais a sua experiência. É por isso que é muito importante acessar nossa Política periodicamente. Para facilitar, indicamos no início do documento a data da última atualização. Caso sejam feitas alterações relevantes que ensejem novas autorizações suas, publicaremos uma nova política de privacidade, sujeita novamente ao seu consentimento.</p>
            </Typography>
            
            <Typography variant='h6' mt={2} mb={2}>
              7. LEI APLICÁVEL
            </Typography>

            <Typography variant='body2'>
              <p style={{ marginBottom: 10, textAlign: 'justify' }}>Este documento é regido e deve ser interpretado de acordo com as leis da República Federativa do Brasil. Fica eleito o Foro da Comarca de Dourados, Mato Grosso do Sul, como o competente para dirimir quaisquer questões porventura oriundas do presente documento, com expressa renúncia a qualquer outro, por mais privilegiado que seja.</p>
            </Typography>

            <Typography variant='body2' mt={6}>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>  Dourados/MS, Abril de 2024</p>
              <p style={{ marginBottom: 6, textAlign: 'justify' }}>INVICTOS TECNOLOGIA LTDA</p>
            </Typography>

          </Grid>
        </Grid>
      </MDBox>
    </PoliticaDePrivacidadeLayout >
  );
}

export default PoliticaDePrivacidade;
