
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tab from '@mui/material/Tab';
import { Autocomplete, Box, Icon, IconButton, Tooltip, Typography } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Tabela from "./Tabela";
import Cadastro from "./Cadastro";

import { useTrataRetorno } from "commons/authUtils";
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import AuthContext from "hooks/AuthContext";
import DialogoConfirma from 'componentes/DialogoConfirma';
import MenuConfiguracaoModulos from "componentes/MenuConfiguracaoModulos";



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.any,
  index: PropTypes.number,
  value: PropTypes.number,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Usuarios(props) {

  const [codigoCarregado, setCodigoCarregado] = useState('');
  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const [recarregaTabela, setRecarregaTabela] = useState(false);
  const [enabledBotaoGravar, setEnabledBotaoGravar] = useState(true);
  const [openFiltrosAvancados, setOpenFiltrosAvancados] = useState(false);
  const [openMenuConfiguracao, setOpenMenuConfiguracao] = useState(false);
  const [currentTargetBotaoMenu, setCurrentTargetBotaoMenu] = useState(null);
  const trataRetorno = useTrataRetorno();
  const baseUrl = `${rotafechada()}empresa_usuarios`;
  const baseCaption = 'Usuários';

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${baseUrl}/permissao/${operacao}`);
      return true;
    }
    catch (error) {
      trataRetorno(error?.response?.data?.erro);
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }

  const abrirRegistro = async (id) => {

    if (!parseInt(id, 10) > 0)
      return;

    try {
      setCodigoCarregado(id);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleEmail = async () => {
    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      const dados = {}
      dados.id = dialogoOpen.id
      let retorno;

      MostraTelaEspera('Enviando email'); //Abre tela de espera
      retorno = await api.post(`${rotafechada()}empresa_usuarios/email`, dados);

      FechaTelaEspera(); //Fecho tela de espera
      if (retorno.data.data == 'habilitado') {
        MensagemAviso('Usuário já habilitado');
      }
      else MensagemAviso('Email enviado com sucesso!');

      setCodigoCarregado('');
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      FechaTelaEspera(); //Fecho tela de espera
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickEmail = async (id) => {

    setDialogoOpen({ ...dialogoOpen, visible: true, id });
  }

  useEffect(async () => {

    if (parseInt(codigoCarregado, 10) > 0) {
      if (await validaRotaPermissao('editar') === false) {
        //alem da mensagem, desativo o botao de gravar
        setEnabledBotaoGravar(false);
      }
    }
    else {
      if (await validaRotaPermissao('inserir') === false) {
        //alem da mensagem, desativo o botao de gravar
        setEnabledBotaoGravar(false);
      }
    }


  }, [])

  return (
    <>

      <MenuConfiguracaoModulos
        open={openMenuConfiguracao}
        setOpen={setOpenMenuConfiguracao}
        nomeModulo='EMPRESA_USUARIOS'
        currentTarget={currentTargetBotaoMenu}
      />
      <DashboardLayout>
        <DashboardNavbar />

        <DialogoConfirma
          open={dialogoOpen.visible}
          title={'Confirma Envio de Email'}
          message={'Certeza que deseja enviar email de ativação?'}
          handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
          handleConfirm={handleEmail}
        />

        <MDBox mt={5} mb={9}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={12}>
              <MDBox mt={0} mb={4} textAlign="center">
                <MDBox mb={1}>
                  <MDTypography variant="h3" fontWeight="bold">
                    {`${baseCaption}`}
                  </MDTypography>
                </MDBox>
                <MDTypography variant="h5" fontWeight="regular" color="secondary">
                  { }
                </MDTypography>
                {/* Botao de Configuracoes (Parametros e Permissoes) */}
                <MDBox width="100%" display="flex" justifyContent="flex-end">
                  <Tooltip title="Configurações e Permissões" placement="top">
                    <IconButton
                      onClick={(event) => {
                        setCurrentTargetBotaoMenu(event.currentTarget);
                        setOpenMenuConfiguracao(true);
                      }
                      }
                    >
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>

              <Card>
                {/*<TabPanel value={aba} index={0}>*/}

                <MDBox width="100%" display="flex" justifyContent="space-between">

                  {/*CADASTRO */}
                  <Cadastro
                    setUsuarioAtual={setCodigoCarregado}
                    usuarioAtual={codigoCarregado}
                    recarregaTabela={recarregaTabela}
                    setRecarregaTabela={setRecarregaTabela}
                  />

                </MDBox>

                <Tabela
                  openFiltrosAvancados={openFiltrosAvancados}
                  setOpenFiltrosAvancados={setOpenFiltrosAvancados}
                  abrirRegistro={abrirRegistro}
                  handleClickEmail={handleClickEmail}
                  recarregaTabela={recarregaTabela}
                  validaRotaPermissao={validaRotaPermissao} />
                {/*</TabPanel>*/}


              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default Usuarios;
