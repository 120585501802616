import React, { useContext, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, Grid, IconButton, Typography, Autocomplete, Tooltip, Divider, Icon, FormControlLabel } from '@mui/material';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';

import MDTypography from 'components/MDTypography';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import MDButton from 'components/MDButton';
import AuthContext from 'hooks/AuthContext';
import TabelaOpcoes from './tabela';
import DialogoConfirma from 'componentes/DialogoConfirma';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import InfoIcon from '@mui/icons-material/Info';
import TabelaPadrao from 'componentes/TabelaPadrao';
import AceEditor from 'react-ace';

function DadosCampoFiltro(props) {

    const { dadosCampoFiltro, setDadosCampoFiltro, campoFiltro, label } = props;
    // console.log('dadosCampoFiltro:', dadosCampoFiltro);
    return (
        <>
            <Grid container spacing={2}>

                < Grid item xs={12} sm={12} >
                    <Typography variant='title' fontWeight="bold">{label}</Typography>
                    <Divider
                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                        style={{
                            marginTop: 20,
                            border: "none",
                            height: 2,
                            margin: 0,
                        }}
                    />
                </Grid >
                {dadosCampoFiltro?.map((item, index) => {
                    return (
                        <>
                            <Grid item xs={12} sm={3}> {/* CAMPO FILTRO */}
                                <FormField
                                    required={true}
                                    type="text"
                                    label="Campo filtro"
                                    value={item.campoFiltro}
                                    onChange={(e) => {
                                        let tempValor = [...dadosCampoFiltro]
                                        tempValor[index].campoFiltro = e.target.value
                                        setDadosCampoFiltro(tempValor);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}> {/* CAMPO CHECKBOX */}
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={item.buscaParcial}
                                            onChange={(e) => {
                                                let tempChecked = [...dadosCampoFiltro]
                                                tempChecked[index].buscaParcial = e.target.checked
                                                setDadosCampoFiltro(tempChecked);
                                            }}
                                        />
                                    }
                                    label="Busca parcial"
                                />
                            </Grid>

                            {
                                dadosCampoFiltro.length > 1 &&
                                <>
                                    <Grid item xs={12} sm={1}> {/*REMOVER*/}
                                        <Tooltip title="Remover">
                                            <MDButton
                                                size="medium"
                                                type="button"
                                                iconOnly
                                                onClick={() => {
                                                    let tempRemover = [...dadosCampoFiltro];
                                                    tempRemover.splice(index, 1)

                                                    setDadosCampoFiltro(tempRemover)
                                                }}
                                            >
                                                <Icon>close</Icon>
                                            </MDButton>

                                        </Tooltip>
                                    </Grid>
                                </>
                            }
                        </>
                    )
                })}
            </Grid>
            <Grid container mt={2}> {/*ADICIONAR CAMPO FILTRO*/}
                <MDBox width="100%" display="flex" justifyContent="flex-start">
                    <MDButton
                        variant="outlined"
                        color="info"
                        type="button"
                        onClick={() => {
                            let vetorTemp = [...dadosCampoFiltro];

                            vetorTemp.push({
                                campoFiltro: '',
                                buscaParcial: false
                            },)
                            setDadosCampoFiltro(vetorTemp)
                        }}
                        size='small'
                    > + Adicionar campo filtro
                    </MDButton>
                </MDBox>
            </Grid>
            <Divider
                sx={{ bgcolor: (theme) => theme.palette.divider }}
                style={{
                    marginTop: 20,
                    border: "none",
                    height: 2,
                    margin: 0,
                }}
            />
        </>

    )
}

const Relatorios_Personalizados_Filtros = (props) => {


    const { handleSubmit, control, setValue, getValues, Relatorios_PersonalizadosAtual, recarregaComponenteOpcoes, opcoesTipo, opcoesPeriodoDataTipo, opcoesOperadorJuncao } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MensagemAlerta } = messageContext;
    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();
    const [openCollapse, setOpenCollapse] = useState(false);
    const [openCollapseChaveEstrangeira, setOpenCollapseChaveEstrangeira] = useState(false);
    const [openCollapseMultiplaEscolha, setOpenCollapseMultiplaEscolha] = useState(false);
    const [openCollapseSqlAdicional, setOpenCollapseSqlAdicional] = useState(false);
    const [openCollapseOpcoes, setOpenCollapseOpcoes] = useState(false);
    const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
    const [dialogoOpenOpcao, setDialogoOpenOpcao] = useState({ visible: false, id: null });
    const opcaoDefault = [{ id: -1, value: -1, label: '<Selecione...>' }];
    const [recarregaTabela, setRecarregaTabela] = useState(false);
    const [codigoAtual, setCodigoAtual] = useState('');
    const [vetorPerfis, setVetorPerfis] = useState([])
    const [tipoValue, setTipoValue] = useState('');
    const [dadosCampoFiltro, setDadosCampoFiltro] = useState([]);
    const [campoFiltro, setCampoFiltro] = useState('');

    const objModeloRelatorios_Personalizados_Filtros = {
        id: { type: 'text' },
        relatorio_personalizado_id: { type: 'text' },
        nome: { type: 'text' },
        tipo: { type: 'autocomplete' },
        periodo_data_tipo: { type: 'autocomplete' },
        label: { type: 'text' },
        obrigatorio: { type: 'checkbox' },
        usa_em_branco: { type: 'checkbox' },
        sql_chave_estrangeira: { type: 'text' },
        campo_sql_chave_estrangeira: { type: 'text' },
        texto_filtro_utilizado: { type: 'text' },
        multipla_escolha: { type: 'checkbox' },
        operador_multipla_escolha: { type: 'autocomplete' },
        campo_multipla_escolha: { type: 'text' },
        sql_adicional: { type: 'text' },
        sql_adicional_nome_filtro: { type: 'text' },
        valor_padrao: { type: 'text' },
        opcao: { type: 'text' },
        comando: { type: 'text' },

    }

    const [countAll, setCountAll] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [colunasTable, setColunasTable] = React.useState([
        {
            id: 'id',
            numeric: false,
            disablePadding: true,
            label: 'Id'
        },
        {
            id: 'filtro',
            numeric: false,
            disablePadding: true,
            label: 'Filtro'
        },
        {
            id: 'opcao',
            numeric: false,
            disablePadding: true,
            label: 'Opção'
        },
        {
            id: 'comando',
            numeric: false,
            disablePadding: true,
            label: 'Comando'
        },
        {
            id: 'acoes',
            numeric: false,
            disablePadding: true,
            label: 'Ações',
            disableOrder: true
        },
    ]);
    const [linhas, setLinhas] = React.useState([]);

    const validaRotaPermissao = async (operacao = '', silencioso = false) => {
        if ((operacao !== 'inserir') &&
            (operacao !== 'editar') &&
            (operacao !== 'excluir') &&
            (operacao !== 'listar')) {
            MensagemErro('Escolha a operação que deseja validar a permissão!');
            return false;
        }

        //validando a permissao
        try {
            await api.get(`${rotafechada()}relatorios_personalizados_filtros/permissao/${operacao}`);
            return true;
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;

            if (silencioso === false) {
                MensagemErro(`Erro: ${msg}`);
            }
            return false;
        }
    }

    const validaCampos = (data) => {

        let camposObrigatorios = ``;

        if (!data.nome) {
            camposObrigatorios = `${camposObrigatorios}/Nome`
        }

        if (camposObrigatorios !== '') {
            throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
        }
    }

    const gravarRegistro = async (data) => {

        const dados = {};
        //no data esta todos os dados do RHF registrados, o que me interesse é 
        //apenas o objeto "relatorios"
        //assim resgato no data apenas os campos que se referem a relatorios
        //e monto o objeto que sera enviado para o back
        Object.keys(objModeloRelatorios_Personalizados_Filtros).forEach((nome) => {
            dados[nome] = '';
            if (nome in data['relatorios_filtros']) {
                if (typeof data['relatorios_filtros'][nome] === 'object') {
                    dados[nome] = data['relatorios_filtros'][nome]?.value ? data['relatorios_filtros'][nome]?.value : '';
                }
                else
                    if (typeof data['relatorios_filtros'][nome] === 'boolean') {
                        dados[nome] = data['relatorios_filtros'][nome] === true ? 'S' : 'N'
                    }
                    else dados[nome] = data['relatorios_filtros'][nome];
            }
        })

        dados.empresa_id = sessaolocal.empresa_id || null;
        dados.filtro_sql_chave_estrangeira = dadosCampoFiltro
        dados.relatorio_personalizado_id = Relatorios_PersonalizadosAtual
        delete dados.id;

        try {
            validaCampos(dados);
        }
        catch (error) {
            MensagemErro(`${error.message}`);
            return;
        }
        // console.log('dados filtros', dados)
        try {
            let retorno;
            let codigoTemp = data.relatorios_filtros.id;

            if ((data.relatorios_filtros.id) && (parseInt(data.relatorios_filtros.id, 10) > 0)) {
                retorno = await api.put(`${rotafechada()}relatorios_personalizados_filtros/${data.relatorios_filtros.id}`, dados);
            }
            else {
                retorno = await api.post(`${rotafechada()}relatorios_personalizados_filtros`, dados);
                codigoTemp = retorno.data.data.id;
            }

            MensagemAviso(`Gravado com sucesso!`);
            setCodigoAtual('');
            setRecarregaTabela(!recarregaTabela);
        }
        catch (error) {
            console.log(error);
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const gravaOpcao = async (data) => {

        const dados = {
            empresa_id: sessaolocal.empresa_id || null,
            relatorio_personalizado_filtro_id: getValues('relatorios_filtros.id'),
            opcao: getValues('relatorios_filtros.opcao'),
            comando: getValues('relatorios_filtros.comando')
        }
        // console.log('dados opção', dados)

        if (!dados.relatorio_personalizado_filtro_id) {
            MensagemAlerta('Primeiro grave o relatório para depois inserir as opções', 5)
        }
        else {
            if (!dados.opcao) {
                MensagemAlerta('Campo: Opção é obrigatório.')
            }
            else {
                try {
                    let retorno;

                    // if ((data.relatorios_filtros.id) && (parseInt(data.relatorios_filtros.id, 10) > 0)) {
                    //     retorno = await api.put(`${rotafechada()}relatorios_personalizados_filtros_opcao/${data.relatorios_filtros.id}`, dados);
                    // }
                    // else {
                    retorno = await api.post(`${rotafechada()}relatorios_personalizados_filtros_opcao`, dados);
                    // }

                    MensagemAviso(`Gravado com sucesso!`);

                    abrirRegistro(getValues('relatorios_filtros.id'))
                    //setCodigoAtual('');
                    //setRecarregaTabela(!recarregaTabela);
                }
                catch (error) {
                    console.log(error);
                    const msg = error.response?.data?.msg || error;
                    MensagemErro(`Erro: ${msg}`);
                }
            }
        }


    }

    const novoRegistro = () => {
        //limpo os campos
        Object.keys(objModeloRelatorios_Personalizados_Filtros).forEach((nome) => {
            if (objModeloRelatorios_Personalizados_Filtros[nome].type === 'autocomplete') {
                // setValue(`relatorios_filtros.${nome}`, opcaoDefault[0])
                setValue(`relatorios_filtros.${nome}`, { value: '', label: '' })
            }
            else
                if (objModeloRelatorios_Personalizados_Filtros[nome].type === 'checkbox') {
                    setValue(`relatorios_filtros.${nome}`, false)
                }
                else
                    if (objModeloRelatorios_Personalizados_Filtros[nome].type === 'text') {
                        setValue(`relatorios_filtros.${nome}`, '')
                    }
        })
        setDadosCampoFiltro([])
        setLinhas([]);
    }

    const replicaRegistro = async () => {
        try {
            let url = `${rotafechada()}relatorios_personalizados_filtros/replica-filtro/${getValues('relatorios_filtros.id')}`;
            const retorno = await api.get(encodeURI(url));
            MensagemAviso('Replicado com sucesso!');
            novoRegistro();
            setRecarregaTabela(!recarregaTabela);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const abrirRegistro = async (codigo) => {
        try {
            let url = `${rotafechada()}relatorios_personalizados_filtros?id=${codigo}`;
            const retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            // console.log('dados do filtros do relatorio', data);

            if (!data.length > 0) {
                MensagemErro(`Componente ID ${codigo} não localizado`);
                return;
            }
            setValue('relatorios_filtros.id', data[0].id);
            setValue('relatorios_filtros.obrigatorio', data[0].obrigatorio === 'S' ? true : false);
            setValue('relatorios_filtros.usa_em_branco', data[0].usa_em_branco === 'S' ? true : false);
            setValue('relatorios_filtros.multipla_escolha', data[0].multipla_escolha === 'S' ? true : false);
            setValue(`relatorios_filtros.nome`, data[0].nome);
            setValue(`relatorios_filtros.label`, data[0].label);
            setValue(`relatorios_filtros.texto_filtro_utilizado`, data[0].texto_filtro_utilizado);
            setValue(`relatorios_filtros.valor_padrao`, data[0].valor_padrao);
            setValue(`relatorios_filtros.sql_chave_estrangeira`, data[0].sql_chave_estrangeira);
            setValue(`relatorios_filtros.campo_sql_chave_estrangeira`, data[0].campo_sql_chave_estrangeira);
            setValue(`relatorios_filtros.campo_multipla_escolha`, data[0].campo_multipla_escolha);
            setValue(`relatorios_filtros.sql_adicional`, data[0].sql_adicional);
            setValue(`relatorios_filtros.sql_adicional_nome_filtro`, data[0].sql_adicional_nome_filtro);
            setValue(`relatorios_filtros.opcao`, '');
            setValue(`relatorios_filtros.comando`, '');
            setDadosCampoFiltro(data[0].filtro_sql_chave_estrangeira == null ? [] : data[0].filtro_sql_chave_estrangeira)

            let t_operador_multipla_escolha = data[0].operador_multipla_escolha;
            let operador_multipla_escolha = '';
            let label_operador_multipla_escolha = '';

            switch (t_operador_multipla_escolha) {
                case 'A':
                    operador_multipla_escolha = 'A';
                    label_operador_multipla_escolha = 'AND';
                    break;
                case 'O':
                    operador_multipla_escolha = 'O';
                    label_operador_multipla_escolha = 'OR';
                    break;
            }

            setValue(`relatorios_filtros.operador_multipla_escolha`, { value: operador_multipla_escolha, label: label_operador_multipla_escolha });

            let t_tipo = data[0].tipo;
            let tipo = '';
            let label_tipo = '';

            switch (t_tipo) {
                case 'INT':
                    tipo = 'INT';
                    label_tipo = 'Inteiro';
                    break;
                case 'DEC':
                    tipo = 'DEC';
                    label_tipo = 'Decimal';
                    break;
                case 'DAT':
                    tipo = 'DAT';
                    label_tipo = 'Data';
                    break;
                case 'HOR':
                    tipo = 'HOR';
                    label_tipo = 'Hora';
                    break;
                case 'DEH':
                    tipo = 'C';
                    label_tipo = 'Data e Hora';
                    break;
                case 'STR':
                    tipo = 'STR';
                    label_tipo = 'String';
                    break;
                case 'OPC':
                    tipo = 'OPC';
                    label_tipo = 'Opções';
                    break;
            }

            setValue(`relatorios_filtros.tipo`, { value: tipo, label: label_tipo });
            setTipoValue(tipo)

            let t_periodo_data_tipo = data[0].periodo_data_tipo;
            let periodo_data_tipo = '';
            let label_periodo_data_tipo = '';

            switch (t_periodo_data_tipo) {
                case 'E':
                    periodo_data_tipo = 'E';
                    label_periodo_data_tipo = 'Exata (dd/MM/yyyy 00:00:00 and dd/MM/yyyy 23:59:59)';
                    break;
                case 'I':
                    periodo_data_tipo = 'I';
                    label_periodo_data_tipo = 'Início dd/MM/yyyy 00:00:00';
                    break;
                case 'F':
                    periodo_data_tipo = 'F';
                    label_periodo_data_tipo = 'Fim dd/MM/yyyy 23:59:59';
                    break;

            }

            setValue(`relatorios_filtros.periodo_data_tipo`, { value: periodo_data_tipo, label: label_periodo_data_tipo });
            /* ------------------       Buscando as sql para o alvo  ----------------------*/

            /* ------------------       Setando as opções  ----------------------*/
            const dadostemp = [];

            //mapeando os dados para a "rows"
            data[0].Relatorios_Personalizados_Filtros_Opcaos.map((item, index) => {
                dadostemp.push({
                    id: item.id,
                    filtro: item.relatorio_personalizad || '',
                    opcao: item.opcao || '',
                    comando: item.comando || '',
                    acoes: <>
                        {/* <MDButton size="medium" onClick={() => abrirRegistro(item.id)} iconOnly > */}
                        {/* <MDButton size="medium" iconOnly >
                            <Icon fontSize="medium" color="success">edit_icon</Icon>
                        </MDButton> */}
                        <MDButton size="medium" onClick={() => handleClickExcluirOpcao(item.id)} iconOnly >
                            {/* <MDButton size="medium" iconOnly > */}
                            <Icon fontSize="medium" color="error">delete_forever</Icon>
                        </MDButton>
                    </>
                })
            })
            // console.log(dadostemp, "dadostemp")
            setLinhas([...dadostemp]);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const excluirRegistro = async () => {

        setDialogoOpen({ ...dialogoOpen, visible: false });

        try {
            await api.delete(`${rotafechada()}relatorios_personalizados_filtros/excluir-full/${dialogoOpen.id || 0}`);
            MensagemAviso('Excluído com sucesso!');
            novoRegistro();
            setRecarregaTabela(!recarregaTabela);

        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleClickExcluir = async (id) => {
        if (!(await validaRotaPermissao('excluir') === true)) {
            return;
        }
        setDialogoOpen({ ...dialogoOpen, visible: true, id });
    }

    const excluirRegistroOpcao = async () => {

        setDialogoOpenOpcao({ ...dialogoOpenOpcao, visible: false });

        try {
            await api.delete(`${rotafechada()}relatorios_personalizados_filtros_opcao/${dialogoOpenOpcao.id || 0}`);
            MensagemAviso('Excluído com sucesso!');
            abrirRegistro(getValues('relatorios_filtros.id'));
            //setRecarregaTabela(!recarregaTabela);

        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleClickExcluirOpcao = async (id) => {
        if (!(await validaRotaPermissao('excluir') === true)) {
            return;
        }
        setDialogoOpenOpcao({ ...dialogoOpenOpcao, visible: true, id });
    }

    const buscaPerfisAcessos = async (data) => {

        try {
            vetorPerfis.length = 0

            let url = `${rotafechada()}relatorios_personalizados_sql?relatorio_personalizado_id=${Relatorios_PersonalizadosAtual}&direction=asc&order=id`



            const retorno = await api.get(encodeURI(url));
            const dados = retorno.data.data;

            let dadosTemp = [];
            dados?.map((item) => {

                let usuario_perfil_id = '';
                let selecionado = false;

                // if (item.Usuario_Perfis?.length > 0) {
                //     usuario_perfil_id = item.Usuario_Perfis[0].id;
                //     selecionado = true;
                // }

                dadosTemp.push(
                    {
                        usuario_perfil_id,
                        selecionado,
                        nome: item.nome,
                        id: item.id
                    })
            })

            setVetorPerfis(dadosTemp);
        }
        catch (error) {
            console.log(error);
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const gravaClick = async (index, estado) => {

        const dadosTemp = [...vetorPerfis];
        dadosTemp[index].selecionado = estado;
        setVetorPerfis(dadosTemp);
    }

    useEffect(() => {
        if (openCollapse == true) {
            buscaPerfisAcessos()
            if (parseInt(codigoAtual, 10) > 0) {
                abrirRegistro(codigoAtual);
            }
            else novoRegistro();
        }
    }, [Relatorios_PersonalizadosAtual, codigoAtual, recarregaTabela, recarregaComponenteOpcoes, openCollapse])

    // useEffect(() => {
    //     buscaPerfisAcessos()
    // }, [])
    return (
        <MDBox p={2} bgColor='grey-100' mt={2} borderRadius="lg" coloredShadow='secondary'>
            <DialogoConfirma
                open={dialogoOpen.visible}
                title={'Confirma Exclusão'}
                color={'error'}
                message={'Certeza que deseja excluir?'}
                handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
                handleConfirm={excluirRegistro}
            />
            <DialogoConfirma
                open={dialogoOpenOpcao.visible}
                title={'Confirma Exclusão'}
                color={'error'}
                message={'Certeza que deseja excluir?'}
                handleCancel={() => setDialogoOpenOpcao({ ...dialogoOpenOpcao, visible: false })}
                handleConfirm={excluirRegistroOpcao}
            />
            <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpenCollapse(!openCollapse)}
            >
                {openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                <Typography variant='h6' mb={2}>Filtro`s  </Typography>
            </IconButton>
            <Tooltip title="Você pode cadastrar mais de um filtro para esse relatório." placement="top">
                <InfoIcon color="info" sx={{ m: 0.5 }}></InfoIcon>
            </Tooltip>

            <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                <form onSubmit={handleSubmit(gravarRegistro)}>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={12} sm={2}>{/*id*
              <Controller
                name={`relatorios_filtros.id`}
                control={props.control}
                defaultValue='Id'
                render={({ field: { ref, ...props } }) =>
                  <FormField
                    //autoComplete={false}
                    type='text'
                    label='Id'
                    rows={1}
                    multiline={false}
                    required={false}
                    disabled={true}
                    inputProps={{
                      disabled: true
                    }}
                    inputRef={ref}
                    {...props}
                  />}
              />
                         </Grid> */}
                        <Grid item xs={12} sm={12}>{/*TITULO: Principais*/}
                            <MDBox p={2} bgColor='grey-100' borderRadius="lg" coloredShadow='secondary'>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>{/*TITULO: Principais*/}
                                        <MDTypography pl={3} fontWeight="bold" variant="title">
                                            {'Principais'}
                                        </MDTypography>
                                    </Grid>

                                    <Grid item xs={12} sm={4.5}>{/*nome*/}
                                        <Controller
                                            name={`relatorios_filtros.nome`}
                                            control={props.control}
                                            //defaultValue='Nome'
                                            render={({ field: { ref, ...props } }) =>
                                                <FormField

                                                    //autoComplete={false}
                                                    type='text'
                                                    label='Nome(Variável do Parâmetro)'
                                                    rows={1}
                                                    multiline={false}
                                                    required={true}
                                                    disabled={false}
                                                    InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                                    inputRef={ref}
                                                    {...props}

                                                />}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>{/*tipo*/}
                                        <Controller
                                            required={true}
                                            name={`relatorios_filtros.tipo`}
                                            control={control}
                                            defaultValue={{ value: '' }}
                                            render={({ field: { onChange, ...props } }) => (
                                                <AutoCompleteStyled
                                                    options={opcoesTipo}
                                                    getOptionLabel={(option) => option.label || ''}
                                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                                    renderInput={(params) => <FormField {...params} label='Tipo' required />}
                                                    onChange={(_, data) => {
                                                        setTipoValue(data?.value); // Atualiza o valor do estado local
                                                        return onChange(data);
                                                    }}
                                                    disabled={false}
                                                    required={true}
                                                    {...props}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={1.5}>{/*Obrigatorio*/}
                                        <MDBox width="100%" display="flex" alignItems='center' flexDirection='column'>
                                            <MDTypography fontWeight="bold" variant="caption">
                                                {'Obrigatório'}
                                            </MDTypography>
                                            <Controller
                                                name={`relatorios_filtros.obrigatorio`}
                                                control={control}
                                                render={({ field: { value, ...otherprops } }) => (
                                                    <Checkbox
                                                        {...otherprops}
                                                        checked={value ? (value === true ? true : false) : false}
                                                        onChange={(e) => setValue(`relatorios_filtros.obrigatorio`, e.target.checked)}
                                                    />
                                                )}
                                            />
                                        </MDBox>
                                    </Grid>
                                    {tipoValue == 'OPC' && // Usa o valor do estado local para controlar a exibição
                                        <Grid item xs={12} sm={1.5}>{/*usa_em_branco*/}
                                            <MDBox width="100%" display="flex" alignItems='center' flexDirection='column'>
                                                <MDTypography fontWeight="bold" variant="caption">
                                                    {'Usa em Branco ?'}
                                                </MDTypography>
                                                <Controller
                                                    name={`relatorios_filtros.usa_em_branco`}
                                                    control={control}
                                                    render={({ field: { value, ...otherprops } }) => (
                                                        <Checkbox
                                                            {...otherprops}
                                                            checked={value ? (value === true ? true : false) : false}
                                                            onChange={(e) => setValue(`relatorios_filtros.usa_em_branco`, e.target.checked)}
                                                        />
                                                    )}
                                                />
                                            </MDBox>
                                        </Grid>
                                    }

                                    {tipoValue == 'DAT' && // Usa o valor do estado local para controlar a exibição
                                        <Grid item xs={12} sm={3}>{/*usa_em_branco*/}
                                            <Controller
                                                required={tipoValue == 'DAT' ? true : false}
                                                name={`relatorios_filtros.periodo_data_tipo`}
                                                control={control}
                                                defaultValue={{ value: '' }}
                                                render={({ field: { onChange, ...props } }) => (
                                                    <AutoCompleteStyled
                                                        options={opcoesPeriodoDataTipo}
                                                        getOptionLabel={(option) => option.label || ''}
                                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                                        renderInput={(params) => <FormField {...params} label='Periodo data tipo' required />}
                                                        onChange={(_, data) => {
                                                            return onChange(data);
                                                        }}
                                                        disabled={false}
                                                        required={tipoValue == 'DAT' ? true : false}
                                                        {...props}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    }

                                    <Grid item xs={12} sm={4}>{/*Label do filtro*/}
                                        <Controller
                                            name={`relatorios_filtros.label`}
                                            control={props.control}
                                            defaultValue=''
                                            render={({ field: { ref, ...props } }) =>
                                                <FormField

                                                    //autoComplete={false}
                                                    type='text'
                                                    label='Label'
                                                    rows={1}
                                                    multiline={false}
                                                    disabled={false}
                                                    inputRef={ref}
                                                    {...props}

                                                />}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4}>{/*texto_filtro_utilizado*/}
                                        <Controller
                                            name={`relatorios_filtros.texto_filtro_utilizado`}
                                            control={props.control}
                                            defaultValue=''
                                            render={({ field: { ref, ...props } }) =>
                                                <FormField

                                                    //autoComplete={false}
                                                    type='text'
                                                    label='Texto Filtros Utilizados'
                                                    rows={1}
                                                    multiline={false}
                                                    disabled={false}
                                                    inputRef={ref}
                                                    {...props}

                                                />}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4}>{/*valor_padrao*/}
                                        <Controller
                                            name={`relatorios_filtros.valor_padrao`}
                                            control={props.control}
                                            defaultValue=''
                                            render={({ field: { ref, ...props } }) =>
                                                <FormField

                                                    //autoComplete={false}
                                                    type='text'
                                                    label='Valor Padrão'
                                                    rows={1}
                                                    multiline={false}
                                                    disabled={false}
                                                    inputRef={ref}
                                                    {...props}

                                                />}
                                        />
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={12}>{/*TITULO: Chave Estrangeira*/}
                            <MDBox p={2} bgColor='grey-100' borderRadius="lg" coloredShadow='secondary'>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpenCollapseChaveEstrangeira(!openCollapseChaveEstrangeira)}
                                >
                                    {openCollapseChaveEstrangeira ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    <MDTypography pb={2} fontWeight="bold" variant="title">
                                        {'Chave Estrangeira '}
                                    </MDTypography>
                                </IconButton>
                                <Collapse in={openCollapseChaveEstrangeira} timeout="auto" unmountOnExit>
                                    <Grid container spacing={2}>

                                        <Grid item xs={12} sm={12}>{/*sql_chave_estrangeira aceeditor*/}
                                            <MDTypography pl={2} fontWeight="bold" variant="h6">
                                                {'Sql Chave Estrangeira '}
                                            </MDTypography>
                                            <Typography variant='caption'>
                                                {`(Usar `}<strong>{` :EMPRESA `}</strong>{`NOS SQLS QUE DESEJA INFORMAR A EMPRESA_ID) Exemplo select * from "Pessoas" where empresa_id = :EMPRESA`}
                                            </Typography>
                                            <Controller
                                                name={`relatorios_filtros.sql_chave_estrangeira`}
                                                control={props.control}
                                                defaultValue='Sql Chave Estrangeira'
                                                render={({ field: { onChange, value } }) =>
                                                    <AceEditor
                                                        focus
                                                        mode="sql"
                                                        theme="ambiance"
                                                        fontSize={14}
                                                        placeholder="Escreve seu SQL aqui..."
                                                        width="100%" // Ajuste a largura aqui
                                                        height="200px" // Ajuste a altura aqu
                                                        onChange={onChange}
                                                        editorProps={{ $blockScrolling: false }}
                                                        setOptions={{
                                                            showLineNumbers: true,
                                                            tabSize: 2
                                                        }}
                                                        value={value}
                                                    />
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>{/*campo_sql_chave_estrangeira*/}
                                            <Controller
                                                name={`relatorios_filtros.campo_sql_chave_estrangeira`}
                                                control={props.control}
                                                defaultValue=''
                                                render={({ field: { ref, ...props } }) =>
                                                    <FormField

                                                        //autoComplete={false}
                                                        type='text'
                                                        label='Comando Chave Estrangeira'
                                                        rows={1}
                                                        multiline={false}
                                                        disabled={false}
                                                        inputRef={ref}
                                                        {...props}

                                                    />}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12}>{/* Campos Filtro DADOS */}
                                            <DadosCampoFiltro
                                                label={'Informar os campos filtro da tela de pesquisa'}
                                                campoFiltro={campoFiltro}
                                                setCampoFiltro={setCampoFiltro}
                                                dadosCampoFiltro={dadosCampoFiltro}
                                                setDadosCampoFiltro={setDadosCampoFiltro}
                                            />
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </MDBox>
                        </Grid>

                        <Grid item xs={12} sm={12}>{/*TITULO: Multipla Escolha*/}
                            <MDBox p={2} bgColor='grey-100' borderRadius="lg" coloredShadow='secondary'>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpenCollapseMultiplaEscolha(!openCollapseMultiplaEscolha)}
                                >
                                    {openCollapseMultiplaEscolha ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    <MDTypography pb={2} fontWeight="bold" variant="title">
                                        {'Multipla Escolha'}
                                    </MDTypography>

                                </IconButton>
                                <Collapse in={openCollapseMultiplaEscolha} timeout="auto" unmountOnExit>
                                    <Grid container spacing={2}>

                                        <Grid item xs={12} sm={2}>{/*multipla_escolha*/}
                                            <MDBox width="100%" display="flex" alignItems='center' flexDirection='column'>
                                                <MDTypography fontWeight="bold" variant="caption">
                                                    {'Múltipla Escolha ?'}
                                                </MDTypography>
                                                <Controller
                                                    name={`relatorios_filtros.multipla_escolha`}
                                                    control={control}
                                                    render={({ field: { value, ...otherprops } }) => (
                                                        <Checkbox
                                                            {...otherprops}
                                                            checked={value ? (value === true ? true : false) : false}
                                                            onChange={(e) => setValue(`relatorios_filtros.multipla_escolha`, e.target.checked)}
                                                        />
                                                    )}
                                                />
                                            </MDBox>
                                        </Grid>

                                        <Grid item xs={12} sm={10}>{/*operador_multipla_escolha*/}
                                            <Controller
                                                name={`relatorios_filtros.operador_multipla_escolha`}
                                                control={control}
                                                defaultValue={{ value: '' }}
                                                render={({ field: { onChange, ...props } }) => (
                                                    <AutoCompleteStyled
                                                        options={opcoesOperadorJuncao}
                                                        getOptionLabel={(option) => option.label || ''}
                                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                                        renderInput={(params) => <FormField {...params} label='Operador de Junção' />}
                                                        onChange={(_, data) => {
                                                            return onChange(data);
                                                        }}
                                                        disabled={false}
                                                        {...props}
                                                    />
                                                )}

                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>{/*campo_multipla_escolha*/}
                                            <Controller
                                                name={`relatorios_filtros.campo_multipla_escolha`}
                                                control={props.control}
                                                defaultValue=''
                                                render={({ field: { ref, ...props } }) =>
                                                    <FormField

                                                        //autoComplete={false}
                                                        type='text'
                                                        label='Comando para múltipla escolha'
                                                        rows={1}
                                                        multiline={false}
                                                        disabled={false}
                                                        inputRef={ref}
                                                        {...props}

                                                    />}
                                            />
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </MDBox>
                        </Grid>

                        <Grid item xs={12} sm={12}>{/*TITULO: SQL Adicional*/}
                            <MDBox p={2} bgColor='grey-100' borderRadius="lg" coloredShadow='secondary'>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpenCollapseSqlAdicional(!openCollapseSqlAdicional)}
                                >
                                    {openCollapseSqlAdicional ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    <MDTypography pb={2} fontWeight="bold" variant="title">
                                        {'Sql Adicional'}
                                    </MDTypography>
                                </IconButton>
                                <Collapse in={openCollapseSqlAdicional} timeout="auto" unmountOnExit>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12}>{/*descrição sql_adicional*/}
                                            <Typography variant='caption'>
                                                {`Sql Adicional (Usar `}<strong>{` /*PFILTROADICIONAL*/ `}</strong>{`NOS SQLS QUE DESEJA ADIÇÃO) Exemplo and "Nome_Tabela".campo = :PPARAMETRO`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>{/*sql_adicional*/}
                                            <Controller
                                                name={`relatorios_filtros.sql_adicional`}
                                                control={props.control}
                                                defaultValue=''
                                                render={({ field: { ref, ...props } }) =>
                                                    <FormField

                                                        //autoComplete={false}
                                                        type='text'
                                                        label='Sql Adicional'
                                                        rows={3}
                                                        multiline={true}
                                                        disabled={false}
                                                        inputRef={ref}
                                                        {...props}

                                                    />}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>{/*descrição sql_adicional_nome_filtro*/}
                                            <Typography variant='caption'>
                                                {`Sql Adicional por Filtro (Usar`}<strong>{` /*NOMEFILTRO*/ `}</strong>{`no local que deseja que esse sql seja inserido)`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>{/*sql_adicional_nome_filtro*/}
                                            <Controller
                                                name={`relatorios_filtros.sql_adicional_nome_filtro`}
                                                control={props.control}
                                                defaultValue=''
                                                render={({ field: { ref, ...props } }) =>
                                                    <FormField

                                                        //autoComplete={false}
                                                        type='text'
                                                        label='Sql Adicional Nome Filtro'
                                                        rows={3}
                                                        multiline={true}
                                                        disabled={false}
                                                        inputRef={ref}
                                                        {...props}

                                                    />}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12}>{/*alvo_sql_adicional*/}
                                            <Typography variant='h6' mb={2}>Inserir em qual Sql</Typography>
                                            <MDBox width="100%" display="flex" justifyContent='flex-start' alignItems='space-between' flexDirection='row'>
                                                {
                                                    vetorPerfis?.map((paramitem, index) => {
                                                        return (
                                                            <div>
                                                                <MDTypography variant="caption">
                                                                    <Checkbox
                                                                        checked={paramitem.selecionado ? paramitem.selecionado : false}
                                                                        onChange={(e) => gravaClick(index, e.target.checked)}
                                                                    />
                                                                    {paramitem.nome}
                                                                </MDTypography>
                                                            </div>)
                                                    })
                                                }
                                            </MDBox>
                                        </Grid>

                                    </Grid>
                                </Collapse>
                            </MDBox>
                        </Grid>

                        <Grid item xs={12} sm={12}>{/*TITULO: Opções*/}
                            <MDBox p={2} bgColor='grey-100' borderRadius="lg" coloredShadow='secondary'>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpenCollapseOpcoes(!openCollapseOpcoes)}
                                >
                                    {openCollapseOpcoes ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    <MDTypography pb={2} fontWeight="bold" variant="title">
                                        {'Opções'}
                                    </MDTypography>
                                </IconButton>
                                <Collapse in={openCollapseOpcoes} timeout="auto" unmountOnExit>
                                    <Grid container spacing={2}>

                                        <Grid item xs={12} sm={4}>{/*opcao*/}
                                            <Controller
                                                name={`relatorios_filtros.opcao`}
                                                control={props.control}
                                                defaultValue=''
                                                render={({ field: { ref, ...props } }) =>
                                                    <FormField

                                                        //autoComplete={false}
                                                        type='text'
                                                        label='Opção'
                                                        rows={1}
                                                        multiline={false}
                                                        disabled={getValues('relatorios_filtros.id') ? false : true}
                                                        inputRef={ref}
                                                        {...props}

                                                    />}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6.5}>{/*comando*/}
                                            <Controller
                                                name={`relatorios_filtros.comando`}
                                                control={props.control}
                                                defaultValue=''
                                                render={({ field: { ref, ...props } }) =>
                                                    <FormField

                                                        //autoComplete={false}
                                                        type='text'
                                                        label='Substituir Opção pelo Comando:'
                                                        rows={1}
                                                        multiline={false}
                                                        disabled={getValues('relatorios_filtros.id') ? false : true}
                                                        inputRef={ref}
                                                        {...props}

                                                    />}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={1.5}>{/*Botão inserir */}
                                            <MDButton
                                                variant="gradient"
                                                color="info"
                                                onClick={gravaOpcao}
                                            >
                                                inserir
                                            </MDButton>
                                        </Grid>

                                        <Grid item xs={12} sm={12}>{/*tabela das opcoes*/}
                                            <TabelaPadrao
                                                colunas={colunasTable}
                                                linhas={linhas}
                                                countAll={countAll}
                                                page={page}
                                                setPage={setPage}
                                                rowsPerPage={rowsPerPage}
                                                setRowsPerPage={setRowsPerPage}
                                                order={order}
                                                setOrder={setOrder}
                                                orderBy={orderBy}
                                                setOrderBy={setOrderBy}
                                            />
                                        </Grid>

                                    </Grid>
                                </Collapse>
                            </MDBox>
                        </Grid>

                    </Grid>

                    <MDBox width="100%" display="flex" justifyContent="flex-end" mt={2}>
                        <MDButton variant="gradient" color="info"
                            type="submit"
                        // onClick={() => handleSubmit(gravarRegistro)()}
                        >Gravar Filtro</MDButton>
                        <MDButton variant="gradient" color="info" onClick={novoRegistro} sx={{ ml: 2 }}>Novo</MDButton>
                        {getValues('relatorios_filtros.id') &&
                            <MDButton variant="gradient" color="info" onClick={replicaRegistro} sx={{ ml: 2 }}>Replicar</MDButton>
                        }
                    </MDBox>
                    <MDBox width="100%" display="flex" mt={2}>
                        <TabelaOpcoes
                            Relatorios_PersonalizadosAtual={Relatorios_PersonalizadosAtual}
                            abrirRegistro={abrirRegistro}
                            excluirRegistro={handleClickExcluir}
                            recarregaTabela={recarregaTabela}
                            recarregaComponenteOpcoes={recarregaComponenteOpcoes}
                            getValues={getValues}
                        />
                    </MDBox>
                </form>
            </Collapse>
        </MDBox>
    )
}

export default Relatorios_Personalizados_Filtros;