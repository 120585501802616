import MessagesContext from 'hooks/MessagesContext';
import React, { useContext, useEffect, useState } from 'react';
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import { Grid } from '@mui/material';
import ConfiguraPermissao from './configurapermissao';
import AuthContext from 'hooks/AuthContext';

export default function PermissoesModulo(props) {

    const { moduloCarregado, setModuloCarregado, permitidoVerReservado } = props;
    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
    const [datasetPermissoesCategoriaModuloPermissao, setDatasetPermissoesCategoriaModuloPermissao] = useState([]);
    const [datasetCategorias, setDatasetCategorias] = useState([]);
    const [perfis, setPerfis] = useState([]);
    const [usuarios, setUsuarios] = useState([]);

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    const buscaCategorias = async () => {

        if (!parseInt(moduloCarregado, 10) > 0) {
            return;
        }

        try {
            MostraTelaEspera(`Buscando Módulo e Permissões`);
            const baseUrl = `${rotafechada()}permissoes_categoria_modulo_permissao`;
            const url = `${baseUrl}?limit=-1&order=categoria_modulo_permissao_id&modulo_sistema_id=${moduloCarregado}`;
            const retorno = await api.get(encodeURI(url));
            FechaTelaEspera();
            const { data, countAll } = retorno.data;

            const categoria = {};

            const categoriastemp = [];

            //separando as categorias
            data.map((item) => {

                if (categoria?.id !== item.categoria_modulo_permissao_id) {
                    categoriastemp.push({
                        id: item.categoria_modulo_permissao_id,
                        caption: item.Categorias_Modulo_Permissao.caption
                    })
                    categoria.id = item.categoria_modulo_permissao_id;
                }
            })

            setDatasetCategorias(categoriastemp)
            setDatasetPermissoesCategoriaModuloPermissao(data)
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }
    }

    const buscaPerfis = async () => {

        try {
            let perfisTemp = [];
            let retorno;
            let baseUrl = `${rotafechada()}perfis_acessos?limit=0&listaglobal=true`;

            if (permitidoVerReservado){
                baseUrl=`${baseUrl}&all=S`;
            }
            
            //lista os perfis da empresa e os globais
            retorno = await api.get(baseUrl);

            if (retorno.data.data.length > 0) {
                perfisTemp = [...perfisTemp, ...retorno.data.data];
            }
            setPerfis(perfisTemp)
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }
    }

    const buscaUsuarios = async () => {

        try {
            let usuariosTemp = [];
            let retorno;
            let complementourl='';

            if (!permitidoVerReservado){
                complementourl='&reservado=N';
            }

            //busca apenas os usuarios ativos
            const baseUrl = `${rotafechada()}empresa_usuarios?limit=0&ativo=S${complementourl}`;
            retorno = await api.get(`${baseUrl}&empresa_id=${sessaolocal.empresa_id}`);

            retorno?.data?.data.map((item) => {
                if ((item.ativo === 'S')) {
                    usuariosTemp.push({
                        id: item.id,
                        nome: item.nome || ''
                    })
                }
            })

            setUsuarios(usuariosTemp);
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }
    }

    useEffect(async () => {

        buscaCategorias();
        buscaPerfis();
        buscaUsuarios();

    }, [moduloCarregado])

    const handleClickModulo = (item) => {
        setModuloCarregado(item.id);

    }

    return (
        <MDBox
            sx={{
                mr: 6
            }}
        >
            {
                datasetCategorias?.map((itemcategoria, index) => {
                    return (
                        <div>
                            <MDBox
                                key={index}
                                bgColor={'grey-200'}
                                fullWidth
                            >
                                <MDTypography variant="button" m={2} fontWeight='bold'>
                                    {
                                        itemcategoria.caption
                                    }
                                </MDTypography>
                            </MDBox>
                            <Grid container m={2}>
                                {
                                    datasetPermissoesCategoriaModuloPermissao?.filter((itemfiltro) => itemfiltro.categoria_modulo_permissao_id === itemcategoria.id).map((item, index) => {
                                        return (
                                            <Grid item xs={12} sm={12} >
                                                <ConfiguraPermissao
                                                    permitidoVerReservado={permitidoVerReservado}
                                                    perfis={perfis}
                                                    usuarios={usuarios}
                                                    permissao={item.Permisso}
                                                    permissao_categoria_modulo_permissao_id={item.id}
                                                />
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>

                        </div>

                    )
                })


                // dataset?.map((item, index) => {
                //     return (
                //         <Box key={index}>
                //             <MDTypography variant="button">
                //                 {
                //                     item.Parametro.nome
                //                 }
                //             </MDTypography>
                //         </Box>
                //     )
                // }
                // )
            }
        </MDBox>
    )
}