import React, { useState, useEffect, useContext } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import { Box, Icon, Tooltip, Typography } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

//My Componentes
import FormField from "componentes/FormField";
//My componentes e functions
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import AuthContext from "hooks/AuthContext";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";
import InfoIcon from "@mui/icons-material/Info";
import MDTypography from "components/MDTypography";


function Cadastro(props) {
  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();

  const { setCodigoAbrir, codigoAbrir, setAba, tipoEscolhido, categoriaMae, tipoMae } = props;

  const [plano_conta_id, setPlano_conta_id] = useState({ value: "", label: "" });
  const [optionPlanoConta, setOptionPlanoConta] = useState([
    { value: -1, label: "<Escolha um Plano de conta>", tipo: '' },
  ]);

  let opcoesCategoria = [
    { value: -1, label: "<Escolha uma Categoria>" },
  ];

  const [categoria_id, setCategoria_id] = useState([{ value: "", label: "" }]);
  const [optionCategoria, setOptionCategoria] = useState([
    { value: -1, label: "<Escolha uma Categora>" },
  ]);

  const [desabilitaCampo, setDesabilitaCampo] = useState(false);

  const [id, setId] = useState("");
  const [id_externo, setId_Externo] = useState("");
  const [nome, setNome] = useState("");

  const [tipo, setTipo] = useState({
    label: tipoEscolhido.label,
    value: tipoEscolhido.value,
  });
  const optionTipo = [
    {
      label: "Despesa",
      value: "D"
    },
    {
      label: "Receita",
      value: "R"
    },
  ];

  const buscaDados = async () => {

    MostraTelaEspera("Carregando..");

    if (categoriaMae > 0) {

      setTipo(tipoMae === "D" ? optionTipo[0] : optionTipo[1]);

      opcoesCategoria.map((item) => {
        if (item.value == categoriaMae) {
          setCategoria_id({ value: categoriaMae, label: item.label });
        }
      });


    }

    if (!parseInt(props.codigoAbrir, 10) > 0) {
      FechaTelaEspera();
      return;
    }

    try {
      const complementoUrl = tipoEscolhido?.value ? `&tipo=${tipoEscolhido.value}` : '';
      const retorno = await api.get(`${rotafechada()}categoria?id=${props.codigoAbrir}${complementoUrl}`);
      const { data, count } = retorno.data;

      //console.log("DATA::", data)

      if (!count > 0) {
        FechaTelaEspera();
        MensagemErro("Nenhuma informação foi selecionada");
        return;
      }

      if (data[0].empresa_id !== sessaolocal.empresa_id) {
        FechaTelaEspera();
        MensagemErro("Registro de outra empresa!");
        return;
      }

      setId(data[0].id);
      setId_Externo(data[0].id_externo);
      setNome(data[0].nome);

      setTipo(data[0].tipo === "D" ? optionTipo[0] : optionTipo[1]);

      if (!data[0].verificador) {
        setCategoria_id({ value: data[0].categoria_id ? data[0].categoria_id : '', label: data[0].Categorium?.nome ? data[0].Categorium.nome : '' });
        setPlano_conta_id({ value: data[0].plano_conta_id ? data[0].plano_conta_id : '', label: data[0].Plano_Conta?.nome ? data[0].Plano_Conta.nome : '' })
      }


      //Verifica se é mae para desabilitar os campos de plano de conta e categoria_id
      let desabilitaCampoSeForMae = data[0]?.verificador ? setDesabilitaCampo(true) : "";

      FechaTelaEspera();
    } catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  };

  const buscaPlanoConta = async () => {
    try {
      let complementoUrl = tipoEscolhido?.value ? `&tipo=${tipoEscolhido.value}` : '';
      complementoUrl = `${complementoUrl}&uso_interno=N`;
      const url = `${rotafechada()}plano_contas?&limit=-1&order=mascara&tipo=T${complementoUrl}`;
      //console.log("***URL Plano de conta:", url);
      let retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      let opcoesPlanoContaTemp = [{ id: -1, value: -1, label: "<Selecione um plano de conta>", tipo: '' }];

      data.map((item) => {
        opcoesPlanoContaTemp.push({ id: item.id, value: item.id, label: item.nome, tipo: item.mascara_tipo });
      });

      setOptionPlanoConta(opcoesPlanoContaTemp);

      return opcoesPlanoContaTemp;
    } catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  };

  const buscaCategoria = async () => {
    try {
      //passo com um parametro para trazer somente as categorias mae
      //complementoUrl = `${complementoUrl}&direction=${order}`;
      const complementoUrl = tipoEscolhido?.value ? `&tipo=${tipoEscolhido.value}` : '';
      const url = `${rotafechada()}categoria?mae=${null}&order=nome&limit=-1${complementoUrl}`;
      //console.log("***URL categoria:", url);
      let retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      let opcoesCategoriaTemp = [{ value: -1, label: "<Selecione uma categoria" }];

      data.map((item) => {
        opcoesCategoriaTemp.push({ value: item.id, label: item.nome });
      });
      setOptionCategoria(opcoesCategoriaTemp);
      opcoesCategoria = opcoesCategoriaTemp

      return opcoesCategoriaTemp;
    } catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  };

  const handleClickGravar = async (e) => {
    e.preventDefault();

    try {
      MostraTelaEspera("Gravando categoria..");
      let retorno;

      let dados = [];

      // if (categoria_id && categoria_id.value >= 1) {
      //   dados = {
      //     empresa_id: sessaolocal.empresa_id || null,
      //     nome,
      //     tipo: tipo.value,
      //     plano_conta_id: plano_conta_id?.value ? plano_conta_id.value : null,
      //     categoria_id: categoria_id?.value ? categoria_id.value : null,
      //   };
      // } else {
      dados = {
        empresa_id: sessaolocal.empresa_id || null,
        nome,
        tipo: tipo.value,
        plano_conta_id: plano_conta_id?.value ? plano_conta_id.value : null,
        categoria_id: categoria_id?.value ? categoria_id.value : null,
      };
      //}
      if (codigoAbrir && parseInt(codigoAbrir, 10) > 0)
        retorno = await api.put(`${rotafechada()}categoria/${codigoAbrir}`, dados);
      else {
        retorno = await api.post(`${rotafechada()}categoria`, dados);
        props.setCodigoAbrir(retorno.data.data.id || 0);
      }

      MensagemAviso("Gravado com sucesso!");
      FechaTelaEspera();
      setAba(0);
    } catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  };

  useEffect(async () => {
    await buscaCategoria();
    await buscaPlanoConta();
    await buscaDados();
  }, [codigoAbrir]);


  return (
    <MDBox p={1}>
      <MDBox width="100%" display="flex" justifyContent="space-between">
        <MDButton variant="gradient" color="secondary" type="button" onClick={() => setAba(0)}>
          Voltar
        </MDButton>
      </MDBox>
      <form onSubmit={handleClickGravar}>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={3}>{/* ID */}
            <FormField
              type="text"
              label="ID"
              value={id || ""}
              disabled={true}
              inputProps={{
                disabled: true
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>{/* ID_EXTERNO */}
            <FormField
              type="text"
              label="ID EXTERNO"
              value={id_externo || ""}
              disabled={true}
              inputProps={{
                disabled: true
              }}
            />
          </Grid>

          <Grid item xs={12} sm={3}>{/* TIPO */}
            <AutoCompleteStyled
              disabled={true}
              defaultValue={tipoEscolhido}
              options={optionTipo}
              getOptionLabel={(option) => option.label || ""}
              renderInput={(params) => <FormField {...params} label="Tipo" />}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              value={tipo}
              onChange={(event, newValue) => {
                setTipo(newValue);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>{/* NOME */}
            <FormField
              type="text"
              label="Descrição"
              value={nome}
              required={true}
              onChange={(e) => setNome(e.target.value)}
              autoFocus
            />
          </Grid>

          <Grid item xs={12} sm={5.5}>{/*plano_conta_id*/}
            <AutoCompleteStyled
              disabled={desabilitaCampo}
              options={optionPlanoConta}
              getOptionLabel={(option) => option.label || ""}
              getOptionDisabled={(option) => option.tipo === 'T'}
              renderInput={(params) => <FormField {...params} label="Associar com Plano de contas" />}
              renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.tipo === 'T' ?
                      <Typography  variant="subtitle" style={{ fontWeight: '900', color:'black' }}>{option.label}</Typography>
                      :  
                      <Typography variant="subtitle" style={{font:'inherit' }}>{`   ${option.label}`}</Typography>
                    }
                  </Box>
              )}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              value={plano_conta_id}
              onChange={(event, newValue) => {
                setPlano_conta_id(newValue);
              }}
              style={{ cursor: "not-allowed" }}
            />
          </Grid>

          <Grid item xs={12} sm={0.5}>{/*info*/}
            <Tooltip title="Indica a relação da categoria financeira com o DRE." placement="top">
              <InfoIcon color="info" sx={{ mt: 1 }}></InfoIcon>
            </Tooltip>
          </Grid>

          <Grid item xs={12} sm={5.5}>{/*categoria_id*/}
            <AutoCompleteStyled
              disabled={desabilitaCampo}
              options={optionCategoria}
              getOptionLabel={(option) => option.label || ""}
              renderInput={(params) => <FormField {...params} label="Aparecer dentro de:" />}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              value={categoria_id}
              onChange={(event, newValue) => {
                setCategoria_id(newValue);
              }}
              style={{ cursor: "not-allowed" }}
            />
          </Grid>

          <Grid item xs={12} sm={0.5}>{/*info*/}
            <Tooltip
              title="Indica a hierarquia das categoria. Se estiver desabilitado é uma categoria mãe"
              placement="top"
            >
              <InfoIcon color="info" sx={{ mt: 1 }}></InfoIcon>
            </Tooltip>
          </Grid>
        </Grid>

        <MDBox p={2}>{/*Botão gravar */}
          <MDBox mt={3} width="100%" display="flex" justifyContent="flex-end">
            <MDButton
              variant="gradient"
              color="info"
              //onClick={handleClickGravar} 
              type="submit">
              Gravar
            </MDButton>
          </MDBox>
        </MDBox>

      </form >
    </MDBox>

  );
}

export default Cadastro;
