import { createContext, useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// import Snackbar from "components/Snackbar/Snackbar.js";
// import AddAlert from "@material-ui/icons/AddAlert";
// import { makeStyles } from '@material-ui/core/styles';
import MDSnackbar from 'components/MDSnackbar';

const MessagesContext = createContext()

export default MessagesContext;

export const MessageProvider = ({ children }) => {


    const [openAlert, setOpenAlert] = useState({ title: '', content: '', open: false, color: 'info' });
    const [openBackDrop, setOpenBackDrop] = useState({ open: false, msg: '' });


    function MensagemAlerta(mensagem = '', temposeg = 3) {

        setOpenAlert({ ...openAlert, title: 'Aviso', content: mensagem, open: true, color: 'warning' });
        //console.log('MensagemAviso:',mensagem);

        if (temposeg !== null) {
            //fecha apos X segundos
            setTimeout(function () {
                setOpenAlert({ ...openAlert, open: false })
            }, (temposeg * 1000));
        }
    }

    function MensagemAviso(mensagem = '', temposeg = 3) {

        setOpenAlert({ ...openAlert, title: 'Aviso', content: mensagem, open: true, color: 'info' });
        //console.log('MensagemAviso:',mensagem);

        if (temposeg !== null) {
            //fecha apos X segundos
            setTimeout(function () {
                setOpenAlert({ ...openAlert, open: false })
            }, (temposeg * 1000));
        }
    }

    function MensagemErro(mensagem = '', temposeg = 3) {

        setOpenAlert({ ...openAlert, title: 'Aviso', content: mensagem, open: true, color: 'error' });
        //console.log('MensagemAviso:',mensagem);

        if (temposeg !== null) {
            //fecha apos X segundos
            setTimeout(function () {
                setOpenAlert({ ...openAlert, open: false })
            }, (temposeg * 1000));
        }
    }


    function MostraTelaEspera(mensagem = '') {
        setOpenBackDrop({ ...openBackDrop, msg: mensagem, open: true });
    }

    function FechaTelaEspera() {
        setOpenBackDrop({ ...openBackDrop, open: false });
    }


    let contextData = {
        MensagemAlerta,
        MensagemAviso,
        MensagemErro,
        MostraTelaEspera,
        FechaTelaEspera
    }
    return (
        <MessagesContext.Provider value={contextData} >
            <>
                {openAlert.open ?
                    <MDSnackbar
                        // color="info"
                        color={openAlert.color}
                        icon="notifications"
                        title={openAlert.title}
                        content={openAlert.content}
                        // dateTime="11 mins ago"
                        dateTime=""
                        open={openAlert.open}
                        close={() => setOpenAlert({ ...openAlert, open: false })}
                    />
                    : false
                }

                {openBackDrop.open ?
                    <Backdrop
                        //sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        sx={{ color: '#fff', zIndex: 99999 }}
                        open={openBackDrop.open}
                    // onClick={handleClose}
                    >
                        <span style={{ position: 'absolute', top: '400px', left: '500px' }}>{openBackDrop.msg}</span>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    : false
                }

                
                {children}
            </>
        </MessagesContext.Provider>
    )
}
