import { Autocomplete, Grid, Typography, Icon, Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import React, { useContext, useRef, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import packageJson from '../../../../../../package.json';

// Images
import image1 from "assets/images/products/choose-product.png";
import ImagemProjeto from './imagemprojeto';
import MDButton from 'components/MDButton';
import { useNavigate } from 'react-router-dom';
import MessagesContext from 'hooks/MessagesContext';
import DialogoOpcoes from 'componentes/DialogoOpcoes';
import ProjetoTempera from 'pages/desenhos/projetotempera';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import DialogoConfirma from 'componentes/DialogoConfirma';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';

const Cabecalho = (props) => {

  const navigate = useNavigate();

  const { control, opcoesGrupo, opcoesAtivo, getValues, projetoAtual, setValue, handleSubmit,
    novoProjeto, gravarRegistro, setAba, setProjetoAtual } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const [currentImage, setCurrentImage] = useState(image1);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const [opcoes, setOpcoes] = useState({
    open: false,
    items: [{}]
  });
  const [openDesenhoTempera, setOpenDesenhoTempera] = useState(false);
  const [openMensagemModuloAdicional, setOpenMensagemModuloAdicional] = useState(false);
  //https://beta.reactjs.org/learn/manipulating-the-dom-with-refs#how-to-manage-a-list-of-refs-using-a-ref-callback
  const itemsRef = useRef(null);
  const nomeModulo = 'PEDIDOS_TEMPERA';
  const captionModulo = 'Desenho para Têmpera';

  const [openConfirmacao, setOpenConfirmacao] = useState({ open: false });

  const handleClickNovo = () => {
    navigate(`/projetos/projeto/0`, { replace: true });
    setImagePreviewUrl('');//limpa a imagem atual
    novoProjeto();
  }

  const handleClickVoltar = () => {
    navigate(`/projetos/projeto/`, { replace: true });
    setAba(0);
    setProjetoAtual('');
  }

  const handleClickOpcoes = () => {


    const items = [
      { value: 0, label: 'Replicar o projeto atual' },
      { value: 1, label: 'Desenho para Têmpera' },
    ];
    setOpcoes({ ...opcoes, open: true, tipo: 'opcoes', items });
  }

  const onCloseDialogoOpcoes = (value) => {

    setOpcoes({ ...opcoes, open: false, tipo: '' });

    if (opcoes.tipo === 'opcoes') {
      switch (parseInt(value, 10)) {
        case 0:
          if (!parseInt(projetoAtual) > 0) {
            MensagemErro('Selecione um projeto');
            return;
          }
          replicaProjetoAtual();
          break;

        case 1:
          //Chamar função que verifica se essa empresa tem acesso o módulo
          validaModulo();
          //setOpenMensagemModuloAdicional(true);
          //setOpenDesenhoTempera(true);
          break;

        default:
          break;
      }
    }
  }

  const replicaProjetoAtual = async () => {

    try {
      MostraTelaEspera('Aguarde o processamento....');
      let url = `${rotafechada()}projetos/replicar/${projetoAtual}`;
      const retorno = await api.post(url);
      FechaTelaEspera();
      MensagemAviso(retorno?.data?.msg || 'Replicado com Sucesso!');
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }


  const handleConfirmaAbortarDesenho = () => {
    setOpenConfirmacao({
      open: true,
      message: 'Importante salvar antes de sair para não perder as alterações. Certeza que deseja abortar o processo?',
      handleCancel: () => setOpenConfirmacao({ open: false }),
      handleConfirm: () => {
        setOpenConfirmacao({ open: false });
        setOpenDesenhoTempera(false);
      }
    });
  }

  const getNome = useWatch({
    control,
    name: "nome",
  });

  const validaModulo = async () => {


    setOpenDesenhoTempera(true);

    // MostraTelaEspera('Validando acesso ao módulo');
    // try {
    //   let url = `${rotafechada()}assinatura_modulos/validar?nome=${nomeModulo}`;
    //   const retorno = await api.get(url);
    //   setOpenDesenhoTempera(true);

    // }
    // catch (error) {
    //   const msg = error.response?.data?.msg || error;
    //   const erro = error.response?.data?.erro || '';
    //   //MensagemErro(`${msg}`);
    //   setOpenMensagemModuloAdicional(true)
    // }
    // FechaTelaEspera();

  }


  return (
    <>


      <DialogoConfirma
        open={openConfirmacao?.open || false}
        message={openConfirmacao?.message || ''}
        title={openConfirmacao?.title || 'Confirmação'}
        handleCancel={openConfirmacao.handleCancel}
        handleConfirm={openConfirmacao.handleConfirm}
      />

      <DialogoOpcoes
        value={''}
        open={opcoes.open || false}
        onClose={onCloseDialogoOpcoes}
        options={opcoes.items || []}
      />

      <Dialog open={openMensagemModuloAdicional} onClose={() => {
        setOpenMensagemModuloAdicional(false);
        //setOpenDesenhoTempera(true); //se clicar fora
      }}>

        <DialogContent>
          <Typography variant='h5'>Módulo adicional</Typography>
          <Typography variant='h6'> </Typography>
          <Typography variant='h7'>Módulo {captionModulo} não contratado, para mais informações entre em contato com nossa equipe de suporte!</Typography>
        </DialogContent>
        <DialogActions>
          <MDButton color='Success' onClick={() => window.open(packageJson?.urlsuporte.replace('text', 'text=Tenho interesse em contratar o módulo Desenho de Têmpera para o VidrosWeb📌 '))} >
            Contato<WhatsAppIcon fontSize="medium" color='Success' />
          </MDButton>
          <MDButton onClick={() => {
            setOpenMensagemModuloAdicional(false);
            //setOpenDesenhoTempera(true); // se clicar no fechar
          }}>Fechar</MDButton>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={openDesenhoTempera}
        onClose={handleConfirmaAbortarDesenho}
        disableEscapeKeyDown
      >
        <DialogTitle>
          <Box display='flex' justifyContent="space-between">
            <Typography variant='h6'>Desenho para Têmpera</Typography>
            <MDButton onClick={handleConfirmaAbortarDesenho}>
              <CloseIcon fontSize="large" color='info' />
            </MDButton>

          </Box>
        </DialogTitle>
        <DialogContent>
          <ProjetoTempera
            projeto_id={projetoAtual}
            pedido_tempera_venda_projeto_id={-1}
            itemsRef={itemsRef}
            nomeprojeto={getNome}

          />
        </DialogContent>
      </Dialog>


      <MDBox p={2}>
        <MDBox width="100%" display="flex" justifyContent="space-between">
          <MDButton
            variant="gradient"
            color="secondary"
            type="button"
            onClick={handleClickVoltar}
          >Voltar</MDButton>
          <MDButton
            variant="gradient"
            circular
            color="info"
            type="button"
            onClick={handleClickNovo}
          >
            <Icon>add</Icon>
          </MDButton>
        </MDBox>
        <MDBox mb={3}>
          <Typography variant='h6' ></Typography>
        </MDBox>
        <MDBox mb={3}>
          <Typography variant='h6' >Dados do Projeto: {`${getValues('id') || ''}-${getValues('nome') || ''}`}</Typography>
        </MDBox>

        <form onSubmit={handleSubmit(gravarRegistro)}>
          <Grid container spacing={1}>

            <Grid item xs={12} sm={9}>{/*dados do projeto*/}
              <Grid container spacing={2}>

                <Grid item xs={12} sm={2}>{/*id*/}
                  <Controller
                    name={'id'}
                    control={props.control}
                    defaultValue=''
                    render={({ field: { ref, ...props } }) =>
                      <FormField

                        key={'id'}
                        id={'id'}
                        autoComplete={false}
                        type='text'
                        label='ID'
                        rows={1}
                        multiline={false}
                        required={false}
                        disabled={true}
                        inputProps={{
                          disabled: true
                        }}
                        inputRef={ref}
                        {...props}
                      />}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>{/*Referencia*/}
                  <Controller
                    name={'referencia'}
                    defaultValue=''
                    control={props.control}
                    render={({ field: { ref, ...props } }) =>
                      <FormField

                        key={'referencia'}
                        id={'referencia'}
                        autoComplete={false}
                        type='text'
                        label='Referência'
                        rows={1}
                        multiline={false}
                        required={false}
                        disabled={false}
                        inputRef={ref}
                        {...props}
                      />}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>{/*IdExterno*/}
                  <Controller
                    name={'id_externo'}
                    defaultValue=''
                    control={props.control}
                    render={({ field: { ref, ...props } }) =>
                      <FormField

                        autoComplete={false}
                        type='text'
                        label='Id Externo'
                        rows={1}
                        multiline={false}
                        required={false}
                        disabled={false}
                        inputRef={ref}
                        {...props}
                      />}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>{/*Ativo*/}
                  <Controller
                    name='ativo'
                    control={control}
                    defaultValue={{ value: 'N' }}
                    render={({ field: { onChange, ...props } }) => (
                      <AutoCompleteStyled
                        disableClearable //Retira o X (clear)
                        options={opcoesAtivo}
                        getOptionLabel={(option) => option.label || ''}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        renderInput={(params) => <FormField {...params} label='Ativo' />}
                        onChange={(_, data) => {
                          return onChange(data);
                        }}
                        disabled={false}
                        {...props}
                      />
                    )}

                  />
                </Grid>

                <Grid item xs={12} sm={12}>{/*Nome*/}
                  <Controller
                    name={'nome'}
                    defaultValue=''
                    control={props.control}
                    render={({ field: { ref, ...props } }) =>
                      <FormField

                        key={'nome'}
                        id={'nome'}
                        autoComplete={false}
                        type='text'
                        label='Nome'
                        rows={1}
                        multiline={false}
                        required={true}
                        disabled={false}
                        inputRef={ref}
                        {...props}
                      />}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>{/*Grupo*/}
                  <Controller
                    name='grupo_projeto_id'
                    control={control}
                    defaultValue={{ value: -1 }}
                    render={({ field: { onChange, ...props } }) => (
                      <AutoCompleteStyled
                        options={opcoesGrupo}
                        getOptionLabel={(option) => option.label || ''}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        renderInput={(params) => <FormField required {...params} label='Grupo' />}
                        onChange={(_, data) => {
                          return onChange(data);
                        }}
                        disabled={false}
                        {...props}

                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>{/*observacao*/}
                  <Controller
                    name={'observacao'}
                    defaultValue=''
                    control={props.control}
                    render={({ field: { ref, ...props } }) =>
                      <FormField

                        key={'observacao'}
                        id={'observacao'}
                        autoComplete={false}
                        type='text'
                        label='Observação'
                        rows={2}
                        multiline={true}
                        required={false}
                        disabled={false}
                        inputRef={ref}
                        {...props}
                      />}
                  />
                </Grid>

              </Grid>
            </Grid>

            <Grid item xs={12} sm={3}>{/*Foto*/}
              {/* <MDBox m={2}
            component="img"
            src={currentImage}
            alt="Product Image"
            shadow="lg"
            borderRadius="lg"
            width="100%"
          // onClick={}
          /> */}
              <ImagemProjeto

                projetoAtual={projetoAtual}
                setValue={setValue}
                imagePreviewUrl={imagePreviewUrl}
                setImagePreviewUrl={setImagePreviewUrl}

              />

            </Grid>

          </Grid>

          <MDBox width="100%" display="flex" justifyContent="flex-end" sx={{ mt: 4 }}>

            <MDButton
              variant="gradient"
              color="dark"
              type="button"
              onClick={() => handleClickOpcoes()}
              sx={{ mr: 2 }}
            >
              Opções
            </MDButton>

            <MDButton
              variant="gradient"
              color="info"
              type="submit"
            //type="button"
            //onClick={() => handleSubmit(gravarRegistro)()}
            >
              Gravar
            </MDButton>
          </MDBox>

        </form>

      </MDBox>
    </>
  )
}

export default Cabecalho;