import React, { useState, useEffect, useContext } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AutoCompleteStyled } from './AutoCompleteStyled';
import FormField from './FormField';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MessagesContext from "hooks/MessagesContext";


export default function DialogoConfirma(props) {

  const { open, message, handleCancel, handleConfirm, title, color, id, tipoEscolhido, setNovaCategoria,
    novaCategoria, recarregaCategoria, } = props;

  const messageContext = useContext(MessagesContext);
  const { MensagemErro } = messageContext;
  const [desabilitaBotao, setDesabilitaBotao] = useState(true)
  const [optionCategoria, setOptionCategoria] = useState([
    { value: -1, label: 'Decidas' },
  ]);

  const buscaCategoria = async () => {

    try {
      let complementoUrl = ''
      complementoUrl = `${complementoUrl}&order=nome`;
      complementoUrl = tipoEscolhido ? `${complementoUrl}&tipo=${tipoEscolhido}` : '';
      complementoUrl = tipoEscolhido ? `${complementoUrl}&id_exclusao=${id}` : '';
      const url = `${rotafechada()}categoria?limit=-1${complementoUrl}`;
      let retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      let opcoesCategoriaTemp = [{ value: '', label: '' }];

      data.map((item) => {
        if (!item.verificador && item.categoria_id != id) {
          opcoesCategoriaTemp.push({ value: item.id, label: item.nome });
        }
      });
      setOptionCategoria(opcoesCategoriaTemp);

      return opcoesCategoriaTemp;
    } catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  };

  useEffect(async () => {
    await buscaCategoria();
    setDesabilitaBotao(true);
  }, [id]);

  return (
    <div>

      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
          <br></br>
          <MDTypography variant="caption">
            Enviar os lançamentos existentes para:
          </MDTypography>
          <AutoCompleteStyled
            autocomplete="off"
            pt={2}
            mt={2}
            required
            options={optionCategoria}
            getOptionLabel={(option) => option.label || ""}
            renderInput={(params) => <FormField {...params} label={''} />}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            value={novaCategoria}
            onChange={(event, newValue) => {
              setNovaCategoria(newValue)
              if (newValue) {
                setDesabilitaBotao(false);
              }
              if (!newValue) {
                setDesabilitaBotao(true);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
            <MDButton onClick={handleCancel} variant="outlined" color="dark">
              Cancelar
            </MDButton>
            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
              <MDButton disabled={desabilitaBotao} onClick={handleConfirm} variant="gradient" color={color} sx={{ height: "100%" }}>
                Excluir categoria
              </MDButton>
            </MDBox>
          </MDBox>
        </DialogActions>
      </Dialog>
    </div>
  );
}
