import { useContext, useEffect, useState } from "react";

// react-router-dom components
import { Link, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import PreContratoLayout from "./precontratolayout";
import bgImage from "assets/images/personalizados/headerlogin.png";

import { rotaaberta, rotafechada } from '../../../../commons/urlconf';
import api from '../../../../commons/api';
import { incMinutes, minutesBetween, retiraEspacos } from '../../../../commons/utils';

import AuthContext from "../../../../hooks/AuthContext";
import MessageContext from "../../../../hooks/MessagesContext";
import { Alert, Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import { useMaterialUIController, carregaTemplate } from "context";
import { adicionaMascaraCPFCNPJ } from "commons/utils";
import { Image, Window } from "@mui/icons-material";
import FormField from "componentes/FormField";
import { Box } from "@mui/system";
import DialogoConfirma from "componentes/DialogoConfirma";

/*

Essa rota precisa receber o token da turma, assim ela carregará os dados
via back

*/


const camposEmpresa = {
  id: '',
  tipo_empresa_id: '',
  fisica_juridica: 'J',
  razaosocial: '',
  fantasia: '',
  cnpj: '',
  endereco_rua: '',
  endereco_numero: '',
  endereco_bairro: '',
  endereco_cep: '',
  endereco_cidade: '',
  endereco_cidade_id: '',
  endereco_estado: '',

  nome_socio: '',
  cpf_socio: '',
  rg_socio: '',
  datadenascimento_socio: '',
  telefonecelular_socio: '',
  nome_cracha: '',
  emissao_nf:'',

  textotermosdeuso: 'Li e aceito as CONDIÇÕES GERAIS DE PRESTAÇÃO DE SERVIÇOS EDUCACIONAIS – MÉTODO GEN ',
  textopoliticaprivacidade: 'Li e concordo com a Política de Privacidade',
  textorepresentantelegal: 'Confirmo que sou o representante legal pela empresa ou que fui autorizado pela empresa a me inscrever',
  textoinformacoesverificas: 'Confirmo que todos os dados acima são verdadeiro e assumo a responsabilidade jurídica por qualquer informação inverídica',
  textoenviodocumentos: 'Estou ciente que se solicitado terei que enviar documentos que comprovem os dados aqui informados',
  //textoClausulaIndicacao: 'Desconto de 10% aceitando a cláusula de compromisso de indicação'

}

function DadosParticipante(props) {

  const { datasetEmpresas, setDatasetEmpresas, label, disabledImput } = props;

  const alteraCampo = (e) => {
    const dados = { ...datasetEmpresas };
    dados[e.target.name] = e.target.value;
    setDatasetEmpresas(dados);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Typography variant='h5'>{label}</Typography>
      </Grid>

      <Grid item xs={12} sm={12}>{/*Nome contato*/}
        <FormField
          required={true}
          label="Nome Completo"
          name='nome_socio'
          value={datasetEmpresas.nome_socio}
          onChange={alteraCampo}
          disabled={disabledImput}
        />
      </Grid>
      <Grid item xs={12} sm={6}>{/*CPF Sócio*/}
        <FormField
          required={true}
          label="CPF"
          name='cpf_socio'
          value={datasetEmpresas.cpf_socio}
          onChange={alteraCampo}
          disabled={disabledImput}
        />
      </Grid>
      <Grid item xs={12} sm={6}>{/*RG Sócio*/}
        <FormField
          label="RG"
          name='rg_socio'
          value={datasetEmpresas.rg_socio}
          onChange={alteraCampo}
          disabled={disabledImput}
        />
      </Grid>

      <Grid item xs={12} sm={12}>{/*Data de Nascimento*/}
        <FormField
          required={true}
          label="Data de Nascimento"
          disabled={disabledImput}
          name='datadenascimento_socio'
          value={datasetEmpresas.datadenascimento_socio}
          onChange={alteraCampo}
        />
      </Grid>

      <Grid item xs={12} sm={8}>{/*Telefone*/}
        <FormField
          required={true}
          label="Telefone Celular (Com WhatsApp)"
          disabled={disabledImput}
          name='telefonecelular_socio'
          value={datasetEmpresas.telefonecelular_socio}
          onChange={alteraCampo}
        />
      </Grid>

      <Grid item xs={12} sm={12}>{/*Nome Crachá*/}
        <FormField
          required={true}
          label="Nome que será impresso no Crachá (Utilize no máximo 2 nomes)"
          name='nome_cracha'
          value={datasetEmpresas.nome_cracha}
          onChange={alteraCampo}
          disabled={disabledImput}
        />
      </Grid>
    </Grid>
  )
}

function DadosTreinamento(props) {
  const { datasetEmpresas,

    checkTermosUso,
    setCheckTermosUso,

    checkClausulaOpcional,
    setCheckClausulaOpcional,

    checkRepresentanteLegal,
    setCheckRepresentanteLegal,

    checkInformacoesVeridicas,
    setCheckInformacoesVeridicas,

    checkEnvioDocumentacao,
    setCheckEnvioDocumentacao,

    formaPagamento,
    setFormaPagamento,

    dadosTurma
  } = props;

  return (
    <>
      <Box mt={2}>

        <Typography mt={2} mb={2} variant='h5'>Dados da Turma</Typography>

        <Typography mt={2} mb={2} variant='caption' fontStyle={{ fontWeight: 'bold' }}>Nome: {dadosTurma.nome}</Typography>
        <br />
        <Typography mt={2} mb={2} variant='caption' fontStyle={{ fontWeight: 'bold' }}>Cidade: {dadosTurma.cidade}</Typography>
        <br />
        <Typography mt={2} mb={2} variant='caption' fontStyle={{ fontWeight: 'bold' }}>{dadosTurma.texto_adicional}</Typography>


        {
          dadosTurma.forma_pagamento?.length > 0 &&
          <>
            <Typography mt={2} mb={2} variant='h5'>Forma de Pagamento</Typography>
            <RadioGroup
              name="formaPagamento"
              value={formaPagamento}
              onChange={(e) => setFormaPagamento(e.target.value)}
              row
            >
              {/* <FormControlLabel value="MetodoGen4699APrazo10X" control={<Radio />} label="Método Gen por R$ 4.699,00 em 10 Parcelas no Cartão de Crédito" />
          <FormControlLabel value="MetodoGen4229" control={<Radio />} label="Método Gen por R$ 4.229,10 a vista (pix)" /> */}
              {
                dadosTurma.forma_pagamento?.map((item) =>
                  <FormControlLabel value={`${item.nome}-${item.valor}`} control={<Radio />} label={`${item.nome} ${parseInt(item.valor, 10) > 0 ? ' - R$ ' + item.valor : ''}`} />
                )
              }

            </RadioGroup>
          </>
        }

      </Box>

      {
        dadosTurma.clausula_opcional &&
        <Box mt={2}>
          <Typography mt={2} mb={2} variant='h5'>Opcional</Typography>
          <Box>
            <Checkbox checked={checkClausulaOpcional} onChange={(e) => setCheckClausulaOpcional(e.target.checked)} />
            <Typography mt={1.5} variant='caption' sx={{ fontWeight: 'bold', fontSize: '15px' }}>
              {dadosTurma.clausula_opcional}
            </Typography>
          </Box>
        </Box>

      }

      <Grid item xs={12} sm={12} mt={2}>
        <Typography variant='h5'>Aceite</Typography>
      </Grid>

      <Box display='flex' mt={2}>
        <Checkbox checked={checkTermosUso} onChange={(e) => setCheckTermosUso(e.target.checked)} />
        <Typography mt={1.5} variant='caption'>
          <a href={`/geneducacao/contratoturma/${dadosTurma.id}`} target="_blank" rel="noopener noreferrer">
            {camposEmpresa.textotermosdeuso}
            (clique aqui para visualizar)
          </a>
        </Typography>
      </Box>

      {
        datasetEmpresas?.fisica_juridica === 'J' &&
        <Box display='flex'>
          <Checkbox checked={checkRepresentanteLegal} onChange={(e) => setCheckRepresentanteLegal(e.target.checked)} />

          <Typography mt={1.5} variant='caption'> {camposEmpresa.textorepresentantelegal}</Typography>
        </Box>
      }


      <Box display='flex'>
        <Checkbox checked={checkInformacoesVeridicas} onChange={(e) => setCheckInformacoesVeridicas(e.target.checked)} />
        <Typography mt={1.5} variant='caption'>{camposEmpresa.textoinformacoesverificas}</Typography>
      </Box>

      <Box display='flex'>
        <Checkbox checked={checkEnvioDocumentacao} onChange={(e) => setCheckEnvioDocumentacao(e.target.checked)} />
        <Typography mt={1.5} variant='caption'>{camposEmpresa.textoenviodocumentos}</Typography>
      </Box>
    </>
  )
}

function PreContratoEtapaPreenchimento(props) {

  const { token } = props

  const messageContext = useContext(MessageContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

  const [datasetEmpresas, setDatasetEmpresas] = useState({ ...camposEmpresa });

  const opcaoDefaultCidadePessoa = { value: -1, label: '<Selecione uma cidade>', uf: '' };
  const [ufAtual, setUfAtual] = useState({ value: 'MS', label: 'Mato Grosso do Sul' });
  const [cidadeAtual, setCidadeAtual] = useState(opcaoDefaultCidadePessoa);

  const [opcoesCidade, setOpcoesCidade] = useState([opcaoDefaultCidadePessoa]);
  const [opcoesEstado, setOpcoesEstado] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceara' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ]);

  const [checkTermosUso, setCheckTermosUso] = useState(false);
  const [checkRepresentanteLegal, setCheckRepresentanteLegal] = useState(false);
  const [checkEnvioDocumentacao, setCheckEnvioDocumentacao] = useState(false);
  const [checkInformacoesVeridicas, setCheckInformacoesVeridicas] = useState(false);

  //****desconto**** */
  const [checkClausulaOpcional, setCheckClausulaOpcional] = useState(false);

  const [formaPagamento, setFormaPagamento] = useState('');
  const [turma, setTurma] = useState('Dourados082023');

  const [openMensagemConcluido, setOpenMensagemConcluido] = useState(false);

  const [validaToken, setValidaToken] = useState(true);
  const [validaTokenEmail, setValidaTokenEmail] = useState(true);
  const [dadosTurma, setDadosTurma] = useState({});


  const [disabledImput, setDisabledImput] = useState(false);


  const [tempoSessao, setTempoSessao] = useState('');
  const [minutosVencimento, setMinutosVencimento] = useState(0);
  const [emailPreenchido, setEmailPreenchido] = useState('');

  const [dialogoConfirma, setDialogoConfirma] = useState({
    open: false,
    message: '',
    handleCancel: null,
    handleConfirm: null,
    title: ''
  });


  const baseUrl = `${rotaaberta()}gen/`;

  const limpaCampos = () => {
    setDatasetEmpresas({ ...camposEmpresa });
    setCheckTermosUso(false);
    setCheckRepresentanteLegal(false);
    setCheckEnvioDocumentacao(false);
    setCheckInformacoesVeridicas(false);
  }

  const BuscaCidades = async (paramUf = '') => {

    if (!paramUf) {
      setCidadeAtual(opcaoDefaultCidadePessoa);
    }

    if (`${paramUf}` === '-1') {
      return;
    }

    try {
      let url = `${baseUrl}cidades?uf=${paramUf}`;
      //URL montada para procurar a cidade de acordo com o estado escolhido
      const retorno = await api.post(url, { token, uf: paramUf });
      const { data } = retorno.data;
      let opcoesCidadeTemp = [];
      opcoesCidadeTemp.push({ ...opcaoDefaultCidadePessoa });

      data.map((item) => {
        opcoesCidadeTemp.push({ /*id: item.id,*/ value: item.id, label: item.nome, uf: item.uf })
      })

      setOpcoesCidade(opcoesCidadeTemp);

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
      setValidaToken(true)
    }

  }

  const validaDadosInsercao = (dados = {}) => {


    if ((datasetEmpresas.fisica_juridica !== 'J') && (datasetEmpresas.fisica_juridica !== 'F')) {
      MensagemErro('Escolha o tipo de Contrato (Pessoa Física ou Jurídica)');
      return false;
    }

    const textorepresentantelegal = datasetEmpresas.fisica_juridica === 'J' ? '(representante legal)' : '';

    if (dados.nome_socio === '') {
      MensagemErro(`O nome ${textorepresentantelegal} é um campo obrigatório`);
      return false;
    }

    if (dados.nome_cracha === '') {
      MensagemErro(`O nome que será impresso no crachá é um campo obrigatório`);
      return false;
    }

    if (dados.cpf_socio === '') {
      MensagemErro(`O cpf ${textorepresentantelegal} é um campo obrigatório`);
      return false;
    }

    if (dados.datadenascimento_socio === '') {
      MensagemErro(`A data de nascimento ${textorepresentantelegal} é um campo obrigatório`);
      return false;
    }

    if (dados.telefonecelular_socio === '') {
      MensagemErro(`O telefone ${textorepresentantelegal} é um campo obrigatório`);
      return false;
    }

    if (dados.razaosocial === '') {
      MensagemErro('A Razão Social da Empresa é um campo obrigatório');
      return false;
    }

    if (dados.fantasia === '') {
      MensagemErro('O nome Fantasia é um campo obrigatório');
      return false;
    }

    if (dados.cnpj === '') {
      MensagemErro('O cnpj é um campo obrigatório');
      return false;
    }

    if (dados.endereco_rua === '') {
      MensagemErro('A Rua é campo obrigatório');
      return false;
    }

    if (dados.endereco_numero === '') {
      MensagemErro('O número é campo obrigatório');
      return false;
    }

    if (dados.endereco_bairro === '') {
      MensagemErro('O bairro é campo obrigatório');
      return false;
    }

    if (dados.endereco_cidade_id === '') {
      MensagemErro('Escolha um estado e uma  cidade na lista');
      return false;
    }

    if (dados.endereco_estado === '') {
      MensagemErro('Escolha um estado na lista');
      return false;
    }

    if (dados.emissao_nf === '') {
      MensagemErro('Escolha o destino da emissão da NF se na física ou na jurídica');
      return false;
    }

    return true;
  }

  const handeClickGravarPreContrato = async () => {


    if ((datasetEmpresas.fisica_juridica === 'J') && (!checkRepresentanteLegal)) {
      MensagemErro('É necessário concordar com todos os termos para contratar o serviço!');
      return;
    }
    if ((!checkRepresentanteLegal) || (!checkTermosUso) || (!checkInformacoesVeridicas) || (!checkEnvioDocumentacao)) {
      MensagemErro('É necessário concordar com todos os termos do Aceite para contratar o serviço!');
      return;
    }

    if (dadosTurma.forma_pagamento?.length > 0) {

      if (formaPagamento === '') {
        MensagemErro('É necessário escolher a forma de pagamento!');
        return;
      }
    }

    gravarPreContrato();

  }

  const gravarPreContrato = async () => {

    setDialogoConfirma({ open: false });

    const dados = {

      turma: dadosTurma.id,

      fisica_juridica: datasetEmpresas.fisica_juridica || '',
      razaosocial: datasetEmpresas.razaosocial || '',
      fantasia: datasetEmpresas.fantasia || '',
      cnpj: datasetEmpresas.cnpj || '',

      endereco_rua: datasetEmpresas.endereco_rua || '',
      endereco_numero: datasetEmpresas.endereco_numero || '',
      endereco_bairro: datasetEmpresas.endereco_bairro || '',
      endereco_cep: datasetEmpresas.endereco_cep || '',
      endereco_cidade_id: `${parseInt(cidadeAtual?.value)}` || '',
      endereco_cidade: cidadeAtual?.label || '',
      endereco_estado: cidadeAtual?.uf || '',

      nome_socio: datasetEmpresas.nome_socio || '',
      cpf_socio: datasetEmpresas.cpf_socio || '',
      rg_socio: datasetEmpresas.rg_socio || '',
      datadenascimento_socio: datasetEmpresas.datadenascimento_socio || '',
      telefonecelular_socio: datasetEmpresas.telefonecelular_socio || '',
      nome_cracha: datasetEmpresas.nome_cracha || '',

      emissao_nf:datasetEmpresas.emissao_nf||'',
      forma_pagamento: formaPagamento,
      aceitou_clausula_opcional: checkClausulaOpcional ? 'S' : 'N',

      textotermosdeuso: camposEmpresa.textotermosdeuso || '',
      textopoliticaprivacidade: camposEmpresa.textopoliticaprivacidade || '',
      textorepresentantelegal: camposEmpresa.textorepresentantelegal || '',
      textoinformacoesverificas: camposEmpresa.textoinformacoesverificas || '',
      textoenviodocumentos: camposEmpresa.textoenviodocumentos || '',

      token
    };

    if (dados.fisica_juridica === 'F') {
      dados.razaosocial = dados.nome_socio;
      dados.fantasia = dados.nome_socio;
      dados.cnpj = dados.cpf_socio;
    }

    try {

      if (!validaDadosInsercao(dados)) {
        return;
      }
      MostraTelaEspera('Aguarde... estamos processando seu pedido!');
      const retorno = await api.post(`${baseUrl}/matriculaturma`, dados);
      FechaTelaEspera();
      MensagemAviso('Inserido com Sucesso!');
      setOpenMensagemConcluido(true);

      limpaCampos();

    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  useEffect(() => {
    validaTokenBack();
    limpaCampos();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {

      if (minutosVencimento > 0) {
        setMinutosVencimento((c) => c - 1);
      }
      if (minutosVencimento <= 0) {
        setDisabledImput(true)
        setMinutosVencimento((c) => c = 0)
      }


    }, (1000 * 60));

    return () => clearInterval(interval);

  }, [minutosVencimento]);

  useEffect(async () => {
    if (ufAtual) {
      await BuscaCidades(ufAtual.value ? ufAtual.value : '');
    }
    else {
      setCidadeAtual(opcaoDefaultCidadePessoa)
    }
  }, [ufAtual])

  const alteraCampo = (e) => {
    const dados = { ...datasetEmpresas };
    dados[e.target.name] = e.target.value;
    setDatasetEmpresas(dados);
  }

  const setEmissaoNF = (valor) => {
    debugger;
    const dados = { ...datasetEmpresas };
    dados.emissao_nf = valor;
    setDatasetEmpresas(dados);
  }

  const handleClickEnviarEmail = async () => {

    try {

      MostraTelaEspera('Enviando email...');
      const retorno = await api.post(`${baseUrl}tokenemail`, { token });
      FechaTelaEspera();
      setValidaToken(false)
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const validaTokenBack = async () => {
    try {

      const retorno = await api.post(`${baseUrl}validatoken`, { token });
      console.log(retorno.data);

      setMinutosVencimento(retorno.data.minutos);
      setEmailPreenchido(retorno.data.email);
      setDadosTurma(retorno.data.turma);

      if (retorno.data.turma.forma_pagamento?.length > 0) {
        //marco a primeira opção
        setFormaPagamento(`${retorno.data.turma.forma_pagamento[0].nome}-${retorno.data.turma.forma_pagamento[0].valor}`)
      }
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
      setValidaTokenEmail(false)
    }
  }


  if (validaTokenEmail === false) {
    return (
      <PreContratoLayout image={bgImage}>

        <Dialog
          open={validaToken}
        //onClose={() => setValidaToken(false)}
        >
          <DialogTitle id="alert-dialog-title">
            {' Ativação expirada '}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`O tempo de 1 hora de ativação enviada no seu email venceu, clique no botão abaixo para solicitar um novo email.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>

            <Button onClick={handleClickEnviarEmail} >
              Solicitar
            </Button>
          </DialogActions>

        </Dialog>

        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Gen Educação - Contratação dos Serviços
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>

            </MDTypography>
          </MDBox>

          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" pb={3} px={3}>


              <MDBox mt={4} mb={1}>
                <MDTypography display="block" variant="medium" color="black" my={1}>
                  Email de ativação enviado com Sucesso.
                </MDTypography>
                <MDTypography display="block" variant="button" color="black" my={1}>
                  Acesse novamente seu email e clique no novo link
                </MDTypography>
              </MDBox>

            </MDBox>
          </MDBox>
        </Card>
      </PreContratoLayout >
    );
  }

  if (validaTokenEmail === true) {
    return (
      <PreContratoLayout image={bgImage}>
        <DialogoConfirma
          open={dialogoConfirma?.open || false}
          message={dialogoConfirma?.message || ''}
          handleCancel={dialogoConfirma?.handleCancel || null}
          handleConfirm={dialogoConfirma?.handleConfirm || null}
          title={dialogoConfirma?.title || ''}
        />

        <Dialog
          open={openMensagemConcluido}
          onClose={() => setOpenMensagemConcluido(false)}
        >
          <DialogTitle id="alert-dialog-title">
            {'Parabéns'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {'Solicitação de Contratação Concluída com Sucesso! Avise seu consultor comercial!'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenMensagemConcluido(false)} >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Gen Educação - Contratação dos Serviços
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Termo de Adesão
              <br />
              Email de Solicitação: {emailPreenchido}
              <br />
              Turma:{dadosTurma.id || ''}-{dadosTurma.nome || ''}
              <br />
              Preencha todos os dados e clique em enviar dados
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Seu acesso expira em: {minutosVencimento} minutos
            </MDTypography>
          </MDBox>

          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" pb={3} px={3}>
              <Grid container spacing={3}>

                <Grid item xs={12} sm={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant='h5'>Dados da Empresa</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>{/* Cnpj */}
                      <FormField
                        required={true}
                        label="Cnpj"
                        disabled={disabledImput}
                        placeholder="" name='cnpj' value={datasetEmpresas.cnpj} onChange={alteraCampo} />

                    </Grid>
                    <Grid item xs={12} sm={12}>{/* Razão Social */}
                      <FormField
                        required={true}
                        label="Razão Social"
                        placeholder=""
                        name='razaosocial'
                        value={datasetEmpresas.razaosocial}
                        disabled={disabledImput}
                        onChange={alteraCampo} />
                    </Grid>

                    <Grid item xs={12} sm={12}>{/* Nome Fantasia */}

                      <FormField
                        required={true}
                        label="Nome Fantasia"
                        placeholder="" name='fantasia' value={datasetEmpresas.fantasia} onChange={alteraCampo}
                        disabled={disabledImput}
                      />

                    </Grid>
                  </Grid>
                </Grid>



                <Grid item xs={12} sm={12}>{/*Endereço*/}
                  <Typography variant='h5'>Endereço {`${datasetEmpresas?.fisica_juridica === 'J' ? 'da Empresa' : ''}`}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>{/* Rua */}
                  <FormField
                    required={true}
                    label="Rua"
                    placeholder=""
                    name='endereco_rua' value={datasetEmpresas.endereco_rua} onChange={alteraCampo}
                    disabled={disabledImput}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>{/* Nº */}
                  <FormField
                    required={true}
                    label="Nº"
                    placeholder=""
                    inputProps={{ type: "number" }}
                    name='endereco_numero' value={datasetEmpresas.endereco_numero} onChange={alteraCampo}
                    disabled={disabledImput}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>{/* Bairro */}
                  <FormField
                    required={true}
                    label="Bairro"
                    placeholder=""
                    name='endereco_bairro' value={datasetEmpresas.endereco_bairro} onChange={alteraCampo}
                    disabled={disabledImput}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>{/* Estado */}
                  <Autocomplete

                    inputProps={{
                      name: 'uf'
                    }}

                    value={ufAtual}
                    options={opcoesEstado}
                    required={true}
                    renderInput={(params) => (
                      <FormField {...params} label="Estado" InputLabelProps={{ shrink: true }} />
                    )}
                    onChange={(event, newValue) => {
                      setUfAtual(newValue)
                    }}
                    disabled={disabledImput}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>{/* Cidade */}
                  <Autocomplete

                    inputProps={{
                      name: 'cidade'
                    }}

                    value={cidadeAtual}
                    options={opcoesCidade}
                    renderInput={(params) => (
                      <FormField {...params} label="Cidade" InputLabelProps={{ shrink: true }} />
                    )}
                    onChange={(event, newValue) => {
                      setCidadeAtual(newValue)
                    }}
                    disabled={disabledImput}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>{/* CEP */}
                  <FormField
                    label="CEP"
                    placeholder=""
                    name='endereco_cep' value={datasetEmpresas.endereco_cep} onChange={alteraCampo}
                    disabled={disabledImput}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <DadosParticipante
                    label={`Dados do Participante`}
                    datasetEmpresas={datasetEmpresas}
                    setDatasetEmpresas={setDatasetEmpresas}
                    disabledImput={disabledImput}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <>
                    <Typography mt={2} mb={2} variant='h5'>Emissão da NF</Typography>
                    <RadioGroup
                      name="emissao_nf"
                      value={datasetEmpresas.emissao_nf}
                      onChange={(e) => setEmissaoNF(e.target.value)}
                      row
                    >
                      <FormControlLabel value={`Física`} control={<Radio />} label='Física' />
                      <FormControlLabel value={`Jurídica`} control={<Radio />} label='Jurídica' />
                        
                    </RadioGroup>
                  </>

                </Grid>

                <Grid item xs={12} sm={12} m={0}>{/* Plano */}
                  < DadosTreinamento
                    datasetEmpresas={datasetEmpresas}
                    setDatasetEmpresas={setDatasetEmpresas}
                    disabledImput={disabledImput}
                    checkTermosUso={checkTermosUso}
                    setCheckTermosUso={setCheckTermosUso}
                    checkClausulaOpcional={checkClausulaOpcional}
                    setCheckClausulaOpcional={setCheckClausulaOpcional}
                    checkRepresentanteLegal={checkRepresentanteLegal}
                    setCheckRepresentanteLegal={setCheckRepresentanteLegal}
                    checkInformacoesVeridicas={checkInformacoesVeridicas}
                    setCheckInformacoesVeridicas={setCheckInformacoesVeridicas}
                    checkEnvioDocumentacao={checkEnvioDocumentacao}
                    setCheckEnvioDocumentacao={setCheckEnvioDocumentacao}
                    formaPagamento={formaPagamento}
                    setFormaPagamento={setFormaPagamento}
                    turma={turma}
                    setTurma={setTurma}
                    dadosTurma={dadosTurma}
                  />



                </Grid>
              </Grid>

              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth onClick={handeClickGravarPreContrato}>
                  ENVIAR
                </MDButton>
              </MDBox>

            </MDBox>
          </MDBox>
        </Card>
      </PreContratoLayout >
    );
  }

}


export function GenEducacaoPreContratoTurmaSolicitaMatricula() {

  const messageContext = useContext(MessageContext);
  const { codigoturma } = useParams();
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

  const baseUrl = `${rotaaberta()}gen/`;
  const [openMensagemConcluido, setOpenMensagemConcluido] = useState(false);
  const [email, setEmail] = useState('');
  const [emailConfirmacao, setEmailConfirmacao] = useState('');
  const [token, setToken] = useState('');

  const handleClickEnviar = async () => {

    if (!((email !== '') && (emailConfirmacao !== ''))) {
      MensagemErro('É necessário preencher os dois campos e eles precisam ser iguais!');
      return;
    }

    if (email !== emailConfirmacao) {
      MensagemErro('Os emails precisam ser iguais!');
      return;
    }

    //retira o espaço e passa para minusculo
    const email_temp = retiraEspacos(`${email}`.toLowerCase());

    try {

      MostraTelaEspera('Aguarde... estamos processando seu pedido!');
      const retorno = await api.post(`${baseUrl}email`, {
        email: email_temp,
        turma: codigoturma,
        token
      });
      FechaTelaEspera();
      setOpenMensagemConcluido(true);
      setEmail('');
      setEmailConfirmacao('');
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }


  const buscaToken = async () => {
    try {
      let url = `${baseUrl}token`;
      const retorno = await api.post(url);
      const { msg } = retorno.data;
      setToken(msg);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  useEffect(() => {
    // Ao entrar na pagina ele solicita um token para poder enviar a rota que envia o email
    //para o cliente
    if (parseInt(codigoturma, 10) > 0) {
      buscaToken();
    }

  }, [])


  return (

    <PreContratoLayout image={bgImage}>

      {
        (!(parseInt(codigoturma, 10) > 0)) ?

          <Dialog
            open={true}
          >
            <DialogTitle id="alert-dialog-title">
              {'Atenção'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {'Verifique o LINK de matrícula, é necessário enviar a turma junto com o Link!'}
              </DialogContentText>
            </DialogContent>

          </Dialog>

          :
          <>
            <Dialog
              open={openMensagemConcluido}
              onClose={() => setOpenMensagemConcluido(false)}
            >
              <DialogTitle id="alert-dialog-title">
                {'Parabéns'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {'Acesse seu email e clique no link para preencher os dados e fazer a contratação dos serviços (email válido por 1 hora)'}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenMensagemConcluido(false)} >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Card>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Contratação dos Serviços - Método GEN
                </MDTypography>
                <MDTypography display="block" variant="button" color="white" my={1}>
                  Digite o email que você usará para assinar o serviço (FormasetFormaPagamento/curso)
                </MDTypography>
              </MDBox>

              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" pb={3} px={3}>
                  <Grid container spacing={3}>

                    <Grid item xs={12} sm={12}>{/*Email*/}
                      <FormField
                        label="Email"
                        disabled={false}
                        name='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>{/*Confirmacao do Email*/}
                      <FormField
                        label="Confirme o Email"
                        disabled={false}
                        name='emailconfirmacao'
                        value={emailConfirmacao}
                        onChange={(e) => setEmailConfirmacao(e.target.value)}
                      />
                    </Grid>
                  </Grid>

                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="info" fullWidth onClick={handleClickEnviar}>
                      ENVIAR
                    </MDButton>
                  </MDBox>

                </MDBox>
              </MDBox>
            </Card>
          </>
      }
    </PreContratoLayout >
  )
}

export function GenEducacaoPreContratoTurmaRealizaMatricula() {

  const { token } = useParams();

  console.log('Token:', token);

  return (
    <>
      <PreContratoEtapaPreenchimento
        token={token}
      />

    </>
  )

}

