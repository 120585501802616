import React, { useContext, useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MessagesContext from 'hooks/MessagesContext';
import AuthContext from "hooks/AuthContext";
import { useForm, useWatch } from 'react-hook-form';
import MDButton from 'components/MDButton';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import Cabecalho from './cabecalho';
import Relatorios_Personalizados_Sql from './relatorios_personalizados_sql';
import Relatorios_Personalizados_Filtros from './relatorios_personalizados_filtros';
import { Icon } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import DialogoFiltrosRelatorio from '../Filtro';


function Cadastro(props) {

  const navigate = useNavigate();

  const { Relatorios_PersonalizadosAtual, setRelatorios_PersonalizadosAtual, setAba } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const { handleSubmit, control, setValue, getValues, setFocus } = useForm({});
  const [parametrosPessoa, setParametrosPessoa] = useState({});
  const [foco, setFoco] = useState(false)


  const [opcoesModulo_Sistema_id, setOpcoesModulo_Sistema_id] = useState([]);
  const [opcoesPermissao_id, setOpcoesPermissao_id] = useState([]);
  const [recarregaComponenteOpcoes, setRecarregaComponenteOpcoes] = useState(false);
  const [opcoesCategoria_Central_Relatorio_id, setCategoria_Central_Relatorio_id] = useState([]);

  const [opcoesTipo, setOpcoesTipo] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'INT', label: 'Inteiro' },
    { value: 'DEC', label: 'Decimal' },
    { value: 'DAT', label: 'Data' },
    { value: 'HOR', label: 'Hora' },
    { value: 'DEH', label: 'Data e Hora' },
    { value: 'STR', label: 'String' },
    { value: 'OPC', label: 'Opções' },
  ]);

  const opcoesAtivo = [
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'S', label: 'Sim' },
    { value: 'N', label: 'Não' },
  ];

  const opcoesPeriodoDataTipo = [
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'E', label: 'Exata (dd/MM/yyyy 00:00:00 and dd/MM/yyyy 23:59:59)' },
    { value: 'I', label: 'Início dd/MM/yyyy 00:00:00' },
    { value: 'F', label: 'Fim dd/MM/yyyy 23:59:59' },
  ];


  const [opcoesOperadorJuncao, setOpcoesOperadorJuncao] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'A', label: 'AND' },
    { value: 'O', label: 'OR' },

  ]);

  const [filtros, setFiltros] = useState({
    open: false,
    itens: [{}],
    dataset: [{}]
  });

  const onSubmit = (data) => {
    handleClickGravar(data);
  }

  const limpaInputs = () => {
    setValue('id', '');
    setValue('modulo_sistema_id', { value: -1, label: 'Sem módulo' });
    setValue('categoria_central_relatorio_id', { value: -1, label: 'Sem categoria' });
    setValue('permissao_id', { value: -1, label: 'Sem permissão' });
    setValue(`nome`, '');
    setValue(`arquivo_json`, '');
    setValue(`ativo`, { value: 'S', label: 'Sim' });
    setValue(`filtro_externo`, { value: 'N', label: 'Não' });
  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;

    // if (!data.arquivo_json) {
    //   camposObrigatorios = `${camposObrigatorios}/Arquivo_Json`
    // }

    if (!data.ativo) {
      camposObrigatorios = `${camposObrigatorios}/Ativo`
    }

    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const handleClickGravar = async (data) => {

    //tratando os campos autocomplete
    data.modulo_sistema_id = data.modulo_sistema_id?.value ? data.modulo_sistema_id.value : '';
    data.categoria_central_relatorio_id = data.categoria_central_relatorio_id?.value ? data.categoria_central_relatorio_id.value : '';
    data.permissao_id = data.permissao_id?.value ? data.permissao_id.value : '';
    data.ativo = data.ativo?.value ? data.ativo.value : '';
    data.filtro_externo = data.filtro_externo?.value ? data.filtro_externo.value : '';

    try {
      validaCampos(data);
      if (data.categoria_central_relatorio_id == -1 || data.categoria_central_relatorio_id == '') {
        //MensagemAlerta(`Categoria é um campo obrigatório`) 
        throw new Error(`Categoria é um campo obrigatório`)
      }
    }
    catch (error) {
      MensagemErro(`${error.message}`);
      return;
    }

    try {
      const dados = { empresa_id: sessaolocal.empresa_id || null };

      //pegando do OBJ do RHF apenas os campos que sao do projeto
      dados.modulo_sistema_id = data.modulo_sistema_id == -1 || data.modulo_sistema_id == '' ? null : data.modulo_sistema_id;
      dados.categoria_central_relatorio_id = data.categoria_central_relatorio_id == -1 || data.categoria_central_relatorio_id == '' ? null : data.categoria_central_relatorio_id;
      dados.permissao_id = data.permissao_id == -1 || data.permissao_id == '' ? null : data.permissao_id;
      dados.nome = data.nome;
      dados.filtro_externo = data.filtro_externo;
      dados.ativo = data.ativo;
      delete data.arquivo_json //= data.arquivo_json;

      let retorno;
      let codigoRelatorio_Personalizado = '';
      MostraTelaEspera('Gravando');
      if (parseInt(Relatorios_PersonalizadosAtual, 10) > 0) {
        // console.log('alterações Relatorio',dados)
        retorno = await api.put(`${rotafechada()}relatorios_personalizados/full/${Relatorios_PersonalizadosAtual}`, dados);
        codigoRelatorio_Personalizado = Relatorios_PersonalizadosAtual;
        setRecarregaComponenteOpcoes(!recarregaComponenteOpcoes);
        setFoco(false)
        MensagemAviso(`Alterado com sucesso! Relatorios_Personalizados: ${codigoRelatorio_Personalizado}`);

      }
      else {
        retorno = await api.post(`${rotafechada()}relatorios_personalizados/full`, dados);
        codigoRelatorio_Personalizado = retorno.data.data.id;
        setFoco(true)
        MensagemAviso(`Gravado com sucesso! Relatorios_Personalizados: ${codigoRelatorio_Personalizado}`);
      }


      //se for um novo produto entao "recarrego" a pagina
      if (codigoRelatorio_Personalizado !== Relatorios_PersonalizadosAtual) {
        setRelatorios_PersonalizadosAtual(codigoRelatorio_Personalizado);
      }
      FechaTelaEspera();
    }
    catch (error) {
      FechaTelaEspera();
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const novoRelatorio_Personalizado = async () => {
    limpaInputs();
    setRelatorios_PersonalizadosAtual('');
  }

  const configuraFiltros = async (impressao) => {
    setFiltros({ ...filtros, configuracao: true, open: true, impressao: impressao });
  }

  const abrirRelatorio_Personalizado = async () => {
    try {
      MostraTelaEspera('');
      let url = `${rotafechada()}relatorios_personalizados?id=${Relatorios_PersonalizadosAtual}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      // console.log('**********',data[0]?.Personalizacao_Relatorios_Personalizados)

      if (!data.length > 0) {
        MensagemErro(`Relatorio_Personalizado ${Relatorios_PersonalizadosAtual} não localizada`);
        return;
      }
      limpaInputs();

      setValue('id', data[0].id);
      setValue(`modulo_sistema_id`, { value: data[0]?.modulo_sistema_id, label: data[0].Modulos_Sistema?.caption ? data[0].Modulos_Sistema.caption : 'Sem módulo' });
      setValue(`categoria_central_relatorio_id`, { value: data[0]?.Personalizacao_Relatorios_Personalizados[0]?.categoria_central_rela, label: data[0]?.Personalizacao_Relatorios_Personalizados[0]?.Categoria_Central_Rela ? data[0].Personalizacao_Relatorios_Personalizados[0].Categoria_Central_Rela : 'Sem categoria' });
      setValue(`permissao_id`, { value: data[0]?.permissao_id, label: data[0]?.Permisso?.nome ? data[0].Permisso.nome : 'Sem permissão' });
      setValue('nome', data[0].nome);
      setValue('arquivo_json', data[0].arquivo_json);
      setValue(`ativo`, { value: data[0].ativo === 'S' ? 'S' : 'N', label: data[0].ativo === 'S' ? 'Sim' : 'Não' });
      setValue(`filtro_externo`, { value: data[0].filtro_externo === 'S' ? 'S' : 'N', label: data[0].filtro_externo === 'S' ? 'Sim' : 'Não' });
      setRecarregaComponenteOpcoes(!recarregaComponenteOpcoes);

      FechaTelaEspera();
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaDatasets = async () => {
    //console.log('buscando cadastros...');

    let url = '';
    let retorno;
    let opcoesModulosSistemaTemp = [];
    let opcoesPermissoesTemp = [];
    let opcoesCategoriaCentralRelatoriosTemp = [];

    try {
      url = `${rotafechada()}modulos_sistema?limit=-1`;
      retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      opcoesModulosSistemaTemp = [{ id: -1, value: -1, label: '<Selecione um Módulo>' }];

      data.map((item) => {
        opcoesModulosSistemaTemp.push({ id: item.id, value: item.id, label: item.caption })
      })

      setOpcoesModulo_Sistema_id(opcoesModulosSistemaTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }

    try {
      url = `${rotafechada()}permissoes?limit=-1`;
      retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      opcoesPermissoesTemp = [{ id: -1, value: -1, label: '<Selecione uma permissão>' }];

      data.map((item) => {
        opcoesPermissoesTemp.push({ id: item.id, value: item.id, label: item.nome })
      })

      setOpcoesPermissao_id(opcoesPermissoesTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }

    try {
      url = `${rotafechada()}categoria_central_relatorios?limit=-1&listaglobal=true&order=nome`;
      retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      opcoesCategoriaCentralRelatoriosTemp = [{ id: -1, value: -1, label: '<Selecione uma Categoria>' }];

      data.map((item) => {
        opcoesCategoriaCentralRelatoriosTemp.push({ id: item.id, value: item.id, label: item.nome })
      })

      setCategoria_Central_Relatorio_id(opcoesCategoriaCentralRelatoriosTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const buscaParametros = async () => {

    try {
      let url = `${rotafechada()}pessoas/parametros`;
      const retorno = await api.get(encodeURI(url));
      const data = retorno.data;

      setParametrosPessoa(data);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickVoltar = () => {
    navigate(`/relatorios-personalizados/`, { replace: true });
    setRelatorios_PersonalizadosAtual('');
    setAba(0);
  }

  const onCloseDialogoOpcoes = (value) => {

    setFiltros({ ...filtros, open: false, })

  }

  useEffect(async () => {
    await buscaDatasets();
    if (parseInt(Relatorios_PersonalizadosAtual, 10) > 0) {
      await abrirRelatorio_Personalizado();
    }
    else {
      await novoRelatorio_Personalizado();
    }
  }, [Relatorios_PersonalizadosAtual]);


  return (
    <>
      <MDBox p={2}>
        <DialogoFiltrosRelatorio
          open={filtros.open || false}
          onClose={onCloseDialogoOpcoes}
          options={filtros.itens || []}
          relatorio_id={Relatorios_PersonalizadosAtual}
          configuracao={filtros.configuracao}
          impressao={filtros.impressao}
        />

        <MDBox width="100%" display="flex" justifyContent="flex-start">
          <MDButton
            variant="gradient"
            color="secondary"
            type="button"
            onClick={handleClickVoltar}
          >Voltar</MDButton>
        </MDBox>
        {
          parseInt(Relatorios_PersonalizadosAtual, 10) > 0 ?
            <MDBox width="100%" display="flex" justifyContent="flex-end">
              <MDButton
                variant="gradient"

                color="info"
                type="button"
                onClick={() => { configuraFiltros(false) }}
              >
                Configurar
              </MDButton>
              <MDButton
                sx={{ ml: 2 }}
                variant="gradient"
                color="info"
                type="button"
                onClick={() => { configuraFiltros(true) }}
              >
                Imprimir
              </MDButton>
            </MDBox>
            : false
        }

        <form onSubmit={handleSubmit(onSubmit)}>
          <Cabecalho
            control={control}
            opcoesModulo_Sistema_id={opcoesModulo_Sistema_id}
            opcoesCategoria_Central_Relatorio_id={opcoesCategoria_Central_Relatorio_id}
            opcoesAtivo={opcoesAtivo}
            opcoesPermissao_id={opcoesPermissao_id}
            getValues={getValues}
          />
          <MDBox width="100%" display="flex" justifyContent="flex-end">
            <MDButton
              variant="gradient"
              color="info"
              type="submit"
            >
              Gravar
            </MDButton>
          </MDBox>
        </form>

      </MDBox>
      {
        parseInt(Relatorios_PersonalizadosAtual, 10) > 0 ?
          <Relatorios_Personalizados_Sql
            control={control}
            getValues={getValues}
            setValue={setValue}
            Relatorios_PersonalizadosAtual={Relatorios_PersonalizadosAtual}
            handleSubmit={handleSubmit}
            recarregaComponenteOpcoes={recarregaComponenteOpcoes}
          />
          : false
      }
      {
        parseInt(Relatorios_PersonalizadosAtual, 10) > 0 ?
          <Relatorios_Personalizados_Filtros
            control={control}
            getValues={getValues}
            setValue={setValue}
            Relatorios_PersonalizadosAtual={Relatorios_PersonalizadosAtual}
            handleSubmit={handleSubmit}
            recarregaComponenteOpcoes={recarregaComponenteOpcoes}
            opcoesTipo={opcoesTipo}
            opcoesPeriodoDataTipo={opcoesPeriodoDataTipo}
            opcoesOperadorJuncao={opcoesOperadorJuncao}
          />
          : false
      }
    </>
  )

}

export default Cadastro;
