import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import { useContext } from 'react';
import MessagesContext from 'hooks/MessagesContext';
import { useEffect } from 'react';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { copiaProfundaObjetos } from 'commons/utils';
import { Box } from '@mui/system';
import MDBox from 'components/MDBox';
import FormField from 'componentes/FormField';
import PesquisaProdutoFiltroSubstituicaoProdutoProjeto from './pesquisaproduto';


export default function FiltroSubstituicaoProdutoProjeto(props) {

  const { open, setOpen, handleOk, produtoAtual, nomeProdutoAtual } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso } = messageContext;
  const [confPesquisaDinamica, setConfPesquisaDinamica] = useState({});
  const [produtoDestino, setProdutoDestino] = useState('');
  const [nomeProdutoDestino, setNomeProdutoDestino] = useState('');

  const handleClickOk = () => {

    if (!handleOk)
      return

    if (!(parseInt(produtoDestino, 10) > 0)) {
      MensagemErro('Escolha um produto destino!');
      return;
    }

    handleOk(produtoDestino);
  }

  const handleClickPesquisaProdutoDestino = () => {
    setConfPesquisaDinamica({ ...confPesquisaDinamica, open: true });
  }

  const handleFuncaoSelecaoPesquisaDinamica = (codigo, datasetPesquisa) => {
    setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false });
    setProdutoDestino(datasetPesquisa.id);
    setNomeProdutoDestino(datasetPesquisa.nome);
  }

  const handleBlurProdutoDestino = async () => {

    if (!(parseInt(produtoDestino, 10) >= 0)) {
      setProdutoDestino('');
      setNomeProdutoDestino('');
      return;
    }

    try {
      let url = `${rotafechada()}produtos?id=${produtoDestino}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      if ((data) && (data?.length > 0)) {
        setProdutoDestino(data[0].id);
        setNomeProdutoDestino(data[0].nome)
      } else {
        setProdutoDestino('');
        setNomeProdutoDestino('');
      }
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  useEffect(() => {

  }, [open])

  return (
    <>
      <PesquisaProdutoFiltroSubstituicaoProdutoProjeto
        open={confPesquisaDinamica.open || false}
        handleClose={
          () => setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false })
        }
        handleClick={handleFuncaoSelecaoPesquisaDinamica}
      />

      <Dialog
        maxWidth="xl"
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>{'Substitui Produto em todos os Projetos'}</DialogTitle>

        <DialogContent dividers>
          <Typography variant='h6'>Produto a ser substituído:</Typography>
          <Typography variant='button'> {produtoAtual}-{nomeProdutoAtual || ''}</Typography>
          <Box display='flex' mt={2}>
            <FormField
              type='text'
              label='Produto Destino'
              rows={1}
              multiline={false}
              value={produtoDestino}
              onChange={(e) => setProdutoDestino(e.target.value)}
              inputProps={{
                onKeyUp: (e) => {
                  e.preventDefault();
                  if (e.key === 'F9') {
                    handleClickPesquisaProdutoDestino();
                  }

                },
                onBlur: (e) => {
                  e.preventDefault();
                  handleBlurProdutoDestino();
                },
              }}
              InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
              sx={{ width: 100 }}
            />
            <MDBox display="flex" justifyContent='flex-start' alignItems='center' >
              <SearchIcon sx={{ cursor: 'pointer' }}
                onClick={() => {
                  handleClickPesquisaProdutoDestino();
                }}
              />
              <Typography variant='caption'>{nomeProdutoDestino}</Typography>
            </MDBox>
          </Box>

        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            Cancelar
          </Button>
          <Button onClick={handleClickOk}>Ok</Button>
        </DialogActions>
      </Dialog>

    </>)
}