import { Autocomplete, Grid, Typography, Box, Divider } from '@mui/material';
import MDBox from 'components/MDBox';
import MDInput from 'components/MDInput';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import PesquisaPessoas from './pesquisapessoas';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import MessagesContext from 'hooks/MessagesContext';
import { formataValor } from 'commons/utils';
import EditIcon from '@mui/icons-material/Edit';
import DialogoDesconto from './dialogoDesconto';
import { Link } from 'react-router-dom';
import FormField from 'componentes/FormField';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled'
import MDTypography from 'components/MDTypography';
import { ptBR } from "date-fns/locale";
import DialogoValidaModulo from 'componentes/ValidaModulo';
import packageJson from '../../../../../../package.json';
import { useNavigate } from "react-router-dom";
import DialogoDetalheParcela from 'pages/financeiro/contas/DetalheParcela';
import DialogoDetalheConta from 'pages/financeiro/contas/DetalheConta';
import DialogoEditarParcela from 'pages/financeiro/contas/EditarParcela';
import DialogoEditarConta from 'pages/financeiro/contas/EditarConta';
import Lancar from 'pages/financeiro/contas/Lancar';

const Cabecalho = (props) => {

  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const navigate = useNavigate();
  const { control, opcoesAtivo, getValues, setValue, opcoesTabelaPreco, recarregaVenda, parametrosVenda, opcoesTiposPessoas } = props;

  const [detalheContaOpen, setDetalheContaOpen] = useState({ visible: false, title: '', id: 0 });
  const [LancarOpen, setLancarOpen] = useState({ visible: false, nome: '' });
  const [detalheParcelaOpen, setDetalheParcelaOpen] = useState({ visible: false, nome: '' });
  const [editarParcelaOpen, setEditarParcelaOpen] = useState({ visible: false, nome: '' });
  const [editarContaOpen, setEditarContaOpen] = useState({ visible: false, nome: '' });
  const [codigoCarregado, setCodigoCarregado] = useState(0);
  const [recarregaTabela, setRecarregaTabela] = useState(false);

  const [confPesquisaDinamica, setConfPesquisaDinamica] = useState({});
  const [dialogoDesconto, setDialogoDesconto] = useState({ open: false, valor_atual: '0' });
  const [campoTipoPessoaIdFiltroPesquisa, setCampoTipoPessoaIdFiltroPesquisa] = useState(opcoesTiposPessoas[0] ? { ...opcoesTiposPessoas[0] } : { id: -1, label: 'Todos' });
  const [openMensagemModuloAdicional, setOpenMensagemModuloAdicional] = useState(false);

  const getNomeVendedor = useWatch({
    control,
    name: "nome_vendedor_id",
  });

  const getVendedorId = useWatch({
    control,
    name: "vendedor_id",
  });

  const getNomeCliente = useWatch({
    control,
    name: "nome_cliente_id",
  });

  const getClienteId = useWatch({
    control,
    name: "cliente_id",
  });

  const getData_venda = useWatch({
    control,
    name: "data_venda",
  });

  const getValor_total = useWatch({
    control,
    name: "valor_total",
  });

  const getValor_desconto = useWatch({
    control,
    name: "valor_desconto",
  });

  const getValor_final = useWatch({
    control,
    name: "valor_final",
  });

  const getConta_id = useWatch({
    control,
    name: "conta_id",
  });

  const getCobranca_barra = useWatch({
    control,
    name: "cobranca_barra",
  });

  const getId = useWatch({
    control,
    name: "id",
  });

  const handleClickPesquisaPessoa = async (campo) => {

    let tipo_pessoa_id_filtro = '';
    if (campo === 'vendedor_id') {
      tipo_pessoa_id_filtro = parametrosVenda.tipo_pessoa_id_vendedor || 0;
    }
    else {
      tipo_pessoa_id_filtro = parametrosVenda.tipo_pessoa_id_cliente || 0;
    }

    opcoesTiposPessoas.map((item) => {
      if (parseInt(item.id, 10) === parseInt(tipo_pessoa_id_filtro, 10)) {
        setCampoTipoPessoaIdFiltroPesquisa({ ...item });
      }
    })


    setConfPesquisaDinamica({ ...confPesquisaDinamica, open: true, campo });
  }

  const handleBlurPessoa = async (campo) => {
    if (!(parseInt(getValues(campo), 10) >= 0)) {
      setValue(`nome_${campo}`, '');
      return;
    }
    try {
      let url = `${rotafechada()}pessoas?id=${getValues(campo)}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      if ((data) && (data?.length > 0)) {
        setValue(campo, data[0].id);
        setValue(`nome_${campo}`, data[0].razao_social || data[0].fantasia || '...');
      } else {
        setValue(campo, '');
        setValue(`nome_${campo}`, '');
      }
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleFuncaoSelecaoPesquisaDinamica = (codigo, datasetPesquisa) => {
    /* Essa funcao é chamada pelo componente de pesquisa dinamica quando um registro é escolhido
    ele retorna os campos que foram configurados*/
    setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false });
    setValue(confPesquisaDinamica.campo, datasetPesquisa.id);
    setValue(`nome_${confPesquisaDinamica.campo}`, datasetPesquisa.razao_social || datasetPesquisa.fantasia || '');
  }

  const handleClickDesconto = () => {
    if (!(parseInt(getId, 10) > 0)) {
      return;
    }
    const valorAtual = getValues('valor_desconto');
    setDialogoDesconto({ open: true, valorAtual });
  }

  const aplicaDesconto = async (valor) => {
    setDialogoDesconto({ ...dialogoDesconto, open: false })
    const valor_desconto = parseFloat(`${valor}`.replace(',', '.'));
    if (!(valor_desconto >= 0)) {
      MensagemErro('Valor Inválido!');
      return;
    }
    
    if (valor_desconto > getValor_final) {
      MensagemErro('Valor do desconto maior que o valor final!');
      return;
    }

    try {
      await api.put(`${rotafechada()}vendas/${getId}`, { valor_desconto });
      setValue('valor_desconto', valor_desconto);
      MensagemAviso(`Desconto Aplicado com sucesso!`);
      recarregaVenda();
    }
    catch (error) {
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
      recarregaVenda();
    }
  }

  const handleClickDetalheConta = (id) => {

    if (id !== 0) {
      setDetalheContaOpen({ ...detalheContaOpen, visible: true, title: 'Detalhes da Conta ', id: id });
    }

  }

  const handleClickEditarParcela = (id) => {
    setCodigoCarregado(id);
    setEditarParcelaOpen({ ...editarParcelaOpen, visible: true, nome: 'Editar parcela ' });
  }

  // const handleClickExcluir = async (id, situacao) => {

  //   if (!(await validaRotaPermissao('excluir') === true)) {
  //     return;
  //   }

  //   setDialogoOpen({ ...dialogoOpen, visible: true, id, situacao });//abre dialogo de exclusão
  // }

  const handleClickInformarRecebimento = (id) => {
    setCodigoCarregado(id);
    // abrirRegistro(id);
    setLancarOpen({ ...LancarOpen, visible: true, nome: 'Informar recebimento' });
  }

  const handleClickDetalheParcela = (id) => {
    setCodigoCarregado(id);
    setDetalheParcelaOpen({ ...detalheParcelaOpen, visible: true, nome: 'Detalhes da parcela ' });

  }

  const handleClickEditarConta = (id) => {
    setCodigoCarregado(id);
    setEditarContaOpen({ ...editarContaOpen, visible: true, nome: 'Editar conta ' });
  }

  const validaModulo = async () => {

    MostraTelaEspera('Validando acesso ao módulo');
    try {
      let url = `${rotafechada()}assinatura_modulos/validar?nome=${'FINANCEIRO'}`;
      await api.get(url);
      handleClickDetalheConta(getConta_id)
    }
    catch (error) {
      setOpenMensagemModuloAdicional(true)
    }
    FechaTelaEspera();
  }

  return (
    <>
      <DialogoValidaModulo
        open={openMensagemModuloAdicional}
        title={'Módulo adicional'}
        message={'Módulo Financeiro não contratado, para mais informações entre em contato com nossa equipe de suporte!'}
        handleCancel={() => {
          setOpenMensagemModuloAdicional(false);
        }}
        handleConfirm={() => {
          setOpenMensagemModuloAdicional(false);
          window.open(packageJson?.urlsuporte.replace('text', 'text=Tenho interesse em contratar o módulo Financeiro para o VidrosWeb📌 '))
        }
        }
      />

      {detalheContaOpen.visible === true &&

        <DialogoDetalheConta // Ver detalhes da conta 
          open={detalheContaOpen.visible}
          title={detalheContaOpen.nome}
          message={'Mensagem'}
          tipo={'R'}
          conta_id={detalheContaOpen.id}
          setCodigoAbrir={setCodigoCarregado}
          codigoAbrir={codigoCarregado}
          handleCancel={() => {
            setDetalheContaOpen({ ...detalheContaOpen, visible: false, nome: '' })
          }}
          // handleClickExcluir={handleClickExcluir}
          handleClickInformarRecebimento={handleClickInformarRecebimento}
          handleClickDetalheParcela={handleClickDetalheParcela}
          handleClickEditarParcela={handleClickEditarParcela}
          handleClickEditarConta={handleClickEditarConta}
        />
      }
      {
        LancarOpen.visible === true &&
        <Lancar //Informar recebimento
          open={LancarOpen.visible}
          title={LancarOpen.nome}
          message={'Mensagem'}
          tipo={'R'}
          setCodigoAbrir={setCodigoCarregado}
          codigoAbrir={codigoCarregado}
          setRecarregaTabela={setRecarregaTabela}
          recarregaTabela={recarregaTabela}
          handleCancel={() => {
            setLancarOpen({ ...LancarOpen, visible: false, nome: '' })
            setDetalheContaOpen({ ...detalheContaOpen, visible: false })
          }}
        />
      }
      {
        detalheParcelaOpen.visible === true &&

        <DialogoDetalheParcela //Ver detalhes da parcela
          open={detalheParcelaOpen.visible}
          title={detalheParcelaOpen.nome}
          message={'Mensagem'}
          tipo={'R'}
          setRecarregaTabela={setRecarregaTabela}
          recarregaTabela={recarregaTabela}
          setCodigoAbrir={setCodigoCarregado}
          codigoAbrir={codigoCarregado}
          handleCancel={() => {
            setDetalheParcelaOpen({ ...detalheParcelaOpen, visible: false, nome: '' })
            setDetalheContaOpen({ ...detalheContaOpen, visible: false })
          }}
        />
      }
      {
        editarParcelaOpen.visible === true &&

        <DialogoEditarParcela //Editar parcela
          open={editarParcelaOpen.visible}
          title={editarParcelaOpen.nome}
          message={'Mensagem'}
          tipo={'R'}
          setCodigoAbrir={setCodigoCarregado}
          codigoAbrir={codigoCarregado}
          handleCancel={() => {
            setEditarParcelaOpen({ ...editarParcelaOpen, visible: false, nome: '' })
            setDetalheContaOpen({ ...detalheContaOpen, visible: false })
          }}
        />
      }
      {
        editarContaOpen.visible === true &&

        <DialogoEditarConta //Editar conta
          open={editarContaOpen.visible}
          title={editarContaOpen.nome}
          message={'Mensagem'}
          tipo={'R'}
          setCodigoAbrir={setCodigoCarregado}
          codigoAbrir={codigoCarregado}
          handleCancel={() => {
            setEditarContaOpen({ ...editarContaOpen, visible: false, nome: '' })
            setDetalheContaOpen({ ...detalheContaOpen, visible: false })
          }}
        />
      }

      <PesquisaPessoas
        open={confPesquisaDinamica.open || false}
        handleClose={
          () => setConfPesquisaDinamica({ ...confPesquisaDinamica, open: false })
        }
        handleClick={handleFuncaoSelecaoPesquisaDinamica}
        opcoesTiposPessoas={opcoesTiposPessoas}
        campoTipoPessoaIdFiltroPesquisa={campoTipoPessoaIdFiltroPesquisa}
        setCampoTipoPessoaIdFiltroPesquisa={setCampoTipoPessoaIdFiltroPesquisa}
      />

      <DialogoDesconto
        open={dialogoDesconto.open}
        handleClose={() => setDialogoDesconto({ open: false })}
        valorAtual={dialogoDesconto.valorAtual}
        valorTotal={getValor_total}
        aplicaDesconto={aplicaDesconto}
      />

      <MDBox p={2}>

        <MDBox mb={3}>
          <Typography variant='h6' >Orçamento/Venda: {`${getValues('id') || ''}-${getValues('nome') || ''}`}</Typography>
        </MDBox>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>{/*Box dos campos */}
            <Grid container spacing={2}>

              <Grid item xs={12} sm={2}>{/*id*/}
                <Controller
                  name={'id'}
                  control={props.control}
                  defaultValue=''
                  render={({ field: { ref, ...props } }) =>
                    <FormField
                      autoComplete={false}
                      type='text'
                      label='ID'
                      rows={1}
                      multiline={false}
                      required={false}
                      disabled={true}
                      inputProps={{
                        disabled: true
                      }}
                      inputRef={ref}
                      {...props}
                    />}
                />
              </Grid>

              <Grid item xs={12} sm={3}>{/*Ativo*/}
                <Controller
                  name='ativo'
                  control={control}
                  defaultValue={{ value: 'N' }}
                  render={({ field: { onChange, ...props } }) => (
                    <AutoCompleteStyled
                      options={opcoesAtivo}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      renderInput={(params) => <FormField {...params} label='Ativo' />}
                      sx={{ color: 'red' }}
                      onChange={(_, data) => {
                        return onChange(data);
                      }}
                      disabled={getConta_id > 0 ? true : false}
                      {...props}
                    />
                  )}

                />
              </Grid>

              <Grid item xs={12} sm={4} p={0}>{/*tabela_preco_id*/}
                <Controller
                  name='tabela_preco_id'
                  control={control}
                  defaultValue={{ value: -1 }}
                  render={({ field: { onChange, ...props } }) => (
                    <AutoCompleteStyled
                      options={opcoesTabelaPreco}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      renderInput={(params) => <FormField {...params} label='Tabela de Preço' />}
                      onChange={(_, data) => {
                        return onChange(data);
                      }}
                      disabled={getConta_id > 0 ?
                        true
                        :
                        (
                          opcoesTabelaPreco.length > 2 ? //o primeiro é o "-1"
                            false :
                            true
                        )
                      }

                      {...props}

                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12}>{/*linha do vendedor*/}
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={2}>{/*vendedor_id*/}
                    <Controller
                      name={'vendedor_id'}
                      control={control}
                      render={({ field: { ref, ...props } }) =>
                        <FormField
                          type='text'
                          label='Vendedor'
                          rows={1}
                          multiline={false}
                          required={true}
                          disabled={getConta_id > 0 ? true : false}
                          {...props}
                          inputProps={{
                            onKeyUp: (e) => {
                              e.preventDefault();
                              if (e.key === 'F9') {
                                handleClickPesquisaPessoa('vendedor_id');
                              }

                            },
                            onBlur: (e) => {
                              e.preventDefault();
                              handleBlurPessoa('vendedor_id');
                            },
                          }}
                          inputRef={ref}
                          InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                        />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>{/*botao do vendedor_id*/}
                    <MDBox width="100%" height='100%' display="flex" justifyContent='center' alignItems='center' >
                      <SearchIcon sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleClickPesquisaPessoa('vendedor_id');
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={4}>{/*label do vendedor_id*/}
                    <MDBox width="100%" height='100%' display="flex" alignSelf='flex-start' alignItems='center' >
                      <Typography variant='caption'>
                        {/* {getNomeVendedor} */}
                        <Link to={`/pessoas/pessoa/${getVendedorId}`} style={{ textDecoration: "none", color: 'black' }}>{getNomeVendedor}</Link>
                      </Typography>

                    </MDBox>
                  </Grid>


                </Grid>
              </Grid>

              <Grid item xs={12} sm={12}>{/*linha do cliente*/}
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={2}>{/*cliente_id*/}
                    <Controller
                      name={'cliente_id'}
                      control={control}
                      render={({ field: { ref, ...props } }) =>
                        <FormField
                          type='text'
                          label='Cliente'
                          rows={1}
                          multiline={false}
                          required={true}
                          disabled={getConta_id > 0 ? true : false}
                          {...props}
                          inputProps={{
                            onKeyUp: (e) => {
                              e.preventDefault();
                              if (e.key === 'F9') {
                                handleClickPesquisaPessoa('cliente_id');
                              }

                            },
                            onBlur: (e) => {
                              e.preventDefault();
                              handleBlurPessoa('cliente_id');
                            },
                          }}
                          inputRef={ref}
                          InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                        />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={1}>{/*botao do cliente_id*/}
                    <MDBox width="100%" height='100%' display="flex" justifyContent='center' alignItems='center' >
                      <SearchIcon sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleClickPesquisaPessoa('cliente_id');
                        }}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} sm={4}>{/*label do cliente_id*/}
                    <MDBox width="100%" height='100%' display="flex" alignSelf='flex-start' alignItems='center' >
                      <Typography variant='caption'>
                        {/* {getNomeCliente} */}
                        <Link to={`/pessoas/pessoa/${getClienteId}`} style={{ textDecoration: "none", color: 'black' }}>{getNomeCliente}</Link>
                      </Typography>
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={12}>{/*nome*/}
                <Controller
                  name={'nome'}
                  defaultValue=''
                  control={props.control}
                  render={({ field: { ref, ...props } }) =>
                    <FormField
                      id={'nome'}
                      autoComplete={false}
                      type='text'
                      label='Nome/Obra'
                      rows={1}
                      multiline={false}
                      required={false}
                      disabled={getConta_id > 0 ? true : false}
                      inputRef={ref}
                      {...props}
                    />}
                />
              </Grid>

              <Grid item xs={12} sm={4} >{/*data_orcamento*/}
                <Controller
                  name={'data_orcamento'}
                  control={control}

                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                      <DesktopDatePicker
                        label='Data orçamento'
                        inputFormat="dd/MM/yyyy"
                        value={value}
                        onChange={onChange}
                        renderInput={(params) => <FormField {...params} />}
                        disabled={getConta_id > 0 ? true : false}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} >{/*prazo_dias*/}
                <Controller
                  name={'prazo_dias'}
                  defaultValue=''
                  control={props.control}
                  render={({ field: { ref, ...props } }) =>
                    <FormField
                      autoComplete={false}
                      type='number'
                      label='Prazo (dias)'
                      rows={1}
                      multiline={false}
                      required={false}
                      disabled={getConta_id > 0 ? true : false}
                      inputRef={ref}
                      {...props}
                    />}
                />
              </Grid>

              <Grid item xs={12} sm={12}>{/*observacao*/}
                <Controller
                  name={'observacao'}
                  defaultValue=''
                  control={props.control}
                  render={({ field: { ref, ...props } }) =>
                    <FormField
                      autoComplete={false}
                      type='text'
                      label='Observação'
                      rows={4}
                      multiline={true}
                      required={false}
                      disabled={getConta_id > 0 ? true : false}
                      inputRef={ref}
                      {...props}
                    />}
                />
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12} sm={4} >{/*Box dos Totais*/}
            <MDBox width="100%" variant="title" borderRadius="lg" coloredShadow="info">
              <Box
                sx={{
                  '& > :not(style)': { width: '100%' },
                  border: "solid",
                  borderWidth: '1px',
                  borderColor: 'info',
                  //color: "info",
                  borderRadius: '10px',

                }}

              >
                <Grid container spacing={1} justifyContent="flex-start" mt={0.1}>
                  <Grid item xs={12} sm={6}>
                    <Typography ml={2} variant='subtitle2' fontWeight="bold" >Conta:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography onClick={() => { validaModulo() }} color={'info'} sx={{ cursor: "pointer" }} variant='body2' >{getConta_id}</MDTypography>
                    {/* <MDTypography sx={{ cursor: "pointer" }} variant="body2" textTransform="capitalize">{`Transferência - ${item.transferencia}`}</MDTypography> */}
                  </Grid>
                </Grid>

                <Grid container spacing={1} justifyContent="flex-start">
                  <Grid item xs={12} sm={6}>
                    <Typography ml={2} variant='subtitle2' fontWeight="bold">Data da Venda:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant='subtitle2' >{getData_venda}</Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={1} justifyContent="flex-start">
                  <Grid item xs={12} sm={6}>
                    <Typography ml={2} variant='subtitle2' fontWeight="bold" >Cobrança por Barra:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDTypography  variant='body2' >{getCobranca_barra}</MDTypography>
                  </Grid>
                </Grid>

                <Divider />

                <Grid container spacing={1} justifyContent="flex-start">
                  <Grid item xs={12} sm={6}>
                    <Typography ml={2} variant='subtitle2' fontWeight="bold">Valor Total:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant='subtitle2' >{formataValor(getValor_total)}</Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={1} justifyContent="flex-start">
                  <Grid item xs={12} sm={6}>
                    <Typography ml={2} variant='subtitle2' fontWeight="bold">Desconto:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant='subtitle2' >
                      {formataValor(getValor_desconto)}
                      {
                        ((parseInt(getId, 10) > 0) && (!parseInt(getConta_id, 10) > 0)) && <EditIcon color='success' style={{ marginLeft: 4, cursor: 'pointer' }} onClick={handleClickDesconto} />
                      }
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={0} justifyContent="flex-start">
                  <Grid item xs={12} sm={6}>
                    <Typography ml={2} variant='subtitle2' fontWeight="bold">Valor Final:</Typography>

                  </Grid>
                  <Grid item xs={12} sm={6} mb={1}>
                    <Typography fontWeight="bold" variant='subtitle2'>{formataValor(getValor_final)}</Typography>

                  </Grid>
                </Grid>



              </Box>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </>
  )
}

export default Cabecalho;

