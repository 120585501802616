import React, { useState, useEffect, useContext } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MessagesContext from "hooks/MessagesContext";
import { Grid, Icon, InputAdornment, Tooltip, Typography, Checkbox, Divider, Box, Input } from "@mui/material";
import Switch from '@mui/material/Switch';
import FormField from "componentes/FormField";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";
import AuthContext from 'hooks/AuthContext';
import { formataFloat, formatDate, formataValor, incMonth, incDay, dateIsValid } from "commons/utils";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import HelpIcon from '@mui/icons-material/Help';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import { ptBR } from "date-fns/locale";
import CurrencyInput from "componentes/MascaraMonetaria";
import CancelIcon from '@mui/icons-material/Cancel';
import { copiaProfundaObjetos } from "commons/utils";



function DadosRateio(props) {

    const { optionCategoria, optionCentroCusto, dadosRateio, setDadosRateio, valor, porcentagem } = props;
    let subTotalRateado = 0;
    let subTotalRateadoP = 0;

    return (
        <>
            <Grid container spacing={2}>

                < Grid item xs={12} sm={12} >
                    <Typography variant='title' fontWeight="bold">Informar categoria e centro de custo</Typography>
                    <Divider
                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                        style={{
                            marginTop: 20,
                            border: "none",
                            height: 2,
                            margin: 0,
                        }}
                    />
                </Grid >
                {dadosRateio.map((item, index) => {
                    return (
                        <>
                            <Grid item xs={12} sm={4}>{/*CATEGORIA*/}
                                <AutoCompleteStyled
                                    options={optionCategoria}
                                    disableClearable //Retira o X (clear)
                                    getOptionLabel={(option) => option.label || ""}
                                    getOptionDisabled={(option) => option.mae === true}
                                    renderInput={(params) => <FormField required {...params} label="Categoria:" />}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            {option.mae === true ?
                                                <Typography variant="subtitle" style={{ fontWeight: '900', color: 'black' }}>{option.label}</Typography>
                                                :
                                                <Typography variant="subtitle" style={{ font: 'inherit' }}>{`   ${option.label}`}</Typography>
                                            }
                                        </Box>
                                    )}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    value={item.categoria}
                                    onChange={(event, newValue) => {
                                        let tempCategoria = [...dadosRateio]
                                        tempCategoria[index].categoria = newValue
                                        setDadosRateio(tempCategoria);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={1.5}> {/* VALOR */}
                                <FormField
                                    disabled={dadosRateio.length == 1 ? true : false}
                                    required={true}
                                    type="number"
                                    label="Valor total (R$)"
                                    value={item.valorTotal}
                                    onChange={(e) => {
                                        let tempValor = [...dadosRateio]
                                        tempValor[index].valorTotal = e.target.value
                                        tempValor[index].porcentagem = parseFloat((e.target.value * 100) / valor).toFixed(2)
                                        setDadosRateio(tempValor);
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment sx={{ color: 'green' }} position="end"><AttachMoneyIcon /></InputAdornment>,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={1.5}> {/* PORCENTAGEM */}
                                <FormField
                                    disabled={dadosRateio.length == 1 ? true : false}
                                    required={true}
                                    type="number"
                                    label="Porcentagem"
                                    value={item.porcentagem}
                                    onChange={(e) => {
                                        let tempPorcentagem = [...dadosRateio]
                                        tempPorcentagem[index].porcentagem = e.target.value
                                        tempPorcentagem[index].valorTotal = parseFloat((e.target.value * valor) / 100).toFixed(2)

                                        setDadosRateio(tempPorcentagem)
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment sx={{ color: 'blue' }} position="end"><PercentIcon /></InputAdornment>,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3}> {/*CENTRO_CUSTO*/}
                                <AutoCompleteStyled
                                    options={optionCentroCusto}
                                    getOptionLabel={(option) => option.label || ""}
                                    renderInput={(params) => <FormField {...params} label="Centro de custo" />}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    value={item.centroCusto}
                                    onChange={(event, newValue) => {
                                        let tempCentroCusto = [...dadosRateio]
                                        tempCentroCusto[index].centroCusto = newValue
                                        setDadosRateio(tempCentroCusto);
                                    }}
                                    style={{ cursor: "not-allowed" }}
                                />
                            </Grid>
                            {
                                dadosRateio.length > 2 &&
                                <>
                                    <Grid item xs={12} sm={1}> {/*REMOVER*/}
                                        <Tooltip title="Remover">
                                            <MDButton
                                                size="medium"
                                                type="button"
                                                iconOnly
                                                onClick={() => {
                                                    let tempRemover = [...dadosRateio];
                                                    tempRemover.splice(index, 1)

                                                    setDadosRateio(tempRemover)
                                                }}
                                            >
                                                <Icon>close</Icon>
                                            </MDButton>

                                        </Tooltip>
                                    </Grid>
                                </>
                            }
                        </>
                    )
                })}
            </Grid>
            <Grid container mt={2}> {/*ADICIONAR CATEGORIA*/}
                <MDBox width="100%" display="flex" justifyContent="flex-start">
                    <MDButton
                        variant="outlined"
                        color="info"
                        type="button"
                        onClick={() => {
                            let vetorTemp = [...dadosRateio];

                            vetorTemp.push({
                                categoria: { value: "", label: "" },
                                valorTotal: parseFloat(0).toFixed(2),
                                porcentagem: parseFloat(0).toFixed(2),
                                centroCusto: { value: "", label: "" },
                            },)
                            setDadosRateio(vetorTemp)
                        }}
                        size='small'
                    > + Adicionar categoria
                    </MDButton>
                </MDBox>
            </Grid>
            <Divider
                sx={{ bgcolor: (theme) => theme.palette.divider }}
                style={{
                    marginTop: 20,
                    border: "none",
                    height: 2,
                    margin: 0,
                }}
            />

            <Grid container mt={2}> {/*TOTAL RATEIO*/}
                <MDBox width="100%" display="flex" justifyContent="flex-end">
                    {dadosRateio.map((item, index) => {
                        subTotalRateado = parseFloat(subTotalRateado) + parseFloat(item.valorTotal)
                        subTotalRateadoP = parseFloat(subTotalRateadoP) + parseFloat(item.porcentagem)
                    })}

                    <Grid item xs={12} sm={2} > {/*RESTANTE DO RATEIO %% E R$*/}
                        <MDBox width='100%' display='flex' alignItems='center' flexDirection='column'>
                            <MDTypography variant='subtitle2'>
                                {'Restante do rateio  '}
                                <Tooltip
                                    title="É o restante que não foi distribuído a partir do valor total desta 
                            conta. Para completar o rateio, este restante precisa estar zerado."
                                    placement="top"
                                >
                                    <HelpIcon color="info" />
                                </Tooltip>
                            </MDTypography>
                            <MDTypography fontWeight="bold" variant='body1'>
                                {`${(100 - subTotalRateadoP) < 0 ? 0 : parseFloat(100 - subTotalRateadoP).toFixed(2)} % | ${formataValor(parseFloat(valor).toFixed(2) - parseFloat(subTotalRateado).toFixed(2))}`}
                            </MDTypography>
                        </MDBox>
                    </Grid>

                    <Grid item xs={12} sm={2} > {/*TOTAL RATEADO*/}
                        <MDBox width='100%' display='flex' alignItems='center' flexDirection='column'>
                            <MDTypography variant='subtitle2'>
                                {'Total rateado'}
                            </MDTypography>

                            <MDTypography fontWeight="bold" variant='body1'>
                                {formataValor(subTotalRateado)}
                            </MDTypography>
                        </MDBox>
                    </Grid>


                </MDBox>
            </Grid>
        </>

    )
}

function DadosParcela(props) {
    //1x ou mais parcelas
    const { dadosParcelas, setDadosParcelas, optionFormaPagamento, optionBanco, parcelamento,
        nome, valor, banco, formaPagamento, vencimento, intervaloParcelas, agendado,
        tipo, valorOnBlur, competenciaCheck } = props;
    const [dadosParcelasTemp, setDadosParcelasTemp] = useState([]);


    const CriaParcelas = async () => {
        let tempVetor = []
        let dataHoje = vencimento;
        let todosMenosUmValor = 0
        let todosMenosUmPercentual = 0


        for (let i = 0; i < parcelamento.value; i++) {
            if (i == 0) {
                tempVetor.push({
                    vencimento: vencimento,
                    data_competencia: vencimento,
                    valor: parseFloat((valor / parcelamento.value).toFixed(2)),
                    percentual: parseFloat((100 / parcelamento.value).toFixed(2)),
                    formaPagamento: formaPagamento,
                    banco: banco,
                    descricao: `${nome} ${i + 1}/${parcelamento.value}`,
                    agendado: agendado,
                    observacao: ''
                },)
                todosMenosUmValor = todosMenosUmValor + parseFloat((valor / parcelamento.value).toFixed(2))
                todosMenosUmPercentual = todosMenosUmPercentual + parseFloat((100 / parcelamento.value).toFixed(2))
            }
            else
                if (i == (parcelamento.value - 1)) {
                    dataHoje = (incDay(dataHoje, intervaloParcelas))
                    tempVetor.push({
                        vencimento: dataHoje,
                        data_competencia: dataHoje,
                        valor: parseFloat((valor - todosMenosUmValor).toFixed(2)),
                        percentual: parseFloat((100 - todosMenosUmPercentual).toFixed(2)),
                        formaPagamento: formaPagamento,
                        banco: banco,
                        descricao: `${nome} ${i + 1}/${parcelamento.value}`,
                        agendado: agendado,
                        observacao: ''

                    },)
                }
                else {
                    dataHoje = (incDay(dataHoje, intervaloParcelas))
                    tempVetor.push({
                        vencimento: dataHoje,
                        data_competencia: dataHoje,
                        valor: parseFloat((valor / parcelamento.value).toFixed(2)),
                        percentual: parseFloat((100 / parcelamento.value).toFixed(2)),
                        formaPagamento: formaPagamento,
                        banco: banco,
                        descricao: `${nome} ${i + 1}/${parcelamento.value}`,
                        agendado: agendado,
                        observacao: ''
                    },)
                    todosMenosUmValor = todosMenosUmValor + parseFloat((valor / parcelamento.value).toFixed(2))

                    todosMenosUmPercentual = todosMenosUmPercentual + parseFloat((100 / parcelamento.value).toFixed(2))
                }
        }
        //console.log('criaParcelas',tempVetor)
        setDadosParcelas(tempVetor)

    }

    const AlteraParcelasFormaPagamento = () => {
        if (formaPagamento && dadosParcelas.length > 1) {
            let tempVetor = [...dadosParcelas]

            for (let i = 0; i < dadosParcelas.length; i++) {
                tempVetor[i].formaPagamento = formaPagamento
            }
            setDadosParcelas(tempVetor)
        }

    }

    const AlteraParcelasBanco = async () => {
        if (banco) {
            let tempVetor = [...dadosParcelas]

            for (let i = 0; i < parcelamento.value; i++) {
                tempVetor[i].banco = banco
            }
            setDadosParcelas(tempVetor)
        }

    }

    const AlteraParcelasNome = async () => {
        if (nome) {
            let tempVetor = [...dadosParcelas]

            for (let i = 0; i < parcelamento.value; i++) {
                tempVetor[i].descricao = `${nome} ${i + 1}/${parcelamento.value}`
            }
            setDadosParcelas(tempVetor)
        }

    }

    const AlteraParcelasAgendado = async () => {
        if (agendado) {
            let tempVetor = [...dadosParcelas]

            for (let i = 0; i < parcelamento.value; i++) {
                tempVetor[i].agendado = agendado
            }
            //console.log('AlteraParcelasAgendado', tempVetor)
            setDadosParcelas(tempVetor)
        }

    }

    const AlteraParcelasIntervalo = async () => {
        let tempVetor = [...dadosParcelas]

        for (let i = 0; i < parcelamento.value; i++) {
            let tempData = tempVetor[i].vencimento
            tempVetor[i].vencimento = (incDay(tempData, intervaloParcelas))
        }
        setDadosParcelas(tempVetor)

    }

    const AlteraParcelasValor = async () => {
        if (valor) {
            let tempVetor = [...dadosParcelas]

            for (let i = 0; i < parcelamento.value; i++) {
                tempVetor[i].valor = parseFloat((valor / parcelamento.value).toFixed(2))
                tempVetor[i].percentual = parseFloat((100 / parcelamento.value).toFixed(2))

            }
            //console.log('AlteraParcelasValor', tempVetor)
            setDadosParcelas(tempVetor)
            handleBlurValor(tempVetor[0].valor, 0)
            //console.log('AlteraParcelasValor2', tempVetor)
        }

    }

    const handleBlurValor = async (campoAlterado, posicaoAlterada) => {
        let vetorParcelasTemp = [...dadosParcelas]
        let totalAteAqui = 0
        let totalAteAquiPercentual = 0

        let percentualCampoAlterado = (campoAlterado * 100) / valor
        vetorParcelasTemp[posicaoAlterada].percentual = parseFloat((percentualCampoAlterado).toFixed(2))

        for (let i = posicaoAlterada; i >= 0; i--) {
            totalAteAqui = parseFloat(totalAteAqui) + parseFloat(vetorParcelasTemp[i].valor)
            totalAteAquiPercentual = parseFloat(totalAteAquiPercentual) + parseFloat(vetorParcelasTemp[i].percentual)
        }

        let valorRestanteParcelas = parseFloat((valor - totalAteAqui) / (dadosParcelas.length - (posicaoAlterada + 1)))
        let valorRestanteParcelasPercentual = parseFloat((100 - totalAteAquiPercentual) / (dadosParcelas.length - (posicaoAlterada + 1)))


        let parcelasMenosUm = parseFloat(totalAteAqui)
        let parcelasMenosUmPercentual = parseFloat(totalAteAquiPercentual)

        for (let i = posicaoAlterada + 1; i <= (dadosParcelas.length - 2); i++) {
            vetorParcelasTemp[i].valor = parseFloat(valorRestanteParcelas.toFixed(2))//.toFixed(2)
            vetorParcelasTemp[i].percentual = parseFloat(valorRestanteParcelasPercentual.toFixed(2))//.toFixed(2)

            parcelasMenosUm = parseFloat(parcelasMenosUm.toFixed(2)) + parseFloat(valorRestanteParcelas.toFixed(2))
            parcelasMenosUmPercentual = parseFloat(parcelasMenosUmPercentual.toFixed(2)) + parseFloat(valorRestanteParcelasPercentual.toFixed(2))
        }

        vetorParcelasTemp[dadosParcelas.length - 1].valor = parseFloat((valor - parcelasMenosUm).toFixed(2))
        vetorParcelasTemp[dadosParcelas.length - 1].percentual = parseFloat((100 - parcelasMenosUmPercentual).toFixed(2))
        //console.log("vetorParcelasTemp antes do state e depois de passar pelo handle blur valor", vetorParcelasTemp)
        setDadosParcelasTemp(copiaProfundaObjetos(vetorParcelasTemp));
        setDadosParcelas(vetorParcelasTemp);

    }

    const handleBlurPercentual = async (campoAlterado, posicaoAlterada) => {
        let vetorParcelasTemp = [...dadosParcelas]
        let totalAteAqui = 0
        let totalAteAquiValor = 0

        let valorCampoAlterado = (campoAlterado * valor) / 100
        vetorParcelasTemp[posicaoAlterada].valor = parseFloat((valorCampoAlterado).toFixed(2))

        for (let i = posicaoAlterada; i >= 0; i--) {
            totalAteAqui = parseFloat(totalAteAqui) + parseFloat(vetorParcelasTemp[i].percentual)
            totalAteAquiValor = parseFloat(totalAteAquiValor) + parseFloat(vetorParcelasTemp[i].valor)
        }

        let valorRestanteParcelas = parseFloat((100 - totalAteAqui) / (dadosParcelas.length - (posicaoAlterada + 1)))
        let valorRestanteParcelasValor = parseFloat((valor - totalAteAquiValor) / (dadosParcelas.length - (posicaoAlterada + 1)))


        let parcelasMenosUm = parseFloat(totalAteAqui)
        let parcelasMenosUmValor = parseFloat(totalAteAquiValor)

        for (let i = posicaoAlterada + 1; i <= (dadosParcelas.length - 2); i++) {
            vetorParcelasTemp[i].percentual = parseFloat(valorRestanteParcelas.toFixed(2))//.toFixed(2)
            vetorParcelasTemp[i].valor = parseFloat(valorRestanteParcelasValor.toFixed(2))//.toFixed(2)

            parcelasMenosUm = parseFloat(parcelasMenosUm.toFixed(2)) + parseFloat(valorRestanteParcelas.toFixed(2))
            parcelasMenosUmValor = parseFloat(parcelasMenosUmValor.toFixed(2)) + parseFloat(valorRestanteParcelasValor.toFixed(2))
        }

        vetorParcelasTemp[dadosParcelas.length - 1].percentual = parseFloat((100 - parcelasMenosUm).toFixed(2))
        vetorParcelasTemp[dadosParcelas.length - 1].valor = parseFloat((valor - parcelasMenosUmValor).toFixed(2))
        //console.log("vetorParcelasTemp antes do state e depois de passar pelo handle blur percentual", vetorParcelasTemp)
        setDadosParcelas(vetorParcelasTemp);
        //setDadosParcelasTemp(vetorParcelasTemp);

    }

    useEffect(async () => {
        //console.log('***** useEffect antes CriaParcelas', )
        await CriaParcelas();
        //console.log('***** useEffect depois CriaParcelas', dadosParcelas)

    }, [parcelamento, intervaloParcelas])

    useEffect(() => {
        if (dadosParcelas.length > 0)
            AlteraParcelasFormaPagamento();

    }, [formaPagamento])

    useEffect(() => {
        if (dadosParcelas.length > 0)
            AlteraParcelasBanco();

    }, [banco])

    useEffect(() => {
        if (dadosParcelas.length > 0)
            AlteraParcelasNome();

    }, [nome])

    useEffect(() => {
        if (dadosParcelas.length > 0)
            AlteraParcelasAgendado();

    }, [agendado])

    useEffect(() => {
        //console.log('***** useEffect antes AlteraParcelasValor', dadosParcelas)
        if (dadosParcelas.length > 0)
            AlteraParcelasValor();
        //console.log('***** useEffect depois AlteraParcelasValor', dadosParcelas)
    }, [valorOnBlur])

    return (
        <>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography variant='title' fontWeight="bold" >Parcelas</Typography>
                    <Divider
                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                        style={{
                            marginTop: 20,
                            border: "none",
                            height: 2,
                            margin: 0,
                        }}
                    />
                </Grid>

                {
                    dadosParcelas?.map((item, index) => {
                        return (
                            <>
                                <Grid item xs={12} sm={0.3} ml={2.5}>{/*INDICE*/}
                                    {<><strong>{index + 1}</strong></>}
                                </Grid>

                                <Grid item xs={12} sm={2}>{/*VENCIMENTO*/}
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                        <DesktopDatePicker
                                            required={true}
                                            label='Vencimento'
                                            inputFormat="dd/MM/yyyy"
                                            value={item.vencimento}
                                            onChange={(e) => {
                                                let tempVencimento = [...dadosParcelas]
                                                tempVencimento[index].vencimento = e
                                                setDadosParcelas(tempVencimento);
                                            }}

                                            renderInput={(params) => <FormField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                <Grid item xs={12} sm={1.5}> {/* VALOR PARCELA R$*/}
                                    <CurrencyInput
                                        disabled={index + 1 == parcelamento.value ? true : false}
                                        required={true}
                                        label="Valor (R$)"
                                        value={item.valor}
                                        onChange={(e) => {
                                            let tempValor = [...dadosParcelas]
                                            tempValor[index].valor = parseFloat(e.target.value.replace(/\./g, '').replace(',', '.'))
                                            setDadosParcelas(tempValor);
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment sx={{ width: '5%', color: 'green' }} position="end"><AttachMoneyIcon /></InputAdornment>,
                                            onBlur: (e) => {
                                                e.preventDefault();
                                                handleBlurValor(item.valor, index);
                                            },
                                            disabled: index + 1 == parcelamento.value ? true : false,
                                            autoFocus: false,
                                        }}
                                        InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                    />
                                </Grid>

                                <Grid item xs={12} sm={1}> {/* PERCENTUAL */}
                                    <CurrencyInput
                                        disabled={index + 1 == parcelamento.value ? true : false}
                                        required={true}
                                        label="Percentual"
                                        value={item.percentual}
                                        onChange={(e) => {
                                            let tempPercentual = [...dadosParcelas]
                                            //console.log('tempPercentual antes',index,tempPercentual)
                                            //tempPercentual[index].percentual = e.target.value.replace(/[^.,0-9]/g, '')
                                            tempPercentual[index].percentual = parseFloat(e.target.value.replace(/\./g, '').replace(',', '.'))
                                            //console.log('tempPercentual depois',index,tempPercentual)
                                            setDadosParcelas(tempPercentual);
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment sx={{ width: '5%', color: 'blue' }} position="end"><PercentIcon /></InputAdornment>,
                                            onBlur: (e) => {
                                                e.preventDefault();
                                                if (dadosParcelasTemp[index]?.percentual !== item.percentual || dadosParcelasTemp.length === 0) {
                                                    handleBlurPercentual(item.percentual, index);
                                                }
                                            },
                                            disabled: index + 1 == parcelamento.value ? true : false,
                                        }}
                                        InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                    />
                                </Grid>

                                <Grid item xs={12} sm={2}> {/*FORMA_PAGAMENTO*/}
                                    <AutoCompleteStyled
                                        options={optionFormaPagamento}
                                        getOptionLabel={(option) => option.label || ""}
                                        renderInput={(params) => <FormField {...params} label="Forma de pagamento" />}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                        value={item.formaPagamento}
                                        onChange={(event, newValue) => {
                                            let tempFormaPagamento = [...dadosParcelas]
                                            tempFormaPagamento[index].formaPagamento = newValue
                                            setDadosParcelas(tempFormaPagamento);
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={2}> {/*BANCO OU CAIXA*/}
                                    <AutoCompleteStyled
                                        options={optionBanco}
                                        getOptionLabel={(option) => option.label || ""}
                                        renderInput={(params) => <FormField {...params} label="Conta de Pagamento:" />}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                        value={item.banco}
                                        onChange={(event, newValue) => {
                                            let tempBanco = [...dadosParcelas]
                                            tempBanco[index].banco = newValue
                                            setDadosParcelas(tempBanco);
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={tipo == 'D' ? 1.5 : 3}> {/*DESCRIÇÃO QUE É O NOME*/}
                                    <FormField
                                        required
                                        type="text"
                                        label="Descrição"
                                        value={item.descricao}
                                        onChange={(e) => {
                                            let tempDescricao = [...dadosParcelas]
                                            tempDescricao[index].descricao = e.target.value
                                            setDadosParcelas(tempDescricao);
                                        }}
                                        InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                    />
                                </Grid>

                                {
                                    tipo == 'D' &&
                                    <>
                                        <Grid item xs={12} sm={1.5}> {/*AGENDADO*/}
                                            <MDBox width="100%" display="flex" alignItems='center' flexDirection='row'>
                                                <Checkbox
                                                    checked={item.agendado}
                                                    onChange={(e) => {
                                                        let tempAgendado = [...dadosParcelas]
                                                        tempAgendado[index].agendado = e.target.checked
                                                        setDadosParcelas(tempAgendado);
                                                    }}
                                                />
                                                <MDTypography pr={0.5} variant="caption">
                                                    {'Agendado'}
                                                </MDTypography>
                                                <Tooltip
                                                    title={`Ao marcar como agendado, será considerada a data ${dateIsValid(item?.vencimento) && vencimento != null ? formatDate(item.vencimento, 'DD/MM/YYYY') : 'Data invalida'}
                                            A baixa será feita no sistema somente quando for marcado manualmente como pago`}
                                                    placement="top"
                                                >
                                                    <HelpIcon color="info" />
                                                </Tooltip>
                                            </MDBox>
                                        </Grid>
                                    </>
                                }

                                {
                                    !competenciaCheck &&
                                    <>
                                        <Grid item xs={12} sm={0.5}> {/*espaço*/}

                                        </Grid>
                                        <Grid item xs={12} sm={2}> {/*DATA_COMPETENCIA*/}
                                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                                <DesktopDatePicker
                                                    required={true}
                                                    label='Data competência'
                                                    inputFormat="dd/MM/yyyy"
                                                    value={item.data_competencia}
                                                    onChange={(e) => {
                                                        let tempDataCompetencia = [...dadosParcelas]
                                                        tempDataCompetencia[index].data_competencia = e
                                                        setDadosParcelas(tempDataCompetencia);
                                                    }}

                                                    renderInput={(params) => <FormField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        {/* <Grid item xs={12} sm={9.5}>

                                        </Grid> */}
                                    </>

                                }

                                {
                                    competenciaCheck &&
                                    <Grid item xs={12} sm={0.5}> {/*ESPAÇO*/}
                                    </Grid>
                                }

                                <Grid item xs={12} sm={!competenciaCheck ? 9.5 : 11.5}> {/*OBSERVAÇÃO DA PARCELA*/}
                                    <FormField
                                        type="text"
                                        label={`Observação da parcela ${index + 1}`}
                                        value={item.observacao}
                                        onChange={(e) => {
                                            let tempObs = [...dadosParcelas]
                                            tempObs[index].observacao = e.target.value
                                            setDadosParcelas(tempObs);
                                        }}
                                        InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12}> {/*LINHA DIVISORIA*/}
                                    <Divider
                                        sx={{ bgcolor: (theme) => theme.palette.divider }}
                                        style={{
                                            marginTop: 20,
                                            border: "none",
                                            height: 2,
                                            margin: 0,
                                        }}
                                    />
                                </Grid>
                            </>
                        )
                    })
                }
            </Grid>
        </>

    )
}

export default function DialogoNovaConta(props) {

    const { open, message, handleCancel, title, setCodigoAbrir, codigoAbrir, tipo, dadosVenda, handleConcluir } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera, MensagemAlerta } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    const [vencimento, setVencimento] = useState(new Date());
    const [intervaloParcelas, setIntervaloParcelas] = useState(30);
    const [formaPagamento, setFormaPagamento] = useState();
    const [optionFormaPagamento, setOptionFormaPagamento] = useState([]);
    const [banco, setBanco] = useState();
    const [optionBanco, setOptionBanco] = useState([
        { value: -1, label: "<Escolha uma conta de pagamento>" },
    ]);

    const optionParcelamento = [
        { value: 0, label: "À vista" },
        { value: 1, label: "1x" },
        { value: 2, label: "2x" },
        { value: 3, label: "3x" },
        { value: 4, label: "4x" },
        { value: 5, label: "5x" },
        { value: 6, label: "6x" },
        { value: 7, label: "7x" },
        { value: 8, label: "8x" },
        { value: 9, label: "9x" },
        { value: 10, label: "10x" },
        { value: 11, label: "11x" },
        { value: 12, label: "12x" },
        { value: 13, label: "13x" },
        { value: 14, label: "14x" },
        { value: 15, label: "15x" },
        { value: 16, label: "16x" },
        { value: 17, label: "17x" },
        { value: 18, label: "18x" },
        { value: 19, label: "19x" },
        { value: 20, label: "20x" },
        { value: 21, label: "21x" },
        { value: 22, label: "22x" },
        { value: 23, label: "23x" },
        { value: 24, label: "24x" },
        { value: 25, label: "25x" },
        { value: 26, label: "26x" },
        { value: 27, label: "27x" },
        { value: 28, label: "28x" },
        { value: 29, label: "29x" },
        { value: 30, label: "30x" },
        { value: 31, label: "31x" },
        { value: 32, label: "32x" },
        { value: 33, label: "33x" },
        { value: 34, label: "34x" },
        { value: 35, label: "35x" },
        { value: 36, label: "36x" },
        { value: 37, label: "37x" },
        { value: 38, label: "38x" },
        { value: 39, label: "39x" },
        { value: 40, label: "40x" },
        { value: 41, label: "41x" },
        { value: 42, label: "42x" },
        { value: 43, label: "43x" },
        { value: 44, label: "44x" },
        { value: 45, label: "45x" },
        { value: 46, label: "46x" },
        { value: 47, label: "47x" },
        { value: 48, label: "48x" },
        { value: 49, label: "49x" },
        { value: 50, label: "50x" },
        { value: 51, label: "51x" },
        { value: 52, label: "52x" },
        { value: 53, label: "53x" },
        { value: 54, label: "54x" },
        { value: 55, label: "55x" },
        { value: 56, label: "56x" },
        { value: 57, label: "57x" },
        { value: 58, label: "58x" },
        { value: 59, label: "59x" },
        { value: 60, label: "60x" },

    ];
    const [parcelamento, setParcelamento] = useState(optionParcelamento[0]);

    const [pago, setPago] = useState(false);
    const [agendado, setAgendado] = useState(false);
    const [competenciaCheck, setCompetenciaCheck] = useState(true);
    const [valor, setValor] = useState('');
    const [valorOnBlur, setValorOnBlur] = useState(true);

    const [dadosRateio, setDadosRateio] = useState();

    const [dadosParcelas, setDadosParcelas] = useState([]);


    const [nome, setNome] = useState('');
    const [codigoReferencia, setCodigoReferencia] = useState('');
    const [observacao, setObservacao] = useState('');

    const [porcentagem, setPorcentagem] = useState(0);
    const [dataCompetencia, setDataCompetencia] = useState(new Date());
    const [categoria_id, setCategoria_id] = useState({ value: -1, label: '<Escolha uma categoria>' });
    const [optionCategoria, setOptionCategoria] = useState({ value: -1, label: '<Escolha uma categoria>' });

    const [centro_custo_id, setCentro_custo_id] = useState('');
    const [optionCentroCusto, setOptionCentroCusto] = useState({ value: -1, label: '<Escolha um centro de custo>' });

    const [pessoa, setPessoa] = useState({ value: -1, label: '<Escolha uma pessoa>' });
    const [optionPessoa, setOptionPessoa] = useState({ value: -1, label: '<Escolha uma pessoa>' });

    const [checked, setChecked] = useState(false);
    const [manual, setManual] = useState('N');


    const buscaDadosVenda = async () => {
        // console.log('Dados do orçamento/venda:', dadosVenda) //obj vindo da venda via props
        if (dadosVenda) {
            const retornoCategoria = await buscaCategoria();
            // console.log('retornoCategoria:', retornoCategoria)
            const retornoCentroCusto = await buscaCentroCusto();
            // console.log('retornoCentroCusto:', retornoCentroCusto)
            setValor(parseFloat(dadosVenda.valor_final.toFixed(2)))
            //setObservacao(`${dadosVenda.nome} - ${dadosVenda.observacao}`)
            setDataCompetencia(dadosVenda.data_orcamento)
            setPessoa({ value: dadosVenda.cliente_id, label: `${dadosVenda.cliente_id} - ${dadosVenda.nome_cliente_id}` })
            setNome(`Venda - ${dadosVenda.id}`)
            setCodigoReferencia(`${dadosVenda.id}`)
            setManual('S')
            retornoCategoria.map((item) => {
                if (dadosVenda.categoria_id == item.value) {
                    setCategoria_id({ value: item.value, label: item.label })
                }
            })
            retornoCentroCusto.map((item) => {
                if (dadosVenda.centro_custo_id == item.value) {
                    setCentro_custo_id({ value: item.value, label: item.label })
                }
            })

        }
    }

    const buscaParametros = async () => {


        try {
            let url = `${rotafechada()}vendas/parametros`;
            const retorno = await api.get(encodeURI(url));
            const data = retorno.data;

            return data
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const buscaCategoria = async () => {
        try {
            //passo com um parametro para trazer somente as categorias mae
            //complementoUrl = `${complementoUrl}&direction=${order}`;
            const complementoUrl = `?tipo=${tipo}`;
            const url = `${rotafechada()}categoria/listarordenado${complementoUrl}`;
            // console.log("***URL categoria:", url);
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesCategoriaTemp = [{ value: -1, label: '<Escolha uma categoria>' }];

            data.map((item) => {
                //if (!item.verificador) {
                opcoesCategoriaTemp.push({ value: item.id, label: item.nome, mae: item?.filho ? true : false, });
                //}
            });
            setOptionCategoria(opcoesCategoriaTemp);

            return opcoesCategoriaTemp;
        } catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const buscaFormaPagamento = async () => {
        try {
            const url = `${rotafechada()}forma_pagamento?order=nome&limit=-1&ativo=S`;
            //console.log("***URL forma de pagamento:", url);
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesFormaPagamentoTemp = [{}];

            data.map((item) => {
                opcoesFormaPagamentoTemp.push({ value: item.id, label: item.nome });
            });
            setOptionFormaPagamento(opcoesFormaPagamentoTemp);

            return opcoesFormaPagamentoTemp;
        } catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const buscaBanco = async () => {
        try {
            const url = `${rotafechada()}banco?order=nome&limit=-1&ativo=S`;
            //console.log("***URL forma de pagamento:", url);
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesBancoTemp = [{}];

            data.map((item) => {
                opcoesBancoTemp.push({ value: item.id, label: item.nome });
            });
            setOptionBanco(opcoesBancoTemp);

            return opcoesBancoTemp;
        } catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const buscaPessoa = async (parametrosVenda) => {
        try {
            const url = `${rotafechada()}pessoas?order=razao_social&limit=-1&tipo_pessoa_id=${tipo == 'D' ? parseInt(parametrosVenda.tipo_pessoa_id_fornecedor) : parseInt(parametrosVenda.tipo_pessoa_id_cliente)}`;
            //console.log("***URL pessoa:", url);
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesPessoaTemp = [{ value: -1, label: '<Escolha uma pessoa>' }];

            data.map((item) => {
                opcoesPessoaTemp.push({ value: item.id, label: item.fisica_juridica == 'F' ? item.id + ' - ' + item.razao_social : item.id + ' - ' + item.razao_social + ' / ' + item.fantasia });
            });
            setOptionPessoa(opcoesPessoaTemp);

            return opcoesPessoaTemp;
        } catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const buscaCentroCusto = async () => {
        try {
            const url = `${rotafechada()}centro_custo?order=nome&limit=-1&ativo=S`;
            //console.log("***URL centro de custo:", url);
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesCentroCustoTemp = [{ value: -1, label: '<Escolha um centro de custo>' }];

            data.map((item) => {
                opcoesCentroCustoTemp.push({ value: item.id, label: item.nome });
            });
            setOptionCentroCusto(opcoesCentroCustoTemp);
            return opcoesCentroCustoTemp;
        } catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    };

    const handleClickGravar = async (e) => {

        let msg = ''

        if (!(dateIsValid(vencimento)) || vencimento == null) {
            msg = msg + 'vencimento, '
        }

        if ((pessoa == '') || (pessoa.value == -1)) {
            msg = msg + 'pessoa, '
        }
        if (dataCompetencia == '' || dataCompetencia == null) {
            msg = msg + 'data competencia, '
        }
        if (nome == '') {
            msg = msg + 'descrição, '
        }
        if (valor <= 0) {
            msg = msg + 'valor, '
        }
        if (((!categoria_id) && (!checked)) || ((categoria_id.value == -1) && (!checked))) {
            msg = msg + 'categoria, '
        }
        if (checked) {
            let countCategoria = 0
            let countValor = 0
            let subTotalRateado = 0
            let subTotalRateadoP = 0
            for (let i = 0; i < dadosRateio.length; i++) {
                if (dadosRateio[i].categoria.value == '') {
                    countCategoria++
                }
                if (dadosRateio[i].valorTotal == 0) {
                    countValor++
                }
                subTotalRateado = parseFloat(subTotalRateado) + parseFloat(dadosRateio[i].valorTotal)
                subTotalRateadoP = parseFloat(subTotalRateadoP) + parseFloat(dadosRateio[i].porcentagem)
            }
            if (countCategoria > 0)
                msg = msg + 'categoria do rateio'
            if (countValor > 0)
                msg = msg + ' | Valor do rateio deve ser superior a 0'
            if ((parseFloat(valor).toFixed(2) - parseFloat(subTotalRateado).toFixed(2)) != 0)
                msg = msg + ' | Somatória do rateio difere do valor da conta'
        }

        if (parcelamento.value > 0) {
            let countValorParcelas = 0
            let subTotalParelas = 0
            for (let i = 0; i < dadosParcelas.length; i++) {
                if (dadosParcelas[i].valor == 0)
                    countValorParcelas++
                if (dadosParcelas[i].valor > 0)
                    subTotalParelas = parseFloat(parseFloat(subTotalParelas).toFixed(2)) + parseFloat(parseFloat(dadosParcelas[i].valor).toFixed(2))
                if (dadosParcelas[i].descricao == '')
                    msg = msg + `descrição da parcela ${i + 1}, `
            }
            if (countValorParcelas > 0)
                msg = msg + 'Valor da parcela deve ser superior a 0'
            if ((parseFloat(valor).toFixed(2) - subTotalParelas.toFixed(2)) != 0)
                msg = msg + 'Somatória das parcelas difere do valor da conta'
        }
        if (msg.length > 0) {
            MensagemAlerta(`Campo(s) obrigatório(s): ${msg}`)

        } else {
            try {
                MostraTelaEspera('Gravando o conta...');
                let retorno;
                let dadosRateioBack = []
                let dadosParcelasBack = []
                if (checked) { //Rateio
                    for (let i = 0; i < dadosRateio.length; i++) {
                        dadosRateioBack.push(
                            {
                                categoria_id: dadosRateio[i]?.categoria.value,
                                centro_custo_id: dadosRateio[i]?.centroCusto?.value ? dadosRateio[i]?.centroCusto.value : null,
                                valor: dadosRateio[i].valorTotal,
                                porcentagem: dadosRateio[i].porcentagem,
                                conta_id: 0
                            }
                        )

                    }
                }
                if (parcelamento.value > 0) { //Parcelas
                    for (let i = 0; i < dadosParcelas.length; i++) {
                        dadosParcelasBack.push(
                            {
                                empresa_id: sessaolocal.empresa_id || null,
                                conta_id: 0,
                                forma_pagamento_id: dadosParcelas[i].formaPagamento?.value,
                                banco_id: dadosParcelas[i].banco?.value,
                                nome: dadosParcelas[i].descricao,
                                data_competencia: competenciaCheck === true ? dadosParcelas[i].vencimento : dadosParcelas[i].data_competencia,
                                vencimento: dadosParcelas[i].vencimento,
                                previsao: 'Não tenho ainda',
                                valor: dadosParcelas[i].valor,
                                agendado: dadosParcelas[i].agendado ? 'S' : 'N',
                                observacao: dadosParcelas[i]?.observacao,
                                numero_parcela: (i + 1),
                                ativo: 'S'


                            }
                        )


                    }
                }

                const dados = {
                    empresa_id: sessaolocal.empresa_id || null,
                    pessoa_id: pessoa?.value,
                    categoria_id: checked == true ? dadosRateioBack[0]?.categoria_id ? dadosRateioBack[0]?.categoria_id : null : categoria_id?.value ? categoria_id?.value : null,
                    centro_custo_id: checked == true ? dadosRateioBack[0]?.centro_custo_id ? dadosRateioBack[0]?.centro_custo_id : null : centro_custo_id?.value ? centro_custo_id?.value : null,
                    nome: nome,
                    data_competencia: dataCompetencia,
                    codigo_referencia: codigoReferencia,
                    valor,
                    manual: 'S',
                    ativo: 'S',
                    rateio: checked ? 'S' : 'N',
                    parcelasrateio: dadosRateioBack,
                    avista: parcelamento.value > 0 ? false : true,
                    parcela: {
                        empresa_id: sessaolocal.empresa_id || null,
                        conta_id: 0,
                        forma_pagamento_id: formaPagamento?.value ? formaPagamento.value : null,
                        banco_id: banco?.value ? banco.value : null,
                        nome: nome,
                        data_competencia: vencimento,
                        vencimento: vencimento,
                        previsao: 'Não tenho ainda',
                        valor: valor,
                        agendado: agendado ? 'S' : 'N',
                        observacao: '',
                        numero_parcela: 1,
                        ativo: 'S'
                    },
                    parcelas: dadosParcelasBack,
                    observacao: observacao
                }

                //console.log(dados);

                retorno = await api.post(`${rotafechada()}contas/full`, dados);


                MensagemAviso('Gravado com sucesso!');
                FechaTelaEspera();
                handleCancel()
                limpaImputs()
            }
            catch (error) {
                FechaTelaEspera();
                const msg = error.response?.data?.msg || error;
                MensagemErro(`Erro: ${msg}`);
            }

        }

    }

    const handleClickConcluirVenda = async (e) => {
        let msg = ''

        if (pessoa == '') {
            msg = msg + 'pessoa, '
        }
        if (dataCompetencia == '' || dataCompetencia == null) {
            msg = msg + 'data competencia, '
        }
        if (nome == '') {
            msg = msg + 'descrição, '
        }
        if (valor <= 0) {
            msg = msg + 'valor, '
        }
        if (((!categoria_id) && (!checked)) || (categoria_id.value == -1)) {
            msg = msg + 'categoria'
        }
        if (checked) {
            let countCategoria = 0
            let countValor = 0
            let subTotalRateado = 0
            let subTotalRateadoP = 0
            for (let i = 0; i < dadosRateio.length; i++) {
                if (dadosRateio[i].categoria.value == '') {
                    countCategoria++
                }
                if (dadosRateio[i].valorTotal == 0) {
                    countValor++
                }
                subTotalRateado = parseFloat(subTotalRateado) + parseFloat(dadosRateio[i].valorTotal)
                subTotalRateadoP = parseFloat(subTotalRateadoP) + parseFloat(dadosRateio[i].porcentagem)
            }
            if (countCategoria > 0)
                msg = msg + 'categoria do rateio'
            if (countValor > 0)
                msg = msg + ' | Valor do rateio deve ser superior a 0'
            if ((valor - subTotalRateado) != 0)
                msg = msg + ' | Somatória do rateio difere do valor da conta'
        }

        if (parcelamento.value > 0) {
            let countValorParcelas = 0
            let subTotalParelas = 0
            for (let i = 0; i < dadosParcelas.length; i++) {
                if (dadosParcelas[i].valor == 0)
                    countValorParcelas++
                if (dadosParcelas[i].valor > 0)
                    subTotalParelas = parseFloat(parseFloat(subTotalParelas).toFixed(2)) + parseFloat(parseFloat(dadosParcelas[i].valor).toFixed(2))
            }
            if (countValorParcelas > 0)
                msg = msg + 'Valor da parcela deve ser superior a 0'
            if ((parseFloat(valor).toFixed(2) - subTotalParelas.toFixed(2)) != 0)
                msg = msg + 'Somatória das parcelas difere do valor da conta'
        }
        if (msg.length > 0) {
            MensagemAlerta(`Campo(s) obrigatório(s): ${msg}`)

        } else {
            if (!dadosVenda.plano_conta_id) {
                MensagemAlerta('Parâmetro: ID do plano de conta padrão da venda não foi configurado!')
            } else {
                try {
                    MostraTelaEspera('Gravando o conta...');
                    let retorno;
                    let dadosRateioBack = []
                    let dadosParcelasBack = []
                    // if (checked) { //Rateio
                    //     for (let i = 0; i < dadosRateio.length; i++) {
                    //         dadosRateioBack.push(
                    //             {
                    //                 categoria_id: dadosRateio[i]?.categoria.value,
                    //                 centro_custo_id: dadosRateio[i]?.centroCusto?.value ? dadosRateio[i]?.centroCusto.value : null,
                    //                 valor: dadosRateio[i].valorTotal,
                    //                 porcentagem: dadosRateio[i].porcentagem,
                    //                 conta_id: 0
                    //             }
                    //         )

                    //     }
                    // }
                    if (parcelamento.value > 0) { //Parcelas
                        for (let i = 0; i < dadosParcelas.length; i++) {
                            dadosParcelasBack.push(
                                {
                                    empresa_id: sessaolocal.empresa_id || null,
                                    conta_id: 0,
                                    forma_pagamento_id: dadosParcelas[i].formaPagamento?.value,
                                    banco_id: dadosParcelas[i].banco?.value,
                                    nome: dadosParcelas[i].descricao,
                                    data_competencia: dataCompetencia,
                                    vencimento: dadosParcelas[i].vencimento,
                                    previsao: 'Não tenho ainda',
                                    valor: dadosParcelas[i].valor,
                                    agendado: dadosParcelas[i].agendado ? 'S' : 'N',
                                    observacao: dadosParcelas[i]?.observacao,
                                    numero_parcela: (i + 1),
                                    ativo: 'S'


                                }
                            )


                        }
                    }

                    const dados = {
                        empresa_id: sessaolocal.empresa_id || null,
                        pessoa_id: pessoa?.value,
                        categoria_id: categoria_id?.value ? categoria_id.value > 0 ? categoria_id.value : null : null,
                        //centro_custo_id: centro_custo_id?.value ? centro_custo_id.value : dadosRateioBack[0]?.centro_custo_id ? dadosRateioBack[0].centro_custo_id : null,
                        centro_custo_id: centro_custo_id?.value ? centro_custo_id.value > 0 ? centro_custo_id.value : null : null,
                        nome: nome,
                        data_competencia: dataCompetencia,
                        data_venda: dataCompetencia,
                        codigo_referencia: codigoReferencia,
                        valor,
                        desconto: parseFloat(dadosVenda.valor_desconto),
                        plano_conta_desconto_venda: dadosVenda.plano_conta_desconto_venda,
                        manual: 'N',
                        ativo: 'S',
                        rateio: checked ? 'S' : 'N',
                        parcelasrateio: dadosRateioBack,
                        avista: parcelamento.value > 0 ? false : true,
                        parcela: {
                            empresa_id: sessaolocal.empresa_id || null,
                            conta_id: 0,
                            forma_pagamento_id: formaPagamento?.value ? formaPagamento.value : null,
                            banco_id: banco?.value ? banco.value : null,
                            nome: nome,
                            data_competencia: dataCompetencia,
                            vencimento: vencimento,
                            previsao: 'Não tenho ainda',
                            valor: valor,
                            agendado: agendado ? 'S' : 'N',
                            observacao: '',
                            numero_parcela: 1,
                            ativo: 'S'
                        },
                        parcelas: dadosParcelasBack,
                        observacao: observacao,
                        plano_conta_id: dadosVenda.plano_conta_id || 0
                    }

                    // console.log(dados);

                    //retorno = await api.post(`${rotafechada()}contas/full`, dados);
                    await api.post(`${rotafechada()}vendas/concluir/${dadosVenda.id}`, {
                        dados
                    });

                    MensagemAviso('Gravado com sucesso!');
                    FechaTelaEspera();
                    handleCancel()
                    limpaImputs()
                    handleConcluir()
                }
                catch (error) {
                    FechaTelaEspera();
                    const msg = error.response?.data?.msg || error;
                    MensagemErro(`Erro: ${msg}`);
                }
            }

        }
    }

    const handleClickCopiaCompetenciaVencimento = async (e) => {
        let tempParcelas = [...dadosParcelas]
        tempParcelas.map((item, index) => {
            item.data_competencia = dataCompetencia
        })

        setDadosParcelas(tempParcelas)

    }

    const limpaImputs = () => {

        dadosVenda?.id > 0 ? setNome('') : setCodigoAbrir('');
        setNome('');
        setPessoa('');
        setValor('');
        setDataCompetencia(new Date());
        setVencimento(new Date());
        setCategoria_id('');
        setCentro_custo_id('');
        setCodigoReferencia('');
        setObservacao('');
        setPago(false);
        setCompetenciaCheck(true);
        setAgendado(false);
        setBanco({});
        setFormaPagamento({});
        setDadosParcelas([])
        setIntervaloParcelas(30);
        setParcelamento(optionParcelamento[0]);
        setChecked(false);

    }


    useEffect(async () => {
        if (open == true) {
            try {
                MostraTelaEspera();
                let parametrosVenda = await buscaParametros();
                await buscaFormaPagamento();
                await buscaBanco();
                await buscaCategoria();
                await buscaPessoa(parametrosVenda);
                await buscaCentroCusto();
                if (dadosVenda) {
                    await buscaDadosVenda();
                }
                FechaTelaEspera();

            } finally {
                FechaTelaEspera();
            }
        }

    }, [open])

    useEffect(() => {
        let temp = [{}]
        if (!checked) {
            temp = [{
                categoria: { value: "", label: "" },
                valorTotal: 0,
                porcentagem: 50,
                centroCusto: { value: "", label: "" },

            },
            {
                categoria: { value: "", label: "" },
                valorTotal: 0,
                porcentagem: 50,
                centroCusto: { value: "", label: "" },

            },]
        }
        if (checked) {
            temp = [{
                categoria: { value: "", label: "" },
                valorTotal: parseFloat(valor / 2).toFixed(2),
                porcentagem: parseFloat(50).toFixed(2),
                centroCusto: { value: "", label: "" },

            },
            {
                categoria: { value: "", label: "" },
                valorTotal: parseFloat(valor / 2).toFixed(2),
                porcentagem: parseFloat(50).toFixed(2),
                centroCusto: { value: "", label: "" },

            },]
        }
        setDadosRateio(temp)

    }, [checked])

    useEffect(() => {
        //console.log('***** useEffect antes parcelamento', dadosParcelas)
        if ((parcelamento.value >= 1) && ((formatDate(vencimento, 'DD/MM/YYYY')) == (formatDate(new Date(), 'DD/MM/YYYY')))) {
            setVencimento(incMonth(new Date(), 1))
        }
        if (parcelamento.value == 0) {
            setDadosParcelas([])
            setVencimento(new Date())
            setDataCompetencia(new Date())
        }
        //console.log('***** useEffect antes parcelamento', dadosParcelas)
    }, [parcelamento])

    useEffect(() => {
        if ((vencimento > new Date()) && parcelamento.value == 0) {
            setParcelamento(optionParcelamento[1])
        }

    }, [vencimento])

    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    handleCancel()
                    limpaImputs()
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullScreen
            >
                <DialogTitle id="alert-dialog-title" sx={{ padding: '5px' }}>
                    <MDBox width="100%">
                        <MDBox display="flex" justifyContent="space-between">
                            <MDTypography fontWeight='bold' ml={5} mt={1} variant='h4'>{title}</MDTypography>

                            <Tooltip title="Fechar (Esc)">
                                <MDButton size="large" sx={{ padding: 0 }} onClick={() => {
                                    handleCancel()
                                    limpaImputs()
                                }}>
                                    <Icon>close</Icon>
                                </MDButton>

                            </Tooltip>
                        </MDBox>
                    </MDBox>
                </DialogTitle>


                <DialogContent dividers sx={{ backgroundColor: "#f1f4f9", borderColor: '#7bb5e4' }}>
                    <DialogContentText id="alert-dialog-description">
                        {/* {message} */}

                        <form onSubmit={handleClickGravar}>
                            <MDBox ml={2} mr={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* CONTA */}
                                <Grid container spacing={2} mt={1} p={2}>

                                    <Grid item xs={12} sm={12}> {/* TITULO*/}
                                        <MDTypography fontWeight="bold" pb={2}>Informações da conta</MDTypography>
                                        <Divider
                                            sx={{ bgcolor: (theme) => theme.palette.divider }}
                                            style={{
                                                marginTop: 20,
                                                border: "none",
                                                height: 2,
                                                margin: 0,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={2}> {/* DATA_COMPETENCIA */}
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                            <DesktopDatePicker
                                                disabled={dadosVenda?.id > 0 ? true : false}
                                                required={true}
                                                label='Data competencia'
                                                inputFormat="dd/MM/yyyy"
                                                value={dataCompetencia}
                                                onChange={(e) => setDataCompetencia(e)}
                                                renderInput={(params) => <FormField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item xs={12} sm={4}>{/*PESSOA*/}
                                        <AutoCompleteStyled
                                            disabled={dadosVenda?.id > 0 ? true : false}
                                            disableClearable //Retira o X (clear)
                                            required={true}
                                            options={optionPessoa}
                                            getOptionLabel={(option) => option.label || ""}
                                            renderInput={(params) => <FormField required {...params} label={tipo == 'D' ? 'Fornecedor:' : 'Cliente:'} />}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            value={pessoa}
                                            onChange={(event, newValue) => {
                                                setPessoa(newValue);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4}> {/* NOME */}

                                        <FormField
                                            disabled={dadosVenda?.id > 0 ? true : false}
                                            required={true}
                                            type="text"
                                            label="Descrição"
                                            value={nome}
                                            onChange={(e) => setNome(e.target.value)}
                                            InputProps={{
                                                disabled: dadosVenda?.id > 0 ? true : false
                                            }}
                                        />

                                    </Grid>

                                    <Grid item xs={12} sm={2}> {/* VALOR */}

                                        <CurrencyInput
                                            autoFocus
                                            disabled={dadosVenda?.id > 0 ? true : false}
                                            label="valor"
                                            value={valor}
                                            placeholder="0,00"
                                            onChange={(e) => {
                                                const tempValor = parseFloat(e.target.value.replace(/\./g, '').replace(',', '.'))
                                                setValor(tempValor)
                                            }}
                                            // helperText={valor > 0 ? false :
                                            //     <>
                                            //         <CancelIcon color={'error'} />
                                            //         <Typography pl={0.5} fontWeight="small" variant="caption" color={'error'}>Valor deve ser superior a 0</Typography>
                                            //     </>
                                            // }
                                            InputProps={{
                                                startAdornment: <InputAdornment sx={{ width: '5%', fontSize: '20px' }} style={{ fontSize: '20px' }} position="start">R$</InputAdornment>,
                                                onBlur: (e) => {
                                                    e.preventDefault();
                                                    setValorOnBlur(!valorOnBlur)
                                                },
                                                disabled: dadosVenda?.id > 0 ? true : false
                                            }}
                                        />
                                        {/* {(inputProps) => {
                                                <Input {...inputProps}/>
                                                // <FormField
                                                //     //autoFocus
                                                //     //disabled={dadosVenda?.id > 0 ? true : false}
                                                //     //required={true}
                                                //     type="number"
                                                //     placeholder="$$$$"
                                                //     label="Valor132"
                                                //     value={valor}
                                                //     // onChange={(e) => setValor(e.target.value.replace(/[^.,0-9]/g, ''))}
                                                //     onChange={(e) => setValor(e.target.value)}
                                                //     // InputProps={{
                                                //     //     startAdornment: <InputAdornment sx={{ width: '5%' }} position="start">R$</InputAdornment>,
                                                //     //     onBlur: (e) => {
                                                //     //         e.preventDefault();
                                                //     //         setValorOnBlur(!valorOnBlur)
                                                //     //     },
                                                //     //     disabled: dadosVenda?.id > 0 ? true : false
                                                //     // }}
                                                // />
                                            }} */}
                                    </Grid>

                                    {
                                        manual === 'N' &&
                                        <Grid item xs={12} sm={1} > {/*RATEIO*/}
                                            <MDBox width='100%' display='flex' alignItems='center' flexDirection='column'>
                                                <MDTypography variant='caption'>
                                                    {'Habilitar rateio'}
                                                </MDTypography>
                                                <Switch
                                                    checked={checked}
                                                    onChange={(event) => setChecked(event.target.checked)}
                                                />
                                            </MDBox>
                                        </Grid>
                                    }

                                    <Grid item xs={12} sm={4} >{/*CATEGORIA*/}
                                        <AutoCompleteStyled
                                            disabled={checked == true ? true : false}
                                            disableClearable //Retira o X (clear)
                                            required={true}
                                            options={optionCategoria}
                                            getOptionDisabled={(option) => option.mae === true}
                                            getOptionLabel={(option) => option.label || ""}
                                            renderInput={(params) => <FormField required {...params} label="Categoria:" />}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    {option.mae === true ?
                                                        <Typography variant="subtitle" style={{ fontWeight: '900', color: 'black' }}>{option.label}</Typography>
                                                        :
                                                        <Typography variant="subtitle" style={{ font: 'inherit' }}>{`   ${option.label}`}</Typography>
                                                    }
                                                </Box>
                                            )}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            value={categoria_id}
                                            onChange={(event, newValue) => {
                                                setCategoria_id(newValue);
                                            }}
                                            style={{ cursor: "not-allowed" }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={0.5}>{/*INFO CATEGORIA*/}
                                        <Tooltip
                                            title="As categorias são utilizadas para formar um Plano de Contas."
                                            placement="top"
                                        >
                                            <HelpIcon color="info" />
                                        </Tooltip>
                                    </Grid>

                                    <Grid item xs={12} sm={3}> {/*CENTRO_CUSTO*/}
                                        <AutoCompleteStyled mr={2}
                                            disabled={checked == true ? true : false}
                                            options={optionCentroCusto}
                                            getOptionLabel={(option) => option.label || ""}
                                            renderInput={(params) => <FormField {...params} label="Centro de custo" />}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            value={centro_custo_id}
                                            onChange={(event, newValue) => {
                                                setCentro_custo_id(newValue);
                                            }}
                                            style={{ cursor: "not-allowed" }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={0.5}>{/*INFO CENTRO_CUSTO*/}
                                        <Tooltip pl={1} title="O Centro de Custo é uma informação financeira para ajudar no controle de receitas
                                    e despesas de determinada área ou projeto da empresa." placement="top">
                                            <HelpIcon color="info" />
                                        </Tooltip>
                                    </Grid>

                                    <Grid item xs={12} sm={2.5}> {/* CODIGO_REFERENCIA */}
                                        <FormField
                                            disabled={dadosVenda?.id > 0 ? true : false}
                                            type="text"
                                            label="Código de referência"
                                            value={codigoReferencia}
                                            onChange={(e) => setCodigoReferencia(e.target.value)}
                                            InputProps={{
                                                disabled: dadosVenda?.id > 0 ? true : false
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={0.5}>{/*INFO CENTRO_CUSTO*/}
                                        <Tooltip title="Código informado manualmente para identificação da conta. Exceto quando 
                                    originado por uma venda, neste caso o código é automáticamente preenchido com o número da venda." placement="top">
                                            <HelpIcon color="info" />
                                        </Tooltip>
                                    </Grid>

                                    {checked === true &&
                                        <>
                                            <Grid item xs={12} md={12}>{/* RATEIO DADOS */}
                                                <DadosRateio
                                                    label={'Informar categoria e centro de custo'}
                                                    optionCategoria={optionCategoria}
                                                    categoria_id={categoria_id}
                                                    setCategoria_id={setCategoria_id}
                                                    valor={valor}
                                                    setValor={setValor}
                                                    optionCentroCusto={optionCentroCusto}
                                                    centro_custo_id={centro_custo_id}
                                                    setCentro_custo_id={setCentro_custo_id}
                                                    porcentagem={porcentagem}
                                                    setPorcentagem={setPorcentagem}
                                                    dadosRateio={dadosRateio}
                                                    setDadosRateio={setDadosRateio}
                                                />
                                            </Grid>
                                        </>
                                    }

                                </Grid>
                            </MDBox >

                            <MDBox ml={2} mr={2} mt={2} bgColor={'#fff'} sx={{ borderRadius: '3px' }}> {/* LANCAMENTO */}
                                <Grid container spacing={2} mt={2} p={2}>

                                    <Grid item xs={12} sm={12}> {/* TITULO*/}
                                        <MDTypography fontWeight="bold" pb={2}>Condição de pagamento</MDTypography>
                                        <Divider
                                            sx={{ bgcolor: (theme) => theme.palette.divider }}
                                            style={{
                                                marginTop: 20,
                                                border: "none",
                                                height: 2,
                                                margin: 0,
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={1.5}>{/*PARCELAMENTO*/}
                                        <AutoCompleteStyled
                                            required={true}
                                            defaultValue={optionParcelamento[0]}
                                            disableClearable //Retira o X (clear)
                                            options={optionParcelamento}
                                            getOptionLabel={(option) => option.label || ""}
                                            renderInput={(params) => <FormField required {...params} label="Parcelamento:" />}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            disabled={!(dateIsValid(vencimento)) || vencimento == null }
                                            value={parcelamento}
                                            onChange={(event, newValue) => {
                                                //console.log('parcelamento',newValue)
                                                setParcelamento(newValue);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={1.6}> {/* VENCIMENTO */}
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                            <DesktopDatePicker
                                                //disabled={parcelamento.value == 0 ? true : false}
                                                required={true}
                                                label={parcelamento.value >= 1 ? '1° Vencimento' : 'Vencimento'}
                                                inputFormat="dd/MM/yyyy"
                                                value={vencimento}
                                                onChange={(e) => {
                                                    //vencimentoGlobal = e
                                                    setVencimento(e)
                                                }}
                                                renderInput={(params) => <FormField required {...params} />}

                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    {
                                        parcelamento?.value >= 1 &&
                                        <>
                                            <Grid item xs={12} sm={1}> {/* INTERVALO PARCELAS */}
                                                {/* <MDBox width="100%" display="flex" alignItems='space-between' flexDirection='row'> */}
                                                <MDBox width="100%" display="flex" alignItems='center' justifyContent="space-between" flexDirection='row'>
                                                    <FormField
                                                        defaultValue={30}
                                                        type="number"
                                                        label="Intervalo"
                                                        value={intervaloParcelas}
                                                        onChange={(e) => setIntervaloParcelas(e.target.value)}
                                                    />
                                                    <Tooltip
                                                        title={`Representa o intervalo em dias do vencimento entre as parcelas`}
                                                        placement="top"
                                                    >
                                                        <HelpIcon color="info" />
                                                    </Tooltip>
                                                </MDBox>
                                            </Grid>
                                        </>
                                    }

                                    <Grid item xs={12} sm={2}> {/*FORMA_PAGAMENTO*/}
                                        <AutoCompleteStyled
                                            freeSolo
                                            forcePopupIcon={true}
                                            options={optionFormaPagamento}
                                            getOptionLabel={(option) => option.label || ""}
                                            renderInput={(params) => <FormField {...params} label="Forma de pagamento" />}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            value={formaPagamento}
                                            onChange={(event, newValue) => {
                                                setFormaPagamento(newValue);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={2}>{/*BANCO_CAIXA*/}
                                        <AutoCompleteStyled
                                            options={optionBanco}
                                            getOptionLabel={(option) => option.label || ""}
                                            renderInput={(params) => <FormField {...params} label="Conta de Pagamento:" />}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                            value={banco}
                                            onChange={(event, newValue) => {
                                                setBanco(newValue);
                                            }}
                                        />
                                    </Grid>
                                    {/* {(parcelamento?.value < 1 || parcelamento?.value === '') &&
                                        <>  
                                        /* Habilitar futuramente 
                                            <Grid item xs={12} sm={1}> /*PAGO ou RECEBIDO
                                                <MDBox width="100%" display="flex" alignItems='center' flexDirection='row'>
                                                    <Checkbox
                                                        checked={pago}
                                                        onChange={(e) => setPago(e.target.checked)}
                                                    />
                                                    <MDTypography variant="caption">
                                                        {tipo == 'D' ? 'Pago ' : 'Recebido '}
                                                    </MDTypography>
                                                    <Tooltip
                                                        title={`Marcar como ${tipo == 'D' ? 'pago' : 'recebido'}.`}
                                                        placement="top"
                                                    >
                                                        <HelpIcon color="info" />
                                                    </Tooltip>
                                                </MDBox>
                                            </Grid>
                                        </>
                                    } */}
                                    {
                                        tipo == 'D' &&
                                        <>
                                            <Grid item xs={12} sm={1.5}>{/*AGENDADO*/}
                                                <MDBox width="100%" display="flex" alignItems='center' flexDirection='row'>
                                                    <Checkbox
                                                        checked={agendado}
                                                        onChange={(e) => setAgendado(e.target.checked)}
                                                    />
                                                    <MDTypography variant="caption">
                                                        {'Agendado '}
                                                    </MDTypography>
                                                    <Tooltip
                                                        title={`Ao marcar como agendado, será considerada a data ${dateIsValid(vencimento) && vencimento != null ? formatDate(vencimento, 'DD/MM/YYYY') : 'Data invalida'}
                                            A baixa será feita no sistema somente quando for marcado manualmente como pago`}
                                                        placement="top"
                                                    >
                                                        <HelpIcon color="info" />
                                                    </Tooltip>
                                                </MDBox>
                                            </Grid>
                                        </>
                                    }

                                    {
                                        parcelamento.value >= 1 && !dadosVenda &&
                                        <Grid item xs={12} sm={2} > {/*COMPETENCIA CHECK*/}
                                            <MDBox width='100%' display='flex' alignItems='center' flexDirection='column'>
                                                <MDTypography variant='caption'>
                                                    {'Competência = Vencimento '}
                                                    <Tooltip
                                                        title={`Quando desabilitado abre a possibilidade de alterar a data de competência por parcela`}
                                                        placement="top"
                                                    >
                                                        <HelpIcon color="info" />
                                                    </Tooltip>
                                                </MDTypography>
                                                <Switch
                                                    checked={competenciaCheck}
                                                    onChange={(event) => setCompetenciaCheck(event.target.checked)}
                                                />
                                            </MDBox>
                                        </Grid>
                                    }

                                    {

                                        !competenciaCheck && parcelamento.value >= 1 &&
                                        <Grid item xs={12} sm={2} > {/*BOTÃO COPIA COMPETENCIA VENCIMENTO*/}
                                            <MDButton onClick={handleClickCopiaCompetenciaVencimento} size='small' variant="gradient" type="button" color="info">
                                                Copia competência da conta para as parcelas
                                            </MDButton>
                                        </Grid>
                                    }

                                    {
                                        parcelamento.value >= 1 &&
                                        <>
                                            <Grid item xs={12} sm={12}>{/*PARCELAS 1x ate 60x*/}
                                                <DadosParcela
                                                    optionFormaPagamento={optionFormaPagamento}
                                                    optionBanco={optionBanco}
                                                    dadosParcelas={dadosParcelas}
                                                    setDadosParcelas={setDadosParcelas}
                                                    parcelamento={parcelamento}
                                                    nome={nome}
                                                    valor={valor}
                                                    banco={banco}
                                                    formaPagamento={formaPagamento}
                                                    vencimento={vencimento}
                                                    setVencimento={setVencimento}
                                                    intervaloParcelas={intervaloParcelas}
                                                    agendado={agendado}
                                                    tipo={tipo}
                                                    FechaTelaEspera={FechaTelaEspera}
                                                    MostraTelaEspera={MostraTelaEspera}
                                                    valorOnBlur={valorOnBlur}
                                                    competenciaCheck={competenciaCheck}
                                                />
                                            </Grid>
                                        </>
                                    }


                                    <Grid item xs={12} sm={12}> {/* OBSERVACAO da conta*/}
                                        <FormField
                                            type="text"
                                            label="Observação da conta"
                                            value={observacao}
                                            onChange={(e) => setObservacao(e.target.value)}
                                            rows={3}
                                            multiline={true}
                                        />
                                    </Grid>

                                </Grid>
                            </MDBox >
                        </form>
                    </DialogContentText>
                </DialogContent>

                <DialogActions sx={{ padding: '7px', margin: 0.5 }}> {/*BOTÕES*/}
                    <MDBox width="100%">
                        <MDBox width="100%" display="flex" justifyContent="space-between">
                            <MDButton onClick={() => {
                                handleCancel()
                                limpaImputs()
                            }} variant="gradient" type="button" color="secondary">
                                voltar
                            </MDButton>
                            <MDButton
                                onClick={() => {
                                    dadosVenda?.id > 0 ? handleClickConcluirVenda() : handleClickGravar()
                                }}
                                variant="gradient"
                                type="submit"
                                //type="button" 
                                color="info">
                                {dadosVenda?.id > 0 ? 'CONCLUIR' : 'SALVAR'}
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </DialogActions>
            </Dialog >
        </>
    );
}