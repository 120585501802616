import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import FormField from "componentes/FormField";
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import { useContext } from "react";
import MessagesContext from "hooks/MessagesContext";
import { copiaProfundaObjetos } from "commons/utils";


function PrintColunas(props) {

    const { colunas } = props;

    return (
        <Box component="thead" sx={{ backgroundColor: '#2222', WebkitPrintColorAdjust: 'exact' }} >
            {/* <TableHead> */}
            <TableRow >
                {colunas?.map((item) => (
                    (!item.disabled) &&
                    <TableCell
                        key={`tc${item.id}`}
                        align={item.align ? item.align : 'left'}
                        width={item?.sx?.width || 0}
                        sx={{ ...item.sx }}
                    >
                        {item.label}
                    </TableCell>
                ))}
            </TableRow>
        </Box>
    );
}

export default function TabelaAlteracaPreco(props) {

    const { dataset, colunas, linhas, setLinhas, } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso } = messageContext;

    const alteraValor = (valor, campo, index) => {

        const linhastemp = [...linhas];

        linhastemp[index][campo] = valor.replace(',', '.');
        setLinhas(linhastemp);
    }

    const handleBlurValor = async (campo, index) => {
        //ao sair do campo chamo a rota de alteracao do valor do campo
        //debugger;
        console.log('linhas**********************', linhas[index])
        let url;
        let retorno;

        let valor_custo = parseFloat(linhas[index].valor_custo);
        let percentual_margem = parseFloat(linhas[index].percentual_margem);
        let valor_venda = parseFloat(linhas[index].valor_venda);
        let referencia = linhas[index].referenciamaterial;

        if (campo === 'valor_venda') {
            //se alterar o valor de venda entao preciso alterar a margem em cima do custo

            /*
            valor_custo    100%
             valor_venda    Y
             
             y=(valor_venda*100)/valor_custo
            */
            if (valor_venda > 0) {
                if (valor_custo > 0) {
                    percentual_margem = ((100 * valor_venda) - (100 * valor_custo)) / valor_custo;
                    percentual_margem = percentual_margem.toFixed(2);
                }
                else percentual_margem = 0;
            } else
                percentual_margem = 0
        }

        //campo "virtual"
        let preco_sugerido = valor_custo + (valor_custo * percentual_margem) / 100;
        preco_sugerido = preco_sugerido.toFixed(2);

        try {
            if (parseInt(linhas[index].produto_preco_id, 10) > 0) {
                if (campo === 'valor_custo') {
                    url = `${rotafechada()}produto_grades/${linhas[index].produto_grade_id}`;
                    retorno = await api.put(url, {
                        valor_custo,
                    });
                }
                if (campo === 'referenciamaterial') {
                    url = `${rotafechada()}produtos/${linhas[index].produto_id}`;
                    retorno = await api.put(url, {
                        referencia,
                    });
                }
                else {
                    url = `${rotafechada()}produto_precos/${linhas[index].produto_preco_id}`;
                    retorno = await api.put(url, {
                        percentual_margem,
                        valor_venda,
                    });
                }
            } else
                if (parseInt(linhas[index].servico_id, 10) > 0) {
                    if (campo === 'valor_custo') {
                        url = `${rotafechada()}servicos/${linhas[index].servico_id}`;
                        retorno = await api.put(url, {
                            valor_custo,
                        });
                    }
                    else {
                        url = `${rotafechada()}servico_precos/${linhas[index].servico_preco_id}`;
                        retorno = await api.put(url, {
                            percentual_margem,
                            valor_venda,
                        });
                    }
                }

            const linhasTemp = linhas;
            linhasTemp[index].valor_custo = valor_custo;
            linhasTemp[index].percentual_margem = percentual_margem;
            linhasTemp[index].valor_venda = valor_venda;
            linhasTemp[index].preco_sugerido = preco_sugerido;
            setLinhas(linhasTemp);

            MensagemAviso('Alterado');

        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <PrintColunas
                        colunas={colunas || []}
                    />

                    <TableBody>
                        {linhas?.map((row, indexlinha) => {
                            return (
                                <TableRow
                                    hover
                                    tabIndex={-1}
                                    key={`tr${indexlinha}`}
                                    sx={{ ...row.sx }}
                                >
                                    {
                                        colunas?.map((item, indexcoluna) => {
                                            return (
                                                (!item.disabled) &&
                                                <TableCell
                                                    align={item.align ? item.align : 'left'}
                                                    //padding={item.disablePadding ? 'none' : 'normal'}
                                                    key={`tr${indexlinha}tc${indexcoluna}`}
                                                    sx={{ ...row.sx }}
                                                >
                                                    {(item.id === 'valor_custo') ?
                                                        <FormField
                                                            type='text'
                                                            label=''
                                                            rows={1}
                                                            multiline={false}
                                                            InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                                            sx={{ width: 100 }}
                                                            value={row[item.id]}
                                                            onChange={(e) => alteraValor(e.target.value, 'valor_custo', indexlinha)}
                                                            inputProps={{
                                                                onBlur: (e) => {
                                                                    e.preventDefault();
                                                                    handleBlurValor('valor_custo', indexlinha);
                                                                },
                                                            }}

                                                        />
                                                        :
                                                        (item.id === 'valor_venda') ?
                                                            <FormField
                                                                type='text'
                                                                label=''
                                                                rows={1}
                                                                multiline={false}
                                                                InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                                                sx={{ width: 100 }}
                                                                value={row[item.id]}
                                                                onChange={(e) => alteraValor(e.target.value, 'valor_venda', indexlinha)}
                                                                inputProps={{
                                                                    onBlur: (e) => {
                                                                        e.preventDefault();
                                                                        handleBlurValor('valor_venda', indexlinha);
                                                                    },
                                                                }}
                                                            />
                                                            :
                                                            (item.id === 'percentual_margem') ?
                                                                <FormField
                                                                    type='text'
                                                                    label=''
                                                                    rows={1}
                                                                    multiline={false}
                                                                    InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                                                                    sx={{ width: 100 }}
                                                                    value={row[item.id]}
                                                                    onChange={(e) => alteraValor(e.target.value.replace(/[^.,0-9]/g, ''), 'percentual_margem', indexlinha)}
                                                                    //onChange={(e) => setValor(e.target.value.replace(/[^.,0-9]/g, ''))}
                                                                    inputProps={{
                                                                        onBlur: (e) => {
                                                                            e.preventDefault();
                                                                            handleBlurValor('percentual_margem', indexlinha);
                                                                        },
                                                                    }}
                                                                />
                                                                :
                                                                row[item.id]
                                                    }
                                                </TableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}