import React, { Component, useState } from 'react';
import { Stage, Layer, Rect, Text, Shape, Circle, Line, Image, Transformer, Arrow } from 'react-konva';


const CotaVertical = ({ shapeProps, isSelected, onSelect, onChange }) => {

    const cotaRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            trRef.current.nodes([cotaRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>

            {shapeProps.texto ?
                <Text
                    {...shapeProps.texto}
                />
                : false
            }
            <Arrow
                onClick={onSelect}
                onTap={onSelect}
                ref={cotaRef}
                {...shapeProps}
                draggable
                onDragEnd={(e) => {

                    const x = parseInt(e.target.x(), 10);
                    const y = parseInt(e.target.y(), 10);
                    const alturaseta = parseInt(e.target.attrs.points[3], 10)

                    shapeProps.texto.x = x - 25;
                    shapeProps.texto.y = y + alturaseta / 2;

                    onChange({
                        ...shapeProps,
                        x,
                        y,
                    });
                }}


                onTransformEnd={(e) => {

                    //posicional inicial da seta apos o redimensionamento
                    const x = parseInt(e.target.x(), 10);
                    const y = parseInt(e.target.y(), 10);
                    const alturaseta = parseInt(e.target.attrs.points[3], 10);

                    const node = cotaRef.current;
                    const scaleY = node.scaleY();

                    //redimensionando a seta com uma nova largura
                    const novaalturaseta = parseInt(alturaseta * scaleY, 10);
                    const points = [0, 0, 0, novaalturaseta];
                    const rotacao = node.rotation();

                    //zerando a escala
                    node.scaleX(1);
                    node.scaleY(1);

                    onChange({
                        ...shapeProps,
                        x,
                        y,
                        points,
                        rotation: rotacao,
                        texto: {
                            ...shapeProps.texto,
                            rotation: 270 + rotacao,
                            x: x - 25,
                            y: y + parseInt(novaalturaseta / 2, 10)
                        }
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};

export default CotaVertical;