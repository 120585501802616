import React, { useContext, useEffect, useState } from "react";

//My componentes e functions
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import TabelaCategoria from "./tabelaCategoria";

function Tabela(props) {
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const { handleClickExcluir, abrirRegistro, recarregaTabela, tipo, handleClickNovaDespesa,
    handleClickNovaReceita, handleClickCriaFilho} = props;
  const [linhas, setLinhas] = React.useState([]);

  const baseUrl = `${rotafechada()}categoria`;
  const baseCaption = "Categoria";

  const buscaDados = async () => {
    if (props.validaRotaPermissao) {
      if ((await props.validaRotaPermissao("listar")) === false) {
        return;
      }
    }

    try {
      let complementoUrl = "";

      if (tipo) {
        complementoUrl = `${complementoUrl}&tipo=${tipo}`;
      }

      //complementoUrl = encodeURI(complementoUrl)

      MostraTelaEspera(`Buscando ${baseCaption}`);

      const url = `${baseUrl}/listarordenado?${complementoUrl}`;
      const retorno = await api.get(encodeURI(url));
      const { data, countAll: countAllretorno } = retorno.data;
      const dadostemp = [];

      //console.log("DATA:",data)

      //mapeando os dados para a "rows"
      data.map(async (item, index) => {
        dadostemp.push({
          id: item.id,
          nome: item.nome,
          plano_conta_id: item?.verificador
            ? ""
            : item.Plano_Conta?.nome
              ? item.Plano_Conta.nome
              : "",
          categoria_id: item.categoria_id,
          nome_categoria: item.Categorium?.nome ? item.Categorium.nome : "",
          tipo: item.tipo,
          mae: item?.filho ? true : false,
        });
      });

      setLinhas(dadostemp);
      FechaTelaEspera();
    } catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  };

  useEffect(() => {
    buscaDados();
  }, [recarregaTabela]);



  return (
    <>
      <TabelaCategoria
        linhas={linhas}
        handleClickExcluir={handleClickExcluir}
        abrirRegistro={abrirRegistro}
        tipo={tipo}
        handleClickNovaReceita={handleClickNovaReceita}
        handleClickNovaDespesa={handleClickNovaDespesa}
        handleClickCriaFilho={handleClickCriaFilho}
      />
    </>
  );
}

export default Tabela;
