
import React, { useContext, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Individual from "./Individual";
import Geral from "./Geral";
import EstatisticaGeralModal from "./GeralModal";
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import DialogoConfirma from 'componentes/DialogoConfirma';
import { AppBar, Tab, Tabs } from "@mui/material";
import TabPanel from "componentes/Tabpanel";
import { useTrataRetorno } from "commons/authUtils";



function EstatisticaCs(props) {

  const trataRetorno = useTrataRetorno();
  const { empresaAtual } = props;
  const [usuarioAtual, setUsuarioAtual] = useState('');
  const messageContext = useContext(MessagesContext);
  const { MensagemErro } = messageContext;
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const [aba, setAba] = useState(0);

  const baseUrl = `${rotafechada()}empresas`;
  const baseCaption = 'Estatisticas CS';

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${baseUrl}/permissao/${operacao}`);
      return true;
    }
    catch (error) {
      trataRetorno(error?.response?.data?.erro);
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }

  useEffect(async () => {

    if (parseInt(usuarioAtual, 10) > 0) {
      if (await validaRotaPermissao('editar') === false) {
      }
    }
    else {
      if (await validaRotaPermissao('inserir') === false) {
      }
    }


  }, [])

  const handleChangeTab = (event, newValue) => {

    // if (newValue !== 0) {
    //   if (!parseInt(protocolo, 10) > 0) {
    //     return false
    //   }
    //   setEscondeObservacao(true);
    // }
    // else setEscondeObservacao(false);

    setAba(newValue);
  };

  return (
    <>

      <DialogoConfirma
        open={dialogoOpen.visible}
        title={dialogoOpen.title}
        message={dialogoOpen.message}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
      />

      <MDBox mt={0} mb={3} width="100%">
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={12}>
            <MDBox mt={0} mb={3} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  {`${baseCaption}`}
                </MDTypography>
              </MDBox>
            </MDBox>

            {/*Abas tabela */}

            <Card>

              <AppBar position="static">
                <Tabs value={aba} onChange={handleChangeTab}>
                  <Tab label="Individual" {...a11yProps(0)} />
                  <Tab label="Geral" {...a11yProps(1)} />
                </Tabs>

              </AppBar>

              <TabPanel value={aba} index={0}>{/* Individual */}
                <MDBox width="100%" display="flex" justifyContent="space-between">
                  <Individual
                    empresaAtual={empresaAtual}
                  />
                </MDBox>
              </TabPanel>
              {/* 
              <TabPanel value={aba} index={1}>{/* Geral }
                <MDBox width="100%" display="flex" justifyContent="space-between">
                  <Geral
                    empresaAtual={empresaAtual}
                  />
                </MDBox>
              </TabPanel> */}

              <TabPanel value={aba} index={1}>{/* Geral Modal */}
                <MDBox width="100%" display="flex" justifyContent="space-between">
                  <EstatisticaGeralModal
                    empresaAtual={empresaAtual}
                    setAba={setAba}
                  />
                </MDBox>
              </TabPanel>

            </Card>

          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default EstatisticaCs;
