import { useContext } from "react";

// react-router-dom components
import {  useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import AtivaCadastroLayout from "./Layout";
import bgImage from "assets/images/personalizados/headerlogin.png";

import { rotaaberta } from '../../commons/urlconf';
import api from '../../commons/api';
import MessageContext from "../../hooks/MessagesContext";


function AtivaCadastro() {


  const messageContext = useContext(MessageContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

  const { token } = useParams();
  let navigate = useNavigate();


  const handleClickAtivar = async (e) => {
    e.preventDefault();

    try {
      MostraTelaEspera('Ativando cadastro');
      await api.post(`${rotaaberta()}ativacadastro`,
        {
          token: token
        });
      FechaTelaEspera();
  
      MensagemAviso('Ativação efetuada com Sucesso!')
  
      navigate('/login');
    }
    catch (error){
      FechaTelaEspera();
      const msgerro = error.response?.data?.msg || error;
      MensagemErro(`Erro na tentativa de ativar o cadastro: ${msgerro}`);
      return;
    }
  }

  return (
    <AtivaCadastroLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Ativação de Cadastro
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">

            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleClickAtivar}>
                Ativar acesso
              </MDButton>
            </MDBox>

          </MDBox>
        </MDBox>
      </Card>
    </AtivaCadastroLayout>
  );
}

export default AtivaCadastro;
