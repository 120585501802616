import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import MDBox from 'components/MDBox';


function EnhancedTableHead(props) {
  const { headCells, onChangeAllCheckBox } = props;

  const [checkado, setCheckado] = React.useState(false);

  return (

    <MDBox component="thead" bgColor='grey-200' >
      {/* <TableHead> */}

      <TableRow>
        {headCells?.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            <Typography
              variant="h6"
              component="div"
            >
              {
                (index === 0) && <input type='checkbox' id='selecionado' name='selecionado' checked={checkado}
                  onChange={
                    (e) => {
                      setCheckado(e.target.checked);
                      onChangeAllCheckBox(e.target.checked);
                    }
                  }
                  style={{ marginRight: 4 }} />
              }
              {headCell.label}
            </Typography>

          </TableCell>
        ))}
      </TableRow>
    </MDBox>


  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,

  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function TabelaEtapa1(props) {

  const { linhas: rows, colunas: headCells, onChangeCheckBox, onChangeAllCheckBox } = props;



  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size='small'
          >
            <EnhancedTableHead
              headCells={headCells}
              onChangeAllCheckBox={onChangeAllCheckBox}
            />
            <TableBody>
              {rows?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={`tr${index}`}
                  >
                    {
                      headCells.map((item, indexcoluna) => {

                        return (
                          <TableCell
                            align={item.numeric ? 'right' : 'left'}
                            padding={item.disablePadding ? 'none' : 'normal'}
                            key={`tc${indexcoluna}`}
                          >

                            {
                              indexcoluna === 0 &&
                              <input type='checkbox' id='selecionado' name='selecionado' style={{marginRight:4}} checked={row.selecionado} onChange={(e) => onChangeCheckBox(index, e.target.checked)} />
                            }

                            {row[item.id]}
                          </TableCell>
                        )
                      })
                    }
                  </TableRow>
                );
              })}

            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default React.memo(TabelaEtapa1);