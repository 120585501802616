import { Card, Collapse, Grid, IconButton, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MDBox from "components/MDBox";
import { useContext, useEffect, useState } from "react";
import MDButton from "components/MDButton";
import MessagesContext from "hooks/MessagesContext";
import DialogoConfirma from "componentes/DialogoConfirma";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";
import FormField from "componentes/FormField";
import CancelIcon from '@mui/icons-material/Cancel';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { formatDate } from "commons/utils";
import { ptBR } from "date-fns/locale";


export default function PagarContasLoteAdmin(props) {

    const { openCollapsePagarContasLote, handleClickCollapsePagarContasLote } = props;
    const messageContext = useContext(MessagesContext);
    const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;


    const [dataInicial, setDataInicial] = useState(new Date());
    const [dataFinal, setDataFinal] = useState(new Date());
    const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
    const [empresas, setEmpresas] = useState({ value: -1, label: '<Escolha uma empresa>' });
    const [bancoCaixa, setBancoCaixa] = useState({ value: -1, label: '<Escolha uma conta financeira>' });
    const [formaPagamento, setFormaPagamento] = useState({ value: -1, label: '<Escolha forma de pagamento>' });
    const [optionEmpresas, setOptionEmpresas] = useState([
        { value: -1, label: "<Escolha uma empresa>" },
    ]);
    const [optionBancoCaixa, setOptionBancoCaixa] = useState([
        { value: -1, label: "<Escolha uma conta financeira>" },
    ]);
    const [optionFormaPagamento, setOptionFormaPagamento] = useState([
        { value: -1, label: "<Escolha forma de pagamento>" },
    ]);

    const buscaDados = async () => {

        try {

            MostraTelaEspera(`Buscando`);

            const url = `${rotafechada()}empresas?all=true&limit=200`;
            //console.log("***URL forma de pagamento:", url);
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesEmpresasTemp = [{ value: -1, label: '<Escolha uma empresa>' }];

            data.map((item) => {
                opcoesEmpresasTemp.push({ value: item.id, label: `${item.id}-${item.razaosocial}/${item.fantasia}` });
            });
            setOptionEmpresas(opcoesEmpresasTemp);
            FechaTelaEspera();

            return opcoesEmpresasTemp;


        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const buscaBancoCaixa = async () => {
        try {

            MostraTelaEspera(`Buscando`);

            const url = `${rotafechada()}banco?empresa_id=${empresas.value}`;
            //console.log("***URL banco/caixa:", url);
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesBancoCaixaTemp = [{ value: -1, label: '<Escolha uma conta financeira>' }];

            data.map((item) => {
                opcoesBancoCaixaTemp.push({ value: item.id, label: `${item.empresa_id}-${item.nome}` });
            });
            setOptionBancoCaixa(opcoesBancoCaixaTemp);
            FechaTelaEspera();

            return opcoesBancoCaixaTemp;


        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const buscaFormaPagamento = async () => {
        try {

            MostraTelaEspera(`Buscando`);

            const url = `${rotafechada()}forma_pagamento`;
            //console.log("***URL banco/caixa:", url);
            let retorno = await api.get(encodeURI(url));
            const { data } = retorno.data;
            let opcoesFormaPagamentoTemp = [{ value: -1, label: '<Escolha forma de pagamento>' }];

            data.map((item) => {
                opcoesFormaPagamentoTemp.push({ value: item.id, label: item.nome });
            });
            setOptionFormaPagamento(opcoesFormaPagamentoTemp);
            FechaTelaEspera();

            return opcoesFormaPagamentoTemp;


        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleClickPagarContasLote = async () => {

        setDialogoOpen({ ...dialogoOpen, visible: false });

        try {
            MostraTelaEspera('Aguarde o processamento');

            let complementoUrl = '';

            // if (empresas && bancoCaixa) {
            //     console.log("bancoCaixa:", empresas)
            //     console.log("Valor padrão:", bancoCaixa)
            //     complementoUrl = `${complementoUrl}&valor_padrao=${valorPadrao}`;
            //     complementoUrl = `${complementoUrl}&parametro_id=${parametros.value}`;
            // }

            const dados = {
                empresa_id: empresas.value,
                banco_id: bancoCaixa.value,
                forma_pagamento_id: formaPagamento.value,
                dataInicial: new Date(formatDate(dataInicial, "DD/MM/YYYY 00:00:00")),
                dataFinal: new Date(formatDate(dataFinal, "DD/MM/YYYY 23:59:59"))
            }

            await api.post(`${rotafechada()}/parcelas_conta/pagarcontaslote`,dados)

            //await api.get(`${rotafechada()}admin/empresas/ajustabasefinanceiro?empresa_id=1${complementoUrl}`, { });
            FechaTelaEspera();
            MensagemAviso('Ajuste processado com Sucesso!');
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }

    const handleConfirmDialogo = () => {

        if (dialogoOpen.funcao === 'pagarContasLote') {
            handleClickPagarContasLote();
        }
    }

    const handleClickPermissoes = () => {
        setDialogoOpen({
            ...dialogoOpen,
            visible: true,
            id: null,
            title: 'Ajuste na base para entrada do Financeiro',
            message: `Certeza que deseja quitar todas contas da empresa: ${empresas.label} com vencimento no período 
            de ${formatDate(dataInicial, "DD/MM/YYYY")} até ${formatDate(dataFinal, "DD/MM/YYYY")} na conta financeira ${bancoCaixa.label} ?`,
            funcao: 'pagarContasLote'
        });
    }

    useEffect(() => {
        setBancoCaixa(optionBancoCaixa[0])
        if (empresas.value > 0)
            buscaBancoCaixa();
    }, [empresas])


    useEffect(() => {
        buscaDados();
        buscaFormaPagamento();
    }, [])


    return (
        <>
            <DialogoConfirma
                open={dialogoOpen.visible}
                title={dialogoOpen.title || ''}
                message={dialogoOpen.message || ''}
                handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
                handleConfirm={handleConfirmDialogo}
            />
            <Card id="ajustta-base-financeiro" sx={{ overflow: "visible" }}>
                <MDBox p={2} bgColor='grey-100' mt={2}>
                    <IconButton aria-label="expand row" size="small" onClick={() => handleClickCollapsePagarContasLote(!openCollapsePagarContasLote)}>
                        {openCollapsePagarContasLote ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        <Typography variant='h6' mb={2}>Pagar contas em lote por empresa</Typography>
                    </IconButton>

                    <Collapse in={openCollapsePagarContasLote} timeout="auto" unmountOnExit>
                        <Grid container spacing={1}>

                            <Grid item xs={12} sm={12}> {/* Observação */}
                                <Typography variant='caption' mb={2}>Obs. Após selecionado empresa, conta financeira e periodo ele irá
                                    quitar todas as contas em aberto no banco/caixa especifiado.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} mb={2}> {/* Empresas */}
                                <AutoCompleteStyled
                                    disableClearable //Retira o X (clear)
                                    options={optionEmpresas}
                                    getOptionLabel={(option) => option.label}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    renderInput={(params) => <FormField {...params} label='Empresa' required />}
                                    value={empresas}
                                    onChange={(event, newValue) => {
                                        setEmpresas(newValue);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4}> {/* Banco/Caixa */}
                                <AutoCompleteStyled
                                    disableClearable //Retira o X (clear)
                                    options={optionBancoCaixa}
                                    getOptionLabel={(option) => option.label}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    renderInput={(params) => <FormField {...params} label='Conta financeira' required />}
                                    value={bancoCaixa}
                                    onChange={(event, newValue) => {
                                        setBancoCaixa(newValue);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3}> {/* Forma de pagamento */}
                                <AutoCompleteStyled
                                    disableClearable //Retira o X (clear)
                                    options={optionFormaPagamento}
                                    getOptionLabel={(option) => option.label}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    renderInput={(params) => <FormField {...params} label='Forma de pagamento' required />}
                                    value={formaPagamento}
                                    onChange={(event, newValue) => {
                                        setFormaPagamento(newValue);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={2.5}> {/*Periodo inicio*/}
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                                    <DesktopDatePicker
                                        label='Data Inicial'
                                        inputFormat="dd/MM/yyyy"
                                        value={dataInicial}
                                        onChange={(e) => setDataInicial(e)}
                                        renderInput={(params) => <FormField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} sm={2.5}> {/*Periodo fim*/}
                                <LocalizationProvider dateAdapter={AdapterDateFns} >
                                    <DesktopDatePicker
                                        label='Data Final'
                                        inputFormat="dd/MM/yyyy"
                                        value={dataFinal}
                                        onChange={(e) => setDataFinal(e)}
                                        renderInput={(params) => <FormField {...params} />}
                                    />
                                </LocalizationProvider>

                            </Grid>

                            <Grid item xs={12} sm={10}>

                            </Grid>

                            <Grid item xs={12} sm={2} mb={5} mt={2}> {/* Botão */}
                                <MDButton
                                    disabled = {empresas.value == -1 || formaPagamento.value == -1 || bancoCaixa.value == -1 ? true : false}
                                    color='info'
                                    onClick={handleClickPermissoes}
                                >
                                    Executar
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Collapse>
                </MDBox>
            </Card>
        </>
    )
}