import React, { useContext, useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MessagesContext from 'hooks/MessagesContext';
import AuthContext from "hooks/AuthContext";
import { useForm, useWatch } from 'react-hook-form';
import MDButton from 'components/MDButton';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import Cabecalho from './cabecalho';
//import DadosPrecos from './precos';
import { Icon } from "@mui/material";
import id from 'date-fns/esm/locale/id/index.js';
import { useNavigate } from 'react-router-dom';
import Gen_Forma_Pagamento_Turma from 'pages/geneducacao/gen_forma_pagamento_turma';

function Cadastro(props) {


  const navigate = useNavigate();

  const { turmaAtual, setTurmaAtual, setAba } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const { handleSubmit, control, setValue, getValues, setFocus } = useForm({});
  const indice = 0;
  
  const [opcoesAtivo, setOpcoesAtivo] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'S', label: 'Sim' },
    { value: 'N', label: 'Não' },
  ]);
  
  

  const onSubmit = (data) => {
    handleClickGravar(data);
  }

  const limpaInputs = () => {
    setValue('id', '');
    setValue('nome', '');
    setValue('cidade', '');
    setValue(`ativo`, { value: 'S', label: 'Sim' });
    setValue('valor', '');
    setValue('texto_adicional', '');
    setValue('clausula_opcional', '');
    setValue('data_inicio', null);
    setValue('data_termino', null);
    setValue('data_inicio_matricula', null);
    setValue('data_termino_matricula', null);
    setValue('observacao', '');
    setValue('arquivo_contrato', '');
    setValue('email_confirmacao', '');
    // tabelapreco.map((item, key) => {
    //   setValue(`turma_precos[${key}].id`, '');
    //   setValue(`turma_precos[${key}].percentual_margem`, '');
    //   setValue(`turma_precos[${key}].sugerido`, 0);
    //   setValue(`turma_precos[${key}].valor_venda`, '');
    //   setValue(`turma_precos[${key}].ativo`, false);
    // })

  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;

    if (!data.nome) {
      camposObrigatorios = `${camposObrigatorios}/Nome`
    }


    if (!data.cidade) {
      camposObrigatorios = `${camposObrigatorios}/Cidade`
    }

    if (!data.valor) {
      camposObrigatorios = `${camposObrigatorios}/Valor`
    }

    if ((!data.ativo) || ((data.ativo) !== 'S') && (data.ativo !== 'N')) {
      camposObrigatorios = `${camposObrigatorios}/Ativo`
    }

    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const handleClickGravar = async (data) => {

    //tratando os campos autocomplete
    data.ativo = data.ativo?.value ? data.ativo.value : '';

    try {
      validaCampos(data);
    }
    catch (error) {
      MensagemErro(`${error.message}`);
      return;
    }

    try {
      const dados = { empresa_id: sessaolocal.empresa_id || null };

      //pegando do OBJ do RHF apenas os campos que sao do serviço
      dados.id = turmaAtual || '';
      dados.nome = data.nome;
      dados.cidade = data.cidade;
      dados.ativo = data.ativo;
      dados.valor = `${data.valor}`.replace(',', '.');
      dados.texto_adicional = data.texto_adicional;
      dados.clausula_opcional = data.clausula_opcional;
      dados.data_inicio = data.data_inicio;
      dados.data_termino = data.data_termino;
      dados.data_inicio_matricula = data.data_inicio_matricula;
      dados.data_termino_matricula = data.data_termino_matricula;
      dados.observacao = data.observacao;
      dados.arquivo_contrato = data.arquivo_contrato;
      dados.email_confirmacao = data.email_confirmacao;

      /*dados.turma_precos = []
      data.turma_precos.map((item, index) => {
        const obj = {}
        obj.id = item.id || '';
        obj.empresa_id = sessaolocal.empresa_id || null
        obj.turma_id = data.id || '';
        obj.tabela_preco_id = item.tabela_preco_id || '';
        obj.percentual_margem = `${item.percentual_margem}`.replace(',', '.') || '';
        obj.valor_venda = `${item.valor_venda}`.replace(',', '.') || '';
        if (item.ativo === true) {
          obj.ativo = 'S';
        } else obj.ativo = 'N';

        dados.turma_precos.push(obj);
      })
      */

      let retorno;
      let codigoturma = '';

      if (parseInt(turmaAtual, 10) > 0) {
        retorno = await api.put(`${rotafechada()}gen_turmas/${turmaAtual}`, dados);
        codigoturma = turmaAtual;
        //setRecarregaComponenteOpcoes(!recarregaComponenteOpcoes);
      }
      else {
        delete dados.id;
        retorno = await api.post(`${rotafechada()}gen_turmas`, dados);
        codigoturma = retorno.data.data.id;
      }

      MensagemAviso(`Gravado com sucesso! Turma: ${codigoturma}`);

      //se for um novo serviço entao "recarrego" a pagina
      if (codigoturma !== turmaAtual) {
        setTurmaAtual(codigoturma);
      }
    }
    catch (error) {
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const novoturma = async () => {
    limpaInputs();
    setTurmaAtual('');
  }

  const abrirturma = async () => {
    //console.log('abrir turma:', turmaAtual);
    try {
      let url = `${rotafechada()}gen_turmas?id=${turmaAtual}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      if (!data.length > 0) {
        MensagemErro(`Turma ${turmaAtual} não localizada`);
        return;
      }
      limpaInputs();

      setValue('id', data[0].id);
      setValue('nome', data[0].nome);
      setValue('cidade', data[0].cidade);
      setValue(`ativo`, { value: data[0].ativo === 'S' ? 'S' : 'N', label: data[0].ativo === 'S' ? 'Sim' : 'Não' });
      setValue('valor', `${data[0].valor}`.replace('.', ','));

      setValue('texto_adicional', data[0].texto_adicional);
      setValue('clausula_opcional', data[0].clausula_opcional);
      setValue('data_inicio', data[0].data_inicio);
      setValue('data_termino', data[0].data_termino);
      setValue('data_inicio_matricula', data[0].data_inicio_matricula);
      setValue('data_termino_matricula', data[0].data_termino_matricula);
      setValue('observacao', data[0].observacao);
      setValue('arquivo_contrato', data[0].arquivo_contrato);
      setValue('email_confirmacao', data[0].email_confirmacao);

      //setRecarregaComponenteOpcoes(!recarregaComponenteOpcoes);
      //buscaTabelasPrecos();

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickVoltar = () => {
    setTurmaAtual('');
    setAba(0);
  }

  useEffect(async () => {
    //await buscaDatasets();
    if (parseInt(turmaAtual, 10) > 0) {
      await abrirturma();
    }
    else {
      await novoturma();
    }
  }, [turmaAtual])

  return (
    <MDBox p={2}>
      <MDBox width="100%" display="flex" justifyContent="space-between">
        <MDButton
          variant="gradient"
          color="secondary"
          type="button"
          onClick={handleClickVoltar}
        >Voltar</MDButton>

        <MDButton
          variant="gradient"
          circular
          color="info"
          type="button"
          onClick={novoturma}
        >
          <Icon>add</Icon>
        </MDButton>
      </MDBox>


      <form onSubmit={handleSubmit(onSubmit)}>
        <Cabecalho
          control={control}
          opcoesAtivo={opcoesAtivo}
          setValue={setValue}
          getValues={getValues}

        />
        <MDBox width="100%" display="flex" justifyContent="flex-end" mt={2}>
          <MDButton
            variant="gradient"
            color="info"
            type="submit"
          >
            Gravar
          </MDButton>

        </MDBox>
      </form>

      {
        ((turmaAtual) && (parseInt(turmaAtual, 10) > 0)) ?
          <Gen_Forma_Pagamento_Turma
            turmaAtual={turmaAtual}
            setFocus={setFocus}
            control={control}
            getValues={getValues}
            setValue={setValue}
            
            handleSubmit={handleSubmit}
          />
          : false
      }



    </MDBox>
  )

}

export default Cadastro;
