import React, { useContext, useEffect, useState } from "react";

// @mui material components
import { Collapse, Grid, MenuItem, Menu, ListItemIcon, } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//My componentes e functions
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import FormField from "componentes/FormField";
import { Box } from "@mui/system";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";
import TabelaPadrao from "componentes/TabelaPadrao";
import { formataValor, formataFloat, formatDate, retornaSomenteNumeros } from "commons/utils";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';



function Tabela(props) {

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
    const { handleClickExcluir, abrirRegistro, recarregaTabela, openFiltrosAvancados,
        handleClickInformarRecebimento, handleClickDetalheParcela, handleClickEditarParcela,
        handleClickDetalheConta, handleClickEditarConta, handleClickEmitirRecibo, dataFinalFiltro, dataInicialFiltro } = props;

    const [countAll, setCountAll] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('vencimento');
    const [colunasTable, setColunasTable] = React.useState([]);
    const [linhas, setLinhas] = React.useState([]);
    const [idParcela, setIdParcela] = useState(0);
    const [idConta, setIdConta] = useState(0);
    const [situacaoParcela, setSituacaoParcela] = useState('');

    /*****************Menu Item*******************/
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
        setOpen(true)
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false)
    };


    // *****States relacionado do filtro de pesquisa ****
    const [campoPesquisa, setCampoPesquisa] = React.useState({ id: 'nome', label: 'Descrição', buscaParcial: true });
    const [valorPesquisa, setValorPesquisa] = React.useState('');
    const [camposFiltro, setCamposFiltro] = useState([
        { id: 'nome', label: 'Descrição', buscaParcial: true },
        { id: 'fantasia', label: 'Fantasia/Razão Social', buscaParcial: true },
        { id: 'valor_total', label: 'Valor total' },
        //{ id: 'id', label: 'Id' },
    ]);
    const opcoesFiltroSituacao = ([
        { value: '', label: 'Todos' },
        { value: 'PAGO', label: 'Recebido' },
        { value: 'EM ABERTO', label: 'Em Aberto' },
    ]);
    const [filtroSituacao, setFiltroSituacao] = useState();
    // ****************************

    const baseUrl = `${rotafechada()}parcelas_conta`;
    const baseCaption = 'Parcelas a receber';

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 'none',
            //backgroundColor: '#1A73E8',
            //color: 'rgb(255, 255, 255)',
            //boxShadow: theme.shadows[1],
            //fontSize: 12,
        },
    }));

    const buscaDados = async () => {
        if (props.validaRotaPermissao) {
            if (await props.validaRotaPermissao('listar') === false) {
                return;
            }
        }

        try {

            let complementoUrl = '';

            if ((campoPesquisa.id) && (valorPesquisa)) {
                complementoUrl = `&${campoPesquisa.id}=${valorPesquisa}`;

                if (campoPesquisa.id === 'id') {
                    const temp = retornaSomenteNumeros(valorPesquisa);
                    if ((`${temp}`.length !== `${valorPesquisa}`.length) ||
                        (!(parseInt(`${valorPesquisa}`, 10) > 0))) {
                        MensagemErro('A pesquisa por ID aceita só números, escolha outro campo para busca');
                        return;
                    }
                }

                if ((valorPesquisa) && (valorPesquisa.length > 0) && (valorPesquisa[0] !== '%') && (valorPesquisa[valorPesquisa.length - 1] !== '%')
                    && (camposFiltro) && (camposFiltro.length > 0)) {

                    //se o texto a ser buscado nao terminar ou começar com %
                    //e tiver uma configuracao no camposFiltro
                    //entao localizo que o campo usado nesse momento é um campo que esteja configurado
                    //o buscaParcial, se sim acrescento automaticamente o % no inicio e fim


                    let encontrouCampo = false;
                    for (let i = 0; i < camposFiltro.length; i++) {

                        if ((camposFiltro[i].buscaParcial === true) && (camposFiltro[i].id === campoPesquisa.id)) {
                            encontrouCampo = true;
                        }
                    }

                    if (encontrouCampo === true) {
                        complementoUrl = `&${campoPesquisa.id}=%${valorPesquisa}%`;
                    }
                }
            }

            if (page > 0) {
                complementoUrl = `${complementoUrl}&offset=${page * rowsPerPage}`;
            }

            if (orderBy) {
                complementoUrl = `${complementoUrl}&order=${orderBy}`;
                if (order) {
                    complementoUrl = `${complementoUrl}&direction=${order}`;
                }
            }

            if (filtroSituacao) {
                complementoUrl = `${complementoUrl}&situacao_parcela=${filtroSituacao.value}`;
            }

            //complementoUrl = `${complementoUrl}&tipo_categoria=R`; //Receita

            //complementoUrl = encodeURI(complementoUrl)

            MostraTelaEspera(`Buscando ${baseCaption}`);

            // const url = `${baseUrl}/situacao?limit=${rowsPerPage}&tipo='D'${complementoUrl}`;
            const url = `${baseUrl}/situacao?tipo=R&data1=${new Date(dataInicialFiltro)}&data2=${new Date(dataFinalFiltro)}&limit=${rowsPerPage}${complementoUrl}`;
            //console.log("URL", url)

            const retorno = await api.get(encodeURI(url));
            const { data, countAll: countAllretorno } = retorno.data;
            const dadostemp = [];

            const colunasTabletemp = [
                // {
                //     id: 'id',
                //     numeric: false,
                //     disablePadding: true,
                //     label: 'id'
                // },
                // {
                //     id: 'conta_id',
                //     numeric: false,
                //     disablePadding: true,
                //     label: 'Conta'
                // },
                {
                    id: 'vencimento',
                    numeric: false,
                    disablePadding: true,
                    label: 'Vencimento'
                },
                {
                    id: 'recebimento',
                    numeric: false,
                    disablePadding: true,
                    label: 'Recebimento',
                    disableOrder: true
                },
                {
                    id: 'descricao',
                    numeric: false,
                    disablePadding: true,
                    label: 'Descrição',
                    disableOrder: true
                },
                {
                    id: 'total',
                    numeric: false,
                    disablePadding: true,
                    label: 'Total(R$)',
                    disableOrder: true
                },
                {
                    id: 'areceber',
                    numeric: false,
                    disablePadding: true,
                    label: 'Receber(R$)',
                    disableOrder: true,
                },
                {
                    id: 'situacao',
                    numeric: false,
                    disablePadding: true,
                    label: 'Situação',
                    disableOrder: true
                },
                {
                    id: 'acoes',
                    numeric: false,
                    disablePadding: true,
                    label: '',
                    disableOrder: true
                },
            ]

            //mapeando os dados para a "rows"
            data.map((item, index) => {
                dadostemp.push({
                    id: item.id_parcela,
                    conta_id: item.id_conta,
                    vencimento: formatDate(new Date(item.vencimento), 'DD/MM/YYYY'),
                    recebimento: formatDate(new Date(item.pagamento), 'DD/MM/YYYY'),
                    descricao: <>
                        <CustomWidthTooltip
                            title={<>
                                <p align='left'>
                                    {item.descricao}<br></br>
                                    Categoria: {item.qtd_categoria_rateada > 0 ? item.categorias_raterio : item.categoria.categoria} <br></br>
                                    Fornecedor: {`${item.pessoa?.razao_social} - ${item.pessoa?.fantasia}`}
                                </p>
                            </>}
                            placement="top"
                            disableInteractive
                        >
                            <Box>
                                <MDBox width='100%' display='flex' justifyContent="flex-start" flexDirection='row'>
                                    <MDTypography variant="body2" >{item.descricao}</MDTypography>
                                </MDBox>
                                <MDBox width='100%' display='flex' justifyContent="flex-start" flexDirection='row'>
                                    <MDTypography variant="caption" >{item.qtd_categoria_rateada > 0 ? `${item.qtd_categoria_rateada} Categorias` : item.categoria.categoria} - {item.pessoa?.razao_social}</MDTypography>
                                </MDBox>
                            </Box>
                        </CustomWidthTooltip>
                    </>,
                    total: formataValor(item.total).replace("R$", ""),
                    areceber: formataValor(item.saldo).replace("R$", ""),
                    situacao:
                        <MDBox display="flex" alignText={'center'} width="120px" justifyContent={'center'}
                            sx={{ background: `${item.situacao == 'PAGO' ? '#bce2be' : item.situacao == 'EM ABERTO' ? '#ffd59f' : '#aecef7'}` }}
                            borderRadius="sm"
                            p={0.5}
                        >
                            <MDTypography fontWeight="medium" variant="caption" textTransform="capitalize"
                            //color={item.situacao == 'PAGO' ? 'success' : item.situacao == 'EM ABERTO' ? 'warning' : 'info'}
                            >
                                {`${item.situacao == 'PAGO' ? 'RECEBIDO' : item.situacao == 'EM ABERTO' ? 'EM ABERTO' : 'RECEBIDO PARCIAL'}`}
                            </MDTypography>
                        </MDBox>,
                    acoes: <>
                        <MDButton
                            variant="outlined"
                            color="info"
                            size="small"
                            borderRadius="sm"
                            onClick={(e) => {
                                setSituacaoParcela(item.situacao)
                                setIdParcela(item.id_parcela)
                                setIdConta(item.id_conta)
                                setAnchorEl(e.currentTarget);
                                setOpen(true)
                            }}
                            sx={{ borderRadius: '4px', height: '25px', minHeight: '12px' }}
                            disableElevation
                            endIcon={<KeyboardArrowDownIcon />}
                        >
                            ações
                        </MDButton>

                    </>
                })
            })

            setColunasTable([...colunasTabletemp]);
            setLinhas(dadostemp);
            setCountAll(countAllretorno);
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`Erro: ${msg}`);
        }
    }


    useEffect(() => {
        buscaDados();
    }, [recarregaTabela, page, rowsPerPage, order, orderBy])

    const handleClickPesquisar = () => {
        setPage(0);
        buscaDados();
    }

    const desenhaFiltro = () => {
        return (
            <Collapse in={openFiltrosAvancados} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                    < Grid container spacing={2} justifyContent="center" >
                        <Grid item xs={12} sm={3} >
                            <AutoCompleteStyled
                                options={opcoesFiltroSituacao}
                                getOptionLabel={(option) => option.label}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                renderInput={(params) => <FormField {...params} label='Situação' />}
                                value={filtroSituacao}
                                onChange={(event, newValue) => {
                                    setFiltroSituacao(newValue);
                                }}
                                defaultValue={{ value: '', label: 'Todos' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={9} >
                        </Grid>
                        <Grid item xs={12} sm={3}   >{/*Filtro Campo*/}
                            <AutoCompleteStyled
                                options={camposFiltro}
                                getOptionLabel={(option) => option.label}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                renderInput={(params) => <FormField {...params} label='Campo' />}
                                value={campoPesquisa}
                                onChange={(event, newValue) => {
                                    setCampoPesquisa(newValue);
                                }}
                                defaultValue={null}
                            />
                        </Grid>
                        <Grid item xs={12} sm={7}   >{/*Valor a ser Pesquisado*/}
                            <FormField type="text" label="Valor a ser Pesquisado" value={valorPesquisa} onChange={(e) => setValorPesquisa(e.target.value)}

                                inputProps={
                                    {
                                        onKeyPress: (e) => {

                                            if (e.key === 'Enter') {
                                                handleClickPesquisar();
                                            }
                                        },
                                    }
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} >{/*Botão do Filtro*/}
                            <MDButton onClick={handleClickPesquisar} color='info'> Pesquisar</MDButton>
                        </Grid>
                    </Grid >
                </Box>
            </Collapse>
        )
    }

    return (
        <>

            <Menu

                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    dense={true}
                    disabled={situacaoParcela == 'PAGO' ? true : false}
                    onClick={() => {
                        handleClickInformarRecebimento(idParcela)
                        setAnchorEl(null);
                        setOpen(false)
                    }}>
                    Informar recebimento
                </MenuItem>
                <MenuItem
                    dense={true}
                    onClick={() => {
                        handleClickDetalheParcela(idParcela)
                        setAnchorEl(null);
                        setOpen(false)
                    }}>
                    Ver detalhes da parcela
                </MenuItem>
                <MenuItem
                    dense={true}
                    onClick={() => {
                        handleClickEditarParcela(idParcela)
                        setAnchorEl(null);
                        setOpen(false)
                    }}>
                    Editar parcela
                </MenuItem>
                <MenuItem
                    dense={true}
                    onClick={() => {
                        handleClickDetalheConta(idParcela)
                        setAnchorEl(null);
                        setOpen(false)
                    }}>
                    Ver detalhes da conta
                </MenuItem>
                <MenuItem
                    dense={true}
                    onClick={() => {
                        handleClickEditarConta(idConta)
                        setAnchorEl(null);
                        setOpen(false)
                    }}>
                    Editar conta
                </MenuItem>
                <MenuItem
                    dense={true}
                    disabled={situacaoParcela == 'EM ABERTO' ? true : false}
                    onClick={() => {
                        handleClickEmitirRecibo(idParcela)
                        setAnchorEl(null);
                        setOpen(false)
                    }}>
                    Emitir recibo
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem
                    dense={true}
                    onClick={() => {
                        handleClickExcluir(idParcela, situacaoParcela)
                        setAnchorEl(null);
                        setOpen(false)
                    }}>
                    <ListItemIcon>
                        <DeleteIcon />
                    </ListItemIcon>
                    Excluir
                </MenuItem>
            </Menu>

            <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                    {desenhaFiltro()}
                </MDTypography>
            </MDBox>

            <TabelaPadrao
                colunas={colunasTable}
                linhas={linhas}
                countAll={countAll}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                order={order}
                setOrder={setOrder}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
            />
        </>


    );
}

export default Tabela;