import React, { useContext, useEffect, useState } from 'react';
import { Autocomplete, Checkbox, FormControl, MenuItem, NativeSelect, Radio, Select, Switch, TextField } from '@mui/material';
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import AuthContext from "hooks/AuthContext";

export default function ParametroTipo(props) {

    const { parametro } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const baseUrl = `${rotafechada()}parametro_empresas`;
    const baseCaption = 'Parâmetro';

    const [valor, setValor] = useState('');

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();

    const alteraParametro = async (valor = '') => {

        try {
            MostraTelaEspera(`Alterando ${baseCaption}`);
            const url = `${baseUrl}`;
            const retorno = await api.post(`${baseUrl}/altera`, {
                parametro_id: parametro.id,
                empresa_id: sessaolocal.empresa_id,
                valor
            })

            setValor(valor);
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }
    }

    const handleChangeSwitch = (e) => {

        let valor = 'NAO';
        if (e.target.checked) {
            valor = 'SIM';
        }

        alteraParametro(valor);
    }

    const handleChangeText = () => {
        alteraParametro(valor);
    };

    const handleChangeSelect = (e) => {
        alteraParametro(e.target.value);
    };

    useEffect(() => {

        parametro.Parametro_Empresas?.length > 0 ?
            setValor(parametro.Parametro_Empresas[0].valor)

            :
            setValor(parametro.valor || '');
    }, [parametro])

    return (
        <>
            {
                (`${parametro?.tipo}`.toUpperCase() === 'BOOLEAN') ?
                    <Switch
                        label={''}
                        disabled={false}
                        checked={valor === 'SIM' ? true : false}
                        onChange={(e) => handleChangeSwitch(e)}
                        color="default"
                    />
                    :
                    (`${parametro?.tipo}`.toUpperCase() === 'OPCOES') ?
                        <Select
                            value={valor}
                            fullWidth
                            onChange={handleChangeSelect}
                            label=''
                            sx={{ ml: 1 }}

                        >
                            {
                                parametro?.opcoes?.split('|').map((item, index) => {
                                    return (
                                        <MenuItem value={item}>{item}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                        :
                        (`${parametro?.tipo}`.toUpperCase() === 'MEMO') ?
                            <TextField //opcao default é o text
                                type='text'
                                label=''
                                rows={5}
                                multiline={true}
                                required={false}
                                disabled={false}
                                value={valor}
                                onChange={(e) => setValor(e.target.value)}
                                fullWidth
                                inputProps={{
                                    onKeyUp: (e) => {
                                        if (e.key === 'Enter') {
                                            handleChangeText();
                                        }
                                    },
                                    onBlur: (e) => {
                                        e.preventDefault();
                                        handleChangeText();
                                    },
                                }}

                            />
                            :
                            <TextField //opcao default é o text
                                type='text'
                                label=''
                                rows={1}
                                multiline={false}
                                required={false}
                                disabled={false}
                                value={valor}
                                onChange={(e) => setValor(e.target.value)}
                                fullWidth
                                inputProps={{
                                    onKeyUp: (e) => {
                                        if (e.key === 'Enter') {
                                            handleChangeText();
                                        }
                                    },
                                    onBlur: (e) => {
                                        e.preventDefault();
                                        handleChangeText();
                                    },
                                }}

                            />
            }
        </>
    )

}





