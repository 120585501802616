import { Box, Dialog, DialogTitle, Icon, Tooltip, Typography } from '@mui/material';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import React, { Component, useState } from 'react';
import { CompactPicker } from 'react-color';
import { Stage, Layer, Rect, Text, Shape, Circle, Line, Image, Transformer, Arrow } from 'react-konva';


export const PropriedadesCirculo = (props) => {

    const { itensTela, setItensTela, selectedId, open, setOpen } = props;

    const [corAtual, setCorAtual] = useState('#FFF');


    const handleChangeColor = (color, event) => {
        const temp = itensTela.slice();
        temp[selectedId].fill = color.hex;
        temp[selectedId].stroke = color.hex;
        setCorAtual(color.hex);
        setItensTela(temp);
    }

    const handleChangeEspessuraBorda = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].strokeWidth = parseInt(e.target.value, 10) || 1;
        setItensTela(temp);
    }

    const handleChangeRadius = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].radius = parseInt(e.target.value, 10) || 1;
        setItensTela(temp);
    }


    const handleChangeX = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].x = parseInt(e.target.value, 10) || 1;
        setItensTela(temp);
    }

    const handleChangeY = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].y = parseInt(e.target.value, 10) || 1;
        setItensTela(temp);
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            {/* <DialogTitle>{'Círculo'}</DialogTitle> */}
            <Box m={2}>
                <Typography variant='h6'>Cor de Fundo</Typography>
                <CompactPicker onChange={handleChangeColor} />

                <Box>
                    <Typography variant='h6' mt={2}>Sem Fundo</Typography>
                    <Tooltip title='Sem Fundo'>
                        <MDButton sx={{ ml: 2 }} onClick={
                            (e) => {
                                e.preventDefault();
                                const temp = itensTela.slice();
                                temp[selectedId].fill = null;
                                setItensTela(temp);
                            }
                        }>
                            <Icon fontSize='large'>border_outer</Icon>
                        </MDButton>
                    </Tooltip>
                    <MDInput
                        label='Espessura da borda'
                        type='number'
                        value={itensTela[selectedId].strokeWidth || 1}
                        onChange={handleChangeEspessuraBorda}
                        sx={{width:140,mr:2}}
                    />

                    <MDInput
                        label='Raio'
                        type='number'
                        value={itensTela[selectedId].radius || 1}
                        onChange={handleChangeRadius}
                        sx={{width:145}}
                    />
                </Box>

                <Box mt={2}>
                    <MDInput
                        label='Posição do eixo X'
                        type='number'
                        value={itensTela[selectedId].x}
                        onChange={handleChangeX}
                        sx={{ mr: 2 }}
                    />
                    <MDInput
                        label='Posição do eixo Y'
                        type='number'
                        value={itensTela[selectedId].y}
                        onChange={handleChangeY}
                    />
                </Box>
            </Box>

        </Dialog>
    )

}

export const Circulo = ({ shapeProps, isSelected, onSelect, onChange }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>
            <Circle
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...shapeProps}
                draggable
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: parseInt(e.target.x(), 10),
                        y: parseInt(e.target.y(), 10),
                    });
                }}
                onTransformEnd={(e) => {
                    // transformer is changing scale of the node
                    // and NOT its width or height
                    // but in the store we have only width and height
                    // to match the data better we will reset scale on transform end
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);
                    debugger;
                    onChange({
                        ...shapeProps,
                        x: parseInt(node.x(), 10),
                        y: parseInt(node.y(), 10),
                        radius: parseInt((node.radius() * scaleX),10)
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
}

