import React, { useContext, useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import MessagesContext from 'hooks/MessagesContext';
import AuthContext from "hooks/AuthContext";
import { useForm, useWatch } from 'react-hook-form';
import MDButton from 'components/MDButton';
import { rotafechada } from 'commons/urlconf';
import api from 'commons/api';
import Cabecalho from './cabecalho';


function Cadastro(props) {

  const { usuarioAtual, setUsuarioAtual, recarregaTabela, setRecarregaTabela } = props;
  const messageContext = useContext(MessagesContext);
  const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const { handleSubmit, control, setValue, getValues, setFocus } = useForm({});
  const [vetorPerfis, setVetorPerfis] = useState([]) 

  const [opcoesAtivo, setOpcoesAtivo] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'S', label: 'Sim' },
    { value: 'N', label: 'Não' },
  ]);

  const [recarregaComponenteOpcoes, setRecarregaComponenteOpcoes] = useState(false);

  const onSubmit = (data) => {
    handleClickGravar(data);
  }

  const limpaInputs = () => {
    setValue('id', '');
    setValue(`ativo`, { value: 'S', label: 'Sim' });
    setValue(`nome`, '');
    setValue(`email`, '');
  }

  const validaCampos = (data) => {

    let camposObrigatorios = ``;

    if (!data.nome) {
      camposObrigatorios = `${camposObrigatorios}/Nome`
    }
    if (!data.email) {
      camposObrigatorios = `${camposObrigatorios}/Email`
    }

    if (!data.ativo) {
      camposObrigatorios = `${camposObrigatorios}/Ativo`
    }

    if (camposObrigatorios !== '') {
      throw new Error(`Campos obrigatórios: ${camposObrigatorios}`)
    }
  }

  const handleClickGravar = async (data) => {
    //tratando os campos autocomplete
    data.ativo = data.ativo?.value ? data.ativo.value : '';
    

    try {
      validaCampos(data);
    }
    catch (error) {
      MensagemErro(`${error.message}`);
      return;
    }

    try {
      const dados = { empresa_id: sessaolocal.empresa_id || null };

      //pegando do OBJ do RHF apenas os campos que sao do usuário
      dados.ativo = data.ativo;
      dados.nome = data.nome;
      dados.email = data.email;
      dados.reservado = 'N';
      
      dados.perfil_acesso_id =[];
      vetorPerfis.map((item)=>{
        if (item.selecionado){
          dados.perfil_acesso_id.push(item.id)
        }
      })

      debugger;
      if (dados.perfil_acesso_id.length===0){
        MensagemErro('É necessário escolher pelo menos um perfil para o usuário');
        return;
      }

      let retorno;
      let codigoUsuario = '';
      
      if (parseInt(usuarioAtual, 10) > 0) {
        dados.id = usuarioAtual
        //console.log("Dados antes de enviar para o back",dados);
        let url = `${rotafechada()}empresa_usuarios/full`
        retorno = await api.post(url, dados);

        setRecarregaComponenteOpcoes(!recarregaComponenteOpcoes);
        setRecarregaTabela(!recarregaTabela);

      }
      else {
        MostraTelaEspera('Gravando usuário'); //Abre tela de espera
        retorno = await api.post(`${rotafechada()}empresa_usuarios/full`, dados);
        FechaTelaEspera(); //Fecho tela de espera
        codigoUsuario = retorno.data.data.id;
        setRecarregaTabela(!recarregaTabela);
      }

      MensagemAviso(`Gravado com sucesso!`);
      //se for um novo produto entao "recarrego" a pagina
      if (codigoUsuario !== usuarioAtual) {
        setUsuarioAtual(codigoUsuario);
      }

    }
    catch (error) {
      FechaTelaEspera(); //Fecho tela de espera
      console.log(error);
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const novousuario = async () => {
    limpaInputs();
    setUsuarioAtual('');
  }

  const abrirUsuario = async () => {
    try {
      let url = `${rotafechada()}empresa_usuarios?id=${usuarioAtual}`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      if (!data.length > 0) {
        MensagemErro(`Usuário ${usuarioAtual} não localizada`);
        return;
      }
      limpaInputs();

      setValue('id', data[0].id);
      setValue(`ativo`, { value: data[0].ativo === 'S' ? 'S' : 'N', label: data[0].ativo === 'S' ? 'Sim' : 'Não' });
      setValue('nome', data[0].nome);
      setValue('email', data[0].email);
      setRecarregaComponenteOpcoes(!recarregaComponenteOpcoes);

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  useEffect(async () => {
    //await buscaDatasets();
    if (parseInt(usuarioAtual, 10) > 0) {
      await abrirUsuario();
    }
    else {
      await novousuario();
    }
  }, [usuarioAtual])

  return (
    <MDBox p={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Cabecalho
          control={control}
          opcoesAtivo={opcoesAtivo}
          getValues={getValues}
          usuarioAtual={usuarioAtual}
          vetorPerfis={vetorPerfis}
          setVetorPerfis={setVetorPerfis}
        />
        <MDBox width="100%" display="flex" justifyContent="flex-end">
          <MDButton variant="gradient" color="info" type="submit">
            Gravar
          </MDButton>
          <MDBox ml={2}>
            <MDButton variant="gradient" color="info" onClick={novousuario}>
              Novo
            </MDButton>
          </MDBox>
        </MDBox>
      </form>
    </MDBox>
  )
}

export default Cadastro;
