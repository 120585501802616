import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { Checkbox } from '@mui/material';
import MDTypography from 'components/MDTypography';


export default function DialogoConfirmaValidacao(props) {

  const { open, message, validacao, handleCancel, handleConfirm, title, color } = props;

  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div>

      <Dialog
        open={open}
        onClose={() => {
          handleCancel()
          setChecked(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}<strong>{'Lembre-se esta ação não poderá ser desfeita.'}</strong>
            <MDBox pt={1} width="100%" display="flex" justifyContent='flex-start' alignItems='center' flexDirection='row'>
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <MDTypography onClick={()=> {
                setChecked(!checked)
                //handleChange()
              }} variant="caption" fontWeight="bold" sx={{ cursor: "pointer" }}>
                {validacao}
              </MDTypography>
            </MDBox>

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            onClick={() => {
              handleCancel()
              setChecked(false)
            }}
            variant="outlined"
            color="secondary">
            Cancelar
          </MDButton>
          <MDButton onClick={() => {
            handleConfirm()
            setChecked(false)
          }}
            disabled={checked === false ? true : false} variant="gradient" color={color ? color : 'info'} sx={{ height: "100%" }}>
            Confirmar
          </MDButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
