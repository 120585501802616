import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import MDBox from 'components/MDBox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Icon, IconButton } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import MDButton from 'components/MDButton';


/*
colunas = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Id'
  },
  {
    id: 'nome',
    numeric: false,
    disablePadding: true,
    label: 'Nome'
  },
  {
    id: 'ativo',
    numeric: false,
    disablePadding: true,
    label: 'Ativo',
    disableOrder: false
  },
  {
    id: 'acoes',
    numeric: false,
    disablePadding: true,
    label: 'Ações',
    disableOrder: true
  },
]

linhas = [
  {
    id:10,
    nome:'XXX',
    ativo: 'S',
    acoes:<button/>
  }
]
*/



function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort, headCells } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (

    <MDBox component="thead" bgColor='grey-200' >
      {/* <TableHead> */}

      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >

            {

              headCell.disableOrder === true ?

                <Typography
                  variant="h6"
                  component="div"
                >
                  {headCell.label}
                </Typography>
                :

                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  <Typography
                    variant="h6"
                    component="div"
                  >
                    {headCell.label}
                  </Typography>

                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
            }
          </TableCell>
        ))}
      </TableRow>
    </MDBox>


  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,

  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function Row(props) {
  const { row, headCells } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {
          headCells.map((item, index) => {
            return (
              ((item.id !== 'collapse') && (item.id !== 'botoes')) ?
                <TableCell
                  align={item.numeric ? 'right' : 'left'}
                  padding={item.disablePadding ? 'none' : 'normal'}
                  key={`tc${index}`}
                >
                  {row[item.id]}
                </TableCell>
                : false
            )
          })
        }
        <TableCell>
          <MDButton size="medium" onClick={() => props.abrirRegistro(row.id)} iconOnly >
            <Icon fontSize="medium" color="success">edit_icon</Icon>
          </MDButton>
          <MDButton size="medium" onClick={() => props.handleClickExcluir(row.id)} iconOnly >
            <Icon fontSize="medium" color="error">delete_forever</Icon>
          </MDButton>
        </TableCell>
      </TableRow>
    </React.Fragment >
  );
}

function TabelaVendas(props) {

  const { linhas: rows, colunas: headCells, countAll, page, setPage, rowsPerPage, setRowsPerPage, order, setOrder, orderBy, setOrderBy } = props;

  const handleRequestSort = (event, property) => {
    //property é o ID da coluna que sera usada
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size='small'
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}

              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {rows.map((row) => (
                <Row key={row.id} row={row} headCells={headCells}
                  handleClickExcluir={props.handleClickExcluir}
                  abrirRegistro={props.abrirRegistro}
                />
              ))}
            </TableBody>

          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={countAll}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage='Linhas por Página'
        />
      </Paper>
    </Box>
  );
}

export default React.memo(TabelaVendas);