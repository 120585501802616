import React, { useState, useEffect, useContext } from 'react';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Icon, Typography } from "@mui/material";

//My componentes e functions
import GenericForm from 'componentes/GenericForm';

function Cadastro(props) {

    const { dataset, handleGravar, setAba } = props;

    return (
        <MDBox p={2}>
            <MDBox width="100%" display="flex" justifyContent="space-between">
                <MDButton
                    variant="gradient"
                    color="secondary"
                    type="button"
                    onClick={() => setAba(0)}
                >Voltar</MDButton>
                <MDButton
                    variant="gradient"
                    circular
                    color="info"
                    type="button"
                    onClick={props.handleClickNovo}
                >
                    <Icon>add</Icon>
                </MDButton>
            </MDBox>
            <MDBox>
                <MDBox mb={3}>
                    <Typography variant='h6' ></Typography>
                </MDBox>
                <GenericForm
                    setCodigoAbrir={props.setCodigoAbrir}
                    permiteAlteracao={true}
                    funcaoGravar={handleGravar} //precisa retornar um boolean
                    campoPrimaryKey={'id'}
                    dataset={dataset}
                />
            </MDBox>

        </MDBox>
    )

}

export default Cadastro;