import { Autocomplete, Button, Checkbox, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import api from 'commons/api';
import { rotafechada } from 'commons/urlconf';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import MessagesContext from 'hooks/MessagesContext';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import MDInput from 'components/MDInput';
import { AutoCompleteStyled } from 'componentes/AutoCompleteStyled';
import FormField from 'componentes/FormField';

const TabelaProdutos = (props) => {

    const { objFull, control, setValue, getValues } = props;
    const messageContext = useContext(MessagesContext);
    const { MensagemErro } = messageContext;


    useEffect(() => {
    }, [])

    return (

        <Grid container>
            <Grid item xs={12} sm={12} mb={2}>
                <Typography component="div" variant='h6'>Produtos que não possuem a cor escolhida</Typography>
            </Grid>

            <Grid item xs={12} sm={12} >
                <TableContainer>
                    <Table size='small'>
                        <MDBox component="thead" bgColor='grey-200' >
                            <TableRow>
                                <TableCell>Tipo</TableCell>
                                <TableCell>Nome</TableCell>
                                <TableCell>Observação</TableCell>
                                <TableCell>Cor</TableCell>
                            </TableRow>
                        </MDBox>
                        {
                            objFull.produtos.map((item, index) => {
                                return (
                                    //Se o produto estiver "incluido" e 
                                    // produto_grade_id nao foi escolhido ele lista na table
                                    //com as cores disponiveis
                                    (
                                        (getValues(`projetos.produtos[${index}].incluido`) === true)
                                        &&
                                        (!((getValues(`projetos.produtos[${index}].produto_grade_id`)?.value || 0) > 0))
                                    ) ?
                                        <TableBody>
                                            <TableRow >
                                                <TableCell>{item?.Produto?.Tipos_Produto.nome || ''}</TableCell>
                                                <TableCell>{`${item?.Produto?.id} - ${item?.Produto?.nome}`}</TableCell>
                                                <TableCell>{item?.observacao}</TableCell>
                                                <TableCell>
                                                    <Controller
                                                        name={`projetos.produtos[${index}].produto_grade_id`}
                                                        control={control}
                                                        defaultValue={{ value: -1 }}
                                                        render={({ field: { onChange, ...props } }) => (
                                                            <AutoCompleteStyled
                                                                options={item.produtogradeautocomplete || [{}]}
                                                                getOptionLabel={(option) => option.label || ''}
                                                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                                                renderInput={(params) => <FormField {...params} label='Cor' />}
                                                                onChange={(_, data) => {
                                                                    return onChange(data);
                                                                }}
                                                                disabled={false}
                                                                {...props}

                                                            />
                                                        )}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                        : false
                                )

                            })
                        }
                    </Table>
                </TableContainer>
            </Grid>
        </Grid >
    )
}

const ProdutosCorProjetos = (props) => {


    const { control, setValue, getValues, vendaAtual,
        handleSubmit, etapaAtual, setEtapaAtual, objFull, setObjFull, proximaEtapa, desenhaCabecalhoPadrao } = props;

    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;
    const opcaoDefault = [{ id: -1, value: -1, label: '<Selecione...>' }];

    //inicia o botao desativado para evitar um  click antes do Useeffect que valida
    //se tem produtos a corrigir, porque se nao tiver a etapa ja passa sozinha
    const [disabledBotaoProximaEtapa, setDisabledBotaoProximaEtapa] = useState(true);

    function gravarTeste(data) {
        console.log('objfull-original:', objFull);
        console.log('obj-RHF:', data);
    }

    const validaProximaEtapa = () => {

        //verificando se todos os produtos tem um produto_grade_id
        //preenchido

        let erro = false;
        let msgerro = '';
        objFull.produtos?.map((item, index) => {

            if (getValues(`projetos.produtos[${index}].incluido`) === true) {

                const produto_grade_id = getValues(`projetos.produtos[${index}].produto_grade_id`)?.value || 0;

                if (!(produto_grade_id > 0)) {
                    msgerro = msgerro === '' ? item.Produto.nome : `${msgerro} | ${item.Produto.nome}`;
                    erro = true;
                }
            }
        })

        if (erro === true) {
            MensagemErro(`Existem produtos sem ter a cor escolhida: ${msgerro}`);
            return;
        }

        proximaEtapa();
    }

    const etapaAnterior = () => {
        setEtapaAtual(etapaAtual - 1);
    }

    const verificaSeTemProdutosACorrigir = async () => {
        let contproduto = 0;
        objFull.produtos.map((item, index) => {
            if ((getValues(`projetos.produtos[${index}].incluido`) === true) &&
                (!((getValues(`projetos.produtos[${index}].produto_grade_id`)?.value || 0) > 0))) {
                contproduto++;
            }
        });

        if (contproduto === 0) {
            validaProximaEtapa();
        }
        else setDisabledBotaoProximaEtapa(false);//libero o botao
    }

    useEffect(() => {
        verificaSeTemProdutosACorrigir();
    }, [])

    return (
        <>

            {desenhaCabecalhoPadrao('Cor dos Produtos - ')}
            <MDBox width="100%" display="flex" mb={4}>
                <MDTypography variant='button'>Escolha a cor para os produtos baixos</MDTypography>
            </MDBox>
            <MDBox width="100%" display="flex" mt={2}>
                <TabelaProdutos
                    objFull={objFull}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                />
            </MDBox>

            <MDBox width="100%" display="flex" justifyContent="space-between" mt={2}>
                <MDButton variant="gradient" color="info" onClick={etapaAnterior} sx={{ ml: 2 }}>Retornar</MDButton>
                {
                    (props.parametrosVenda.mostra_botao_obj || false) &&
                    <MDButton variant="gradient" color="warning" onClick={() => handleSubmit(gravarTeste)()} sx={{ ml: 2 }}>Mostra OBJ</MDButton>
                }
                <MDButton variant="gradient" color="info" disabled={disabledBotaoProximaEtapa} onClick={validaProximaEtapa} sx={{ ml: 2 }}>Avançar</MDButton>
            </MDBox>
        </>
    )
}

export default ProdutosCorProjetos;
