import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
//import FormField from "layouts/pages/account/components/FormField";
import FormField from 'componentes/FormField';

// Data
import MDButton from "components/MDButton";
import { useContext, useEffect, useState } from "react";
import { rotafechada } from "commons/urlconf";
import api from "commons/api";
import MessagesContext from "hooks/MessagesContext";
import AuthContext from "hooks/AuthContext";
import ImagemLogoEmpresa from "./componentes/imagemlogoempresa";
import { Typography, IconButton, Icon } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import TabelaEmpresasAdmin from "./tabela";
import { Box } from "@mui/system";
import MenuOpcoesEmpresa from "./componentes/MenuOpcoesEmpresa";
import UsuariosAdmin from "./usuarios";
import Assinatura from "./assinatura";
import EstatisticaCs from "./estatisticacs";
import DialogoConfirma from "componentes/DialogoConfirma";
import BancodeDadosAdmin from "./bancodedados";
import TabPanel from "componentes/Tabpanel";
import PesquisaDinamica from "componentes/PesquisaDinamica";
import PesquisaPreContrato from "./componentes/pesquisaprecontratos";
import { retiraEspacos } from "commons/utils";
import { AutoCompleteStyled } from "componentes/AutoCompleteStyled";
import { useTrataRetorno } from "commons/authUtils";

const camposEmpresa = {
  id: '',
  tipo_empresa_id: '',
  tipo_contrato_id: '',
  fisica_juridica: '',
  razaosocial: '',
  fantasia: '',
  cnpj: '',
  inscricao_estadual: '',
  inscricao_municipal: '',
  endereco_rua: '',
  endereco_numero: '',
  endereco_bairro: '',
  endereco_complemento: '',
  endereco_cep: '',
  //endereco_cidade: '',
  //endereco_estado: '',
  //endereco_cidade_id: '',
  codigo_erp: '',
  ativo: { value: 'N', label: 'Não' },
}

const camposUsuarioAdmin = {
  nome: '',
  tipo_telefone: '',
  telefone: '',
  email: ''
}


function DadosUsuarioAdmin(props) {

  const { datasetUsuariodmin, setDatasetUsuariodmin, permitido } = props;

  const [opcoesTipoTelefone, setOpcoesTipoTelefone] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'C', label: 'Celular' },
    { value: 'F', label: 'Fixo' },
    { value: 'W', label: 'WhatsApp' },
  ])

  const alteraCampo = (e) => {
    const dados = { ...datasetUsuariodmin };
    dados[e.target.name] = e.target.value;
    setDatasetUsuariodmin(dados);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>{/*Nome contato*/}
        <FormField
          label="Nome do contato"
          disabled={!permitido}
          name='nome'
          value={datasetUsuariodmin.nome}
          onChange={alteraCampo}
        />
      </Grid>
      <Grid item xs={12} sm={6}>{/*Email*/}
        <FormField
          label="Email"
          disabled={false}
          name='email'
          value={datasetUsuariodmin.email}
          onChange={alteraCampo}
        />
      </Grid>
      <Grid item xs={12} sm={4}>{/*Tipo Telefone*/}
        <AutoCompleteStyled
          options={opcoesTipoTelefone}
          getOptionLabel={(option) => option.label || ''}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          //renderInput={(params) => <MDInput {...params} variant="standard" label='Tipo do contato' />}
          renderInput={(params) => <FormField {...params} label="Tipo" InputLabelProps={{ shrink: true }} />}
          value={datasetUsuariodmin.tipo_telefone}
          onChange={(event, newValue) => {
            setDatasetUsuariodmin({ ...datasetUsuariodmin, tipo_telefone: newValue })
          }}
          disabled={!permitido}
          {...props}
        />
      </Grid>
      <Grid item xs={12} sm={8}>{/*Telefone*/}
        <FormField
          label="Telefone"
          disabled={false}
          name='telefone'
          value={datasetUsuariodmin.telefone}
          onChange={alteraCampo}
        />
      </Grid>


    </Grid>
  )
}

function CadastroEmpresaAdmin(props) {

  const baseUrl = `${rotafechada()}empresas`;

  const { empresaAtual, setEmpresaAtual, recarregaTabela, setRecarregaTabela,
    nomeEmpresaAtual, setNomeEmpresaAtual } = props;
  const trataRetorno = useTrataRetorno();
  const fisica_juridica = ["Jurídica", "Física"];
  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const authContext = useContext(AuthContext);
  const { getSessao } = authContext;
  const sessaolocal = getSessao();
  const [datasetEmpresas, setDatasetEmpresas] = useState({ ...camposEmpresa });
  const [datasetUsuariodmin, setDatasetUsuariodmin] = useState({ ...camposUsuarioAdmin });
  const [permitido, setPermitido] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');

  const opcaoDefaultCidadePessoa = { value: -1, label: '<Selecione uma cidade>', uf: '' };
  const [ufAtual, setUfAtual] = useState({ value: -1, label: '<Selecione uma opção>' });
  const [cidadeAtual, setCidadeAtual] = useState(opcaoDefaultCidadePessoa);

  const [opcoesTiposEmpresas, setOpcoesTiposEmpresas] = useState([{ value: -1, label: '<Selecione uma opção>' }]);
  const [opcoesTiposContratos, setOpcoesTiposContratos] = useState([{ value: -1, label: '<Selecione uma opção>' }]);
  const [opcoesCidade, setOpcoesCidade] = useState([opcaoDefaultCidadePessoa]);
  const [opcoesEstado, setOpcoesEstado] = useState([
    { value: -1, label: '<Selecione uma opção>' },
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceara' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ]);

  const [aba, setAba] = useState(0);
  const [openMenuConfiguracao, setOpenMenuConfiguracao] = useState(false);
  const [currentTargetBotaoMenu, setCurrentTargetBotaoMenu] = useState(null);

  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });

  const [openPreContrato, setOpenPreContrato] = useState(false);

  useEffect(async () => {
    if (ufAtual) {
      await BuscaCidades(ufAtual.value ? ufAtual.value : '');
    }
    else {
      setCidadeAtual(opcaoDefaultCidadePessoa)
    }
  }, [ufAtual])

  const BuscaCidades = async (paramUf = '') => {

    if (!paramUf) {
      setCidadeAtual(opcaoDefaultCidadePessoa);
    }

    try {
      let url = `${rotafechada()}cidades?limit=-1&uf=${paramUf}`;
      //URL montada para procurar a cidade de acordo com o estado escolhido
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;
      let opcoesCidadeTemp = [];
      opcoesCidadeTemp.push({ ...opcaoDefaultCidadePessoa });

      data.map((item) => {
        opcoesCidadeTemp.push({ /*id: item.id,*/ value: item.id, label: item.nome, uf: item.uf })
      })

      setOpcoesCidade(opcoesCidadeTemp);

    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }

  }

  const buscaTiposEmpresas = async () => {

    try {
      let url = `${rotafechada()}tipos_empresas?ativo=S`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      let opcoesTiposEmpresasTemp = [];
      opcoesTiposEmpresasTemp.push({ value: -1, label: '<Selecione uma opção>' });

      data.map((item) => {
        opcoesTiposEmpresasTemp.push({ value: item.id, label: item.nome })
      })

      setOpcoesTiposEmpresas(opcoesTiposEmpresasTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }
  const buscaTiposContratos = async () => {

    try {
      let url = `${rotafechada()}tipos_contratos?ativo=S`;
      const retorno = await api.get(encodeURI(url));
      const { data } = retorno.data;

      let opcoesTiposContratosTemp = [];
      opcoesTiposContratosTemp.push({ value: -1, label: '<Selecione uma opção>' });

      data.map((item) => {
        opcoesTiposContratosTemp.push({ value: item.id, label: item.nome })
      })

      setOpcoesTiposContratos(opcoesTiposContratosTemp);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${baseUrl}/permissao/${operacao}`);
      return true;
    }
    catch (error) {
      trataRetorno(error?.response?.data?.erro);
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }

  const novaEmpresa = async () => {
    setDatasetEmpresas({ ...camposEmpresa });
    setEmpresaAtual('');
    setNomeEmpresaAtual('');
    setUfAtual(opcoesEstado[0]);
    setCidadeAtual(opcaoDefaultCidadePessoa);
    setDatasetUsuariodmin({ ...camposUsuarioAdmin });
  }

  const buscaDados = async () => {

    if (!(parseInt(empresaAtual, 10) > 0)) {
      return;
    }

    try {
      const retorno = await api.get(`${baseUrl}?id=${empresaAtual}`);
      const { data } = retorno.data;
      const dados = { ...camposEmpresa };

      Object.keys(camposEmpresa).forEach((nome) => {
        dados[nome] = data[0][nome] ? data[0][nome] : '';
      });

      if (parseInt(data[0].Cidade?.id, 10) > 0) {
        //tem um endereco_cidade_id preenchido (fk para Cidades)
        if (data[0].Cidade?.uf !== '') {
          const estadotemp = opcoesEstado.filter((item) => item.value === data[0].Cidade?.uf);
          if (estadotemp.length > 0) {
            setUfAtual(estadotemp[0]);
            setCidadeAtual({ value: data[0].Cidade?.id, label: data[0].Cidade?.nome, uf: data[0].Cidade?.uf })
          }
        }
      }
      else {
        setUfAtual(opcoesEstado[0]);
        setCidadeAtual(opcaoDefaultCidadePessoa);
      }
      if (parseInt(data[0].Tipos_Empresa?.id, 10) > 0) {
        const tipotemp = opcoesTiposEmpresas.filter((item) => parseInt(item.value, 10) === parseInt(data[0].Tipos_Empresa?.id, 10));
        if (tipotemp.length > 0) {
          dados.tipo_empresa_id = tipotemp[0];
        }
      }

      if (parseInt(data[0].Tipos_Contrato?.id, 10) > 0) {
        const contratotemp = opcoesTiposContratos.filter((item) => parseInt(item.value, 10) === parseInt(data[0].Tipos_Contrato?.id, 10));
        if (contratotemp.length > 0) {
          dados.tipo_contrato_id = contratotemp[0]
        }
      }

      dados.ativo = { value: dados.ativo, label: dados.ativo == 'S' ? 'Sim' : 'Não' };

      setNomeEmpresaAtual(dados.fantasia);
      setDatasetEmpresas(dados);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const gravarEmpresa = async () => {
    try {

      const empresa = {
        tipo_empresa_id: datasetEmpresas.tipo_empresa_id?.value || '',
        tipo_contrato_id: datasetEmpresas.tipo_contrato_id?.value || null,
        fisica_juridica: datasetEmpresas.fisica_juridica || '',
        razaosocial: datasetEmpresas.razaosocial || '',
        fantasia: datasetEmpresas.fantasia || '',
        cnpj: datasetEmpresas.cnpj || '',
        inscricao_estadual: datasetEmpresas.inscricao_estadual || '',
        inscricao_municipal: datasetEmpresas.inscricao_municipal || '',
        endereco_rua: datasetEmpresas.endereco_rua || '',
        endereco_numero: datasetEmpresas.endereco_numero || '',
        endereco_bairro: datasetEmpresas.endereco_bairro || '',
        //endereco_complemento:'',
        endereco_cep: datasetEmpresas.endereco_cep || '',
        endereco_cidade_id: cidadeAtual?.value || '',
        endereco_cidade: cidadeAtual?.label || '',
        endereco_estado: cidadeAtual?.uf || '',
        codigo_erp: datasetEmpresas.codigo_erp || '',
        ativo: datasetEmpresas.ativo?.value || 'N',
      };

      if (parseInt(empresaAtual, 10) > 0) {
        await api.put(`${baseUrl}/${empresaAtual}`, empresa);
        MensagemAviso('Alterado com Sucesso!');
      } else {

        debugger;
        const email_temp = retiraEspacos(`${datasetUsuariodmin.email || ''}`.toLowerCase());

        const contato = {
          nome: datasetUsuariodmin.nome || '',
          tipo_telefone: datasetUsuariodmin.tipo_telefone.value || '',
          telefone: datasetUsuariodmin.telefone || '',
          email: email_temp,
        }
        let dadosfull = {
          empresa,
          contato
        }
        const retorno = await api.post(`${rotafechada()}admin/empresas/full`, dadosfull);
        const { id } = retorno.data.data;
        MensagemAviso('Inserido com Sucesso!');
        setEmpresaAtual(id);

      }

      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const validaAcesso = async () => {
    const retorno = await validaRotaPermissao('listar', true);
    setPermitido(retorno || false);
  }

  useEffect(() => {
    validaAcesso();
    buscaDados();

  }, [empresaAtual])

  useEffect(() => {
    buscaTiposEmpresas();
    buscaTiposContratos();
  }, [])

  const alteraCampo = (e) => {
    const dados = { ...datasetEmpresas };
    dados[e.target.name] = e.target.value;
    setDatasetEmpresas(dados);
  }

  const alteraFisicaJuridica = (newValue) => {
    const dados = { ...datasetEmpresas };
    dados.fisica_juridica = newValue === 'Jurídica' ? 'J' : 'F';
    setDatasetEmpresas(dados);
  }

  const abrirRegistro = async (codigo = '') => {
    setEmpresaAtual(codigo)
  }

  const excluirRegistro = async () => {
    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      const id = dialogoOpen.id
      const retorno = await api.delete(`${rotafechada()}empresas/${id}`);
      MensagemAviso('Excluído com sucesso!');
      setEmpresaAtual('');
      setNomeEmpresaAtual('');
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const reprocessarPermissoes = async () => {
    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      MostraTelaEspera('Aguarde o reprocessamento');
      await api.post(`${rotafechada()}permissoes/processapermissoes`, {
        empresa_id: empresaAtual,
        permissao_id: ''
      });
      FechaTelaEspera();
      MensagemAviso('Permissões reprocessadas com Sucesso!');
    }
    catch (error) {
      FechaTelaEspera();
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickUsuarios = () => {
    if (!(parseInt(empresaAtual, 10) > 0)) {
      MensagemErro('Escolha uma empresa!');
      return;
    }

    setAba(1);
  }

  const handleClickAssinatura = () => {
    if (!(parseInt(empresaAtual, 10) > 0)) {
      MensagemErro('Escolha uma empresa!');
      return;
    }

    setAba(3);
  }

  const handleClickEstatisticaCs = () => {
    if (!(parseInt(empresaAtual, 10) > 0)) {
      MensagemErro('Escolha uma empresa!');
      return;
    }

    setAba(4);
  }

  const handleClickImportaDados = () => {
    if (!(parseInt(empresaAtual, 10) > 0)) {
      MensagemErro('Escolha uma assinatura!');
      return;
    }

    setAba(2);
  }

  const handleClickImportaPreContrato = () => {
    if ((parseInt(empresaAtual, 10) > 0)) {
      MensagemErro('Opção disponível apenas para empresas em cadastramento');
      return;
    }
    setOpenPreContrato(true);
  }

  const handleSelecionaPreContrato = async (id) => {
    setOpenPreContrato(false);

    try {
      const retorno = await api.get(`${rotafechada()}pre_contratos?id=${id}`);
      const { data, count } = retorno.data;

      if (count === 0) {
        MensagemErro(`Pré-Contrato id ${id} não localizado`);
        return;
      }

      const datasettemp = { ...datasetEmpresas };
      const datasetusuariotemp = { ...datasetUsuariodmin };

      datasettemp.tipo_empresa_id = data[0].tipo_empresa_id;
      datasettemp.tipo_contrato_id = data[0].tipo_contrato_id;
      datasettemp.fisica_juridica = data[0].fisica_juridica;
      datasettemp.razaosocial = data[0].razaosocial;
      datasettemp.fantasia = data[0].fantasia;
      datasettemp.cnpj = data[0].cnpj;
      datasettemp.endereco_rua = data[0].endereco_rua;
      datasettemp.endereco_numero = data[0].endereco_numero;
      datasettemp.endereco_bairro = data[0].endereco_bairro;
      datasettemp.endereco_cep = data[0].endereco_cep;
      datasettemp.codigo_erp = data[0].codigo_erp;
      datasettemp.ativo = { value: 'S', label: 'Sim' };

      datasetusuariotemp.nome = data[0].nome_socio;
      datasetusuariotemp.telefone = data[0].telefonecelular_socio;
      datasetusuariotemp.email = data[0].email_socio;
      datasetusuariotemp.tipo_telefone = { value: 'C', label: 'Celular' };

      opcoesTiposEmpresas.map((item) => {
        if (item.value === data[0].tipo_empresa_id) {
          datasettemp.tipo_empresa_id = { ...item }
        }
      });

      opcoesTiposContratos.map((item) => {
        if (item.value === data[0].tipo_contrato_id) {
          datasettemp.tipo_contrato_id = { ...item }
        }
      });

      opcoesEstado.map((item) => {
        if (item.value === data[0].endereco_estado) {
          setUfAtual({ ...item });
        }
      });

      setCidadeAtual({ value: data[0].endereco_cidade_id, label: data[0].endereco_cidade });
      setDatasetEmpresas(datasettemp);
      setDatasetUsuariodmin(datasetusuariotemp);

      // alert(JSON.stringify(data[0]));
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickExcluir = (id) => {
    setDialogoOpen({
      ...dialogoOpen,
      visible: true,
      id,
      title: 'Confirma Exclusão',
      color: 'error',
      message: `Certeza que excluir a empresa ID ${id}?`,
      funcao: 'excluirRegistro'
    });
  }

  const handleConfirmDialogo = () => {
    if (dialogoOpen.funcao === 'excluirRegistro') {
      excluirRegistro();
    }
    else
      if (dialogoOpen.funcao === 'reprocessarPermissoes') {
        reprocessarPermissoes();
      }


  }

  const handleClickPermissoes = () => {
    setDialogoOpen({
      ...dialogoOpen,
      visible: true,
      id: null,
      title: 'Processamento de Permissões',
      message: `Certeza que deseja reprocessar as permissões para a empresa atual?`,
      funcao: 'reprocessarPermissoes'
    });
  }

  return (


    <Card id="cadastro-empresa" sx={{ overflow: "visible" }}>

      <DialogoConfirma
        open={dialogoOpen.visible}
        title={dialogoOpen.title || ''}
        message={dialogoOpen.message || ''}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
        handleConfirm={handleConfirmDialogo}
      />

      <PesquisaPreContrato
        open={openPreContrato || false}
        handleClose={
          () => setOpenPreContrato(false)
        }
        handleClick={handleSelecionaPreContrato}
      />


      <MDBox p={3}>
        <MDTypography variant="h5"
          sx={{ color: datasetEmpresas.ativo?.value === 'S' ? 'black' : 'red' }}


        >{`CONTRATO ${empresaAtual}-${datasetEmpresas.razaosocial} - ${datasetEmpresas.codigo_erp} ${datasetEmpresas.ativo?.value !== 'S' ? '(DESATIVADO)' : ''}`}</MDTypography>
      </MDBox>

      <TabPanel value={aba} index={0}>
        <MenuOpcoesEmpresa
          open={openMenuConfiguracao}
          setOpen={setOpenMenuConfiguracao}
          handleClickUsuarios={handleClickUsuarios}
          handleClickAssinatura={handleClickAssinatura}
          handleClickEstatisticaCs={handleClickEstatisticaCs}
          handleClickPermissoes={handleClickPermissoes}
          handleClickImportaDados={handleClickImportaDados}
          handleClickImportaPreContrato={handleClickImportaPreContrato}
          currentTarget={currentTargetBotaoMenu}
          empresaAtual={empresaAtual}
        />

        <MDBox component="form" pb={3} px={3}>
          <Grid container spacing={2}>

            <Grid item xs={12} sm={8}>
              <Grid container spacing={2}>

                <Grid item xs={12} sm={6}>{/* Código ERP */}
                  <FormField
                    label="Código ERP"
                    disabled={!permitido}
                    placeholder=""
                    name='codigo_erp'
                    value={datasetEmpresas.codigo_erp}
                    onChange={alteraCampo} />
                </Grid>
                <Grid item xs={12} sm={6}>{/* Tipo de Contrato */}
                  <AutoCompleteStyled
                    disabled={!permitido}
                    inputProps={{
                      name: 'tipo_contrato_id'
                    }}

                    defaultValue=""
                    value={datasetEmpresas.tipo_contrato_id}
                    options={opcoesTiposContratos}
                    renderInput={(params) => (
                      <FormField {...params} label="Tipo de Contrato" InputLabelProps={{ shrink: true }} />
                    )}
                    onChange={(event, newValue) => {
                      setDatasetEmpresas({ ...datasetEmpresas, tipo_contrato_id: newValue });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={8}>{/* Tipo de Empresa */}
                  <AutoCompleteStyled
                    disabled={!permitido}
                    inputProps={{
                      name: 'tipo_empresa_id'
                    }}

                    defaultValue=""
                    value={datasetEmpresas.tipo_empresa_id}
                    options={opcoesTiposEmpresas}
                    renderInput={(params) => (
                      <FormField {...params} label="Tipos" InputLabelProps={{ shrink: true }} />
                    )}
                    onChange={(event, newValue) => {
                      setDatasetEmpresas({ ...datasetEmpresas, tipo_empresa_id: newValue });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>{/* Ativo */}
                  <AutoCompleteStyled
                    disabled={!permitido}
                    inputProps={{
                      name: 'ativo'
                    }}

                    defaultValue=""
                    value={datasetEmpresas.ativo}
                    options={[{ value: 'S', label: 'Sim' }, { value: 'N', label: 'Não' }]}
                    renderInput={(params) => (
                      <FormField {...params} label="Ativo" InputLabelProps={{ shrink: true }} />
                    )}
                    onChange={(event, newValue) => {
                      setDatasetEmpresas({ ...datasetEmpresas, ativo: newValue });
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>{/* Razão Social */}
                  <FormField
                    label="Razão Social"
                    disabled={!permitido}
                    placeholder=""
                    name='razaosocial'
                    value={datasetEmpresas.razaosocial}
                    onChange={alteraCampo} />
                </Grid>
                <Grid item xs={12} sm={12}>{/* Nome Fantasia */}
                  <FormField label="Nome Fantasia" disabled={!permitido} placeholder="" name='fantasia' value={datasetEmpresas.fantasia} onChange={alteraCampo} />
                </Grid>
                <Grid item xs={12} sm={6}>{/* Pessoa */}
                  <AutoCompleteStyled
                    disabled={!permitido}
                    inputProps={{
                      name: 'fisica_juridica'
                    }}

                    defaultValue="Jurídica"
                    value={datasetEmpresas.fisica_juridica === 'J' ? 'Jurídica' : 'Física'}
                    options={fisica_juridica}
                    renderInput={(params) => (
                      <FormField {...params} label="Pessoa" InputLabelProps={{ shrink: true }} />
                    )}
                    onChange={(event, newValue) => {
                      alteraFisicaJuridica(newValue)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>{/* Cnpj */}
                  <FormField label="Cnpj" disabled={!permitido} placeholder="" name='cnpj' value={datasetEmpresas.cnpj} onChange={alteraCampo} />
                </Grid>
                <Grid item xs={12} sm={6}>{/* Insc.Estadual */}
                  <FormField label="Insc.Estadual" disabled={!permitido} placeholder="" name='inscricao_estadual' value={datasetEmpresas.inscricao_estadual} onChange={alteraCampo} />
                </Grid>
                <Grid item xs={12} sm={6}>{/* Insc.Municipal */}
                  <FormField label="Insc.Municipal" disabled={!permitido} placeholder="" name='inscricao_municipal' value={datasetEmpresas.inscricao_municipal} onChange={alteraCampo} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={4}>{/* Img */}
              <ImagemLogoEmpresa
                imagePreviewUrl={imagePreviewUrl}
                setImagePreviewUrl={setImagePreviewUrl}
                empresaAtual={empresaAtual}
              />
            </Grid>
            <Grid item xs={12} sm={6}>{/* Rua */}
              <FormField
                label="Rua"
                placeholder=""
                name='endereco_rua' disabled={!permitido} value={datasetEmpresas.endereco_rua} onChange={alteraCampo}
              />
            </Grid>
            <Grid item xs={12} sm={2}>{/* Nº */}
              <FormField
                label="Nº"
                placeholder=""
                inputProps={{ type: "number" }}
                name='endereco_numero' disabled={!permitido} value={datasetEmpresas.endereco_numero} onChange={alteraCampo}
              />
            </Grid>
            <Grid item xs={12} sm={4}>{/* Bairro */}
              <FormField
                label="Bairro"
                placeholder=""
                name='endereco_bairro' disabled={!permitido} value={datasetEmpresas.endereco_bairro} onChange={alteraCampo}
              />
            </Grid>


            <Grid item xs={12} sm={4}>{/* Estado */}
              <AutoCompleteStyled
                disabled={!permitido}
                inputProps={{
                  name: 'uf'
                }}

                value={ufAtual}
                options={opcoesEstado}
                renderInput={(params) => (
                  <FormField {...params} label="Estado" InputLabelProps={{ shrink: true }} />
                )}
                onChange={(event, newValue) => {
                  setUfAtual(newValue)
                }}
              />
            </Grid>


            <Grid item xs={12} sm={6}>{/* Cidade */}
              <AutoCompleteStyled
                disabled={!permitido}
                inputProps={{
                  name: 'cidade'
                }}

                value={cidadeAtual}
                options={opcoesCidade}
                renderInput={(params) => (
                  <FormField {...params} label="Cidade" InputLabelProps={{ shrink: true }} />
                )}
                onChange={(event, newValue) => {
                  setCidadeAtual(newValue)
                }}
              />
            </Grid>

            <Grid item xs={12} sm={2}>{/* CEP */}
              <FormField
                label="CEP"
                placeholder=""
                name='endereco_cep' disabled={!permitido} value={datasetEmpresas.endereco_cep} onChange={alteraCampo}
              />
            </Grid>


            <Grid item xs={12} md={12}>
              {
                (!(parseInt(empresaAtual, 10) > 0)) &&
                <DadosUsuarioAdmin
                  datasetUsuariodmin={datasetUsuariodmin}
                  setDatasetUsuariodmin={setDatasetUsuariodmin}
                  permitido={permitido}
                />
              }

            </Grid>



            <Grid item xs={12} md={8}>
            </Grid>
            <Grid item xs={12} md={4} >
              <MDBox display='flex' justifyContent="flex-end" mt={2}>
                <MDBox mr={2}>
                  <IconButton
                    onClick={(event) => {
                      setCurrentTargetBotaoMenu(event.currentTarget);
                      setOpenMenuConfiguracao(true);
                    }
                    }
                  >
                    <SettingsIcon />
                  </IconButton>
                </MDBox>
                <MDBox mr={2}>
                  <MDButton color='info' disabled={!permitido} onClick={gravarEmpresa}>Gravar</MDButton>
                </MDBox>
                <MDButton color='info' disabled={!permitido} onClick={novaEmpresa} ml={2} >Novo</MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox>
          <TabelaEmpresasAdmin
            abrirRegistro={abrirRegistro}
            excluirRegistro={handleClickExcluir}
            recarregaTabela={recarregaTabela}
            opcoesTiposContratos={opcoesTiposContratos}
          /*Passa o state do tipos contratos*/
          />
        </MDBox>
      </TabPanel>

      <TabPanel value={aba} index={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <MDButton color='info' disabled={!permitido} onClick={() => setAba(0)} ml={2} >Voltar</MDButton>
          </Grid>

          <Grid item xs={12} md={12}>
            <UsuariosAdmin
              empresaAtual={empresaAtual}
            />
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={aba} index={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <MDButton color='info' disabled={!permitido} onClick={() => setAba(0)} ml={2} >Voltar</MDButton>
          </Grid>

          <Grid item xs={12} md={12}>
            <BancodeDadosAdmin
              empresaAtual={empresaAtual}
              nomeEmpresaAtual={nomeEmpresaAtual}
            />
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={aba} index={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <MDButton color='info' disabled={!permitido} onClick={() => setAba(0)} ml={2} >Voltar</MDButton>
          </Grid>

          <Grid item xs={12} md={12}>
            <Assinatura
              empresaAtual={empresaAtual}
            />
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={aba} index={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Grid item xs={12} md={12}>
              <MDButton color='info' disabled={!permitido} onClick={() => setAba(0)} ml={2} >Voltar</MDButton>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12}>
            <EstatisticaCs
              empresaAtual={empresaAtual}
            />
          </Grid>
        </Grid>
      </TabPanel>

    </Card>
  );
}

export default CadastroEmpresaAdmin;
