import { Card, Collapse, IconButton, Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MDBox from "components/MDBox";
import { useState } from "react";
import PerfisAcessos from "./Cadastro";



export default function PerfisAcessoAdmin(props) {

    const { openCollapseAcessoPerfil, handleClickCollapseAcessoPerfil } = props;
    const [recarregaTabela, setRecarregaTabela] = useState('');
    const [perfilAcessoAtual, setperfilAcessoAtual] = useState('');

    return (
        <Card id="cadastro-perfil-acesso" sx={{ overflow: "visible" }}>
            <MDBox p={2} bgColor='grey-100' mt={2}>
                <IconButton aria-label="expand row" size="small" onClick={() => handleClickCollapseAcessoPerfil(!openCollapseAcessoPerfil)}>
                    {openCollapseAcessoPerfil ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    <Typography variant='h6' mb={2}>Perfis de Acesso</Typography>
                </IconButton>

                <Collapse in={openCollapseAcessoPerfil} timeout="auto" unmountOnExit>
                    <PerfisAcessos
                        recarregaTabela={recarregaTabela}
                        setRecarregaTabela={setRecarregaTabela}
                        perfilAcessoAtual={perfilAcessoAtual}
                        setperfilAcessoAtual={setperfilAcessoAtual}
                    />
                </Collapse>
            </MDBox>
        </Card>
    )
}