
import React, { useContext, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Cadastro from "./Cadastro";
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import DialogoConfirma from 'componentes/DialogoConfirma';
import { useTrataRetorno } from "commons/authUtils";

function Assinatura(props) {

  const trataRetorno = useTrataRetorno();
  const { empresaAtual } = props;
  const [usuarioAtual, setUsuarioAtual] = useState('');
  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro, MostraTelaEspera, FechaTelaEspera } = messageContext;
  const [dataset, setDataset] = useState([]);
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const [enabledBotaoGravar, setEnabledBotaoGravar] = useState(true);
  const [openFiltrosAvancados, setOpenFiltrosAvancados] = useState(false);

  const baseUrl = `${rotafechada()}assinaturas`;
  const baseCaption = 'Assinatura';

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${baseUrl}/permissao/${operacao}`);
      return true;
    }
    catch (error) {
      trataRetorno(error?.response?.data?.erro);

      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }

  useEffect(async () => {

    if (parseInt(usuarioAtual, 10) > 0) {
      if (await validaRotaPermissao('editar') === false) {
        //alem da mensagem, desativo o botao de gravar
        setEnabledBotaoGravar(false);
      }
    }
    else {
      if (await validaRotaPermissao('inserir') === false) {
        //alem da mensagem, desativo o botao de gravar
        setEnabledBotaoGravar(false);
      }
    }


  }, [])


  return (
    <>

      <DialogoConfirma
        open={dialogoOpen.visible}
        title={dialogoOpen.title}
        message={dialogoOpen.message}
        handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
      />

      <MDBox mt={0} mb={0}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={12}>
            <MDBox mt={0} mb={0} textAlign="center">
              <MDBox mb={2}>
                <MDTypography variant="h3" fontWeight="bold">
                  {`${baseCaption}`}
                </MDTypography>
              </MDBox>
            </MDBox>

            <Card>
              <MDBox width="100%" display="flex" justifyContent="space-between">
                <Cadastro
                  setUsuarioAtual={setUsuarioAtual}
                  usuarioAtual={usuarioAtual}
                  empresaAtual={empresaAtual}
                />
              </MDBox>
            </Card>

          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

export default Assinatura;
