
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Icon, Typography, IconButton, Tooltip } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Tabela from "./Tabela";
import Cadastro from "./Cadastro";
import MDButton from "components/MDButton";
import { configTextField } from "./Cadastro/configfields";
import { useConfigForm } from "hooks/ConfigForm";
import MessagesContext from "hooks/MessagesContext";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import AuthContext from "hooks/AuthContext";
import DialogoConfirma from 'componentes/DialogoConfirma';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuConfiguracaoModulos from "componentes/MenuConfiguracaoModulos";
import { useTrataRetorno } from "commons/authUtils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.any,
  index: PropTypes.number,
  value: PropTypes.number,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function Cores() {
  const trataRetorno = useTrataRetorno();

  const [aba, setAba] = React.useState(0);
  const [codigoCarregado, setCodigoCarregado] = useState('');

  const messageContext = useContext(MessagesContext);
  const { MensagemAviso, MensagemErro } = messageContext;

  const [dataset, setDataset] = useState([]);
  const configtext = configTextField;
  const { configForm, setConfig } = useConfigForm();
  setConfig(configtext); //->setConfig
  const authContext = useContext(AuthContext);
  const { getSessao, logout } = authContext;
  const sessaolocal = getSessao();
  // const logoff = logout();
  const [dialogoOpen, setDialogoOpen] = useState({ visible: false, id: null });
  const [recarregaTabela, setRecarregaTabela] = useState(false);
  const [enabledBotaoGravar, setEnabledBotaoGravar] = useState(true);

  const [openMenuConfiguracao, setOpenMenuConfiguracao] = useState(false);
  const [currentTargetBotaoMenu, setCurrentTargetBotaoMenu] = useState(null);

  const baseUrl = `${rotafechada()}cores`;
  const baseCaption = 'Cores';

  const validaRotaPermissao = async (operacao = '', silencioso = false) => {
    if ((operacao !== 'inserir') &&
      (operacao !== 'editar') &&
      (operacao !== 'excluir') &&
      (operacao !== 'listar')) {
      MensagemErro('Escolha a operação que deseja validar a permissão!');
      return false;
    }

    //validando a permissao
    try {
      await api.get(`${baseUrl}/permissao/${operacao}`);
      return true;
    }
    catch (error) {
       trataRetorno(error?.response?.data?.erro);
      const msg = error.response?.data?.msg || error;

      if (silencioso === false) {
        MensagemErro(`Erro: ${msg}`);
      }
      return false;
    }
  }

  const handleChangeAba = (event, novaAba) => {
    setAba(novaAba);
  };

  const limpaDataset = () => {
    //percorre o vetor de campos e cria um objeto com as propriedades
    //vazias
    let dadoslimpos = {};
    configForm.forEach((item) => {
      dadoslimpos[item.name] = '';
      if ((item.type === 'autocomplete') && (item.items?.length > 0)) {
        dadoslimpos[item.name] = item.items[0];
      }
    });
    setDataset(dadoslimpos);
  }

  const handleClickNovo = async () => {
    if (await validaRotaPermissao('inserir') === false) {
      return;
    }

    limpaDataset();
    setCodigoCarregado('');
    setAba(1)
  }

  const abrirRegistro = async (id) => {

    if (!parseInt(id, 10) > 0)
      return;

    try {

      const retorno = await api.get(`${baseUrl}?id=${id}`);
      const { data, count } = retorno.data;

      if (!count > 0) {
        MensagemErro('Nenhuma informação foi selecionada');
        return;
      }

      if (data[0].empresa_id !== sessaolocal.empresa_id) {
        MensagemErro('Registro de outra empresa!');
        return;
      }

      let dados = { ...data[0] };

      // dados.checkbox=dados.ativo==='S'?true:false;
      // dados.switch=dados.ativo==='S'?true:false;
      // dados.radiogroup=dados.ativo;

      setDataset(dados);
      setCodigoCarregado(id);
      setAba(1);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleGravar = async (dados) => {

    delete dados.id;
    try {
      dados = { ...dados, empresa_id: sessaolocal.empresa_id || null };

      let retorno;

      if (codigoCarregado && parseInt(codigoCarregado, 10) > 0)
        retorno = await api.put(`${baseUrl}/${codigoCarregado}`, dados);
      else {
        retorno = await api.post(`${baseUrl}`, dados);
        setCodigoCarregado(retorno.data.data.id || 0);
        setDataset(retorno.data.data);
      }
      MensagemAviso('Gravado com sucesso!');
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleExcluir = async () => {
    setDialogoOpen({ ...dialogoOpen, visible: false });

    try {
      await api.delete(`${baseUrl}/${dialogoOpen.id || 0}`);
      MensagemAviso('Excluído com sucesso!');
      limpaDataset();
      setRecarregaTabela(!recarregaTabela);
    }
    catch (error) {
      const msg = error.response?.data?.msg || error;
      MensagemErro(`Erro: ${msg}`);
    }
  }

  const handleClickExcluir = async (id) => {

    if (!(await validaRotaPermissao('excluir') === true)) {
      return;
    }


    setDialogoOpen({ ...dialogoOpen, visible: true, id });

  }

  useEffect(() => {
    const tratapermissoes = async () => {
      if (aba === 1) {
        if (parseInt(codigoCarregado, 10) > 0) {
          if (await validaRotaPermissao('editar') === false) {
            //alem da mensagem, desativo o botao de gravar
            setEnabledBotaoGravar(false);
          }
        }
        else {
          if (await validaRotaPermissao('inserir') === false) {
            //alem da mensagem, desativo o botao de gravar
            setEnabledBotaoGravar(false);
          }
        }
      }
    }
    tratapermissoes();
  }, [aba])

  return (
    <>
      <MenuConfiguracaoModulos
        open={openMenuConfiguracao}
        setOpen={setOpenMenuConfiguracao}
        nomeModulo='CORES'
        currentTarget={currentTargetBotaoMenu}
      />
      <DashboardLayout>
        <DashboardNavbar />

        <DialogoConfirma
          open={dialogoOpen.visible}
          title={'Confirma Exclusão'}
          color={'error'}
          message={'Certeza que deseja excluir?'}
          handleCancel={() => setDialogoOpen({ ...dialogoOpen, visible: false })}
          handleConfirm={handleExcluir}
        />

        <MDBox mt={5} mb={9}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={12}>
              <MDBox mt={0} mb={0} textAlign="center">
                <MDBox mb={1}>
                  <MDTypography variant="h3" fontWeight="bold">
                    {`${baseCaption}`}
                  </MDTypography>
                </MDBox>
                {/* Botao de Configuracoes (Parametros e Permissoes) */}
                <MDBox width="100%" display="flex" justifyContent="flex-end">
                  <Tooltip title="Configurações e Permissões" placement="top">
                    <IconButton
                      onClick={(event) => {
                        setCurrentTargetBotaoMenu(event.currentTarget);
                        setOpenMenuConfiguracao(true);
                      }
                      }
                    >
                      <SettingsIcon />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              </MDBox>

              <Card>
                {/* <MDBox mt={-3} mb={3} mx={2}>
                  <Tabs value={aba} onChange={handleChangeAba} aria-label={`Aba de ${baseCaption}`}>
                    <Tab label="Listagem" {...a11yProps(0)} />
                    <Tab label="Cadastro" {...a11yProps(1)} />
                  </Tabs>

                </MDBox> */}

                <TabPanel value={aba} index={0}>
                  <MDBox width="100%" display="flex" justifyContent="flex-end">
                    <Tooltip title='Novo' placement="top">
                      <MDButton
                        variant="gradient"
                        circular
                        onClick={handleClickNovo}
                        color='info'
                      >
                        <Icon>add</Icon>
                      </MDButton>
                    </Tooltip>

                  </MDBox>

                  <Tabela abrirRegistro={abrirRegistro} handleClickExcluir={handleClickExcluir} recarregaTabela={recarregaTabela} validaRotaPermissao={validaRotaPermissao} />

                </TabPanel>

                <TabPanel value={aba} index={1}>
                  <Cadastro
                    setAba={setAba}
                    handleClickNovo={handleClickNovo}
                    dataset={dataset}
                    handleGravar={handleGravar}
                    enabledBotaoGravar={enabledBotaoGravar} />
                </TabPanel>

              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default Cores;
