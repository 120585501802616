import {createContext, useContext, useState} from 'react';

const ConfigFormContext = createContext();

export function ConfigFormProvider({children}){

    const [configForm, setConfigForm] = useState([]);

    const setConfig = (cfg) => {
        setConfigForm(cfg)
    }

    return (
        <ConfigFormContext.Provider
            value={{configForm, setConfig}}
        >
            {children}
        </ConfigFormContext.Provider>
    )
}


//Hook usado na configuracao de formularios
export function useConfigForm(){
    const context = useContext(ConfigFormContext)
    return context;
}