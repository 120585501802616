import React from 'react';
import { NumericFormat } from "react-number-format";
import FormField from 'componentes/FormField';
import { InputAdornment } from '@mui/material';

//value só aceita float se mandar uma string ele se perde na hora de montrar os ponto e as virgula

function CurrencyInput(props) {
  return (
    <NumericFormat
      allowNegative={props.allowNegative ? props.allowNegative : true}
      thousandSeparator={'.'}
      decimalSeparator={','}
      placeholder={props.placeholder ? props.placeholder : "0,00"}
      decimalScale={props.decimalScale ? props.decimalScale : 2}
      label={props.label ? props.label : ''}
      value={props.valor ? props.valor : 0}
      isNumericString={props.isNumericString ? props.isNumericString : true}
      {...props}

      customInput={FormField} />
  );
}

export default CurrencyInput;