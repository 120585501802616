import React from 'react';
import { Checkbox, Grid, Typography } from '@mui/material';
import FormField from 'componentes/FormField';
import MDBox from 'components/MDBox';
import { Controller } from 'react-hook-form';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MDTypography from 'components/MDTypography';


const DadosPrecos = (props) => {
  const { control, setValue, tabelapreco, indice, getValues } = props;
  //const { register } = useForm({});


  const handleBlurMargem = () => {

    let custo = getValues(`valor_custo`);
    custo = `${custo}`.replace(',', '.');

    let margem = getValues(`servico_precos[${indice}].percentual_margem`);
    margem = `${margem}`.replace(',', '.');

    if (!parseFloat(custo) > 0) {
      custo = 0;
    }

    if (!parseFloat(margem) > 0) {
      margem = 0;
    }

    const valorsugerido = parseFloat(custo) + ((parseFloat(custo) * parseFloat(margem)) / 100);
    setValue(`servico_precos[${indice}].sugerido`, `${valorsugerido}`);

  }

  const handleBlurValorVenda = () => {

    let margem = getValues(`servico_precos[${indice}].percentual_margem`);

    /********************************** */
    let valor_venda = getValues(`servico_precos[${indice}].valor_venda`);
    valor_venda = `${valor_venda}`.replace(',', '.');

    let valor_custo = getValues(`valor_custo`);
    valor_custo = `${valor_custo}`.replace(',', '.');

    let percentual_margem

    if (!parseFloat(valor_custo) > 0) {
      valor_custo = 0;
    }

    if (!parseFloat(valor_venda) > 0) {
      valor_venda = 0;
    }

    if (valor_venda > 0 && valor_custo > 0) {
      percentual_margem = (((100 * valor_venda) - (100 * valor_custo)) / valor_custo);
      percentual_margem = percentual_margem.toFixed(2);

    }
    else percentual_margem = 0

    setValue(`servico_precos[${indice}].percentual_margem`, `${percentual_margem}`);
    setValue(`servico_precos[${indice}].sugerido`, `${valor_venda}`);

  }

  return (

    < MDBox p={2} bgColor='#EAFAF1' mt={1} key={indice}>
      <Typography variant='h6' mb={2}>Preços <MonetizationOnIcon sx={{ color: '#21e4d0' }} /></Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={2}>{/*id*/}
          <Controller
            name={`servico_precos[${indice}].id`}
            control={props.control}
            defaultValue=''
            render={({ field }) =>
              <FormField
                {...field}
                autoComplete={false}
                type='text'
                label='Id'
                rows={1}
                multiline={false}
                required={false}
                disabled={true}
                inputProps={{
                  disabled: true
                }}
              />}
          />
        </Grid>
        <Grid item xs={12} sm={3}>{/*Nome tabela de Preço*/}
          {/*<input type="hidden" {...register(`grades[${indiceCor}].precos[${indiceVetor}].tabela_preco_id`)} />*/}
          <Controller
            name={`servico_precos[${indice}].tabela_preco`}
            control={props.control}
            defaultValue=''
            render={({ field }) =>
              <FormField
                {...field}
                autoComplete={false}
                type='text'
                label='Tabela de Preço'
                rows={1}
                multiline={false}
                required={false}
                disabled={true}
                InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
                inputProps={{
                  disabled: true
                }}
              />}
          />
        </Grid>
        <Grid item xs={12} sm={2}>{/*Margem*/}
          <Controller
            name={`servico_precos[${indice}].percentual_margem`}
            control={props.control}
            defaultValue=''
            render={({ field }) =>
              <FormField
                {...field}
                autoComplete={false}
                type='text'
                label='Margem(%)'
                rows={1}
                multiline={false}
                required={true}
                disabled={false}

                inputProps={{
                  onKeyUp: (e) => {
                  },
                  onBlur: (e) => {
                    e.preventDefault();
                    handleBlurMargem();
                  },
                }}


              />}
          />
        </Grid>
        <Grid item xs={12} sm={2}>{/*Sugerido*/}
          <Controller
            name={`servico_precos[${indice}].sugerido`}
            control={props.control}
            defaultValue=''
            render={({ field, value }) =>
              <FormField
                {...field}
                autoComplete={false}
                type='text'
                label='Sugerido(R$)'
                rows={1}
                multiline={false}
                required={false}
                disabled={true}
                inputProps={{
                  disabled: true
                }}
                InputLabelProps={{ shrink: true }} //evita o problema da label sobrepor o valor do input
              />}
          />
        </Grid>
        <Grid item xs={12} sm={2}>{/*Preco Venda*/}
          <Controller
            name={`servico_precos[${indice}].valor_venda`}
            control={props.control}
            defaultValue=''
            render={({ field }) =>
              <FormField
                {...field}
                autoComplete={false}
                type='text'
                label='Valor de Venda(R$)'
                rows={1}
                multiline={false}
                required={true}
                disabled={false}

                inputProps={{
                  onKeyUp: (e) => {
                  },
                  onBlur: (e) => {
                    e.preventDefault();
                    handleBlurValorVenda();
                  },
                }}

              />}
          />
        </Grid>
        <Grid item xs={12} sm={1}>{/*ativo*/}
          <MDTypography variant="caption">
            {'Ativo'}
            <Controller
              name={`servico_precos[${indice}].ativo`}
              control={control}
              render={({ field: { value, ...otherprops } }) => (
                <Checkbox
                  {...otherprops}
                  checked={value ? (value === true ? true : false) : false}
                  onChange={(e) => setValue(`servico_precos[${indice}].ativo`, e.target.checked)}
                />
              )}
            />
          </MDTypography>
        </Grid>

      </Grid>
    </MDBox >
  )
}

export default DadosPrecos;