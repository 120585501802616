import { Avatar, Divider, Grid, Icon, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Switch, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from "react";
import { useEffect } from "react";
import AddIcon from '@mui/icons-material/Add';
export default function ListaPermissao(props) {

  const { titulo, lista, onClickDelete, opcoesInserir,onClickAdd } = props;

  const [opcao, setOpcao] = useState('');

  useEffect(() => {

  }, [opcoesInserir])
  
  const handleClickAdd=()=>{
    onClickAdd(opcao||0);
  }

  return (
    <MDBox p={1} fullWidth >

      <MDBox display='flex' flexDirection='row' justifyContent="space-between" width='100%'>

        {
          opcoesInserir?.length > 0 &&
          <Select
            value={opcao}
            onChange={(e) => setOpcao(e.target.value)}
            label=''
            fullWidth
            sx={{ ml: 1 }}

          >
            {
              opcoesInserir?.map((item, index) => {
                return (
                  <MenuItem value={item.id}>{item.nome || item.caption || ''}</MenuItem>
                )
              })
            }
          </Select>
        }


        <MDBox width='20%'>
          <IconButton size="small" onClick={handleClickAdd}>
            <AddIcon fontSize="small" />
          </IconButton>
        </MDBox>

      </MDBox>
      <Typography variant="body2" mb={2} fontWeight='bold'>{titulo}</Typography>

      <List
        sx={{
          width: '100%',
          maxWidth: 400,
          bgcolor: 'background.paper',
        }}
        component="nav"
        aria-label="listas"
      >
        {
          lista?.map((item, key) =>
            <ListItem button divider sx={{ fontSize: 12 }}
              secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => onClickDelete(item.id || 0)}>
                  <DeleteIcon />
                </IconButton>
              }>
              <ListItemText primary={item.caption || ''} primaryTypographyProps={{ fontSize: 16 }} />
            </ListItem>
          )
        }
      </List>
    </MDBox>

  )
}
