
function degToRad(angle) {
    return angle / 180 * Math.PI;
  }


const calculaPosicaoTexto=(props={angulo:'',largura:'',x:'',y:''})=>{

    const {angulo,largura,x,y}=props;
    
    //Calculando o ponto final da seta
    const angleRad=degToRad(angulo*-1);
    const hip=largura;
    const catop=(hip*Math.sin(angleRad));
    const catad=(hip*Math.cos(angleRad));
    const x2=x+catad;
    const y2=y-catop;
    //************************/
    //Calculando o meio da seta
    const xMeio=x+parseInt((x2-x)/2,10);
    const yMeio=y-parseInt((y-y2)/2,10)-20;//10 para ficar acima da seta
    return {x:xMeio,y:yMeio};

}

module.exports={calculaPosicaoTexto}