import { Checkbox, Dialog, Divider, FormControlLabel, Icon, Typography } from "@mui/material";
import { Box } from "@mui/system";
import api from "commons/api";
import { rotafechada } from "commons/urlconf";
import MDButton from "components/MDButton";
import AuthContext from "hooks/AuthContext";
import MessagesContext from "hooks/MessagesContext";
import ProjetoTempera from "pages/desenhos/projetotempera";
import { useContext, useEffect, useRef, useState } from "react";
import DadosPedidoTempera from "../etapa1_preparapedidotempera/dadospedidotempera";



export default function Etapa4_MostraDesenhos(props) {

    const { pedido_tempera_id, handleClose, vendaAtual, etapaAtual, setEtapaAtual, abortaProcesso, etapaAnterior } = props;
    const messageContext = useContext(MessagesContext);
    const { MensagemErro, MensagemAviso, MostraTelaEspera, FechaTelaEspera } = messageContext;

    const authContext = useContext(AuthContext);
    const { getSessao } = authContext;
    const sessaolocal = getSessao();
    const [dadosProjetos, setDadosProjetos] = useState([]);
    const [dadosPosicaoImpressao, setDadosPosicaoImpressao] = useState([]);

    const [imageBase64, setImageBase64] = useState();
    const [openDialogo, setOpenDialogo] = useState(false);
    const [downloadArquivo, setDownloadArquivo] = useState(false);
    const [textoLateral, setTextoLateral] = useState(true);

    //https://beta.reactjs.org/learn/manipulating-the-dom-with-refs#how-to-manage-a-list-of-refs-using-a-ref-callback
    const itemsRef = useRef(null);

    const buscaProjetosPedidoTempera = async () => {

        if (!(parseInt(pedido_tempera_id, 10) > 0)) {
            return;
        }

        //chamo a rota que vai trocar o texto pelas variáveis e retornará a posição de impressão de cada 
        //Pedido_Tempera_Venda_Projetos
        try {
            MostraTelaEspera('Alterando os valores nas imagens...');
            const retorno = await api.post(encodeURI(`${rotafechada()}pedido_tempera_venda_projetos/calculaformulas`), {
                pedido_tempera_id

            });
            const dados = retorno.data.dados;
            setDadosPosicaoImpressao(dados);
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            const erro = error.response?.data?.erro || '';
            console.log(erro);
            MensagemErro(`Erro ao tentar substituir as variáveis nos desenhos!${msg}`)
        }

        try {

            MostraTelaEspera('Aguarde a busca dos dados...');
            const retorno = await api.get(encodeURI(`${rotafechada()}pedido_tempera_venda_projetos?pedido_tempera_id=${pedido_tempera_id}&order=id`));
            const { data } = retorno.data;

            if (!data.length > 0) {
                MensagemErro(`O pedido de têmpera não possui projetos!`);
                abortaProcesso();
                return;
            }

            const temp = [];
            data.map((item) => {

                temp.push({
                    nomeprojeto: item?.Venda_Projeto?.Projeto?.nome,
                    ambiente_instalacao: item?.Venda_Projeto.ambiente_instalacao,
                    pedido_tempera_venda_projeto_id: item.id,
                    venda_projeto_id: item.venda_projeto_id,
                    projeto_id: item.Venda_Projeto?.projeto_id,
                })

            })

            setDadosProjetos(temp);
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            const erro = error.response?.data?.erro || '';
            console.log(erro);
            MensagemErro(`Erro ao tentar resgatar os dados dos projetos da Venda!${msg}`)
        }
    }


    const handleClickRetornar = async () => {
        setEtapaAtual(etapaAtual - 1);
    }

    const geraImagensFinais = async () => {

        let dados = { imagens: [] };

        try {
            MostraTelaEspera('Gerando as  imagens finais...');



            dadosProjetos.map((item) => {

                if (!itemsRef.current) {
                    // Initialize the Map on first usage.
                    itemsRef.current = new Map();
                }

                const map = itemsRef.current;

                //const map = getMap();
                const node = map.get(item.pedido_tempera_venda_projeto_id);

                const posicoes = dadosPosicaoImpressao.filter((itemImpressao) => itemImpressao.id === item.pedido_tempera_venda_projeto_id);
                const posicaoExportacao = {
                    x: posicoes.length > 0 ? posicoes[0].x : 0,
                    y: posicoes.length > 0 ? posicoes[0].y : 0,
                    width: posicoes.length > 0 ? posicoes[0].width : 1024,
                    height: posicoes.length > 0 ? posicoes[0].height : 768
                }

                const uri = node.toDataURL(posicaoExportacao);
                dados.imagens.push({
                    pedido_tempera_venda_projeto_id: item.pedido_tempera_venda_projeto_id,
                    imagembase64: uri
                });

                // setImageBase64(uri);
                // setOpenDialogo(true);
            });

            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            const erro = error.response?.data?.erro || '';
            console.log(erro);
            MensagemErro(`Erro ao tentar gerar as imagens!${msg}`)
        }

        //disparando as imagens para o backend
        try {
            MostraTelaEspera('Enviando as imagens para o servidor...');
            //const retorno = await api.post(encodeURI(`${rotafechada()}pedido_tempera_venda_projetos/gravaimagensfinais`), dados);
            // dados.imagens.forEach(async (item) => {
            //     const dadostemp={imagens:[]};
            //     dadostemp.imagens.push(item);
            //     await api.post(encodeURI(`${rotafechada()}pedido_tempera_venda_projetos/gravaimagensfinais`), dadostemp);
            // });

            for(let i = 0; i < dados.imagens.length; i++){
                const dadostemp={imagens:[]};
                dadostemp.imagens.push(dados.imagens[i]);
                await api.post(encodeURI(`${rotafechada()}pedido_tempera_venda_projetos/gravaimagensfinais`), dadostemp);                
            }


            MensagemAviso('Imagens enviadas')
            FechaTelaEspera();
        }
        catch (error) {
            FechaTelaEspera();
            const msg = error.response?.data?.msg || error;
            const erro = error.response?.data?.erro || '';
            console.log(erro);
            MensagemErro(`Erro ao tentar enviar as imagens para o servidor!${msg}`)
        }

        //solicitando a geracao do relatorio
        try {
            const resultado = await api.get(`${rotafechada()}imprimir/pedidos_tempera/ordem_fabricacao_compacta?pedido_tempera_id=${pedido_tempera_id}&texto_lateral=${textoLateral ? 'S' : 'N'}`, {
                responseType: 'arraybuffer'
            });

            const file = new Blob([resultado.data], { type: 'application/pdf' });

            if (downloadArquivo) {
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(file);
                link.download = `ordemfabricacao_${vendaAtual}.pdf`;
                link.click();
                link.remove();
            } else {
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);

            }
        }
        catch (error) {
            const msg = error.response?.data?.msg || error;
            MensagemErro(`${msg}`);
        }
    }

    const handleClickProximaEtapa = async () => {

        await geraImagensFinais(dadosPosicaoImpressao);

    }


    useEffect(() => {
        buscaProjetosPedidoTempera();
    }, [pedido_tempera_id])
    return (
        <>
            <Dialog onClose={() => setOpenDialogo(false)} open={openDialogo}
                fullWidth={true}
                maxWidth={'xl'}
            >
                <Box m={2}>
                    <img src={imageBase64} alt="" />
                </Box>
            </Dialog>

            <Box>
                <MDButton color='info' onClick={handleClickRetornar}>Retornar</MDButton>
                <Typography variant='h6' mt={2}>
                    Após todas as imagens terem sido geradas confira os valores e clique no Botão Imprimir (fim da tela)
                </Typography>
            </Box>

            <Divider />
            <Box>
                {
                    dadosProjetos?.map((item, index) => {
                        return (
                            <>
                                <Typography variant='h6'>{`${item?.venda_projeto_id}-${item?.nomeprojeto}| Ambiente: ${item?.ambiente_instalacao}`}</Typography>
                                <ProjetoTempera
                                    key={item?.pedido_tempera_venda_projeto_id}
                                    projeto_id={item?.projeto_id || -1}
                                    pedido_tempera_venda_projeto_id={item?.pedido_tempera_venda_projeto_id || -1}
                                    nomeprojeto={item?.nomeprojeto || ''}
                                    itemsRef={itemsRef}
                                    desabilitaBarras={true}
                                />
                                <Divider />
                            </>
                        )
                    })
                }

            </Box>
            <Box mt={2} mb={2}>
                <Box display='flex' justifyContent={'flex-end'}>

                    <MDButton color='info' onClick={handleClickProximaEtapa}>Imprimir</MDButton>
                </Box>

                <Box display='flex' justifyContent={'flex-end'}>
                    <FormControlLabel control={
                        <Checkbox
                            checked={textoLateral}
                            onChange={(e) => setTextoLateral(e.target.checked)}
                        />
                    }
                        label='Texto na Lateral do Desenho'
                    />
                </Box>

                <Box display='flex' justifyContent={'flex-end'}>
                    <FormControlLabel control={
                        <Checkbox
                            checked={downloadArquivo}
                            onChange={(e) => setDownloadArquivo(e.target.checked)}
                        />
                    }
                        label='Download do Arquivo'
                    />
                    <Icon sx={{ mt: 1 }} color='info'>cloud_download</Icon>
                </Box>

            </Box>



        </>
    )


}