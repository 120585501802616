import { Box, Button, Dialog, DialogTitle, Icon, Tooltip, Typography } from '@mui/material';
import React, { Component, useState } from 'react';
import { Stage, Layer, Rect, Text, Shape, Circle, Line, Image, Transformer, Arrow } from 'react-konva';
import { CompactPicker } from 'react-color';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';


export const PropriedadesRetangulo = (props) => {

    const { itensTela, setItensTela, selectedId, open, setOpen } = props;

    const [corAtual, setCorAtual] = useState('#FFF');


    const handleChangeColor = (color, event) => {
        const temp = itensTela.slice();
        temp[selectedId].fill = color.hex;
        temp[selectedId].stroke = color.hex;
        setCorAtual(color.hex);
        setItensTela(temp);
    }

    const handleChangeEspessuraBorda = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].strokeWidth = parseInt(e.target.value, 10) || 1;
        setItensTela(temp);
    }


    const handleChangeX = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].x = parseInt(e.target.value, 10) || 1;
        setItensTela(temp);
    }

    const handleChangeY = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].y = parseInt(e.target.value, 10) || 1;
        setItensTela(temp);
    }

    const handleChangeWidth = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].width = parseInt(e.target.value, 10) || 100;
        setItensTela(temp);
    }

    const handleChangeHeight = (e) => {
        const temp = itensTela.slice();
        temp[selectedId].height = parseInt(e.target.value, 10) || 100;
        setItensTela(temp);
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            {/* <DialogTitle>{'Retângulo'}</DialogTitle> */}
            <Box m={2}>
                {/* <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant='h6' color={'blue'} mt={2}>Retângulo</Typography>
                    <MDButton>(X)</MDButton>
                </Box> */}

                <Typography variant='h6'>Cor de Fundo</Typography>
                <CompactPicker onChange={handleChangeColor} />


                <Box mt={2} mb={2}>
                    <Typography variant='caption' mt={2}>Sem Fundo</Typography>
                    <MDButton
                        label='Sem Fundo'
                        sx={{ ml: 2 }} onClick={
                            (e) => {
                                e.preventDefault();
                                const temp = itensTela.slice();
                                temp[selectedId].fill = null;
                                setItensTela(temp);
                            }
                        }>
                        <Icon fontSize='large'>border_outer</Icon>
                    </MDButton>
                    <MDInput
                        label='Espessura da Borda'
                        type='number'
                        value={itensTela[selectedId].strokeWidth || 1}
                        onChange={handleChangeEspessuraBorda}
                    />
                </Box>

                <MDInput
                    label='Posição no Eixo X'
                    type='number'
                    value={itensTela[selectedId].x}
                    onChange={handleChangeX}
                    sx={{ mr: 2 }}
                />
                <MDInput
                    label='Posição no Eixo Y'
                    type='number'
                    value={itensTela[selectedId].y}
                    onChange={handleChangeY}
                />

                <MDInput
                    label='Largura'
                    type='number'
                    value={parseInt(itensTela[selectedId].width,10)}
                    onChange={handleChangeWidth}
                    sx={{ mt:2,mr: 2 }}
                />
                <MDInput
                    label='Altura'
                    type='number'
                    value={parseInt(itensTela[selectedId].height,10)}
                    onChange={handleChangeHeight}
                    sx={{ mt:2}}
                />
            </Box>

        </Dialog>
    )

}


export const Retangulo = ({ shapeProps, isSelected, onSelect, onChange }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <React.Fragment>
            <Rect
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...shapeProps}
                draggable
                onDragEnd={(e) => {
                    onChange({
                        ...shapeProps,
                        x: parseInt(e.target.x(), 10),
                        y: parseInt(e.target.y(), 10),
                    });
                }}
                onTransformEnd={(e) => {
                    // transformer is changing scale of the node
                    // and NOT its width or height
                    // but in the store we have only width and height
                    // to match the data better we will reset scale on transform end
                    const node = shapeRef.current;
                    const scaleX = node.scaleX();
                    const scaleY = node.scaleY();

                    // we will reset it back
                    node.scaleX(1);
                    node.scaleY(1);
                    debugger;
                    onChange({
                        ...shapeProps,
                        x: parseInt(node.x(), 10),
                        y: parseInt(node.y(), 10),
                        width: parseInt(Math.max(5, node.width() * scaleX),10),
                        height: parseInt(Math.max(node.height() * scaleY),10),
                        rotation: node.rotation()
                    });
                }}
            />
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </React.Fragment>
    );
};

